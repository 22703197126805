<template>
  <div>
    <SplitScreen
      v-if="isActive"
      :isActive="isActive"
      :isLoading="isLoading"
      custom-class="w-1/6"
      @closeHandler="closeFleetDetail"
    >
      <template #header>
        <span class="font-extrabold">{{ $t("fleet.affiliationTitle") }}</span>
      </template>

      <template #default>
        <div class="pt-4">
          <div class="w-auto mt-4 text-2xl">
            {{ affiliation.title }}
          </div>
          <div class="w-auto mt-8">
            <div class="text-gray-400 mb-2">{{ $t("fleet.truck") }}</div>
            <div :class="{ 'multiselect--error': errors.truck.length > 0 }">
              <multiSelect
                v-model="truckPreviewValue"
                :options="truckPreview"
                :custom-label="displayName"
                placeholder="Select truck"
                label="displayName"
                track-by="displayName"
                :loading="truckLoading"
                :disabled="affiliation.type === 'truck'"
                @input="truckChanged"
              />
            </div>
            <label v-if="errors.truck.length > 0" class="multiselect__error-message">
              {{ $t(errors.truck[0]) }}
            </label>
          </div>
          <div class="w-auto mt-8">
            <div class="text-gray-400 mb-2">{{ $t("fleet.trailer") }}</div>
            <multiSelect
              v-model="trailerPreviewValue"
              :options="trailerPreview"
              :custom-label="displayName"
              placeholder="Select Trailer"
              label="displayName"
              track-by="displayName"
              :loading="trailerLoading"
              :disabled="affiliation.type === 'trailer'"
              @input="trailerChanged"
            />
          </div>
        </div>
      </template>

      <template #actionBar="{ close }">
        <div class="w-full flex">
          <div class="ml-auto flex">
            <Button @click="close()" variant="secondary" class="mr-4"
              ><span v-if="affiliationWasModified">{{ $t("actions.cancel") }}</span>
              <span v-else>{{ $t("actions.close") }}</span></Button
            >
            <Button v-show="affiliationWasModified" @click="saveChanges">{{ $t("actions.save") }}</Button>
          </div>
        </div>
      </template>
    </SplitScreen>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import multiSelect from "vue-multiselect";
import Button from "@/components/Buttons/Button.vue";
import fleetService from "@/services/fleetService";
import store from "@/store";
import { Preview, Truck, AffiliationRequest, Trailer, FleetEntityType, FleetEntity } from "@/types/fleet";
import { LOCK_TRUCK, UNLOCK_TRUCK, UPDATE_FLEET_AFFILIATION } from "@/store/fleet";
import { setAsFirstIfMatching } from "@/use/useArray";
import { translateAndToastSuccess } from "@/use/useToast";
import { spreadTruckId } from "@/use/useFleet";

export default defineComponent({
  name: "FleetDetail",
  components: {
    SplitScreen,
    multiSelect,
    Button
  },

  props: {
    fleetMember: {
      type: Object as () => FleetEntity
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const truckPreview = ref<Array<Preview>>([]);
    const truckPreviewValue = ref<Preview | null>(null);
    const truckLoading = ref(false);
    const trailerPreview = ref<Array<Preview>>([]);
    const trailerPreviewValue = ref<Preview | null>(null);
    const trailerLoading = ref(false);

    const errors = ref<{
      truck: string[];
      trailer: string[];
    }>({
      truck: [],
      trailer: []
    });

    const affiliationTruck = ref<Truck | null>(null);
    const affiliation = ref<{
      type?: string;
      title?: string;
      truckId?: string;
      driverId?: string;
      trailerId?: string;
    }>({
      type: undefined,
      title: undefined,
      truckId: undefined,
      driverId: undefined,
      trailerId: undefined
    });

    const isLoading = ref(false);

    const affiliationWasModified = computed(() => {
      return (
        affiliation.value.truckId !== truckPreviewValue.value?.id ||
        affiliation.value.trailerId !== trailerPreviewValue.value?.id
      );
    });

    const displayName = (previewItem: Preview): string => {
      const { displayName } = previewItem;
      return displayName;
    };

    const initAffiliation = async () => {
      const { fleetMember } = props;
      affiliation.value = {
        title: fleetMember?.id,
        type: fleetMember?.type,
        truckId: undefined,
        driverId: undefined,
        trailerId: undefined
      };

      if (fleetMember?.type === FleetEntityType.TRUCK) {
        const truckMember = fleetMember as Truck;
        const truckMemberId = spreadTruckId(truckMember.id).id;

        await lockTruck(truckMemberId);

        affiliationTruck.value = {
          ...truckMember,
          id: truckMemberId
        };

        affiliation.value = {
          ...affiliation.value,
          truckId: truckMemberId,
          trailerId: truckMember.trailer?.id,
          driverId: truckMember.driver?.id,
          title: truckMemberId
        };
      }
      if (fleetMember?.type === FleetEntityType.TRAILER) {
        const trailerMember = fleetMember as Trailer;
        affiliation.value = {
          ...affiliation.value,
          trailerId: trailerMember.id
        };
      }
    };

    const initDropDowns = () => {
      const { truckId, trailerId } = affiliation.value;
      truckPreviewValue.value =
        (truckId && {
          id: truckId,
          displayName: truckId
        }) ||
        null;
      trailerPreviewValue.value =
        (trailerId && {
          id: trailerId,
          displayName: trailerId
        }) ||
        null;
    };

    const fetchTrucks = async () => {
      if (props.fleetMember?.type === FleetEntityType.TRUCK) {
        return;
      }
      truckLoading.value = true;
      try {
        const { data } = await fleetService.getTrucksPreview();
        truckPreview.value = setAsFirstIfMatching(data.previews, truckPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      truckLoading.value = false;
    };

    const fetchTrailers = async () => {
      if (props.fleetMember?.type === FleetEntityType.TRAILER) {
        return;
      }
      trailerLoading.value = false;
      try {
        const { data } = await fleetService.getTrailersPreview();

        trailerPreview.value = setAsFirstIfMatching(data.previews, trailerPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      trailerLoading.value = false;
    };

    const getDropdownData = () => {
      fetchTrailers();
      if (props.fleetMember?.type !== FleetEntityType.TRUCK) {
        fetchTrucks();
      }
    };

    const setSelectedTrailer = (trailer: Trailer) => {
      trailerPreviewValue.value = {
        id: trailer.id,
        displayName: trailer.id
      };
      trailerPreview.value = setAsFirstIfMatching(trailerPreview.value, trailerPreviewValue.value.id);
    };

    const truckChanged = async (selectedTruck?: Preview | null) => {
      if (typeof selectedTruck === "undefined" || selectedTruck === null) {
        if (affiliationTruck.value?.id) {
          await unlockTruck(affiliationTruck.value?.id);
        }
        return;
      }
      errors.value.truck = [];

      try {
        const { data } = await fleetService.getTruckById(selectedTruck!.id!);
        const truck = data as Truck;
        if (truck.id !== affiliationTruck.value?.id) {
          if (affiliationTruck.value?.id) {
            await unlockTruck(affiliationTruck.value?.id);
          }
          await lockTruck(truck.id, false);
        }
        affiliationTruck.value = truck;
        truckPreview.value = setAsFirstIfMatching(truckPreview.value, truck.id);
        if (trailerPreviewValue.value == null && truck.trailer != null) {
          setSelectedTrailer(truck.trailer);
        }
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const trailerChanged = async (selectedTrailer?: Preview | null) => {
      if (typeof selectedTrailer === "undefined" || selectedTrailer === null) {
        return;
      }
      trailerPreview.value = setAsFirstIfMatching(trailerPreview.value, trailerPreviewValue.value?.id);
    };

    const closeFleetDetail = () => {
      if (affiliationTruck.value?.id) {
        unlockTruck(affiliationTruck.value?.id);
      }
      emit("fleetDetailClosed");
    };

    const updateAffiliation = async (request: AffiliationRequest) => {
      isLoading.value = true;
      try {
        await store.dispatch(UPDATE_FLEET_AFFILIATION, request);
        isLoading.value = false;
        closeFleetDetail();
        translateAndToastSuccess("Affiliation updated");
      } catch (e) {
        isLoading.value = false;
      }
      await unlockTruck(request.id);
    };

    const saveChanges = async () => {
      if (affiliation.value.type == FleetEntityType.TRAILER) {
        if (affiliationTruck.value == null) {
          errors.value.truck.push("errors.SELECT_TRUCK");
          isLoading.value = false;
          return;
        }
      }

      const driverId = affiliation.value?.driverId || null;
      const trailerId = trailerPreviewValue.value?.id || null;

      await updateAffiliation({
        ...(affiliationTruck.value as Truck),
        driverId,
        trailerId
      });
    };

    const initializeState = async () => {
      await initAffiliation();
      initDropDowns();
      getDropdownData();
    };

    const lockTruck = async (truckId: string, closeOnFail = true) => {
      try {
        await store.dispatch(LOCK_TRUCK, truckId);
      } catch (e) {
        if (closeOnFail) {
          await closeFleetDetail();
        } else {
          errors.value.truck.push("errors.tuckLocked");
        }
      }
    };

    const unlockTruck = async (truckId: string) => {
      try {
        await store.dispatch(UNLOCK_TRUCK, truckId);
      } catch (e) {
        closeFleetDetail();
      }
    };

    onMounted(() => {
      initializeState();
    });

    watch(
      () => props.fleetMember,
      () => {
        initializeState();
      }
    );

    return {
      closeFleetDetail,
      displayName,
      initDropDowns,
      saveChanges,
      truckChanged,
      trailerChanged,
      affiliationWasModified,
      truckPreview,
      truckPreviewValue,
      truckLoading,
      trailerPreview,
      trailerPreviewValue,
      trailerLoading,
      isLoading,
      affiliation,
      errors
    };
  }
});
</script>

<style lang="scss"></style>
