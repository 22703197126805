import { BoardMembership, FleetEntity } from "@/types/fleet";
import { BoardTimespan } from "@/types/board";

const filterBoardTypes = (filterVal: string, fleetEntity: FleetEntity): BoardMembership[] => {
  return fleetEntity.boards.filter((board: BoardMembership) => (board && board.boardType) === filterVal);
};

const getLatestDateOnBoards = (boards: BoardMembership[]): BoardTimespan => {
  return boards.reduce((a: any, b: any) => {
    return new Date(a.from) > new Date(b.from) ? a : b;
  });
};

export { filterBoardTypes, getLatestDateOnBoards };
