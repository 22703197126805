




































import { defineComponent, ref, PropType } from "@vue/composition-api";
import { BoardLeg, LegSize, DisplayLegContent, DisplayLegStatuses, LegCallbacks } from "@/types/leg";
import Icon from "@/components/Icon.vue";
import LegTransportStatusIndicator from "@/components/Board/Leg/LegTransportStatusIndicator/LegTransportStatusIndicator.vue";
import ClickOutside from "vue-click-outside";
import ClosablePopover from "@/components/ClosablePopover.vue";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import CalloutLegTrailer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegTrailer.vue";
import CalloutLegDriver from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDriver.vue";
import TTALink from "@/components/Board/Leg/TTALink/TTALink.vue";
import LegBreakpointXL from "@/components/Board/Leg/LegBreakpoints/LegBreakpointXL.vue";
import LegAffiliationPopover from "@/components/Board/Leg/LegAffiliationPopover/LegAffiliationPopover.vue";

export default defineComponent({
  name: "FullLegPreviewPopover",
  components: {
    Icon,
    LegTransportStatusIndicator,
    CalloutLegContainer,
    CalloutLegDriver,
    CalloutLegTrailer,
    ClosablePopover,
    LegBreakpointXL,
    TTALink,
    LegAffiliationPopover
  },
  directives: { ClickOutside },
  props: {
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    legData: {
      type: Object as PropType<BoardLeg>,
      required: true
    },
    legStatuses: {
      type: Object as () => DisplayLegStatuses,
      required: true
    },
    legActionCallbacks: {
      type: Object as () => LegCallbacks,
      required: true
    },
    onTouchStart: {
      type: Function,
      default: () => ({})
    }
  },
  setup() {
    const isVisible = ref<boolean>(false);

    const largeLegSize = ref<LegSize>({
      xs: false,
      sm: false,
      md: false,
      lg: true,
      xl: true
    });

    // delay in seconds
    const POPOVER_OPEN_DELAY = 1;
    const POPOVER_CLOSE_DELAY = 5;

    const onClickOutside = () => {
      isVisible.value = false;
    };

    return {
      POPOVER_OPEN_DELAY,
      POPOVER_CLOSE_DELAY,
      onClickOutside,
      isVisible,
      largeLegSize
    };
  }
});
