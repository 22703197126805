<template>
  <div v-show="isActive">
    <div tabindex="0" :class="dynamicClass" class="dialog absolute focus:outline-none mt-2 z-50">
      <div class="dialog__header mb-8">
        <span class="font-semibold text-xl">{{ title }}</span>
        <div tabindex="0" @keydown.enter="closeHandler" @click="closeHandler">
          <Icon name="close" class="cursor-pointer" />
        </div>
      </div>
      <div v-if="message" class="max-w-xs">
        {{ message }}
      </div>
      <slot />
      <div v-if="showButtons" class="flex justify-between mt-8">
        <Button @click="closeHandler" variant="secondary" class="mr-4">{{ $t("actions.cancel") }}</Button>
        <Button @click="confirmHandler">{{ $t("actions.confirm") }}</Button>
      </div>
    </div>
    <Overlay @clickHandler="closeHandler" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted } from "@vue/composition-api";
import Overlay from "@/components/Overlay.vue";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Buttons/Button.vue";
import useScrollPosition from "@/use/useScrollPosition";
export default defineComponent({
  name: "Dialog",
  components: { Overlay, Icon, Button },
  props: {
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    disableScroll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { toggleScrollFixed } = useScrollPosition();

    const dynamicClass = computed(() => (props.isCentered ? "dialog--isCentered" : ""));

    const confirmHandler = () => {
      emit("confirm");
      closeHandler();
    };

    const closeHandler = () => {
      emit("closeDialog");
    };

    const dialogEscapeHandler = (e: any) => {
      if (e.key === "Escape") {
        e.stopPropagation(); //stops firering other listeners on esc key
        closeHandler();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", dialogEscapeHandler, {
        capture: true,
        once: true
      });
      if (props.disableScroll) {
        toggleScrollFixed();
      }
    });

    onUnmounted(() => {
      if (props.disableScroll) {
        toggleScrollFixed();
      }
    });

    return {
      dynamicClass,
      closeHandler,
      confirmHandler
    };
  }
});
</script>

<style scoped>
.dialog {
  @apply shadow-lg px-4 pt-2 pb-10 bg-white z-50 border-btYellow border-t-4;
}
.dialog--isCentered {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}
.dialog__header {
  @apply flex justify-between;
}
</style>
