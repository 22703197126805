var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cursor-pointer flex justify-center relative ml-1",
      on: { click: _vm.openChatHub }
    },
    [
      _c("Icon", {
        staticClass: "hover:text-btYellow",
        attrs: {
          name: "chat",
          width: _vm.iconWidth ? _vm.iconWidth : "25",
          height: _vm.iconWidth ? _vm.iconWidth : "25"
        }
      }),
      _vm.totalUnreadMessages > 0
        ? _c(
            "div",
            {
              staticClass:
                " px-1 text-sm text-white rounded-full text-center absolute -right-2 -bottom-2 notification"
            },
            [_vm._v(" " + _vm._s(_vm.totalUnreadMessages) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }