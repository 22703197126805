





































































import { defineComponent, PropType, ref, onMounted } from "@vue/composition-api";
import { Subcontractor } from "@/types/fleet";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import Icon from "@/components/Icon.vue";
import fleetService from "@/services/fleetService";
import { spreadSubcontractorId } from "@/use/useFleet";
import store from "@/store";
import { FETCH_BOARD_FLEET, LOCK_SUBCONTRACTOR, UNLOCK_SUBCONTRACTOR } from "@/store/fleet/";
import { translateAndToastError, translateAndToastSuccess } from "@/use/useToast";
import { SubcontractorBoardMembership } from "@/types/fleet";

export default defineComponent({
  name: "SubcontractorDetail",
  components: { SplitScreen, Button, Icon },
  props: {
    subcontractor: {
      type: Object as PropType<Subcontractor>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const subcontractorAffiliations = ref<SubcontractorBoardMembership[]>([]);
    const { id, boardId } = spreadSubcontractorId(props.subcontractor.id);

    onMounted(async () => {
      await store.dispatch(LOCK_SUBCONTRACTOR, id);
      subcontractorAffiliations.value = props.subcontractor?.boards
        .filter(board => board.boardId === boardId)
        .map(board => ({ ...board, from: undefined, to: undefined }))
        .sort((b1, b2) => b1.sequence - b2.sequence);
    });

    const addDetail = (): void => {
      subcontractorAffiliations.value.push({
        boardId: boardId,
        sequence: subcontractorAffiliations.value.length + 1,
        truckName: undefined,
        driverName: undefined
      });
    };

    const removeDetail = (): void => {
      subcontractorAffiliations.value.pop();
    };

    const closeDetails = () => {
      try {
        store.dispatch(UNLOCK_SUBCONTRACTOR, id);
        emit("close");
      } catch (e) {
        // pass
      }
    };

    const saveDetails = async () => {
      try {
        const { name, drivers, role } = props.subcontractor;
        const restAffiliations = props.subcontractor.boards
          .filter(board => board.boardId !== boardId)
          .map(board => ({ ...board, from: undefined, to: undefined }));
        const sanitizedSubiAffiliations = subcontractorAffiliations.value.map(affiliation => {
          const { truckName, driverName } = affiliation;
          return {
            ...affiliation,
            truckName: truckName === "" ? undefined : truckName,
            driverName: driverName === "" ? undefined : driverName
          };
        });
        const boards = [...sanitizedSubiAffiliations, ...restAffiliations];
        await fleetService.updateSubcontractor(id, {
          id,
          name,
          drivers,
          role: role,
          boards
        } as Subcontractor);
        await store.dispatch(FETCH_BOARD_FLEET);
        translateAndToastSuccess("subcontractor.updated");
      } catch (e) {
        translateAndToastError("subcontractor.updateFailed");
      }
      await store.dispatch(UNLOCK_SUBCONTRACTOR, id);
      emit("close");
    };

    return { subcontractorAffiliations, addDetail, removeDetail, closeDetails, saveDetails, boardId };
  }
});
