var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isActive,
          expression: "isActive"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog absolute focus:outline-none mt-2 z-50",
          class: _vm.dynamicClass,
          attrs: { tabindex: "0" }
        },
        [
          _c("div", { staticClass: "dialog__header mb-8" }, [
            _c("span", { staticClass: "font-semibold text-xl" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c(
              "div",
              {
                attrs: { tabindex: "0" },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.closeHandler.apply(null, arguments)
                  },
                  click: _vm.closeHandler
                }
              },
              [
                _c("Icon", {
                  staticClass: "cursor-pointer",
                  attrs: { name: "close" }
                })
              ],
              1
            )
          ]),
          _vm.message
            ? _c("div", { staticClass: "max-w-xs" }, [
                _vm._v(" " + _vm._s(_vm.message) + " ")
              ])
            : _vm._e(),
          _vm._t("default"),
          _vm.showButtons
            ? _c(
                "div",
                { staticClass: "flex justify-between mt-8" },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "mr-4",
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeHandler }
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                  ),
                  _c("Button", { on: { click: _vm.confirmHandler } }, [
                    _vm._v(_vm._s(_vm.$t("actions.confirm")))
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c("Overlay", { on: { clickHandler: _vm.closeHandler } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }