var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ]
    },
    [
      _c("a-time-picker", {
        ref: "timepicker",
        staticClass: "w-full",
        attrs: {
          size: "large",
          format: "HH:mm",
          open: _vm.isOpen,
          "allow-clear": false,
          defaultValue: _vm.defaultTime,
          getPopupContainer: _vm.addPopupToParent
        },
        on: {
          focus: _vm.onFocus,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.onTab.apply(null, arguments)
          }
        },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }