<template>
  <div @click="clickHandler" class="cursor-pointer">
    <Icon name="copy" :width="width" :height="height" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import { copyTextToClipBoard } from "@/use/useText";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "CopyToClipboard",
  components: { Icon },
  props: {
    text: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const clickHandler = () => {
      copyTextToClipBoard(props.text);
    };
    return {
      clickHandler
    };
  }
});
</script>

<style scoped></style>
