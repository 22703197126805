



























































import { computed, defineComponent, toRefs } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import { FleetAction } from "@/types/action";

export default defineComponent({
  name: "CalloutSidebarAction.vue",
  components: {
    Icon
  },
  props: {
    actions: {
      type: Array as () => Array<FleetAction>,
      default: []
    }
  },
  setup(props, { emit }) {
    const { actions } = toRefs(props);

    const sortedActions = computed(() => actions.value.sort(getActionSortingOrder));

    const getEntityOrdinal = (action: FleetAction): number => {
      const { truckId, trailerId, driverId } = action;
      if (truckId) {
        return 1;
      }
      if (trailerId) {
        return 2;
      }
      if (driverId) {
        return 3;
      }
      return 100;
    };

    const getActionSortingOrder = (action1: FleetAction, action2: FleetAction): number => {
      return getEntityOrdinal(action1) - getEntityOrdinal(action2);
    };

    const equipmentId = (action: FleetAction): string => {
      if (action.truckId) {
        return action.truckId;
      }
      if (action.driverId) {
        return `${action.firstName} ${action.lastName}`;
      }
      if (action.trailerId) {
        return action.trailerId;
      }
      return "";
    };

    const openFleetAction = (actionItem: FleetAction) => {
      emit("onFleetAction", {
        ...actionItem,
        payloadType: "action"
      });
    };

    return {
      equipmentId,
      openFleetAction,
      dateStringToShowDate,
      dateStringToShowTime,
      sortedActions
    };
  }
});
