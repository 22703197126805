import { getUnixTime, parseISO } from "date-fns";
import { FleetEntityTest, TrailerMetadata, TruckMetaData } from "@/types/fleet";
import { i18n } from "@/i18n/i18n";
import { dateStringToShowDate } from "@/use/useDate";

type FleetTest = {
  name: string;
  timestamp: number;
};

const fleetEntityTests = [
  "nextADRTest",
  "nextBrakeTest",
  "nextHoseTest",
  "nextHydraulicHosesTest",
  "nextMajorTest",
  "nextSafetyTest",
  "nextTachoTest",
  "nextRotaryTest"
];

const sortTestsAsc = (data: TruckMetaData | TrailerMetadata): FleetTest[] => {
  return fleetEntityTests
    .filter(testType => data[testType] != null)
    .map(testType => ({
      name: testType,
      timestamp: getUnixTime(parseISO(data[testType]))
    }))
    .sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
};

const getNextInspectionDate = (data: TruckMetaData | TrailerMetadata): FleetEntityTest | undefined => {
  return getNextInspectionDetails(data)[0];
};

const getNextInspectionDetails = (data: TruckMetaData | TrailerMetadata): FleetEntityTest[] => {
  return sortTestsAsc(data).map(test => ({
    name: i18n.t(`fleetTests.${test.name}`).toString(),
    date: dateStringToShowDate(data[test.name]),
    isDateInPast: test.timestamp < getUnixTime(new Date())
  }));
};

export { getNextInspectionDate, getNextInspectionDetails };
