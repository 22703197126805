




























































import { defineComponent, ref, computed, onMounted } from "@vue/composition-api";
import { Trailer } from "@/types/fleet";
import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import fleetService from "@/services/fleetService";
import store from "@/store";
import { GET_TRAILER_BY_ID } from "@/store/fleet";
import { stringifyTrailerEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "CalloutLegTrailer",
  components: { Icon },
  props: {
    title: {
      type: String,
      default: ""
    },
    trailerId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const trailerData = ref<Trailer | null>(null);

    const trailerDataFromStore = computed(async () => {
      return await store.dispatch(GET_TRAILER_BY_ID, props.trailerId);
    });
    onMounted(async () => {
      trailerData.value = await trailerDataFromStore.value;
      if (trailerData.value == null) {
        getTrailerData();
      }
    });

    const getTrailerData = async () => {
      try {
        const { data } = await fleetService.getTrailerById(props.title);
        trailerData.value = data;
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const nextInspectionDate = computed(() => getNextInspectionDate(trailerData.value!.metadata));

    return {
      getNextInspectionDate,
      stringifyTrailerEquipment,
      trailerData,
      trailerDataFromStore,
      nextInspectionDate
    };
  }
});
