<template>
  <div>
    <div v-if="legType.collection && selectedLeg.events.loadingSlotFrom && selectedLeg.events.loadingSlotTo">
      {{ dateStringToShowTime(selectedLeg.events.loadingSlotFrom) }}
      -
      {{ dateStringToShowTime(selectedLeg.events.loadingSlotTo) }}
    </div>
    <div v-else-if="legType.collection && selectedLeg.events.loadingSlotFrom">
      {{ dateStringToShowTime(selectedLeg.events.loadingSlotFrom) }}
    </div>
    <div v-else-if="legType.delivery && selectedLeg.events.unloadingSlotFrom && selectedLeg.events.unloadingSlotTo">
      {{ dateStringToShowTime(selectedLeg.events.unloadingSlotFrom) }}
      -
      {{ dateStringToShowTime(selectedLeg.events.unloadingSlotTo) }}
    </div>
    <div v-else-if="legType.delivery && selectedLeg.events.unloadingSlotFrom">
      {{ dateStringToShowTime(selectedLeg.events.unloadingSlotFrom) }}
    </div>
    <div v-if="(legType.dropSwap || legType.fullTransport) && selectedLeg.events.loadingSlotFrom">
      L:
      {{ dateStringToShowTime(selectedLeg.events.loadingSlotFrom) }}
      <span v-if="selectedLeg.events.loadingSlotTo"
        >- {{ dateStringToShowTime(selectedLeg.events.loadingSlotTo) }}</span
      >
    </div>
    <div v-if="(legType.dropSwap || legType.fullTransport) && selectedLeg.events.unloadingSlotFrom">
      U:
      {{ dateStringToShowTime(selectedLeg.events.unloadingSlotFrom) }}
      <span v-if="selectedLeg.events.unloadingSlotTo">
        -
        {{ dateStringToShowTime(selectedLeg.events.unloadingSlotTo) }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "@vue/composition-api";
import { BoardLeg } from "@/types/leg";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import { dateStringToShowTime } from "@/use/useDate";

export default defineComponent({
  name: "LegDetailTimeSlot",
  props: {
    selectedLeg: {
      type: Object as () => BoardLeg
    }
  },
  setup(props) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legType } = useLegStatus(selectedLegRef);

    return {
      legType,
      dateStringToShowTime
    };
  }
});
</script>

<style scoped></style>
