import { Leg } from "@/types/leg";
import { FleetAction } from "@/types/action";
import { Trailer } from "./fleet";
import { Chat } from "@/types/chat";

interface WebSocketMessage {
  messageType: string;
  messageContent: Leg | FleetAction | Trailer | Chat;
  messageAction: WebSocketMessageAction;
}

enum WebSocketMessageAction {
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export { WebSocketMessage, WebSocketMessageAction };
