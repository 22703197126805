var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      staticClass: "popover",
      attrs: {
        trigger: "click",
        destroyTooltipOnHide: true,
        align: _vm.align,
        placement: _vm.placement,
        overlayStyle: _vm.overlayStyle,
        defaultVisible: _vm.defaultVisible
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _c("div", { staticClass: "popover__header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close-btn ml-auto w-max mt-1",
                      on: { click: _vm.closePopover }
                    },
                    [
                      _c("Icon", {
                        staticClass:
                          "cursor-pointer text-black hover:text-yellow-500",
                        attrs: { tabindex: "0", name: "close" }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("div", {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.onClickOutside,
                      expression: "onClickOutside"
                    }
                  ]
                }),
                _vm._t("popoverContent")
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.isPopoverVisible,
        callback: function($$v) {
          _vm.isPopoverVisible = $$v
        },
        expression: "isPopoverVisible"
      }
    },
    [_c("div", [_vm._t("popoverTrigger")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }