var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center relative z-40" },
    [
      _vm.isEditMode
        ? _c(
            "a-select",
            {
              staticClass: "ml-2 relative ",
              staticStyle: { width: "300px" },
              attrs: {
                dropdownClassName: "fixed",
                placeholder: _vm.$t("transportInfoEditor.selectStatus")
              },
              on: { change: _vm.bulkOptionChanged }
            },
            _vm._l(_vm.bulkOptions, function(option, index) {
              return _c(
                "a-select-opt-group",
                {
                  key: index,
                  attrs: {
                    label: _vm.$t("transportInfoEditor.options." + option.type)
                  }
                },
                _vm._l(option.values, function(value, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: option.type + "," + value } },
                    [
                      option.type === "status"
                        ? _c("div", {
                            class: "statusBox statusBox--" + value.toLowerCase()
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "transportInfoEditor.options." + option.type
                                )
                              ) + " - "
                            )
                          ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "transportInfoEditor.values." +
                                value.toLowerCase()
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                }),
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { on: { click: _vm.toggleEditMode } },
        [
          !_vm.isEditMode
            ? _c(
                "a-tooltip",
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("editorMode")) + " ")
                  ]),
                  _c("icon", {
                    staticClass:
                      "text-gray-300 hover:text-btYellow cursor-pointer",
                    attrs: { name: "edit", height: "25", width: "25" }
                  })
                ],
                2
              )
            : _c("icon", {
                staticClass: " text-black cursor-pointer hover:text-btRed",
                attrs: { name: "close", height: "25", width: "25" }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }