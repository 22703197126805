/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heating': {
    width: 640,
    height: 512,
    viewBox: '0 0 169.333 135.467',
    data: '<path pid="0" d="M37.961-.087c-8.611 0-15.618 7.006-15.618 15.618v72.068c-4.143 4.694-10.355 10.444-10.41 21.5-.074 14.276 11.57 26.067 25.84 26.166h.188c14.353 0 26.03-11.676 26.03-26.029 0-11.172-6.303-16.98-10.412-21.637V15.53c0-8.612-7.007-15.618-15.618-15.618zm.241 8.152a8.212 8.212 0 018.212 8.212v79.216c4.908 2.84 8.212 8.143 8.212 14.22 0 9.07-7.353 16.424-16.424 16.424-9.07 0-16.423-7.353-16.423-16.423 0-6.078 3.304-11.381 8.212-14.221V16.277a8.211 8.211 0 018.211-8.212z"/><text style="line-height:1.25" x="274.057" y="238.826" font-weight="400" font-size="25.363" font-family="sans-serif" letter-spacing="0" word-spacing="0" _fill="#fff" stroke-width=".634" transform="translate(0 -161.533)"><tspan x="274.057" y="238.826" style="-inkscape-font-specification:\'sans-serif Bold\'" font-weight="700">Klötzli</tspan></text><path pid="1" d="M122.684 41.84v88.551a3.52 3.52 0 003.52 3.52h16.423a3.52 3.52 0 003.52-3.52V41.84h13.507c6.27 0 9.411-7.581 4.977-12.016L139.392 4.586a7.038 7.038 0 00-9.954 0L104.2 29.824c-4.435 4.434-1.294 12.016 4.977 12.016zM58.471 44.702h30.348v12.3H58.47zM58.471 18.69h30.348v12.3H58.47zM58.471 70.714h30.348v12.3H58.47z"/>'
  }
})
