var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-64 h-28 p-2",
      attrs: { slot: "popover" },
      slot: "popover"
    },
    [
      _c("div", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("popup.dropChallenge", { date: _vm.plannedDate })
          )
        }
      }),
      _c("div", { staticClass: "w-full mt-4" }, [
        _c(
          "div",
          { staticClass: "flex justify-center" },
          [
            _c(
              "Button",
              {
                staticClass: "CalloutLegDrop__button--cancel",
                attrs: { variant: "primary h-7 w-24 p-0 mr-4" },
                on: { click: _vm.onCancel }
              },
              [_vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")]
            ),
            _c(
              "Button",
              {
                staticClass: "CalloutLegDrop__button--allow",
                attrs: { variant: "secondary h-7 w-24 p-0" },
                on: { click: _vm.onAllow }
              },
              [_vm._v(" " + _vm._s(_vm.$t("actions.allow")) + " ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }