/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no-adr': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="-125.736" cy="-12.523" _fill="rgba(225,27,27,0.6)" transform="translate(137.754 24.5)" r="12"/><circle pid="1" cx="-139.707" cy="-13.915" _fill="#FDFDFD" transform="matrix(.9 0 0 .9 137.754 24.5)" r="10"/><path pid="2" _fill="rgba(5,0,0,0.5)" d="M4.053 6.254h16.129V17.83H4.053z"/><path pid="3" _fill="#FFFDFC" d="M5.143 7.236h13.75v9.481H5.143z"/><path pid="4" _fill="rgba(244,122,5,0.69)" d="M5.143 7.236h13.75v9.481H5.143z"/>'
  }
})
