var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "boardTimeLineHeader w-full bg-white h-28 flex flex-col fixed top-0 mt-28",
      class: { "bg-yellow-50": _vm.isBoardEditModeActive },
      style: _vm.styledObject
    },
    [
      _c("div", { staticClass: "flex-grow" }, [_c("Ticker")], 1),
      _c(
        "div",
        { staticClass: "h-8" },
        [
          _c("BoardTimeBoxes", { attrs: { "width-in-hours": 1, header: true } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h-8" },
        [_c("BoardTimeBoxes", { attrs: { "width-in-hours": 1 } })],
        1
      ),
      _vm.isBoardEditModeActive || _vm.fleetIsLoading
        ? _c("div", {
            staticClass:
              "h-28 w-full bg-black fixed top-100 left-200 z-40 opacity-20"
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }