<template>
  <div class="w-12">
    <div v-if="legData.details.ttaLink" class="cursor-pointer">
      <a :href="legData.details.ttaLink" target="_blank" class="text-black">
        <Icon v-if="legData.details.flexible" name="truck-flexible" class="leg__truck" />
        <Icon v-if="!legData.details.flexible" name="truck" class="leg__truck" />
      </a>
    </div>

    <div v-else>
      <Icon v-if="legData.details.flexible" name="truck-flexible" class="leg__truck" />
      <Icon v-if="!legData.details.flexible" name="truck" class="leg__truck" />
    </div>
  </div>
</template>

<script lang="ts">
import { BoardLeg } from "@/types/leg";
import { defineComponent, PropType } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "TTALink",
  components: { Icon },
  props: {
    legData: {
      type: Object as PropType<BoardLeg>,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
@use "../Leg.scss";
</style>
