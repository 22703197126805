











































































































import { computed, ref, defineComponent, onMounted } from "@vue/composition-api";
import { dateStringToShowDate } from "@/use/useDate";
import CopyToClipboard from "@/components/CopyToClipboard.vue";
import Icon from "@/components/Icon.vue";
import { i18n } from "@/i18n/i18n";
import { BaffleTypes, DipTubeTypes, GroundOperatedContainerTypes, IsoDimensionTypes } from "@/types/container";
import { LegContainer } from "@/types/leg";
import { Container, Trailer, TrailerLastLocation, LastLocationInfo } from "@/types/fleet";
import containerService from "@/services/containerService";
import { getLastLocationContainerId } from "@/use/useLastLocation";

export default defineComponent({
  name: "CalloutLegContainer",
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: Object as () => LegContainer | Trailer, //LastLocationInfo,
      required: true
    }
  },
  components: { CopyToClipboard, Icon },
  setup(props) {
    const container = ref<Container | LegContainer | undefined>(undefined);

    const loading = ref<boolean>(true);

    onMounted(() => {
      loading.value = true;
      const containerId =
        (props.content as LegContainer)?.containerId ||
        getLastLocationContainerId((props.content as Trailer)?.metadata?.lastLocation);
      if (props.content && containerId) {
        getContainer(containerId);
      } else {
        container.value =
          ((props.content as Trailer)?.metadata?.lastLocation?.leg?.container as LegContainer) ||
          (props?.content as LegContainer);
        loading.value = false;
      }
    });

    const getContainer = async (containerId: string) => {
      try {
        const data = await containerService.getSingleContainer(containerId);
        container.value = data.data;
        loading.value = false;
      } catch (e) {
        // unhandled global
        loading.value = false;
      }
    };

    const hasBaffles = (baffles?: string): boolean => {
      return baffles != null && baffles !== BaffleTypes.NO;
    };

    const hasGroundOperatedContainer = (goc?: string): boolean => {
      return goc != null && goc !== GroundOperatedContainerTypes.NO;
    };

    const hasIsoDimension = (iso?: string): boolean => {
      return iso != null && iso !== IsoDimensionTypes.NO;
    };

    const hasHeating = (heating?: string): boolean => {
      return !!heating;
    };

    const hasDipTube = (dipTube?: string): boolean => {
      return dipTube != null && dipTube !== DipTubeTypes.NO;
    };

    const hasAnchorChain = (anchorChain?: string): boolean => {
      return !!anchorChain;
    };

    const getBafflesInfo = (): string => {
      return i18n.t("leg.container.baffles").toString();
    };

    const getGroundOperatedContainerInfo = (goc: string): string => {
      if (goc === GroundOperatedContainerTypes.GO1) return GroundOperatedContainerTypes.GOC1;
      if (goc === GroundOperatedContainerTypes.GO2) return GroundOperatedContainerTypes.GOC2;
      return GroundOperatedContainerTypes.GOC;
    };

    const getHeatingInfo = (heatingMethod: string): string => {
      return i18n.t("leg.container.heatingMethod", { method: heatingMethod }).toString();
    };

    const getDipTubeInfo = (dipTube: string): string => {
      if (dipTube === DipTubeTypes.YES) {
        return i18n.t("leg.container.dipTube").toString();
      }
      if (dipTube === DipTubeTypes.POSSIBLE) {
        return i18n.t("leg.container.dipTubePossible").toString();
      }
      return "";
    };

    const getIsoDimensionInfo = (): string => {
      return IsoDimensionTypes.GLISO;
    };

    const getAnchorChainInfo = (): string => {
      return i18n.t("leg.container.anchorChain").toString();
    };

    const specialEquipment = computed<string>(() => {
      const equipment: string[] = [];
      const { baffles, groundOperatedContainer, heatingMethod, dipTube, isoDimension, anchorChain } =
        container?.value?.metadata || {};

      if (hasBaffles(baffles)) {
        equipment.push(getBafflesInfo());
      }

      if (hasGroundOperatedContainer(groundOperatedContainer)) {
        equipment.push(getGroundOperatedContainerInfo(groundOperatedContainer!));
      }

      if (hasHeating(heatingMethod)) {
        equipment.push(getHeatingInfo(heatingMethod!));
      }

      if (hasDipTube(dipTube)) {
        equipment.push(getDipTubeInfo(dipTube!));
      }

      if (hasIsoDimension(isoDimension)) {
        equipment.push(getIsoDimensionInfo());
      }

      if (hasAnchorChain(anchorChain)) {
        equipment.push(getAnchorChainInfo());
      }

      return equipment.join(", ");
    });

    const chamberVolumes = computed(() => {
      const { chamberVolumes = [] } = container?.value?.metadata || {};
      return chamberVolumes.map(vol => `${vol} l`).join(", ");
    });

    const nextAdrTest = computed<string | undefined>(() => {
      const { nextAdrTest, nextAdrMinorTest } = container?.value?.metadata || {};
      if (nextAdrTest || nextAdrMinorTest) {
        return dateStringToShowDate(nextAdrTest! || nextAdrMinorTest!);
      }
      return undefined;
    });

    return { dateStringToShowDate, specialEquipment, chamberVolumes, nextAdrTest, container, loading };
  }
});
