var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SplitScreen", {
        attrs: {
          isActive: _vm.isActive,
          isLoading: _vm.isLoading,
          "custom-class": "w-1/3 z-40"
        },
        on: { closeHandler: _vm.closeBoardSettings },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("span", { staticClass: "font-extrabold" }, [
                  _vm._v(_vm._s(_vm.$t("settings.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "pt-4" },
                  [
                    _c(
                      "a-collapse",
                      {
                        staticClass: "bg-gray-100 ant-collapse--single mb-4",
                        attrs: { "default-active-key": "1", bordered: false }
                      },
                      [
                        _c(
                          "a-collapse-panel",
                          {
                            key: "1",
                            staticClass: "text-xl bg-gray-100 ",
                            attrs: { header: _vm.$t("showWeekend") }
                          },
                          [
                            _c("ShowWeekend", {
                              on: { isLoading: _vm.isLoadingHandler }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-collapse",
                      {
                        staticClass: "bg-gray-100 ant-collapse--single mb-4",
                        attrs: { "default-active-key": "1", bordered: false }
                      },
                      [
                        _c(
                          "a-collapse-panel",
                          {
                            key: "1",
                            staticClass: "text-xl bg-gray-100 ",
                            attrs: { header: _vm.$t("settings.timeSpan") }
                          },
                          [
                            _c("TimeSelectorDialog", {
                              staticClass: "mt-2",
                              attrs: { showDialog: false },
                              on: { isLoading: _vm.isLoadingHandler }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-collapse",
                      {
                        staticClass: "bg-gray-100 ant-collapse--single mb-4",
                        attrs: { "default-active-key": "1", bordered: false }
                      },
                      [
                        _c(
                          "a-collapse-panel",
                          {
                            key: "1",
                            staticClass:
                              "navSettings__languageSelector text-xl bg-gray-100 ",
                            attrs: { header: _vm.$t("settings.language") }
                          },
                          [_c("language-switcher", { staticClass: "mt-2" })],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-collapse",
                      {
                        staticClass: "bg-gray-100 ant-collapse--single",
                        attrs: { "default-active-key": "1", bordered: false }
                      },
                      [
                        _c(
                          "a-collapse-panel",
                          {
                            key: "1",
                            staticClass: "text-xl bg-gray-100",
                            attrs: { header: _vm.$t("settings.defaultDate") }
                          },
                          [
                            _c("default-date", {
                              on: { isLoading: _vm.isLoadingHandler }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "actionBar",
            fn: function(ref) {
              var close = ref.close
              return [
                _c("div", { staticClass: "w-full flex" }, [
                  _c(
                    "div",
                    { staticClass: "ml-auto flex" },
                    [
                      _c(
                        "Button",
                        {
                          staticClass: "mr-4",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function($event) {
                              return close()
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("actions.close")))])]
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }