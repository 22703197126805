var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasDifferentDriver ||
    _vm.hasDifferentTrailer ||
    _vm.hasSubiWithPrimaryBoard
    ? _c(
        "div",
        { staticClass: "absolute bottom-0 right-0 leg" },
        [
          _c("ClosablePopover", {
            attrs: {
              placement: "top",
              overlayStyle: {
                maxWidth: "500px",
                width: "500px",
                height: "260px"
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "popoverContent",
                  fn: function() {
                    return [
                      _vm.hasDifferentTrailer
                        ? _c("CalloutLegTrailer", {
                            attrs: {
                              title: _vm.legContent.trailerId,
                              "trailer-id": _vm.legContent.trailerId
                            }
                          })
                        : _vm._e(),
                      _vm.hasDifferentDriver
                        ? _c("CalloutLegDriver", {
                            attrs: { "driver-id": _vm.legContent.driverId }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "popoverTrigger",
                  fn: function() {
                    return [
                      _c("div", {
                        class: {
                          "indicator--both leg_callout":
                            _vm.hasDifferentDriver && _vm.hasDifferentTrailer,
                          "indicator--blue leg_callout": _vm.hasDifferentDriver,
                          "indicator--brown leg_callout":
                            _vm.hasDifferentTrailer,
                          indicator: !_vm.isViewedInPopover,
                          "indicator-popover leg_callout": _vm.isViewedInPopover
                        }
                      }),
                      _vm.legSize.xl && _vm.hasDifferentDriver
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "cursor-pointer border-b border-gray-400 hover:border-yellow-500 mb-2 mr-3.5 inline-block",
                              on: { touchstart: _vm.onTouchStartHandler }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.legContent.driverId) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.legSize.xl && _vm.hasDifferentTrailer
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "cursor-pointer border-b border-gray-400 hover:border-yellow-500 mb-2 mr-3.5 inline-block",
                              on: { touchstart: _vm.onTouchStartHandler }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.legContent.trailerId) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1678513458
            )
          }),
          _vm.legSize.xl && _vm.hasSubiWithPrimaryBoard
            ? _c(
                "span",
                { staticClass: "cursor-pointer mb-2 mr-3.5 inline-block" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.legContent.subcontractor.subconDriverName) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.legSize.xl &&
          _vm.hasSubiWithPrimaryBoard &&
          _vm.legContent.trailerId
            ? _c(
                "span",
                { staticClass: "cursor-pointer mb-2 mr-3.5 inline-block" },
                [_vm._v(" " + _vm._s(_vm.legContent.trailerId) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }