
















import { defineComponent } from "@vue/composition-api";

import Button from "@/components/Buttons/Button.vue";

export default defineComponent({
  name: "CalloutLegDrop",
  components: {
    Button
  },
  props: {
    plannedDate: {
      type: String,
      default: ""
    },
    actualDate: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const onCancel = () => {
      emit("dropCancelled");
    };
    const onAllow = () => {
      emit("dropAllowed");
    };

    return {
      onCancel,
      onAllow
    };
  }
});
