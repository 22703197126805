var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-collapse",
        {
          staticClass: "bg-gray-100 ant-collapse--single",
          attrs: { bordered: false, defaultActiveKey: _vm.defaultActiveKey }
        },
        [
          _c(
            "a-collapse-panel",
            {
              key: _vm.collapsePanelKey,
              staticClass: "text-xl bg-gray-100 text-red-400",
              attrs: { header: _vm.$t("previousLeg.title") }
            },
            [
              _c("div", { staticClass: "text-base mt-2" }, [
                _c("div", { staticClass: "grid grid-cols-4 gap-y-8" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.legType")))
                    ]),
                    _c("div", [
                      _vm._v(_vm._s(_vm.data.legType || _vm.fallbackText))
                    ])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("ranges.from")))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.from))])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("ranges.toLocation")))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.to))])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.vehicleNumber")))
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.data.intermodalVehicleNumber || _vm.DEFAULT_TEXT
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.bookingNumber")))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.bookingId))])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.departureDate")))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.departureDate))])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.arrivalDate")))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.arrivalDate))])
                  ]),
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c("div", { staticClass: "text-gray-400 font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("previousLeg.irregularities")))
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.data.hasIntermodalIrregularities
                            ? _vm.data.irregularityDescription
                            : _vm.$t("answers.no")
                        )
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }