<template>
  <div class="w-72">
    <a-config-provider :locale="locale">
      <a-range-picker
        v-model="dateRange"
        id="board-header-date-picker"
        inputReadOnly
        size="large"
        format="DD.MM.YYYY"
        dropdownClassName="z-50"
        separator="-"
        :allow-clear="false"
        :disabled-date="disableWeekends"
        :getCalendarContainer="trigger => trigger.parentNode"
        @change="updateDates"
        @openChange="onOpenChange"
        @calendarChange="onCalendarChange"
      >
        <template #suffixIcon>
          <a-icon type="calendar" />
        </template>
      </a-range-picker>
    </a-config-provider>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store";
import moment from "moment";
import {
  GET_LANGUAGE,
  GET_SHOW_WEEKENDS,
  INIT_FROM_TO_DATES,
  IS_DEFAULT_DATE_ACTIVE,
  UPDATE_CONFIG_PROFILE,
  UPDATE_FROM_TO_DATES
} from "@/store/board/";
import { CLEAR_FLEET, FETCH_BOARD_FLEET } from "@/store/fleet/";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { setDateHoursToZero } from "@/use/useDate";
import "moment/locale/de";
import { getDatePickerLocale } from "@/i18n/i18nUtils";

export default defineComponent({
  name: "boardDateSelector",
  components: { Icon },
  setup() {
    store.dispatch(INIT_FROM_TO_DATES);
    const fromDate = computed(() => store.state.board.fromDate);
    const toDate = computed(() => store.state.board.toDate);
    const isDefaultDateActive = computed(() => store.getters[IS_DEFAULT_DATE_ACTIVE]);
    const showWeekends = computed(() => store.getters[GET_SHOW_WEEKENDS]);

    const dateRange = ref([moment(fromDate.value), moment(toDate.value)]);
    const dateSelectionLocal = ref<moment.Moment[]>([]);

    const lang = computed(() => store.getters[GET_LANGUAGE]);
    const locale = ref(getDatePickerLocale(lang.value));

    onMounted(() => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch(lang, () => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch([fromDate, toDate], () => {
      dateRange.value = [moment(fromDate.value), moment(toDate.value)];
    });

    const disableWeekends = (current: any) => {
      // if no start date was selected yet
      if (!dateSelectionLocal.value || dateSelectionLocal.value.length === 0) {
        if (!showWeekends.value) {
          return moment(current).day() === 0 || moment(current).day() === 6;
        }
        return false;
      }
      // if start date was selected
      const diff = current.diff(dateSelectionLocal.value[0], "days");
      if (!showWeekends.value) {
        return moment(current).day() === 0 || moment(current).day() === 6 || Math.abs(diff) > 31;
      }
      return Math.abs(diff) > 31;
    };

    const updateDates = async () => {
      if (
        setDateHoursToZero(fromDate.value) !== setDateHoursToZero(dateRange.value[0].toDate()) ||
        setDateHoursToZero(toDate.value) !== setDateHoursToZero(dateRange.value[1].toDate())
      ) {
        await store.dispatch(UPDATE_FROM_TO_DATES, {
          fromDate: dateRange.value[0].toDate(),
          toDate: dateRange.value[1].toDate()
        });

        await store.dispatch(UPDATE_CONFIG_PROFILE);
        await store.commit(CLEAR_FLEET);
        await store.commit(RESET_LEGS);
        await store.commit(RESET_FLEET_ACTIONS);
        await store.dispatch(FETCH_BOARD_FLEET);
      }
    };

    const onCalendarChange = (dates: moment.Moment[]) => {
      dateSelectionLocal.value = dates;
    };

    const onOpenChange = (isOpen: boolean) => {
      if (isOpen) {
        dateSelectionLocal.value = [];
      }
    };

    return {
      onCalendarChange,
      onOpenChange,
      lang,
      locale,
      dateRange,
      disableWeekends,
      fromDate,
      toDate,
      showWeekends,
      updateDates,
      isDefaultDateActive
    };
  }
});
</script>

<style lang="scss">
.calendar__day--disabled {
  @apply text-gray-400 cursor-not-allowed pointer-events-none;
}

.calendar__weekend--disabled {
  .weekday-1 {
    @apply calendar__day--disabled;
  }
  .weekday-7 {
    @apply calendar__day--disabled;
  }
}

#board-header-date-picker {
  .ant-calendar-prev-year-btn {
    display: none;
  }
  .ant-calendar-next-year-btn {
    display: none;
  }
}
</style>
