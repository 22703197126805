<template>
  <div class="mb-8">
    <div class="flex pt-4 mb-2">
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.truck") }}</div>
        <multiSelect
          v-model="truckPreviewValue"
          :disabled="optimized && !fleetMember.truckId"
          :options="truckPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectTruck')"
          label="displayName"
          track-by="displayName"
          :loading="truckLoading"
          @input="truckChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.trailer") }}</div>
        <multiSelect
          v-model="trailerPreviewValue"
          :disabled="optimized && !fleetMember.trailerId"
          :options="trailerPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectTrailer')"
          label="displayName"
          track-by="displayName"
          :loading="trailerLoading"
          @input="trailerChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
      <div class="w-1/3">
        <div class="text-gray-400 mb-2">{{ $t("fleet.driver") }}</div>
        <multiSelect
          v-model="driverPreviewValue"
          :disabled="optimized && !fleetMember.driverId"
          :options="driverPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectDriver')"
          label="displayName"
          track-by="displayName"
          :loading="driverLoading"
          @input="driverChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
    </div>
    <div class="flex py-4">
      <div class="w-1/3 pr-4">
        <div class="text-gray-400 mb-2">{{ $t("fleet.subcontractor") }}</div>
        <multiSelect
          v-model="subcontractorPreviewValue"
          :options="subcontractorPreview"
          :disabled="optimized && !fleetMember.subcontractorId"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectSubcontractor')"
          label="displayName"
          track-by="displayName"
          :loading="subcontractorLoading"
          @input="subcontractorChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import { FleetEntityType, Preview } from "@/types/fleet";
import fleetService from "@/services/fleetService";
import { setAsFirstIfMatching } from "@/use/useArray";
import { FleetActionPayload } from "@/types/action";
import { spreadSubcontractorId, spreadTrailerId } from "@/use/useFleet";
import store from "@/store";
import { GET_FLEET } from "@/store/fleet";
import { sortBy } from "@/use/useObject";

export default defineComponent({
  name: "FleetSelection",
  components: {
    multiSelect
  },
  props: {
    fleetMember: {
      type: Object as () => FleetActionPayload
    },
    optimized: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const truckPreview = ref<Array<Preview>>([]);
    const truckPreviewValue = ref<Preview | null>(null);
    const truckLoading = ref(false);
    const trailerPreview = ref<Array<Preview>>([]);
    const trailerPreviewValue = ref<Preview | null>(null);
    const trailerLoading = ref(false);
    const driverPreview = ref<Array<Preview>>([]);
    const driverPreviewValue = ref<Preview | null>(null);
    const driverLoading = ref(false);
    const subcontractorPreview = ref<Array<Preview>>([]);
    const subcontractorPreviewValue = ref<Preview | null>(null);
    const subcontractorLoading = ref(false);
    const fleet = computed(() => store.getters[GET_FLEET]);

    const displayName = (previewItem: Preview): string => {
      const { displayName } = previewItem;
      return displayName;
    };

    const initDropDowns = () => {
      if (typeof props.fleetMember !== "undefined") {
        const { truckId, trailerId, driverId, subcontractorId } = props.fleetMember;
        truckPreviewValue.value =
          (truckId && {
            id: truckId,
            displayName: truckId
          }) ||
          null;
        driverPreviewValue.value =
          (driverId && {
            id: driverId,
            displayName: driverId
          }) ||
          null;
        trailerPreviewValue.value =
          (trailerId && {
            id: spreadTrailerId(trailerId).id,
            displayName: spreadTrailerId(trailerId).id
          }) ||
          null;

        subcontractorPreviewValue.value =
          (subcontractorId && {
            id: subcontractorId,
            displayName: subcontractorId
          }) ||
          null;
      }
    };

    const fetchTrucks = async () => {
      if (props.optimized) {
        const { truckId } = props.fleetMember || {};
        if (truckId == null) {
          return;
        }
      }
      truckLoading.value = true;
      try {
        const { data } = await fleetService.getTrucksPreview();
        truckPreview.value = setAsFirstIfMatching(data.previews, truckPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      truckLoading.value = false;
    };

    const fetchTrailers = async () => {
      if (props.optimized) {
        const { trailerId } = props.fleetMember || {};
        if (trailerId == null) {
          return;
        }
      }
      trailerLoading.value = false;
      try {
        const { data } = await fleetService.getTrailersPreview();
        trailerPreview.value = setAsFirstIfMatching(data.previews, trailerPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      trailerLoading.value = false;
    };

    const fetchDrivers = async () => {
      if (props.optimized) {
        const { driverId } = props.fleetMember || {};
        if (driverId == null) {
          return;
        }
      }
      driverLoading.value = true;
      try {
        const { data } = await fleetService.getDriversPreview();
        driverPreview.value = setAsFirstIfMatching(data.previews, driverPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      driverLoading.value = false;
    };

    /**
     * Subcontractors handling
     */
    const fetchSubcontractors = async () => {
      if (props.optimized) {
        const { subcontractorId } = props.fleetMember || {};
        if (subcontractorId == null) {
          return;
        }
      }
      subcontractorLoading.value = true;
      try {
        const fleetSubcontractors = fleet.value
          .filter(entity => entity.type === FleetEntityType.SUBCONTRACTOR)
          .map(subi => ({
            displayName: `${subi.name} (${spreadSubcontractorId(subi.id).boardId})`,
            id: subi.id
          }))
          .sort(sortBy("displayName"));

        subcontractorPreview.value = setAsFirstIfMatching(fleetSubcontractors, subcontractorPreviewValue.value?.id!);
        setSubconDisplayName();
      } catch (e) {
        //show errors which are not handled globally
      }

      subcontractorLoading.value = false;
    };
    const setSubconDisplayName = (): void => {
      if (subcontractorPreviewValue.value?.displayName) {
        subcontractorPreviewValue.value.displayName =
          subcontractorPreview.value.find(item => item.id === subcontractorPreviewValue.value?.id)?.displayName || "";
      }
    };

    const getDropdownData = () => {
      fetchTrailers();
      fetchTrucks();
      fetchDrivers();
      fetchSubcontractors();
    };

    const truckChanged = async (selectedTruck?: Preview | null) => {
      try {
        const { data } = await fleetService.getTruckById(selectedTruck!.id!);
        truckPreview.value = setAsFirstIfMatching(truckPreview.value, data.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      emit("fleetChange", {
        type: FleetEntityType.TRUCK,
        id: selectedTruck?.id
      });
    };

    const trailerChanged = async (selectedTrailer?: Preview | null) => {
      trailerPreview.value = setAsFirstIfMatching(trailerPreview.value, trailerPreviewValue.value?.id);
      emit("fleetChange", {
        type: FleetEntityType.TRAILER,
        id: selectedTrailer?.id
      });
    };
    const driverChanged = async (selectedDriver?: Preview | null) => {
      driverPreview.value = setAsFirstIfMatching(driverPreview.value, driverPreviewValue.value?.id);
      emit("fleetChange", {
        type: FleetEntityType.DRIVER,
        id: selectedDriver?.id
      });
    };

    const subcontractorChanged = async (selectedSubcontractor?: Preview | null) => {
      subcontractorPreview.value = setAsFirstIfMatching(
        subcontractorPreview.value,
        subcontractorPreviewValue.value?.id
      );
      emit("fleetChange", {
        type: FleetEntityType.SUBCONTRACTOR,
        id: selectedSubcontractor?.id
      });
    };

    const initializeState = async () => {
      initDropDowns();
      getDropdownData();
    };

    onMounted(() => {
      initializeState();
    });

    watch(
      () => props.fleetMember,
      () => {
        initializeState();
      }
    );

    return {
      displayName,
      initDropDowns,
      truckChanged,
      trailerChanged,
      driverChanged,
      truckPreview,
      truckPreviewValue,
      truckLoading,
      driverPreview,
      driverPreviewValue,
      driverLoading,
      trailerPreview,
      trailerPreviewValue,
      trailerLoading,
      subcontractorPreview,
      subcontractorPreviewValue,
      subcontractorLoading,
      subcontractorChanged
    };
  }
});
</script>

<style scoped></style>
