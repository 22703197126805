/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'truck-flexible': {
    width: 29.997,
    height: 18.378,
    viewBox: '0 0 29.997 18.378',
    data: '<path pid="0" _stroke="rgba(0,0,0,0)" stroke-miterlimit="10" d="M24.555 17.878a2.78 2.78 0 112.779-2.778 2.782 2.782 0 01-2.779 2.778zm0-4a1.22 1.22 0 101.22 1.222 1.222 1.222 0 00-1.22-1.222zM6.421 17.69a2.594 2.594 0 112.6-2.59 2.594 2.594 0 01-2.6 2.59zm0-3.73a1.137 1.137 0 101.142 1.14 1.139 1.139 0 00-1.142-1.14zm23.075 1.516h-1.604v-.287c0-.159 0-.316-.006-.473h1.61v.76zm-8.365 0H9.489c.008-.089.018-.181.018-.287a3.123 3.123 0 00-.042-.473h11.668v.76zm-17.975 0H.527V15.189c0-.169 0-.324-.009-.473h2.641v.76zm18.056-1.85H9.558a3.241 3.241 0 00-6.237-.354l-.129.081H.527L.521.5h19.958v3.169h6.875l2.144 4.693v5.261h-1.582a3.456 3.456 0 00-6.7 0zM1.26 10.437v1.612h.407v-1.612zm10.954-8.279a4.313 4.313 0 104.307 4.311 4.315 4.315 0 00-4.307-4.311zm8.633 2.268v3.936h7.7l-1.563-3.936zM14.021 8.28L11.783 6.9V4.314h.645v2.242l1.941 1.164-.348.56z"/>'
  }
})
