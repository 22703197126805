var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-44 shadow-lg" }, [
      _c(
        "button",
        {
          staticClass: "p-2 w-full bg-white openFleetDetailHandler",
          class: {
            "cursor-pointer hover:bg-btYellow": !_vm.isDriver,
            "text-gray-300": _vm.isDriver
          },
          attrs: { disabled: _vm.isDriver },
          on: { click: _vm.openFleetDetail }
        },
        [
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.affiliationTitle")))
          ])
        ]
      ),
      _c(
        "button",
        {
          staticClass:
            "p-2 w-full cursor-pointer bg-white hover:bg-btYellow openFleetActionHandler",
          on: { click: _vm.openFleetAction }
        },
        [
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.actionReminderTitle")))
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }