

































import { defineComponent, ref } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import LogoutButton from "@/components/Buttons/LogoutButton.vue";
import BoardSettingsButton from "@/components/Buttons/BoardSettingsButton.vue";
import NavSettings from "@/components/Nav/NavSettings/NavSettings.vue";

export default defineComponent({
  components: { Icon, LogoutButton, BoardSettingsButton, NavSettings },
  setup() {
    const isPopoverVisible = ref(false);
    const closePopover = () => {
      isPopoverVisible.value = false;
    };
    const boardSettingsActive = ref(false);
    const showBoardSettings = (): void => {
      boardSettingsActive.value = true;
    };
    const closeBoardSettings = (): void => {
      boardSettingsActive.value = false;
    };
    return { boardSettingsActive, showBoardSettings, closeBoardSettings, isPopoverVisible, closePopover };
  }
});
