var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "border-b-2 font-semibold mb-2 text-gray-900 border-gray-900"
      },
      [_vm._v(" " + _vm._s(_vm.truck.id) + " ")]
    ),
    _c(
      "table",
      { staticClass: "truckCallout__table table-fixed text-left mb-6 w-full" },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.suitableFor")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.tareWeight")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.nextTest")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "font-light align-top" }, [
          _c("tr", [
            _c("td", [
              _vm.truck.metadata.suitableFor
                ? _c("span", [_vm._v(_vm._s(_vm.truck.metadata.suitableFor))])
                : _vm._e()
            ]),
            _c("td", [
              _vm.truck.metadata.tareWeight
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.truck.metadata.tareWeight) + " kg ")
                  ])
                : _vm._e()
            ]),
            _c("td", [
              _vm.nextInspectionDate
                ? _c(
                    "span",
                    {
                      class: {
                        "text-btRed": _vm.nextInspectionDate.isDateInPast
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.nextInspectionDate.date) + " ")]
                  )
                : _vm._e(),
              _c(
                "span",
                [
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        trigger: "hover",
                        placement: "right",
                        overlayStyle: { maxWidth: "650px" },
                        getPopupContainer: function(triggerNode) {
                          return triggerNode.parentNode
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c("FleetNextInspectionTable", {
                                attrs: { content: _vm.truck.metadata }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("Icon", {
                        staticClass: "text-black cursor-pointer",
                        attrs: {
                          name: "info",
                          width: "1.5rem",
                          height: "1.5rem"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]
    ),
    _c(
      "table",
      { staticClass: "truckCallout__table table-fixed text-left w-full mb-6" },
      [
        _c("thead", { staticClass: "font-light" }, [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.equipment")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [_vm._v("Mapon")]),
            _c("th", { staticClass: "w-1/3" }, [_vm._v("Euro class")])
          ])
        ]),
        _c("tbody", { staticClass: "align-top" }, [
          _c("tr", [
            _c("td", { staticClass: "capitalize" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.stringifyTruckEquipment(_vm.truck.metadata)) +
                  " "
              )
            ]),
            _c("td", [
              _vm.truck.metadata.maponLink
                ? _c(
                    "a",
                    {
                      staticClass: "text-black",
                      attrs: {
                        href: _vm.truck.metadata.maponLink,
                        target: "_blank"
                      }
                    },
                    [
                      _c("Icon", {
                        attrs: { name: "link", height: "18", width: "18" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("td", [
              _vm.truck.metadata.euroClass
                ? _c("span", [_vm._v(_vm._s(_vm.truck.metadata.euroClass))])
                : _vm._e()
            ])
          ])
        ])
      ]
    ),
    _c(
      "table",
      { staticClass: "truckCallout__table table-fixed text-left w-full mb-6" },
      [
        _c("thead", { staticClass: "font-light" }, [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.department")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.tempDepartment")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.tempGroup")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "align-top" }, [
          _c("tr", [
            _c("td", [_vm._v(" " + _vm._s(_vm.truck.baseBoardId) + " ")]),
            _vm.tempDepartment
              ? _c("td", [
                  _vm._v(" " + _vm._s(_vm.tempDepartment.boardId) + " ")
                ])
              : _vm._e(),
            _vm.tempGroup
              ? _c("td", [_vm._v(" " + _vm._s(_vm.tempGroup.boardId) + " ")])
              : _vm._e()
          ])
        ])
      ]
    ),
    _c(
      "table",
      { staticClass: "truckCallout__table table-fixed text-left w-full" },
      [
        _c("thead", { staticClass: "font-light" }, [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.assignedDriver")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.driverPhoneNr")))
            ]),
            _c("th", { staticClass: "w-1/3" })
          ])
        ]),
        _c("tbody", { staticClass: "align-top" }, [
          _c("tr", [
            _c("td", [
              _vm.truck.driver
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.truck.driver.firstName) +
                        " " +
                        _vm._s(_vm.truck.driver.lastName) +
                        " "
                    )
                  ])
                : _vm._e()
            ]),
            _c("td", [
              _vm.truck.driver
                ? _c(
                    "div",
                    { staticClass: "flex space-x-3" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.truck.driver.phoneNumber))
                      ]),
                      _vm.truck.driver.phoneNumber
                        ? _c("CopyToClipboard", {
                            attrs: {
                              width: "18",
                              height: "18",
                              text: _vm.truck.driver.phoneNumber
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("td")
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }