<template>
  <div class="flex items-center relative z-40">
    <a-select
      dropdownClassName="fixed"
      v-if="isEditMode"
      :placeholder="$t('transportInfoEditor.selectStatus')"
      class="ml-2 relative "
      style="width: 300px"
      @change="bulkOptionChanged"
    >
      <a-select-opt-group
        v-for="(option, index) in bulkOptions"
        :key="index"
        :label="$t(`transportInfoEditor.options.${option.type}`)"
      >
        <a-select-option v-for="(value, index) in option.values" :key="index" :value="`${option.type},${value}`">
          <div v-if="option.type === 'status'" :class="`statusBox statusBox--${value.toLowerCase()}`"></div>
          <span v-else>{{ $t(`transportInfoEditor.options.${option.type}`) }} - </span>
          {{ $t(`transportInfoEditor.values.${value.toLowerCase()}`) }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>

    <div @click="toggleEditMode">
      <a-tooltip v-if="!isEditMode">
        <template slot="title">
          {{ $t("editorMode") }}
        </template>
        <icon name="edit" class="text-gray-300 hover:text-btYellow cursor-pointer" height="25" width="25" />
      </a-tooltip>
      <icon v-else name="close" class=" text-black cursor-pointer hover:text-btRed" height="25" width="25" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "@vue/composition-api";
import Icon from "../../Icon.vue";
import multiSelect from "vue-multiselect";
import store from "@/store";
import { TOGGLE_BOARD_EDIT_MODE, SET_TRANSPORT_INFO_STATUS, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { GET_LEG_TRANSPORT_INFO_KEYS } from "@/store/brick";
import { LegTransportStatus, TransportInfoGroupStatus, TransportInfoUpdateType } from "@/types/leg";

export default defineComponent({
  components: { Icon, multiSelect },
  setup() {
    const isEditMode = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const toggleEditMode = () => {
      store.dispatch(TOGGLE_BOARD_EDIT_MODE);
    };
    const bulkOptionsPresets = {
      documents: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      specialEquipments: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      temperatures: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      customs: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      specialCustoms: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE]
    };
    const statusPreset = [
      {
        type: TransportInfoUpdateType.STATUS,
        values: [
          LegTransportStatus.NO_STATUS,
          LegTransportStatus.PREPARATION,
          LegTransportStatus.READY,
          LegTransportStatus.BLOCKED
        ]
      }
    ];

    const transportInfoKeys = computed(() => store.getters[GET_LEG_TRANSPORT_INFO_KEYS]);
    const bulkOptions = computed(() => {
      const options = transportInfoKeys.value.map(key => ({
        type: key,
        values: bulkOptionsPresets[key]
      }));
      return [...statusPreset, ...options];
    });

    const bulkOptionChanged = (payload: string) => {
      const values = payload.split(",");
      const type = values[0];
      const value = LegTransportStatus.NO_STATUS === values[1] ? null : values[1];

      store.commit(SET_TRANSPORT_INFO_STATUS, { type: type, value: value });
    };

    const handleEscKey = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        toggleEditMode();
      }
    };

    watch(
      () => isEditMode.value,
      () => {
        if (isEditMode.value) {
          document.addEventListener("keydown", handleEscKey);
        } else {
          document.removeEventListener("keydown", handleEscKey);
        }
      }
    );

    return { isEditMode, toggleEditMode, bulkOptionChanged, bulkOptions };
  }
});
</script>

<style lang="scss">
@use "TransportInfoEditor";
</style>
