var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "context-menu-options flex flex-col" }, [
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: {
          "cursor-not-allowed opacity-60":
            !_vm.isActionSelected || _vm.isEditMode
        },
        attrs: { disabled: !_vm.isActionSelected || _vm.isEditMode },
        on: { click: _vm.copyAction }
      },
      [_vm._v(" Copy ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: {
          "cursor-not-allowed opacity-60":
            !_vm.hasCopiedAction || _vm.isEditMode
        },
        attrs: { disabled: !_vm.hasCopiedAction || _vm.isEditMode },
        on: { click: _vm.pasteAction }
      },
      [_vm._v(" Paste ")]
    ),
    _c("div", { staticClass: "bg-gray-100 px-3 py-1" }, [
      _vm._v(_vm._s(_vm.$t("contextMenu.actionGroupLabel")))
    ]),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasTruck },
        attrs: { disabled: !_vm.hasTruck },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.TRUCK, false)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.truck")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasTrailer },
        attrs: { disabled: !_vm.hasTrailer },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.TRAILER, false)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.trailer")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasDriver },
        attrs: { disabled: !_vm.hasDriver },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.DRIVER, false)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.driver")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasSubcontractor },
        attrs: { disabled: !_vm.hasSubcontractor },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.SUBCONTRACTOR, false)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.subcontractor")) + " ")]
    ),
    _c("div", { staticClass: "bg-gray-100 px-3 py-1" }, [
      _vm._v(_vm._s(_vm.$t("contextMenu.reminderGroupLabel")))
    ]),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasTruck },
        attrs: { disabled: !_vm.hasTruck },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.TRUCK, true)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.truck")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasTrailer },
        attrs: { disabled: !_vm.hasTrailer },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.TRAILER, true)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.trailer")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasDriver },
        attrs: { disabled: !_vm.hasDriver },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.DRIVER, true)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.driver")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "px-3 py-1 text-left hover:bg-gray-200",
        class: { "cursor-not-allowed opacity-60": !_vm.hasSubcontractor },
        attrs: { disabled: !_vm.hasSubcontractor },
        on: {
          click: function() {
            return _vm.onOptionClick(_vm.FleetEntityType.SUBCONTRACTOR, true)
          }
        }
      },
      [_vm._v(" " + _vm._s(_vm.$t("fleet.subcontractor")) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }