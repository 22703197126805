import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";
import Dashboard from "@/views/Dashboard.vue";
import { IS_LOGGED_IN } from "@/store/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login.vue")
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "Error" */ "@/views/Error.vue")
  },
  //route all paths when not existing to error page
  { path: "*", component: () => import("../views/Error.vue") }
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters[IS_LOGGED_IN]) {
      next(); // go to wherever I'm going
    } else {
      next({ name: "Login" });
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
