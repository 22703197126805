<template>
  <div class="h-28 w-full shadow flex fixed z-50 bg-white header">
    <router-link :to="{ name: 'Dashboard' }">
      <div class="h-full w-84 w- bg-btYellow flex relative justify-center items-center p-10">
        <Logo />
        <div class="absolute bottom-0 right-0 p-2 text-black text-sm">Version {{ version }}</div>
      </div>
    </router-link>

    <div class="w-full flex justify-between">
      <div class="w-1/3 flex items-center min-w-0">
        <span class="float-left m-6 text-2xl header-title">{{ title }}</span>
      </div>
      <div class="w-full flex justify-center items-center">
        <TimeLineZoom class="mr-4" />
        <BoardDateSelector class="mr-4" />
        <ShowPreviousLegDetail class="ml-4" />
        <ShowBoardEditMode class="ml-4" />
      </div>
      <div class="w-1/3 flex items-center justify-end">
        <div class="flex pr-2">
          <span class="font-bold"
            >{{ $t("legsAtRisk") }}:
            <span :class="{ 'text-red-500': totalLegsWithIssues > 0 }">{{ totalLegsWithIssues }}</span></span
          >
        </div>
        <SearchBox class="mr-6" />
        <div class="flex flex-col h-full justify-center pr-2">
          <Nav class="mr-4 -mt-1" />
          <ChatNotifications :content="{}" :totalUnreadMessages="totalUnreadMessages" class="mr-4 mt-2" />
        </div>
      </div>
    </div>
    <ChatHub v-if="chatHubActive" :isActive="chatHubActive" @chatHubClosed="closeChatHub" />
    <div v-if="boardEditMode || fleetIsLoading" class="h-28 w-full bg-black fixed top-0 left-0 z-30 opacity-20"></div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api";
import { version } from "../../package.json";
import Logo from "@/components/Logo.vue";
import BoardDateSelector from "@/components/Board/DateSelector/DateSelector.vue";
import TimeLineZoom from "@/components/Board/TimeLine/TimeLineZoom/TimeLineZoom.vue";
import store from "@/store";
import Icon from "@/components/Icon.vue";
import Nav from "@/components/Nav/Nav.vue";
import { GET_BOARD_ID, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import ShowBoardEditMode from "@/components/Board/TransportInfoEditor/TransportInfoEditor.vue";
import ShowPreviousLegDetail from "@/components/Board/ShowPreviousLegDetail.vue";
import { FLEET_LOADING } from "@/store/fleet";
import SearchBox from "@/components/SearchBox/SearchBox.vue";
import ChatHub from "@/components/Chat/ChatHub.vue";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";
import { GET_CHATS, IS_CHAT_HUB_ACTIVE, TOGGLE_CHAT_HUB_MODE } from "@/store/chat";

export default defineComponent({
  name: "Header",
  components: {
    Logo,
    BoardDateSelector,
    TimeLineZoom,
    Icon,
    ChatNotifications,
    Nav,
    ShowPreviousLegDetail,
    ShowBoardEditMode,
    SearchBox,
    ChatHub
  },
  setup() {
    const title = computed(() => {
      const boardId: string = store.getters[GET_BOARD_ID];
      return boardId.replaceAll(",", " / ");
    });
    const boardEditMode = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const fleetIsLoading = computed(() => store.getters[FLEET_LOADING]);

    const chatHubActive = computed(() => store.getters[IS_CHAT_HUB_ACTIVE]);

    const chats = computed(() => store.getters[GET_CHATS]);

    const legs = computed(() => store.state.brick.legs);

    const totalLegsWithIssues = computed(() => {
      return legs.value.reduce((acc, leg) => {
        if (leg.details?.issues?.length > 0) {
          return acc + 1;
        }
        return acc;
      }, 0);
    });

    const totalUnreadMessages = computed(() =>
      chats.value.reduce((acc, chat) => {
        return acc + chat.unreadCount;
      }, 0)
    );

    const closeChatHub = () => {
      store.dispatch(TOGGLE_CHAT_HUB_MODE, false);
    };

    return {
      closeChatHub,
      chatHubActive,
      version,
      title,
      boardEditMode,
      fleetIsLoading,
      totalUnreadMessages,
      totalLegsWithIssues
    };
  }
});
</script>

<style scoped>
.header-title {
  font-family: "Open Sans Bold", sans-serif;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
