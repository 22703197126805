<template>
  <div class="relative w-full h-full bg-gray-100 form mb-8">
    <form @submit.prevent>
      <div class="form__head flex items-center">
        <Icon name="notifications" class="mr-1" width="20" height="20" /> {{ $t("form.reminderForm.title") }}
      </div>
      <div class="form__body">
        <div class="flex mb-8">
          <div class="w-1/2 mr-6">
            <div class="flex flex-col">
              <label class="mb-1">{{ $t("form.title") }}</label>
              <a-input v-model="form.type" :placeholder="$t('form.titlePlaceholder')" size="large" />
            </div>
            <label v-if="!form.type && form.hasData" class="label__error">
              {{ $t("form.required") }}
            </label>
          </div>
          <div class="w-1/2">
            <div class="flex flex-col">
              <label class="mb-1">{{ $t("form.remarks") }}</label>
              <a-input v-model="form.remarks" :placeholder="$t('form.descriptionPlaceholder')" size="large" />
            </div>
          </div>
        </div>

        <div class="form__section">
          <div class="form__section__title">{{ $t("form.dateAndTime") }}</div>
          <div class="flex">
            <div class="w-1/2 mr-6 flex">
              <div class="w-1/2">
                <div class="flex flex-col mr-4">
                  <label class="mb-1">{{ $t("form.date") }}</label>
                  <fleet-forms-date-picker v-model="form.date" />
                </div>
                <label v-if="!form.date && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
              </div>
              <div class="w-1/2">
                <div class="flex flex-col">
                  <label class="mb-1">{{ $t("form.time") }}</label>
                  <fleet-forms-time-picker v-model="form.time" :default-value="defaultTime" format="HH:mm" />
                </div>
                <label v-if="!form.time && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
              </div>
            </div>
            <div class="w-1/2"></div>
          </div>
        </div>

        <div class="form__section">
          <div class="form__section__title">{{ $t("form.location") }}</div>
          <div class="flex">
            <div class="w-1/3">
              <multiSelect
                v-model="formLocation"
                :options="locations"
                track-by="id"
                label="displayName"
                placeholder="Select to location"
                :selectLabel="$t('actions.select')"
                :deselectLabel="$t('actions.remove')"
                @input="onLocationChange"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import Button from "@/components/Buttons/Button.vue";
import Icon from "@/components/Icon.vue";
import fleetFormsDatePicker from "./FleetFormsDatePicker.vue";
import fleetFormsTimePicker from "./FleetFormsTimePicker.vue";
import { Preview } from "@/types/fleet";
import { FleetReminderFormData, FleetReminderFormDataInternal } from "@/types/action";
import fleetService from "@/services/fleetService";
import { setAsFirstIfMatching } from "@/use/useArray";
import moment from "moment";
import { parseISO } from "date-fns/fp";
import { hasValues } from "@/use/useObject";

export default defineComponent({
  name: "FleetReminderForm",
  components: {
    fleetFormsDatePicker,
    fleetFormsTimePicker,
    multiSelect,
    Button,
    Icon
  },
  props: {
    formData: {
      type: Object as () => FleetReminderFormData
    }
  },
  setup(props, { emit }) {
    const form = reactive<FleetReminderFormDataInternal>({
      type: undefined,
      remarks: undefined,
      date: undefined,
      time: undefined,
      location: undefined,
      hasData: computed(() => hasValues(form, ["hasData", "time"]))
    });

    const formIsValid = computed(() => {
      return form.hasData && form.type && form.date && form.time;
    });

    const locations = ref<Preview[]>([]);
    const formLocation = ref<Preview | null>(null);

    const getLocations = async () => {
      try {
        const { data } = await fleetService.getLocationsPreview();
        locations.value = data.previews;

        //if it is an update we need to fetch the right location and show it
        if (props.formData != null) {
          formLocation.value = locations.value.find(location => location.id === props.formData?.location?.id) || null;
        }
      } catch (e) {
        //show erros which are not handled globally
      }
    };

    const onLocationChange = async (location?: Preview | null) => {
      try {
        const { data } = await fleetService.getLocationById(location!.id!);
        form.location = data;
        locations.value = setAsFirstIfMatching(locations.value, data.id);
        emit("form-change", form);
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const initializeForm = () => {
      const { type, remarks, date, time, location } = props.formData || {};

      form.time = (time && moment(time)) || null;
      form.date = date ? parseISO(date) : null;

      if (props.formData == null || props.formData.type == null) {
        return;
      }

      form.type = type;
      form.remarks = remarks;
      form.date = parseISO(date || "");
      form.location = location;
    };

    watch(
      () => ({ ...form }),
      () => {
        emit("form-change", form);
        emit("form-is-valid", formIsValid.value);
      }
    );

    watch(
      () => props.formData,
      () => {
        initializeForm();
      }
    );

    onMounted(() => {
      getLocations();
      initializeForm();
    });

    const defaultTime = computed(() => {
      const { time } = props.formData || {};
      return time ? moment(time).format("HH:mm") : "00:00";
      //return time ? dateStringToShowTime(time) : "00:00";
    });

    return {
      defaultTime,
      form,
      formIsValid,
      formLocation,
      locations,
      onLocationChange
    };
  }
});
</script>

<style scoped></style>
