var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "leg-remarks pr-4" },
      [
        _c("a-input", {
          attrs: {
            defaultValue: _vm.defaultValue,
            size: "large",
            placeholder: _vm.$t("dispo.legRemarksPlaceholder")
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.remarks,
            callback: function($$v) {
              _vm.remarks = $$v
            },
            expression: "remarks"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }