var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "w-screen h-screen bg-black fixed top-0 left-0 z-30 opacity-20"
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }