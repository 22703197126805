






















import { computed, defineComponent, onMounted, onUnmounted } from "@vue/composition-api";
import Overlay from "@/components/Overlay.vue";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Buttons/Button.vue";
import useScrollPosition from "@/use/useScrollPosition";
export default defineComponent({
  name: "Dialog",
  components: { Overlay, Icon, Button },
  props: {
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    disableScroll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { toggleScrollFixed } = useScrollPosition();

    const dynamicClass = computed(() => (props.isCentered ? "dialog--isCentered" : ""));

    const confirmHandler = () => {
      emit("confirm");
      closeHandler();
    };

    const closeHandler = () => {
      emit("closeDialog");
    };

    const dialogEscapeHandler = (e: any) => {
      if (e.key === "Escape") {
        e.stopPropagation(); //stops firering other listeners on esc key
        closeHandler();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", dialogEscapeHandler, {
        capture: true,
        once: true
      });
      if (props.disableScroll) {
        toggleScrollFixed();
      }
    });

    onUnmounted(() => {
      if (props.disableScroll) {
        toggleScrollFixed();
      }
    });

    return {
      dynamicClass,
      closeHandler,
      confirmHandler
    };
  }
});
