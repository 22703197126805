<template>
  <div
    v-if="isActive"
    class="w-full h-full flex left-0 top-0  bg-black bg-opacity-40 justify-center items-center absolute"
  >
    <icon name="spinner" class="text-btYellow" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "BrickSpinner",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: { Icon }
});
</script>

<style scoped></style>
