import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import board from "@/store/board";
import fleet from "@/store/fleet";
import brick from "@/store/brick";
import chat from "@/store/chat";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  modules: {
    auth,
    board,
    fleet,
    brick,
    chat
  }
});

export default store;
