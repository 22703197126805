var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "flex justify-center" },
          [
            _c("icon", {
              staticClass: "text-btYellow",
              attrs: { name: "spinner" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            {
              staticClass: "calloutLeg",
              attrs: { slot: "popover" },
              slot: "popover"
            },
            [
              _c("div", { staticClass: "calloutLeg__header" }, [
                _c(
                  "div",
                  {
                    staticClass: "border-b-2 font-semibold mb-2 text-gray-500"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.title))]),
                    _vm.container.metadata &&
                    _vm.container.metadata.markedForRepair
                      ? _c(
                          "span",
                          { staticClass: "font-normal text-red-400 ml-6" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("leg.container.markedForRepair"))
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "popover-body" }, [
            _c(
              "table",
              { staticClass: "calloutLeg__table table-fixed w-full mb-6" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.length")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.chamberVolume")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.tareWeight")))
                    ])
                  ])
                ]),
                _c("tbody", { staticClass: "font-light align-top" }, [
                  _c("tr", [
                    _c("td", [
                      _vm.container.metadata.length
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.container.metadata.length) + " cm"
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", [
                      _vm.container.metadata.chamberVolumes
                        ? _c("div", [
                            _vm._v(" " + _vm._s(_vm.chamberVolumes) + " ")
                          ])
                        : _vm._e()
                    ]),
                    _c("td", [
                      _vm.container.metadata.tareWeight
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.container.metadata.tareWeight) + " kg"
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "table",
              { staticClass: "calloutLeg__table table-fixed w-full mb-6" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.nextTest")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.specialEquipment")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.acceptance")))
                    ])
                  ])
                ]),
                _c("tbody", { staticClass: "font-light align-top" }, [
                  _c("tr", [
                    _c("td", [
                      _vm.nextAdrTest
                        ? _c("div", [_vm._v("ADR: " + _vm._s(_vm.nextAdrTest))])
                        : _vm._e(),
                      _vm.container.metadata.nextCscTest
                        ? _c("div", [
                            _vm._v(
                              " CSC: " +
                                _vm._s(
                                  _vm.dateStringToShowDate(
                                    _vm.container.metadata.nextCscTest
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.container.metadata.nextAcepTest
                        ? _c("div", [
                            _vm._v(
                              " ACEP: " +
                                _vm._s(
                                  _vm.dateStringToShowDate(
                                    _vm.container.metadata.nextAcepTest
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(_vm.specialEquipment))])
                    ]),
                    _c("td", [
                      _vm.container.metadata.acceptance
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.container.metadata.acceptance))
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "table",
              { staticClass: "calloutLeg__table table-fixed w-full mb-6" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.tankCode")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.dedicationGroup")))
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("leg.container.nexiotLink")))
                    ])
                  ])
                ]),
                _c("tbody", { staticClass: "font-light align-top" }, [
                  _c("tr", [
                    _c("td", [
                      _vm.container.metadata.tankCode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.container.metadata.tankCode))
                          ])
                        : _vm._e()
                    ]),
                    _c("td", [
                      _vm.container.metadata.dedicatedProductGroup
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.container.metadata.dedicatedProductGroup
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", [
                      _vm.container.metadata.nexxiotLink
                        ? _c("div", { staticClass: "mr-2 overflow-hidden" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.container.metadata.nexxiotLink
                                }
                              },
                              [
                                _c("Icon", {
                                  attrs: {
                                    name: "link",
                                    height: "18",
                                    width: "18"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }