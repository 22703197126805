<template>
  <div class="text-base">
    <div class="flex mt-2">
      <a-switch @change="onDefaultDateSwitchChange" v-model="defaultDateActivated" size="large" class="mr-2" /><span>{{
        $t("settings.activated")
      }}</span>
    </div>
    <div v-if="defaultDateActivated" class="mb-4">
      <a-divider />
      <div class="flex items-center">
        <div class="flex">
          <a-input-number v-model="daysFromTodayStartInput" size="large" />
        </div>
        <div class="mx-2 flex items-center h-12">
          <div>{{ $t("dates.today") }}</div>
        </div>
        <div class="flex flex-col">
          <a-input-number v-model="daysFromTodayEndInput" :min="daysFromTodayStartInput" size="large" />
        </div>
      </div>

      <div class="flex mt-4 items-center">
        <a-date-picker v-model="fromDate" :default-value="fromDate" disabled size="large" format="DD.MM.YYYY" />
        <span class="mx-2">-</span>
        <a-date-picker v-model="toDate" :default-value="toDate" disabled size="large" format="DD.MM.YYYY" />
        <Button size="sm" class="ml-auto" @click="onSaveButtonClick">{{ $t("actions.save") }}</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "@vue/composition-api";
import moment from "moment";
import Button from "@/components/Buttons/Button.vue";
import store from "@/store";
import { DAYS_FROM_TODAY_START, DAYS_FROM_TODAY_END, SET_DEFAULT_DATES, UPDATE_CONFIG_PROFILE } from "@/store/board";
import { CLEAR_FLEET, FETCH_BOARD_FLEET } from "@/store/fleet";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { DefaultDateDaysPayload } from "@/types/board";

export default defineComponent({
  components: { Button },
  setup(props, { emit }) {
    const daysFromTodayStart = computed(() => store.getters[DAYS_FROM_TODAY_START]);
    const daysFromTodayEnd = computed(() => store.getters[DAYS_FROM_TODAY_END]);
    const daysFromTodayStartInput = ref(daysFromTodayStart.value || 0);
    const daysFromTodayEndInput = ref(daysFromTodayEnd.value || 0);
    const fromDate = computed(() => moment().add(daysFromTodayStartInput.value, "days"));
    const toDate = computed(() => moment().add(daysFromTodayEndInput.value, "days"));

    const defaultDateActivated = daysFromTodayStart.value != null && daysFromTodayEnd.value != null;

    const onDefaultDateSwitchChange = async () => {
      const payload = {
        daysFromTodayStart: defaultDateActivated ? null : 0,
        daysFromTodayEnd: defaultDateActivated ? null : 0
      };
      saveDefaultDateSettings(payload);
    };

    const onSaveButtonClick = () => {
      const payload = {
        daysFromTodayStart: daysFromTodayStartInput.value,
        daysFromTodayEnd: daysFromTodayEndInput.value
      };
      saveDefaultDateSettings(payload);
    };

    const saveDefaultDateSettings = async (payload: DefaultDateDaysPayload) => {
      emit("isLoading", true);
      await store.dispatch(SET_DEFAULT_DATES, payload);
      await store.dispatch(UPDATE_CONFIG_PROFILE);
      await store.commit(CLEAR_FLEET);
      await store.commit(RESET_LEGS);
      await store.commit(RESET_FLEET_ACTIONS);
      await store.dispatch(FETCH_BOARD_FLEET);
      emit("isLoading", false);
    };

    return {
      defaultDateActivated,
      daysFromTodayStart,
      daysFromTodayEnd,
      daysFromTodayStartInput,
      daysFromTodayEndInput,
      fromDate,
      toDate,
      onSaveButtonClick,
      onDefaultDateSwitchChange
    };
  }
});
</script>

<style scoped></style>
