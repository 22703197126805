var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close"
            }
          ],
          staticClass:
            "z-50 context-menu absolute bg-white shadow-lg rounded-sm border border-gray-200",
          style: _vm.positionCls
        },
        [
          _vm._t("content", null, {
            closeContextMenu: _vm.close,
            mouseEvent: _vm.mouseEvent
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }