<template>
  <div>
    <a-collapse :bordered="false" class="bg-gray-100 ant-collapse--single" :defaultActiveKey="defaultActiveKey">
      <a-collapse-panel
        :key="collapsePanelKey"
        :header="$t('previousLeg.title')"
        class="text-xl bg-gray-100 text-red-400"
      >
        <div class="text-base mt-2">
          <div class="grid grid-cols-4 gap-y-8">
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.legType") }}</div>
              <div>{{ data.legType || fallbackText }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("ranges.from") }}</div>
              <div>{{ from }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("ranges.toLocation") }}</div>
              <div>{{ to }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.vehicleNumber") }}</div>
              <div>{{ data.intermodalVehicleNumber || DEFAULT_TEXT }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.bookingNumber") }}</div>
              <div>{{ bookingId }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.departureDate") }}</div>
              <div>{{ departureDate }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.arrivalDate") }}</div>
              <div>{{ arrivalDate }}</div>
            </div>
            <div class="flex flex-col">
              <div class="text-gray-400 font-bold mb-1">{{ $t("previousLeg.irregularities") }}</div>
              <div>{{ data.hasIntermodalIrregularities ? data.irregularityDescription : $t("answers.no") }}</div>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { PreviousLeg, LegStatuses } from "@/types/leg";
import { dateStringToShowDate } from "@/use/useDate";

export default defineComponent({
  name: "LegDetailPreviousLegState",
  props: {
    data: {
      type: Object as PropType<PreviousLeg>,
      required: true
    },
    status: {
      type: Object as PropType<LegStatuses>,
      required: true
    }
  },
  setup(props) {
    const collapsePanelKey = "previousLegStateKey";
    const defaultActiveKey = computed<string>(() =>
      props.status.notBooked || props.status.prePlanned ? collapsePanelKey : ""
    );

    const DEFAULT_TEXT = "-";

    const from = computed<string>(() => props.data?.from?.name || DEFAULT_TEXT);

    const to = computed<string>(() => props.data?.to?.name || DEFAULT_TEXT);

    const bookingId = computed<string>(() => props.data?.booking?.id || DEFAULT_TEXT);

    const departureDate = computed<string>(() => {
      const { date } = props.data?.effectiveDepartureDate || {};
      return date ? `E: ${dateStringToShowDate(date)}` : DEFAULT_TEXT;
    });

    const arrivalDate = computed<string>(() => {
      const { date: effectiveArivalDate } = props.data?.effectiveArrivalDate || {};
      if (effectiveArivalDate) {
        return `E: ${dateStringToShowDate(effectiveArivalDate)}`;
      }
      const { date: plannedArrivalDate } = props.data?.plannedArrivalDate || {};
      return plannedArrivalDate ? `P: ${dateStringToShowDate(plannedArrivalDate)}` : DEFAULT_TEXT;
    });

    return {
      collapsePanelKey,
      defaultActiveKey,
      from,
      to,
      bookingId,
      departureDate,
      arrivalDate,
      DEFAULT_TEXT
    };
  }
});
</script>

<style scoped></style>
