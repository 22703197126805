var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "flex items-center", on: { click: _vm.showBoardSettings } },
    [
      _c("Icon", {
        staticClass: "mr-1",
        attrs: { name: "settings", height: "18", width: "18" }
      }),
      _vm._v(_vm._s(_vm.$t("settings.title")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }