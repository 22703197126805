<template>
  <VueDragResize
    ref="brickDragComponent"
    :w="actionCoordinates.width"
    :minw="10"
    :h="100"
    :snapToGrid="true"
    :gridY="100"
    :gridX="hourBoxWidth / 4"
    :parentLimitation="true"
    :parentW="parentWidth"
    :x="actionCoordinates.x"
    :y="actionCoordinates.y"
    :preventActiveBehavior="false"
    :sticks="resizeSticks"
    :axis="allowedAxisMovement"
    :isDraggable="draggable"
    :isResizable="updatable"
    @dragging="onDragging"
    @dragstop="onDragStop"
    @resizestop="onResizeStop"
    @activated="onActivated"
    @deactivated="onDeactivated"
    class="flex items-center drag-resize brick"
    :key="actionKey"
  >
    <span
      class="action-brick absolute w-full h-24 cursor-pointer py-2 shadow-lg border border-gray-400 px-2 rounded"
      :key="overlaySpanKey"
      :class="overlaySpanClass"
    />
    <div
      @dblclick="openActionDetails"
      class="action-brick absolute w-full h-24 cursor-pointer py-2 shadow-lg border border-gray-400 px-2 rounded"
      :class="{
        'action-brick__blocking-action': !actionData.isReminder,
        'action-brick__reminder': actionData.isReminder,
        'action-brick--disabled': isBoardEditActive
      }"
    >
      <div class="h-full overflow-hidden flex">
        <div class="w-full flex justify-between flex-no-wrap text-center">
          <div class="flex flex-col w-full">
            <div v-if="!actionData.isReminder" class="whitespace-nowrap">
              <div class="font-bold">{{ actionType }}</div>
              <div class="font-bold">{{ dateTimeInfo }}</div>
              <div>{{ actionData.remarks }}</div>
            </div>
            <div v-else>
              <Icon name="notifications" class="text-btYellow mb-1" width="25" height="25" />
              <div class="font-bold">{{ actionData.type }}</div>
            </div>
          </div>
        </div>
      </div>
      <BrickSpinner :is-active="actionIsUpdating" />
      <BrickActionBar
        v-if="actionIsDirty"
        :disabled="actionBarDisabled"
        @updateBrick="updateAction"
        @resetBrick="resetAction"
        class="brick-action-bar"
      />
    </div>
    <span
      @dblclick="openActionDetails"
      @click.right="setActionSelected"
      @mousedown="onMouseDown"
      class="absolute w-full h-24 cursor-pointer px-2 rounded"
      :class="[overlayClass, dirtyStateClass]"
    />
    <SearchMatchOverlay :search-id="searchId" outerSpanClass="h-24 rounded" />
  </VueDragResize>
</template>

<script lang="ts">
import VueDragResize from "vue-drag-resize";
import Icon from "@/components/Icon.vue";
import store from "@/store";

import { computed, defineComponent, inject, onMounted, ref, toRefs, watch, nextTick } from "@vue/composition-api";
import { GET_FROM_TO_DATE_WITH_TIME, GET_HOURBOX_WIDTH, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board/";
import { capitalizeFirst, generateRandomString } from "@/use/useText";
import { BoardFleetAction } from "@/types/action";
import { dateStringToShowDate } from "@/use/useDate";
import { GET_DIRTY_FLEET_ACTION_ID, GET_DIRTY_LEG_ID } from "@/store/brick";
import { TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION, TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS } from "@/store/fleet";
import useBoardActionDrag from "@/components/Board/Action/Use/useBoardActionDrag";
import BrickActionBar from "@/components/Board/Brick/BrickActionBar/BrickActionBar.vue";
import BrickSpinner from "@/components/Board/Brick/BrickSpinner/BrickSpinner.vue";
import SearchMatchOverlay from "@/components/SearchBox/SearchMatchOverlay/SearchMatchOverlay.vue";
import { buildSubcontractorId } from "@/use/useFleet";
import { buildSearchId } from "@/store/board/useSearchIndex";
import { BoardEntityType } from "@/types/board";
import { isAfter, isBefore } from "date-fns";
import { translateAndToastError } from "@/use/useToast";

export default defineComponent({
  name: "ActionBrick",
  components: {
    VueDragResize,
    Icon,
    BrickActionBar,
    BrickSpinner,
    SearchMatchOverlay
  },
  props: {
    actionData: {
      type: Object as () => BoardFleetAction
    }
  },
  setup(props, { emit }) {
    const { actionData: actionDataRef } = toRefs(props);
    const {
      brickDragComponent,
      allowedAxisMovement,
      actionIsUpdating,
      isDraggable,
      resizeSticks,
      actionIsDirty,
      resetAction,
      actionCoordinates,
      dirtyStateClass,
      updateAction,
      onDragStop,
      onDragging,
      onActivated,
      onResizeStop,
      setInitPosition,
      onDeactivated,
      initializeDropped
    } = useBoardActionDrag(actionDataRef);

    const hourBoxWidth = computed(() => store.getters[GET_HOURBOX_WIDTH]);
    const parentWidth = computed(() => store.state.board.board.offsetWidth);

    const searchId = computed(() => buildSearchId(props.actionData!.id, BoardEntityType.ACTION));

    const actionType = computed(() => {
      return capitalizeFirst(props.actionData!.type).replaceAll("_", " ");
    });

    const overlayClass = computed(() => {
      if (actionIsDirty.value) {
        return "";
      }
      const { trailerId, driverId } = props.actionData!;
      return (
        (trailerId && "action-brick__overlay--trailer") ||
        (driverId && "action-brick__overlay--driver") ||
        "action-brick__overlay--truck"
      );
    });

    const actionBarDisabled = computed(() => {
      return actionIsUpdating.value;
    });

    const dateTimeInfo = computed(() => {
      const { fromDate, toDate, fromLocation, toLocation } = props.actionData!;

      const fromTime = (fromDate?.date != null && dateStringToShowDate(fromDate?.date)) || "";
      const toTime = (toDate?.date != null && dateStringToShowDate(toDate?.date)) || "";
      const fromLocationName = (fromLocation != null && fromLocation.name) || "";
      const toLocationName = (toLocation != null && toLocation.name) || "";

      return `${fromLocationName} ${fromTime} - ${toTime} ${toLocationName}`;
    });

    const actionSize = computed(() => {
      const { width } = props.actionData!;
      return {
        sm: width <= 100,
        lg: width > 100
      };
    });

    const isBoardEditActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const dirtyLegId = computed<string>(() => store.getters[GET_DIRTY_LEG_ID]);
    const dirtyActionId = computed<string>(() => store.getters[GET_DIRTY_FLEET_ACTION_ID]);

    const updatable = computed(() => {
      return !!props.actionData?.updatable && !isBoardEditActive.value;
    });

    const draggable = computed(() => {
      return !!props.actionData?.updatable && isDraggable.value && !isBoardEditActive.value;
    });

    const subcontractorId = computed<string | undefined>(() => {
      const { subcontractor } = props.actionData || {};
      const { id, primaryBoard } = subcontractor || {};

      if (id && primaryBoard) {
        return buildSubcontractorId(id, primaryBoard.id, primaryBoard.sequence);
      }
      return undefined;
    });

    const openActionDetails = async () => {
      if (!actionIsDirty.value && !isBoardEditActive.value && !dirtyLegId.value && !dirtyActionId.value) {
        emit("showSplitScreen", {
          ...props.actionData,
          subcontractorId,
          payloadType: "action"
        });
        return;
      }

      if (dirtyActionId.value) {
        if (actionIsDirty.value) {
          return;
        }
        if (!actionIsUpdating.value) {
          await store.dispatch(TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION);
          return;
        }
      }

      if (dirtyLegId.value) {
        await store.dispatch(TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS);
      }
    };

    //shows the websocket update glow
    const overlaySpanKey = ref(generateRandomString());
    const overlaySpanClass = computed(() => {
      if (props.actionData?.updated) {
        overlaySpanKey.value = generateRandomString();
        return "fancy";
      } else {
        return "";
      }
    });

    onMounted(() => {
      if (props.actionData?.copied) {
        initializeDropped();
      }
      setInitPosition();
      document.addEventListener("animationend", event => {
        if (event.animationName.startsWith("glowy") && props.actionData?.updated) {
          props.actionData.updated = false;
        }
      });
      window.addEventListener("mouseup", onMouseUp);
    });

    const isMouseHoldDown = ref(false);
    const mouseHoldTimeout = ref(0);

    const onMouseDown = () => {
      mouseHoldTimeout.value = setTimeout(() => {
        isMouseHoldDown.value = true;
      }, 300);
    };

    const onMouseUp = () => {
      clearTimeout(mouseHoldTimeout.value);
      const { fromDate, toDate } = props.actionData || {};
      if (isMouseHoldDown.value && fromDate && toDate) {
        const { fromDate: boardFromDate, toDate: boardToDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];

        const actionTooLongStart = isBefore(new Date(fromDate.date), boardFromDate);
        const actionTooLongEnd = isAfter(new Date(toDate.date), boardToDate);
        if (actionTooLongStart && actionTooLongEnd) {
          translateAndToastError("errors.ACTION_LONGER_THAN_BOARD");
        } else if (actionTooLongStart) {
          translateAndToastError("errors.ACTION_LONGER_THAN_BOARD_END");
        } else if (actionTooLongEnd) {
          translateAndToastError("errors.ACTION_LONGER_THAN_BOARD_START");
        }
        isMouseHoldDown.value = false;
      }
    };

    const setSelectedAction: any = inject("setSelectedAction");

    const setActionSelected = async () => {
      setSelectedAction(props.actionData);
    };

    const actionKey = ref(Math.random());
    watch(
      [() => Math.floor(actionCoordinates.value.x), () => Math.floor(actionCoordinates.value.y)],
      async ([newX, newY]) => {
        await nextTick();
        setTimeout(() => {
          const epsilon = 5; // max pixel deviation

          const left = brickDragComponent.value.$el.offsetLeft;
          const top = brickDragComponent.value.$el.offsetTop;

          const diffX = Math.abs(left - newX);
          const diffY = Math.abs(top - newY);

          if (diffX > epsilon || diffY > epsilon) {
            console.info(`[INFO] Action with ID ${props.actionData?.id} was re-built due to incorrect position update`);
            actionKey.value = Math.random();
          }
        }, 250);
      },
      { flush: "post" }
    );

    return {
      actionKey,
      hourBoxWidth,
      parentWidth,
      actionType,
      dateTimeInfo,
      overlayClass,
      actionSize,
      searchId,
      isBoardEditActive,
      overlaySpanKey,
      overlaySpanClass,
      brickDragComponent,
      allowedAxisMovement,
      resizeSticks,
      actionIsDirty,
      actionBarDisabled,
      actionIsUpdating,
      resetAction,
      draggable,
      updatable,
      actionCoordinates,
      dirtyStateClass,
      updateAction,
      openActionDetails,
      onDragging,
      onDragStop,
      onResizeStop,
      onActivated,
      onDeactivated,
      setActionSelected,
      onMouseDown
    };
  }
});
</script>

<style lang="scss" scoped>
@use "ActionBrick";

.drag-resize {
  height: 100px !important;
}

.drag-resize:focus {
  outline: none;
}

.action-title {
  margin-left: -25px !important;
}

.action-icon {
  margin-bottom: -10px !important;
}

.brick-action-bar {
  z-index: 4;
}

.brick {
  z-index: 1 !important;
}
</style>
