var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c(
        "a-input",
        {
          ref: "searchInputField",
          staticClass: "flex",
          attrs: { placeholder: "Search..." },
          on: { pressEnter: _vm.onSearchNext, change: _vm.onSearchChange },
          model: {
            value: _vm.searchTerm,
            callback: function($$v) {
              _vm.searchTerm = $$v
            },
            expression: "searchTerm"
          }
        },
        [
          _c("Icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searching,
                expression: "searching"
              }
            ],
            staticClass: "-mr-2",
            attrs: {
              slot: "suffix",
              name: "spinner",
              height: "25",
              width: "25"
            },
            slot: "suffix"
          }),
          _c("Icon", {
            staticClass: "text-gray-400 -ml-2",
            attrs: {
              slot: "prefix",
              name: "search",
              height: "20",
              width: "20"
            },
            slot: "prefix"
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.searching,
                  expression: "!searching"
                }
              ],
              staticClass: "text-btYellow",
              attrs: { slot: "suffix" },
              slot: "suffix"
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    (_vm.showCounter &&
                      _vm.currentMatch + "/" + _vm.totalMatches) ||
                      ""
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _vm.showCounter
        ? _c(
            "span",
            {
              staticClass:
                "flex pt-2 px-1 border-y border-r border-t border-b border-gray-200 bg-gray-50"
            },
            [
              _c("Icon", {
                staticClass:
                  "mr-1 cursor-pointer hover:bg-gray-200 rounded-full",
                class: {
                  "pointer-events-none": !_vm.totalMatches,
                  "text-gray-200": !_vm.totalMatches
                },
                attrs: { name: "up", height: "20", width: "20" },
                on: { click: _vm.decreaseCurrentMatch }
              }),
              _c("Icon", {
                staticClass:
                  "mr-1 cursor-pointer hover:bg-gray-200 rounded-full",
                class: {
                  "pointer-events-none": !_vm.totalMatches,
                  "text-gray-200": !_vm.totalMatches
                },
                attrs: { name: "down", height: "20", width: "20" },
                on: { click: _vm.increaseCurrentMatch }
              }),
              _c("Icon", {
                staticClass:
                  "cursor-pointer hover:bg-gray-200 rounded-full p-0.5",
                attrs: { name: "close", height: "20", width: "20" },
                on: { click: _vm.clearSearch }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }