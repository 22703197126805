








































import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import useDriverRemarks from "@/components/Board/SideBar/SideBarCard/SideBarCardDriverRemarks/Use/useDriverRemarks";
import { Driver, FleetEntityDescription } from "@/types/fleet";
import store from "@/store";
import {
  ACTIVATE_DRIVER_COMMENT_TOGGLE,
  DEACTIVATE_DRIVER_COMMENT_TOGGLE,
  GET_ACTIVE_REMARKS_DESCRIPTION,
  UPDATE_ACTIVE_REMARKS_DESCRIPTION
} from "@/store/fleet";
import { translateAndToastWarn } from "@/use/useToast";
import { i18n } from "@/i18n/i18n";

export default defineComponent({
  name: "CardDriverRemarks",
  components: {
    Icon
  },
  props: {
    driver: {
      type: Object as () => Driver,
      required: true
    },
    cardDescription: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      driverCommentEditState,
      currentDriverRemarks,
      setSelectedDriver,
      unsetSelectedDriver,
      initDriver,
      updateDriver
    } = useDriverRemarks(props.driver);

    const unsavedRemarksWarning = (description: FleetEntityDescription): void => {
      const { entity, name } = description;
      const translationKey = `fleet.${entity}`;
      translateAndToastWarn("errors.UNSAVED_CHANGES", {
        dirtyItem: i18n.t("form.remarks"),
        entity: `${i18n.t(translationKey)} ${name}`
      });
    };

    const driverComment = ref<string>(props.driver.remarks || "");

    const activeRemarkDescription = computed<FleetEntityDescription | null>(
      () => store.getters[GET_ACTIVE_REMARKS_DESCRIPTION]
    );

    const toggleEdit = () => {
      if (driverCommentEditState.value) {
        unsetSelectedDriver();
        store.dispatch(UPDATE_ACTIVE_REMARKS_DESCRIPTION, null);
        return;
      }
      const driverWasSet = setSelectedDriver(props.driver.id);
      if (!driverWasSet) {
        unsavedRemarksWarning(activeRemarkDescription.value!);
      } else {
        store.dispatch(UPDATE_ACTIVE_REMARKS_DESCRIPTION, props.cardDescription);
      }
    };

    const saveComment = () => {
      updateDriver({
        id: props.driver.id,
        remarks: driverComment.value
      });
      toggleEdit();
    };

    const revertComment = () => {
      driverComment.value = currentDriverRemarks.value || "";
      toggleEdit();
    };

    const onFocus = () => {
      store.dispatch(DEACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    const blur = () => {
      store.dispatch(ACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    onMounted(() => {
      initDriver();
    });

    return {
      driverCommentEditState,
      driverComment,
      onFocus,
      blur,
      toggleEdit,
      saveComment,
      revertComment
    };
  }
});
