



































import { defineComponent, ref, provide, computed } from "@vue/composition-api";
import BoardFilter from "@/components/Board/Filter/BoardFilter.vue";
import BoardSideBar from "@/components/Board/SideBar/SideBar.vue";
import BoardTimeLine from "@/components/Board/TimeLine/TimeLine.vue";
import LegDetail from "@/components/Board/Leg/LegDetail/LegDetail.vue";
import FleetDetail from "@/components/Board/Fleet/FleetDetail/FleetDetail.vue";
import FleetActionReminder from "@/components/Board/Fleet/FleetAction/FleetAction.vue";
import SubcontractorDetail from "@/components/Board/Fleet/FleetDetail/SubcontractorDetail.vue";
import { BoardLeg } from "@/types/leg";
import { Truck, Driver, Trailer, Subcontractor, FleetEntityType, FleetEntity } from "@/types/fleet";
import { FleetAction, FleetActionPayload } from "@/types/action";
import store from "@/store";
import { GET_DIRTY_LEG_ID } from "@/store/brick";
import { ACTIVATE_DRIVER_COMMENT_TOGGLE, DEACTIVATE_DRIVER_COMMENT_TOGGLE } from "@/store/fleet";
import LoadingScreen from "@/components/Board/LoadingScreen/LoadingScreen.vue";

export default defineComponent({
  name: "Board",
  components: {
    BoardFilter,
    BoardSideBar,
    BoardTimeLine,
    LegDetail,
    FleetDetail,
    FleetActionReminder,
    SubcontractorDetail,
    LoadingScreen
  },
  props: {
    trucks: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const selectedLeg = ref<BoardLeg | null>(null);
    const selectedAffiliation = ref<FleetEntity | null>(null);
    const selectedFleetAction = ref<FleetActionPayload | null>(null);

    const legDetailActive = ref(false);
    const fleetDetailActive = ref(false);
    const fleetActionActive = ref(false);
    const subcontractorDetailActive = ref(false);
    const dirtyLegId = computed<string>(() => store.getters[GET_DIRTY_LEG_ID]);

    const showSplitScreen = (data: BoardLeg | FleetAction | FleetEntity) => {
      if (dirtyLegId.value != null) {
        return;
      }
      if ((data as Truck).type == FleetEntityType.TRUCK) {
        fleetDetailActive.value = true;
        selectedAffiliation.value = data as Truck;
      } else if ((data as Driver).type == FleetEntityType.DRIVER) {
        fleetDetailActive.value = true;
        selectedAffiliation.value = data as Driver;
      } else if ((data as Trailer).type == FleetEntityType.TRAILER) {
        fleetDetailActive.value = true;
        selectedAffiliation.value = data as Trailer;
      } else if ((data as Subcontractor).type == FleetEntityType.SUBCONTRACTOR) {
        subcontractorDetailActive.value = true;
        selectedAffiliation.value = data as Subcontractor;
      } else if ((data as FleetActionPayload).payloadType == "action") {
        selectedFleetAction.value = data as FleetActionPayload;
        fleetActionActive.value = true;
      } else {
        selectedLeg.value = data as BoardLeg;
        legDetailActive.value = true;
      }
      store.dispatch(DEACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    const closeSplitScreen = () => {
      legDetailActive.value = false;
      fleetDetailActive.value = false;
      fleetActionActive.value = false;
      subcontractorDetailActive.value = false;
      selectedFleetAction.value = null;
      store.dispatch(ACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    provide("showSplitScreen", showSplitScreen);

    return {
      selectedLeg,
      legDetailActive,
      selectedAffiliation,
      fleetDetailActive,
      fleetActionActive,
      selectedFleetAction,
      subcontractorDetailActive,
      showSplitScreen,
      closeSplitScreen
    };
  }
});
