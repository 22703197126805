<template>
  <div v-if="isLoading" class="loadingScreen w-full fixed z-50 top-0 flex justify-center items-center">
    <div class="loadingIndicator bg-btYellow flex flex-col justify-center items-center p-6 shadow-2xl">
      <Logo class="block" />
      <div class="pt-6">
        <a-spin size="large" class="text-white mr-6" />
        <span class="text-xl">{{ $t("states.loading") }}...</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import Logo from "@/components/Logo.vue";
import { FLEET_LOADING } from "@/store/fleet";
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: { Logo },
  setup() {
    const isLoading = computed(() => store.getters[FLEET_LOADING]);

    return { isLoading };
  }
});
</script>

<style scoped>
.loadingScreen {
  height: 100vh;
}
.loadingIndicator {
  width: 500px;
  height: 150px;
  box-sizing: content-box;
}
</style>
