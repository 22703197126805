<template>
  <div class="flex sideBarCard" :style="cardStyle">
    <div
      class="w-84 flex justify-center items-center"
      :class="{
        'w-84': !isGroupCard,
        'w-full': isGroupCard
      }"
    >
      <div
        class="h-full w-full flex justify-center items-center pb-1"
        :class="{
          'px-2': !isGroupCard,
          'pr-2': isGroupCard
        }"
      >
        <Card
          :class="{
            'sideBarCard--draggable': isDraggable,
            'sideBarCard--sortable-moved': content.moved
          }"
          :disabled="content.isOccupied"
          :search-id="searchId"
        >
          <template #header>
            <div class="flex justify-between items-center w-full">
              <div v-if="isTrailerCard && onlyRenderLeadingTitle && isGroupCard" class="text-lg text-yellow-900">
                <span>{{ trailer.groupGlueId }}</span>
              </div>
              <ClosablePopover
                v-else
                placement="rightTop"
                :overlayStyle="{
                  maxWidth: '700px',
                  'z-index': 20
                }"
                :align="{ targetOffset: [entityCallableOffset, 0] }"
              >
                <template #popoverContent>
                  <CalloutSideBar :content="content" :type="type" />
                </template>
                <template #popoverTrigger>
                  <div class="cursor-pointer text-lg">
                    <span v-if="isDriverCard">
                      <div
                        class="text-blue-400"
                        :class="{
                          capitalize: !hasDriverSubcontractor,
                          'leading-4 pt-1': hasDriverSubcontractor
                        }"
                      >
                        {{ $t("fleet.driver") }}
                      </div>
                      <div v-if="hasDriverSubcontractor" class="text-xs leading-3">{{ driver.subcontractorName }}</div>
                    </span>
                    <span v-if="isTrailerCard && !isGroupCard" class="text-yellow-900 capitalize">{{
                      isGroupCard ? trailer.groupGlueId : $t("fleet.trailer")
                    }}</span>
                    <span v-if="isTruckCard">
                      <div
                        class="text-gray-900"
                        :class="{
                          capitalize: !hasDriverSubcontractor,
                          'text-base leading-4 pt-1': hasDriverSubcontractor
                        }"
                      >
                        {{ truckDisplayId }}
                      </div>
                      <div v-if="hasDriverSubcontractor" class="text-xs leading-3">{{ driver.subcontractorName }}</div>
                    </span>
                    <span v-if="isSubcontractorCard">
                      <div
                        class="text-green-600 capitalize"
                        :class="{
                          'text-base leading-4 pt-1': hasSubcontractorPrimaryBoard
                        }"
                      >
                        {{ subcontractor.name }}
                      </div>
                      <div v-if="hasSubcontractorPrimaryBoard" class="text-xs leading-3">
                        {{ subcontractor.primaryBoardId }}
                      </div>
                    </span>
                  </div>
                </template>
              </ClosablePopover>
              <div class="ml-auto flex flex-row">
                <div v-if="content.actions" class="cursor-pointer">
                  <div v-if="content.actions.length">
                    <ClosablePopover
                      placement="rightTop"
                      :overlayStyle="{ maxWidth: '700px' }"
                      :align="{ targetOffset: [-60, 0] }"
                    >
                      <template #popoverContent>
                        <CalloutSidebarActionDetail
                          :actions="content.actions"
                          :key="content.actions.length"
                          @onFleetAction="showSplitScreen"
                        />
                      </template>
                      <template #popoverTrigger>
                        <Icon name="clock" class="text-btYellow p-1" />
                      </template>
                    </ClosablePopover>
                  </div>
                </div>
                <LastLocationAction :fleet-entity="content" />
              </div>
              <a-popover
                v-model="isMenuVisible"
                placement="bottomRight"
                trigger="click"
                overlayClassName="menu-popover"
              >
                <div class="cursor-pointer h-full" :class="{ 'cursor-not-allowed': isDraggable }">
                  <Icon name="3dots" :class="{ 'text-gray-400': isDraggable }" />
                </div>
                <template #content>
                  <SidebarActionCallout
                    :content="content"
                    @onAffiliate="showSplitScreen"
                    @onFleetAction="showSplitScreen"
                    @closePopover="closePopover($event)"
                  />
                </template>
              </a-popover>
            </div>
          </template>
          <Carousel :draggable="true" ref="cardCarouselRef">
            <div class="flex-grow flex py-1 h-full">
              <div class="pr-1 flex flex-grow">
                <div class="w-1/2 pr-2 box-border">
                  <div v-if="isTrailerCard || trailer">
                    <div v-if="!trailer.groupGlueId">{{ spreadTrailerId(trailer.id).id }}</div>
                    <ClosablePopover
                      v-else
                      placement="rightTop"
                      :overlayStyle="{
                        maxWidth: '700px',
                        'z-index': 20
                      }"
                      :align="{ targetOffset: [-142, 33] }"
                    >
                      <template #popoverContent>
                        <CalloutSideBar :content="content" :type="type" />
                      </template>
                      <template #popoverTrigger>
                        <div class="cursor-pointer">
                          <div>{{ spreadTrailerId(trailer.id).id }}</div>
                        </div>
                      </template>
                    </ClosablePopover>
                    <div class="text-xxs">{{ trailer.metadata.chassisType }}</div>
                    <div class="text-xxs">{{ trailer.metadata.rotaryValveBrand }}</div>
                  </div>
                  <div v-if="isSubcontractorCard && subcontractor.flatTruckName">
                    <div>{{ subcontractor.flatTruckName }}</div>
                  </div>
                </div>
                <div class="w-1/2 pl-2 box-border fixed-name-box">
                  <a-tooltip placement="top" :title="isNameTruncated ? `${driver.firstName} ${driver.lastName}` : ''">
                    <div v-if="isDriverCard || driver">
                      <div ref="firstNameRef" class="truncate">{{ driver.firstName }}</div>
                      <div ref="lastNameRef" class="truncate">{{ driver.lastName }}</div>
                    </div>
                  </a-tooltip>
                  <div v-if="isSubcontractorCard && subcontractor.flatDriverName">
                    <div>{{ subcontractor.flatDriverName }}</div>
                  </div>
                </div>
              </div>
              <div class="sideBarCard__actions h-full flex flex-col">
                <div class="sideBarCard__actions__icon">
                  <a-tooltip placement="right" :align="{ offset: [-5, 0] }" :title="$t('fleet.noAdr')">
                    <Icon v-if="hasNoAdr" name="no-adr" height="20" :original="true" />
                  </a-tooltip>
                </div>
                <div class="sideBarCard__actions__icon">
                  <a-tooltip placement="right" :align="{ offset: [-5, 0] }" :title="$t('fleet.compressor')">
                    <Icon v-if="hasCompressor" name="compressor" height="20" :original="true" />
                  </a-tooltip>
                </div>
                <div class="sideBarCard__actions__icon" v-if="hasAdditionalCooler">
                  <a-tooltip placement="right" :align="{ offset: [-5, 0] }" :title="$t('fleet.additionalCooler')">
                    <Icon v-if="hasAdditionalCooler" name="additional-cooler" height="20" :original="true" />
                  </a-tooltip>
                </div>

                <div class="sideBarCard cursor-pointer" v-if="hasDropWithTrailer">
                  <ClosablePopover
                    placement="rightTop"
                    :overlayStyle="{
                      maxWidth: '600px',
                      'z-index': 20
                    }"
                    :align="{ targetOffset: [-5, 0] }"
                  >
                    <template #popoverContent>
                      <CalloutLegContainer
                        :title="getLastLocationContainerId(trailer.metadata.lastLocation)"
                        :content="trailer"
                        class="px-2"
                      />
                    </template>
                    <template #popoverTrigger>
                      <a-tooltip placement="right" :align="{ offset: [-5, 0] }" :title="$t('fleet.dropWithTrailer')">
                        <Icon name="trailerdrop" height="20" :original="true" />
                      </a-tooltip>
                    </template>
                  </ClosablePopover>
                </div>
              </div>
            </div>
            <CardDriverRemarks v-if="driver" :driver="driver" :card-description="cardDescription" />
          </Carousel>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from "@vue/composition-api";
import Card from "@/components/Card.vue";
import Icon from "@/components/Icon.vue";
import CalloutSideBar from "@/components/Board/CallOuts/CalloutSideBar/CalloutSideBar.vue";
import SidebarActionCallout from "@/components/Board/CallOuts/CalloutSidebarAction/CalloutSidebarAction.vue";
import CalloutSidebarActionDetail from "@/components/Board/CallOuts/CalloutSidebarAction/CalloutSidebarActionDetail.vue";
import LastLocationAction from "@/components/Board/CallOuts/CalloutSideBarLastLocation/LastLocation.vue";
import store from "@/store";
import { GET_FLEET_SPANS } from "@/store/fleet";
import { Driver, FleetEntityType, Subcontractor, Trailer, Truck, FleetEntityDescription } from "@/types/fleet";
import { isAfter } from "date-fns";
import { Carousel } from "ant-design-vue";
import CardDriverRemarks from "@/components/Board/SideBar/SideBarCard/SideBarCardDriverRemarks/SideBarCardDriverRemarks.vue";
import ClosablePopover from "@/components/ClosablePopover.vue";
import { spreadTruckId, spreadTrailerId } from "@/use/useFleet";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import { buildSearchId } from "@/store/board/useSearchIndex";
import { BoardEntityType } from "@/types/board";
import { getLastLocationDropWithTrailer, getLastLocationContainerId, getLastLocationInfo } from "@/use/useLastLocation";

export default defineComponent({
  name: "BoardSideBarCard",
  components: {
    Card,
    Icon,
    CalloutSideBar,
    LastLocationAction,
    SidebarActionCallout,
    CalloutSidebarActionDetail,
    Carousel,
    CardDriverRemarks,
    ClosablePopover,
    CalloutLegContainer
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    type: {
      type: String
    },
    isDraggable: {
      type: Boolean
    },
    isGroupCard: {
      type: Boolean
    },
    onlyRenderLeadingTitle: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const isMenuVisible = ref(false);
    const closePopover = (): void => {
      isMenuVisible.value = false;
    };

    const showSplitScreen = inject("showSplitScreen");
    const isTrailerCard = computed<boolean>(() => props.type === FleetEntityType.TRAILER);
    const isTruckCard = computed<boolean>(() => props.type === FleetEntityType.TRUCK);
    const isSubcontractorCard = computed<boolean>(() => props.type === FleetEntityType.SUBCONTRACTOR);
    const isDriverCard = computed<boolean>(() => props.type === FleetEntityType.DRIVER);
    const searchId = computed(() => buildSearchId(props.content!.id, BoardEntityType.FLEET));
    const COMPRESSORS: (string | undefined)[] = ["BRAETSCH", "MOUVEX B200"];
    const hasDriverSubcontractor = computed<boolean>(
      () => driver.value != undefined && driver.value.subcontractorId != undefined
    );
    const hasSubcontractorPrimaryBoard = computed<boolean>(() => subcontractor.value?.primaryBoardId != undefined);

    const driver = computed<Driver | undefined>(() => {
      const { content } = props;
      if (isTruckCard.value) {
        return (content as Truck).crewDriver;
      }
      return isDriverCard.value ? (content as Driver) : undefined;
    });

    const trailer = computed<Trailer | undefined>(() => {
      const { content } = props;
      if (isTruckCard.value) {
        return (content as Truck).trailer;
      }
      return isTrailerCard.value ? (content as Trailer) : undefined;
    });

    const subcontractor = computed<Subcontractor | undefined>(() => {
      const { content } = props;
      return isSubcontractorCard.value ? (content as Subcontractor) : undefined;
    });

    const fleetSpans = computed(() => store.getters[GET_FLEET_SPANS]);

    const cardStyle = computed(() => ({
      height: `${fleetSpans.value[props.content?.id] || 100}px`
    }));

    const onAffiliateAction = fleetMember => {
      emit("showFleetDetail", fleetMember);
    };

    const isSingleTrailer = trailer => {
      return trailer.type;
    };

    const hasNoAdr = computed<boolean>(() => {
      if ((!isTrailerCard.value && !isTruckCard.value) || driver.value?.subcontractorId) {
        return false;
      }
      const { nextADRTest } = props.content.metadata;
      if (nextADRTest) {
        const currentDate = new Date();
        const nextTestDate = new Date(nextADRTest);
        return isAfter(currentDate, nextTestDate);
      }
      return true;
    });

    const hasCompressor = computed<boolean>(() => {
      const { content } = props;
      if (!isTruckCard.value) {
        return false;
      }
      const truck = content as Truck;
      return COMPRESSORS.includes(truck.metadata.compressor?.toUpperCase());
    });

    const hasAdditionalCooler = computed<boolean>(() => {
      return !!trailer.value?.metadata.additionalCooler;
    });

    const hasDropWithTrailer = computed<boolean>(() => {
      return getLastLocationDropWithTrailer(trailer.value?.metadata.lastLocation) && isSingleTrailer(trailer.value);
    });

    const truckDisplayId = computed<string | undefined>(() => {
      const { content } = props;
      return (isTruckCard.value && spreadTruckId(content.id).id) || undefined;
    });

    const cardCarouselRef = ref<any>(null);

    const addCarouselRef: any = inject("addCarouselRef");
    const removeCarouselRef: any = inject("removeCarouselRef");

    onMounted(() => {
      addCarouselRef(props.content.id, cardCarouselRef);
    });

    onUnmounted(() => {
      removeCarouselRef(props.content.id);
    });

    const cardDescription = computed<FleetEntityDescription | undefined>(() => {
      if (isTruckCard.value) {
        return { entity: FleetEntityType.TRUCK, name: props.content.id };
      }
      if (isSubcontractorCard.value) {
        return { entity: FleetEntityType.SUBCONTRACTOR, name: props.content.name };
      }
      if (isTrailerCard.value) {
        return { entity: FleetEntityType.TRAILER, name: props.content.id };
      }
      if (isDriverCard.value) {
        return { entity: FleetEntityType.DRIVER, name: `${props.content.firstName} ${props.content.lastName}` };
      }
    });

    const firstNameRef = ref<HTMLSpanElement | null>(null);
    const lastNameRef = ref<HTMLSpanElement | null>(null);
    const isNameTruncated = computed<boolean>(() => {
      if (firstNameRef.value != null && lastNameRef.value != null) {
        return (
          firstNameRef.value.offsetWidth < firstNameRef.value.scrollWidth ||
          lastNameRef.value.offsetWidth < lastNameRef.value.scrollWidth
        );
      }
      return false;
    });

    const entityCallableOffset = computed<number>(() => {
      const defaultOffset = -235;
      const charWidth = 9;
      const staticOffset = -190;
      if (isTruckCard.value) {
        return truckDisplayId.value != null ? truckDisplayId.value.length * charWidth + defaultOffset : defaultOffset;
      }
      if (isTrailerCard.value || isDriverCard.value) {
        return staticOffset;
      }
      if (isSubcontractorCard.value) {
        return subcontractor.value ? subcontractor.value.name.length * charWidth + defaultOffset : defaultOffset;
      }

      return defaultOffset;
    });

    return {
      firstNameRef,
      lastNameRef,
      isNameTruncated,
      cardDescription,
      closePopover,
      driver,
      trailer,
      subcontractor,
      cardStyle,
      onAffiliateAction,
      showSplitScreen,
      hasNoAdr,
      hasCompressor,
      hasAdditionalCooler,
      isTrailerCard,
      isTruckCard,
      isSubcontractorCard,
      isDriverCard,
      searchId,
      isMenuVisible,
      hasDriverSubcontractor,
      hasSubcontractorPrimaryBoard,
      cardCarouselRef,
      truckDisplayId,
      hasDropWithTrailer,
      entityCallableOffset,
      spreadTrailerId,
      getLastLocationContainerId,
      getLastLocationInfo
    };
  }
});
</script>

<style lang="scss">
@use "SideBarCard";
.menu-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
.fixed-name-box {
  max-width: 100px;
}
</style>
