













import { defineComponent } from "@vue/composition-api";
import SearchMatchOverlay from "@/components/SearchBox/SearchMatchOverlay/SearchMatchOverlay.vue";

export default defineComponent({
  name: "Card",
  components: {
    SearchMatchOverlay
  },
  props: {
    disabled: {
      type: Boolean
    },
    searchId: {
      type: String
    }
  }
});
