


















































































































































































































































































































































import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, toRefs } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import LegDetailHeader from "@/components/Board/Leg/LegDetail/LegDetailHeader/LegDetailHeader.vue";
import LegDetailOpeningHours from "@/components/Board/Leg/LegDetail/LegDetailOpeningHours/LegDetailOpeningHours.vue";
import LegDetailTimeSlot from "@/components/Board/Leg/LegDetail/LegDetailTimeSlot/LegDetailTimeSlot.vue";
import LegDetailStepSection from "@/components/Board/Leg/LegDetail/LegDetailStepSection/LegDetailStepSection.vue";
import LegDetailCheckList from "@/components/Board/Leg/LegDetail/LegDetailChecklist/LegDetailChecklist.vue";
import LegDetailPreviousLegState from "@/components/Board/Leg/LegDetail/LegDetailPreviousLegState/LegDetailPreviousLegState.vue";
import LegDetailPlannedDates from "@/components/Board/Leg/LegDetail/LegDetailPlannedDates/LegDetailPlannedDates.vue";
import LegDetailRemarks from "@/components/Board/Leg/LegDetail/LegDetailRemarks/LegDetailRemarks.vue";
import Icon from "@/components/Icon.vue";
import { isEmpty } from "@/use/useObject";
import store from "@/store";
import { Leg, BoardLeg, LegFormPayload, LegTransportChecklist, LoadingUnloadingInfo, LegStop } from "@/types/leg";
import { LOCK_LEG, UNLOCK_LEG, UPDATE_LEG } from "@/store/brick";
import Dialog from "@/components/Dialog.vue";
import LegDetailMultiSelectFleet from "@/components/Board/Leg/LegDetail/LegDetailMultiSelectFleet/LegDetailMultiFleet.vue";
import { i18n } from "@/i18n/i18n";
import Store from "@/store/index";
import { GET_SELECTED_BOARD_IDS } from "@/store/board";
import { translateAndToastSuccess } from "@/use/useToast";
import { toLocalDateTimeFull, dateStringToShowDate } from "@/use/useDate";
import { LegPlannedFields } from "@/types/leg";

export default defineComponent({
  name: "LegDetail",
  components: {
    SplitScreen,
    Button,
    LegDetailHeader,
    LegDetailOpeningHours,
    LegDetailTimeSlot,
    LegDetailStepSection,
    LegDetailPlannedDates,
    LegDetailRemarks,
    Icon,
    Dialog,
    LegDetailMultiSelectFleet,
    LegDetailCheckList,
    LegDetailPreviousLegState
  },

  props: {
    selectedLeg: {
      type: Object as () => BoardLeg,
      default: Object as () => BoardLeg
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legType, legStatus, statusClass, unloadingTemperature } = useLegStatus(selectedLegRef);

    const formData = ref<BoardLeg>({ ...props.selectedLeg });

    const plannedDatesWereModified = ref(false);
    const plannedDatesAreValid = ref(true);

    const plannedDates = reactive({
      plannedDepartureDate: formData.value?.events?.plannedDepartureDate?.date,
      plannedArrivalDate: formData.value?.events?.plannedArrivalDate?.date
    });

    const clearFleet = () => {
      (formData.value.truckId = null), (formData.value.driverId = null), (formData.value.subcontractor = null);
    };

    const checkListChanged = ref(false);

    const onChecklistChanged = (payload: LegTransportChecklist) => {
      formData.value.status = payload.status;
      formData.value.transportInfo = payload;
      checkListChanged.value = true;
    };

    const initialLegRemarks = computed<string>(() => {
      return props.selectedLeg.details?.remarks || "";
    });

    const onRemarksChange = (remarks: string): void => {
      formData.value.details = {
        ...formData.value.details,
        remarks: remarks
      };
    };

    const legWasModified = computed(() => {
      return (
        (props.selectedLeg?.truckId !== formData.value.truckId ||
          props.selectedLeg?.driverId !== formData.value.driverId ||
          props.selectedLeg?.trailerId !== formData.value.trailerId ||
          props.selectedLeg?.subcontractor?.id !== formData.value.subcontractor?.id ||
          props.selectedLeg?.details?.subconTruckName != formData.value.details?.subconTruckName ||
          props.selectedLeg?.details?.subconDriverName != formData.value.details?.subconDriverName ||
          props.selectedLeg?.details?.remarks != formData.value.details?.remarks ||
          checkListChanged.value ||
          plannedDatesWereModified.value) &&
        plannedDatesAreValid.value
      );
    });

    const updateLegForm = payload => {
      const { type, value } = payload as LegFormPayload;
      if (type === "fleet") {
        formData.value = {
          ...formData.value,
          ...value
        };
      }
      if (type === "details") {
        formData.value.details = {
          ...formData.value.details,
          ...value
        };
      }
    };

    const updateLeg = async (leg: Leg) => {
      isLoading.value = true;
      try {
        await store.dispatch(UPDATE_LEG, leg);
        isLoading.value = false;
        closeLegDetail();
        translateAndToastSuccess("leg.updated");
      } catch (e) {
        isLoading.value = false;
      }
    };

    const isUpdating = ref(false);
    const saveChanges = async () => {
      isUpdating.value = true;

      //check if we selected a subcontractor then we need to add the selected board to the leg
      if (formData.value.subcontractor?.id) {
        formData.value.boards = formData.value.boards
          ? [...formData.value.boards, ...Store.getters[GET_SELECTED_BOARD_IDS]]
          : [...Store.getters[GET_SELECTED_BOARD_IDS]];
      }

      if (plannedDates.plannedDepartureDate != null) {
        if (formData.value.events?.plannedDepartureDate) {
          formData.value.events.plannedDepartureDate.date = plannedDates.plannedDepartureDate;
        } else {
          formData.value.events = {
            ...(formData.value?.events || {}),
            plannedDepartureDate: { date: plannedDates.plannedDepartureDate }
          };
        }
      } else {
        formData.value.events!.plannedDepartureDate = undefined;
      }

      if (plannedDates.plannedArrivalDate != null && formData.value.events?.plannedArrivalDate) {
        if (formData.value.events?.plannedArrivalDate) {
          formData.value.events.plannedArrivalDate.date = plannedDates.plannedArrivalDate;
        } else {
          formData.value.events = {
            ...(formData.value?.events || {}),
            plannedArrivalDate: { date: plannedDates.plannedArrivalDate }
          };
        }
      } else {
        formData.value.events!.plannedArrivalDate = undefined;
      }
      await updateLeg(formData.value);
      isUpdating.value = false;
    };

    const confirmDeleteLeg = () => {
      dialog.isActive = true;
      dialog.title = i18n.t("popup.thinkAboutIt").toString();
      dialog.message = i18n
        .t("popup.deleteLeg", {
          legId: props.selectedLeg?.orderNumberAlt
        })
        .toString();
      dialog.isCentered = true;
      dialog.showButtons = true;
    };

    const deleteLeg = async () => {
      if (typeof props.selectedLeg !== "undefined") {
        await updateLeg({
          ...props.selectedLeg,
          truckId: null,
          driverId: null,
          trailerId: null,
          subcontractor: null
        });
      }
    };

    //handle leg lock unlock stuff
    const legIsLocked = ref(false);
    const lockLeg = async () => {
      if ((legStatus.value.notBooked || legStatus.value.prePlanned) && typeof props.selectedLeg !== "undefined") {
        try {
          await store.dispatch(LOCK_LEG, props.selectedLeg.id);
          legIsLocked.value = true;
        } catch {
          //do some not global error handling here
        }
      }
    };
    const unlockLeg = async () => {
      if ((legStatus.value.notBooked || legStatus.value.prePlanned) && typeof props.selectedLeg !== "undefined") {
        try {
          await store.dispatch(UNLOCK_LEG, props.selectedLeg.id);
          legIsLocked.value = false;
        } catch {
          //do some not global error handling here
        }
      }
    };

    const isLoading = ref(false);
    const dialog = reactive({
      isActive: false,
      showButtons: false,
      isCentered: true,
      title: "",
      message: ""
    });

    const eventIsRead = ref(false);
    const setEventIsRead = value => (eventIsRead.value = value);
    const closeLegDetail = () => {
      emit("legDetailClosed"); //tells parent that we closed the splittscreen
    };

    onMounted(() => {
      lockLeg();
    });

    onUnmounted(() => {
      unlockLeg();
    });

    const getPlannedDate = (date: moment.Moment | null, time: moment.Moment | null): string | undefined => {
      if (date != null) {
        if (time != null) {
          return toLocalDateTimeFull(date.toDate(), time.hour(), time.minutes());
        }
        return date.format("YYYY-MM-DD");
      }
      return undefined;
    };

    const updatePlannedDates = (planned: LegPlannedFields) => {
      const { fromDate, fromTime, toDate, toTime, valid } = planned;
      plannedDates.plannedDepartureDate = getPlannedDate(fromDate, fromTime);
      plannedDates.plannedArrivalDate = getPlannedDate(toDate, toTime);

      plannedDatesAreValid.value = valid;
      plannedDatesWereModified.value = true;
    };

    const loadingUnloadingData = computed<LoadingUnloadingInfo>(() => {
      const { collection, delivery, fullTransport, dropSwap } = legType.value;
      const { loading, unloading } = props.selectedLeg?.stops;

      if (collection) {
        return {
          title: i18n.t("dispo.loadingPoint"),
          loadingPoint: loading?.point
        } as LoadingUnloadingInfo;
      }

      if (delivery) {
        return {
          title: i18n.t("dispo.unloadingPoint"),
          unloadingPoint: unloading?.point
        } as LoadingUnloadingInfo;
      }

      if (dropSwap || fullTransport) {
        return {
          title: i18n.t("dispo.loadingAndUnloadingPoint"),
          loadingPoint: `L: ${loading?.point || ""}`,
          unloadingPoint: `U: ${unloading?.point || ""}`
        } as LoadingUnloadingInfo;
      }

      return { title: i18n.t("dispo.loadingPoint") } as LoadingUnloadingInfo;
    });

    const getFullLoadingUnloadingAddressInfo = (cargoProcess?: LegStop) => {
      const { longName, name, street, countryCode = "", postalCode = "", city = "" } = cargoProcess || {};
      return {
        name,
        longName,
        street,
        countryAndCity: `${countryCode} ${postalCode} ${city}`.trim()
      };
    };

    const fullLoadingAddress = computed(() => getFullLoadingUnloadingAddressInfo(props.selectedLeg?.stops?.loading));

    const fullUnloadingAddress = computed(() =>
      getFullLoadingUnloadingAddressInfo(props.selectedLeg?.stops?.unloading)
    );

    const plannedLoadingAndUnloading = computed(() => {
      const { plannedLoading, plannedUnloading } = props.selectedLeg.events || {};
      const plannedLoadingDate = plannedLoading?.date ? dateStringToShowDate(plannedLoading.date, "dd.MM") : "";
      const plannedUnloadingDate = plannedUnloading?.date ? dateStringToShowDate(plannedUnloading.date, "dd.MM") : "";

      return { plannedLoadingDate, plannedUnloadingDate };
    });

    return {
      initialLegRemarks,
      onRemarksChange,
      fullLoadingAddress,
      fullUnloadingAddress,
      loadingUnloadingData,
      isUpdating,
      isEmpty,
      closeLegDetail,
      saveChanges,
      deleteLeg,
      confirmDeleteLeg,
      legType,
      legStatus,
      statusClass,
      isLoading,
      dialog,
      setEventIsRead,
      eventIsRead,
      legIsLocked,
      updateLegForm,
      legWasModified,
      clearFleet,
      formData,
      onChecklistChanged,
      plannedDates,
      updatePlannedDates,
      unloadingTemperature,
      plannedLoadingAndUnloading
    };
  }
});
