

























import { defineComponent, computed } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store";
import { GET_DRIVER_BY_ID, FETCH_DRIVER } from "@/store/fleet";

export default defineComponent({
  name: "CalloutLegDriver",
  props: {
    driverId: {
      type: String,
      default: "",
      required: true
    }
  },
  components: { Icon },
  setup(props) {
    const driverData = computed(() => {
      const { driverId } = props;
      const driver = store.getters[GET_DRIVER_BY_ID](driverId);
      if (driver == null) {
        store.dispatch(FETCH_DRIVER, driverId);
      }
      return driver;
    });

    return {
      driverData
    };
  }
});
