




import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  setup(props) {
    const dynamicClass = computed(() => {
      return `tag--${props.variant}`;
    });
    return {
      dynamicClass
    };
  }
});
