var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col justify-center items-center" },
    [
      _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c("Icon", {
            staticClass: "mt-1",
            attrs: { name: "remove", height: "20" }
          }),
          _c("a-slider", {
            staticClass: "w-32",
            attrs: { min: 0, max: 15, "default-value": 1 },
            on: { afterChange: _vm.handleBoardZoom },
            model: {
              value: _vm.sliderValue,
              callback: function($$v) {
                _vm.sliderValue = $$v
              },
              expression: "sliderValue"
            }
          }),
          _c("Icon", {
            staticClass: "mt-1",
            attrs: { name: "add", height: "20" }
          })
        ],
        1
      ),
      _vm.loading ? _c("overlay") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }