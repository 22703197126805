var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.legStatuses.departed ||
    _vm.legStatuses.unloaded ||
    _vm.legStatuses.loaded ||
    _vm.legStatuses.booked
    ? _c(
        "div",
        {
          staticClass:
            "step-progress-indicator w-full flex flex-nowrap overflow-hidden"
        },
        _vm._l(_vm.stepGroups, function(group, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "flex flex-row",
              class: { "flex-1": !group.hasEnd },
              style: group.hasEnd
                ? { width: group.width + "px", minWidth: group.width + "px" }
                : null
            },
            _vm._l(group.steps, function(step, index) {
              var _obj
              return _c("div", {
                key: "step-" + index,
                staticClass: "step-border box-border flex-1",
                class:
                  ((_obj = {}),
                  (_obj[_vm.defineStepColorCls(step.status || "")] = true),
                  (_obj.border = group.width > 0),
                  _obj)
              })
            }),
            0
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }