













import { computed, defineComponent, ref } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import store from "@/store";
import { GET_LANGUAGE, UPDATE_LANGUAGE } from "@/store/board";
import { LANG_DE, LANG_EN } from "@/i18n/i18n";

export default defineComponent({
  name: "LanguageSwitcher",
  components: { multiSelect },
  setup(props, { root }) {
    const language = computed(() => store.getters[GET_LANGUAGE]);

    const languages = ref([
      { key: LANG_EN, value: "English" },
      { key: LANG_DE, value: "Deutsch" }
    ]);
    const actualLanguage = computed(() => {
      return languages.value.find(language => language.key === root.$i18n.locale);
    });

    const languageChanged = value => {
      //save it to user Profile
      store.dispatch(UPDATE_LANGUAGE, value.key);
    };

    return {
      languageChanged,
      actualLanguage,
      languages,
      language
    };
  }
});
