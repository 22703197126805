import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/i18n/locales/en.json";
import de from "@/i18n/locales/de.json";
import { getBrowserLang } from "@/use/useBrowserLang";

const LANG_EN = "en";
const LANG_DE = "de";

Vue.use(VueI18n);
const messages = {
  en,
  de
};
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: getBrowserLang(), // set locale default
  messages // set locale messages
});

export { i18n, LANG_EN, LANG_DE };
