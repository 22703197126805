import { FleetEntityType, FleetIdentifier } from "@/types/fleet";
import { BoardBrick, BoardBrickType } from "@/types/board";
import { i18n } from "@/i18n/i18n";
import { translateAndToastError } from "./useToast";
import { BoardFleetAction } from "@/types/action";

const getBrickFleetIdentifier = (brick: BoardBrick): FleetIdentifier | undefined => {
  const { truckId, subcontractor, trailerId, driverId } = brick;
  if (truckId) {
    return { type: FleetEntityType.TRUCK, id: truckId };
  }
  if (subcontractor) {
    return { type: FleetEntityType.SUBCONTRACTOR, id: subcontractor.id };
  }
  if (trailerId) {
    return { type: FleetEntityType.TRAILER, id: trailerId };
  }
  if (driverId) {
    return { type: FleetEntityType.DRIVER, id: driverId };
  }
};

const brickAssignableToFleetEntity = (entityType: FleetEntityType, brick: BoardBrick) => {
  const { truckId, trailerId, driverId, subcontractor } = brick;
  let brickType = "Leg";
  if (brick.brickType === BoardBrickType.ACTION) {
    brickType = i18n
      .t((brick as BoardFleetAction).isReminder ? "actionReminder.reminder" : "actionReminder.action")
      .toString();
  }

  if (entityType === FleetEntityType.TRUCK && !truckId) {
    translateAndToastError("errors.FLEET_INVALID_BRICK_DROP", {
      brickType,
      fleetEntityType: i18n.t("fleet.truck").toString()
    });
    return false;
  }

  if (entityType === FleetEntityType.TRAILER && !trailerId) {
    translateAndToastError("errors.FLEET_INVALID_BRICK_DROP", {
      brickType,
      fleetEntityType: i18n.t("fleet.trailer").toString()
    });
    return false;
  }

  if (entityType === FleetEntityType.DRIVER && !driverId) {
    translateAndToastError("errors.FLEET_INVALID_BRICK_DROP", {
      brickType,
      fleetEntityType: i18n.t("fleet.driver").toString()
    });
    return false;
  }

  if (entityType === FleetEntityType.SUBCONTRACTOR && !subcontractor) {
    translateAndToastError("errors.FLEET_INVALID_BRICK_DROP", {
      brickType,
      fleetEntityType: i18n.t("fleet.subcontractor").toString()
    });
    return false;
  }
  return true;
};

export { getBrickFleetIdentifier, brickAssignableToFleetEntity };
