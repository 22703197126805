import { $axios } from "@/config/axios";
import { AxiosResponse } from "axios";
import { Chat, ChatMessagePost } from "@/types/chat";

const baseSlug = "/v1/chats";

interface Chats {
  chats: Chat[];
}

export default {
  async getChats(boardIds: string[]): Promise<AxiosResponse<Chats>> {
    return await $axios.get(`${baseSlug}`, {
      params: { boardIds: boardIds.join(",") }
    });
  },
  async readChatMessages(bookingId: string): Promise<AxiosResponse<Chat>> {
    return await $axios.post(`${baseSlug}/bookings/${bookingId}/read`);
  },
  async sendChatMessage(bookingId: string, message: ChatMessagePost): Promise<AxiosResponse<void>> {
    return await $axios.post(`${baseSlug}/bookings/${bookingId}/send`, message);
  },
  async readAllChatMessages(payload: string[]): Promise<AxiosResponse<void>> {
    return await $axios.post(`${baseSlug}/bookings/read`, payload);
  }
};
