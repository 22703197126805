var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative w-full h-full bg-gray-100 form mb-8" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form__head flex items-center" },
            [
              _c("Icon", {
                staticClass: "mr-1",
                attrs: { name: "services", width: "20", height: "20" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("form.actionForm.title")) + " ")
            ],
            1
          ),
          _c("div", { staticClass: "form__body" }, [
            _c("div", { staticClass: "flex mb-8" }, [
              _c(
                "div",
                { staticClass: "w-1/2 mr-6" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("form.reason")))]),
                  _c("multiSelect", {
                    staticClass: "mt-1",
                    attrs: {
                      options: _vm.actionTypes,
                      "track-by": "id",
                      label: "displayName",
                      placeholder: _vm.$t("form.selectReason"),
                      selectLabel: _vm.$t("actions.select"),
                      deselectLabel: _vm.$t("actions.remove")
                    },
                    on: { input: _vm.onActionTypeChange },
                    model: {
                      value: _vm.actionType,
                      callback: function($$v) {
                        _vm.actionType = $$v
                      },
                      expression: "actionType"
                    }
                  }),
                  !_vm.form.type && _vm.form.hasData
                    ? _c("label", { staticClass: "label__error" }, [
                        _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "w-1/2" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col" },
                  [
                    _c("label", { staticClass: "mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("form.remarks")))
                    ]),
                    _c("a-input", {
                      attrs: {
                        placeholder: _vm.$t("form.descriptionPlaceholder"),
                        size: "large"
                      },
                      model: {
                        value: _vm.form.remarks,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "remarks", $$v)
                        },
                        expression: "form.remarks"
                      }
                    })
                  ],
                  1
                ),
                _vm.form.type === "VARIOUS" &&
                _vm.form.hasData &&
                !_vm.form.remarks
                  ? _c("label", { staticClass: "label__error" }, [
                      _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "form__section" }, [
              _c("div", { staticClass: "form__section__title" }, [
                _vm._v(_vm._s(_vm.$t("form.dateAndTime")))
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-1/2 mr-6 flex" }, [
                  _c("div", { staticClass: "mr-4 w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("dates.fromDate")))
                        ]),
                        _c("fleet-forms-date-picker", {
                          attrs: { "default-date": _vm.defaultDates.from },
                          on: { input: _vm.fromDateInput },
                          model: {
                            value: _vm.form.fromDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "fromDate", $$v)
                            },
                            expression: "form.fromDate"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.fromDate && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("form.timeFrom")))
                        ]),
                        _c("fleet-forms-time-picker", {
                          attrs: { "default-value": _vm.defaultTimes.from },
                          model: {
                            value: _vm.form.fromTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "fromTime", $$v)
                            },
                            expression: "form.fromTime"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.fromTime && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "w-1/2 flex" }, [
                  _c("div", { staticClass: "mr-4 w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("dates.toDate")))
                        ]),
                        _c("fleet-forms-date-picker", {
                          attrs: { "default-date": _vm.defaultDates.to },
                          on: { input: _vm.toDateInput },
                          model: {
                            value: _vm.form.toDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "toDate", $$v)
                            },
                            expression: "form.toDate"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.toDate && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("form.timeTo")))
                        ]),
                        _c("fleet-forms-time-picker", {
                          attrs: { "default-value": _vm.defaultTimes.to },
                          model: {
                            value: _vm.form.toTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "toTime", $$v)
                            },
                            expression: "form.toTime"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.toTime && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e(),
                    _vm.hasTimeError
                      ? _c("label", { staticClass: "label__error block" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("errors.IMPOSSIBLE_TIME_RANGE")) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "form__section" }, [
              _c("div", { staticClass: "form__section__title" }, [
                _vm._v(_vm._s(_vm.$t("form.location")))
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-1/2 mr-6" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("ranges.from")))]),
                      _c("multiSelect", {
                        attrs: {
                          options: _vm.fromLocations,
                          "track-by": "id",
                          label: "displayName",
                          placeholder: _vm.$t("form.selectFromLocation"),
                          selectLabel: _vm.$t("actions.select"),
                          deselectLabel: _vm.$t("actions.remove")
                        },
                        on: { input: _vm.onLocationFromChanged },
                        model: {
                          value: _vm.locationFrom,
                          callback: function($$v) {
                            _vm.locationFrom = $$v
                          },
                          expression: "locationFrom"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "w-1/2" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("ranges.toLocation")))
                      ]),
                      _c("multiSelect", {
                        attrs: {
                          options: _vm.toLocations,
                          "track-by": "id",
                          label: "displayName",
                          placeholder: _vm.$t("form.selectToLocation"),
                          selectLabel: _vm.$t("actions.select"),
                          deselectLabel: _vm.$t("actions.remove")
                        },
                        on: { input: _vm.onLocationToChanged },
                        model: {
                          value: _vm.locationTo,
                          callback: function($$v) {
                            _vm.locationTo = $$v
                          },
                          expression: "locationTo"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }