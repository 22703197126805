var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isActive
        ? _c("SplitScreen", {
            attrs: {
              isActive: _vm.isActive,
              isLoading: false,
              "custom-class": "w-96"
            },
            on: { closeHandler: _vm.closeDetails },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "font-extrabold" }, [
                        _vm._v(_vm._s(_vm.$t("fleet.affiliationTitle")))
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "pt-4 h-full" }, [
                        _c("div", { staticClass: "w-auto mb-4 text-2xl" }, [
                          _vm._v(
                            _vm._s(_vm.subcontractor.name) +
                              " - " +
                              _vm._s(_vm.boardId)
                          )
                        ]),
                        _c("div", { staticClass: "flex flex-col h-full" }, [
                          _c("div", { staticClass: "flex-1 overflow-auto" }, [
                            _c(
                              "div",
                              { staticClass: "w-full mb-24" },
                              _vm._l(_vm.subcontractorAffiliations, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "bg-gray-100 p-3 mb-4 shadow-md"
                                  },
                                  [
                                    _c("div", { staticClass: "mb-1" }, [
                                      _vm._v(_vm._s(item.sequence))
                                    ]),
                                    _c("div", {
                                      staticClass:
                                        "divider w-full h-0.5 bg-gray-300 mb-1"
                                    }),
                                    _c("div", { staticClass: "mb-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("fleet.subcontractor") +
                                            " " +
                                            _vm.$t("fleet.truck") +
                                            " Name"
                                        )
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "mb-2" },
                                      [
                                        _c("a-input", {
                                          model: {
                                            value: item.truckName,
                                            callback: function($$v) {
                                              _vm.$set(item, "truckName", $$v)
                                            },
                                            expression: "item.truckName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("fleet.subcontractor") +
                                            " " +
                                            _vm.$t("fleet.driver") +
                                            " Name"
                                        )
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("a-input", {
                                          model: {
                                            value: item.driverName,
                                            callback: function($$v) {
                                              _vm.$set(item, "driverName", $$v)
                                            },
                                            expression: "item.driverName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "subContractorDetail__buttons" },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "mr-4",
                                  on: { click: _vm.removeDetail }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          name: "remove",
                                          height: "19",
                                          width: "19"
                                        }
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("actions.remove")))
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c("Button", { on: { click: _vm.addDetail } }, [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("Icon", {
                                      attrs: {
                                        name: "add",
                                        height: "19",
                                        width: "19"
                                      }
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("actions.add")))
                                    ])
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actionBar",
                  fn: function(ref) {
                    return [
                      _c("div", { staticClass: "w-full flex" }, [
                        _c(
                          "div",
                          { staticClass: "ml-auto flex" },
                          [
                            _c(
                              "Button",
                              {
                                staticClass: "mr-4",
                                attrs: { variant: "secondary" },
                                on: { click: _vm.closeDetails }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("actions.close")))
                                ])
                              ]
                            ),
                            _c("Button", { on: { click: _vm.saveDetails } }, [
                              _vm._v(_vm._s(_vm.$t("actions.save")))
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              2194441189
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }