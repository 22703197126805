import Vue from "vue";
import { i18n, LANG_EN } from "@/i18n/i18n";
import { AxiosError } from "axios";
import { ErrorResponse } from "@/types/types";

interface ToastOptions {
  type: string;
  duration?: number;
}

const INFO = "info";
const WARNING = "warning";
const ERROR = "error";
const SUCCESS = "success";

const ERROR_TOAST_DURATION = 5000; // 5s

const toastMessage = (
  message: string,
  options: ToastOptions = {
    type: INFO
  }
): void => {
  Vue.$toast.open({
    message,
    ...options
  });
};

const translateAndToast = (
  translationKey: string,
  values = {},
  options: ToastOptions = {
    type: INFO
  }
): void => {
  toastMessage(i18n.t(translationKey, values).toString(), options);
};

const translateAndToastInfo = (translationKey: string, values = {}): void => {
  translateAndToast(translationKey, values);
};

const translateAndToastSuccess = (translationKey: string, values = {}): void => {
  translateAndToast(translationKey, values, {
    type: SUCCESS
  });
};

const translateAndToastWarn = (translationKey: string, values = {}): void => {
  translateAndToast(translationKey, values, {
    type: WARNING
  });
};

const translateAndToastError = (translationKey: string, values = {}): void => {
  translateAndToast(translationKey, values, {
    type: ERROR,
    duration: ERROR_TOAST_DURATION
  });
};

const toastInfo = (message: string): void => {
  toastMessage(message);
};

const toastSuccess = (message: string): void => {
  toastMessage(message, {
    type: SUCCESS
  });
};

const toastWarn = (message: string): void => {
  toastMessage(message, {
    type: WARNING
  });
};

const toastWarnInteractive = (message: string): void => {
  toastMessage(message, {
    type: ERROR,
    duration: 10000
  });
};

const toastError = (message: string): void => {
  toastMessage(message, {
    type: ERROR,
    duration: ERROR_TOAST_DURATION
  });
};

const toastAxiosError = (axiosError: AxiosError<ErrorResponse>, locale = LANG_EN): void => {
  if (axiosError.response == null) {
    toastError(axiosError.message);
    return;
  }

  const { data: errorResponse } = axiosError.response!;
  const { errorCode, details } = errorResponse;

  if (details == null || details.length === 0) {
    translateAndToastError(`apiErrors.${errorCode}`);
    return;
  }

  details
    .filter(detail => detail.severity.toLowerCase() === ERROR)
    .forEach(detail => toastError(detail.message[locale]));
};

export {
  toastMessage,
  translateAndToast,
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
  toastWarnInteractive,
  translateAndToastError,
  translateAndToastInfo,
  translateAndToastSuccess,
  translateAndToastWarn,
  toastAxiosError
};
