<template>
  <div class="w-screen h-screen bg-black fixed top-0 left-0 z-30 opacity-20"></div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Overlay"
});
</script>

<style scoped></style>
