const TP_COOKIE_NAME = process.env.VUE_APP_COOKIENAME;
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const APP_AUTH = process.env.VUE_APP_AUTH;
const APP_BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const DISPATCHER_BASE_URL = process.env.VUE_APP_DISPATCHER_BASE_URL;
const G11_WEB_URL = process.env.VUE_APP_G11_WEB_URL;
const G11_WEB_DOMAIN_ALIAS = process.env.VUE_APP_G11_WEB_DOMAIN_ALIAS;
const G11_WINDOW_WIDTH = process.env.VUE_APP_G11_WINDOW_WIDTH;
const G11_WINDOW_HEIGHT = process.env.VUE_APP_G11_WINDOW_HEIGHT;

export {
  TP_COOKIE_NAME,
  API_BASE_URL,
  APP_AUTH,
  APP_BACKEND_URL,
  DISPATCHER_BASE_URL,
  G11_WEB_URL,
  G11_WEB_DOMAIN_ALIAS,
  G11_WINDOW_WIDTH,
  G11_WINDOW_HEIGHT
};
