<template>
  <button @click="showBoardSettings" class="flex items-center">
    <Icon name="settings" height="18" width="18" class="mr-1" />{{ $t("settings.title") }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  components: { Icon },
  setup(props, { emit }) {
    const showBoardSettings = () => {
      emit("showBoardSettings");
    };
    return { showBoardSettings };
  }
});
</script>

<style scoped></style>
