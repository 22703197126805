var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex pt-4" }, [
      _c(
        "div",
        { staticClass: "w-1/3 mr-6" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.truck")))
          ]),
          _c("multiSelect", {
            attrs: {
              disabled: _vm.isDisabledFleet,
              options: _vm.truckPreview,
              "custom-label": _vm.displayName,
              placeholder: _vm.$t("fleet.selectTruck"),
              label: "displayName",
              "track-by": "displayName",
              loading: _vm.truckLoading,
              selectLabel: _vm.$t("actions.select"),
              deselectLabel: _vm.$t("actions.remove")
            },
            on: { input: _vm.truckChanged },
            model: {
              value: _vm.truckPreviewValue,
              callback: function($$v) {
                _vm.truckPreviewValue = $$v
              },
              expression: "truckPreviewValue"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/3 mr-6" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.trailer")))
          ]),
          _c("multiSelect", {
            attrs: {
              disabled: _vm.isDisabled,
              options: _vm.trailerPreview,
              "custom-label": _vm.displayName,
              placeholder: _vm.$t("fleet.selectTrailer"),
              label: "displayName",
              "track-by": "displayName",
              loading: _vm.trailerLoading,
              selectLabel: _vm.$t("actions.select"),
              deselectLabel: _vm.$t("actions.remove")
            },
            on: { input: _vm.trailerChanged },
            model: {
              value: _vm.trailerPreviewValue,
              callback: function($$v) {
                _vm.trailerPreviewValue = $$v
              },
              expression: "trailerPreviewValue"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/3" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.driver")))
          ]),
          _c("multiSelect", {
            attrs: {
              disabled: _vm.isDisabledFleet,
              options: _vm.driverPreview,
              "custom-label": _vm.displayName,
              placeholder: _vm.$t("fleet.selectDriver"),
              label: "displayName",
              "track-by": "displayName",
              loading: _vm.driverLoading,
              selectLabel: _vm.$t("actions.select"),
              deselectLabel: _vm.$t("actions.remove")
            },
            on: { input: _vm.driverChanged },
            model: {
              value: _vm.driverPreviewValue,
              callback: function($$v) {
                _vm.driverPreviewValue = $$v
              },
              expression: "driverPreviewValue"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "flex py-4" }, [
      _c(
        "div",
        { staticClass: "w-1/3 mr-6" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.subcontractor")))
          ]),
          _c("multiSelect", {
            attrs: {
              options: _vm.subcontractorPreview,
              disabled: _vm.isDisabled || _vm.hasSubiDriver,
              "custom-label": _vm.displayName,
              placeholder: _vm.$t("fleet.selectSubcontractor"),
              label: "displayName",
              "track-by": "displayName",
              loading: _vm.subcontractorLoading,
              selectLabel: _vm.$t("actions.select"),
              deselectLabel: _vm.$t("actions.remove")
            },
            on: { input: _vm.subcontractorChanged },
            model: {
              value: _vm.subcontractorPreviewValue,
              callback: function($$v) {
                _vm.subcontractorPreviewValue = $$v
              },
              expression: "subcontractorPreviewValue"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/3 mr-6" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.subitruck")))
          ]),
          _c("a-input", {
            attrs: {
              size: "large",
              disabled: _vm.isDisabledSubcontractor,
              placeholder: _vm.$t("fleet.subitruck")
            },
            on: {
              input: function($event) {
                return _vm.subconTruckNameChanged(_vm.subcontractorTruckName)
              }
            },
            model: {
              value: _vm.subcontractorTruckName,
              callback: function($$v) {
                _vm.subcontractorTruckName = $$v
              },
              expression: "subcontractorTruckName"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/3" },
        [
          _c("div", { staticClass: "text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("fleet.subidriver")))
          ]),
          _c("a-input", {
            attrs: {
              size: "large",
              disabled: _vm.isDisabledSubcontractor,
              placeholder: _vm.$t("fleet.subidriver")
            },
            on: {
              input: function($event) {
                return _vm.subconDriverNameChanged(_vm.subcontractorDriverName)
              }
            },
            model: {
              value: _vm.subcontractorDriverName,
              callback: function($$v) {
                _vm.subcontractorDriverName = $$v
              },
              expression: "subcontractorDriverName"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }