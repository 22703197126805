/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.5 8a10.464 10.464 0 00-6.9 2.6L2 7v9h9l-3.62-3.62A7.971 7.971 0 0120.1 16l2.37-.78A10.514 10.514 0 0012.5 8z"/>'
  }
})
