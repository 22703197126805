var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ],
      staticClass: "relative z-50",
      style: _vm.tickerPosition
    },
    [
      _c("a-tooltip", [
        _c(
          "a",
          {
            staticClass:
              "relative inline-block text-white no-underline font-bold text-3xl hover:text-gray-200 transition-all text-center p-0.5 notification rounded-full w-10 h-10 -left-4",
            attrs: { href: "#" },
            on: { click: _vm.highlightLeg }
          },
          [
            _vm._v("! "),
            _c(
              "span",
              {
                staticClass:
                  "absolute -right-2.5 -bottom-2.5 w-6 h-6 text-white font-semibold text-xs text-center p-1 notification rounded-xl"
              },
              [_vm._v(_vm._s(_vm.notStartedLegsOn.length || 0))]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }