/***
 * This script follows the mouse and scrolls the window according to the page position
 * Inspiration from:
 * https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
 */

import { computed, ComputedRef, reactive } from "@vue/composition-api";

interface MouseScrollState {
  viewportX: null;
  viewportY: null;
  viewportWidth: number;
  viewportHeight: number;
  edge: {
    top: number;
    left: number;
    right: ComputedRef<number>;
    bottom: ComputedRef<number>;
    isLeft: ComputedRef<boolean>;
    isRight: ComputedRef<boolean>;
    isTop: ComputedRef<boolean>;
    isBottom: ComputedRef<boolean>;
  };
  documentWidth: number;
  documentHeight: number;
  maxScrollX: ComputedRef<number>;
  maxScrollY: ComputedRef<number>;
}

const edgeSize = 100;
const state = reactive<MouseScrollState>({
  viewportX: null,
  viewportY: null,
  viewportWidth: document.documentElement.clientWidth,
  viewportHeight: document.documentElement.clientHeight,
  edge: {
    top: edgeSize,
    left: edgeSize,
    right: computed(() => state.viewportWidth - edgeSize),
    bottom: computed(() => state.viewportHeight - edgeSize),
    isLeft: computed(() => state.viewportX < state.edge.left),
    isRight: computed(() => state.viewportX > state.edge.right),
    isTop: computed(() => state.viewportY < state.edge.top),
    isBottom: computed(() => state.viewportY > state.edge.bottom)
  },
  documentWidth: Math.max(
    document.body.scrollWidth,
    document.body.offsetWidth,
    document.body.clientWidth,
    document.documentElement.scrollWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  ),
  documentHeight: Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  ),
  maxScrollX: computed(() => state.documentWidth - state.viewportWidth),
  maxScrollY: computed(() => state.documentHeight - state.viewportHeight)
});

const handleMouseDrag = (event): void => {
  // Get the viewport-relative coordinates of the mousemove event.
  state.viewportX = event.clientX;
  state.viewportY = event.clientY;

  const { isLeft, isRight, isTop, isBottom } = state.edge;

  if (!(isLeft || isRight || isTop || isBottom)) {
    return;
  }
  checkForWindowScroll();
};

// Adjust the window scroll based on the user's mouse position. Returns True
// or False depending on whether or not the window scroll was changed.
const adjustWindowScroll = (): boolean => {
  // Get the current scroll position of the document.
  const currentScrollX = window.pageXOffset;
  const currentScrollY = window.pageYOffset;

  // Determine if the window can be scrolled in any particular direction.
  const canScrollUp = currentScrollY > 0;
  const canScrollDown = currentScrollY < state.maxScrollY;
  const canScrollLeft = currentScrollX > 0;
  const canScrollRight = currentScrollX < state.maxScrollX;

  // Since we can potentially scroll in two directions at the same time,
  // let's keep track of the next scroll, starting with the current scroll.
  // Each of these values can then be adjusted independently in the logic
  // below.
  let nextScrollX = currentScrollX;
  let nextScrollY = currentScrollY;

  // As we examine the mouse position within the edge, we want to make the
  // incremental scroll changes more "intense" the closer that the user
  // gets the viewport edge. As such, we'll calculate the percentage that
  // the user has made it "through the edge" when calculating the delta.
  // Then, that use that percentage to back-off from the "max" step value.
  const maxStep = 10;

  const { top, left, bottom, right, isTop, isLeft, isBottom, isRight } = state.edge;

  // Should we scroll left?
  if (isLeft && canScrollLeft) {
    const intensity = (left - state.viewportX) / edgeSize;
    nextScrollX = nextScrollX - maxStep * intensity;

    // Should we scroll right?
  } else if (isRight && canScrollRight) {
    const intensity = (state.viewportX - right) / edgeSize;
    nextScrollX = nextScrollX + maxStep * intensity;
  }

  // Should we scroll up?
  if (isTop && canScrollUp) {
    const intensity = (top - state.viewportY) / edgeSize;

    nextScrollY = nextScrollY - maxStep * intensity;

    // Should we scroll down?
  } else if (isBottom && canScrollDown) {
    const intensity = (state.viewportY - bottom) / edgeSize;
    nextScrollY = nextScrollY + maxStep * intensity;
  }

  // Sanitize invalid maximums. An invalid scroll offset won't break the
  // subsequent .scrollTo() call; however, it will make it harder to
  // determine if the .scrollTo() method should have been called in the
  // first place.
  nextScrollX = Math.max(0, Math.min(state.maxScrollX, nextScrollX));
  nextScrollY = Math.max(0, Math.min(state.maxScrollY, nextScrollY));

  if (nextScrollX !== currentScrollX || nextScrollY !== currentScrollY) {
    window.scrollTo(nextScrollX, nextScrollY);
    return true;
  } else {
    return false;
  }
};

const checkForWindowScroll = (): void => {
  if (adjustWindowScroll()) {
    setTimeout(checkForWindowScroll, 30);
  }
};

const initMouseScroll = (): void => {
  //we initialize or update the static values
  state.viewportWidth = document.documentElement.clientWidth;
  state.viewportHeight = document.documentElement.clientHeight;
  state.documentWidth = Math.max(
    document.body.scrollWidth,
    document.body.offsetWidth,
    document.body.clientWidth,
    document.documentElement.scrollWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  state.documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};

const startMouseScroll = (): void => {
  initMouseScroll();
  window.addEventListener("mousemove", handleMouseDrag, true);
  window.addEventListener("drag", handleMouseDrag, true);
};

const stopMouseScroll = (): void => {
  window.removeEventListener("mousemove", handleMouseDrag, true);
  window.removeEventListener("drag", handleMouseDrag, true);
};

export { startMouseScroll, stopMouseScroll };
