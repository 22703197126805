<template>
  <div>
    <a-switch
      @change="onPreviousLegDetailToggleChange"
      :checked="showPreviousLegDetail"
      size="large"
      class="mr-2"
    /><span class="text-sm">{{ $t("showPreviousLegDetail") }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import { GET_SHOW_PREVIOUS_LEG_DETAIL, TOGGLE_SHOW_PREVIOUS_LEG_DETAIL } from "@/store/board";

export default defineComponent({
  name: "ShowPreviousLegDetail",
  setup() {
    const showPreviousLegDetail = computed(() => store.getters[GET_SHOW_PREVIOUS_LEG_DETAIL]);

    const onPreviousLegDetailToggleChange = () => {
      store.dispatch(TOGGLE_SHOW_PREVIOUS_LEG_DETAIL);
    };

    return {
      showPreviousLegDetail,
      onPreviousLegDetailToggleChange
    };
  }
});
</script>
