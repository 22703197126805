var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full h-full flex" },
    _vm._l(_vm.hours, function(index) {
      return _c(
        "div",
        {
          key: index,
          class: [
            _vm.dynamicClass,
            {
              "boardTimeLine__boxes--endOfDay": _vm.shouldShowDayName(
                index - 1,
                true
              )
            }
          ],
          style: _vm.boxStyle
        },
        [
          _vm.header
            ? _c(
                "div",
                {
                  staticClass: "px-2 font-semibold flex flex-col relative",
                  class: {
                    "boardTimeLine__boxes__header--endOfDay": _vm.shouldShowDayName(
                      index - 1,
                      true
                    )
                  }
                },
                [
                  _vm.shouldShowDayName(index - 1)
                    ? _c("div", { staticClass: "h-6" }, [
                        index === 1
                          ? _c("span", { staticClass: "whitespace-nowrap" }, [
                              _vm._v(_vm._s(_vm.startDateLabel))
                            ])
                          : _c("span", { staticClass: "whitespace-nowrap" }, [
                              _vm._v(_vm._s(_vm.deriveDateLabel(index)))
                            ])
                      ])
                    : _vm.shouldShowDayName(index - 1, true)
                    ? _c("div", { staticClass: "h-6 relative" }, [
                        _c(
                          "div",
                          { staticClass: "whitespace-nowrap absolute right-0" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.deriveDateLabel(index)) + " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "h-6 boardTimeLine--lastHour" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getTime(index - 1, true)) + " "
                            )
                          ]
                        )
                      ])
                    : _c("div", { staticClass: "h-6" }),
                  _c("div", { staticClass: "h-6" }, [
                    _vm._v(" " + _vm._s(_vm.getTime(index - 1)) + " ")
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }