import { TrailerMetadata, TruckMetaData } from "@/types/fleet";

const stringifyTrailerEquipment = (metadata: TrailerMetadata): string => {
  const rotaryValveBrand = metadata?.rotaryValveBrand;
  const foodApproved = metadata?.foodApproved == true && "foodApproved";
  const additionalCooler = metadata?.additionalCooler == true && "additionalCooler";

  return [rotaryValveBrand, foodApproved, additionalCooler].filter(Boolean).join(", ");
};

const stringifyTruckEquipment = (metaData: TruckMetaData): string => {
  //check for this equipment keys
  const equipmentKeys = ["blower", "compressor", "pump", "hydraulic", "foodApproved"];

  return equipmentKeys.filter(item => typeof metaData[item] !== "undefined" && metaData[item] !== false).join(", ");
};

export { stringifyTrailerEquipment, stringifyTruckEquipment };
