var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "svg",
      {
        staticClass: "steps fill-current text-gray-300",
        class: {
          "text-yellow-500": _vm.isActive,
          "text-green-500": _vm.passed
        },
        attrs: {
          width: "14",
          height: "70",
          xmlns: "http://www.w3.org/2000/svg"
        }
      },
      [
        _c("g", { attrs: { "fill-rule": "evenodd" } }, [
          !_vm.isFirst
            ? _c("circle", { attrs: { cx: "7.5", cy: "3.5", r: "3.5" } })
            : _vm._e(),
          !_vm.isFirst
            ? _c("circle", { attrs: { cx: "7.5", cy: "17.5", r: "3.5" } })
            : _vm._e(),
          _c("circle", { attrs: { cx: "7", cy: "35", r: "7" } }),
          !_vm.isLast
            ? _c("circle", { attrs: { cx: "7.5", cy: "52.5", r: "3.5" } })
            : _vm._e(),
          !_vm.isLast
            ? _c("circle", { attrs: { cx: "7.5", cy: "66.5", r: "3.5" } })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }