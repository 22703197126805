










































import { defineComponent, ref, onBeforeMount, computed, watch } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import multiSelect from "vue-multiselect";
import store from "@/store";
import {
  INIT_BOARDS_DROPDOWN,
  SET_BOARDS_SELECTED,
  GET_FILTERED_BOARD_PROFILE,
  GET_SELECTED_BOARDS,
  UPDATE_CONFIG_PROFILE
} from "@/store/board";
import {
  ACTIVATE_DRIVER_COMMENT_TOGGLE,
  CLEAR_FLEET,
  DEACTIVATE_DRIVER_COMMENT_TOGGLE,
  FETCH_BOARD_FLEET,
  REMOVE_SELECTED_TRAILERS
} from "@/store/fleet/";
import FilterButton from "@/components/Buttons/FilterButton.vue";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { Board } from "@/types/board";

export default defineComponent({
  name: "boardFilter",
  components: { Icon, multiSelect, FilterButton },
  props: {
    editModeActive: {
      type: Boolean
    }
  },
  setup() {
    const value = ref([]);
    const options = computed(() => store.state.board.boards);
    const selectedBoards = computed(() => store.getters[GET_SELECTED_BOARDS]);

    onBeforeMount(() => {
      store.dispatch(INIT_BOARDS_DROPDOWN);
    });

    watch(selectedBoards, last => {
      value.value = last;
    });

    const updateDropdownHandler = (value: Board[]): void => {
      if (value !== selectedBoards.value) {
        store.commit(
          SET_BOARDS_SELECTED,
          value.map(board => ({ name: board.id, id: board.id }))
        );

        store.commit(CLEAR_FLEET);
        store.commit(RESET_LEGS);
        store.commit(REMOVE_SELECTED_TRAILERS);
        store.commit(RESET_FLEET_ACTIONS);

        if (selectedBoards.value.length > 0) {
          const boardIds: string[] = store.state.board.boardsSelected.map(board => board.id);
          store.dispatch(FETCH_BOARD_FLEET, boardIds);
        }

        store.dispatch(UPDATE_CONFIG_PROFILE);
        store.dispatch(GET_FILTERED_BOARD_PROFILE);
      }
      //scroll always to top when changing selection
      window.scrollTo(0, 0);
      store.dispatch(ACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    const openDropdown = (): void => {
      store.dispatch(DEACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    return { value, options, updateDropdownHandler, openDropdown, selectedBoards };
  }
});
