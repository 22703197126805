<template>
  <button :disabled="disabled" class="button" :class="[dynamicClass, disabledClass, sizeClass]" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "Button",
  props: {
    variant: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const dynamicClass = computed(() => props.variant);
    const sizeClass = computed(() => (props.size === "sm" ? "button--small" : ""));
    const disabledClass = computed(() => {
      return props.disabled ? "button--disabled" : "";
    });
    return {
      dynamicClass,
      disabledClass,
      sizeClass
    };
  }
});
</script>

<style lang="scss">
.button {
  @apply h-12 px-8 py-2 transition-colors duration-300 text-black;
}

.button--small {
  @apply h-11 px-7 py-2;
}

.primary {
  @apply bg-btYellow  hover:bg-yellow-500;
  &.button--disabled {
    @apply opacity-50 hover:bg-btYellow;
  }
}
.secondary {
  @apply bg-white border-2 border-gray-300  hover:border-yellow-500;
  &.button--disabled {
    @apply opacity-50 hover:bg-white;
  }
}
.tertiary {
  @apply bg-white hover:text-yellow-500;
  &.button--disabled {
    @apply opacity-50 hover:bg-white;
  }
}
</style>
