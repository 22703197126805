var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VueDragResize",
    {
      key: _vm.actionKey,
      ref: "brickDragComponent",
      staticClass: "flex items-center drag-resize brick",
      attrs: {
        w: _vm.actionCoordinates.width,
        minw: 10,
        h: 100,
        snapToGrid: true,
        gridY: 100,
        gridX: _vm.hourBoxWidth / 4,
        parentLimitation: true,
        parentW: _vm.parentWidth,
        x: _vm.actionCoordinates.x,
        y: _vm.actionCoordinates.y,
        preventActiveBehavior: false,
        sticks: _vm.resizeSticks,
        axis: _vm.allowedAxisMovement,
        isDraggable: _vm.draggable,
        isResizable: _vm.updatable
      },
      on: {
        dragging: _vm.onDragging,
        dragstop: _vm.onDragStop,
        resizestop: _vm.onResizeStop,
        activated: _vm.onActivated,
        deactivated: _vm.onDeactivated
      }
    },
    [
      _c("span", {
        key: _vm.overlaySpanKey,
        staticClass:
          "action-brick absolute w-full h-24 cursor-pointer py-2 shadow-lg border border-gray-400 px-2 rounded",
        class: _vm.overlaySpanClass
      }),
      _c(
        "div",
        {
          staticClass:
            "action-brick absolute w-full h-24 cursor-pointer py-2 shadow-lg border border-gray-400 px-2 rounded",
          class: {
            "action-brick__blocking-action": !_vm.actionData.isReminder,
            "action-brick__reminder": _vm.actionData.isReminder,
            "action-brick--disabled": _vm.isBoardEditActive
          },
          on: { dblclick: _vm.openActionDetails }
        },
        [
          _c("div", { staticClass: "h-full overflow-hidden flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-full flex justify-between flex-no-wrap text-center"
              },
              [
                _c("div", { staticClass: "flex flex-col w-full" }, [
                  !_vm.actionData.isReminder
                    ? _c("div", { staticClass: "whitespace-nowrap" }, [
                        _c("div", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.actionType))
                        ]),
                        _c("div", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.dateTimeInfo))
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.actionData.remarks))])
                      ])
                    : _c(
                        "div",
                        [
                          _c("Icon", {
                            staticClass: "text-btYellow mb-1",
                            attrs: {
                              name: "notifications",
                              width: "25",
                              height: "25"
                            }
                          }),
                          _c("div", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.actionData.type))
                          ])
                        ],
                        1
                      )
                ])
              ]
            )
          ]),
          _c("BrickSpinner", { attrs: { "is-active": _vm.actionIsUpdating } }),
          _vm.actionIsDirty
            ? _c("BrickActionBar", {
                staticClass: "brick-action-bar",
                attrs: { disabled: _vm.actionBarDisabled },
                on: {
                  updateBrick: _vm.updateAction,
                  resetBrick: _vm.resetAction
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("span", {
        staticClass: "absolute w-full h-24 cursor-pointer px-2 rounded",
        class: [_vm.overlayClass, _vm.dirtyStateClass],
        on: {
          dblclick: _vm.openActionDetails,
          contextmenu: function($event) {
            return _vm.setActionSelected($event)
          },
          mousedown: _vm.onMouseDown
        }
      }),
      _c("SearchMatchOverlay", {
        attrs: { "search-id": _vm.searchId, outerSpanClass: "h-24 rounded" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }