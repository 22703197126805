<template>
  <a-popover
    v-model="isVisible"
    overlayClassName="fullLegViewPopover"
    placement="topLeft"
    :overlayStyle="{ width: '650px' }"
    :arrowPointAtCenter="true"
    :mouseEnterDelay="POPOVER_OPEN_DELAY"
    :mouseLeaveDelay="POPOVER_CLOSE_DELAY"
  >
    <template #content>
      <div class="flex" v-click-outside="onClickOutside" :class="legStatuses.statusClass">
        <TTALink :leg-data="legData" />
        <LegAffiliationPopover
          class="bottom-4 right-1"
          :legContent="legContent"
          :legSize="largeLegSize"
          :isViewedInPopover="true"
          :hasDifferentDriver="legStatuses.hasDifferentDriver"
          :hasDifferentTrailer="legStatuses.hasDifferentTrailer"
          :hasSubiWithPrimaryBoard="legStatuses.hasSubiWithPrimaryBoard"
          :onTouchStartHandler="onTouchStart"
        />
        <LegBreakpointXL
          :legContent="legContent"
          :legStatuses="legStatuses"
          :legActionCallbacks="legActionCallbacks"
          :onTouchStart="onTouchStart"
          :showChatNotificationIcon="false"
        />
      </div>
    </template>
    <div class="full-leg-view-trigger absolute left-0 top-0 w-2 h-full bg-transparent" />
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "@vue/composition-api";
import { BoardLeg, LegSize, DisplayLegContent, DisplayLegStatuses, LegCallbacks } from "@/types/leg";
import Icon from "@/components/Icon.vue";
import LegTransportStatusIndicator from "@/components/Board/Leg/LegTransportStatusIndicator/LegTransportStatusIndicator.vue";
import ClickOutside from "vue-click-outside";
import ClosablePopover from "@/components/ClosablePopover.vue";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import CalloutLegTrailer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegTrailer.vue";
import CalloutLegDriver from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDriver.vue";
import TTALink from "@/components/Board/Leg/TTALink/TTALink.vue";
import LegBreakpointXL from "@/components/Board/Leg/LegBreakpoints/LegBreakpointXL.vue";
import LegAffiliationPopover from "@/components/Board/Leg/LegAffiliationPopover/LegAffiliationPopover.vue";

export default defineComponent({
  name: "FullLegPreviewPopover",
  components: {
    Icon,
    LegTransportStatusIndicator,
    CalloutLegContainer,
    CalloutLegDriver,
    CalloutLegTrailer,
    ClosablePopover,
    LegBreakpointXL,
    TTALink,
    LegAffiliationPopover
  },
  directives: { ClickOutside },
  props: {
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    legData: {
      type: Object as PropType<BoardLeg>,
      required: true
    },
    legStatuses: {
      type: Object as () => DisplayLegStatuses,
      required: true
    },
    legActionCallbacks: {
      type: Object as () => LegCallbacks,
      required: true
    },
    onTouchStart: {
      type: Function,
      default: () => ({})
    }
  },
  setup() {
    const isVisible = ref<boolean>(false);

    const largeLegSize = ref<LegSize>({
      xs: false,
      sm: false,
      md: false,
      lg: true,
      xl: true
    });

    // delay in seconds
    const POPOVER_OPEN_DELAY = 1;
    const POPOVER_CLOSE_DELAY = 5;

    const onClickOutside = () => {
      isVisible.value = false;
    };

    return {
      POPOVER_OPEN_DELAY,
      POPOVER_CLOSE_DELAY,
      onClickOutside,
      isVisible,
      largeLegSize
    };
  }
});
</script>

<style lang="scss">
@use "../Leg.scss";

.fullLegViewPopover {
  .ant-popover-content {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
      0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
