import { $axios } from "@/config/axios";
import { LegFilter, LegsResponse, Leg, LegBooking, LegUpdateResponse } from "@/types/leg";
import { AxiosResponse } from "axios";

const baseSlug = "/v2/legs";

export default {
  async getLegs(filter: LegFilter): Promise<AxiosResponse<LegsResponse>> {
    return await $axios.post(`${baseSlug}/filter`, filter);
  },
  async lockLeg(legId: string) {
    return await $axios.post(`${baseSlug}/${legId}/lock`);
  },
  async unlockLeg(legId: string) {
    return await $axios.post(`${baseSlug}/${legId}/unlock`);
  },
  async updateLeg(leg: Leg): Promise<AxiosResponse<LegUpdateResponse>> {
    return await $axios.post(`${baseSlug}/${leg.id}`, leg);
  },
  async getLeg(legId: string): Promise<AxiosResponse<Leg>> {
    return await $axios.get(`${baseSlug}/${legId}`);
  },
  async getLegBooking(legId: string): Promise<AxiosResponse<LegBooking>> {
    return await $axios.get(`${baseSlug}/bookings/${legId}`);
  }
};
