














































































import Tag from "@/components/Tag.vue";
import legService from "@/services/legService";
import Icon from "@/components/Icon.vue";
import { BoardLeg, LegBooking, LegBookingStepStatus } from "@/types/leg";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import LegDetailStepSectionBullets from "./LegDetailStepSectionBullets/LegDetailStepSectionBullets.vue";
import LegDetailStepSectionEvents from "@/components/Board/Leg/LegDetail/LegDetailStepSection/LegDetailStepSectionEvents/LegDetailStepSectionEvents.vue";

export default defineComponent({
  components: { LegDetailStepSectionBullets, Tag, Icon, LegDetailStepSectionEvents },
  props: {
    selectedLeg: {
      type: Object as () => BoardLeg
    }
  },
  setup(props, { emit }) {
    const { selectedLeg } = toRefs(props);
    const bookingDetail = ref<LegBooking | null>(null);
    const isLoading = ref(false);

    const steps = computed(() => {
      return bookingDetail.value?.steps
        .map(item => {
          const timeStatus = {
            planned: false,
            active: false,
            arrived: false
          };
          if (item.effectiveDepartureDate) {
            timeStatus.arrived = true;
          } else if (item.effectiveArrivalDate) {
            timeStatus.active = true;
          } else if (item.plannedTime) {
            timeStatus.planned = true;
          }
          item = {
            ...item,
            ...timeStatus
          };
          return item;
        })
        .sort((s1, s2) => s1.sequenceNumber - s2.sequenceNumber);
    });

    const getLegBookingDetails = async () => {
      isLoading.value = true;
      try {
        const { data } = await legService.getLegBooking(selectedLeg?.value?.booking?.id || "");
        bookingDetail.value = data;
      } catch (e) {
        //handle not global errors
      }
      isLoading.value = false;
    };

    const variant = (step: LegBookingStepStatus): string => {
      if (step.active) {
        return "warning";
      }
      if (step.arrived) {
        return "success";
      }
      return "primary";
    };

    const eventWasRead = computed(() => {
      const readEvents = steps.value
        ?.map(item => {
          return item.events.find(event => event.type === "READ");
        })
        .filter(item => item);
      if (readEvents?.length) {
        return true;
      }
      return false;
    });

    watch(
      () => eventWasRead.value,
      () => {
        emit("setEventIsRead", eventWasRead.value);
      }
    );

    onMounted(() => {
      getLegBookingDetails();
    });

    return {
      steps,
      variant,
      dateStringToShowTime,
      dateStringToShowDate,
      isLoading,
      bookingDetail
    };
  }
});
