<template>
  <span
    v-if="isSpotted"
    ref="matchOverlay"
    class="absolute w-full select-none pointer-events-none z-30"
    :class="outerSpanCls"
  >
    <span class="absolute w-full h-full opacity-50 bg-yellow-300 select-none pointer-events-none" />
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { GET_SPOTTED_IDS, GET_FOCUSED_ID } from "@/store/board";

export default defineComponent({
  name: "SearchMatchOverlay",
  props: {
    searchId: {
      type: String
    },
    outerSpanClass: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const matchOverlay = ref<any>(null);

    const isSpotted = computed(() => store.getters[GET_SPOTTED_IDS]?.includes(props.searchId));
    const isFocused = computed(() => store.getters[GET_FOCUSED_ID] === props.searchId);

    const outerSpanCls = computed(() => {
      const { outerSpanClass } = props;
      return outerSpanClass + (isFocused.value ? " border-2 border-yellow-500" : "");
    });

    watch(
      () => isFocused.value,
      async focused => {
        if (focused) {
          await nextTick();
          matchOverlay.value.scrollIntoView({ block: "center", inline: "center" });
        }
      }
    );

    return {
      isSpotted,
      isFocused,
      matchOverlay,
      outerSpanCls
    };
  }
});
</script>

<style scoped></style>
