






















































import { defineComponent, ref } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import ShowPreviousLegDetail from "@/components/Board/ShowPreviousLegDetail.vue";
import ShowWeekend from "@/components/Board/ShowWeekend.vue";
import DefaultDate from "@/components/DefaultDate.vue";
import TimeSelectorDialog from "@/components/Board/TimeSelector/TimeSelectorDialog/TimeSelectorDialog.vue";

export default defineComponent({
  name: "NavSettings",
  components: {
    SplitScreen,
    Button,
    LanguageSwitcher,
    ShowPreviousLegDetail,
    ShowWeekend,
    TimeSelectorDialog,
    DefaultDate
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isLoadingHandler = (value: boolean): void => {
      isLoading.value = value;
    };
    const closeBoardSettings = () => emit("boardSettingsClosed");
    return { closeBoardSettings, isLoading, isLoadingHandler };
  }
});
