var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "ml-3 w-full flex flex-no-wrap justify-between whitespace-nowrap"
    },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-col mr-2 overflow-hidden",
          class: { "justify-between": !_vm.legStatuses.isPrevLegViewActive }
        },
        [
          _c("div", [
            _c("span", [
              _vm._v(_vm._s(_vm.legStatuses.hierarchyIndicator) + " ")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.legContent.type) + "-")]),
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer border-b border-gray-400 hover:border-yellow-500 inline-block",
                on: { click: _vm.legActionCallbacks.g11BookingCallback }
              },
              [_vm._v(" " + _vm._s(_vm.legContent.orderNumberAlt) + " ")]
            ),
            _vm.legStatuses.transportStatuses.hasPendingIssues
              ? _c(
                  "span",
                  { staticClass: "cursor-pointer ml-1" },
                  [
                    _c(
                      "a-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          title: _vm.legContent.issues
                        }
                      },
                      [
                        _c("Icon", {
                          staticClass: "text-red-500",
                          attrs: {
                            name: "exclamation-triangle",
                            height: "17",
                            width: "17"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "cursor-pointer ml-1",
                on: { click: _vm.legActionCallbacks.g11PlanningCallback }
              },
              [
                _c("Icon", {
                  staticClass: "hover:text-btYellow",
                  attrs: { name: "hyperlink", height: "17", width: "17" }
                })
              ],
              1
            )
          ]),
          _vm.legContent.from
            ? _c("div", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.legContent.from))
              ])
            : _vm._e(),
          _c("div", { staticClass: "overflow-hidden overflow-ellipsis" }, [
            _vm.legContent.product
              ? _c(
                  "span",
                  {
                    staticClass: "capitalize",
                    class: { "text-btRed": _vm.legContent.adrClass }
                  },
                  [_vm._v(_vm._s(_vm.legContent.product.toLowerCase()))]
                )
              : _vm._e(),
            _vm.legStatuses.unloadingTemperature
              ? _c("span", { staticClass: "font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.legStatuses.unloadingTemperature))
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-col mr-2",
          class: { "justify-between": !_vm.legStatuses.isPrevLegViewActive }
        },
        [
          _vm.legContent.container
            ? _c(
                "div",
                [
                  _c("ClosablePopover", {
                    attrs: {
                      overlayStyle: { maxWidth: "600px", width: "500px" }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "popoverContent",
                          fn: function() {
                            return [
                              _c("CalloutLegContainer", {
                                staticClass: "px-2",
                                attrs: {
                                  title: _vm.legContent.container.id,
                                  content: _vm.legContent.container,
                                  "container-type": "LEG"
                                }
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "popoverTrigger",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "leg_callout cursor-pointer border-b border-gray-400 hover:border-yellow-500",
                                  on: { touchstart: _vm.onTouchStart }
                                },
                                [_vm._v(_vm._s(_vm.legContent.container.id))]
                              ),
                              _c("span", { staticClass: "font-bold" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.legStatuses.initialLoadState)
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2133196365
                    )
                  })
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "font-semibold whitespace-nowrap invisible" },
                [_vm._v("Placeholder")]
              ),
          _vm.legStatuses.legType.fullTransport
            ? [
                _c("div", [
                  _c("span", { staticClass: "font-semibold" }, [
                    _vm._v(_vm._s(_vm.legContent.loading))
                  ]),
                  _vm.legContent.loadingTime || _vm.legContent.plannedLoading
                    ? _c("span", [
                        _c("span", [_vm._v(_vm._s(" L:"))]),
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(_vm._s(" " + _vm.legContent.plannedLoading))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(" " + _vm.legContent.loadingTime))
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("div", [
                  _c("span", { staticClass: "font-semibold" }, [
                    _vm._v(_vm._s(_vm.legContent.unloading))
                  ]),
                  _vm.legContent.unloadingTime ||
                  _vm.legContent.plannedUnloading
                    ? _c("span", [
                        _c("span", [_vm._v(_vm._s(" U:"))]),
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(_vm._s(" " + _vm.legContent.plannedUnloading))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(" " + _vm.legContent.unloadingTime))
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            : _vm.legStatuses.legType.collection
            ? [
                _vm.legContent.loading
                  ? _c("div", { staticClass: "font-semibold" }, [
                      _vm._v(_vm._s(_vm.legContent.loading))
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ]),
                _vm.legContent.loadingTime || _vm.legContent.plannedLoading
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(" L:"))]),
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(" " + _vm.legContent.plannedLoading))
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(" " + _vm.legContent.loadingTime))
                      ])
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ])
              ]
            : _vm.legStatuses.legType.delivery
            ? [
                _vm.legContent.unloading
                  ? _c("div", { staticClass: "font-semibold" }, [
                      _vm._v(_vm._s(_vm.legContent.unloading))
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ]),
                _vm.legContent.unloadingTime || _vm.legContent.plannedUnloading
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(" U:"))]),
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(" " + _vm.legContent.plannedUnloading))
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(" " + _vm.legContent.unloadingTime))
                      ])
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ])
              ]
            : _vm.legStatuses.legType.dropSwap
            ? [
                _vm.legContent.plannedLoading
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s("L: "))]),
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(_vm.legContent.plannedLoading))
                      ])
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ]),
                _vm.legContent.plannedUnloading
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s("U: "))]),
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(_vm.legContent.plannedUnloading))
                      ])
                    ])
                  : _c("div", { staticClass: "invisible" }, [
                      _vm._v("placeholder")
                    ])
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-col items-end ",
          class: { "justify-between": !_vm.legStatuses.isPrevLegViewActive }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-row " },
            [
              _c("LegTransportStatusIndicator", {
                attrs: { status: _vm.legStatuses.transportStatuses }
              }),
              _vm.showChatNotificationIcon
                ? _c("ChatNotifications", {
                    attrs: {
                      content: _vm.legContent,
                      iconWidth: "20",
                      totalUnreadMessages: _vm.legContent.unreadMessages
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.legContent.to
            ? _c("div", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.legContent.to))
              ])
            : _vm._e(),
          _c("div", { staticClass: "invisible" }, [_vm._v("placeholder")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }