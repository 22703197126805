/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '3dots': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#b9b9b9" d="M16 12a2 2 0 102-2 2.006 2.006 0 00-2 2zm-2 0a2 2 0 10-2 2 2.006 2.006 0 002-2zm-6 0a2 2 0 10-2 2 2.006 2.006 0 002-2z"/>'
  }
})
