var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-config-provider",
        { attrs: { locale: _vm.locale } },
        [
          _c("a-date-picker", {
            attrs: {
              size: "large",
              format: "DD.MM.YYYY",
              "disabled-date": _vm.disabledDates,
              showToday: false,
              getCalendarContainer: function(trigger) {
                return trigger.parentNode
              }
            },
            on: { change: _vm.updateDate },
            scopedSlots: _vm._u([
              {
                key: "suffixIcon",
                fn: function() {
                  return [_c("a-icon", { attrs: { type: "calendar" } })]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.selectedDate,
              callback: function($$v) {
                _vm.selectedDate = $$v
              },
              expression: "selectedDate"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }