var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative w-full h-full bg-gray-100 form mb-8" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form__head flex items-center" },
            [
              _c("Icon", {
                staticClass: "mr-1",
                attrs: { name: "notifications", width: "20", height: "20" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("form.reminderForm.title")) + " ")
            ],
            1
          ),
          _c("div", { staticClass: "form__body" }, [
            _c("div", { staticClass: "flex mb-8" }, [
              _c("div", { staticClass: "w-1/2 mr-6" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col" },
                  [
                    _c("label", { staticClass: "mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("form.title")))
                    ]),
                    _c("a-input", {
                      attrs: {
                        placeholder: _vm.$t("form.titlePlaceholder"),
                        size: "large"
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    })
                  ],
                  1
                ),
                !_vm.form.type && _vm.form.hasData
                  ? _c("label", { staticClass: "label__error" }, [
                      _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "w-1/2" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col" },
                  [
                    _c("label", { staticClass: "mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("form.remarks")))
                    ]),
                    _c("a-input", {
                      attrs: {
                        placeholder: _vm.$t("form.descriptionPlaceholder"),
                        size: "large"
                      },
                      model: {
                        value: _vm.form.remarks,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "remarks", $$v)
                        },
                        expression: "form.remarks"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "form__section" }, [
              _c("div", { staticClass: "form__section__title" }, [
                _vm._v(_vm._s(_vm.$t("form.dateAndTime")))
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-1/2 mr-6 flex" }, [
                  _c("div", { staticClass: "w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col mr-4" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("form.date")))
                        ]),
                        _c("fleet-forms-date-picker", {
                          model: {
                            value: _vm.form.date,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "date", $$v)
                            },
                            expression: "form.date"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.date && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("label", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.$t("form.time")))
                        ]),
                        _c("fleet-forms-time-picker", {
                          attrs: {
                            "default-value": _vm.defaultTime,
                            format: "HH:mm"
                          },
                          model: {
                            value: _vm.form.time,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "time", $$v)
                            },
                            expression: "form.time"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.form.time && _vm.form.hasData
                      ? _c("label", { staticClass: "label__error" }, [
                          _vm._v(" " + _vm._s(_vm.$t("form.required")) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "w-1/2" })
              ])
            ]),
            _c("div", { staticClass: "form__section" }, [
              _c("div", { staticClass: "form__section__title" }, [
                _vm._v(_vm._s(_vm.$t("form.location")))
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "w-1/3" },
                  [
                    _c("multiSelect", {
                      attrs: {
                        options: _vm.locations,
                        "track-by": "id",
                        label: "displayName",
                        placeholder: "Select to location",
                        selectLabel: _vm.$t("actions.select"),
                        deselectLabel: _vm.$t("actions.remove")
                      },
                      on: { input: _vm.onLocationChange },
                      model: {
                        value: _vm.formLocation,
                        callback: function($$v) {
                          _vm.formLocation = $$v
                        },
                        expression: "formLocation"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }