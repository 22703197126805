var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSpotted
    ? _c(
        "span",
        {
          ref: "matchOverlay",
          staticClass: "absolute w-full select-none pointer-events-none z-30",
          class: _vm.outerSpanCls
        },
        [
          _c("span", {
            staticClass:
              "absolute w-full h-full opacity-50 bg-yellow-300 select-none pointer-events-none"
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }