import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BoardLeg, Leg, LegDropInfo, LegFilter, LegsUpdateRequest, LegUpdateDetails } from "@/types/leg";
import legService from "@/services/legService";
import {
  GET_BOARD_DETAILS,
  GET_LANGUAGE,
  GET_SELECTED_BOARD_IDS,
  GET_SHOW_WEEKENDS,
  UPDATE_CONFIG_PROFILE
} from "@/store/board/";
import { add, endOfDay, startOfDay } from "date-fns";
import {
  GET_FLEET_IDS,
  REPOPULATE_EXTENDED_FLEET_ACTIONS,
  SET_FLEET_SPANS,
  SET_FLEET_LOADING,
  GET_TRAILERS_SELECTED,
  UPDATE_TRAILERS_VIA_LEG
} from "@/store/fleet";
import { dateStringToShowDate, formatDateString, isSameDay, toIsoWithNoOffset, toLocalDateTime } from "@/use/useDate";
import {
  actionFitsTimespan,
  brickHasMatchingFleetEntity,
  deriveBoardFlexibles,
  deriveBrickCoordinates,
  extractEffectiveActionTimestamps,
  extractEffectiveLegTimestamps,
  legFitsTimespan,
  isMatchFound
} from "@/store/brick/useBrick";

import {
  BoardFleetAction,
  FleetAction,
  FleetActionFilter,
  FleetBoardActionFilter,
  FleetActionIdFilter,
  FleetActionsResponse,
  FleetActionsUpdateRequest
} from "@/types/action";
import fleetService from "@/services/fleetService";
import { OrderedFleetIds } from "@/types/fleet";
import { BoardEntityType, BoardPositionable } from "@/types/board";
import {
  toastAxiosError,
  toastError,
  toastInfo,
  toastWarn,
  toastWarnInteractive,
  translateAndToastError
} from "@/use/useToast";
import { AxiosError } from "axios";
import { addToSearchIndex, buildSearchId, removeFromSearchIndex } from "@/store/board/useSearchIndex";
import { stringifyNestedValues } from "@/use/useObject";
import { spreadTrailerId } from "@/use/useFleet";

const SET_LEGS_LOCAL = "SET_LEGS";
const RESET_LEGS_LOCAL = "RESET_LEGS";
const SET_DIRTY_LEG_LOCAL = "SET_DIRTY_LEG";
const UNSET_DIRTY_LEG_LOCAL = "UNSET_DIRTY_LEG";
const MAP_TO_BOARD_LEGS_LOCAL = "MAP_TO_BOARD_LEGS";
const UPDATE_LEGS_LOCAL = "UPDATE_LEGS";
const UNLOCK_LEG_LOCAL = "UNLOCK_LEG";
const CLEAR_LEG_EVENTS_LOCAL = "CLEAR_LEG_EVENTS";
const INDEX_LEGS_LOCAL = "INDEX_LEGS";
const INDEX_BOARD_ACTIONS_LOCAL = "INDEX_BOARD_ACTIONS";
const MAP_TO_BOARD_ACTIONS_LOCAL = "MAP_TO_BOARD_ACTIONS";
const SET_BOARD_ACTIONS_LOCAL = "SET_BOARD_ACTIONS";
const SET_FLEET_ACTIONS_LOCAL = "SET_FLEET_ACTIONS";
const UPDATE_FLEET_ACTIONS_LOCAL = "UPDATE_FLEET_ACTIONS";
const RESET_FLEET_ACTIONS_LOCAL = "RESET_FLEET_ACTIONS";
const REMOVE_FLEET_ACTIONS_LOCAL = "REMOVE_FLEET_ACTIONS";
const UPDATE_FUTURE_FLEET_ACTIONS_LOCAL = "UPDATE_FUTURE_FLEET_ACTIONS";
const SET_DIRTY_FLEET_ACTION_LOCAL = "SET_DIRTY_FLEET_ACTION";
const UNSET_DIRTY_FLEET_ACTION_LOCAL = "UNSET_DIRTY_FLEET_ACTION";
const MARK_ALL_BRICKS_UPDATABLE_LOCAL = "MARK_ALL_BRICKS_UPDATABLE";
const SET_INITIAL_HARDCOPY_ACTION_LOCAL = "SET_INITIAL_HARDCOPY_ACTION";

const BRICK_STORE = "brick";
const FETCH_LEGS = `${BRICK_STORE}/FETCH_LEGS`;
const SORT_LEGS = `${BRICK_STORE}/SORT_LEGS`;
const DELETE_LEGS = `${BRICK_STORE}/DELETE_LEGS`;
const UPDATE_LEGS = `${BRICK_STORE}/${UPDATE_LEGS_LOCAL}`;
const UPDATE_LEG = `${BRICK_STORE}/UPDATE_LEG`;
const RESET_LEGS = `${BRICK_STORE}/${RESET_LEGS_LOCAL}`;
const FETCH_DROPPED_LEG = `${BRICK_STORE}/FETCH_DROPPED_LEG`;
const LOCK_LEG = `${BRICK_STORE}/LOCK_LEG`;
const UNLOCK_LEG = `${BRICK_STORE}/${UNLOCK_LEG_LOCAL}`;
const ADJUST_BRICK_SIZES = `${BRICK_STORE}/ADJUST_BRICK_SIZES`;
const CLEAR_LEG_EVENTS = `${BRICK_STORE}/${CLEAR_LEG_EVENTS_LOCAL}`;
const INDEX_LEGS = `${BRICK_STORE}/${INDEX_LEGS_LOCAL}`;
const INDEX_BOARD_ACTIONS = `${BRICK_STORE}/${INDEX_BOARD_ACTIONS_LOCAL}`;
const GET_LEG_BY_ID = `${BRICK_STORE}/GET_LEG_BY_ID`;
const GET_FLEET_ACTION_BY_ID = `${BRICK_STORE}/GET_FLEET_ACTION_BY_ID`;

const FETCH_FLEET_ACTIONS = `${BRICK_STORE}/FETCH_FLEET_ACTIONS`;
const CREATE_FLEET_ACTIONS = `${BRICK_STORE}/CREATE_FLEET_ACTIONS`;
const RESET_FLEET_ACTIONS = `${BRICK_STORE}/${RESET_FLEET_ACTIONS_LOCAL}`;
const UPDATE_FLEET_ACTION = `${BRICK_STORE}/UPDATE_FLEET_ACTION`;
const UPDATE_FLEET_ACTIONS = `${BRICK_STORE}/${UPDATE_FLEET_ACTIONS_LOCAL}`;
const DELETE_FLEET_ACTION = `${BRICK_STORE}/DELETE_FLEET_ACTION`;
const SET_LEG_TO_SELECTED = `${BRICK_STORE}/SET_LEG_TO_SELECTED`;
const GET_LEG_TRANSPORT_INFO_KEYS = `${BRICK_STORE}/GET_LEG_TRANSPORT_INFO_KEYS`;
const GET_DIRTY_LEG_ID = `${BRICK_STORE}/GET_DIRTY_LEG_ID`;
const REMOVE_FLEET_ACTIONS = `${BRICK_STORE}/${REMOVE_FLEET_ACTIONS_LOCAL}`;
const LOCK_FLEET_ACTION = `${BRICK_STORE}/LOCK_FLEET_ACTION`;
const UNLOCK_FLEET_ACTION = `${BRICK_STORE}/UNLOCK_FLEET_ACTION`;
const CLEAR_FLEET_ACTION_EVENTS = `${BRICK_STORE}/CLEAR_FLEET_ACTION_EVENTS`;
const SET_FLEET_ACTION_TO_SELECTED = `${BRICK_STORE}/SET_FLEET_ACTION_TO_SELECTED`;
const GET_DIRTY_FLEET_ACTION_ID = `${BRICK_STORE}/GET_DIRTY_FLEET_ACTION_ID`;
const GET_COPIED_ACTION = `${BRICK_STORE}/GET_COPIED_ACTION`;
const SET_COPIED_ACTION = `${BRICK_STORE}/SET_COPIED_ACTION`;
const ADD_ACTION_TO_STORE = `${BRICK_STORE}/ADD_ACTION_TO_STORE`;
const FETCH_ACTIONS_ON_TRAILERS = `${BRICK_STORE}/FETCH_ACTIONS_ON_TRAILERS`;
const GET_LEGS = `${BRICK_STORE}/GET_LEGS`;
const GET_BOARD_ACTIONS = `${BRICK_STORE}/GET_BOARD_ACTIONS`;
const SET_LEGS = `${BRICK_STORE}/SET_LEGS`;
const SET_BOARD_ACTIONS = `${BRICK_STORE}/SET_BOARD_ACTIONS`;
const GET_INITIAL_ACTIONS = `${BRICK_STORE}/GET_INITIAL_ACTIONS`;
const GET_INITIAL_LEGS = `${BRICK_STORE}/GET_INITIAL_LEGS`;

const COPIED_ACTION_ID = "COPIED_ACTION_ID";

const SET_INITIAL_LEGS_LOCAL = "SET_INITIAL_LEGS";
const SET_INITIAL_ACTIONS_LOCAL = "SET_INITIAL_ACTIONS";
const GET_IGNORABLE_BRICK_ID = `${BRICK_STORE}/GET_IGNORABLE_BRICK_ID`;
const SET_IGNORABLE_BRICK_ID = `${BRICK_STORE}/SET_IGNORABLE_BRICK_ID`;

const extractFittingActions = function(
  actions: FleetAction[],
  startDate: Date,
  daysAfterStartDate: number
): FleetAction[] {
  return actions.filter(action => {
    const { fromDate, toDate } = action;
    if (fromDate?.date == null) {
      return false;
    }
    const actionFromDate = new Date(fromDate.date);
    const startOfDateRange = startOfDay(startDate);
    const endOfDateRange = endOfDay(add(startDate, { days: daysAfterStartDate }));

    if (toDate?.date == null) {
      return startOfDateRange <= actionFromDate && actionFromDate <= endOfDateRange;
    }
    const actionToDate = new Date(toDate.date);

    return !(actionFromDate > endOfDateRange || actionToDate < startOfDateRange);
  });
};

const processLegUpdateDetails = function(updateDetails: LegUpdateDetails, locale: string): void {
  const { info, warnings, interactiveWarnings, errors } = updateDetails;

  (info || []).forEach(message => toastInfo(message[locale]));
  (warnings || []).forEach(message => toastWarn(message[locale]));
  (interactiveWarnings || []).forEach(message => toastWarnInteractive(message[locale]));
  (errors || []).forEach(message => toastError(message[locale]));
};

@Module({ namespaced: true })
export default class Index extends VuexModule {
  legs: BoardLeg[] = [];
  dirtyLegId: string | undefined = undefined;

  boardActions: BoardFleetAction[] = [];
  fleetActions: FleetAction[] = [];
  fleetActionDays = 9999;
  dirtyBoardActionId: string | undefined = undefined;
  copiedAction = undefined;
  hardCopyBoardActions: BoardFleetAction[] = [];

  initialActions: BoardFleetAction[] = [];
  initialLegs: BoardLeg[] = [];
  ignorableBrickId: string | null = null;

  get GET_IGNORABLE_BRICK_ID() {
    return this.ignorableBrickId;
  }

  get GET_COPIED_ACTION() {
    return this.copiedAction;
  }

  get GET_LEGS() {
    return this.legs;
  }

  get GET_BOARD_ACTIONS() {
    return this.boardActions;
  }

  get GET_LEG_TRANSPORT_INFO_KEYS() {
    const transportInfoKeys = new Set();
    this.legs
      .filter(leg => leg.transportInfo != null)
      .map(leg => leg.transportInfo)
      .flatMap(tInfo => Object.keys(tInfo).filter(info => tInfo[info].length))
      .forEach(infoKey => transportInfoKeys.add(infoKey));
    return [...transportInfoKeys];
  }

  get GET_DIRTY_LEG_ID() {
    return this.dirtyLegId;
  }

  get GET_DIRTY_FLEET_ACTION_ID() {
    return this.dirtyBoardActionId;
  }

  @Mutation
  SET_IGNORABLE_BRICK_ID(id: string | null) {
    this.ignorableBrickId = id;
  }

  @Mutation
  SET_COPIED_ACTION(action) {
    this.copiedAction = action;
  }

  @Mutation
  RESET_LEGS() {
    this.legs = [];
  }

  @Mutation
  SET_LEGS(legs: BoardLeg[]) {
    this.legs = legs;
  }

  @Mutation
  SET_DIRTY_LEG(legId: string) {
    this.dirtyLegId = legId;
  }

  @Mutation
  SET_INITIAL_LEGS(legs: BoardLeg[]) {
    this.initialLegs = legs;
  }

  @Mutation
  SET_INITIAL_ACTIONS(actions: BoardFleetAction[]) {
    this.initialActions = actions;
  }

  @Mutation
  UNSET_DIRTY_LEG() {
    this.dirtyLegId = undefined;
  }

  @Mutation
  CLEAR_LEG_EVENTS(legId: string) {
    this.legs = this.legs.map(leg => {
      if (leg.id === legId) {
        return {
          ...leg,
          updated: false,
          oldPlannedDate: undefined,
          selected: false
        };
      }
      return leg;
    });
  }

  @Mutation
  SET_INITIAL_HARDCOPY_ACTION(actions: BoardFleetAction[]) {
    this.hardCopyBoardActions = [...actions];
  }

  @Mutation
  SET_BOARD_ACTIONS(actions: BoardFleetAction[]) {
    this.boardActions = actions;
  }

  @Mutation
  SET_FLEET_ACTIONS(actions: FleetAction[]) {
    this.fleetActions = actions;
  }

  @Mutation
  RESET_FLEET_ACTIONS() {
    this.boardActions = [];
  }

  @Mutation
  SET_LEG_TO_SELECTED(legId: string) {
    this.legs = this.legs.map(leg => {
      if (leg.id === legId) {
        return {
          ...leg,
          selected: true
        };
      }
      return leg;
    });
  }

  @Action
  INDEX_LEGS(legs: BoardLeg[]) {
    legs.forEach(leg => {
      addToSearchIndex(buildSearchId(leg.id, BoardEntityType.LEG), stringifyNestedValues(leg));
    });
  }

  @Action
  INDEX_BOARD_ACTIONS(actions: BoardFleetAction[]) {
    actions.forEach(action => {
      addToSearchIndex(buildSearchId(action.id, BoardEntityType.ACTION), stringifyNestedValues(action));
    });
  }

  @Action
  GET_LEG_BY_ID(legId: string): BoardLeg | undefined {
    return this.legs.find(leg => leg.id === legId) as BoardLeg;
  }

  @Action
  async FETCH_DROPPED_LEG(legDropInfo: LegDropInfo) {
    if (this.dirtyLegId) {
      return;
    }

    const {
      legId,
      dropTimestamp: plannedDepartureTime,
      dropTruckId,
      dropTrailerId,
      dropDriverId,
      dropSubcontractor
    } = legDropInfo;
    const plannedArrivalTime = toLocalDateTime(add(new Date(plannedDepartureTime), { hours: 3 }));

    try {
      const { data: leg } = await legService.getLeg(legId);

      const plannedDepartureTimezone: string = leg.events?.plannedDepartureDate?.timezone || leg.stops.from.timezone;
      const plannedArrivalTimezone: string = leg.events?.plannedArrivalDate?.timezone || leg.stops.to.timezone;

      let oldPlannedDate: string | undefined = undefined;
      if (
        leg.events?.plannedDepartureDate?.date &&
        !isSameDay(plannedDepartureTime, leg.events?.plannedDepartureDate?.date)
      ) {
        oldPlannedDate = dateStringToShowDate(leg.events?.plannedDepartureDate?.date);
      }

      const { rootGetters } = this.context;

      const boards = dropSubcontractor?.id != null ? rootGetters[GET_SELECTED_BOARD_IDS] : leg.boards;

      const legToStore = {
        ...leg,
        boards,
        truckId: dropTruckId,
        trailerId: dropTrailerId,
        driverId: dropDriverId,
        subcontractor: dropSubcontractor,
        events: {
          ...leg.events,
          plannedDepartureDate: {
            date: plannedDepartureTime,
            timezone: plannedDepartureTimezone
          },
          plannedArrivalDate: {
            date: plannedArrivalTime,
            timezone: plannedArrivalTimezone
          }
        },
        oldPlannedDate
      };

      await this.context.dispatch(UPDATE_LEGS_LOCAL, {
        legs: [legToStore],
        viaDrop: true
      });
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async LOCK_LEG(legId: string) {
    try {
      await legService.lockLeg(legId);
      this.context.commit(
        SET_BOARD_ACTIONS_LOCAL,
        this.boardActions.map(action => ({
          ...action,
          updatable: false,
          dropped: false,
          updated: false
        }))
      );
      this.context.commit(
        SET_LEGS_LOCAL,
        this.legs.map(leg => {
          if (leg.id !== legId) {
            return {
              ...leg,
              updatable: false,
              dropped: false,
              updated: false
            };
          }
          return {
            ...leg,
            updated: false
          };
        })
      );
      this.context.commit(SET_DIRTY_LEG_LOCAL, legId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UNLOCK_LEG(legId: string) {
    try {
      await legService.unlockLeg(legId);
      await this.context.dispatch(MARK_ALL_BRICKS_UPDATABLE_LOCAL);

      if (this.dirtyLegId && this.dirtyLegId === legId) {
        this.context.commit(UNSET_DIRTY_LEG_LOCAL);
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  SORT_LEGS() {
    const { rootGetters, commit } = this.context;

    const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

    const { legs, actions } = deriveBoardFlexibles([...this.legs, ...this.boardActions], orderedFleetIds);

    commit(SET_LEGS_LOCAL, legs);
    commit(SET_BOARD_ACTIONS_LOCAL, actions);
  }

  @Action
  DELETE_LEGS(legIds: string[]) {
    const { rootGetters, commit } = this.context;

    const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];
    const intactLegs: BoardLeg[] = this.legs.filter(leg => !legIds.includes(leg.id));

    if (intactLegs.length === this.legs.length) {
      return;
    }

    const { legs, fleetSpans } = deriveBoardFlexibles([...intactLegs, ...this.boardActions], orderedFleetIds);

    commit(SET_FLEET_SPANS, fleetSpans, {
      root: true
    });
    commit(SET_LEGS_LOCAL, legs);
    legIds.forEach(legId => removeFromSearchIndex(buildSearchId(legId, BoardEntityType.LEG)));

    if (this.dirtyLegId && legIds.includes(this.dirtyLegId)) {
      this.context.commit(UNSET_DIRTY_LEG_LOCAL);
    }
  }

  @Action
  async UPDATE_LEG(legRequest: Leg): Promise<BoardLeg> {
    const { rootGetters, dispatch } = this.context;
    const currentLocale = rootGetters[GET_LANGUAGE];
    try {
      const { data } = await legService.updateLeg(legRequest);
      const { leg, details } = data;
      const boardLegs = await dispatch(UPDATE_LEGS_LOCAL, {
        legs: [leg]
      });

      if (details) {
        processLegUpdateDetails(details, currentLocale);
      }

      return boardLegs[0];
    } catch (e) {
      toastAxiosError(e as AxiosError, currentLocale);
      throw e;
    }
  }

  @Action
  async UPDATE_LEGS(updateRequest: LegsUpdateRequest): Promise<BoardLeg[]> {
    const { rootGetters, dispatch, commit } = this.context;
    try {
      if (updateRequest.viaSocket) {
        dispatch(UPDATE_TRAILERS_VIA_LEG, updateRequest.legs, { root: true });
      }

      const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];
      const updatedLegs: BoardLeg[] = await dispatch(MAP_TO_BOARD_LEGS_LOCAL, updateRequest.legs);

      const updatedLegIds: string[] = updatedLegs.map(leg => leg.id);
      const legIdsToRemove: string[] = updateRequest.legs
        .map(leg => leg.id)
        .filter(legId => !updatedLegIds.includes(legId));

      const intactLegs: BoardLeg[] = this.legs
        .filter(leg => !updatedLegIds.includes(leg.id) && !legIdsToRemove.includes(leg.id))
        .map(leg => ({
          ...leg,
          updated: false
        }));

      if (updateRequest.viaSocket && intactLegs.length === this.legs.length && updatedLegIds.length === 0) {
        return [];
      }

      const boardLegsToAppend: BoardLeg[] = updatedLegs.map(leg1 => ({
        ...leg1,
        updated: updateRequest.viaSocket,
        dropped: updateRequest.viaDrop
      }));

      const { legs, actions, fleetSpans } = deriveBoardFlexibles(
        [...boardLegsToAppend, ...intactLegs, ...this.boardActions],
        orderedFleetIds
      );

      commit(SET_FLEET_SPANS, fleetSpans, {
        root: true
      });
      commit(SET_LEGS_LOCAL, legs);
      dispatch(INDEX_LEGS_LOCAL, updatedLegs);
      commit(SET_BOARD_ACTIONS_LOCAL, actions);

      const legToAppendIds = boardLegsToAppend.map(leg => leg.id);
      return legs.filter(leg => legToAppendIds.includes(leg.id));
    } catch (e) {
      //show errors which are not handled globally
      return [];
    }
  }

  @Action
  MAP_TO_BOARD_LEGS(legs: BoardLeg[]): BoardLeg[] {
    if (typeof legs === "undefined" || legs.length === 0) {
      return [];
    }

    const { rootGetters } = this.context;
    const showWeekends: boolean = rootGetters[GET_SHOW_WEEKENDS];
    const activeBoardIds: string[] = rootGetters[GET_SELECTED_BOARD_IDS];

    const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

    const {
      boardFromDate,
      boardToDate,
      boardFromTime,
      boardToTime,
      hourBoxWidth,
      boardWidth,
      hourBoxesPerDay
    } = rootGetters[GET_BOARD_DETAILS];

    return legs
      .filter(leg => legFitsTimespan(leg, boardFromDate, boardFromTime, boardToDate, boardToTime, showWeekends))
      .filter(leg => brickHasMatchingFleetEntity(leg, orderedFleetIds, activeBoardIds))
      .flatMap(leg => {
        const { departureDate, arrivalDate } = extractEffectiveLegTimestamps(leg.events!);

        const brickCoordinates: BoardPositionable[] = deriveBrickCoordinates(
          leg,
          orderedFleetIds,
          departureDate!,
          arrivalDate!,
          boardFromDate,
          boardFromTime,
          boardToTime,
          hourBoxesPerDay,
          hourBoxWidth,
          boardWidth,
          showWeekends
        );

        return brickCoordinates.map(coordinate => ({
          ...leg,
          ...coordinate,
          updatable: true,
          effectiveStartTime: departureDate,
          effectiveEndTime: arrivalDate
        }));
      });
  }

  @Action
  async FETCH_LEGS(boardIds: string[]) {
    const { rootState, rootGetters, dispatch, commit } = this.context;

    const boards: string[] = boardIds || rootGetters[GET_SELECTED_BOARD_IDS];

    const filter: LegFilter = {
      boardIds: boards,
      from: toIsoWithNoOffset(startOfDay(rootState.board.fromDate)),
      to: toIsoWithNoOffset(endOfDay(rootState.board.toDate))
    };

    try {
      const { data } = await legService.getLegs(filter);
      const boardLegs: BoardLeg[] = await dispatch(MAP_TO_BOARD_LEGS_LOCAL, data.legs);

      commit(SET_INITIAL_LEGS_LOCAL, boardLegs);
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async ADJUST_BRICK_SIZES() {
    const { dispatch } = this.context;
    if (this.legs.length > 0) {
      await dispatch(UPDATE_LEGS_LOCAL, {
        legs: this.legs
      });
    }
    if (this.boardActions.length > 0) {
      await dispatch(UPDATE_FLEET_ACTIONS_LOCAL, {
        actions: this.boardActions
      });
    }
  }

  @Action
  MAP_TO_BOARD_ACTIONS(actions: BoardFleetAction[]): BoardFleetAction[] {
    if (typeof actions === "undefined" || actions.length === 0) {
      return [];
    }

    const { rootGetters } = this.context;
    const showWeekends: boolean = rootGetters[GET_SHOW_WEEKENDS];
    const activeBoardIds: string[] = rootGetters[GET_SELECTED_BOARD_IDS];

    const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

    const {
      boardFromDate,
      boardToDate,
      boardFromTime,
      boardToTime,
      hourBoxWidth,
      boardWidth,
      hourBoxesPerDay
    } = rootGetters[GET_BOARD_DETAILS];

    return actions
      .filter(action =>
        actionFitsTimespan(action, boardFromDate, boardFromTime, boardToDate, boardToTime, showWeekends)
      )
      .filter(action => brickHasMatchingFleetEntity(action, orderedFleetIds, activeBoardIds))
      .flatMap(action => {
        const { actionStartDate, actionEndDate } = extractEffectiveActionTimestamps(action);
        const brickCoordinates: BoardPositionable[] = deriveBrickCoordinates(
          action,
          orderedFleetIds,
          actionStartDate!,
          actionEndDate!,
          boardFromDate,
          boardFromTime,
          boardToTime,
          hourBoxesPerDay,
          hourBoxWidth,
          boardWidth,
          showWeekends
        );

        return brickCoordinates.map(coordinate => ({
          ...action,
          ...coordinate,
          updatable: true,
          effectiveStartTime: actionStartDate,
          effectiveEndTime: actionEndDate,
          isDuplicate: brickCoordinates.length > 1
        }));
      });
  }

  @Action
  async FETCH_FLEET_ACTIONS(actionsIdFilter: FleetActionIdFilter): Promise<FleetAction[]> {
    const { rootState, dispatch, commit } = this.context;

    try {
      let boardActionsData: FleetActionsResponse;
      let fleetActionData: FleetActionsResponse = { actions: [] };

      const boardActionsFilter: FleetBoardActionFilter = {
        boardIds: actionsIdFilter.boardIds?.join(",") || "",
        from: toIsoWithNoOffset(startOfDay(rootState.board.fromDate)),
        to: toIsoWithNoOffset(endOfDay(rootState.board.toDate))
      };

      const today = new Date();
      const { truckIds, trailerIds, driverIds, subcontractorIds, boardIds } = actionsIdFilter;
      if (
        isMatchFound(truckIds) ||
        isMatchFound(trailerIds) ||
        isMatchFound(driverIds) ||
        isMatchFound(subcontractorIds)
      ) {
        const fleetActionsFilter: FleetActionFilter = {
          truckIds,
          trailerIds,
          driverIds,
          subcontractorIds,
          boardIds,
          from: toIsoWithNoOffset(startOfDay(today))
        };

        const [boardActionsResponse, fleetActionResponse] = await Promise.all([
          fleetService.getFleetBoardActions(boardActionsFilter),
          fleetService.getFleetActions(fleetActionsFilter)
        ]);
        boardActionsData = boardActionsResponse.data;
        fleetActionData = fleetActionResponse.data;
      } else {
        boardActionsData = (await fleetService.getFleetBoardActions(boardActionsFilter)).data;
      }

      const boardActions: BoardFleetAction[] = await dispatch(MAP_TO_BOARD_ACTIONS_LOCAL, boardActionsData.actions);

      commit(SET_INITIAL_HARDCOPY_ACTION_LOCAL, boardActions);

      commit(SET_INITIAL_ACTIONS_LOCAL, boardActions);
      commit(SET_FLEET_ACTIONS_LOCAL, fleetActionData.actions);

      return fleetActionData.actions;
    } catch (e) {
      //show errors which are not handled globally
    }

    return [];
  }

  @Action
  async CREATE_FLEET_ACTIONS(request: FleetAction) {
    const { rootGetters, dispatch, commit } = this.context;

    try {
      const { data } = await fleetService.createAction({
        ...request,
        trailerId: request.trailerId ? spreadTrailerId(request.trailerId).id : request.trailerId
      });

      if (request.id === COPIED_ACTION_ID) {
        await dispatch(REMOVE_FLEET_ACTIONS_LOCAL, [COPIED_ACTION_ID]);
      }

      const newBoardActions: BoardFleetAction[] = await dispatch(MAP_TO_BOARD_ACTIONS_LOCAL, data.actions);

      const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

      const { actions, legs, fleetSpans } = deriveBoardFlexibles(
        [...this.legs, ...this.boardActions, ...newBoardActions],
        orderedFleetIds
      );

      commit(SET_FLEET_SPANS, fleetSpans, {
        root: true
      });

      const futureActions = extractFittingActions(data.actions, new Date(), this.fleetActionDays);
      const updatedFleetActions = [...this.fleetActions, ...futureActions];

      commit(SET_LEGS_LOCAL, legs);
      commit(SET_BOARD_ACTIONS_LOCAL, actions);
      dispatch(INDEX_BOARD_ACTIONS_LOCAL, newBoardActions);
      commit(SET_FLEET_ACTIONS_LOCAL, updatedFleetActions);

      await dispatch(REPOPULATE_EXTENDED_FLEET_ACTIONS, updatedFleetActions, { root: true });
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async DELETE_FLEET_ACTION(actionId: string) {
    const { dispatch } = this.context;
    try {
      await fleetService.deleteAction(actionId);
      await dispatch(REMOVE_FLEET_ACTIONS_LOCAL, [actionId]);
      if (this.dirtyBoardActionId && this.dirtyBoardActionId === actionId) {
        await this.context.commit(UNSET_DIRTY_FLEET_ACTION_LOCAL);
        await this.context.dispatch(MARK_ALL_BRICKS_UPDATABLE_LOCAL);
      }
    } catch {
      //show errors which are not handled globally
    }
  }

  @Action
  async REMOVE_FLEET_ACTIONS(actionIds: string[]) {
    const { rootGetters, commit, dispatch } = this.context;
    try {
      const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

      const updatedFleetActions = this.fleetActions.filter(action => !actionIds.includes(action.id));
      const updatedBoardActions = this.boardActions.filter(action => !actionIds.includes(action.id));

      if (updatedFleetActions.length !== this.fleetActions.length) {
        commit(SET_FLEET_ACTIONS_LOCAL, updatedFleetActions);
        await dispatch(REPOPULATE_EXTENDED_FLEET_ACTIONS, updatedFleetActions, { root: true });
      }

      if (updatedBoardActions.length === this.boardActions.length) {
        return;
      }

      const { actions, legs, fleetSpans } = deriveBoardFlexibles(
        [...this.legs, ...updatedBoardActions],
        orderedFleetIds
      );

      commit(SET_FLEET_SPANS, fleetSpans, {
        root: true
      });
      commit(SET_BOARD_ACTIONS_LOCAL, actions);
      actionIds.forEach(actionId => removeFromSearchIndex(buildSearchId(actionId, BoardEntityType.ACTION)));
      commit(SET_LEGS_LOCAL, legs);
    } catch {
      //show errors which are not handled globally
    }
  }

  @Action
  async UPDATE_FLEET_ACTION(action: FleetAction) {
    const { dispatch } = this.context;

    const updateRequest = {
      ...action,
      fromLocation: (action.fromLocation?.id && action.fromLocation) || undefined,
      toLocation: (action.toLocation?.id && action.toLocation) || undefined
    };

    try {
      const { data } = await fleetService.updateAction(updateRequest);
      await dispatch(UPDATE_FLEET_ACTIONS_LOCAL, {
        actions: [data]
      });
    } catch (e) {
      const fromDate = formatDateString(action.fromDate?.date!);
      const toDate = formatDateString(action.toDate?.date!);
      translateAndToastError(`apiErrors.${(e as AxiosError).response?.data.errorCode}`, {
        fromDate,
        toDate
      });
    }
  }

  @Action
  async FETCH_ACTIONS_ON_TRAILERS() {
    const { rootGetters, dispatch, commit } = this.context;

    const trailers = rootGetters[GET_TRAILERS_SELECTED];

    await dispatch(
      UPDATE_CONFIG_PROFILE,
      trailers.map(trailer => trailer.id),
      { root: true }
    );

    const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];

    const boardActions = [...this.hardCopyBoardActions];

    const { actions } = deriveBoardFlexibles([...boardActions], orderedFleetIds);

    commit(SET_BOARD_ACTIONS_LOCAL, actions);

    commit(SET_FLEET_LOADING, false, {
      root: true
    });
  }

  @Action
  async UPDATE_FLEET_ACTIONS(updateRequest: FleetActionsUpdateRequest): Promise<BoardFleetAction[]> {
    const { rootGetters, dispatch, commit } = this.context;

    try {
      await dispatch(UPDATE_FUTURE_FLEET_ACTIONS_LOCAL, updateRequest.actions);
      const orderedFleetIds: OrderedFleetIds = rootGetters[GET_FLEET_IDS];
      const mappedActions: BoardFleetAction[] = await dispatch(MAP_TO_BOARD_ACTIONS_LOCAL, updateRequest.actions);

      const updatableActionIds: string[] = mappedActions.map(action => action.id);
      const actionIdsToRemove: string[] = updateRequest.actions
        .map(action => action.id)
        .filter(actionId => !updatableActionIds.includes(actionId));

      const intactActions: BoardFleetAction[] = this.boardActions
        .filter(action => !updatableActionIds.includes(action.id) && !actionIdsToRemove.includes(action.id))
        .map(action => ({
          ...action,
          updated: false
        }));

      if (
        updateRequest.viaSocket &&
        intactActions.length === this.boardActions.length &&
        updatableActionIds.length === 0
      ) {
        return [];
      }

      const boardActionsToAppend = mappedActions.map(action => ({
        ...action,
        updated: updateRequest.viaSocket
      }));

      const { legs, actions, fleetSpans } = deriveBoardFlexibles(
        [...boardActionsToAppend, ...intactActions, ...this.legs],
        orderedFleetIds
      );

      commit(SET_FLEET_SPANS, fleetSpans, {
        root: true
      });
      commit(SET_LEGS_LOCAL, legs);
      commit(SET_BOARD_ACTIONS_LOCAL, actions);
      dispatch(INDEX_BOARD_ACTIONS_LOCAL, mappedActions);

      return actions.filter(action => updatableActionIds.includes(action.id));
    } catch (e) {
      //show errors which are not handled globally
      return [];
    }
  }

  @Action
  async UPDATE_FUTURE_FLEET_ACTIONS(actions: FleetAction[]) {
    const { dispatch, commit } = this.context;

    const futureFleetActions = extractFittingActions(actions, new Date(), this.fleetActionDays).reduce(
      (acc, action) => {
        acc[action.id] = action;
        return acc;
      },
      {} as any
    );

    const fleetActionIdsToRemove = actions
      .filter(action => futureFleetActions[action.id] == null)
      .map(action => action.id);

    const intactFleetActions = this.fleetActions.filter(
      action => futureFleetActions[action.id] == null && !fleetActionIdsToRemove.includes(action.id)
    );

    const updatedFleetActions = [...intactFleetActions, ...Object.values(futureFleetActions)];

    commit(SET_FLEET_ACTIONS_LOCAL, updatedFleetActions);
    await dispatch(REPOPULATE_EXTENDED_FLEET_ACTIONS, updatedFleetActions, { root: true });
  }

  @Action
  GET_FLEET_ACTION_BY_ID(actionId: string): BoardFleetAction | undefined {
    return this.boardActions.find(action => action.id === actionId) as BoardFleetAction;
  }

  @Action
  async LOCK_FLEET_ACTION(actionId: string) {
    try {
      if (actionId != COPIED_ACTION_ID) {
        await fleetService.lockAction(actionId);
      }
      this.context.commit(
        SET_LEGS_LOCAL,
        this.legs.map(leg => ({
          ...leg,
          updatable: false,
          dropped: false,
          updated: false
        }))
      );
      this.context.commit(
        SET_BOARD_ACTIONS_LOCAL,
        this.boardActions.map(action => {
          if (action.id !== actionId) {
            return {
              ...action,
              updatable: false,
              dropped: false,
              updated: false
            };
          }
          return {
            ...action,
            updated: false
          };
        })
      );
      this.context.commit(SET_DIRTY_FLEET_ACTION_LOCAL, actionId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UNLOCK_FLEET_ACTION(actionId: string) {
    try {
      if (actionId != COPIED_ACTION_ID) {
        await fleetService.unlockAction(actionId);
      }
      await this.context.dispatch(MARK_ALL_BRICKS_UPDATABLE_LOCAL);

      if (this.dirtyBoardActionId && this.dirtyBoardActionId === actionId) {
        this.context.commit(UNSET_DIRTY_FLEET_ACTION_LOCAL);
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  MARK_ALL_BRICKS_UPDATABLE() {
    this.context.commit(
      SET_BOARD_ACTIONS_LOCAL,
      this.boardActions.map(action => ({
        ...action,
        dropped: false,
        updatable: true
      }))
    );
    this.context.commit(
      SET_LEGS_LOCAL,
      this.legs.map(leg => ({
        ...leg,
        dropped: false,
        updatable: true
      }))
    );
  }

  @Mutation
  SET_FLEET_ACTION_TO_SELECTED(actionId: string) {
    this.boardActions = this.boardActions.map(action => {
      if (action.id === actionId) {
        return {
          ...action,
          selected: true
        };
      }
      return action;
    });
  }

  @Mutation
  CLEAR_FLEET_ACTION_EVENTS(actionId: string) {
    this.boardActions = this.boardActions.map(action => {
      if (action.id === actionId) {
        return {
          ...action,
          updated: false,
          selected: false
        };
      }
      return action;
    });
  }

  @Mutation
  SET_DIRTY_FLEET_ACTION(actionId: string) {
    this.dirtyBoardActionId = actionId;
  }

  @Mutation
  UNSET_DIRTY_FLEET_ACTION() {
    this.dirtyBoardActionId = undefined;
  }

  @Action
  async ADD_ACTION_TO_STORE(fleetAction: FleetAction) {
    if (this.dirtyLegId || this.dirtyBoardActionId) {
      return;
    }

    await this.context.dispatch(UPDATE_FLEET_ACTIONS_LOCAL, {
      actions: [
        {
          ...fleetAction,
          id: COPIED_ACTION_ID,
          copied: true
        }
      ]
    });
  }

  get GET_INITIAL_LEGS() {
    return this.initialLegs;
  }

  get GET_INITIAL_ACTIONS() {
    return this.initialActions;
  }
}

export {
  GET_INITIAL_ACTIONS,
  GET_INITIAL_LEGS,
  FETCH_LEGS,
  SORT_LEGS,
  RESET_LEGS,
  UPDATE_LEGS,
  UPDATE_LEG,
  FETCH_DROPPED_LEG,
  LOCK_LEG,
  UNLOCK_LEG,
  DELETE_LEGS,
  ADJUST_BRICK_SIZES,
  CLEAR_LEG_EVENTS,
  FETCH_FLEET_ACTIONS,
  CREATE_FLEET_ACTIONS,
  RESET_FLEET_ACTIONS,
  UPDATE_FLEET_ACTION,
  UPDATE_FLEET_ACTIONS,
  DELETE_FLEET_ACTION,
  SET_LEG_TO_SELECTED,
  GET_LEG_TRANSPORT_INFO_KEYS,
  GET_DIRTY_LEG_ID,
  INDEX_LEGS,
  INDEX_BOARD_ACTIONS,
  GET_LEG_BY_ID,
  REMOVE_FLEET_ACTIONS,
  LOCK_FLEET_ACTION,
  UNLOCK_FLEET_ACTION,
  CLEAR_FLEET_ACTION_EVENTS,
  SET_FLEET_ACTION_TO_SELECTED,
  GET_DIRTY_FLEET_ACTION_ID,
  GET_FLEET_ACTION_BY_ID,
  GET_COPIED_ACTION,
  SET_COPIED_ACTION,
  ADD_ACTION_TO_STORE,
  FETCH_ACTIONS_ON_TRAILERS,
  GET_LEGS,
  GET_BOARD_ACTIONS,
  SET_LEGS,
  SET_BOARD_ACTIONS,
  GET_IGNORABLE_BRICK_ID,
  SET_IGNORABLE_BRICK_ID
};
