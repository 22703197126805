var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bookingDetail
    ? _c("div", { staticClass: "mt-8 legDetailStepSection relative" }, [
        _c("table", { staticClass: "table-fixed" }, [
          _c("thead", [
            _c("tr", [
              _c("th"),
              _c("th", {}, [_vm._v(_vm._s(_vm.$t("legSteps.stepType")))]),
              _c("th", { staticClass: "w-1/4" }, [
                _vm._v(_vm._s(_vm.$t("legSteps.timing")))
              ]),
              _c("th", {}, [_vm._v(_vm._s(_vm.$t("legSteps.address")))]),
              _c("th", { staticClass: "w-1/4" }, [
                _vm._v(_vm._s(_vm.$t("legSteps.reference")))
              ]),
              _c("th", { staticClass: "w-1/2" }, [
                _vm._v(_vm._s(_vm.$t("legSteps.remarks")))
              ])
            ])
          ]),
          _vm.steps && !_vm.isLoading
            ? _c(
                "tbody",
                _vm._l(_vm.steps, function(step, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "td",
                      [
                        _c("leg-detail-step-section-bullets", {
                          attrs: {
                            isFirst: index === 0,
                            passed: step.arrived,
                            isActive: step.active,
                            isLast: index === _vm.steps.length - 1
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("tag", { attrs: { variant: _vm.variant(step) } }, [
                          _vm._v(_vm._s(step.type))
                        ])
                      ],
                      1
                    ),
                    _c("td", [
                      _c("div", { staticClass: "flex items-center" }, [
                        step.active
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateStringToShowDate(
                                      step.effectiveArrivalDate.date
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.dateStringToShowTime(
                                      step.effectiveArrivalDate.date
                                    )
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(step.effectiveArrivalDate.timezone) + " "
                              )
                            ])
                          : step.arrived
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateStringToShowDate(
                                      step.effectiveDepartureDate.date
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.dateStringToShowTime(
                                      step.effectiveDepartureDate.date
                                    )
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(step.effectiveDepartureDate.timezone) +
                                  " "
                              )
                            ])
                          : _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateStringToShowDate(
                                      step.plannedTime.date
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.dateStringToShowTime(
                                      step.plannedTime.date
                                    )
                                  )
                              ),
                              _c("br"),
                              _vm._v(_vm._s(step.plannedTime.timezone) + " ")
                            ]),
                        step.events
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-popover",
                                  {
                                    attrs: {
                                      trigger: "hover",
                                      placement: "top",
                                      overlayStyle: { maxWidth: "650px" },
                                      getPopupContainer: function(triggerNode) {
                                        return triggerNode.parentNode
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function() {
                                            return [
                                              _c("LegDetailStepSectionEvents", {
                                                attrs: { events: step.events }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "text-black cursor-pointer",
                                      attrs: {
                                        name: "info",
                                        width: "1.5rem",
                                        height: "1.5rem"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("td", [
                      step.location
                        ? _c("span", [_vm._v(_vm._s(step.location.name))])
                        : _vm._e()
                    ]),
                    _c("td", [
                      step.references
                        ? _c("span", [_vm._v(_vm._s(step.references))])
                        : _vm._e()
                    ]),
                    _c("td", [
                      step.remarks
                        ? _c("span", [_vm._v(_vm._s(step.remarks))])
                        : _vm._e()
                    ])
                  ])
                }),
                0
              )
            : _vm._e()
        ]),
        _vm.isLoading
          ? _c(
              "div",
              {
                staticClass:
                  " h-48 w-full bg-btYellow bg-opacity-25 absolute top-0 flex items-center justify-center"
              },
              [
                _c("Icon", {
                  staticClass: "text-black",
                  attrs: { name: "spinner" }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }