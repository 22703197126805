<template>
  <svg id="logo-head" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 39" class="h-20">
    <path
      d="M61.84 37.19h14.51v-5.08h-6.77V21.29h6.47v-5.34h-6.47V6.68h6.77V1.6H61.84v35.59zM98.08 6.97h5.21v30.22h7.74V6.97h5.22V1.6H98.08v5.37zM154 2.31a15.56 15.56 0 00-13 0 5.45 5.45 0 00-2.84 5.08v24a5.39 5.39 0 002.84 5 14.67 14.67 0 0013 0 5.4 5.4 0 002.76-5v-7.68H149v6.54c0 2-.87 2.37-1.54 2.37s-1.46-.37-1.46-2.36V8.35c0-2 .87-2.15 1.54-2.18s1.46.21 1.46 2.18v5.58h7.74V7.38A5.48 5.48 0 00154 2.31zM170.34 15.83h-3.08V1.6h-7.75v35.59h7.75V21.72h3.08v15.47h7.74V1.6h-7.74v14.23zM181.88 1.6h7.74v35.59h-7.74zM130.53 17.18C126.37 14 125 13.6 125 9.37v-1c0-2 .87-2.19 1.54-2.19s1.54.2 1.54 2.18v4.9h7.74V7.4c0-3.63-3.07-6.5-9.28-6.5s-9.28 2.83-9.28 6.52v2c0 3.85.5 6.91 5.45 10.43 4.16 3.22 5.49 3.59 5.54 7.81v2.65c0 2-.88 2.37-1.54 2.37s-1.54-.38-1.54-2.37v-6h-7.74v7.16c0 3.63 3.07 6.5 9.28 6.5s9.29-2.9 9.29-6.59v-3.77c0-3.85-.51-6.91-5.47-10.43zM97.51 24.17c0-2.94-1.95-5.32-5.86-6.17 3.76-.82 5.69-2.55 5.84-5.32v-4.9c-.19-3.52-3.25-6.17-9.26-6.17h-9.3v35.58h7.74V21h1c1.32 0 2.11 0 2.11 1.61v10c0 3.61 1 4.59 1 4.59h7.75s-1-1-1-4.59c-.03-.01-.02-7.11-.02-8.44zm-7.76-9.65c0 1.57-.79 1.61-2.11 1.61h-1V6.68h1c1.32 0 2.11 0 2.11 1.61zM53 18.48c3.75-.82 5.68-3 5.84-5.82V7.77c-.19-3.52-3.25-6.17-9.25-6.17h-9.3v35.55h9.3c6.2 0 9.27-2.82 9.27-6.51v-6c-.01-2.92-1.95-5.3-5.86-6.16zm-1.9 12c0 1.57-.8 1.61-2.11 1.61H48V21h1c1.31 0 2.11 0 2.11 1.61zm0-16c0 1.57-.8 1.61-2.11 1.61H48V6.67h1c1.31 0 2.11 0 2.11 1.61z"
    />
    <g>
      <path
        fill="#d9261c"
        d="M27.25
      25.21v6.72h-6.72v5.22h11.93V25.21h-5.21zM27.15
      13.5h5.21V1.57H20.42v5.21h6.73v6.72zM5.58
      25.21H.37v11.94h11.94v-5.22H5.58v-6.72zM.47
      13.5h5.22V6.78h6.72V1.57H.47V13.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo"
};
</script>

<style scoped></style>
