var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-collapse",
        {
          staticClass: "bg-gray-100 ant-collapse--single",
          attrs: { "default-active-key": _vm.activePanelKey, bordered: false }
        },
        [
          _c(
            "a-collapse-panel",
            {
              key: _vm.checklistPanelKey,
              staticClass: "legDetailChecklist__statusDropdown text-xl",
              attrs: { header: _vm.$t("checkList.title") }
            },
            [
              _c(
                "div",
                { staticClass: "text-base mt-2" },
                [
                  _vm._l(Object.keys(_vm.legDetails), function(textCategory) {
                    return _c("div", { key: textCategory }, [
                      textCategory !== "status"
                        ? _c("div", [
                            _vm.legDetails[textCategory] &&
                            _vm.legDetails[textCategory].length
                              ? _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "w-1/3" },
                                    [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: _vm.$t(
                                              "checkList." +
                                                ((_vm.allChecked[
                                                  textCategory
                                                ] &&
                                                  "checkNone") ||
                                                  "checkAll")
                                            )
                                          }
                                        },
                                        [
                                          _c("a-checkbox", {
                                            attrs: {
                                              checked:
                                                _vm.allChecked[textCategory]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.onCheckAllChanged(
                                                  textCategory
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-gray-400 font-bold ml-2"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "checkList." + textCategory
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full grid grid-cols-4 mb-3"
                                    },
                                    _vm._l(
                                      _vm.legDetails[textCategory],
                                      function(textInfo, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            on: {
                                              change: function($event) {
                                                return _vm.onChecklistChanged(
                                                  textInfo.text,
                                                  textCategory
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "a-checkbox",
                                              {
                                                staticClass: "uppercase mr-2",
                                                attrs: {
                                                  checked: textInfo.checked
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(textInfo.text) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  }),
                  _c("div", { staticClass: "flex mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "w-1/3 text-gray-400 font-bold" },
                      [
                        _c("span", { staticClass: "ml-7" }, [
                          _vm._v(_vm._s(_vm.$t("checkList.status")))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "flex flex-wrap w-full" }, [
                      _c(
                        "div",
                        [
                          _c("multiSelect", {
                            attrs: {
                              multiple: false,
                              options: _vm.statusOptions,
                              hideSelected: true,
                              "track-by": "identifier",
                              label: "label",
                              selectLabel: _vm.$t("actions.select"),
                              deselectLabel: _vm.$t("actions.remove")
                            },
                            on: { input: _vm.onStatusChanged },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function(ref) {
                                  var option = ref.option
                                  return [
                                    _c("div", {
                                      class:
                                        "legDetailChecklist__statusBox legDetailChecklist__statusBox--" +
                                        option.identifier.toLowerCase()
                                    }),
                                    _vm._v(" " + _vm._s(option.label) + " ")
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.statusOptionsValue,
                              callback: function($$v) {
                                _vm.statusOptionsValue = $$v
                              },
                              expression: "statusOptionsValue"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }