var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "brickActionBar",
      staticClass: "absolute z-10 left-0 bottom-0 h-10 flex bg-btYellow",
      attrs: { id: "brickActionBar", tabindex: "0" }
    },
    [
      _vm.isBoardEditActive
        ? _c(
            "button",
            {
              staticClass:
                "BrickActionBar__button--save p-1 bg-btYellow transition-colors hover:bg-yellow-500",
              on: {
                touchstart: _vm.resetOnTouch,
                click: function($event) {
                  return _vm.$emit("resetBrick")
                }
              }
            },
            [_c("icon", { attrs: { name: "close" } })],
            1
          )
        : _vm._e(),
      !_vm.isBoardEditActive
        ? _c(
            "button",
            {
              staticClass:
                "BrickActionBar__button--reset p-1 bg-btYellow transition-colors hover:bg-yellow-500",
              class: { disabled: _vm.disabled },
              on: {
                touchstart: _vm.resetOnTouch,
                click: function($event) {
                  return _vm.$emit("resetBrick")
                }
              }
            },
            [_c("icon", { attrs: { name: "undo" } })],
            1
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass:
            "BrickActionBar__button--save p-1 bg-btYellow transition-colors hover:bg-yellow-500",
          class: { disabled: _vm.disabled },
          on: { touchstart: _vm.saveOnTouch, click: _vm.updateBrick }
        },
        [_c("icon", { attrs: { name: "save" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }