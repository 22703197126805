<template>
  <div>
    <table class="table-fixed stepEvents__table">
      <thead>
        <tr>
          <th class="w-1/4">Type</th>
          <th class="w-1/2">Timing</th>
          <th class="w-1/2">Received</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(event, index) in events" :key="index">
          <td>
            <div v-if="event.type">{{ event.type }}</div>
          </td>
          <td>
            <div v-if="event.occurred">
              {{ dateStringToShowDate(event.occurred.date) }} {{ dateStringToShowTime(event.occurred.date) }}<br />{{
                event.occurred.timezone
              }}
            </div>
          </td>
          <td>
            <div v-if="event.received">
              {{ dateStringToShowDateLocal(event.received.date, event.received.timezone) }}<br />{{ localTimezone }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import { dateStringToShowDate, dateStringToShowTime, dateStringToShowDateLocal, getLocalTimezone } from "@/use/useDate";

export default defineComponent({
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const localTimezone = getLocalTimezone();
    return {
      dateStringToShowDate,
      dateStringToShowTime,
      dateStringToShowDateLocal,
      getLocalTimezone,
      localTimezone
    };
  }
});
</script>

<style scoped lang="scss">
.stepEvents__table {
  td {
    @apply pb-4 pr-4;
  }
}
</style>
