

















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Dialog from "@/components/Dialog.vue";
import TimeSelectorInput from "@/components/Board/TimeSelector/TimeSelectorInput/TimeSelectorInput.vue";
import Button from "@/components/Buttons/Button.vue";

export default defineComponent({
  name: "TimeSelectorDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: true
    }
  },
  components: { Dialog, Button, TimeSelectorInput },
  setup(props, { emit }) {
    const isActive = ref<boolean>(false);
    const closeTimeSelector = () => {
      isActive.value = false;
      emit("closeHandler");
    };

    const isLoadingHandler = (value: boolean) => {
      emit("isLoading", value);
    };

    onMounted(() => {
      isActive.value = true;
    });

    return {
      isActive,
      closeTimeSelector,
      isLoadingHandler
    };
  }
});
