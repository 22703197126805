import { LANG_DE, LANG_EN } from "@/i18n/i18n";
import deDE from "ant-design-vue/es/locale-provider/de_DE";
import enGB from "ant-design-vue/es/locale-provider/en_GB";
import moment from "moment";

const localeDateFormats = {
  en: "MM/DD/YYYY",
  de: "DD.MM.YYYY"
};

const getDatePickerLocale = (lang: string): string => {
  switch (lang) {
    case LANG_EN:
      moment.locale("en-gb");
      return enGB;
    case LANG_DE:
      moment.locale("de");
      return deDE;
    default:
      return enGB;
  }
};

export { localeDateFormats, getDatePickerLocale };
