var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table-fixed stepEvents__table" }, [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.events, function(event, index) {
          return _c("tr", { key: index }, [
            _c("td", [
              event.type ? _c("div", [_vm._v(_vm._s(event.type))]) : _vm._e()
            ]),
            _c("td", [
              event.occurred
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dateStringToShowDate(event.occurred.date)) +
                        " " +
                        _vm._s(_vm.dateStringToShowTime(event.occurred.date))
                    ),
                    _c("br"),
                    _vm._v(_vm._s(event.occurred.timezone) + " ")
                  ])
                : _vm._e()
            ]),
            _c("td", [
              event.received
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateStringToShowDateLocal(
                            event.received.date,
                            event.received.timezone
                          )
                        )
                    ),
                    _c("br"),
                    _vm._v(_vm._s(_vm.localTimezone) + " ")
                  ])
                : _vm._e()
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-1/4" }, [_vm._v("Type")]),
        _c("th", { staticClass: "w-1/2" }, [_vm._v("Timing")]),
        _c("th", { staticClass: "w-1/2" }, [_vm._v("Received")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }