





























import { defineComponent, computed } from "@vue/composition-api";
import { PreviousLegDetails } from "@/types/leg";
import { defineLegTypeName, defineLegTypeIcon } from "@/use/useLeg";
import { dateStringToShowDate } from "@/use/useDate";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "PreviousLegDetail",
  components: {
    Icon
  },
  props: {
    data: {
      type: Object as () => PreviousLegDetails,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    hasIrregularities: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const heightClass = computed(() => (props.show && "details-height") || "h-0");
    const DEFAULT_TEXT = "-";

    const arrivalDate = computed(() => {
      const { effectiveArrivalDate, plannedArrivalDate } = props.data;
      const date = effectiveArrivalDate?.date || plannedArrivalDate?.date;

      if (!date) {
        return "";
      }
      return date && dateStringToShowDate(date, "dd.MM/HH:mm");
    });

    const departureInfo = computed(() => {
      const { effectiveDepartureDate, intermodalVehicleNumber } = props.data;
      const departureDate =
        (effectiveDepartureDate?.date && dateStringToShowDate(effectiveDepartureDate?.date, "dd.MM")) || DEFAULT_TEXT;
      const vehicleNumber = intermodalVehicleNumber || DEFAULT_TEXT;
      return { departureDate, vehicleNumber };
    });

    const previousLegIcon = computed(() => {
      return defineLegTypeIcon(props.data?.legType);
    });

    const backgroundGradientClass = computed(() => {
      const { arrived, booked, departed } = props.data.state || {};
      return {
        "gradient-yellow": departed,
        "gradient-green": arrived,
        "gradient-gray": booked,
        "gradient-red": props.hasIrregularities
      };
    });

    const legTypeAbbreviation = computed(() => {
      return `${defineLegTypeName(props.data?.legType)}-${props.data?.from?.name || ""}`;
    });

    return {
      heightClass,
      backgroundGradientClass,
      previousLegIcon,
      arrivalDate,
      departureInfo,
      legTypeAbbreviation
    };
  }
});
