<template>
  <div class="flex">
    <a-input
      ref="searchInputField"
      v-model="searchTerm"
      placeholder="Search..."
      class="flex"
      @pressEnter="onSearchNext"
      @change="onSearchChange"
    >
      <Icon v-show="searching" slot="suffix" name="spinner" class="-mr-2" height="25" width="25" />
      <Icon slot="prefix" name="search" class="text-gray-400 -ml-2" height="20" width="20" />
      <span v-show="!searching" slot="suffix" class="text-btYellow">
        {{ (showCounter && `${currentMatch}/${totalMatches}`) || "" }}
      </span>
    </a-input>
    <span v-if="showCounter" class="flex pt-2 px-1 border-y border-r border-t border-b border-gray-200 bg-gray-50">
      <Icon
        name="up"
        class="mr-1 cursor-pointer hover:bg-gray-200 rounded-full"
        :class="{
          'pointer-events-none': !totalMatches,
          'text-gray-200': !totalMatches
        }"
        height="20"
        width="20"
        @click="decreaseCurrentMatch"
      />
      <Icon
        name="down"
        class="mr-1 cursor-pointer hover:bg-gray-200 rounded-full"
        :class="{
          'pointer-events-none': !totalMatches,
          'text-gray-200': !totalMatches
        }"
        height="20"
        width="20"
        @click="increaseCurrentMatch"
      />
      <Icon
        name="close"
        height="20"
        width="20"
        class="cursor-pointer hover:bg-gray-200 rounded-full p-0.5"
        @click="clearSearch"
      />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "@vue/composition-api";
import { FOCUS_BOARD_ID, SEARCH_BOARD_ENTITIES } from "@/store/board";
import store from "@/store";
import Icon from "@/components/Icon.vue";
import { isInputField, isTextArea } from "@/use/useGlobalEventListeners";

export default defineComponent({
  name: "SearchBox",
  components: {
    Icon
  },

  setup() {
    const ESC_KEY = "Escape";
    const FOCUS_KEY = "f";

    const searchTerm = ref<string>("");
    const matches = ref<string[]>([]);
    const totalMatches = ref<number>(0);
    const currentMatch = ref<number>(0);
    const searchIsDirty = ref<boolean>(false);
    const searching = ref<boolean>(false);
    const showCounter = ref<boolean>(false);
    const searchInputField = ref<any>(null);

    const onSearchChange = () => {
      searchIsDirty.value = true;
      if (searchTerm.value.length === 0) {
        showCounter.value = false;
      }
    };

    const highlightCurrentMatch = async () => {
      if (currentMatch.value > 0) {
        store.commit(FOCUS_BOARD_ID, matches.value[currentMatch.value - 1]);
      }
    };

    const increaseCurrentMatch = async () => {
      if (totalMatches.value === 0) {
        return;
      }

      currentMatch.value = currentMatch.value < totalMatches.value ? currentMatch.value + 1 : 1;
      await highlightCurrentMatch();
    };

    const decreaseCurrentMatch = async () => {
      currentMatch.value = currentMatch.value > 1 ? currentMatch.value - 1 : totalMatches.value;
      await highlightCurrentMatch();
    };

    const clearSearch = async () => {
      await store.dispatch(SEARCH_BOARD_ENTITIES, "");
      store.commit(FOCUS_BOARD_ID, undefined);
      searchIsDirty.value = false;
      showCounter.value = false;
      totalMatches.value = 0;
      currentMatch.value = 0;
      searchTerm.value = "";
    };

    const onSearchNext = async () => {
      try {
        if (searchIsDirty.value) {
          searching.value = true;
          matches.value = await store.dispatch(SEARCH_BOARD_ENTITIES, searchTerm.value);
          searchIsDirty.value = false;
          showCounter.value = searchTerm.value.length > 0;
          totalMatches.value = matches.value.length;
          currentMatch.value = matches.value.length > 0 ? 1 : 0;
          await highlightCurrentMatch();
        } else {
          await increaseCurrentMatch();
        }
      } catch (error) {
        // do nothing
      }
      searching.value = false;
    };

    let fKeystrokes = 0;

    const keydownHandler = (e: KeyboardEvent) => {
      const { key: eventKey, ctrlKey: isControlHeld } = e;
      if (eventKey === ESC_KEY && searchTerm.value.length > 0) {
        clearSearch();
        return;
      }
      if (eventKey === FOCUS_KEY && !isControlHeld && !isInputField(e) && !isTextArea(e)) {
        if (fKeystrokes > 0) {
          searchInputField.value.focus();
          e.preventDefault();
          return;
        }
        fKeystrokes++;
      }
    };

    const keyupHandler = (e: KeyboardEvent) => {
      const { key: eventKey, ctrlKey: isControlHeld } = e;
      if (eventKey === FOCUS_KEY && !isControlHeld && !isInputField(e) && !isTextArea(e)) {
        setTimeout(function() {
          if (fKeystrokes > 0) {
            fKeystrokes--;
          }
        }, 100);
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", keydownHandler, true);
      document.addEventListener("keyup", keyupHandler, true);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", keydownHandler, true);
      document.removeEventListener("keyup", keyupHandler, true);
    });

    return {
      searchTerm,
      totalMatches,
      currentMatch,
      searching,
      showCounter,
      searchInputField,
      onSearchNext,
      onSearchChange,
      increaseCurrentMatch,
      decreaseCurrentMatch,
      clearSearch
    };
  }
});
</script>

<style scoped></style>
