

















import { defineComponent, onMounted, onUnmounted, ref, PropType } from "@vue/composition-api";
import { FOCUS_BOARD_ID, SPOT_BOARD_IDS } from "@/store/board";
import store from "@/store";
import Icon from "../../Icon.vue";
import { buildSearchId, searchIndex } from "@/store/board/useSearchIndex";
import { Leg } from "@/types/leg";
import ClickOutside from "vue-click-outside";
import { BoardEntityType } from "@/types/board";

export default defineComponent({
  name: "Notification",
  directives: { ClickOutside },

  components: { Icon },
  props: {
    notStartedLegsOn: {
      type: Array as PropType<Array<Leg>>,
      default: []
    },
    tickerPosition: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const ESC_KEY = "Escape";

    const matches = ref<string[]>([]);
    const currentMatch = ref<number>(0);

    const notStartedLegs = props.notStartedLegsOn.map(item => item.id);
    const totalMatches = ref<number>(notStartedLegs.length);

    const increaseCurrentMatch = () => {
      if (totalMatches.value === 0) {
        return;
      }

      currentMatch.value = currentMatch.value < totalMatches.value ? currentMatch.value + 1 : 1;
    };

    const highlightCurrentLeg = async () => {
      const indexToHighlight =
        currentMatch.value !== totalMatches.value
          ? await searchIndex(notStartedLegs[currentMatch.value])
          : await searchIndex(notStartedLegs[0]);
      store.commit(FOCUS_BOARD_ID, indexToHighlight.toString());
      await increaseCurrentMatch();
    };

    const highlightLeg = async (e: Event) => {
      try {
        if (currentMatch.value === 0) {
          store.commit(
            SPOT_BOARD_IDS,
            notStartedLegs.map(legId => buildSearchId(legId, BoardEntityType.LEG))
          );
          await highlightCurrentLeg();
          e.preventDefault();
        } else {
          await highlightCurrentLeg();
          e.preventDefault();
        }
      } catch (error) {
        // do nothing
      }
    };

    const onClickOutside = event => {
      if (currentMatch.value > 0) {
        const element = event.target.closest(".leg");
        const idToCheck = element ? element.getAttribute("id").substring(4) : null;
        if (idToCheck && notStartedLegs.find(legId => legId === idToCheck)) {
          return;
        } else {
          clearSearch();
        }
      }
    };

    const clearSearch = async () => {
      store.commit(SPOT_BOARD_IDS, undefined);
      store.commit(FOCUS_BOARD_ID, undefined);
      currentMatch.value = 0;
    };

    const keydownHandler = (e: KeyboardEvent) => {
      const { key: eventKey } = e;
      if (eventKey === ESC_KEY && matches.value) {
        clearSearch();
        return;
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", keydownHandler, true);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", keydownHandler, true);
    });

    return {
      highlightLeg,
      increaseCurrentMatch,
      clearSearch,
      onClickOutside
    };
  }
});
