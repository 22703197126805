var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "border-b-2 font-semibold mb-2 border-blue-400 text-blue-400 w-full"
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.driver.firstName) +
            " " +
            _vm._s(_vm.driver.lastName) +
            " "
        )
      ]
    ),
    _c(
      "table",
      { staticClass: "driverCallout__table table-fixed text-left w-full mb-6" },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("driver.department")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("driver.timespan")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("driver.dayShifts")))
            ])
          ])
        ]),
        _c(
          "tbody",
          { staticClass: "font-light align-top" },
          [
            _vm.driver.shifts.length === 0
              ? _c("tr", [_c("td", [_vm._v(_vm._s(_vm.driver.baseBoardId))])])
              : _vm._e(),
            _vm._l(_vm.driver.shifts, function(shift, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(_vm._s((index === 0 && _vm.driver.baseBoardId) || ""))
                ]),
                _c("td", [
                  !_vm.hideTimeframe(shift.fromDate, shift.toDate)
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(shift.fromDate) +
                            " - " +
                            _vm._s(shift.toDate)
                        )
                      ])
                    : _vm._e()
                ]),
                _c("td", [
                  !_vm.hideTimeframe(shift.fromDate, shift.toDate)
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(shift.startTime) +
                            " - " +
                            _vm._s(shift.endTime)
                        )
                      ])
                    : _vm._e()
                ])
              ])
            })
          ],
          2
        )
      ]
    ),
    _c(
      "table",
      { staticClass: "driverCallout__table table-fixed text-left w-full" },
      [
        _c("thead", { staticClass: "font-light" }, [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("driver.phoneNumber")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "align-top" }, [
          _c("tr", [
            _c("td", [
              _c(
                "div",
                { staticClass: "flex space-x-3" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.driver.phoneNumber))]),
                  _vm.driver.phoneNumber
                    ? _c("CopyToClipboard", {
                        attrs: {
                          width: "18",
                          height: "18",
                          text: _vm.driver.phoneNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }