var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex sideBarCard", style: _vm.cardStyle }, [
    _c(
      "div",
      {
        staticClass: "w-84 flex justify-center items-center",
        class: {
          "w-84": !_vm.isGroupCard,
          "w-full": _vm.isGroupCard
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "h-full w-full flex justify-center items-center pb-1",
            class: {
              "px-2": !_vm.isGroupCard,
              "pr-2": _vm.isGroupCard
            }
          },
          [
            _c(
              "Card",
              {
                class: {
                  "sideBarCard--draggable": _vm.isDraggable,
                  "sideBarCard--sortable-moved": _vm.content.moved
                },
                attrs: {
                  disabled: _vm.content.isOccupied,
                  "search-id": _vm.searchId
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-between items-center w-full"
                          },
                          [
                            _vm.isTrailerCard &&
                            _vm.onlyRenderLeadingTitle &&
                            _vm.isGroupCard
                              ? _c(
                                  "div",
                                  { staticClass: "text-lg text-yellow-900" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.trailer.groupGlueId))
                                    ])
                                  ]
                                )
                              : _c("ClosablePopover", {
                                  attrs: {
                                    placement: "rightTop",
                                    overlayStyle: {
                                      maxWidth: "700px",
                                      "z-index": 20
                                    },
                                    align: {
                                      targetOffset: [
                                        _vm.entityCallableOffset,
                                        0
                                      ]
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "popoverContent",
                                      fn: function() {
                                        return [
                                          _c("CalloutSideBar", {
                                            attrs: {
                                              content: _vm.content,
                                              type: _vm.type
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "popoverTrigger",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cursor-pointer text-lg"
                                            },
                                            [
                                              _vm.isDriverCard
                                                ? _c("span", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-blue-400",
                                                        class: {
                                                          capitalize: !_vm.hasDriverSubcontractor,
                                                          "leading-4 pt-1":
                                                            _vm.hasDriverSubcontractor
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "fleet.driver"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm.hasDriverSubcontractor
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-xs leading-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.driver
                                                                  .subcontractorName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                : _vm._e(),
                                              _vm.isTrailerCard &&
                                              !_vm.isGroupCard
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-yellow-900 capitalize"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.isGroupCard
                                                            ? _vm.trailer
                                                                .groupGlueId
                                                            : _vm.$t(
                                                                "fleet.trailer"
                                                              )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.isTruckCard
                                                ? _c("span", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-gray-900",
                                                        class: {
                                                          capitalize: !_vm.hasDriverSubcontractor,
                                                          "text-base leading-4 pt-1":
                                                            _vm.hasDriverSubcontractor
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.truckDisplayId
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm.hasDriverSubcontractor
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-xs leading-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.driver
                                                                  .subcontractorName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                : _vm._e(),
                                              _vm.isSubcontractorCard
                                                ? _c("span", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-green-600 capitalize",
                                                        class: {
                                                          "text-base leading-4 pt-1":
                                                            _vm.hasSubcontractorPrimaryBoard
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.subcontractor
                                                                .name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm.hasSubcontractorPrimaryBoard
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-xs leading-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .subcontractor
                                                                    .primaryBoardId
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                }),
                            _c(
                              "div",
                              { staticClass: "ml-auto flex flex-row" },
                              [
                                _vm.content.actions
                                  ? _c(
                                      "div",
                                      { staticClass: "cursor-pointer" },
                                      [
                                        _vm.content.actions.length
                                          ? _c(
                                              "div",
                                              [
                                                _c("ClosablePopover", {
                                                  attrs: {
                                                    placement: "rightTop",
                                                    overlayStyle: {
                                                      maxWidth: "700px"
                                                    },
                                                    align: {
                                                      targetOffset: [-60, 0]
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "popoverContent",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "CalloutSidebarActionDetail",
                                                              {
                                                                key:
                                                                  _vm.content
                                                                    .actions
                                                                    .length,
                                                                attrs: {
                                                                  actions:
                                                                    _vm.content
                                                                      .actions
                                                                },
                                                                on: {
                                                                  onFleetAction:
                                                                    _vm.showSplitScreen
                                                                }
                                                              }
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "popoverTrigger",
                                                        fn: function() {
                                                          return [
                                                            _c("Icon", {
                                                              staticClass:
                                                                "text-btYellow p-1",
                                                              attrs: {
                                                                name: "clock"
                                                              }
                                                            })
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3897838706
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _c("LastLocationAction", {
                                  attrs: { "fleet-entity": _vm.content }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-popover",
                              {
                                attrs: {
                                  placement: "bottomRight",
                                  trigger: "click",
                                  overlayClassName: "menu-popover"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function() {
                                      return [
                                        _c("SidebarActionCallout", {
                                          attrs: { content: _vm.content },
                                          on: {
                                            onAffiliate: _vm.showSplitScreen,
                                            onFleetAction: _vm.showSplitScreen,
                                            closePopover: function($event) {
                                              return _vm.closePopover($event)
                                            }
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.isMenuVisible,
                                  callback: function($$v) {
                                    _vm.isMenuVisible = $$v
                                  },
                                  expression: "isMenuVisible"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer h-full",
                                    class: {
                                      "cursor-not-allowed": _vm.isDraggable
                                    }
                                  },
                                  [
                                    _c("Icon", {
                                      class: {
                                        "text-gray-400": _vm.isDraggable
                                      },
                                      attrs: { name: "3dots" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "Carousel",
                  { ref: "cardCarouselRef", attrs: { draggable: true } },
                  [
                    _c("div", { staticClass: "flex-grow flex py-1 h-full" }, [
                      _c("div", { staticClass: "pr-1 flex flex-grow" }, [
                        _c("div", { staticClass: "w-1/2 pr-2 box-border" }, [
                          _vm.isTrailerCard || _vm.trailer
                            ? _c(
                                "div",
                                [
                                  !_vm.trailer.groupGlueId
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.spreadTrailerId(_vm.trailer.id)
                                              .id
                                          )
                                        )
                                      ])
                                    : _c("ClosablePopover", {
                                        attrs: {
                                          placement: "rightTop",
                                          overlayStyle: {
                                            maxWidth: "700px",
                                            "z-index": 20
                                          },
                                          align: { targetOffset: [-142, 33] }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "popoverContent",
                                              fn: function() {
                                                return [
                                                  _c("CalloutSideBar", {
                                                    attrs: {
                                                      content: _vm.content,
                                                      type: _vm.type
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "popoverTrigger",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.spreadTrailerId(
                                                              _vm.trailer.id
                                                            ).id
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2146321147
                                        )
                                      }),
                                  _c("div", { staticClass: "text-xxs" }, [
                                    _vm._v(
                                      _vm._s(_vm.trailer.metadata.chassisType)
                                    )
                                  ]),
                                  _c("div", { staticClass: "text-xxs" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trailer.metadata.rotaryValveBrand
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSubcontractorCard &&
                          _vm.subcontractor.flatTruckName
                            ? _c("div", [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.subcontractor.flatTruckName)
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "w-1/2 pl-2 box-border fixed-name-box"
                          },
                          [
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  title: _vm.isNameTruncated
                                    ? _vm.driver.firstName +
                                      " " +
                                      _vm.driver.lastName
                                    : ""
                                }
                              },
                              [
                                _vm.isDriverCard || _vm.driver
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          ref: "firstNameRef",
                                          staticClass: "truncate"
                                        },
                                        [_vm._v(_vm._s(_vm.driver.firstName))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          ref: "lastNameRef",
                                          staticClass: "truncate"
                                        },
                                        [_vm._v(_vm._s(_vm.driver.lastName))]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm.isSubcontractorCard &&
                            _vm.subcontractor.flatDriverName
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.subcontractor.flatDriverName)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "sideBarCard__actions h-full flex flex-col"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "sideBarCard__actions__icon" },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    align: { offset: [-5, 0] },
                                    title: _vm.$t("fleet.noAdr")
                                  }
                                },
                                [
                                  _vm.hasNoAdr
                                    ? _c("Icon", {
                                        attrs: {
                                          name: "no-adr",
                                          height: "20",
                                          original: true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "sideBarCard__actions__icon" },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    align: { offset: [-5, 0] },
                                    title: _vm.$t("fleet.compressor")
                                  }
                                },
                                [
                                  _vm.hasCompressor
                                    ? _c("Icon", {
                                        attrs: {
                                          name: "compressor",
                                          height: "20",
                                          original: true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.hasAdditionalCooler
                            ? _c(
                                "div",
                                { staticClass: "sideBarCard__actions__icon" },
                                [
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        align: { offset: [-5, 0] },
                                        title: _vm.$t("fleet.additionalCooler")
                                      }
                                    },
                                    [
                                      _vm.hasAdditionalCooler
                                        ? _c("Icon", {
                                            attrs: {
                                              name: "additional-cooler",
                                              height: "20",
                                              original: true
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasDropWithTrailer
                            ? _c(
                                "div",
                                { staticClass: "sideBarCard cursor-pointer" },
                                [
                                  _c("ClosablePopover", {
                                    attrs: {
                                      placement: "rightTop",
                                      overlayStyle: {
                                        maxWidth: "600px",
                                        "z-index": 20
                                      },
                                      align: { targetOffset: [-5, 0] }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "popoverContent",
                                          fn: function() {
                                            return [
                                              _c("CalloutLegContainer", {
                                                staticClass: "px-2",
                                                attrs: {
                                                  title: _vm.getLastLocationContainerId(
                                                    _vm.trailer.metadata
                                                      .lastLocation
                                                  ),
                                                  content: _vm.trailer
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "popoverTrigger",
                                          fn: function() {
                                            return [
                                              _c(
                                                "a-tooltip",
                                                {
                                                  attrs: {
                                                    placement: "right",
                                                    align: { offset: [-5, 0] },
                                                    title: _vm.$t(
                                                      "fleet.dropWithTrailer"
                                                    )
                                                  }
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      name: "trailerdrop",
                                                      height: "20",
                                                      original: true
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      981652912
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm.driver
                      ? _c("CardDriverRemarks", {
                          attrs: {
                            driver: _vm.driver,
                            "card-description": _vm.cardDescription
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }