const prependZeroNumber = (number: number): string => {
  return number.toString().padStart(2, "0");
};

/**
 * @param {number} num
 * @param {number} lowerLimit: lower limit
 * @param {number} upperLimit: upper limit
 * @return {boolean}
 *
 * Checks if num lies in interval [lowerLimit, upperLimit)
 */
const isBetween = (num: number, lowerLimit: number, upperLimit: number): boolean => {
  return num >= lowerLimit && num < upperLimit;
};

export { prependZeroNumber, isBetween };
