<template>
  <button
    :disabled="disabled"
    @click="onClick"
    :class="{ 'filterButton--disabled': disabled }"
    class="buttonIcon bg-btYellow flex-shrink-0 cursor-pointer transition-colors duration-300 hover:bg-yellow-500 ml-2"
  >
    <a-tooltip placement="bottom" :align="{ offset: [0, 3] }" :title="tooltip">
      <Icon :name="icon" key="1" />
    </a-tooltip>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import Button from "./Button.vue";

export default defineComponent({
  name: "FilterButton",
  components: { Icon, Button },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit("click");
    };

    return { onClick };
  }
});
</script>

<style scoped lang="scss">
.filterButton {
  &--disabled {
    @apply cursor-default bg-gray-200;
    &:hover {
      @apply bg-gray-200;
    }
  }
}
</style>
