<template>
  <div class="min-h-screen">
    <Overlay class="left-84" v-show="fleetEditActive || fleetIsLoading" />
    <div class="z-20 fixed">
      <BoardFilter
        class=" z-20 bg-gray-100 left-0"
        @edit="onFleetEdit()"
        @save="onFleetSave()"
        @cancel="onFleetCancel()"
        :editModeActive="fleetEditActive"
      />
      <TrailerFilter
        class=" z-20 bg-gray-100 left-0"
        @edit="onFleetEdit()"
        @save="onFleetSave()"
        @cancel="onFleetCancel()"
        :editModeActive="fleetEditActive"
      />
    </div>

    <div class="mt-28 absolute z-10 left-0 top-0 bg-gray-100 sidebar w-full" :style="styledObject">
      <div v-if="fleetIsEmpty || fleetIsLoading">
        <div v-if="fleetIsEmpty && !fleetIsLoading && boardsSelected" class="flex justify-center items-center h-24">
          <div>No data found</div>
        </div>
        <div v-if="fleetIsLoading" class="p-2">
          <a-skeleton active :paragraph="{ rows: 60 }" />
        </div>
      </div>

      <div v-if="!fleetIsLoading">
        <draggable
          id="fleetList"
          :disabled="!fleetEditActive"
          v-model="fleetDraggables"
          @start="startDrag"
          @end="stopDrag"
          @change="highlightMovedElement"
          ghostClass="sortable-ghost"
          chosenClass="sortable-chosen"
          dragClass="dragClass"
        >
          <div v-for="draggable in fleetDraggables" :key="draggable.ordinal">
            <BoardSideBarCard
              v-if="
                draggable.items.length === 1 &&
                  !(draggable.items[0].type === FleetEntityType.TRAILER && draggable.items[0].groupGlueId)
              "
              :key="draggable.items[0].id"
              :content="draggable.items[0]"
              :type="draggable.items[0].type"
              :isDraggable="fleetEditActive"
              :onlyRenderLeadingTitle="false"
              :isGroupCard="false"
            />
            <div
              v-else
              :class="{
                'sideBarCard--grouped__subcontractor': draggable.items[0].type === FleetEntityType.SUBCONTRACTOR,
                'sideBarCard--grouped__truck': draggable.items[0].type === FleetEntityType.TRUCK,
                'sideBarCard--grouped__trailer': draggable.items[0].type === FleetEntityType.TRAILER
              }"
            >
              <BoardSideBarCard
                v-for="(groupDraggable, index) in draggable.items"
                :key="groupDraggable.id"
                :content="groupDraggable"
                :type="groupDraggable.type"
                :isDraggable="fleetEditActive"
                :isGroupCard="true"
                :onlyRenderLeadingTitle="groupDraggable.type === FleetEntityType.TRAILER && index === 0"
              />
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div v-if="isBoardEditModeActive" class="h-full w-84 bg-black fixed top-0 left-0 z-30 opacity-20"></div>
  </div>
</template>

<script lang="ts">
import useScrollPosition from "@/use/useScrollPosition";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  provide,
  Ref,
  ref,
  watch,
  nextTick
} from "@vue/composition-api";
import BoardFilter from "@/components/Board/Filter/BoardFilter.vue";
import BoardSideBarCard from "@/components/Board/SideBar/SideBarCard/SideBarCard.vue";
import Overlay from "@/components/Overlay.vue";
import TrailerFilter from "@/components/Board/Filter/TrailerFilter.vue";
import Icon from "@/components/Icon.vue";
import draggable from "vuedraggable";
import store from "@/store";
import { FleetEntityType } from "@/types/fleet";
import {
  CLEAR_MOVED_FLEET_ENTITIES,
  SET_MOVED_FLEET_ENTITIES,
  FLEET_IS_EMPTY,
  FLEET_LOADING,
  GET_FLEET,
  UPDATE_FLEET,
  UPDATE_FLEET_ORDER,
  IS_DRIVER_COMMENT_TOGGLE_ACTIVE
} from "@/store/fleet";
import { FleetEntity, Subcontractor } from "@/types/fleet";
import { GET_SELECTED_BOARDS, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { startMouseScroll, stopMouseScroll } from "@/use/useMouseScroll";
import { SORT_LEGS } from "@/store/brick";
import { isInputField, isTextArea } from "@/use/useGlobalEventListeners";

interface FleetEntityDraggable {
  items: FleetEntity[];
  ordinal: number;
}

export default defineComponent({
  name: "BoardSideBar",
  components: { BoardFilter, BoardSideBarCard, Icon, draggable, Overlay, TrailerFilter },
  props: {
    trucks: {
      type: Array
    }
  },
  setup() {
    const fleetDraggables = computed<FleetEntityDraggable[]>({
      get() {
        const fleet: FleetEntity[] = store.getters[GET_FLEET];
        return toDraggables(fleet);
      },
      set(draggables) {
        store.commit(UPDATE_FLEET, toFleet(draggables));
      }
    });

    const boardsSelected = computed(() => {
      const selectedBoards = store.getters[GET_SELECTED_BOARDS];
      return !!selectedBoards?.length;
    });

    const fleetIsEmpty = computed(() => store.getters[FLEET_IS_EMPTY]);
    const fleetIsLoading = computed(() => store.getters[FLEET_LOADING]);

    const fleetEditActive = ref(false);

    const toDraggables = (fleet: FleetEntity[]): FleetEntityDraggable[] => {
      const idToDraggable = fleet.reduce((acc, entity, index) => {
        if ((entity as Subcontractor).groupGlueId == null) {
          acc[entity.id] = {
            items: [entity],
            ordinal: index
          };
          return acc;
        }

        if (acc[entity.groupGlueId!] == null) {
          acc[entity.groupGlueId!] = {
            items: [],
            ordinal: index
          };
        }

        acc[entity.groupGlueId!].items = [...acc[entity.groupGlueId!].items, entity];
        return acc;
      }, {} as any);

      const orderedFleet = Object.values<FleetEntityDraggable>(idToDraggable).sort((draggable1, draggable2) => {
        return draggable1.ordinal - draggable2.ordinal;
      });

      return orderedFleet;
    };

    const toFleet = (draggables: FleetEntityDraggable[]): FleetEntity[] => {
      return draggables.flatMap(draggable => [...draggable.items]);
    };
    watch(
      () =>
        toFleet(fleetDraggables.value)
          .map(fe => fe.groupGlueId)
          .join(","),
      () => {
        store.commit(UPDATE_FLEET, toFleet(fleetDraggables.value));
        store.dispatch(SORT_LEGS);
      }
    );

    let fleetClone: FleetEntityDraggable[] = [];

    const onFleetEdit = (): void => {
      fleetClone = [...fleetDraggables.value];
      fleetEditActive.value = true;
      store.commit(CLEAR_MOVED_FLEET_ENTITIES);
    };

    const onFleetSave = (): void => {
      store.dispatch(UPDATE_FLEET_ORDER);
      store.commit(CLEAR_MOVED_FLEET_ENTITIES);
      fleetEditActive.value = false;
    };

    const onFleetCancel = (): void => {
      store.commit(CLEAR_MOVED_FLEET_ENTITIES);
      fleetDraggables.value = [...fleetClone];
      fleetEditActive.value = false;
    };

    const highlightMovedElement = (event: any): void => {
      const { newIndex } = event.moved;

      let newFleetIndex = 0;
      for (let i = 0; i < newIndex; i++) {
        newFleetIndex += fleetDraggables.value[i].items.length;
      }

      const draggable = fleetDraggables.value[newIndex];
      if (draggable.items.length === 1) {
        store.commit(SET_MOVED_FLEET_ENTITIES, [newFleetIndex]);
        return;
      }
      const newIndices = [...Array(draggable.items.length).keys()].map(index => index + newFleetIndex);
      store.commit(SET_MOVED_FLEET_ENTITIES, newIndices);
    };

    const useScroll = useScrollPosition();
    const scrolledLeft = useScroll.scrolledLeft;
    const styledObject = computed(() => {
      if (scrolledLeft.value > 0) {
        return {
          top: 0,
          left: `${scrolledLeft.value}px`
        };
      } else {
        return {};
      }
    });

    const startDrag = () => {
      startMouseScroll();
    };
    const stopDrag = () => {
      stopMouseScroll();
    };

    const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

    const cardCarouselRefs = ref<any>([]);

    const addCarouselRef = (id: string, reference: Ref<any>) => {
      cardCarouselRefs.value.push({ id, reference });
    };

    const removeCarouselRef = (id: string) => {
      const currentRef = cardCarouselRefs.value.find(r1 => r1.id === id);
      if (currentRef) {
        const currentRefIndex = cardCarouselRefs.value.indexOf(currentRef);
        if (currentRefIndex !== -1) {
          cardCarouselRefs.value.splice(currentRefIndex, 1);
        }
      }
    };

    provide("addCarouselRef", addCarouselRef);
    provide("removeCarouselRef", removeCarouselRef);

    const driverCommentToggleActive = computed<boolean>(() => store.getters[IS_DRIVER_COMMENT_TOGGLE_ACTIVE]);
    const cardKeyHandler = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === "t" && driverCommentToggleActive.value && !isInputField(e) && !isTextArea(e)) {
        if (cardCarouselRefs.value) {
          for (const cardCarouselRef of cardCarouselRefs.value) {
            cardCarouselRef.reference.value.next();
          }
        }
      }
    };

    onMounted(async () => {
      document.addEventListener("keydown", cardKeyHandler, true);
      await nextTick();
      store.commit(UPDATE_FLEET, toFleet(fleetDraggables.value));
      store.dispatch(SORT_LEGS);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", cardKeyHandler, true);
    });

    return {
      highlightMovedElement,
      onFleetEdit,
      onFleetSave,
      onFleetCancel,
      startDrag,
      stopDrag,
      fleetEditActive,
      styledObject,
      fleetIsEmpty,
      fleetIsLoading,
      boardsSelected,
      isBoardEditModeActive,
      fleetDraggables,
      FleetEntityType
    };
  }
});
</script>

<style lang="scss">
@use "Sidebar";
</style>
