











import { defineComponent, ref, watch, computed, inject } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";

export default defineComponent({
  name: "ContextMenu",
  directives: { ClickOutside },
  props: {
    mouseEvent: {
      type: MouseEvent
    }
  },
  setup(props) {
    const visible = ref(false);
    const position = ref({ left: 0, top: 0 });

    const open = (posX: number, posY: number): void => {
      position.value.left = posX;
      position.value.top = posY;
      visible.value = true;
    };

    const setSelectedAction: any = inject("setSelectedAction");

    const close = (): void => {
      visible.value = false;
      setSelectedAction(null);
    };

    watch(
      () => props.mouseEvent,
      () => {
        const { clientX, clientY, pageX, pageY } = props.mouseEvent as MouseEvent;

        const distanceToRightScreenBorder = window.innerWidth - clientX;
        const distanceToBottomScreenBorder = window.innerHeight - clientY;

        const spawnX = distanceToRightScreenBorder > 200 ? pageX : pageX - 200;
        const spawnY = distanceToBottomScreenBorder > 500 ? pageY : pageY - 300;

        open(spawnX, spawnY);
      }
    );

    const positionCls = computed(() => {
      return {
        left: `${position.value.left}px`,
        top: `${position.value.top}px`
      };
    });

    return {
      visible,
      position,
      close,
      positionCls
    };
  }
});
