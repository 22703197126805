<template>
  <div class="grid grid-cols-4 gap-x-5 gap-y-2">
    <div class="planned-departure-title text-gray-400 col-span-2">{{ $t("leg.events.plannedDeparture") }}</div>
    <div class="planned-arrival-title text-gray-400 col-span-2">{{ $t("leg.events.plannedArrival") }}</div>
    <div class="col-span-1">
      <span class="block text-gray-400 mb-2">{{ $t("dates.fromDate") }}</span>
      <a-date-picker
        v-model="planned.fromDate"
        class="w-full"
        format="DD.MM.YYYY"
        size="large"
        :disabled="disableAllInputs || disablePlannedDepartureFields"
        :show-today="false"
        :getCalendarContainer="setTriggerAsAnchor"
        @change="onChange"
      />
    </div>
    <div class="col-span-1">
      <span class="block text-gray-400 mb-2">{{ $t("times.fromTime") }}</span>
      <a-time-picker
        v-model="planned.fromTime"
        size="large"
        class="w-full"
        format="HH:mm"
        :disabled="disableAllInputs || disablePlannedDepartureFields"
        :getPopupContainer="setTriggerAsAnchor"
        @change="onChange"
      />
    </div>
    <div class="col-span-1">
      <span class="block text-gray-400 mb-2">{{ $t("dates.toDate") }}</span>
      <a-date-picker
        v-model="planned.toDate"
        size="large"
        class="w-full"
        format="DD.MM.YYYY"
        :disabled="disableAllInputs"
        :show-today="false"
        :getCalendarContainer="setTriggerAsAnchor"
        @change="onChange"
      />
    </div>
    <div class="col-span-1">
      <span class="block text-gray-400 mb-2">{{ $t("times.toTime") }}</span>
      <a-time-picker
        v-model="planned.toTime"
        size="large"
        class="w-full"
        format="HH:mm"
        :disabled="disableAllInputs"
        :getPopupContainer="setTriggerAsAnchor"
        @change="onChange"
      />
    </div>
    <div
      class="col-span-4 error-hint text-center text-red-600 error-transition overflow-hidden"
      :class="{ 'h-0': datesValid, 'h-6': !datesValid }"
    >
      {{ $t("errors.INVALID_PLANNED_DATE_RANGE") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, computed, reactive } from "@vue/composition-api";
import { LegStatuses } from "@/types/leg";
import moment from "moment";
import { LegPlannedFields } from "@/types/leg";

export default defineComponent({
  name: "LegDetailPlannedDates",
  emits: ["plannedDatesChange"],
  props: {
    status: {
      type: Object as PropType<LegStatuses>,
      required: true
    },
    plannedDates: {
      type: Object as PropType<Record<string, string | undefined>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const planned = reactive<LegPlannedFields>({
      fromDate: null,
      fromTime: null,
      toDate: null,
      toTime: null,
      valid: true
    });

    const hasTime = (date: string): boolean => {
      const [dateStr, timeStr] = date.split("T");
      return timeStr != null;
    };

    onMounted(() => {
      const { plannedDepartureDate, plannedArrivalDate } = props.plannedDates;
      if (plannedDepartureDate != null) {
        planned.fromDate = moment(plannedDepartureDate);
        planned.fromTime = hasTime(plannedDepartureDate) ? moment(plannedDepartureDate) : null;
      }
      if (plannedArrivalDate != null) {
        planned.toDate = moment(plannedArrivalDate);
        planned.toTime = hasTime(plannedArrivalDate) ? moment(plannedArrivalDate) : null;
      }
    });

    const disableAllInputs = computed(() => {
      return props.status.dropped;
    });

    const disablePlannedDepartureFields = computed(() => {
      const { departed, loaded, unloaded } = props.status;
      return departed || loaded || unloaded;
    });

    const datesValid = computed(() => {
      if (planned.fromDate != null && planned.toDate != null) {
        const formattedFrom = moment(planned.fromDate);
        formattedFrom.hour(planned.fromTime ? planned.fromTime.hour() : 0);
        formattedFrom.minutes(planned.fromTime ? planned.fromTime.minutes() : 0);

        const formattedTo = moment(planned.toDate);
        formattedTo.hour(planned.toTime ? planned.toTime.hour() : 23);
        formattedTo.minutes(planned.toTime ? planned.toTime.minutes() : 59);

        return formattedFrom.isSameOrBefore(formattedTo);
      }
      return false;
    });

    const onChange = () => {
      planned.valid = datesValid.value;
      emit("plannedDatesChange", planned);
    };

    const setTriggerAsAnchor = triggerNode => triggerNode.parentNode;

    return {
      datesValid,
      onChange,
      planned,
      setTriggerAsAnchor,
      disableAllInputs,
      disablePlannedDepartureFields
    };
  }
});
</script>

<style scoped>
.error-transition {
  transition: height 250ms ease-in-out;
}
</style>
