<template>
  <div
    v-if="legStatuses.departed || legStatuses.unloaded || legStatuses.loaded || legStatuses.booked"
    class="step-progress-indicator w-full flex flex-nowrap overflow-hidden"
  >
    <div
      v-for="(group, key) in stepGroups"
      :key="key"
      :style="group.hasEnd ? { width: `${group.width}px`, minWidth: `${group.width}px` } : null"
      :class="{ 'flex-1': !group.hasEnd }"
      class="flex flex-row"
    >
      <div
        v-for="(step, index) in group.steps"
        :key="`step-${index}`"
        class="step-border box-border flex-1"
        :class="{ [defineStepColorCls(step.status || '')]: true, border: group.width > 0 }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { BoardLeg, LegStatuses } from "@/types/leg";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { GET_BOARD_DETAILS, GET_SHOW_WEEKENDS } from "@/store/board";
import { getEventXBoardCoordinate } from "@/store/brick/useBrick";
import { LegStep } from "@/types/leg";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "LegStepProgressIndicator",
  props: {
    leg: {
      type: Object as PropType<BoardLeg>,
      required: true
    },
    legStatuses: {
      type: Object as PropType<LegStatuses>,
      required: true
    },
    legBoundaries: { type: Object as PropType<{ start: string; end: string }> },
    currentLegWidth: {
      type: Number,
      required: true,
      default: 0
    },
    currentLegXCoord: {
      type: Number,
      required: true,
      default: 0
    }
  },
  setup(props) {
    const defineStepColorCls = (status: string): string => {
      switch (status) {
        case "PLANNED":
          return "bg-gray-300";
        case "IN_PROGRESS":
          return "bg-btBrown";
        case "DONE":
          return "bg-green-700";
        default:
          return "bg-gray-400";
      }
    };

    type StepBlock = {
      steps: LegStep[];
      endXCoord: number;
      width: number;
      hasEnd: boolean;
    };

    /* Function that attempts to correct some pixel errors that occur due to floating point errors */
    const sanitizeXCoordinate = (xCoord: number, hourBoxWidth: number, isHourStart: boolean): number => {
      if (isHourStart) {
        const xDeviation = (xCoord + 3) % hourBoxWidth;
        return xCoord - xDeviation;
      }
      return xCoord;
    };

    const boardDetails = computed(() => store.getters[GET_BOARD_DETAILS]);
    const showWeekends = computed(() => store.getters[GET_SHOW_WEEKENDS]);

    const stepGroups = computed(() => {
      // eslint-disable-next-line
      const _currentLegXCoord = props.currentLegXCoord;
      const { steps } = props.leg;

      let stepGroupBlockStart = 0;
      let stepGroupBlockEnd = 0;
      const stepGroups: LegStep[][] = [];

      /**
       * Create slices/groups of steps which will share space within a block
       * Groups are built by splitting steps at steps which have time (or end of steps)
       * Consider: "S" -> Step with time; "N" -> Step without time
       * Example 1: [N, N, S, N, S, S, N, N] -> (after grouping) -> [[N, N, S], [N, S] [S], [N, N]]
       * Example 2: [N, N, N, N]             -> (after grouping) -> [[N, N, N, N]]
       */
      steps.forEach((step, index) => {
        if (step.time != null || index === steps.length - 1) {
          stepGroupBlockEnd = index;
          const groupSlice = steps.slice(stepGroupBlockStart, stepGroupBlockEnd + 1);
          stepGroups.push(groupSlice);
          stepGroupBlockStart = stepGroupBlockEnd + 1;
        }
      });

      const stepBlocks: Record<string, StepBlock> = {};
      stepGroups.forEach((group, index) => {
        const blockKey = `block-${index}`;
        const previousBlockKey = `block-${index - 1}`;
        stepBlocks[blockKey] = {} as StepBlock;
        const lastMember = group[group.length - 1];
        if (lastMember.time != null) {
          let stepEndXCoord = getEventXBoardCoordinate(
            lastMember.time.date,
            boardDetails.value.boardFromDate,
            boardDetails.value.boardFromTime,
            boardDetails.value.boardToTime,
            boardDetails.value.hourBoxesPerDay,
            boardDetails.value.hourBoxWidth,
            boardDetails.value.boardWidth,
            showWeekends.value
          );
          stepEndXCoord = sanitizeXCoordinate(
            stepEndXCoord,
            boardDetails.value.hourBoxWidth,
            moment(lastMember.time.date).minute() === 0
          );
          const isStepEndBeforeVisibleLegStart = stepEndXCoord < props.currentLegXCoord;
          const isStepEndAfterVisibleLegEnd = stepEndXCoord > props.currentLegXCoord + props.currentLegWidth;

          if (index === 0) {
            stepBlocks[blockKey].endXCoord = stepEndXCoord;
            if (isStepEndBeforeVisibleLegStart) {
              stepBlocks[blockKey].width = 0;
            } else if (isStepEndAfterVisibleLegEnd) {
              stepBlocks[blockKey].width = props.currentLegWidth;
            } else {
              stepBlocks[blockKey].width = Math.abs(stepEndXCoord - props.currentLegXCoord);
            }
            stepBlocks[blockKey].hasEnd = true;
            stepBlocks[blockKey].steps = group;
          } else {
            let width = 0;
            const isPreviousStepVisible = stepBlocks[previousBlockKey].width! > 0;
            if (isStepEndBeforeVisibleLegStart) {
              width = 0;
            } else if (isPreviousStepVisible && isStepEndAfterVisibleLegEnd) {
              width = Math.abs(props.currentLegWidth - stepBlocks[previousBlockKey].width);
            } else if (isPreviousStepVisible) {
              width = Math.abs(stepEndXCoord - stepBlocks[previousBlockKey].endXCoord);
            } else {
              width = Math.abs(stepEndXCoord - props.currentLegXCoord);
            }
            stepBlocks[blockKey].endXCoord = stepEndXCoord;
            stepBlocks[blockKey].width = width;
            stepBlocks[blockKey].hasEnd = true;
            stepBlocks[blockKey].steps = group;
          }
        } else {
          stepBlocks[blockKey].endXCoord = Infinity;
          stepBlocks[blockKey].width = Infinity;
          stepBlocks[blockKey].hasEnd = false;
          stepBlocks[blockKey].steps = group;
        }
      });
      return stepBlocks;
    });

    return {
      boardDetails,
      showWeekends,
      stepGroups,
      defineStepColorCls
    };
  }
});
</script>

<style scoped>
.step-progress-indicator {
  height: 5px;
}
.step-border {
  border-color: #595959;
}
</style>
