<template>
  <div>
    <div class="w-44 shadow-lg">
      <button
        @click="openFleetDetail"
        class="p-2 w-full bg-white openFleetDetailHandler"
        :class="{ 'cursor-pointer hover:bg-btYellow': !isDriver, 'text-gray-300': isDriver }"
        :disabled="isDriver"
      >
        <span class="ml-2">{{ $t("fleet.affiliationTitle") }}</span>
      </button>
      <button
        @click="openFleetAction"
        class="p-2 w-full cursor-pointer bg-white hover:bg-btYellow openFleetActionHandler"
      >
        <span class="ml-2">{{ $t("fleet.actionReminderTitle") }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { FleetEntity, FleetEntityType, Truck } from "@/types/fleet";
import { computed, defineComponent } from "@vue/composition-api";
import { spreadTrailerId, spreadTruckId } from "@/use/useFleet";

export default defineComponent({
  name: "CalloutSidebarAction.vue",
  props: {
    content: {
      type: Object as () => FleetEntity
    }
  },
  setup(props, { emit }) {
    const isDriver = computed(() => {
      return props.content?.type === FleetEntityType.DRIVER;
    });

    const openFleetDetail = () => {
      if (props.content?.type === FleetEntityType.TRAILER) {
        emit("onAffiliate", { ...props.content, id: spreadTrailerId(props.content.id).id });
        emit("closePopover");
      } else if (!isDriver.value) {
        emit("onAffiliate", props.content);
        emit("closePopover");
      }
    };

    const openFleetAction = () => {
      if (props.content?.type === FleetEntityType.TRUCK) {
        const truck = props.content as Truck;
        const payload = {
          truckId: spreadTruckId(truck.id).id,
          trailerId: truck.trailer?.id,
          driverId: truck.crewDriver?.id,
          payloadType: "action"
        };
        emit("onFleetAction", payload);
        emit("closePopover");
        return;
      }
      if (props.content?.type === FleetEntityType.DRIVER) {
        emit("onFleetAction", {
          driverId: props.content?.id,
          payloadType: "action"
        });
        emit("closePopover");
      }
      if (props.content?.type === FleetEntityType.SUBCONTRACTOR) {
        emit("onFleetAction", {
          subcontractorId: props.content?.id,
          payloadType: "action"
        });
        emit("closePopover");
      }
      if (props.content?.type === FleetEntityType.TRAILER) {
        emit("onFleetAction", {
          trailerId: props.content?.id,
          payloadType: "action"
        });
        emit("closePopover");
      }
    };

    return {
      isDriver,
      openFleetDetail,
      openFleetAction
    };
  }
});
</script>

<style scoped lang="scss"></style>
