var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDialog
        ? _c(
            "Dialog",
            {
              attrs: {
                title: _vm.$t("timeSelector.selectTime"),
                isActive: _vm.isActive,
                "disable-scroll": true
              },
              on: {
                closeDialog: function($event) {
                  return _vm.closeTimeSelector()
                }
              }
            },
            [
              _c("time-selector-input", {
                on: { isLoading: _vm.isLoadingHandler }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("time-selector-input", {
                on: { isLoading: _vm.isLoadingHandler }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }