


















import { computed, defineComponent, PropType } from "@vue/composition-api";
import { TrailerMetadata, TruckMetaData } from "@/types/fleet";
import { getNextInspectionDetails } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";

export default defineComponent({
  name: "FleetNextInspectionTable",
  props: {
    content: {
      type: Object as PropType<TruckMetaData | TrailerMetadata>,
      required: true
    }
  },
  setup(props) {
    const truckInspections = computed(() => getNextInspectionDetails(props.content));
    return {
      truckInspections
    };
  }
});
