var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VueDragResize",
    {
      key: _vm.legKey,
      ref: "brickDragComponent",
      staticClass: "flex items-center drag-resize brick",
      class: _vm.computedLegClass,
      attrs: {
        tabindex: "0",
        preventActiveBehavior: _vm.isLegDisabled,
        sticks: _vm.resizeSticks,
        w: _vm.legCoordinates.width === 0 ? 15 : _vm.legCoordinates.width,
        minw: 10,
        h: 100,
        snapToGrid: true,
        gridY: 100,
        gridX: _vm.hourBoxWidth / 4,
        parentLimitation: true,
        parentW: _vm.parentWidth,
        x: _vm.legCoordinates.x,
        y: _vm.legCoordinates.y,
        axis: _vm.allowedAxisMovement,
        isDraggable: _vm.draggable,
        isResizable: _vm.updatable
      },
      on: {
        dragging: _vm.onDragging,
        dragstop: _vm.onLegStopDrag,
        resizing: _vm.onLegResize,
        resizestop: _vm.onResizeStop,
        activated: _vm.onActivated,
        deactivated: _vm.onDeactivated,
        clicked: _vm.onDoubleTapOnTouchDevice
      }
    },
    [
      _c("span", {
        key: _vm.overlaySpanKey,
        staticClass: "leg absolute w-full h-24 py-2 px-4 rounded",
        class: _vm.overlaySpanClass
      }),
      _c("SearchMatchOverlay", {
        attrs: { "search-id": _vm.searchId, outerSpanClass: "h-24 rounded" }
      }),
      _vm.hasPreviousLeg
        ? _c("div", {
            staticClass: "leg--previous absolute w-full",
            class: [_vm.previousLegClass, _vm.previousLegIndicatorHeightClass]
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "leg absolute w-full h-24 cursor-pointer pl-2 shadow-lg bg-gray-100 border border-gray-400",
          class: [
            _vm.statusClass,
            _vm.dirtyStateClass,
            _vm.noPaddingClass,
            _vm.roundedCornersClass,
            _vm.legBackgroundClass,
            _vm.editStatusClass,
            { "overflow-hidden": !_vm.legSize.xs && !_vm.legSize.sm }
          ],
          attrs: { id: "LEG-" + _vm.legData.id },
          on: { dblclick: _vm.openDetail, mousedown: _vm.onMouseDown }
        },
        [
          _c("FullLegViewPopover", {
            attrs: {
              legData: _vm.legData,
              legContent: _vm.legContent,
              legStatuses: _vm.legStatuses,
              legActionCallbacks: _vm.legActionCallbacks,
              onTouchStart: _vm.stopEventPropagation
            }
          }),
          _c("BrickSpinner", { attrs: { "is-active": _vm.legIsUpdating } }),
          _vm.legIsDirty
            ? _c("BrickActionBar", {
                attrs: { disabled: _vm.actionBarDisabled },
                on: {
                  updateBrick: _vm.updateLeg,
                  resetBrick: _vm.legResetHandler
                }
              })
            : _vm._e(),
          _vm.hasPreviousLeg
            ? _c("PreviousLegDetail", {
                staticClass: "overflow-hidden",
                attrs: {
                  show: _vm.showPreviousLegDetail,
                  data: _vm.previousLegDetails,
                  "has-irregularities":
                    _vm.isDepartedWithIntermodalIrregularities
                }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "leg__inner h-full overflow-hidden flex",
              class: [
                { "pt-0": _vm.isPrevLegViewActive },
                _vm.dynamicBreakpointPadding
              ]
            },
            [
              _vm.legSize.xl
                ? _c("TTALink", { attrs: { "leg-data": _vm.legData } })
                : _vm._e(),
              _c("LegAffiliationPopover", {
                attrs: {
                  legContent: _vm.legContent,
                  hasDifferentDriver: _vm.hasDifferentDriver,
                  hasDifferentTrailer: _vm.hasDifferentTrailer,
                  hasSubiWithPrimaryBoard: _vm.hasSubiWithPrimaryBoard,
                  onTouchStartHandler: _vm.stopEventPropagation,
                  legSize: _vm.legSize
                }
              }),
              _vm.legSize.lg || _vm.legSize.xl
                ? _c("LegBreakpointXL", {
                    attrs: {
                      legContent: _vm.legContent,
                      legStatuses: _vm.legStatuses,
                      legActionCallbacks: _vm.legActionCallbacks,
                      onTouchStart: _vm.stopEventPropagation,
                      showChatNotificationIcon: _vm.showChatNotificationIcon
                    }
                  })
                : _vm._e(),
              _vm.hasSymbols
                ? _c("div", {
                    staticClass: "indicator indicator--top indicator--black"
                  })
                : _vm._e(),
              _vm.legSize.md
                ? _c(
                    "div",
                    {
                      staticClass:
                        "ml-3 w-full flex flex-nowrap justify-between overflow-hidden whitespace-nowrap text-sm leading-4 font-semibold",
                      class: { "h-1/2": _vm.isPrevLegViewActive }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-base font-normal mr-2" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.hierarchyIndicator) + " ")
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.legContent.type) + "-")
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "leg_callout cursor-pointer border-b border-gray-400 hover:border-yellow-500",
                                  on: { click: _vm.notifyG11Bookings }
                                },
                                [_vm._v(_vm._s(_vm.legContent.orderNumberAlt))]
                              ),
                              _vm.transportStatuses.hasPendingIssues
                                ? _c(
                                    "span",
                                    { staticClass: "cursor-pointer ml-1" },
                                    [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: _vm.legIssues
                                          }
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "text-red-500",
                                            attrs: {
                                              name: "exclamation-triangle",
                                              height: "17",
                                              width: "17"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "cursor-pointer ml-1",
                                  on: {
                                    click:
                                      _vm.legActionCallbacks.g11PlanningCallback
                                  }
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "hover:text-btYellow",
                                    attrs: {
                                      name: "hyperlink",
                                      height: "17",
                                      width: "17"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.legContent.from
                            ? _c("div", [_vm._v(_vm._s(_vm.legContent.from))])
                            : _vm._e(),
                          !_vm.isPrevLegViewActive
                            ? [
                                _vm.legContent.unloading
                                  ? _c("div", [
                                      _vm._v(_vm._s(_vm.legContent.unloading))
                                    ])
                                  : _c("div", { staticClass: "invisible" }, [
                                      _vm._v("placeholder")
                                    ]),
                                _c(
                                  "div",
                                  {
                                    class: { "leg--textShort": _vm.legSize.md }
                                  },
                                  [
                                    _vm.legContent.product
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-normal capitalize",
                                            class: {
                                              "text-btRed":
                                                _vm.legContent.adrClass
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.legContent.product.toLowerCase()
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.unloadingTemperature
                                      ? _c(
                                          "span",
                                          { staticClass: "font-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.unloadingTemperature)
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-col items-end mr-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-row  pt-1" },
                            [
                              _c("LegTransportStatusIndicator", {
                                staticClass: "relative -top-0.5",
                                class: !_vm.isPrevLegViewActive
                                  ? "top-1.5"
                                  : null,
                                attrs: { status: _vm.transportStatuses }
                              }),
                              _vm.showChatNotificationIcon
                                ? _c("ChatNotifications", {
                                    staticClass: "relative -top-0.5",
                                    attrs: {
                                      content: _vm.legContent,
                                      iconWidth: "20",
                                      totalUnreadMessages:
                                        _vm.legContent.unreadMessages
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.legContent.loading
                            ? _c("div", [
                                _vm._v(
                                  " " + _vm._s(_vm.legContent.loading) + " "
                                )
                              ])
                            : _c("div", { staticClass: "invisible" }, [
                                _vm._v("placeholder")
                              ]),
                          !_vm.isPrevLegViewActive
                            ? [
                                _vm.legContent.to
                                  ? _c("div", [
                                      _vm._v(_vm._s(_vm.legContent.to))
                                    ])
                                  : _c("div", { staticClass: "invisible" }, [
                                      _vm._v("placeholder")
                                    ]),
                                _c("div", { staticClass: "font-normal" }, [
                                  _vm.showLoadingAndUnloadingTimeMd
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex flex-col items-end"
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                "L: " +
                                                  _vm.legContent.loadingTime
                                              )
                                            )
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                "U: " +
                                                  _vm.legContent.unloadingTime
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    : (_vm.legType.dropSwap ||
                                        _vm.legType.fullTransport) &&
                                      _vm.legContent.loadingTime
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "L: " + _vm.legContent.loadingTime
                                            ) +
                                            " "
                                        )
                                      ])
                                    : (_vm.legType.dropSwap ||
                                        _vm.legType.fullTransport) &&
                                      _vm.legContent.unloadingTime
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "U: " +
                                                _vm.legContent.unloadingTime
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.legContent.loadingTime ||
                                                _vm.legContent.unloadingTime
                                            ) +
                                            " "
                                        )
                                      ])
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e(),
              _vm.legSize.sm
                ? _c(
                    "div",
                    {
                      staticClass: "flex items-center w-full",
                      class: { "h-1/2": _vm.isPrevLegViewActive }
                    },
                    [
                      _vm.legSize.sm
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ml-3 w-full flex flex-col flex-nowrap overflow-hidden whitespace-nowrap text-sm leading-4 font-semibold"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex justify-between leading-4"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-base font-normal mr-2 w-full"
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.hierarchyIndicator) + " "
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.legContent.type) + "-"
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "leg_callout cursor-pointer border-b border-gray-400 hover:border-yellow-500",
                                          on: { click: _vm.notifyG11Bookings }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.legContent.orderNumberAlt
                                            )
                                          )
                                        ]
                                      ),
                                      _vm.transportStatuses.hasPendingIssues
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "cursor-pointer ml-1"
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                {
                                                  attrs: {
                                                    placement: "top",
                                                    title: _vm.legIssues
                                                  }
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticClass: "text-red-500",
                                                    attrs: {
                                                      name:
                                                        "exclamation-triangle",
                                                      height: "17",
                                                      width: "17"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-pointer ml-1",
                                          on: {
                                            click:
                                              _vm.legActionCallbacks
                                                .g11PlanningCallback
                                          }
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "hover:text-btYellow",
                                            attrs: {
                                              name: "hyperlink",
                                              height: "17",
                                              width: "17"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("LegTransportStatusIndicator", {
                                    staticClass: "relative",
                                    attrs: { status: _vm.transportStatuses }
                                  }),
                                  _vm.showChatNotificationIcon
                                    ? _c("ChatNotifications", {
                                        staticClass: "px-1",
                                        attrs: {
                                          content: _vm.legContent,
                                          iconWidth: "20",
                                          totalUnreadMessages:
                                            _vm.legContent.unreadMessages
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.legContent.from
                                ? _c("div", [
                                    _vm._v(_vm._s(_vm.legContent.from))
                                  ])
                                : _vm._e(),
                              !_vm.isPrevLegViewActive
                                ? [
                                    _vm.legContent.loading
                                      ? _c("div", [
                                          _vm._v(
                                            " " + _vm._s(_vm.legContent.loading)
                                          ),
                                          _vm.legContent.loadingTime
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    " / " +
                                                      _vm.legContent.loadingTime
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm.legContent.unloading
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.legContent.unloading)
                                          ),
                                          _vm.legContent.unloadingTime
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    " / " +
                                                      _vm.legContent
                                                        .unloadingTime
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm.legContent.to
                                      ? _c("div", [
                                          _vm._v(_vm._s(_vm.legContent.to))
                                        ])
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c("leg-step-progress-indicator", {
                staticClass: "absolute bottom-0 left-0",
                attrs: {
                  leg: _vm.legData,
                  "leg-statuses": _vm.legStatus,
                  "leg-boundaries": _vm.currentLegStartAndEndDate,
                  "current-leg-width": _vm.currentLegWidth,
                  "current-leg-x-coord": _vm.currentLegXCoord
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.legData.oldPlannedDate
        ? _c("ClosablePopover", {
            attrs: {
              placement: "right",
              defaultVisible: true,
              align: { offset: [_vm.hourBoxWidth, 0] }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "popoverContent",
                  fn: function() {
                    return [
                      _c("CalloutLegDrop", {
                        attrs: { plannedDate: _vm.legData.oldPlannedDate },
                        on: {
                          dropCancelled: _vm.legResetHandler,
                          dropAllowed: _vm.allowLegDrop
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1532400963
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }