<template>
  <a-popover
    v-model="isPopoverVisible"
    placement="rightTop"
    trigger="click"
    @click="getLastLocationData"
    :align="{ offset: [35, 0] }"
    :destroyTooltipOnHide="true"
  >
    <Icon name="not-listed-location" class="cursor-pointer p-1" width="28" height="28" />

    <template v-slot:content>
      <div v-if="loading" class="flex justify-center">
        <icon name="spinner" class="text-btYellow" />
      </div>
      <div v-else class="last-location-popover px-2">
        <div v-click-outside="onClickOutside"></div>

        <div class="w-full flex justify-end">
          <Icon
            name="close"
            class="cursor-pointer hover:text-yellow-500"
            width="28"
            height="28"
            @click="closePopover"
          />
        </div>
        <div v-if="lastLocationData">
          <div v-if="lastLocationData.length > 0">
            <div v-for="(data, index) in lastLocationData" :key="index" class="mb-6">
              <div class="font-semibold" :class="getEntityColorCls(data.entityType)">
                {{ data.entityName }}
              </div>
              <div
                class="w-full border-b-2 border-gray-300 mt-1 mb-2"
                :class="getEntityColorCls(data.entityType)"
              ></div>
              <div class="grid grid-cols-3 gap-4">
                <div>
                  <div class="font-light">{{ $t("fleet.lastLocation") }}</div>
                  <div v-if="data.name" class="font-semibold">{{ data.name }}</div>
                  <div v-else class="font-semibold">{{ $t("fleet.lastLocationEmpty") }}</div>
                </div>
                <div>
                  <div class="font-light">{{ $t("fleet.movementDate") }}</div>
                  <div class="font-semibold" style="word-spacing: 100px">{{ data.date }}</div>
                </div>
                <div>
                  <div class="font-light">{{ $t("fleet.lastMovement") }}</div>
                  <div v-if="data.isGeneric" class="text-gray-600 font-semibold">
                    {{ data.lastMovement }}
                  </div>
                  <div
                    v-else
                    class="underline text-gray-600 cursor-pointer font-semibold"
                    @click="() => openDetailWindow(data.brick, data.isLeg)"
                  >
                    {{ data.lastMovement }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="text-center w-full">
            {{ $t("fleet.lastLocationDataMissing") }}
          </div>
        </div>

        <div v-else class="text-center w-full">
          {{ $t("fleet.lastLocationDataMissing") }}
        </div>
      </div>
    </template>
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "@vue/composition-api";
import { i18n } from "@/i18n/i18n";
import { isEmpty } from "@/use/useObject";
import { formatDateString, isBeforeToday } from "@/use/useDate";
import { FleetAction } from "@/types/action";
import { Leg } from "@/types/leg";
import { spreadTruckId, spreadTrailerId } from "@/use/useFleet";
import { getLastLocationInfo } from "@/use/useLastLocation";
import { FleetEntity, FleetEntityType, LastBookingsFilter, LastLocationInfo, Trailer, Truck } from "@/types/fleet";
import Icon from "@/components/Icon.vue";
import fleetService from "@/services/fleetService";
import ClickOutside from "vue-click-outside";

export default defineComponent({
  name: "LastLocationAction",
  components: { Icon },
  directives: {
    ClickOutside
  },
  props: {
    fleetEntity: {
      type: Object as () => FleetEntity,
      required: true
    }
  },
  setup(props) {
    const isPopoverVisible = ref(false);
    const loading = ref<boolean>(false);
    const showSplitScreen: any = inject("showSplitScreen");
    type LastLocationEntry = {
      entityType: string;
      entityName: string;
      isGeneric: boolean;
      isLeg: boolean;
      brick?: Leg | FleetAction;
    } & LastLocationInfo;

    // ----------------------------------------------------------------

    const closePopover = () => {
      isPopoverVisible.value = false;
    };

    // ----------------------------------------------------------------

    const onClickOutside = (event): void => {
      const targetPopOverElement = event.target.closest(".ant-popover-content");
      const targetLegElement = event.target.closest(".brick");
      if (!targetPopOverElement && targetLegElement) {
        closePopover();
      }
    };

    // ----------------------------------------------------------------

    const createLastBookingsFilter = (entity: FleetEntity): LastBookingsFilter => {
      const before = new Date().toISOString();

      if (entity.type === FleetEntityType.TRUCK) {
        const { id, crewDriver } = entity as Truck;
        return {
          truckId: spreadTruckId(id).id,
          driverId: crewDriver?.id,
          before
        };
      }

      if (entity.type === FleetEntityType.DRIVER) {
        return { driverId: entity.id, before };
      }

      return { before };
    };

    // ----------------------------------------------------------------

    const lastLocationData = ref<LastLocationEntry[] | null>(null);

    const getLastLocationData = async () => {
      if (isPopoverVisible.value === true) return;

      loading.value = true;

      if (props.fleetEntity.type === FleetEntityType.TRAILER) {
        const trailer = props.fleetEntity as Trailer;
        const lastLocationInfo = getLastLocationInfo(trailer.metadata.lastLocation);
        if (!isEmpty(lastLocationInfo)) {
          lastLocationData.value = [
            {
              entityType: FleetEntityType.TRAILER,
              entityName: spreadTrailerId(trailer.id).id,
              isGeneric: trailer.metadata.lastLocation?.generic != null,
              isLeg: trailer.metadata.lastLocation?.leg != null,
              ...lastLocationInfo,
              date: formatDateString(lastLocationInfo.date) || "",
              brick:
                trailer.metadata.lastLocation?.generic == null
                  ? trailer.metadata.lastLocation?.leg || trailer.metadata.lastLocation?.action
                  : undefined
            }
          ];
        }
      } else {
        try {
          const lastBookingsFilter = createLastBookingsFilter(props.fleetEntity);
          const { data } = await fleetService.getLastBookings(lastBookingsFilter);
          const lastLocationEntries: LastLocationEntry[] = [];
          for (const entity in data) {
            const { leg, action } = data[entity];
            const hasLeg = leg != null;
            const brick = leg || action;
            const lastLocationInfo = getLastLocationInfo({
              leg: hasLeg ? (leg as Leg) : undefined,
              action: !hasLeg ? (action as FleetAction) : undefined
            });
            lastLocationEntries.push({
              entityType: entity,
              entityName: getEntityName(brick, entity),
              isGeneric: false,
              isLeg: hasLeg,
              ...lastLocationInfo,
              date: getLastMovementDate(leg || action, hasLeg) || "",
              brick: leg || action
            });
          }
          const { trailer } = props.fleetEntity as Truck;
          if (trailer?.id) {
            const lastLocationInfo = getLastLocationInfo(trailer.metadata.lastLocation);
            if (!isEmpty(lastLocationInfo)) {
              lastLocationEntries.push({
                entityType: FleetEntityType.TRAILER,
                entityName: spreadTrailerId(trailer.id).id,
                isGeneric: trailer.metadata.lastLocation?.generic != null,
                isLeg: trailer.metadata.lastLocation?.leg != null,
                ...lastLocationInfo,
                date: formatDateString(lastLocationInfo.date) || "",
                brick:
                  trailer.metadata.lastLocation?.generic == null
                    ? trailer.metadata.lastLocation?.leg || trailer.metadata.lastLocation?.action
                    : undefined
              });
            }
          }
          lastLocationEntries.sort((a, b) => {
            return getFleetEntityOrdinal(a.entityType) - getFleetEntityOrdinal(b.entityType);
          });

          lastLocationData.value = lastLocationEntries;
        } catch (e) {
          console.error(e);
        }
      }
      loading.value = false;
    };

    // ----------------------------------------------------------------

    const getEntityName = (brick: Leg | FleetAction, fleetType: string): string => {
      switch (fleetType) {
        case FleetEntityType.TRUCK:
          return brick.truckId || "";
        case FleetEntityType.TRAILER:
          return brick.trailerId || "";
        case FleetEntityType.DRIVER:
          return brick.driverId || "";
        default:
          return "";
      }
    };

    // ----------------------------------------------------------------

    const getEntityColorCls = (entityType: string) => {
      switch (entityType) {
        case FleetEntityType.TRUCK:
          return ["text-gray-900", "border-gray-900"];
        case FleetEntityType.TRAILER:
          return ["text-yellow-900", "border-yellow-900"];
        case FleetEntityType.DRIVER:
          return ["text-blue-400", "border-blue-400"];
      }
    };

    // ----------------------------------------------------------------

    const getLastLocation = (brick: Leg | FleetAction, isLeg: boolean) => {
      const fallbackText = i18n.t("fleet.lastLocationEmpty");

      if (isLeg) {
        const { effectiveArrivalDate, effectiveDepartureDate } = (brick as Leg)?.events || {};
        const { from, to } = (brick as Leg)?.stops || {};

        if (effectiveArrivalDate) {
          return to?.name || fallbackText;
        }
        if (effectiveDepartureDate) {
          return from?.name || fallbackText;
        }
      } else {
        const { toLocation, fromLocation, toDate } = (brick as FleetAction) || {};
        const actionBeforeToday = isBeforeToday(toDate?.date);

        if (toLocation?.name && actionBeforeToday) {
          return toLocation.name || fallbackText;
        }
        if (fromLocation?.name && !actionBeforeToday) {
          return fromLocation?.name || fallbackText;
        }
      }

      return fallbackText;
    };

    // ----------------------------------------------------------------

    const getLastMovementDate = (brick: Leg | FleetAction, isLeg: boolean) => {
      if (isLeg) {
        const { effectiveArrivalDate, effectiveDepartureDate } = (brick as Leg)?.events || {};

        if (effectiveArrivalDate) {
          return formatDateString(effectiveArrivalDate.date);
        }
        if (effectiveDepartureDate) {
          return formatDateString(effectiveDepartureDate?.date);
        }
      } else {
        const { fromDate, toDate } = (brick as FleetAction) || {};
        const actionBeforeToday = isBeforeToday(toDate?.date);

        if (actionBeforeToday) {
          return formatDateString(toDate?.date!);
        }
        if (!actionBeforeToday) {
          return formatDateString(fromDate?.date!);
        }
      }

      return "";
    };

    // ----------------------------------------------------------------

    const openDetailWindow = (brick: Leg | FleetAction, isLeg: boolean): void => {
      showSplitScreen({
        ...brick,
        payloadType: isLeg ? "leg" : "action"
      });
      closePopover();
    };

    // ----------------------------------------------------------------

    const isValidEntity = (entity: FleetEntity): boolean => {
      const validEntities = [FleetEntityType.TRUCK, FleetEntityType.DRIVER, FleetEntityType.TRAILER];
      return validEntities.includes(entity.type);
    };

    // ----------------------------------------------------------------

    const getFleetEntityOrdinal = (fleetEntityType: string): number => {
      switch (fleetEntityType) {
        case FleetEntityType.TRUCK.toLowerCase():
          return 1;
        case FleetEntityType.TRAILER.toLowerCase():
          return 2;
        case FleetEntityType.DRIVER.toLowerCase():
          return 3;
        default:
          return 100; // default fallback value
      }
    };

    // ----------------------------------------------------------------

    return {
      isPopoverVisible,
      closePopover,
      loading,
      isEmpty,
      isValidEntity,
      getLastLocation,
      onClickOutside,
      getLastLocationData,
      getLastMovementDate,
      lastLocationData,
      openDetailWindow,
      formatDateString,
      getEntityColorCls
    };
  }
});
</script>

<style lang="scss">
.last-location-popover {
  width: 500px;
}
</style>
