var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-28 w-full shadow flex fixed z-50 bg-white header" },
    [
      _c("router-link", { attrs: { to: { name: "Dashboard" } } }, [
        _c(
          "div",
          {
            staticClass:
              "h-full w-84 w- bg-btYellow flex relative justify-center items-center p-10"
          },
          [
            _c("Logo"),
            _c(
              "div",
              {
                staticClass: "absolute bottom-0 right-0 p-2 text-black text-sm"
              },
              [_vm._v("Version " + _vm._s(_vm.version))]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full flex justify-between" }, [
        _c("div", { staticClass: "w-1/3 flex items-center min-w-0" }, [
          _c("span", { staticClass: "float-left m-6 text-2xl header-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ]),
        _c(
          "div",
          { staticClass: "w-full flex justify-center items-center" },
          [
            _c("TimeLineZoom", { staticClass: "mr-4" }),
            _c("BoardDateSelector", { staticClass: "mr-4" }),
            _c("ShowPreviousLegDetail", { staticClass: "ml-4" }),
            _c("ShowBoardEditMode", { staticClass: "ml-4" })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-1/3 flex items-center justify-end" },
          [
            _c("div", { staticClass: "flex pr-2" }, [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("legsAtRisk")) + ": "),
                _c(
                  "span",
                  { class: { "text-red-500": _vm.totalLegsWithIssues > 0 } },
                  [_vm._v(_vm._s(_vm.totalLegsWithIssues))]
                )
              ])
            ]),
            _c("SearchBox", { staticClass: "mr-6" }),
            _c(
              "div",
              { staticClass: "flex flex-col h-full justify-center pr-2" },
              [
                _c("Nav", { staticClass: "mr-4 -mt-1" }),
                _c("ChatNotifications", {
                  staticClass: "mr-4 mt-2",
                  attrs: {
                    content: {},
                    totalUnreadMessages: _vm.totalUnreadMessages
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.chatHubActive
        ? _c("ChatHub", {
            attrs: { isActive: _vm.chatHubActive },
            on: { chatHubClosed: _vm.closeChatHub }
          })
        : _vm._e(),
      _vm.boardEditMode || _vm.fleetIsLoading
        ? _c("div", {
            staticClass:
              "h-28 w-full bg-black fixed top-0 left-0 z-30 opacity-20"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }