<template>
  <div :class="dynamicClass" class="border px-4 py-1 inline"><slot /></div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  setup(props) {
    const dynamicClass = computed(() => {
      return `tag--${props.variant}`;
    });
    return {
      dynamicClass
    };
  }
});
</script>

<style scoped lang="scss">
.tag--primary {
  @apply bg-gray-100 border-gray-600 text-gray-600;
}
.tag--success {
  @apply bg-green-100 border-green-600 text-green-600;
}
.tag--warning {
  @apply bg-yellow-100 border-yellow-600 text-yellow-600;
}
</style>
