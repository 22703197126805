<template>
  <div class="cursor-pointer flex justify-center relative ml-1" @click="openChatHub">
    <Icon
      class="hover:text-btYellow"
      name="chat"
      :width="iconWidth ? iconWidth : '25'"
      :height="iconWidth ? iconWidth : '25'"
    />
    <div
      v-if="totalUnreadMessages > 0"
      class=" px-1 text-sm text-white rounded-full text-center absolute -right-2 -bottom-2 notification"
    >
      {{ totalUnreadMessages }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import {
  IS_CHAT_HUB_ACTIVE,
  TOGGLE_CHAT_HUB_MODE,
  FETCH_MESSAGES_FOR_ACTIVE_CHAT,
  SET_IS_CHAT_ACTIVE
} from "@/store/chat";
import store from "@/store";

export default defineComponent({
  name: "ChatNotifications",
  components: { Icon },

  props: {
    content: {
      type: Object
    },
    totalUnreadMessages: {
      type: Number,
      required: false
    },
    iconWidth: {
      type: String,
      required: false
    }
  },

  setup(props) {
    const chatHubActive = computed(() => store.getters[IS_CHAT_HUB_ACTIVE]);

    const openChatHub = () => {
      if (props.content!.bookingId) {
        store.dispatch(FETCH_MESSAGES_FOR_ACTIVE_CHAT, props.content);
        store.commit(SET_IS_CHAT_ACTIVE, true);
        store.dispatch(TOGGLE_CHAT_HUB_MODE, true);
      } else if (props.content!.booking?.id) {
        store.dispatch(FETCH_MESSAGES_FOR_ACTIVE_CHAT, { ...props.content, bookingId: props.content!.booking.id });
        store.commit(SET_IS_CHAT_ACTIVE, true);
        store.dispatch(TOGGLE_CHAT_HUB_MODE, true);
      } else {
        store.dispatch(TOGGLE_CHAT_HUB_MODE, true);
      }
    };

    const closeChatHub = () => {
      store.dispatch(TOGGLE_CHAT_HUB_MODE, false);
    };

    return {
      closeChatHub,
      openChatHub,
      chatHubActive
    };
  }
});
</script>

<style scoped lang="scss">
.calloutLeg {
  &__table {
    th {
      @apply text-left font-light;
    }
    td {
      @apply font-semibold;
    }
  }
}
.notification {
  background-color: #dc2626;
}

.hover-notification {
  background-color: #ffc537;
}

.unread-messages-counter {
  min-width: 20px;
}
</style>
