










































































import { computed, defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import Button from "@/components/Buttons/Button.vue";
import Icon from "@/components/Icon.vue";
import fleetFormsDatePicker from "./FleetFormsDatePicker.vue";
import fleetFormsTimePicker from "./FleetFormsTimePicker.vue";
import { Preview } from "@/types/fleet";
import { FleetReminderFormData, FleetReminderFormDataInternal } from "@/types/action";
import fleetService from "@/services/fleetService";
import { setAsFirstIfMatching } from "@/use/useArray";
import moment from "moment";
import { parseISO } from "date-fns/fp";
import { hasValues } from "@/use/useObject";

export default defineComponent({
  name: "FleetReminderForm",
  components: {
    fleetFormsDatePicker,
    fleetFormsTimePicker,
    multiSelect,
    Button,
    Icon
  },
  props: {
    formData: {
      type: Object as () => FleetReminderFormData
    }
  },
  setup(props, { emit }) {
    const form = reactive<FleetReminderFormDataInternal>({
      type: undefined,
      remarks: undefined,
      date: undefined,
      time: undefined,
      location: undefined,
      hasData: computed(() => hasValues(form, ["hasData", "time"]))
    });

    const formIsValid = computed(() => {
      return form.hasData && form.type && form.date && form.time;
    });

    const locations = ref<Preview[]>([]);
    const formLocation = ref<Preview | null>(null);

    const getLocations = async () => {
      try {
        const { data } = await fleetService.getLocationsPreview();
        locations.value = data.previews;

        //if it is an update we need to fetch the right location and show it
        if (props.formData != null) {
          formLocation.value = locations.value.find(location => location.id === props.formData?.location?.id) || null;
        }
      } catch (e) {
        //show erros which are not handled globally
      }
    };

    const onLocationChange = async (location?: Preview | null) => {
      try {
        const { data } = await fleetService.getLocationById(location!.id!);
        form.location = data;
        locations.value = setAsFirstIfMatching(locations.value, data.id);
        emit("form-change", form);
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const initializeForm = () => {
      const { type, remarks, date, time, location } = props.formData || {};

      form.time = (time && moment(time)) || null;
      form.date = date ? parseISO(date) : null;

      if (props.formData == null || props.formData.type == null) {
        return;
      }

      form.type = type;
      form.remarks = remarks;
      form.date = parseISO(date || "");
      form.location = location;
    };

    watch(
      () => ({ ...form }),
      () => {
        emit("form-change", form);
        emit("form-is-valid", formIsValid.value);
      }
    );

    watch(
      () => props.formData,
      () => {
        initializeForm();
      }
    );

    onMounted(() => {
      getLocations();
      initializeForm();
    });

    const defaultTime = computed(() => {
      const { time } = props.formData || {};
      return time ? moment(time).format("HH:mm") : "00:00";
      //return time ? dateStringToShowTime(time) : "00:00";
    });

    return {
      defaultTime,
      form,
      formIsValid,
      formLocation,
      locations,
      onLocationChange
    };
  }
});
