<template>
  <div class="flex items-center">
    <div :class="statusClass" class="mr-4">
      <div v-if="selectedLeg.details.ttaLink" class="cursor-pointer">
        <a :href="selectedLeg.details.ttaLink" target="_blank">
          <Icon v-if="selectedLeg.details.flexible" name="truck-flexible" class="leg__truck" />
          <Icon v-if="!selectedLeg.details.flexible" name="truck" class="leg__truck" />
        </a>
      </div>
      <div v-else>
        <Icon v-if="selectedLeg.details.flexible" name="truck-flexible" class="leg__truck" />
        <Icon v-if="!selectedLeg.details.flexible" name="truck" class="leg__truck" />
      </div>
    </div>
    <a-tooltip placement="bottom" :align="{ offset: [0, 5] }" :title="$t('legSteps.eventRead')">
      <Icon v-if="eventIsRead" name="mobile-friendly" height="20" class="mr-4" />
    </a-tooltip>
    {{ hierarchyIndicator }} {{ legType.name }}-
    <span class="mr-2" v-if="selectedLeg.orderNumberAlt">
      <span
        class="cursor-pointer border-b border-gray-400 hover:border-yellow-500 inline-block"
        @click="notifyG11Bookings"
      >
        {{ selectedLeg.orderNumberAlt }}
      </span>
      /
    </span>
    <span class="mr-2" v-if="selectedLeg.stops.from.name"> {{ selectedLeg.stops.from.name }} / </span>
    <span
      class="mr-2"
      v-if="
        legType.delivery &&
          selectedLeg.stops.unloading.name !== selectedLeg.stops.from.name &&
          selectedLeg.stops.unloading.name !== selectedLeg.stops.to.name
      "
    >
      {{ selectedLeg.stops.unloading.name }} /
    </span>
    <span
      class="mr-2"
      v-else-if="
        legType.fullTransport ||
          (legType.collection &&
            selectedLeg.stops.loading.name !== selectedLeg.stops.from.name &&
            selectedLeg.stops.loading.name !== selectedLeg.stops.to.name)
      "
    >
      {{ selectedLeg.stops.loading.name }} /</span
    >
    <span
      class="mr-2"
      v-if="
        legType.fullTransport &&
          selectedLeg.stops.unloading.name !== selectedLeg.stops.from.name &&
          selectedLeg.stops.unloading.name !== selectedLeg.stops.to.name
      "
    >
      {{ selectedLeg.stops.unloading.name }} /</span
    >
    <span v-if="selectedLeg.stops.to.name"> {{ selectedLeg.stops.to.name }}</span>
    <span v-if="selectedLeg.details.neutral" class="text-lg ml-4 capitalize">{{
      selectedLeg.details.neutral.toLowerCase()
    }}</span>
    <span v-if="legIssues != ''" class="cursor-pointer ml-4 flex">
      <a-tooltip placement="bottom" :align="{ offset: [0, 5] }" :title="legIssues">
        <Icon name="exclamation-triangle" class="text-red-500" height="17" width="17" />
      </a-tooltip>
    </span>
    <span class="cursor-pointer ml-4 flex" @click="notifyG11Planning">
      <Icon name="hyperlink" class="hover:text-btYellow" height="17" width="17" />
    </span>
    <span class="cursor-pointer ml-4 flex" @click="$emit('close-dialog')">
      <ChatNotifications
        v-if="showChatNotificationIcon"
        :content="selectedLeg"
        iconWidth="20"
        :totalUnreadMessages="totalUnreadMessages"
        class="relative -top-0.5"
      />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import store from "@/store";
import { POST_G11_MESSAGE } from "@/store/board";
import { BookingState, LegG11Action } from "@/types/leg";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";
import { computed } from "@vue/composition-api";
import { useMessages } from "@/use/useMessages";

export default defineComponent({
  name: "LegDetailHeader",
  props: {
    selectedLeg: {
      type: Object
    },
    eventIsRead: {
      type: Boolean,
      default: false
    }
  },
  components: { Icon, ChatNotifications },
  setup(props) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legType, statusClass, hierarchyIndicator, legIssues } = useLegStatus(selectedLegRef);

    const notifyG11 = async (g11Action: LegG11Action) => {
      const { g11Actions } = selectedLegRef?.value || {};
      if (g11Actions) {
        const message = g11Actions[g11Action];
        if (message) {
          await store.dispatch(POST_G11_MESSAGE, message);
        }
      }
    };

    const totalUnreadMessages = computed(() => useMessages(props.selectedLeg?.booking?.id));

    const showChatNotificationIcon = computed(
      () => !!props.selectedLeg?.booking?.id && props.selectedLeg?.booking?.bookingState !== BookingState.PREPLANNED
    );

    const notifyG11Bookings = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_BOOKING_SCREEN);
    };

    const notifyG11Planning = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_PLANNING_SCREEN);
    };

    return {
      statusClass,
      legType,
      notifyG11Bookings,
      notifyG11Planning,
      hierarchyIndicator,
      totalUnreadMessages,
      showChatNotificationIcon,
      legIssues
    };
  }
});
</script>

<style scoped lang="scss">
@use "../../Leg";
</style>
