












import { computed } from "@vue/composition-api";
import { Driver, FleetEntity, FleetEntityType, ScheduledDriver, Trailer, Truck } from "@/types/fleet";
import { spreadTruckId } from "@/use/useFleet";
import CalloutSideBarTruck from "@/components/Board/CallOuts/CalloutSideBar/CalloutSideBarTruck/CalloutSideBarTruck.vue";
import CalloutSideBarTrailer from "@/components/Board/CallOuts/CalloutSideBar/CalloutSideBarTrailer/CalloutSideBarTrailer.vue";
import CalloutSideBarDriver from "@/components/Board/CallOuts/CalloutSideBar/CalloutSideBarDriver/CalloutSideBarDriver.vue";
import { sortBy } from "@/use/useObject";

export default {
  name: "CalloutSideBar.vue",
  components: { CalloutSideBarTruck, CalloutSideBarTrailer, CalloutSideBarDriver },

  props: {
    content: {
      type: Object as () => FleetEntity
    },
    type: {
      type: String
    }
  },
  setup(props) {
    const truck = computed<Truck | undefined>(
      () =>
        (props.type === FleetEntityType.TRUCK &&
          ({
            ...props.content,
            id: spreadTruckId(props.content.id).id
          } as Truck)) ||
        undefined
    );

    const driver = computed<Driver | undefined>(() => {
      const { type, content } = props;
      if (type === FleetEntityType.TRUCK) {
        return (content as Truck).crewDriver;
      }
      return type === FleetEntityType.DRIVER ? (content as Driver) : undefined;
    });
    const trailer = computed<Trailer | undefined>(() => {
      const { type, content } = props;
      if (type === FleetEntityType.TRUCK) {
        return (content as Truck).trailer;
      }
      return type === FleetEntityType.TRAILER ? (content as Trailer) : undefined;
    });

    const extractScheduledDrivers = (truck: Truck): ScheduledDriver[] => {
      const { crewSchedule } = truck;
      const scheduledDrivers = crewSchedule.reduce((acc, schedule) => {
        const { fromDate, toDate, startTime, endTime, driver } = schedule;

        if (acc.get(driver.id) === undefined) {
          acc.set(driver.id, {
            ...(driver as ScheduledDriver),
            shifts: []
          });
        }
        acc.set(driver.id, {
          ...(acc.get(driver.id) as ScheduledDriver),
          shifts: [
            ...acc.get(driver.id)!.shifts,
            {
              fromDate,
              toDate,
              startTime,
              endTime
            }
          ]
        });
        return acc;
      }, new Map<string, ScheduledDriver>());

      return Array.from(scheduledDrivers.values()).sort(sortBy("firstName"));
    };

    const drivers = computed<ScheduledDriver[]>(() => {
      const { type, content } = props;
      if (type === FleetEntityType.TRUCK) {
        return extractScheduledDrivers(content as Truck);
      }

      if (type !== FleetEntityType.DRIVER) {
        return [];
      }

      const { trucksSchedule } = content as Driver;

      if (trucksSchedule.length === 0) {
        return [
          {
            ...content,
            shifts: []
          }
        ];
      }

      const { fromDate, toDate, startTime, endTime } = trucksSchedule.sort(sortBy("fromDate"))[0];

      return [
        {
          ...content,
          shifts: [
            {
              fromDate,
              toDate,
              startTime,
              endTime
            }
          ]
        }
      ];
    });

    return {
      truck,
      driver,
      trailer,
      drivers,
      FleetEntityType
    };
  }
};
