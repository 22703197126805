var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      attrs: {
        placement: "rightTop",
        trigger: "click",
        align: { offset: [35, 0] },
        destroyTooltipOnHide: true
      },
      on: { click: _vm.getLastLocationData },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function() {
            return [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "flex justify-center" },
                    [
                      _c("icon", {
                        staticClass: "text-btYellow",
                        attrs: { name: "spinner" }
                      })
                    ],
                    1
                  )
                : _c("div", { staticClass: "last-location-popover px-2" }, [
                    _c("div", {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.onClickOutside,
                          expression: "onClickOutside"
                        }
                      ]
                    }),
                    _c(
                      "div",
                      { staticClass: "w-full flex justify-end" },
                      [
                        _c("Icon", {
                          staticClass: "cursor-pointer hover:text-yellow-500",
                          attrs: { name: "close", width: "28", height: "28" },
                          on: { click: _vm.closePopover }
                        })
                      ],
                      1
                    ),
                    _vm.lastLocationData
                      ? _c("div", [
                          _vm.lastLocationData.length > 0
                            ? _c(
                                "div",
                                _vm._l(_vm.lastLocationData, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "mb-6" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "font-semibold",
                                          class: _vm.getEntityColorCls(
                                            data.entityType
                                          )
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.entityName) + " "
                                          )
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "w-full border-b-2 border-gray-300 mt-1 mb-2",
                                        class: _vm.getEntityColorCls(
                                          data.entityType
                                        )
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid grid-cols-3 gap-4"
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-light" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("fleet.lastLocation")
                                                  )
                                                )
                                              ]
                                            ),
                                            data.name
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "font-semibold"
                                                  },
                                                  [_vm._v(_vm._s(data.name))]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "font-semibold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "fleet.lastLocationEmpty"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                          ]),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-light" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("fleet.movementDate")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "font-semibold",
                                                staticStyle: {
                                                  "word-spacing": "100px"
                                                }
                                              },
                                              [_vm._v(_vm._s(data.date))]
                                            )
                                          ]),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-light" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("fleet.lastMovement")
                                                  )
                                                )
                                              ]
                                            ),
                                            data.isGeneric
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-gray-600 font-semibold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.lastMovement
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "underline text-gray-600 cursor-pointer font-semibold",
                                                    on: {
                                                      click: function() {
                                                        return _vm.openDetailWindow(
                                                          data.brick,
                                                          data.isLeg
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.lastMovement
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("div", { staticClass: "text-center w-full" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("fleet.lastLocationDataMissing")
                                    ) +
                                    " "
                                )
                              ])
                        ])
                      : _c("div", { staticClass: "text-center w-full" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fleet.lastLocationDataMissing")) +
                              " "
                          )
                        ])
                  ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isPopoverVisible,
        callback: function($$v) {
          _vm.isPopoverVisible = $$v
        },
        expression: "isPopoverVisible"
      }
    },
    [
      _c("Icon", {
        staticClass: "cursor-pointer p-1",
        attrs: { name: "not-listed-location", width: "28", height: "28" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }