/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2.006 2.006 0 002-2V7zm-5 16a3 3 0 113-3 3 3 0 01-3 3zm3-10H5V5h10z"/>'
  }
})
