<template>
  <div>
    <Dialog
      v-if="showDialog"
      :title="$t('timeSelector.selectTime')"
      :isActive="isActive"
      :disable-scroll="true"
      @closeDialog="closeTimeSelector()"
    >
      <time-selector-input @isLoading="isLoadingHandler" />
    </Dialog>
    <div v-else>
      <time-selector-input @isLoading="isLoadingHandler" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Dialog from "@/components/Dialog.vue";
import TimeSelectorInput from "@/components/Board/TimeSelector/TimeSelectorInput/TimeSelectorInput.vue";
import Button from "@/components/Buttons/Button.vue";

export default defineComponent({
  name: "TimeSelectorDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: true
    }
  },
  components: { Dialog, Button, TimeSelectorInput },
  setup(props, { emit }) {
    const isActive = ref<boolean>(false);
    const closeTimeSelector = () => {
      isActive.value = false;
      emit("closeHandler");
    };

    const isLoadingHandler = (value: boolean) => {
      emit("isLoading", value);
    };

    onMounted(() => {
      isActive.value = true;
    });

    return {
      isActive,
      closeTimeSelector,
      isLoadingHandler
    };
  }
});
</script>

<style></style>
