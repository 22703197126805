var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table-fixed inspections__table" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s("" + _vm.$t("fleetTests.nextTest")))])
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.truckInspections, function(inspection, index) {
          return _c("tr", { key: index }, [
            _c("td", [_vm._v(_vm._s(inspection.name) + ":")]),
            _c("td", { class: { "text-btRed": inspection.isDateInPast } }, [
              _vm._v(_vm._s(inspection.date))
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }