var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        {
          staticClass:
            "loadingScreen w-full fixed z-50 top-0 flex justify-center items-center"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "loadingIndicator bg-btYellow flex flex-col justify-center items-center p-6 shadow-2xl"
            },
            [
              _c("Logo", { staticClass: "block" }),
              _c(
                "div",
                { staticClass: "pt-6" },
                [
                  _c("a-spin", {
                    staticClass: "text-white mr-6",
                    attrs: { size: "large" }
                  }),
                  _c("span", { staticClass: "text-xl" }, [
                    _vm._v(_vm._s(_vm.$t("states.loading")) + "...")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }