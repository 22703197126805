/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.99 2A10 10 0 1022 12 10 10 0 0011.99 2zM12 20a8 8 0 118-8 8 8 0 01-8 8z" class="cls-2"/><path pid="1" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" class="cls-2"/>'
  }
})
