var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-grow flex py-1 box-border h-full relative" },
    [
      _c(
        "div",
        { staticClass: "flex-grow flex pb-3 px-1" },
        [
          _c("a-textarea", {
            staticClass: "rounded resize-none overflow-y-auto",
            attrs: {
              defaultValue: _vm.driverComment,
              readOnly: !_vm.driverCommentEditState
            },
            on: { focus: _vm.onFocus, blur: _vm.blur },
            model: {
              value: _vm.driverComment,
              callback: function($$v) {
                _vm.driverComment = $$v
              },
              expression: "driverComment"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "driverCommentActions",
          staticClass: "absolute right-1 bottom-1 h-6 flex",
          attrs: { id: "driverCommentActions", tabindex: "0" }
        },
        [
          !_vm.driverCommentEditState
            ? _c(
                "button",
                {
                  staticClass:
                    "rounded bg-btYellow hover:bg-yellow-500 h-6 w-6",
                  on: { click: _vm.toggleEdit }
                },
                [
                  _c("Icon", {
                    attrs: { name: "edit", height: "1.25rem", width: "1.25rem" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.driverCommentEditState
            ? _c(
                "button",
                {
                  staticClass: "bg-btYellow hover:bg-yellow-500 h-6 w-6",
                  staticStyle: { "border-radius": "0.25rem 0 0 0.25rem" },
                  on: { click: _vm.revertComment }
                },
                [
                  _c("Icon", {
                    attrs: { name: "undo", height: "1.25rem", width: "1.25rem" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.driverCommentEditState
            ? _c(
                "button",
                {
                  staticClass: "bg-btYellow hover:bg-yellow-500 h-6 w-6",
                  staticStyle: { "border-radius": "0 0.25rem 0.25rem 0" },
                  on: { click: _vm.saveComment }
                },
                [
                  _c("Icon", {
                    attrs: { name: "save", height: "1.25rem", width: "1.25rem" }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }