

































import { defineComponent } from "@vue/composition-api";
import { dateStringToShowDate, dateStringToShowTime, dateStringToShowDateLocal, getLocalTimezone } from "@/use/useDate";

export default defineComponent({
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const localTimezone = getLocalTimezone();
    return {
      dateStringToShowDate,
      dateStringToShowTime,
      dateStringToShowDateLocal,
      getLocalTimezone,
      localTimezone
    };
  }
});
