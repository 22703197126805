import { isBetween } from "./useNumbers";

/**
 * Case: input is 1 digit (0-9)
 * Assemble time string from input, like:
 * "0" --> "00:00", ..., "9" --> "09:00"
 */
const formatOneDigitToTimeString = (input: string, fallbackTime: string): string => {
  if (/^\d$/.test(input)) {
    return `0${input}:00`;
  }
  return fallbackTime;
};

/**
 * Case: input is 2 digits (00-23)
 * Assemble time string from input, like:
 * "00" --> "00:00", ..., "23" --> "23:00"
 */
const formatTwoDigitsToTimeString = (input: string, fallbackTime: string): string => {
  if (/^\d{2}$/.test(input) && isBetween(parseInt(input), 0, 24)) {
    return `${input}:00`;
  }
  return fallbackTime;
};

/**
 * Case: input is 4 digits (0000-2359)
 * Assemble time string from input, like:
 * "0000" --> "00:00", ..., "2359" --> "23:59"
 */
const formatFourDigitsToTimeString = (input: string, fallbackTime: string): string => {
  if (
    /^\d{4}$/.test(input) &&
    isBetween(parseInt(input.substr(0, 2)), 0, 24) &&
    isBetween(parseInt(input.substr(2, 2)), 0, 60)
  ) {
    return `${input.substr(0, 2)}:${input.substr(2, 2)}`;
  }
  return fallbackTime;
};

/**
 * Case: input is 2 digits (00-23) + colon (:) + 2 digits (00-59)
 * E.g. "00:00", "12:50", "23:49", etc (no transformations needed)
 */
const returnInputIfValidTimeString = (input: string, fallbackTime: string): string => {
  if (
    /^\d{2}:\d{2}$/.test(input) &&
    isBetween(parseInt(input.substr(0, 2)), 0, 24) &&
    isBetween(parseInt(input.substr(3, 2)), 0, 60)
  ) {
    return input;
  }
  return fallbackTime;
};

const formatTimeString = (input: string, fallbackTime: string): string => {
  switch (input.length) {
    case 1:
      return formatOneDigitToTimeString(input, fallbackTime);
    case 2:
      return formatTwoDigitsToTimeString(input, fallbackTime);
    case 4:
      return formatFourDigitsToTimeString(input, fallbackTime);
    case 5:
      return returnInputIfValidTimeString(input, fallbackTime);
    default:
      return fallbackTime;
  }
};

export { formatTimeString };
