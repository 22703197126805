






























































































































































































































import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from "@vue/composition-api";
import Card from "@/components/Card.vue";
import Icon from "@/components/Icon.vue";
import CalloutSideBar from "@/components/Board/CallOuts/CalloutSideBar/CalloutSideBar.vue";
import SidebarActionCallout from "@/components/Board/CallOuts/CalloutSidebarAction/CalloutSidebarAction.vue";
import CalloutSidebarActionDetail from "@/components/Board/CallOuts/CalloutSidebarAction/CalloutSidebarActionDetail.vue";
import LastLocationAction from "@/components/Board/CallOuts/CalloutSideBarLastLocation/LastLocation.vue";
import store from "@/store";
import { GET_FLEET_SPANS } from "@/store/fleet";
import { Driver, FleetEntityType, Subcontractor, Trailer, Truck, FleetEntityDescription } from "@/types/fleet";
import { isAfter } from "date-fns";
import { Carousel } from "ant-design-vue";
import CardDriverRemarks from "@/components/Board/SideBar/SideBarCard/SideBarCardDriverRemarks/SideBarCardDriverRemarks.vue";
import ClosablePopover from "@/components/ClosablePopover.vue";
import { spreadTruckId, spreadTrailerId } from "@/use/useFleet";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import { buildSearchId } from "@/store/board/useSearchIndex";
import { BoardEntityType } from "@/types/board";
import { getLastLocationDropWithTrailer, getLastLocationContainerId, getLastLocationInfo } from "@/use/useLastLocation";

export default defineComponent({
  name: "BoardSideBarCard",
  components: {
    Card,
    Icon,
    CalloutSideBar,
    LastLocationAction,
    SidebarActionCallout,
    CalloutSidebarActionDetail,
    Carousel,
    CardDriverRemarks,
    ClosablePopover,
    CalloutLegContainer
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    type: {
      type: String
    },
    isDraggable: {
      type: Boolean
    },
    isGroupCard: {
      type: Boolean
    },
    onlyRenderLeadingTitle: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const isMenuVisible = ref(false);
    const closePopover = (): void => {
      isMenuVisible.value = false;
    };

    const showSplitScreen = inject("showSplitScreen");
    const isTrailerCard = computed<boolean>(() => props.type === FleetEntityType.TRAILER);
    const isTruckCard = computed<boolean>(() => props.type === FleetEntityType.TRUCK);
    const isSubcontractorCard = computed<boolean>(() => props.type === FleetEntityType.SUBCONTRACTOR);
    const isDriverCard = computed<boolean>(() => props.type === FleetEntityType.DRIVER);
    const searchId = computed(() => buildSearchId(props.content!.id, BoardEntityType.FLEET));
    const COMPRESSORS: (string | undefined)[] = ["BRAETSCH", "MOUVEX B200"];
    const hasDriverSubcontractor = computed<boolean>(
      () => driver.value != undefined && driver.value.subcontractorId != undefined
    );
    const hasSubcontractorPrimaryBoard = computed<boolean>(() => subcontractor.value?.primaryBoardId != undefined);

    const driver = computed<Driver | undefined>(() => {
      const { content } = props;
      if (isTruckCard.value) {
        return (content as Truck).crewDriver;
      }
      return isDriverCard.value ? (content as Driver) : undefined;
    });

    const trailer = computed<Trailer | undefined>(() => {
      const { content } = props;
      if (isTruckCard.value) {
        return (content as Truck).trailer;
      }
      return isTrailerCard.value ? (content as Trailer) : undefined;
    });

    const subcontractor = computed<Subcontractor | undefined>(() => {
      const { content } = props;
      return isSubcontractorCard.value ? (content as Subcontractor) : undefined;
    });

    const fleetSpans = computed(() => store.getters[GET_FLEET_SPANS]);

    const cardStyle = computed(() => ({
      height: `${fleetSpans.value[props.content?.id] || 100}px`
    }));

    const onAffiliateAction = fleetMember => {
      emit("showFleetDetail", fleetMember);
    };

    const isSingleTrailer = trailer => {
      return trailer.type;
    };

    const hasNoAdr = computed<boolean>(() => {
      if ((!isTrailerCard.value && !isTruckCard.value) || driver.value?.subcontractorId) {
        return false;
      }
      const { nextADRTest } = props.content.metadata;
      if (nextADRTest) {
        const currentDate = new Date();
        const nextTestDate = new Date(nextADRTest);
        return isAfter(currentDate, nextTestDate);
      }
      return true;
    });

    const hasCompressor = computed<boolean>(() => {
      const { content } = props;
      if (!isTruckCard.value) {
        return false;
      }
      const truck = content as Truck;
      return COMPRESSORS.includes(truck.metadata.compressor?.toUpperCase());
    });

    const hasAdditionalCooler = computed<boolean>(() => {
      return !!trailer.value?.metadata.additionalCooler;
    });

    const hasDropWithTrailer = computed<boolean>(() => {
      return getLastLocationDropWithTrailer(trailer.value?.metadata.lastLocation) && isSingleTrailer(trailer.value);
    });

    const truckDisplayId = computed<string | undefined>(() => {
      const { content } = props;
      return (isTruckCard.value && spreadTruckId(content.id).id) || undefined;
    });

    const cardCarouselRef = ref<any>(null);

    const addCarouselRef: any = inject("addCarouselRef");
    const removeCarouselRef: any = inject("removeCarouselRef");

    onMounted(() => {
      addCarouselRef(props.content.id, cardCarouselRef);
    });

    onUnmounted(() => {
      removeCarouselRef(props.content.id);
    });

    const cardDescription = computed<FleetEntityDescription | undefined>(() => {
      if (isTruckCard.value) {
        return { entity: FleetEntityType.TRUCK, name: props.content.id };
      }
      if (isSubcontractorCard.value) {
        return { entity: FleetEntityType.SUBCONTRACTOR, name: props.content.name };
      }
      if (isTrailerCard.value) {
        return { entity: FleetEntityType.TRAILER, name: props.content.id };
      }
      if (isDriverCard.value) {
        return { entity: FleetEntityType.DRIVER, name: `${props.content.firstName} ${props.content.lastName}` };
      }
    });

    const firstNameRef = ref<HTMLSpanElement | null>(null);
    const lastNameRef = ref<HTMLSpanElement | null>(null);
    const isNameTruncated = computed<boolean>(() => {
      if (firstNameRef.value != null && lastNameRef.value != null) {
        return (
          firstNameRef.value.offsetWidth < firstNameRef.value.scrollWidth ||
          lastNameRef.value.offsetWidth < lastNameRef.value.scrollWidth
        );
      }
      return false;
    });

    const entityCallableOffset = computed<number>(() => {
      const defaultOffset = -235;
      const charWidth = 9;
      const staticOffset = -190;
      if (isTruckCard.value) {
        return truckDisplayId.value != null ? truckDisplayId.value.length * charWidth + defaultOffset : defaultOffset;
      }
      if (isTrailerCard.value || isDriverCard.value) {
        return staticOffset;
      }
      if (isSubcontractorCard.value) {
        return subcontractor.value ? subcontractor.value.name.length * charWidth + defaultOffset : defaultOffset;
      }

      return defaultOffset;
    });

    return {
      firstNameRef,
      lastNameRef,
      isNameTruncated,
      cardDescription,
      closePopover,
      driver,
      trailer,
      subcontractor,
      cardStyle,
      onAffiliateAction,
      showSplitScreen,
      hasNoAdr,
      hasCompressor,
      hasAdditionalCooler,
      isTrailerCard,
      isTruckCard,
      isSubcontractorCard,
      isDriverCard,
      searchId,
      isMenuVisible,
      hasDriverSubcontractor,
      hasSubcontractorPrimaryBoard,
      cardCarouselRef,
      truckDisplayId,
      hasDropWithTrailer,
      entityCallableOffset,
      spreadTrailerId,
      getLastLocationContainerId,
      getLastLocationInfo
    };
  }
});
