/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trailerdrop': {
    width: 25,
    height: 20,
    viewBox: '0 0 25 20',
    data: '<path pid="0" _fill="none" _stroke="#f59e0b" stroke-width="3" stroke-miterlimit="10" d="M22.656 12.363c0 .074-.041.137-.09.137H2.454c-.05 0-.089-.063-.089-.137V2.294c0-.077.04-.138.089-.138h20.112c.05 0 .091.061.091.138l-.001 10.069h0z"/><ellipse pid="1" cx="4.94" cy="16.885" rx="2.202" ry="2.172"/><ellipse pid="2" cx="10.94" cy="16.885" rx="2.202" ry="2.172"/><path pid="3" _fill="none" _stroke="#000" stroke-width="2" stroke-miterlimit="10" d="M20.602 14.172v5.109M23.119 18.33h-5.181"/>'
  }
})
