<template>
  <button @click="logOut" @keydown.enter="logOut">
    <div class="flex items-center cursor-pointer ">
      <Icon name="logout" height="18" width="18" class="mr-1" />
      <span>Logout</span>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import { LOG_OUT } from "@/store/auth";

export default defineComponent({
  name: "LogoutButton",
  components: { Icon },
  setup(props, { root }) {
    function logOut() {
      root.$store.dispatch(LOG_OUT);
      root.$router.push({ name: "Login" });
    }
    return {
      logOut
    };
  }
});
</script>

<style scoped></style>
