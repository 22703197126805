<template>
  <div>
    <a-collapse :default-active-key="activePanelKey" :bordered="false" class="bg-gray-100 ant-collapse--single">
      <a-collapse-panel
        :key="checklistPanelKey"
        :header="$t('checkList.title')"
        class="legDetailChecklist__statusDropdown text-xl"
      >
        <div class="text-base mt-2">
          <div v-for="textCategory in Object.keys(legDetails)" :key="textCategory">
            <div v-if="textCategory !== 'status'">
              <div v-if="legDetails[textCategory] && legDetails[textCategory].length" class="flex">
                <div class="w-1/3">
                  <a-tooltip
                    placement="top"
                    :title="$t(`checkList.${(allChecked[textCategory] && 'checkNone') || 'checkAll'}`)"
                  >
                    <a-checkbox :checked="allChecked[textCategory]" @change="onCheckAllChanged(textCategory)" />
                  </a-tooltip>
                  <span class="text-gray-400 font-bold ml-2">{{ $t(`checkList.${textCategory}`) }}</span>
                </div>
                <div class="w-full grid grid-cols-4 mb-3">
                  <div
                    v-for="(textInfo, index) in legDetails[textCategory]"
                    :key="index"
                    @change="onChecklistChanged(textInfo.text, textCategory)"
                  >
                    <a-checkbox class="uppercase mr-2" :checked="textInfo.checked">
                      {{ textInfo.text }}
                    </a-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex mt-4">
            <div class="w-1/3 text-gray-400 font-bold">
              <span class="ml-7">{{ $t("checkList.status") }}</span>
            </div>
            <div class="flex flex-wrap w-full">
              <div>
                <multiSelect
                  v-model="statusOptionsValue"
                  :multiple="false"
                  :options="statusOptions"
                  :hideSelected="true"
                  track-by="identifier"
                  label="label"
                  @input="onStatusChanged"
                  :selectLabel="$t('actions.select')"
                  :deselectLabel="$t('actions.remove')"
                >
                  <template slot="option" slot-scope="{ option }">
                    <div
                      :class="
                        `legDetailChecklist__statusBox legDetailChecklist__statusBox--${option.identifier.toLowerCase()}`
                      "
                    ></div>
                    {{ option.label }}
                  </template>
                </multiSelect>
              </div>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script lang="ts">
import { i18n } from "@/i18n/i18n";
import { BoardLeg, LegTransportChecklist, LegTransportDetail, LegTransportStatus } from "@/types/leg";
import { computed, defineComponent, PropType, reactive, ref } from "@vue/composition-api";
import multiSelect from "vue-multiselect";

interface StatusObject {
  identifier: string;
  label: string;
}

export default defineComponent({
  components: {
    multiSelect
  },
  props: {
    leg: {
      type: Object as PropType<BoardLeg>
    }
  },
  setup(props, { emit }) {
    const checklistPanelKey = "checklist-panel";
    const collapsedPanelKey = "collapsed-panel";

    const areAllChecked = (details?: LegTransportDetail[]): boolean => {
      return (details || []).every(detail => detail.checked);
    };

    const allChecked = reactive({
      documents: areAllChecked(props.leg?.transportInfo?.documents),
      customs: areAllChecked(props.leg?.transportInfo?.customs),
      specialCustoms: areAllChecked(props.leg?.transportInfo?.specialCustoms),
      specialEquipments: areAllChecked(props.leg?.transportInfo?.specialEquipments),
      temperatures: areAllChecked(props.leg?.transportInfo?.temperatures)
    });

    const legDetails = reactive<LegTransportChecklist>({
      status: props.leg?.status,
      documents: props.leg?.transportInfo?.documents || [],
      customs: props.leg?.transportInfo?.customs || [],
      specialCustoms: props.leg?.transportInfo?.specialCustoms || [],
      specialEquipments: props.leg?.transportInfo?.specialEquipments || [],
      temperatures: props.leg?.transportInfo?.temperatures || []
    });

    const onCheckAllChanged = (textCategory: string) => {
      allChecked[textCategory] = !allChecked[textCategory];
      legDetails[textCategory] = legDetails[textCategory].map(detailText => ({
        ...detailText,
        checked: allChecked[textCategory]
      }));
      emit("detailChanged", legDetails);
    };

    const onChecklistChanged = (text: string, textCategory: string) => {
      let allCheckedInCategory = true;
      legDetails[textCategory] = legDetails[textCategory].map(detailText => {
        const checked = detailText.checked;
        if (detailText.text === text) {
          allCheckedInCategory = allCheckedInCategory && !checked;
          return {
            text: detailText.text,
            checked: !checked
          };
        } else {
          allCheckedInCategory = allCheckedInCategory && checked;
        }
        return detailText;
      });
      allChecked[textCategory] = allCheckedInCategory;
      emit("detailChanged", legDetails);
    };

    const buildStatusObject = (status: string): StatusObject => {
      return {
        identifier: status,
        label: i18n.t(`transportInfoEditor.values.${status.toLowerCase()}`).toString()
      };
    };

    const statusOptions = ref<Array<StatusObject>>(Object.values(LegTransportStatus).map(buildStatusObject));

    const statusOptionsValue = ref<StatusObject>(buildStatusObject(legDetails.status || LegTransportStatus.NO_STATUS));

    const onStatusChanged = () => {
      legDetails.status =
        statusOptionsValue.value.identifier === LegTransportStatus.NO_STATUS
          ? undefined
          : statusOptionsValue.value.identifier;
      emit("detailChanged", legDetails);
    };

    const checklistShouldExpand = computed(() => {
      if (props.leg == null) {
        return false;
      }
      const { status, transportInfo } = props.leg;

      if (status) {
        return true;
      }

      return (
        transportInfo.documents?.some(item => !item.checked) ||
        transportInfo.customs?.some(item => !item.checked) ||
        transportInfo.specialCustoms?.some(item => !item.checked) ||
        transportInfo.specialEquipments?.some(item => !item.checked) ||
        transportInfo.temperatures?.some(item => !item.checked)
      );
    });

    const activePanelKey = computed(
      (): string => (checklistShouldExpand.value && checklistPanelKey) || collapsedPanelKey
    );

    return {
      legDetails,
      activePanelKey,
      checklistPanelKey,
      collapsedPanelKey,
      allChecked,
      onStatusChanged,
      onChecklistChanged,
      onCheckAllChanged,
      statusOptions,
      statusOptionsValue
    };
  }
});
</script>

<style lang="scss">
@use "LegDetailChecklist";
</style>
