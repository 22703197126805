var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-switch", {
        staticClass: "mr-2",
        attrs: { size: "large", checked: _vm.showWeekend },
        on: { change: _vm.onWeekendToggleChange }
      }),
      _c("span", { staticClass: "text-base" }, [
        _vm._v(_vm._s(_vm.$t("showWeekend")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }