import { BoardDetails, BoardTimespan } from "@/types/board";
import { brickFitsTimespan } from "@/store/brick/useBrick";

const timespanFitsBoard = (timespan: BoardTimespan, boardDetails: BoardDetails, includeWeekends: boolean) => {
  const { boardFromDate, boardFromTime, boardToDate, boardToTime } = boardDetails;
  const { from, to } = timespan;
  return brickFitsTimespan(boardFromDate, boardFromTime, boardToDate, boardToTime, includeWeekends, from, to);
};

export { timespanFitsBoard };
