var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isActive
        ? _c("SplitScreen", {
            attrs: {
              isActive: _vm.isActive,
              isLoading: _vm.isLoading,
              "custom-class": "w-1/2"
            },
            on: { closeHandler: _vm.closeLegDetail },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("LegDetailHeader", {
                        attrs: {
                          "selected-leg": _vm.selectedLeg,
                          eventIsRead: _vm.eventIsRead
                        },
                        on: { "close-dialog": _vm.closeLegDetail }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("LegDetailMultiSelectFleet", {
                        attrs: {
                          "selected-leg": _vm.selectedLeg,
                          "is-locked": _vm.legIsLocked
                        },
                        on: {
                          multiSelectChange: _vm.updateLegForm,
                          clear: _vm.clearFleet
                        }
                      }),
                      !_vm.legStatus.dropped
                        ? _c("LegDetailPlannedDates", {
                            attrs: {
                              status: _vm.legStatus,
                              "planned-dates": _vm.plannedDates
                            },
                            on: { plannedDatesChange: _vm.updatePlannedDates }
                          })
                        : _vm._e(),
                      _c("LegDetailCheckList", {
                        staticClass: "my-4",
                        attrs: { leg: _vm.formData },
                        on: { detailChanged: _vm.onChecklistChanged }
                      }),
                      _c("div", { staticClass: "bg-gray-100 my-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-12 flex items-center border-b border-gray-200"
                          },
                          [
                            _c("div", { staticClass: "px-4 text-xl" }, [
                              _vm.selectedLeg.container
                                ? _c("div", [
                                    _vm._v(_vm._s(_vm.selectedLeg.container.id))
                                  ])
                                : _vm.selectedLeg.details.containerSize ||
                                  _vm.selectedLeg.details.tankBoxDesign ||
                                  _vm.selectedLeg.details.highCube
                                ? _c("div", [
                                    _vm.selectedLeg.details.containerSize
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.details
                                                .containerSize
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.details.tankBoxDesign ||
                                    _vm.selectedLeg.details.highCube
                                      ? _c("span", [_vm._v(" / ")])
                                      : _vm._e(),
                                    _vm.selectedLeg.details.tankBoxDesign
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.details
                                                .tankBoxDesign
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.details.highCube
                                      ? _c("span", [_vm._v(" / ")])
                                      : _vm._e(),
                                    _vm.selectedLeg.details.highCube
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.details.highCube
                                            )
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("div", [_vm._v("Container Section")])
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "legDetail__table p-4" }, [
                          _c(
                            "table",
                            { staticClass: "table-fixed w-full mb-8" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(_vm._s(_vm.$t("container.product")))
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("container.dgInformation")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("container.tempRequire")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("container.fillingDegree")
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm.selectedLeg.product.name
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.selectedLeg.product.name)
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("td", [
                                    _vm.selectedLeg.product.metadata.adrClass
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.product.metadata
                                                .adrClass
                                            ) + " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.product.metadata
                                      .packagingGroup
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.product.metadata
                                                .packagingGroup
                                            ) + ", "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.product.metadata.unnr
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.product.metadata
                                                .unnr
                                            ) + " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.product.metadata
                                      .kemlerNumber
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.product.metadata
                                                .kemlerNumber
                                            ) + " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLeg.product.metadata
                                      .tunnelRestriction
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.product.metadata
                                                .tunnelRestriction
                                            ) + " "
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.unloadingTemperature))
                                  ]),
                                  _c("td", [
                                    _vm.selectedLeg.details.fillingRate
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLeg.details
                                                .fillingRate
                                            ) + " %"
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              ])
                            ]
                          ),
                          _c("table", { staticClass: "table-fixed w-full" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("container.weight")) + " O/E"
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("container.volume")) + " O/E"
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("container.initialLoadState")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" })
                              ])
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm.selectedLeg.details.orderedWeight
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details
                                              .orderedWeight
                                          ) + "kg "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.selectedLeg.details.orderedWeight &&
                                  _vm.selectedLeg.details.loadedWeight
                                    ? _c("span", [_vm._v(" / ")])
                                    : _vm._e(),
                                  _vm.selectedLeg.details.loadedWeight
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details.loadedWeight
                                          ) + "kg"
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedLeg.details.orderedVolume
                                    ) +
                                      "l / " +
                                      _vm._s(
                                        _vm.selectedLeg.details.loadedVolume
                                      ) +
                                      "l"
                                  )
                                ]),
                                _c("td", [
                                  _vm.selectedLeg.details
                                    .initialContainerLoadState
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details
                                              .initialContainerLoadState
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td")
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "bg-gray-100 my-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-12 flex items-center border-b border-gray-200"
                          },
                          [
                            _c("div", { staticClass: "px-4 text-xl" }, [
                              _vm._v(_vm._s(_vm.$t("dispo.title")))
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "legDetail__table p-4" }, [
                          _c(
                            "table",
                            { staticClass: "table-fixed w-full mb-8" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "w-1/4 text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("dispo.loadingAddress")
                                          ) +
                                          " "
                                      ),
                                      !_vm.isEmpty(
                                        _vm.selectedLeg.events
                                          .loadingOpeningHours
                                      )
                                        ? _c(
                                            "a-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                getPopupContainer: function(
                                                  triggerNode
                                                ) {
                                                  return triggerNode.parentNode
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "content",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "LegDetailOpeningHours",
                                                          {
                                                            attrs: {
                                                              "opening-hours":
                                                                _vm.selectedLeg
                                                                  .events
                                                                  .loadingOpeningHours,
                                                              "planned-date":
                                                                _vm.selectedLeg
                                                                  .events
                                                                  .plannedLoading
                                                                  .date
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2177838221
                                              )
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass:
                                                  "text-black cursor-pointer",
                                                attrs: {
                                                  name: "info",
                                                  width: "1.5rem",
                                                  height: "1.5rem"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "w-1/4 text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("dispo.unloadingAddress")
                                          ) +
                                          " "
                                      ),
                                      !_vm.isEmpty(
                                        _vm.selectedLeg.events
                                          .unloadingOpeningHours
                                      )
                                        ? _c(
                                            "a-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                getPopupContainer: function(
                                                  triggerNode
                                                ) {
                                                  return triggerNode.parentNode
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "content",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "LegDetailOpeningHours",
                                                          {
                                                            attrs: {
                                                              "opening-hours":
                                                                _vm.selectedLeg
                                                                  .events
                                                                  .unloadingOpeningHours,
                                                              "planned-date":
                                                                (_vm.selectedLeg
                                                                  .events
                                                                  .plannedUnloading &&
                                                                  _vm
                                                                    .selectedLeg
                                                                    .events
                                                                    .plannedUnloading
                                                                    .date) ||
                                                                undefined
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                293915653
                                              )
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass:
                                                  "text-black cursor-pointer",
                                                attrs: {
                                                  name: "info",
                                                  width: "1.5rem",
                                                  height: "1.5rem"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dispo.plannedLoadingAndUnloadingDate"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.loadingUnloadingData.title) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", { staticClass: "align-top" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.fullLoadingAddress.name)
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.fullLoadingAddress.longName)
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.fullLoadingAddress.street)
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fullLoadingAddress.countryAndCity
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.fullUnloadingAddress.name)
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fullUnloadingAddress.longName
                                        )
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.fullUnloadingAddress.street)
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fullUnloadingAddress
                                            .countryAndCity
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "L: " +
                                              _vm.plannedLoadingAndUnloading
                                                .plannedLoadingDate
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "U: " +
                                              _vm.plannedLoadingAndUnloading
                                                .plannedUnloadingDate
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm.loadingUnloadingData.loadingPoint
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.loadingUnloadingData
                                                  .loadingPoint
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.loadingUnloadingData.unloadingPoint
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.loadingUnloadingData
                                                  .unloadingPoint
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "table",
                            { staticClass: "table-fixed w-full mb-8" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(_vm._s(_vm.$t("dispo.TimeSlot")))
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("dispo.loadingInformation")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("dispo.unloadingInformation")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "w-1/4 text-left" }, [
                                    _vm._v(_vm._s(_vm.$t("dispo.legRemarks")))
                                  ])
                                ])
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "align-top" },
                                    [
                                      _c("LegDetailTimeSlot", {
                                        attrs: { selectedLeg: _vm.selectedLeg }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm.selectedLeg.details.loadingInfo
                                      ? _c("div", [
                                          _vm.selectedLeg.details.loadingInfo
                                            .length && !_vm.legType.delivery
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.selectedLeg.details
                                                    .loadingInfo,
                                                  function(info, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: info,
                                                        staticClass: "inline"
                                                      },
                                                      [
                                                        index > 0
                                                          ? _c("span", [
                                                              _vm._v(" / ")
                                                            ])
                                                          : _vm._e(),
                                                        _c("span", [
                                                          _vm._v(_vm._s(info))
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("td", [
                                    _vm.selectedLeg.details.unloadingInfo
                                      ? _c("div", [
                                          _vm.selectedLeg.details.unloadingInfo
                                            .length && !_vm.legType.collection
                                            ? _c(
                                                "div",
                                                { staticClass: "inline" },
                                                _vm._l(
                                                  _vm.selectedLeg.details
                                                    .unloadingInfo,
                                                  function(info, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: info,
                                                        staticClass: "inline"
                                                      },
                                                      [
                                                        index > 0
                                                          ? _c("span", [
                                                              _vm._v(" / ")
                                                            ])
                                                          : _vm._e(),
                                                        _c("span", [
                                                          _vm._v(_vm._s(info))
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("LegDetailRemarks", {
                                        staticClass: "pr-3",
                                        attrs: {
                                          defaultValue: _vm.initialLegRemarks
                                        },
                                        on: {
                                          remarksChanged: _vm.onRemarksChange
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c("table", { staticClass: "table-fixed w-full" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("dispo.freightPayer")) +
                                      " "
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("dispo.senderReference")) +
                                      " "
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("dispo.consigneeReference")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("th", { staticClass: "w-1/4 text-left" })
                              ])
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm.selectedLeg.details.invoicee
                                    ? _c("span", { staticClass: "pr-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details.invoicee
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td", [
                                  _vm.selectedLeg.details.senderReference
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details
                                              .senderReference
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td", [
                                  _vm.selectedLeg.details.consigneeReference
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedLeg.details
                                              .consigneeReference
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td")
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("LegDetailPreviousLegState", {
                        attrs: {
                          data: _vm.selectedLeg.previousLeg,
                          status: _vm.legStatus
                        }
                      }),
                      _c("LegDetailStepSection", {
                        staticClass: "mb-20",
                        attrs: { selectedLeg: _vm.selectedLeg },
                        on: { setEventIsRead: _vm.setEventIsRead }
                      }),
                      _vm.legStatus.dropped
                        ? _c("LegDetailPlannedDates", {
                            attrs: {
                              status: _vm.legStatus,
                              "planned-dates": _vm.plannedDates
                            },
                            on: { plannedDatesChange: _vm.updatePlannedDates }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actionBar",
                  fn: function(ref) {
                    var close = ref.close
                    return [
                      _c(
                        "div",
                        { staticClass: "flex justify-between w-full" },
                        [
                          _vm.legStatus.notBooked || _vm.legStatus.prePlanned
                            ? _c(
                                "Button",
                                {
                                  attrs: {
                                    variant: "tertiary",
                                    disabled: _vm.isUpdating
                                  },
                                  on: { click: _vm.confirmDeleteLeg }
                                },
                                [_vm._v(_vm._s(_vm.$t("actions.remove")))]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "flex ml-auto" },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    variant: "secondary",
                                    disabled: _vm.isUpdating
                                  },
                                  on: {
                                    click: function($event) {
                                      return close()
                                    }
                                  }
                                },
                                [
                                  _vm.legWasModified
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("actions.close")))
                                      ])
                                ]
                              ),
                              _c(
                                "Button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.legWasModified,
                                      expression: "legWasModified"
                                    }
                                  ],
                                  attrs: { disabled: _vm.isUpdating },
                                  on: { click: _vm.saveChanges }
                                },
                                [_vm._v(_vm._s(_vm.$t("actions.save")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1208615491
            )
          })
        : _vm._e(),
      _vm.dialog.isActive
        ? _c("Dialog", {
            attrs: {
              title: _vm.dialog.title,
              message: _vm.dialog.message,
              "is-active": _vm.dialog.isActive,
              "is-centered": _vm.dialog.isCentered,
              "show-buttons": _vm.dialog.showButtons
            },
            on: {
              closeDialog: function($event) {
                _vm.dialog.isActive = false
              },
              confirm: _vm.deleteLeg
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }