<template>
  <div>
    <Notification
      v-if="notStartedLegs.length > 0 && ticker.show"
      :notStartedLegsOn="notStartedLegs"
      :tickerPosition="tickerPosition"
    />
    <div
      v-if="ticker.show"
      class="absolute top-12 left-0 w-1 h-screen opacity-50 bg-red-600 z-0"
      :style="tickerPosition"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import { GET_TIME_TICKER, INIT_TIME_TICKER } from "@/store/board";
import { GET_NOTRATED_SUBCONTRACTORS } from "@/store/fleet";
import { Subcontractor } from "@/types/fleet";
import { TimeTicker } from "@/types/timeTicker";
import { toLocalDateTime, removeMinutes } from "@/use/useDate";
import moment from "moment";
import { BoardLeg } from "@/types/leg";
import Notification from "@/components/Board/Notification/Notification.vue";
import { spreadSubcontractorId } from "@/use/useFleet";
export default defineComponent({
  name: "Ticker",
  components: { Notification },

  setup() {
    const ticker = computed(
      (): TimeTicker => {
        return store.getters[GET_TIME_TICKER];
      }
    );

    onMounted(() => {
      store.dispatch(INIT_TIME_TICKER);
    });

    const tickerPosition = computed(() => `transform: translateX(${ticker.value.offset}px)`);
    const subi = computed<Subcontractor[]>(() => store.getters[GET_NOTRATED_SUBCONTRACTORS]);
    const legs = computed<BoardLeg[]>(() => store.state.brick.legs);

    const subcontractorIds = (subi: Array<Subcontractor>): Array<string> => {
      return subi.map((subcontractor: Subcontractor) => spreadSubcontractorId(subcontractor.id).id);
    };

    const getSubcontractors = (subiIds: Array<string>): Array<string> => {
      return [...new Set(subiIds)];
    };

    const notStartedLegs = computed(() => {
      tickerPosition.value;
      const subcontractors = subi && subi.value.length > 0 ? getSubcontractors(subcontractorIds(subi.value)) : [];
      const newDateToCompareWith = moment(toLocalDateTime(removeMinutes(15, new Date())));
      return legs.value
        .filter(leg => leg?.details?.flexible === false)
        .filter(legT => legT?.events?.plannedDepartureDate?.date.includes("T") && !legT?.events?.effectiveDepartureDate)
        .map(legState => ({
          ...legState,
          subcontractorIdToCompare: legState.subcontractor ? legState.subcontractor.id : "",
          comparableDate: moment(toLocalDateTime(new Date(legState.events!.plannedDepartureDate!.date)))
        }))
        .filter(legCompare => moment(legCompare.comparableDate) < newDateToCompareWith)
        .filter(legSubi => !subcontractors.includes(legSubi.subcontractorIdToCompare));
    });

    return {
      ticker,
      tickerPosition,
      notStartedLegs
    };
  }
});
</script>

<style lang="scss" scoped></style>
