import { BoardLeg, BrickSubcontractor, Leg, LegEffectiveTimestamps, LegEvents } from "@/types/leg";
import { DateTimezone } from "@/types/types";
import {
  add,
  addHours,
  differenceInDays,
  eachWeekendOfInterval,
  endOfDay,
  isSaturday,
  isSunday,
  isWeekend,
  startOfDay
} from "date-fns";
import { setDateHoursToZero, toLocalDateTime } from "@/use/useDate";
import { BoardBrick, BoardFlexible, BoardPositionable, BoardBrickCoordinates, CompositeBrickId } from "@/types/board";
import { BoardFleetAction, FleetAction, FleetActionTimestamps } from "@/types/action";
import { OrderedFleetIds } from "@/types/fleet";
import { addIfNotExist } from "@/use/useArray";
import { defineLegStatus } from "@/use/useLeg";
import {
  buildNoSeqSubcontractorId,
  buildSubcontractorId,
  buildTruckId,
  spreadSubcontractorId,
  spreadTrailerId,
  spreadTruckId
} from "@/use/useFleet";

const reminderBrickWidth = 55;

const isDateOnlyTimestamp = function(timestamp: string): boolean {
  return !~timestamp.indexOf("T");
};

const isLegBrick = function(brick: BoardBrick): boolean {
  return (brick as BoardLeg).stops != null;
};

const extractEffectiveActionTimestamps = function(action: FleetAction): FleetActionTimestamps {
  const actionFromDate = action.fromDate?.date;
  const actionToDate = action.toDate?.date;

  let actionStartDate = actionFromDate;
  let actionEndDate = actionToDate;

  if (isDateOnlyTimestamp(actionFromDate!)) {
    actionStartDate = toLocalDateTime(startOfDay(new Date(actionFromDate!)));
  }

  if (action.isReminder) {
    actionEndDate = toLocalDateTime(addHours(new Date(actionFromDate!), 1));
  } else if (isDateOnlyTimestamp(actionToDate!)) {
    actionEndDate = toLocalDateTime(endOfDay(new Date(actionToDate!)));
  }

  return {
    actionStartDate,
    actionEndDate
  };
};

const extractEffectiveLegTimestamps = function(events: LegEvents): LegEffectiveTimestamps {
  const { effectiveDepartureDate, plannedDepartureDate, plannedArrivalDate, effectiveArrivalDate } = events;
  const departureEvent: DateTimezone | undefined = effectiveDepartureDate || plannedDepartureDate;
  const arrivalEvent: DateTimezone | undefined = effectiveArrivalDate || plannedArrivalDate;

  let departureDate = departureEvent?.date;
  let arrivalDate = arrivalEvent?.date;

  if (typeof departureDate === "undefined" && typeof arrivalDate === "undefined") {
    return { departureDate, arrivalDate };
  }

  if (typeof departureDate === "undefined") {
    departureDate = addHours(new Date(arrivalDate!), -3).toISOString();
  }
  if (typeof arrivalDate === "undefined") {
    arrivalDate = addHours(new Date(departureDate!), 3).toISOString();
  }

  if (isDateOnlyTimestamp(departureDate)) {
    departureDate = toLocalDateTime(startOfDay(new Date(departureDate)));
  }

  if (isDateOnlyTimestamp(arrivalDate)) {
    arrivalDate = toLocalDateTime(endOfDay(new Date(arrivalDate)));
  }

  return {
    departureDate,
    arrivalDate
  };
};

const brickFitsTimespan = function(
  timespanFromDate: Date,
  timespanFromTime: number,
  timespanToDate: Date,
  timespanToTime: number,
  includeWeekends: boolean,
  brickStartDate?: string,
  brickEndDate?: string,
  isActionBrick = false
) {
  if (typeof brickStartDate === "undefined" || typeof brickEndDate === "undefined") {
    return false;
  }

  if (isDateOnlyTimestamp(brickStartDate) || isDateOnlyTimestamp(brickEndDate)) {
    return true;
  }

  const brickDepartureDate = new Date(brickStartDate);
  const brickArrivalDate = new Date(brickEndDate);
  const brickDepartureHours = brickDepartureDate.getHours();
  const brickArrivalHours = brickArrivalDate.getHours();
  const brickDepartureDateOnly = setDateHoursToZero(brickArrivalDate);
  const brickArrivalDateOnly = setDateHoursToZero(brickDepartureDate);

  if (!includeWeekends && isWeekend(brickDepartureDate) && isWeekend(brickArrivalDate) && !isActionBrick) {
    return false;
  }

  if (brickArrivalDate < startOfDay(timespanFromDate) || brickDepartureDate > endOfDay(timespanToDate)) {
    return false;
  }

  const daysDifference = differenceInDays(brickDepartureDateOnly, brickArrivalDateOnly);

  if (daysDifference > 0) {
    if (daysDifference === 1 && brickDepartureHours >= timespanToTime && brickArrivalHours <= timespanFromTime) {
      return false;
    }
    return true;
  }
  return !(brickDepartureHours >= timespanToTime || brickArrivalHours < timespanFromTime);
};

const legFitsTimespan = function(
  leg: Leg,
  fromDate: Date,
  fromTime: number,
  toDate: Date,
  toTime: number,
  includeWeekends: boolean
): boolean {
  if (typeof leg.events === "undefined") {
    return false;
  }

  const { departureDate, arrivalDate } = extractEffectiveLegTimestamps(leg.events!);

  return brickFitsTimespan(fromDate, fromTime, toDate, toTime, includeWeekends, departureDate, arrivalDate);
};

const actionFitsTimespan = function(
  action: FleetAction,
  fromDate: Date,
  fromTime: number,
  toDate: Date,
  toTime: number,
  includeWeekends: boolean
): boolean {
  const actionFromDate = action.fromDate?.date;
  const actionToDate = action.toDate?.date;

  if (actionFromDate == null || (!action.isReminder && actionToDate == null)) {
    return false;
  }

  const { actionStartDate, actionEndDate } = extractEffectiveActionTimestamps(action);

  return brickFitsTimespan(fromDate, fromTime, toDate, toTime, includeWeekends, actionStartDate, actionEndDate, true);
};

const getLowestSequenceId = function(subcontractorIds: string[]): string | undefined {
  if (subcontractorIds.length === 0) {
    return undefined;
  }

  return subcontractorIds.sort((subiId1, subiId2) => {
    return spreadSubcontractorId(subiId1).sequence - spreadSubcontractorId(subiId2).sequence;
  })[0];
};

const getBrickTrailerId = (trailerId: string, fleetIndices: Map<string, number[]>): string | undefined => {
  const matchingCompositeTrailerId = Array.from(fleetIndices.keys()).find(
    entityId => spreadTrailerId(entityId)?.id === trailerId
  );
  return matchingCompositeTrailerId;
};

const getBrickSubcontractorId = function(
  subcontractor: BrickSubcontractor,
  fleetIndices: Map<string, number[]>,
  activeBoardIds: string[] = []
): string | undefined {
  const { id, primaryBoard, secondaryBoard } = subcontractor;
  const partialPrimarySubiId = buildNoSeqSubcontractorId(id, primaryBoard?.id || "");
  const partialSecondarySubiId = buildNoSeqSubcontractorId(id, secondaryBoard?.id || "");

  if (activeBoardIds.length) {
    const hasMatchingBoard = activeBoardIds.some(
      boardId => boardId == primaryBoard?.id || boardId == secondaryBoard?.id
    );
    if (!hasMatchingBoard) {
      return undefined;
    }
  }

  const subiIdCandidates = Array.from(fleetIndices.keys())
    .filter(entityId => entityId.startsWith(partialPrimarySubiId) || entityId.startsWith(partialSecondarySubiId))
    .reduce(
      (acc, entityId) => {
        if (entityId.startsWith(partialPrimarySubiId)) {
          acc.primaryMatches = [...acc.primaryMatches, entityId];
        } else {
          acc.secondaryMatches = [...acc.secondaryMatches, entityId];
        }
        return acc;
      },
      {
        primaryMatches: [],
        secondaryMatches: []
      } as {
        primaryMatches: string[];
        secondaryMatches: string[];
      }
    );

  if (subiIdCandidates.primaryMatches.length === 0 && subiIdCandidates.secondaryMatches.length === 0) {
    return undefined;
  }

  const primarySubiId = buildSubcontractorId(id, primaryBoard?.id || "", primaryBoard?.sequence || 1);
  const secondarySubiId = buildSubcontractorId(id, secondaryBoard?.id || "", secondaryBoard?.sequence || 1);

  if (fleetIndices.has(primarySubiId)) {
    return primarySubiId;
  }
  if (fleetIndices.has(secondarySubiId)) {
    return secondarySubiId;
  }
  if (subiIdCandidates.primaryMatches.length) {
    return getLowestSequenceId(subiIdCandidates.primaryMatches);
  }
  if (subiIdCandidates.secondaryMatches.length) {
    return getLowestSequenceId(subiIdCandidates.secondaryMatches);
  }
};

const getBrickSortIndices = function(
  brick: BoardBrick,
  orderedFleetIds: OrderedFleetIds,
  activeBoardIds: string[] = []
): number[] {
  const { directFleetIndices, nestedFleetIndices } = orderedFleetIds;
  const { driverId, subcontractor, trailerId, truckId } = brick;
  if (truckId) {
    if (subcontractor) {
      const { primaryBoard, secondaryBoard } = subcontractor;
      if (primaryBoard || secondaryBoard) {
        return (
          directFleetIndices.get(getBrickSubcontractorId(subcontractor, directFleetIndices, activeBoardIds)!) || []
        );
      }
    }
    const truckIndices = directFleetIndices.get(truckId) || directFleetIndices.get(buildTruckId(truckId, driverId));

    if (truckIndices) {
      return truckIndices;
    }

    const firstMatchingTruckId = Array.from(directFleetIndices.keys()).find(key => spreadTruckId(key).id == truckId);

    return (firstMatchingTruckId && directFleetIndices.get(firstMatchingTruckId)) || [];
  }

  if (subcontractor) {
    return directFleetIndices.get(getBrickSubcontractorId(subcontractor, directFleetIndices, activeBoardIds)!) || [];
  }
  if (driverId) {
    const indices = directFleetIndices.get(driverId);

    if (indices == null && isLegBrick(brick)) {
      return [];
    }
  }

  if (!isLegBrick(brick)) {
    const subcontractorId = subcontractor && getBrickSubcontractorId(subcontractor, directFleetIndices, activeBoardIds);
    const compositeTrailerId =
      (trailerId && getBrickTrailerId(trailerId, directFleetIndices)) ||
      (trailerId && getBrickTrailerId(trailerId, nestedFleetIndices));
    const brickEntityId = compositeTrailerId || driverId || subcontractorId;

    if (brickEntityId) {
      const directFleetIndex = directFleetIndices.get(brickEntityId);

      if (directFleetIndex) {
        return directFleetIndex;
      }

      const indices = nestedFleetIndices.get(brickEntityId);

      if (indices) {
        return indices;
      }

      const firstMatchingId = Array.from(nestedFleetIndices.keys()).find(key => spreadBrickId(key).id == brickEntityId);

      return (firstMatchingId && nestedFleetIndices.get(firstMatchingId)) || [];
    }
  }

  return [];
};

const spreadBrickId = (brickId: string): CompositeBrickId => {
  if (!brickId) {
    throw new Error("Brick ID must not be undefined");
  }

  const parts: string[] = brickId.split("#");

  return {
    id: parts[0],
    index: parts.length === 2 ? parts[1] : undefined
  };
};

const brickHasMatchingFleetEntity = function(
  brick: BoardBrick,
  orderedFleetIds: OrderedFleetIds,
  activeBoardIds: string[]
): boolean {
  return getBrickSortIndices(brick, orderedFleetIds, activeBoardIds).length > 0;
};

const getEventXBoardCoordinate = function(
  eventTimestamp: string,
  boardFromDate: Date,
  boardFromHours: number,
  boardToHours: number,
  hourBoxesPerDay: number,
  hourBoxWidth: number,
  boardWidth: number,
  includeWeekends: boolean
): number {
  let eventDate = new Date(eventTimestamp!);
  if (!includeWeekends) {
    if (isSaturday(eventDate)) {
      eventDate = startOfDay(eventDate);
    }
    if (isSunday(eventDate)) {
      eventDate = startOfDay(add(eventDate, { days: -1 }));
    }
  }

  if (eventDate < boardFromDate) {
    return 0;
  }

  const eventHours = eventDate.getHours();
  const eventMinutes = eventDate.getMinutes();

  let pixelsToShiftPerDay;
  if (eventDate <= boardFromDate) {
    pixelsToShiftPerDay = 0;
  } else {
    let daysDifference = differenceInDays(eventDate, boardFromDate);
    if (!includeWeekends) {
      daysDifference = isSaturday(eventDate) ? daysDifference + 1 : daysDifference;
      daysDifference =
        daysDifference -
        eachWeekendOfInterval({
          start: boardFromDate,
          end: eventDate
        }).length;
    }
    pixelsToShiftPerDay = daysDifference * hourBoxesPerDay * hourBoxWidth;
  }

  if (boardFromHours > eventHours) {
    return pixelsToShiftPerDay < boardWidth ? pixelsToShiftPerDay : boardWidth;
  }

  if (boardToHours < eventHours) {
    const xCoord = pixelsToShiftPerDay + hourBoxesPerDay * hourBoxWidth;
    return xCoord < boardWidth ? xCoord : boardWidth;
  }

  const pixelsToShiftPerHour = (eventHours > boardFromHours ? eventHours - boardFromHours : 0) * hourBoxWidth;

  const pixelsToShiftPerMinute = Math.floor(hourBoxWidth / 4) * Math.floor(eventMinutes / 15);

  const xCoord = pixelsToShiftPerDay + pixelsToShiftPerHour + pixelsToShiftPerMinute;

  return xCoord < boardWidth ? xCoord : boardWidth;
};

const getCollidingBrickIndices = function(brick: BoardBrickCoordinates, bricks: BoardBrickCoordinates[]): number[] {
  const brickIndices = bricks.reduce((acc, b, index) => {
    acc[b.id] = index;
    return acc;
  }, {} as any);

  return bricks
    .filter(b => b.id !== brick.id)
    .filter(b => brick.x < b.x + b.width && brick.x + brick.width > b.x && brick.y < b.y + 100 && brick.y + 100 > b.y)
    .map(b => brickIndices[b.id]);
};

const calculateYDownshift = function(brick: BoardBrickCoordinates, bricks: BoardBrickCoordinates[]): number {
  const brickRowHeight = 100;
  const collidingBrickIndices = getCollidingBrickIndices(brick, bricks);

  if (collidingBrickIndices.length === 0) {
    return 0;
  }

  return (
    brickRowHeight +
    calculateYDownshift(
      {
        ...brick,
        y: brick.y + brickRowHeight
      },
      bricks
    )
  );
};

const recalculateYCoords = function(bricks: BoardBrick[], yOffset: number): BoardBrick[] {
  const brickCoordinates: BoardBrickCoordinates[] = bricks.map(brick => ({
    id: brick.id,
    x: brick.xCoord,
    y: brick.yCoord + yOffset,
    width: brick.width
  }));

  for (let i = 0; i < brickCoordinates.length; i++) {
    const brickCoord = brickCoordinates[i];
    const collidingIndices = getCollidingBrickIndices(brickCoord, brickCoordinates);
    for (let j = 0; j < collidingIndices.length; j++) {
      const collidingBrick = brickCoordinates[collidingIndices[j]];
      const yDownshift = calculateYDownshift(collidingBrick, brickCoordinates);
      brickCoordinates[collidingIndices[j]] = {
        ...collidingBrick,
        y: collidingBrick.y + yDownshift
      };
    }
  }

  return bricks.map((brick, index) => ({
    ...brick,
    yCoord: brickCoordinates[index].y
  }));
};

const sortLegBricks = function(legs: BoardLeg[]): BoardBrick[] {
  const { notBooked, prePlanned, booked, departed, loaded, unloaded, dropped, other } = legs.reduce(
    (acc, leg) => {
      const legStatus = defineLegStatus(leg);
      if (legStatus.notBooked) {
        acc.notBooked = [...acc.notBooked, leg];
      } else if (legStatus.prePlanned) {
        acc.prePlanned = [...acc.prePlanned, leg];
      } else if (legStatus.booked) {
        acc.booked = [...acc.booked, leg];
      } else if (legStatus.departed) {
        acc.departed = [...acc.departed, leg];
      } else if (legStatus.loaded) {
        acc.loaded = [...acc.loaded, leg];
      } else if (legStatus.unloaded) {
        acc.unloaded = [...acc.unloaded, leg];
      } else if (legStatus.dropped) {
        acc.dropped = [...acc.dropped, leg];
      } else {
        acc.other = [...acc.other, leg];
      }
      return acc;
    },
    {
      notBooked: [],
      prePlanned: [],
      booked: [],
      departed: [],
      loaded: [],
      unloaded: [],
      dropped: [],
      other: []
    } as any
  );

  return [...dropped, ...unloaded, ...departed, ...loaded, ...booked, ...prePlanned, ...notBooked, ...other];
};

const sortActionBricks = function(action: BoardFleetAction[]): BoardBrick[] {
  return action.sort((a1, a2) => {
    return a1.isReminder === a2.isReminder ? 0 : a1.isReminder ? 1 : -1;
  });
};

const sortBricksRow = function(bricks: BoardBrick[]): BoardBrick[] {
  const actionLegBricks: {
    actions: BoardFleetAction[];
    legs: BoardLeg[];
  } = bricks.reduce(
    (acc, brick) => {
      if (isLegBrick(brick)) {
        acc.legs = [...acc.legs, brick];
      } else {
        acc.actions = [...acc.actions, brick];
      }
      return acc;
    },
    {
      actions: [],
      legs: []
    } as any
  );

  return [...sortActionBricks(actionLegBricks.actions), ...sortLegBricks(actionLegBricks.legs)];
};

const compareByEffectiveTimestamps = function(brick1: BoardBrick, brick2: BoardBrick): number {
  if (isLegBrick(brick1) && isLegBrick(brick2)) {
    const { effectiveStartTime: firstLegDepartureTime, effectiveEndTime: firstLegArrivalTime } = brick1 as BoardLeg;
    const { effectiveStartTime: secondLegDepartureTime, effectiveEndTime: secondLegArrivalTime } = brick2 as BoardLeg;

    if (firstLegDepartureTime && secondLegDepartureTime) {
      if (firstLegDepartureTime === secondLegDepartureTime) {
        if (firstLegArrivalTime && secondLegArrivalTime) {
          if (firstLegArrivalTime !== secondLegArrivalTime) {
            return new Date(firstLegArrivalTime) > new Date(secondLegArrivalTime) ? 1 : -1;
          }
        }
        return brick1.id.localeCompare(brick2.id);
      }
      return new Date(firstLegDepartureTime) > new Date(secondLegDepartureTime) ? 1 : -1;
    }
  }

  return brick1.id.localeCompare(brick2.id);
};

const deriveBoardFlexibles = function(bricks: BoardBrick[], orderedFleetIds: OrderedFleetIds): BoardFlexible {
  const bricksPerRow = bricks
    .sort((brick1, brick2) => compareByEffectiveTimestamps(brick1, brick2))
    .reduce((acc, brick) => {
      const fleetOrdinals = getBrickSortIndices(brick, orderedFleetIds);

      fleetOrdinals.forEach(fleetOrdinal => {
        if (!acc[`${fleetOrdinal}`]) {
          acc[`${fleetOrdinal}`] = [];
        }
        const rowBrick = {
          ...brick,
          yCoord: fleetOrdinal * 100
        };

        acc[`${fleetOrdinal}`] = addIfNotExist(acc[`${fleetOrdinal}`], rowBrick, ["id", "yCoord"]);
      });

      return acc;
    }, {} as any);

  const bricksPerRowMap = new Map<string, BoardBrick[]>(Object.entries(bricksPerRow));

  let recalculatedBricks: BoardBrick[] = [];
  let yOffset = 0;
  const fleetSpans = {};
  bricksPerRowMap.forEach((bricks, fleetOrdinal) => {
    const sortedBricks = sortBricksRow(bricks);
    const newYBricks = recalculateYCoords(sortedBricks, yOffset);
    const sortedByY = newYBricks
      .map(brick => brick.yCoord)
      .sort((a, b) => {
        return a - b;
      });
    const yDifference = sortedByY[sortedByY.length - 1] - sortedByY[0];

    yOffset += yDifference;
    fleetSpans[fleetOrdinal] = yDifference > 0 ? yDifference + 100 : 100;
    recalculatedBricks = recalculatedBricks.concat(newYBricks);
  });

  const boardBricks = recalculatedBricks.reduce(
    (acc, brick) => {
      if (isLegBrick(brick)) {
        acc.legs = acc.legs.concat(brick as BoardLeg);
      } else {
        acc.actions = acc.actions.concat(brick as BoardFleetAction);
      }
      return acc;
    },
    {
      legs: [],
      actions: []
    } as {
      legs: BoardLeg[];
      actions: BoardFleetAction[];
    }
  );

  return {
    legs: boardBricks.legs,
    actions: boardBricks.actions,
    fleetSpans
  };
};

const deriveBrickWidth = (startXCoord: number, endXCoord: number, hourBoxWidth: number): number => {
  if (startXCoord === 0 && endXCoord === 0) {
    return 0;
  }
  if (endXCoord - startXCoord > 0) {
    return endXCoord - startXCoord;
  }
  return Math.floor(hourBoxWidth / 4);
};

const deriveBrickCoordinates = (
  brick: BoardBrick,
  orderedFleetIds: OrderedFleetIds,
  startTimestamp: string,
  endTimestamp: string,
  boardFromDate: Date,
  boardFromTime: number,
  boardToTime: number,
  hourBoxesPerDay: number,
  hourBoxWidth: number,
  boardWidth: number,
  includeWeekends: boolean
): BoardPositionable[] => {
  const startXCoord: number = getEventXBoardCoordinate(
    startTimestamp,
    boardFromDate,
    boardFromTime,
    boardToTime,
    hourBoxesPerDay,
    hourBoxWidth,
    boardWidth,
    includeWeekends
  );

  const endXCoord: number = (brick as BoardFleetAction).isReminder
    ? startXCoord + reminderBrickWidth
    : getEventXBoardCoordinate(
        endTimestamp!,
        boardFromDate,
        boardFromTime,
        boardToTime,
        hourBoxesPerDay,
        hourBoxWidth,
        boardWidth,
        includeWeekends
      );

  const yCoords = getBrickSortIndices(brick, orderedFleetIds).map(index => index * 100);
  const width = deriveBrickWidth(startXCoord, endXCoord, hourBoxWidth);

  return yCoords.map(yCoord => ({
    xCoord: startXCoord,
    yCoord,
    width
  }));
};

const isMatchFound = (items: string[] | undefined): boolean => {
  if (typeof items !== "undefined" && items.length > 0) {
    return true;
  }
  return false;
};

export {
  isDateOnlyTimestamp,
  brickHasMatchingFleetEntity,
  getEventXBoardCoordinate,
  getCollidingBrickIndices,
  recalculateYCoords,
  deriveBoardFlexibles,
  legFitsTimespan,
  actionFitsTimespan,
  getBrickSortIndices,
  deriveBrickCoordinates,
  extractEffectiveLegTimestamps,
  extractEffectiveActionTimestamps,
  getBrickSubcontractorId,
  brickFitsTimespan,
  deriveBrickWidth,
  isMatchFound
};
