var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c("div", { staticClass: "mr-4", class: _vm.statusClass }, [
        _vm.selectedLeg.details.ttaLink
          ? _c("div", { staticClass: "cursor-pointer" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.selectedLeg.details.ttaLink,
                    target: "_blank"
                  }
                },
                [
                  _vm.selectedLeg.details.flexible
                    ? _c("Icon", {
                        staticClass: "leg__truck",
                        attrs: { name: "truck-flexible" }
                      })
                    : _vm._e(),
                  !_vm.selectedLeg.details.flexible
                    ? _c("Icon", {
                        staticClass: "leg__truck",
                        attrs: { name: "truck" }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          : _c(
              "div",
              [
                _vm.selectedLeg.details.flexible
                  ? _c("Icon", {
                      staticClass: "leg__truck",
                      attrs: { name: "truck-flexible" }
                    })
                  : _vm._e(),
                !_vm.selectedLeg.details.flexible
                  ? _c("Icon", {
                      staticClass: "leg__truck",
                      attrs: { name: "truck" }
                    })
                  : _vm._e()
              ],
              1
            )
      ]),
      _c(
        "a-tooltip",
        {
          attrs: {
            placement: "bottom",
            align: { offset: [0, 5] },
            title: _vm.$t("legSteps.eventRead")
          }
        },
        [
          _vm.eventIsRead
            ? _c("Icon", {
                staticClass: "mr-4",
                attrs: { name: "mobile-friendly", height: "20" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(
        " " +
          _vm._s(_vm.hierarchyIndicator) +
          " " +
          _vm._s(_vm.legType.name) +
          "- "
      ),
      _vm.selectedLeg.orderNumberAlt
        ? _c("span", { staticClass: "mr-2" }, [
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer border-b border-gray-400 hover:border-yellow-500 inline-block",
                on: { click: _vm.notifyG11Bookings }
              },
              [_vm._v(" " + _vm._s(_vm.selectedLeg.orderNumberAlt) + " ")]
            ),
            _vm._v(" / ")
          ])
        : _vm._e(),
      _vm.selectedLeg.stops.from.name
        ? _c("span", { staticClass: "mr-2" }, [
            _vm._v(" " + _vm._s(_vm.selectedLeg.stops.from.name) + " / ")
          ])
        : _vm._e(),
      _vm.legType.delivery &&
      _vm.selectedLeg.stops.unloading.name !==
        _vm.selectedLeg.stops.from.name &&
      _vm.selectedLeg.stops.unloading.name !== _vm.selectedLeg.stops.to.name
        ? _c("span", { staticClass: "mr-2" }, [
            _vm._v(" " + _vm._s(_vm.selectedLeg.stops.unloading.name) + " / ")
          ])
        : _vm.legType.fullTransport ||
          (_vm.legType.collection &&
            _vm.selectedLeg.stops.loading.name !==
              _vm.selectedLeg.stops.from.name &&
            _vm.selectedLeg.stops.loading.name !==
              _vm.selectedLeg.stops.to.name)
        ? _c("span", { staticClass: "mr-2" }, [
            _vm._v(" " + _vm._s(_vm.selectedLeg.stops.loading.name) + " /")
          ])
        : _vm._e(),
      _vm.legType.fullTransport &&
      _vm.selectedLeg.stops.unloading.name !==
        _vm.selectedLeg.stops.from.name &&
      _vm.selectedLeg.stops.unloading.name !== _vm.selectedLeg.stops.to.name
        ? _c("span", { staticClass: "mr-2" }, [
            _vm._v(" " + _vm._s(_vm.selectedLeg.stops.unloading.name) + " /")
          ])
        : _vm._e(),
      _vm.selectedLeg.stops.to.name
        ? _c("span", [_vm._v(" " + _vm._s(_vm.selectedLeg.stops.to.name))])
        : _vm._e(),
      _vm.selectedLeg.details.neutral
        ? _c("span", { staticClass: "text-lg ml-4 capitalize" }, [
            _vm._v(_vm._s(_vm.selectedLeg.details.neutral.toLowerCase()))
          ])
        : _vm._e(),
      _vm.legIssues != ""
        ? _c(
            "span",
            { staticClass: "cursor-pointer ml-4 flex" },
            [
              _c(
                "a-tooltip",
                {
                  attrs: {
                    placement: "bottom",
                    align: { offset: [0, 5] },
                    title: _vm.legIssues
                  }
                },
                [
                  _c("Icon", {
                    staticClass: "text-red-500",
                    attrs: {
                      name: "exclamation-triangle",
                      height: "17",
                      width: "17"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "cursor-pointer ml-4 flex",
          on: { click: _vm.notifyG11Planning }
        },
        [
          _c("Icon", {
            staticClass: "hover:text-btYellow",
            attrs: { name: "hyperlink", height: "17", width: "17" }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "cursor-pointer ml-4 flex",
          on: {
            click: function($event) {
              return _vm.$emit("close-dialog")
            }
          }
        },
        [
          _vm.showChatNotificationIcon
            ? _c("ChatNotifications", {
                staticClass: "relative -top-0.5",
                attrs: {
                  content: _vm.selectedLeg,
                  iconWidth: "20",
                  totalUnreadMessages: _vm.totalUnreadMessages
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }