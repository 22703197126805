

































































import { computed, defineComponent, nextTick, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import {
  GET_ACTIVE_CHAT,
  GET_CHAT_USERNAME,
  GET_IS_CHAT_ACTIVE,
  SET_ACTIVE_CHAT,
  SET_IS_CHAT_ACTIVE
} from "@/store/chat";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Buttons/Button.vue";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import chatService from "@/services/chatService";
import { MessageOrigin } from "@/types/chat";

export default defineComponent({
  name: "ChatRoom",
  components: { Icon, Button },
  setup() {
    const inputValue = ref("");
    const isActive = computed(() => store.getters[GET_IS_CHAT_ACTIVE]);
    const activeChat = computed(() => store.getters[GET_ACTIVE_CHAT]);
    const chatUsername = computed(() => store.getters[GET_CHAT_USERNAME]);
    const messageQueue = ref(false);
    const isBookingIdEmpty = computed(() => activeChat.value?.bookingId == null);

    const chatWindowStyle = computed(() => {
      return {
        height: isActive.value ? "100vh" : "0px",
        paddingBottom: isActive.value ? "84px" : "0px",
        paddingTop: isActive.value ? "57px" : "0px"
      };
    });

    const quitChatRoom = () => {
      store.commit(SET_IS_CHAT_ACTIVE, false);
      store.commit(SET_ACTIVE_CHAT, null);
    };

    const chatMessages = computed(() => {
      const { messages } = activeChat.value || {};
      if (!messages) return {};
      const sortedMessages = [...messages].sort((a, b) => a.sequence - b.sequence);
      return sortedMessages.reduce((acc, message) => {
        const date = dateStringToShowDate(message.sent);
        if (!acc[date]) {
          acc[date] = [message];
        } else {
          acc[date].push(message);
        }
        return acc;
      }, {});
    });

    watch(chatMessages, async n => {
      await nextTick();
      if (Object.keys(n).length !== 0) {
        scrollToBottomOfChat("auto");
      }
    });

    onMounted(async () => {
      await nextTick();
      scrollToBottomOfChat("auto");
    });

    const sendMessage = async () => {
      if (messageQueue.value === true || inputValue.value === "" || isBookingIdEmpty.value) return;
      messageQueue.value = true;
      try {
        if (inputValue.value.length > 0) {
          await chatService.sendChatMessage(activeChat.value.bookingId, { text: inputValue.value });
          inputValue.value = "";
          messageQueue.value = false;
        } else {
          messageQueue.value = false;
        }
      } catch (e) {
        messageQueue.value = false;
      }
    };

    const alignMessageOnRight = (origin: MessageOrigin): boolean => {
      return origin === MessageOrigin.WEB;
    };

    const scrollToBottomOfChat = (scrollType: ScrollBehavior) => {
      const mostRecentMessage = Array.from(document.querySelectorAll(".chat-bubble")).pop();
      if (mostRecentMessage) {
        mostRecentMessage.scrollIntoView({ behavior: scrollType });
      }
    };

    return {
      isActive,
      chatWindowStyle,
      quitChatRoom,
      activeChat,
      chatMessages,
      dateStringToShowTime,
      chatUsername,
      messageQueue,
      inputValue,
      sendMessage,
      alignMessageOnRight,
      isBookingIdEmpty
    };
  }
});
