








































import { defineComponent, onMounted, onUnmounted, ref, watch } from "@vue/composition-api";
import Overlay from "@/components/Overlay.vue";
import Icon from "@/components/Icon.vue";
import useScrollPosition from "@/use/useScrollPosition";

export default defineComponent({
  name: "SplitScreen",
  components: { Overlay, Icon },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    const { toggleScrollFixed } = useScrollPosition();

    const closeBtn = ref<HTMLElement | null>(null);

    const splitScreen = ref<HTMLElement | null>(null);
    const splitScreenContent = ref(false);

    const closeSplitScreen = () => {
      splitScreenContent.value = false;
      setTimeout(() => {
        context.emit("closeHandler");
      }, 500);
    };

    const splitScreenEscapeHandler = (e: any) => {
      if (e.key === "Escape") {
        closeSplitScreen();
        e.stopPropagation();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", splitScreenEscapeHandler, {
        once: true
      });

      setTimeout(() => {
        closeBtn.value?.focus();
      }, 500);
    });

    onUnmounted(() => {
      toggleScrollFixed();
    });

    watch(
      () => props.isActive,
      () => {
        if (props.isActive) {
          toggleScrollFixed();
        }
        splitScreenContent.value = true;
      },
      { immediate: true }
    );

    return {
      splitScreen,
      splitScreenContent,
      closeSplitScreen,
      closeBtn
    };
  }
});
