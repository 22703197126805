var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.driverData
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "border-b-2 font-semibold mb-2 border-blue-300 text-blue-300"
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.driverData.firstName + " " + _vm.driverData.lastName
                ) +
                " "
            )
          ]
        ),
        _c("div", { staticClass: "popover-body" }, [
          _c("table", { staticClass: "table-fixed w-full mb-6" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "w-1/3 font-thin" }, [
                  _vm._v(_vm._s(_vm.$t("driver.phoneNumber")))
                ])
              ])
            ]),
            _c("tbody", { staticClass: "font-bold align-top" }, [
              _c("tr", [_c("td", [_vm._v(_vm._s(_vm.driverData.phoneNumber))])])
            ])
          ])
        ])
      ])
    : _c(
        "div",
        { staticClass: "flex justify-center items-center" },
        [
          _c("Icon", {
            staticClass: "text-btYellow",
            attrs: { name: "spinner", height: "60", width: "60" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }