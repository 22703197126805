/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#3e3e3e" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8.011 8.011 0 01-8 8z"/>'
  }
})
