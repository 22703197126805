import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { deleteCookie, getCookie } from "@/use/useCookie";
import { APP_AUTH, TP_COOKIE_NAME } from "@/env_config";

const envAuth: string | undefined = APP_AUTH;
const envCookieName: string | undefined = TP_COOKIE_NAME;

const AUTH_STORE = "auth";
const IS_LOGGED_IN = `${AUTH_STORE}/IS_LOGGED_IN`;
const CHECK_AUTH = `${AUTH_STORE}/CHECK_AUTH`;
const LOG_OUT = `${AUTH_STORE}/LOG_OUT`;

const SET_AUTH_LOCAL = "SET_AUTH";

@Module({ namespaced: true })
export default class Index extends VuexModule {
  authenticated = false;

  get IS_LOGGED_IN() {
    return this.authenticated;
  }
  @Mutation
  SET_AUTH(value: boolean) {
    this.authenticated = value;
  }

  @Action({ commit: SET_AUTH_LOCAL })
  CHECK_AUTH() {
    if (envAuth === "true" || typeof envAuth === "undefined") {
      const cookieValue = getCookie(envCookieName ? envCookieName : "");
      return !!cookieValue;
    } else {
      return true;
    }
  }

  @Action({ commit: SET_AUTH_LOCAL })
  LOG_OUT() {
    deleteCookie(envCookieName ? envCookieName : "");
    return false;
  }
}

export { IS_LOGGED_IN, CHECK_AUTH, LOG_OUT };
