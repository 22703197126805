import { format, parseISO } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { prependZeroNumber } from "@/use/useNumbers";
import { LANG_DE } from "@/i18n/i18n";
import moment from "moment";

const HOUR_SEPARATOR = ":";

const dateLocale = (locale?: string): Locale => {
  if (locale === LANG_DE) {
    return deLocale;
  }
  return enLocale;
};

const getLocalTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const localTimezone = getLocalTimezone();

const setDateHoursToZero = (date: Date): number => {
  const dateClone = new Date(date.getTime());
  return dateClone.setHours(0, 0, 0, 0);
};

const createEndOfDayDate = (date: Date, includeSeconds = true) => {
  if (includeSeconds) {
    return `${format(date, "yyyy-MM-dd")}T23:59:00`;
  }
  return `${format(date, "yyyy-MM-dd")}T23:59`;
};

const toLocalDateTimeHours = (date: Date, hour: number, minute?: number): string => {
  const hourString = prependZeroNumber(hour);
  const minuteString = minute ? prependZeroNumber(minute) : "00";
  if (hourString === "24") {
    return createEndOfDayDate(date, false);
  }
  return `${format(date, "yyyy-MM-dd")}T${hourString}:${minuteString}`;
};

const toLocalDateTimeFull = (date: Date, hour?: number, minute?: number): string => {
  const hourString = hour ? hour.toString().padStart(2, "0") : "00";
  const minuteString = minute ? minute.toString().padStart(2, "0") : "00";
  if (hourString === "24") {
    return createEndOfDayDate(date);
  }
  return `${format(date, "yyyy-MM-dd")}T${hourString}:${minuteString}:00`;
};

const toLocalDateTime = (date: Date): string => {
  return format(date, "yyyy-MM-dd HH:mm:ss").replace(" ", "T");
};

const addMinutes = (numOfMinutes: number, date: Date): Date => {
  date.setMinutes(date.getMinutes() + numOfMinutes);
  return date;
};

const removeMinutes = (numOfMinutes: number, date: Date): Date => {
  date.setMinutes(date.getMinutes() - numOfMinutes);
  return date;
};

const toIsoWithNoOffset = (date: Date): string => {
  return toLocalDateTime(date).concat(".000Z");
};

const dateStringToShowTime = (date: string): string => {
  return format(parseISO(date), "HH:mm");
};

const dateStringToShowDate = (date: string, dateFormat = "dd.MM.yyyy"): string => {
  return format(new Date(date), dateFormat);
};

const isSameDay = (dateOne: string, dateTwo: string): boolean => {
  return setDateHoursToZero(new Date(dateOne)) === setDateHoursToZero(new Date(dateTwo));
};

const dateStringToShowDateLocal = (date: string, timezone = "UTC", displayFormat = "dd.MM.yyyy HH:mm"): string => {
  return format(new Date(utcToZonedTime(zonedTimeToUtc(date, timezone), localTimezone)), displayFormat);
};

const isBeforeToday = (date?: string): boolean | undefined => {
  return date == null ? undefined : new Date(date) <= new Date();
};

const formatDateString = (date?: string): string | undefined => {
  if (date == null) {
    return undefined;
  }
  const parsedDate = parseISO(date);
  if (parsedDate?.getHours() && parsedDate?.getMinutes()) {
    return format(parsedDate, "dd.MM.yyyy HH:mm");
  }
  return format(parsedDate, "dd.MM.yyyy");
};

const toLocalOrDefault = (timestamp: string | undefined, defaultValue: string): string => {
  if (timestamp == null) {
    return defaultValue;
  }
  const date = moment(timestamp, moment.ISO_8601, true);
  if (date.isValid()) {
    return toLocalDateTime(new Date(timestamp.replaceAll(/[zZ]/g, "")));
  }
  return defaultValue;
};

export {
  dateLocale,
  setDateHoursToZero,
  toLocalDateTimeHours,
  toLocalDateTimeFull,
  toLocalDateTime,
  dateStringToShowTime,
  dateStringToShowDate,
  isSameDay,
  toIsoWithNoOffset,
  dateStringToShowDateLocal,
  getLocalTimezone,
  isBeforeToday,
  removeMinutes,
  formatDateString,
  toLocalOrDefault,
  addMinutes,
  HOUR_SEPARATOR
};
