<template>
  <div class="w-64 h-28 p-2" slot="popover">
    <div v-html="$t('popup.dropChallenge', { date: plannedDate })" />
    <div class="w-full mt-4">
      <div class="flex justify-center">
        <Button @click="onCancel" class="CalloutLegDrop__button--cancel" variant="primary h-7 w-24 p-0 mr-4">
          {{ $t("actions.cancel") }}
        </Button>
        <Button @click="onAllow" class="CalloutLegDrop__button--allow" variant="secondary h-7 w-24 p-0">
          {{ $t("actions.allow") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

import Button from "@/components/Buttons/Button.vue";

export default defineComponent({
  name: "CalloutLegDrop",
  components: {
    Button
  },
  props: {
    plannedDate: {
      type: String,
      default: ""
    },
    actualDate: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const onCancel = () => {
      emit("dropCancelled");
    };
    const onAllow = () => {
      emit("dropAllowed");
    };

    return {
      onCancel,
      onAllow
    };
  }
});
</script>

<style scoped lang="scss"></style>
