<template>
  <a-popover
    v-model="isPopoverVisible"
    trigger="click"
    class="popover"
    :destroyTooltipOnHide="true"
    :align="align"
    :placement="placement"
    :overlayStyle="overlayStyle"
    :defaultVisible="defaultVisible"
  >
    <template #title>
      <div class="popover__header">
        <div class="close-btn ml-auto w-max mt-1" @click="closePopover">
          <Icon tabindex="0" name="close" class="cursor-pointer text-black hover:text-yellow-500" />
        </div>
      </div>
    </template>
    <template #content>
      <div v-click-outside="onClickOutside"></div>
      <slot name="popoverContent"></slot>
    </template>
    <div>
      <slot name="popoverTrigger"></slot>
    </div>
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import ClickOutside from "vue-click-outside";

export default defineComponent({
  name: "Popover",
  components: { Icon },
  directives: {
    ClickOutside
  },
  props: {
    align: {
      type: Object,
      default: () => ({})
    },
    defaultVisible: {
      type: Boolean,
      default: false
    },
    placement: String,
    overlayStyle: Object
  },
  setup() {
    const isPopoverVisible = ref(false);

    const closePopover = (): void => {
      isPopoverVisible.value = false;
    };

    const onClickOutside = (event): void => {
      const targetPopOverElement = event.target.closest(".ant-popover");
      const targetLegElement = event.target.closest(".brick");
      const hasLegCalloutClass = event.target.classList.contains("leg_callout");
      if (
        !targetPopOverElement &&
        targetLegElement &&
        isPopoverVisible.value === true &&
        hasLegCalloutClass === false
      ) {
        closePopover();
      }
    };

    return {
      isPopoverVisible,
      closePopover,
      onClickOutside
    };
  }
});
</script>

<style scoped></style>
