import { GET_CHATS } from "@/store/chat";
import { computed } from "@vue/composition-api";
import store from "@/store";
import { Chat } from "@/types/chat";
import { GET_SELECTED_BOARD_IDS } from "@/store/board";

const chats = computed(() => store.getters[GET_CHATS]);

const useMessages = (bookingId?: string): number => {
  const chatToCheck = chats.value.find((chat: Chat) => chat.bookingId === bookingId);
  return chatToCheck?.unreadCount || 0;
};

const filterByBoard = (chat: Chat): boolean => {
  const boardIds = store.getters[GET_SELECTED_BOARD_IDS];

  const isBoardIncluded =
    chat.boardIds.length === 1
      ? boardIds.includes(chat.boardIds[0])
      : chat.boardIds.find(boardIdd => boardIds.includes(boardIdd));

  return isBoardIncluded;
};

const filterByChats = (localChats: Chat[], updatedChat: Chat): Chat | null => {
  const outdatedChatIndex = localChats.findIndex(chat => chat.bookingId === updatedChat.bookingId);
  if (outdatedChatIndex !== -1) {
    return localChats[outdatedChatIndex];
  } else return null;
};

export { useMessages, filterByBoard, filterByChats };
