<template>
  <div class="w-full">
    <div v-if="loading" class="flex justify-center">
      <icon name="spinner" class="text-btYellow" />
    </div>
    <div v-else>
      <div class="calloutLeg" slot="popover">
        <div class="calloutLeg__header">
          <div class="border-b-2 font-semibold mb-2 text-gray-500">
            <span>{{ title }}</span>

            <span
              v-if="container.metadata && container.metadata.markedForRepair"
              class="font-normal text-red-400 ml-6"
              >{{ $t("leg.container.markedForRepair") }}</span
            >
          </div>
        </div>
      </div>
      <div class="popover-body">
        <table class="calloutLeg__table table-fixed w-full mb-6">
          <thead>
            <tr>
              <th class="w-1/3">{{ $t("leg.container.length") }}</th>
              <th class="w-1/3">{{ $t("leg.container.chamberVolume") }}</th>
              <th class="w-1/3">{{ $t("leg.container.tareWeight") }}</th>
            </tr>
          </thead>
          <tbody class="font-light align-top">
            <tr>
              <td>
                <span v-if="container.metadata.length">{{ container.metadata.length }} cm</span>
              </td>
              <td>
                <div v-if="container.metadata.chamberVolumes">
                  {{ chamberVolumes }}
                </div>
              </td>
              <td>
                <span v-if="container.metadata.tareWeight">{{ container.metadata.tareWeight }} kg</span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="calloutLeg__table table-fixed w-full mb-6">
          <thead>
            <tr>
              <th class="w-1/3">{{ $t("leg.container.nextTest") }}</th>
              <th class="w-1/3">{{ $t("leg.container.specialEquipment") }}</th>
              <th class="w-1/3">{{ $t("leg.container.acceptance") }}</th>
            </tr>
          </thead>
          <tbody class="font-light align-top">
            <tr>
              <td>
                <div v-if="nextAdrTest">ADR: {{ nextAdrTest }}</div>
                <div v-if="container.metadata.nextCscTest">
                  CSC: {{ dateStringToShowDate(container.metadata.nextCscTest) }}
                </div>
                <div v-if="container.metadata.nextAcepTest">
                  ACEP: {{ dateStringToShowDate(container.metadata.nextAcepTest) }}
                </div>
              </td>
              <td>
                <span>{{ specialEquipment }}</span>
              </td>
              <td>
                <span v-if="container.metadata.acceptance">{{ container.metadata.acceptance }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="calloutLeg__table table-fixed w-full mb-6">
          <thead>
            <tr>
              <th class="w-1/3">{{ $t("leg.container.tankCode") }}</th>
              <th class="w-1/3">{{ $t("leg.container.dedicationGroup") }}</th>
              <th class="w-1/3">{{ $t("leg.container.nexiotLink") }}</th>
            </tr>
          </thead>
          <tbody class="font-light align-top">
            <tr>
              <td>
                <span v-if="container.metadata.tankCode">{{ container.metadata.tankCode }}</span>
              </td>
              <td>
                <span v-if="container.metadata.dedicatedProductGroup">{{
                  container.metadata.dedicatedProductGroup
                }}</span>
              </td>
              <td>
                <div v-if="container.metadata.nexxiotLink" class="mr-2 overflow-hidden">
                  <a target="_blank" :href="container.metadata.nexxiotLink">
                    <Icon name="link" height="18" width="18" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent, onMounted } from "@vue/composition-api";
import { dateStringToShowDate } from "@/use/useDate";
import CopyToClipboard from "@/components/CopyToClipboard.vue";
import Icon from "@/components/Icon.vue";
import { i18n } from "@/i18n/i18n";
import { BaffleTypes, DipTubeTypes, GroundOperatedContainerTypes, IsoDimensionTypes } from "@/types/container";
import { LegContainer } from "@/types/leg";
import { Container, Trailer } from "@/types/fleet";
import containerService from "@/services/containerService";
import { getLastLocationContainerId } from "@/use/useLastLocation";

export default defineComponent({
  name: "CalloutLegContainer",
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: Object as () => LegContainer | Trailer, //LastLocationInfo,
      required: true
    }
  },
  components: { CopyToClipboard, Icon },
  setup(props) {
    const container = ref<Container | LegContainer | undefined>(undefined);

    const loading = ref<boolean>(true);

    onMounted(() => {
      loading.value = true;
      const containerId =
        (props.content as LegContainer)?.containerId ||
        getLastLocationContainerId((props.content as Trailer)?.metadata?.lastLocation);
      if (props.content && containerId) {
        getContainer(containerId);
      } else {
        container.value =
          ((props.content as Trailer)?.metadata?.lastLocation?.leg?.container as LegContainer) ||
          (props?.content as LegContainer);
        loading.value = false;
      }
    });

    const getContainer = async (containerId: string) => {
      try {
        const data = await containerService.getSingleContainer(containerId);
        container.value = data.data;
        loading.value = false;
      } catch (e) {
        // unhandled global
        loading.value = false;
      }
    };

    const hasBaffles = (baffles?: string): boolean => {
      return baffles != null && baffles !== BaffleTypes.NO;
    };

    const hasGroundOperatedContainer = (goc?: string): boolean => {
      return goc != null && goc !== GroundOperatedContainerTypes.NO;
    };

    const hasIsoDimension = (iso?: string): boolean => {
      return iso != null && iso !== IsoDimensionTypes.NO;
    };

    const hasHeating = (heating?: string): boolean => {
      return !!heating;
    };

    const hasDipTube = (dipTube?: string): boolean => {
      return dipTube != null && dipTube !== DipTubeTypes.NO;
    };

    const hasAnchorChain = (anchorChain?: string): boolean => {
      return !!anchorChain;
    };

    const getBafflesInfo = (): string => {
      return i18n.t("leg.container.baffles").toString();
    };

    const getGroundOperatedContainerInfo = (goc: string): string => {
      if (goc === GroundOperatedContainerTypes.GO1) return GroundOperatedContainerTypes.GOC1;
      if (goc === GroundOperatedContainerTypes.GO2) return GroundOperatedContainerTypes.GOC2;
      return GroundOperatedContainerTypes.GOC;
    };

    const getHeatingInfo = (heatingMethod: string): string => {
      return i18n.t("leg.container.heatingMethod", { method: heatingMethod }).toString();
    };

    const getDipTubeInfo = (dipTube: string): string => {
      if (dipTube === DipTubeTypes.YES) {
        return i18n.t("leg.container.dipTube").toString();
      }
      if (dipTube === DipTubeTypes.POSSIBLE) {
        return i18n.t("leg.container.dipTubePossible").toString();
      }
      return "";
    };

    const getIsoDimensionInfo = (): string => {
      return IsoDimensionTypes.GLISO;
    };

    const getAnchorChainInfo = (): string => {
      return i18n.t("leg.container.anchorChain").toString();
    };

    const specialEquipment = computed<string>(() => {
      const equipment: string[] = [];
      const { baffles, groundOperatedContainer, heatingMethod, dipTube, isoDimension, anchorChain } =
        container?.value?.metadata || {};

      if (hasBaffles(baffles)) {
        equipment.push(getBafflesInfo());
      }

      if (hasGroundOperatedContainer(groundOperatedContainer)) {
        equipment.push(getGroundOperatedContainerInfo(groundOperatedContainer!));
      }

      if (hasHeating(heatingMethod)) {
        equipment.push(getHeatingInfo(heatingMethod!));
      }

      if (hasDipTube(dipTube)) {
        equipment.push(getDipTubeInfo(dipTube!));
      }

      if (hasIsoDimension(isoDimension)) {
        equipment.push(getIsoDimensionInfo());
      }

      if (hasAnchorChain(anchorChain)) {
        equipment.push(getAnchorChainInfo());
      }

      return equipment.join(", ");
    });

    const chamberVolumes = computed(() => {
      const { chamberVolumes = [] } = container?.value?.metadata || {};
      return chamberVolumes.map(vol => `${vol} l`).join(", ");
    });

    const nextAdrTest = computed<string | undefined>(() => {
      const { nextAdrTest, nextAdrMinorTest } = container?.value?.metadata || {};
      if (nextAdrTest || nextAdrMinorTest) {
        return dateStringToShowDate(nextAdrTest! || nextAdrMinorTest!);
      }
      return undefined;
    });

    return { dateStringToShowDate, specialEquipment, chamberVolumes, nextAdrTest, container, loading };
  }
});
</script>

<style scoped lang="scss">
@use "CalloutLeg";
</style>
