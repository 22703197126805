var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { tabindex: "0" } },
    [
      _c(
        "a-popover",
        {
          staticClass: "cursor-pointer",
          attrs: { placement: "bottomRight", trigger: "click" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.$t("settings.title")) + " ")]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [
                  _c("nav", { staticClass: "menu flex justify-center" }, [
                    _c("ul", { staticClass: "flex flex-col justify-center" }, [
                      _c("li", { on: { click: _vm.closePopover } }, [
                        _c(
                          "a",
                          {
                            staticClass: "flex items-center block",
                            attrs: {
                              tabindex: "0",
                              target: "_blank",
                              href:
                                "https://intranet/?App=ContentManager3&action=getPageContent&pageid=13140"
                            }
                          },
                          [
                            _c("Icon", {
                              staticClass: "mr-1",
                              attrs: { name: "help", height: "18", width: "18" }
                            }),
                            _vm._v(_vm._s(_vm.$t("helpMenu")))
                          ],
                          1
                        )
                      ]),
                      _c(
                        "li",
                        { on: { click: _vm.closePopover } },
                        [
                          _c("board-settings-button", {
                            on: { showBoardSettings: _vm.showBoardSettings }
                          })
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { on: { click: _vm.closePopover } },
                        [_c("logout-button")],
                        1
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isPopoverVisible,
            callback: function($$v) {
              _vm.isPopoverVisible = $$v
            },
            expression: "isPopoverVisible"
          }
        },
        [
          _c("Icon", {
            staticClass: "hover:text-btYellow",
            attrs: { name: "3dots" }
          })
        ],
        1
      ),
      _vm.boardSettingsActive
        ? _c("nav-settings", {
            attrs: { isActive: _vm.boardSettingsActive },
            on: { boardSettingsClosed: _vm.closeBoardSettings }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }