import { BoardLeg, BrickSubcontractor } from "@/types/leg";
import { IdEntity } from "@/types/types";
import { BoardFleetAction } from "@/types/action";

interface Board {
  id: string;
  name: string;
}

interface BoardTimespan {
  from?: string;
  to?: string;
}

type StringNumberMap = {
  [key: string]: number;
};

interface BoardFlexible {
  legs: BoardLeg[];
  actions: BoardFleetAction[];
  fleetSpans: StringNumberMap;
}

interface BoardDetails {
  boardFromDate: Date;
  boardToDate: Date;
  boardFromTime: number;
  boardToTime: number;
  hourBoxWidth: number;
  boardWidth: number;
  hourBoxesPerDay: number;
}

interface BoardPositionable {
  xCoord: number;
  yCoord: number;
  width: number;
}

interface BoardBrickPosition {
  left: number;
  top: number;
  right: number;
  width: number;
}

interface BoardBrick extends IdEntity<string>, BoardPositionable {
  boards?: string[];
  truckId?: string | null;
  driverId?: string | null;
  trailerId?: string | null;
  subcontractor?: BrickSubcontractor | null;
  effectiveStartTime?: string;
  effectiveEndTime?: string;
  updatable: boolean;
  updated?: boolean;
  dropped?: boolean;
  selected?: boolean;
  brickType?: BoardBrickType;
  isDuplicate?: boolean;
}

interface BoardBrickCoordinates {
  id: string;
  x: number;
  y: number;
  width: number;
}

interface SubcontractorBoard {
  id: string;
  sequence: number;
}

interface TransportInfoStatus {
  type?: string;
  value?: string;
}

type CompositeBrickId = {
  id: string;
  index?: string;
};

interface DefaultDateDaysPayload {
  daysFromTodayStart: number | null;
  daysFromTodayEnd: number | null;
}

const dayHourSettings = {
  DAY_START: 0,
  DAY_END: 24,
  DEFAULT_DAY_START: 5,
  DEFAULT_DAY_END: 20
};

enum BoardBrickType {
  ACTION = "action",
  LEG = "leg"
}

enum BoardEntityType {
  ACTION = "A",
  LEG = "L",
  FLEET = "F"
}

interface BoardOccupancy {
  blocks: OccupancyBlock[];
  byOtherBoard: boolean;
  byScheduledShifts: boolean;
}

interface OccupancyBlock {
  startXCoord: number;
  width: number;
}

export {
  Board,
  BoardFlexible,
  StringNumberMap,
  BoardDetails,
  BoardBrick,
  BoardPositionable,
  BoardBrickCoordinates,
  TransportInfoStatus,
  CompositeBrickId,
  DefaultDateDaysPayload,
  SubcontractorBoard,
  BoardBrickPosition,
  dayHourSettings,
  BoardBrickType,
  OccupancyBlock,
  BoardTimespan,
  BoardOccupancy,
  BoardEntityType
};
