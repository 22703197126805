var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-72" },
    [
      _c(
        "a-config-provider",
        { attrs: { locale: _vm.locale } },
        [
          _c("a-range-picker", {
            attrs: {
              id: "board-header-date-picker",
              inputReadOnly: "",
              size: "large",
              format: "DD.MM.YYYY",
              dropdownClassName: "z-50",
              separator: "-",
              "allow-clear": false,
              "disabled-date": _vm.disableWeekends,
              getCalendarContainer: function(trigger) {
                return trigger.parentNode
              }
            },
            on: {
              change: _vm.updateDates,
              openChange: _vm.onOpenChange,
              calendarChange: _vm.onCalendarChange
            },
            scopedSlots: _vm._u([
              {
                key: "suffixIcon",
                fn: function() {
                  return [_c("a-icon", { attrs: { type: "calendar" } })]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }