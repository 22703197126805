var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "calloutLeg" }, [
      _c("div", { staticClass: "calloutLeg__header " }, [
        _c(
          "div",
          {
            staticClass:
              "border-b-2 font-semibold mb-2 border-yellow-900 text-yellow-900"
          },
          [_vm._v(_vm._s(_vm.title))]
        )
      ])
    ]),
    _c("div", { staticClass: "popover-body" }, [
      _vm.trailerData
        ? _c("div", [
            _c(
              "table",
              { staticClass: "calloutLeg__table table-fixed w-full mb-6" },
              [
                _vm._m(0),
                _c("tbody", { staticClass: "font-light align-top" }, [
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.trailerData.metadata.chassisType))
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.trailerData.metadata.suitableFor))
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.trailerData.metadata.tareWeight) + " kg"
                      )
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "table",
              { staticClass: "calloutLeg__table table-fixed w-full mb-6" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v("Gross weight")
                    ]),
                    _c("th", { staticClass: "w-1/3" }, [_vm._v("Next test")]),
                    _c("th", { staticClass: "w-1/3" }, [
                      _vm._v(_vm._s(_vm.$t("truck.equipment")))
                    ])
                  ])
                ]),
                _c("tbody", { staticClass: "font-light align-top" }, [
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.trailerData.metadata.grossWeight) + " kg"
                      )
                    ]),
                    _vm.nextInspectionDate
                      ? _c(
                          "td",
                          {
                            class: {
                              "text-btRed": _vm.nextInspectionDate.isDateInPast
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.nextInspectionDate.date) + " "
                            )
                          ]
                        )
                      : _c("td"),
                    _c("td", { staticClass: "capitalize" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.stringifyTrailerEquipment(
                              _vm.trailerData.metadata
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        : _c(
            "div",
            { staticClass: "flex justify-center items-center" },
            [
              _c("Icon", {
                staticClass: "text-btYellow",
                attrs: { name: "spinner", height: "60", width: "60" }
              })
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-1/3" }, [_vm._v("Type")]),
        _c("th", { staticClass: "w-1/3" }, [_vm._v("Suitable for")]),
        _c("th", { staticClass: "w-1/3" }, [_vm._v("Tare weight")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }