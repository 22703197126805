<template>
  <div v-if="driverData">
    <div class="border-b-2 font-semibold mb-2 border-blue-300 text-blue-300">
      {{ `${driverData.firstName} ${driverData.lastName}` }}
    </div>
    <div class="popover-body">
      <table class="table-fixed w-full mb-6">
        <thead>
          <tr>
            <th class="w-1/3 font-thin">{{ $t("driver.phoneNumber") }}</th>
          </tr>
        </thead>
        <tbody class="font-bold align-top">
          <tr>
            <td>{{ driverData.phoneNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else class="flex justify-center items-center">
    <Icon name="spinner" class="text-btYellow" height="60" width="60" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store";
import { GET_DRIVER_BY_ID, FETCH_DRIVER } from "@/store/fleet";

export default defineComponent({
  name: "CalloutLegDriver",
  props: {
    driverId: {
      type: String,
      default: "",
      required: true
    }
  },
  components: { Icon },
  setup(props) {
    const driverData = computed(() => {
      const { driverId } = props;
      const driver = store.getters[GET_DRIVER_BY_ID](driverId);
      if (driver == null) {
        store.dispatch(FETCH_DRIVER, driverId);
      }
      return driver;
    });

    return {
      driverData
    };
  }
});
</script>
