<template>
  <div>
    <div class="calloutLeg">
      <div class="calloutLeg__header ">
        <div class="border-b-2 font-semibold mb-2 border-yellow-900 text-yellow-900">{{ title }}</div>
      </div>
    </div>
    <div class="popover-body">
      <div v-if="trailerData">
        <table class="calloutLeg__table table-fixed w-full mb-6">
          <thead>
            <tr>
              <th class="w-1/3">Type</th>
              <th class="w-1/3">Suitable for</th>
              <th class="w-1/3">Tare weight</th>
            </tr>
          </thead>
          <tbody class="font-light align-top">
            <tr>
              <td>{{ trailerData.metadata.chassisType }}</td>
              <td>{{ trailerData.metadata.suitableFor }}</td>
              <td>{{ trailerData.metadata.tareWeight }} kg</td>
            </tr>
          </tbody>
        </table>

        <table class="calloutLeg__table table-fixed w-full mb-6">
          <thead>
            <tr>
              <th class="w-1/3">Gross weight</th>
              <th class="w-1/3">Next test</th>
              <th class="w-1/3">{{ $t("truck.equipment") }}</th>
            </tr>
          </thead>
          <tbody class="font-light align-top">
            <tr>
              <td>{{ trailerData.metadata.grossWeight }} kg</td>
              <td
                v-if="nextInspectionDate"
                :class="{
                  'text-btRed': nextInspectionDate.isDateInPast
                }"
              >
                {{ nextInspectionDate.date }}
              </td>
              <td v-else></td>
              <td class="capitalize">
                {{ stringifyTrailerEquipment(trailerData.metadata) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="flex justify-center items-center">
        <Icon name="spinner" class="text-btYellow" height="60" width="60" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "@vue/composition-api";
import { Trailer } from "@/types/fleet";
import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import fleetService from "@/services/fleetService";
import store from "@/store";
import { GET_TRAILER_BY_ID } from "@/store/fleet";
import { stringifyTrailerEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "CalloutLegTrailer",
  components: { Icon },
  props: {
    title: {
      type: String,
      default: ""
    },
    trailerId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const trailerData = ref<Trailer | null>(null);

    const trailerDataFromStore = computed(async () => {
      return await store.dispatch(GET_TRAILER_BY_ID, props.trailerId);
    });
    onMounted(async () => {
      trailerData.value = await trailerDataFromStore.value;
      if (trailerData.value == null) {
        getTrailerData();
      }
    });

    const getTrailerData = async () => {
      try {
        const { data } = await fleetService.getTrailerById(props.title);
        trailerData.value = data;
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const nextInspectionDate = computed(() => getNextInspectionDate(trailerData.value!.metadata));

    return {
      getNextInspectionDate,
      stringifyTrailerEquipment,
      trailerData,
      trailerDataFromStore,
      nextInspectionDate
    };
  }
});
</script>

<style scoped lang="scss">
@use "CalloutLeg";
</style>
