import { HOUR_SEPARATOR, toLocalDateTimeFull, toLocalOrDefault } from "@/use/useDate";
import { BoardDetails, BoardOccupancy, OccupancyBlock } from "@/types/board";
import { BoardMembership, FleetSchedule } from "@/types/fleet";
import { deriveBrickWidth, getEventXBoardCoordinate } from "@/store/brick/useBrick";
import { timespanFitsBoard } from "@/store/board/useBoard";
import { addDays, eachDayOfInterval, endOfDay, startOfDay } from "date-fns";

const deriveOccupancyBlock = (
  entityMembership: BoardMembership,
  boardDetails: BoardDetails,
  includeWeekends: boolean
): OccupancyBlock => {
  const { from, to } = entityMembership;
  const { boardFromDate, boardFromTime, boardToTime, hourBoxesPerDay, hourBoxWidth, boardWidth } = boardDetails;
  const startXCoord = getEventXBoardCoordinate(
    from!,
    boardFromDate,
    boardFromTime,
    boardToTime,
    hourBoxesPerDay,
    hourBoxWidth,
    boardWidth,
    includeWeekends
  );
  const endXCoord = getEventXBoardCoordinate(
    to!,
    boardFromDate,
    boardFromTime,
    boardToTime,
    hourBoxesPerDay,
    hourBoxWidth,
    boardWidth,
    includeWeekends
  );
  const width = deriveBrickWidth(startXCoord, endXCoord, hourBoxWidth);

  return {
    startXCoord,
    width
  };
};

const mergeOccupancyBlocks = (occupancyBlocks: OccupancyBlock[]): OccupancyBlock[] => {
  const blocks = [...occupancyBlocks.sort((b1, b2) => b2.startXCoord - b1.startXCoord)];

  for (let i = blocks.length - 1; i > 0; i--) {
    const { startXCoord: currentX, width: currentW } = blocks[i];
    const { startXCoord: nextX, width: nextW } = blocks[i - 1];
    const currentX1 = currentX + currentW;
    const nextX1 = nextX + nextW;

    if (currentX < nextX1 && currentX1 > nextX) {
      const newStartXCoord = Math.min(currentX, nextX);
      const newEndXCoord = Math.max(currentX1, nextX1);
      blocks[i - 1] = {
        startXCoord: newStartXCoord,
        width: newEndXCoord - newStartXCoord
      };
      blocks.splice(i, 1);
    }
  }

  return blocks;
};

const splitOccupancyBlock = (block: OccupancyBlock, freeBlock: OccupancyBlock): OccupancyBlock[] => {
  const { startXCoord: blockX, width } = block;
  const blockX1 = blockX + width;

  const { startXCoord: freeX, width: freeWidth } = freeBlock;
  const freeX1 = freeX + freeWidth;

  if (freeX <= blockX && freeX1 >= blockX1) {
    return [];
  }

  if (freeX > blockX && freeX1 < blockX1) {
    return [
      {
        startXCoord: blockX,
        width: freeX - blockX
      },
      {
        startXCoord: freeX1,
        width: blockX1 - freeX1
      }
    ];
  }

  if (freeX <= blockX && freeX1 >= blockX && freeX1 < blockX1) {
    return [
      {
        startXCoord: freeX1,
        width: blockX1 - freeX1
      }
    ];
  }

  if (freeX > blockX && freeX <= blockX1 && freeX1 >= blockX1) {
    return [
      {
        startXCoord: blockX,
        width: freeX - blockX
      }
    ];
  }

  return [block];
};

const toOccupancyBlocksIfFitTheBoard = (
  entityMemberships: BoardMembership[],
  boardDetails: BoardDetails,
  includeWeekends: boolean
): OccupancyBlock[] => {
  const { boardFromDate, boardFromTime, boardToDate, boardToTime } = boardDetails;
  const boardFrom = toLocalDateTimeFull(boardFromDate, boardFromTime);
  const boardTo = toLocalDateTimeFull(boardToDate, boardToTime);

  const blocks = entityMemberships
    .map(membership => ({
      boardId: membership.boardId,
      from: toLocalOrDefault(membership.from, boardFrom),
      to: toLocalOrDefault(membership.to, boardTo)
    }))
    .filter(membership => timespanFitsBoard(membership, boardDetails, includeWeekends))
    .map(membership => deriveOccupancyBlock(membership, boardDetails, includeWeekends));

  return mergeOccupancyBlocks(blocks);
};

const toScheduledBoardMembership = (
  fleetSchedule: FleetSchedule,
  boardDetails: BoardDetails,
  boardIds: string[]
): BoardMembership[] => {
  const { fromDate, startTime, toDate, endTime } = fleetSchedule;
  const { boardFromDate, boardFromTime, boardToDate, boardToTime } = boardDetails;
  const [startH, startM] = startTime.split(HOUR_SEPARATOR);
  const [endH, endM] = endTime.split(HOUR_SEPARATOR);
  const startHour = +startH;
  const startMinute = +startM;
  const endHour = +endH;
  const endMinute = +endM;
  const boardFrom = toLocalDateTimeFull(boardFromDate, boardFromTime);
  const boardTo = toLocalDateTimeFull(boardToDate, boardToTime);

  const startTimestamp = toLocalOrDefault(fromDate, boardFrom);
  const endTimestamp = toLocalOrDefault(toDate, boardTo);

  const startDate = startOfDay(new Date(startTimestamp));
  const endDate = endOfDay(new Date(endTimestamp));
  const boardFromFullDate = new Date(boardFrom);
  const boardToFullDate = new Date(boardTo);

  return eachDayOfInterval({
    start: startDate < boardFromFullDate ? boardFromFullDate : startDate,
    end: endDate > boardToFullDate ? boardToFullDate : endDate
  }).flatMap(date => {
    if (endHour <= startHour) {
      return {
        boardId: boardIds[0],
        from: toLocalDateTimeFull(date, startHour, startMinute),
        to: toLocalDateTimeFull(addDays(date, 1), endHour, endMinute)
      };
    }
    if (endHour === 23 && endMinute === 59) {
      return {
        boardId: boardIds[0],
        from: toLocalDateTimeFull(date, startHour, startMinute),
        to: toLocalDateTimeFull(addDays(date, 1), 0, 0)
      };
    }
    return {
      boardId: boardIds[0],
      from: toLocalDateTimeFull(date, startHour, startMinute),
      to: toLocalDateTimeFull(date, endHour, endMinute)
    };
  });
};

const toScheduledBoardMemberships = (
  fleetSchedules: FleetSchedule[],
  boardDetails: BoardDetails,
  boardIds: string[]
): BoardMembership[] =>
  fleetSchedules.flatMap(schedule => toScheduledBoardMembership(schedule, boardDetails, boardIds));

const dissectBoardMembershipBlocks = (
  occupiedMemberships: BoardMembership[],
  freeMemberships: BoardMembership[],
  boardDetails: BoardDetails,
  boardIds: string[],
  includeWeekends: boolean
) => {
  const occupiedBlocks = toOccupancyBlocksIfFitTheBoard(occupiedMemberships, boardDetails, includeWeekends);
  const freeBlocks = toOccupancyBlocksIfFitTheBoard(freeMemberships, boardDetails, includeWeekends);

  if (freeBlocks.length > 0) {
    for (let i = occupiedBlocks.length - 1; i >= 0; i--) {
      for (let j = 0; j < freeBlocks.length; j++) {
        if (occupiedBlocks[i] == null) {
          continue;
        }
        const blockSplits = splitOccupancyBlock(occupiedBlocks[i], freeBlocks[j]);
        occupiedBlocks.splice(i, 1, ...blockSplits);
      }
    }
  }
  return occupiedBlocks;
};

const deriveBoardOccupancy = (
  boardIds: string[],
  boardDetails: BoardDetails,
  includeWeekends: boolean,
  entityMemberships: BoardMembership[],
  fleetSchedules: FleetSchedule[]
): BoardOccupancy => {
  const { foreignMemberships, boardMemberships } = entityMemberships.reduce(
    (acc, membership) => {
      if (!boardIds.includes(membership.boardId)) {
        acc.foreignMemberships = [...acc.foreignMemberships, membership];
      } else {
        acc.boardMemberships = [...acc.boardMemberships, membership];
      }
      return acc;
    },
    {
      foreignMemberships: [] as BoardMembership[],
      boardMemberships: [] as BoardMembership[]
    }
  );

  const occupiedBlocks = dissectBoardMembershipBlocks(
    foreignMemberships,
    boardMemberships,
    boardDetails,
    boardIds,
    includeWeekends
  );

  if (fleetSchedules.length > 0) {
    const { boardFromDate, boardFromTime, boardToDate, boardToTime } = boardDetails;
    const scheduledBoardMemberships = toScheduledBoardMemberships(fleetSchedules, boardDetails, boardIds);

    const occupiedScheduleBlocks = dissectBoardMembershipBlocks(
      [
        {
          boardId: boardIds[0],
          from: toLocalDateTimeFull(boardFromDate, boardFromTime),
          to: toLocalDateTimeFull(boardToDate, boardToTime)
        }
      ],
      scheduledBoardMemberships,
      boardDetails,
      boardIds,
      includeWeekends
    );

    return {
      blocks: mergeOccupancyBlocks([...occupiedBlocks, ...occupiedScheduleBlocks]),
      byOtherBoard: occupiedBlocks.length > 0,
      byScheduledShifts: occupiedScheduleBlocks.length > 1
    };
  }

  return {
    blocks: occupiedBlocks,
    byOtherBoard: occupiedBlocks.length > 0,
    byScheduledShifts: false
  };
};

export { deriveBoardOccupancy };
