import "@/plugins/compositionApi";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/i18n/i18n";

//plugins
import { makeServer } from "./mocks/server";
import "@/plugins/svgIcon";
import "@/plugins/toasted";
import "@/plugins/antDesign";

// global CSS
import "./assets/styles/tailwind.scss";

//init mirage mock server
makeServer();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
