
















































































import { computed, defineComponent, onMounted, reactive, ref, PropType } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import { FleetActionFormData, FleetReminderFormData } from "@/types/action";
import FleetActionForm from "@/components/Board/Fleet/FleetAction/FleetForms/FleetActionForm.vue";
import FleetReminderForm from "@/components/Board/Fleet/FleetAction/FleetForms/FleetReminderForm.vue";
import FleetSelection from "@/components/Board/Fleet/FleetAction/FleetSelection/FleetSelection.vue";
import moment from "moment";
import { dateStringToShowDate, toLocalDateTimeFull } from "@/use/useDate";
import { FleetActionPayload, FleetAction } from "@/types/action";
import store from "@/store";
import {
  CREATE_FLEET_ACTIONS,
  DELETE_FLEET_ACTION,
  LOCK_FLEET_ACTION,
  UNLOCK_FLEET_ACTION,
  UPDATE_FLEET_ACTION
} from "@/store/brick";
import Dialog from "@/components/Dialog.vue";
import { i18n } from "@/i18n/i18n";
import { translateAndToastSuccess } from "@/use/useToast";
import { FleetEntityType } from "@/types/fleet";
import { spreadSubcontractorId, spreadTruckId } from "@/use/useFleet";
import { BrickSubcontractor } from "@/types/leg";

export default defineComponent({
  name: "FleetActionReminder",
  components: {
    SplitScreen,
    Button,
    FleetActionForm,
    FleetReminderForm,
    FleetSelection,
    Dialog
  },

  props: {
    fleetAction: {
      type: Object as PropType<FleetActionPayload>
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const fleetFormComponentRef = ref<any>(null);
    const isLoading = ref(false);
    const hasValidForm = computed(() => {
      return actionFormIsValid.value || reminderFormIsValid.value;
    });
    const isUpdate = computed(() => props.fleetAction?.id != null);

    /**
     * If updating the form
     */
    const isReminderUpdate = computed(() => isUpdate.value && props.fleetAction?.isReminder);
    const isActionUpdate = computed(() => isUpdate.value && !props.fleetAction?.isReminder);
    const action = computed((): FleetAction => (isReminderUpdate.value ? fleetReminderForm : fleetActionForm));

    const existingFormUpdateCounts = ref(0);
    const existingFormUpdated = computed(() => existingFormUpdateCounts.value > 2);

    const fleetMembers = computed(() => {
      const { truckId, trailerId, driverId, subcontractorId } = props.fleetAction!;
      return {
        truckId: truckId ? spreadTruckId(truckId).id : undefined,
        trailerId,
        driverId,
        subcontractorId
      };
    });

    const actionFormData = ref<FleetActionFormData>({});
    const reminderFormData = ref<FleetReminderFormData>({});

    const setFormInitState = (): void => {
      if (props.fleetAction == null) {
        return;
      }
      const { id, type, remarks, fromDate, toDate, fromLocation, toLocation } = props.fleetAction as FleetAction;
      const fromTimestamp = fromDate?.date;
      const toTimestamp = toDate?.date;

      if (props.fleetAction?.isReminder) {
        reminderFormData.value = {
          type,
          remarks,
          date: fromTimestamp != null ? dateStringToShowDate(fromTimestamp, "yyyy-MM-dd") : undefined,
          time: fromTimestamp,
          location: fromLocation
        };
        fleetReminderForm.id = id;
      }
      if (!props.fleetAction?.isReminder) {
        actionFormData.value = {
          type,
          remarks,
          fromDate: fromTimestamp != null ? dateStringToShowDate(fromTimestamp, "yyyy-MM-dd") : undefined,
          fromTime: fromTimestamp != null ? generateDateStringFromTimestamp(fromTimestamp) : undefined,
          toDate: toTimestamp != null ? dateStringToShowDate(toTimestamp, "yyyy-MM-dd") : undefined,
          toTime: toTimestamp != null ? generateDateStringFromTimestamp(toTimestamp) : undefined,
          fromLocation,
          toLocation
        };
        fleetActionForm.id = id;
      }
    };

    const generateDateStringFromTimestamp = (timestamp: string): string => {
      const timestampDate = new Date(timestamp);
      return toLocalDateTimeFull(new Date(), timestampDate.getHours(), timestampDate.getMinutes());
    };

    /**
     * Action form and handling
     */
    const fleetActionForm = reactive<FleetAction>({
      id: "",
      isReminder: false
    });
    const actionFormIsValid = ref(false);

    const updateActionForm = (actionFormValue: FleetActionFormData) => {
      const { type, remarks, fromDate, fromTime, toDate, toTime, fromLocation, toLocation } = actionFormValue;
      fleetActionForm.type = type;
      fleetActionForm.remarks = remarks;
      if (fromDate && fromTime) {
        fleetActionForm.fromDate = {
          date: toLocalDateTimeFull(moment(fromDate).toDate(), moment(fromTime).hour(), moment(fromTime).minute()),
          timezone: fromLocation && fromLocation.timezone ? fromLocation.timezone : "UTC"
        };
      }

      if (toDate && toTime) {
        fleetActionForm.toDate = {
          date: toLocalDateTimeFull(moment(toDate).toDate(), moment(toTime).hour(), moment(toTime).minute()),
          timezone: toLocation && toLocation.timezone ? toLocation.timezone : "UTC"
        };
      }

      if (fromLocation) {
        fleetActionForm.fromLocation = fromLocation;
      }
      if (toLocation) {
        fleetActionForm.toLocation = toLocation;
      }

      existingFormUpdateCounts.value++;
    };

    const setActionFormValidState = (isValid: boolean) => {
      actionFormIsValid.value = isValid;
    };
    const fleetActionKey = ref(Math.random());

    /**
     * Reminder form and handling
     */
    const fleetReminderForm = reactive<FleetAction>({
      id: "",
      isReminder: true
    });

    const reminderFormIsValid = ref(false);

    const updateReminderForm = (reminderFormValue: FleetReminderFormData) => {
      const { type, remarks, date, time, location } = reminderFormValue;
      fleetReminderForm.type = type;
      fleetReminderForm.remarks = remarks;
      if (location) {
        fleetReminderForm.fromLocation = location;
      }
      if (date && time) {
        fleetReminderForm.fromDate = {
          date: toLocalDateTimeFull(moment(date).toDate(), moment(time).hour(), moment(time).minute()),
          timezone: location && location.timezone ? location.timezone : "UTC"
        };
      }

      existingFormUpdateCounts.value++;
    };

    const setReminderFormValidState = (isValid: boolean) => {
      reminderFormIsValid.value = isValid;
    };

    const fleetReminderKey = ref(Math.random());

    /***
     * Event handling
     */
    const createActionAndClear = async () => {
      const { actionCreated, reminderCreated } = await createAction();
      if (actionCreated) {
        fleetActionKey.value = Math.random();
      }
      if (reminderCreated) {
        fleetReminderKey.value = Math.random();
      }
      actionFormIsValid.value = false;
      reminderFormIsValid.value = false;
      fleetFormComponentRef.value.$refs.splitScreen.scrollTo(0, 0);
    };

    const createActionAndClose = async () => {
      const { actionCreated, reminderCreated } = await createAction();
      if (actionCreated && reminderCreated) {
        closeFleetAction();
      }
    };

    const getActionSubcontractor = (): BrickSubcontractor | undefined => {
      const { id, boardId, sequence } =
        (selectedFleet.subcontractorId && spreadSubcontractorId(selectedFleet.subcontractorId)) || {};

      return id
        ? ({
            id,
            primaryBoard: {
              id: boardId,
              sequence
            }
          } as BrickSubcontractor)
        : undefined;
    };

    const createAction = async () => {
      const actionStatus = {
        actionCreated: true,
        reminderCreated: true
      };
      isLoading.value = true;
      const subcontractor = getActionSubcontractor();
      if (actionFormIsValid.value) {
        try {
          const actionRequest: FleetAction = {
            ...selectedFleet,
            ...fleetActionForm,
            subcontractor
          };
          await store.dispatch(CREATE_FLEET_ACTIONS, actionRequest);
          translateAndToastSuccess("actionReminder.actionCreated");
        } catch (e) {
          actionStatus.actionCreated = false;
        }
      }
      if (reminderFormIsValid.value) {
        try {
          const reminderRequest: FleetAction = {
            ...selectedFleet,
            ...fleetReminderForm,
            subcontractor
          };
          await store.dispatch(CREATE_FLEET_ACTIONS, reminderRequest);
          translateAndToastSuccess("actionReminder.reminderCreated");
        } catch (e) {
          actionStatus.reminderCreated = false;
        }
      }
      isLoading.value = false;
      return actionStatus;
    };

    const updateActionAndClose = async () => {
      const subcontractor = getActionSubcontractor();
      const payload: FleetAction = {
        ...selectedFleet,
        ...action.value,
        subcontractor
      };
      try {
        isLoading.value = true;
        await store.dispatch(UPDATE_FLEET_ACTION, payload);
        if (payload.isReminder) {
          translateAndToastSuccess("actionReminder.reminderUpdated");
        } else {
          translateAndToastSuccess("actionReminder.actionUpdated");
        }

        closeFleetAction();
      } catch (e) {
        //show not globally handled errors
      }
      isLoading.value = false;
    };

    /**
     * Handling delete of action
     */

    const deleteAction = async () => {
      try {
        await store.dispatch(DELETE_FLEET_ACTION, action.value.id);
        translateAndToastSuccess("states.itemRemoved");
        closeFleetAction(false);
      } catch (e) {
        //show not globally handled errors
      }
    };

    const openDeleteDialog = () => {
      deleteDialog.isActive = true;
    };

    const deleteDialog = reactive({
      isActive: false,
      title: i18n.t("popup.thinkAboutIt").toString(),
      message: i18n.t("popup.deleteAction").toString()
    });

    const closeDeleteDialog = () => {
      deleteDialog.isActive = false;
    };

    /**
     * Multiselect values and handling
     */
    const selectedFleet = reactive({
      driverId: props.fleetAction?.driverId,
      truckId: props.fleetAction?.truckId,
      trailerId: props.fleetAction?.trailerId,
      subcontractorId: props.fleetAction?.subcontractor?.id
    });

    const initFleet = () => {
      const { truckId, trailerId, driverId, subcontractorId } = fleetMembers.value;
      selectedFleet.truckId = truckId;
      selectedFleet.driverId = driverId;
      selectedFleet.trailerId = trailerId;
      selectedFleet.subcontractorId = subcontractorId;
    };

    const updateSelectedFleet = fleetItem => {
      if (fleetItem.type === FleetEntityType.TRUCK) {
        selectedFleet.truckId = fleetItem.id;
      }
      if (fleetItem.type === FleetEntityType.DRIVER) {
        selectedFleet.driverId = fleetItem.id;
      }
      if (fleetItem.type === FleetEntityType.TRAILER) {
        selectedFleet.trailerId = fleetItem.id;
      }
      if (fleetItem.type === FleetEntityType.SUBCONTRACTOR) {
        selectedFleet.subcontractorId = fleetItem.id;
      }

      if (isUpdate.value) {
        existingFormUpdateCounts.value++;
      }
    };

    const multipleSelectedFleets = computed(() => {
      if (!bannerConfirmed.value) {
        const selectedFleets = {
          truck: selectedFleet.truckId,
          driver: selectedFleet.driverId,
          trailer: selectedFleet.trailerId,
          subcontractor: selectedFleet.subcontractorId
        };
        return Object.values(selectedFleets).filter(value => value != null).length > 1;
      }
      return false;
    });

    /**
     * Lock unlock leg
     */
    const lockAction = async () => {
      try {
        await store.dispatch(LOCK_FLEET_ACTION, action.value.id);
      } catch {
        //handle not global errors
      }
    };
    const unlockAction = async () => {
      try {
        await store.dispatch(UNLOCK_FLEET_ACTION, action.value.id);
      } catch {
        //handle not global errors
      }
    };

    /**
     * Banner handling
     */
    const bannerConfirmed = ref(false);
    const alertConfirmed = () => {
      bannerConfirmed.value = true;
    };

    const closeFleetAction = (unlock = true) => {
      emit("fleetDetailClosed");
      if (isUpdate.value && unlock) {
        unlockAction();
      }
    };

    onMounted(() => {
      initFleet();
      setFormInitState();
      if (isUpdate.value) {
        lockAction();
      }
    });

    return {
      isActionUpdate,
      actionFormData,
      reminderFormData,
      isUpdate,
      isReminderUpdate,
      setActionFormValidState,
      updateActionForm,
      updateReminderForm,
      setReminderFormValidState,
      updateSelectedFleet,
      createActionAndClose,
      createActionAndClear,
      closeFleetAction,
      isLoading,
      actionFormIsValid,
      reminderFormIsValid,
      hasValidForm,
      fleetFormComponentRef,
      multipleSelectedFleets,
      selectedFleet,
      fleetActionKey,
      fleetReminderKey,
      alertConfirmed,
      fleetMembers,
      fleetReminderForm,
      updateActionAndClose,
      existingFormUpdated,
      deleteDialog,
      openDeleteDialog,
      deleteAction,
      closeDeleteDialog
    };
  }
});
