import { LastLocationInfo, TrailerLastLocation } from "@/types/fleet";

// --------------------------------------------------------------------------

export function getLastLocationInfo(lastLocation?: TrailerLastLocation): LastLocationInfo {
  if (!lastLocation) return {} as LastLocationInfo;

  const { leg, action, generic } = lastLocation;

  if (leg || action || generic) {
    return {
      name: getLastLocationName(lastLocation),
      dropWithTrailer: getLastLocationDropWithTrailer(lastLocation),
      containerId: getLastLocationContainerId(lastLocation),
      date: getLastLocationDate(lastLocation),
      lastMovement: getLastLocationLastMovement(lastLocation)
    } as LastLocationInfo;
  }

  return {} as LastLocationInfo;
}

// --------------------------------------------------------------------------

export function getLastLocationName(lastLocation?: TrailerLastLocation): string | undefined {
  if (!lastLocation) return undefined;

  const { leg, action, generic } = lastLocation;

  if (leg) {
    if (leg.events?.effectiveArrivalDate) {
      return leg.stops.to.name;
    } else {
      return leg.stops.from.name;
    }
  }

  if (action) {
    const toDate = new Date(action.toDate!.date);
    if (toDate > new Date()) {
      return action.fromLocation?.name;
    } else {
      return action.toLocation?.name;
    }
  }

  if (generic) {
    return generic.name;
  }

  return undefined;
}

// --------------------------------------------------------------------------

export function getLastLocationDropWithTrailer(lastLocation?: TrailerLastLocation): boolean {
  if (!lastLocation) return false;

  const { leg, action, generic } = lastLocation;

  if (leg) {
    if (leg.events?.effectiveArrivalDate) {
      return !!leg.details?.dropWithTrailer;
    } else {
      return false;
    }
  }

  if (action) {
    return false;
  }

  if (generic) {
    return generic.dropWithTrailer;
  }

  return false;
}

// --------------------------------------------------------------------------

export function getLastLocationContainerId(lastLocation?: TrailerLastLocation): string | undefined {
  if (!lastLocation) return undefined;

  const { leg, action, generic } = lastLocation;

  if (leg) {
    return leg.container?.containerId;
  }

  if (action) {
    return undefined;
  }

  if (generic) {
    return generic.containerId;
  }

  return undefined;
}

// --------------------------------------------------------------------------

export function getLastLocationDate(lastLocation?: TrailerLastLocation): string | undefined {
  if (!lastLocation) return undefined;
  const { leg, action, generic } = lastLocation;

  if (leg) {
    if (leg.events?.effectiveArrivalDate) {
      return leg.events.effectiveArrivalDate.date;
    }
    if (leg.events?.effectiveDepartureDate) {
      return leg.events.effectiveDepartureDate.date;
    }
  }

  if (action) {
    const toDate = new Date(action.toDate!.date);
    if (toDate > new Date()) {
      return action.fromDate?.date;
    } else {
      return action.toDate?.date;
    }
  }

  if (generic) {
    return generic.date;
  }

  return undefined;
}

// --------------------------------------------------------------------------

export function getLastLocationLastMovement(lastLocation?: TrailerLastLocation): string | undefined {
  if (!lastLocation) return undefined;

  const { leg, action, generic } = lastLocation;

  if (leg) {
    return leg.orderNumberAlt || leg.orderNumber;
  }

  if (action) {
    return action.type;
  }

  if (generic) {
    return generic.lastMovement;
  }

  return undefined;
}
