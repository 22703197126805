/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'customs': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _stroke="null" fill-rule="evenodd" _fill="#333"><path pid="0" d="M13.96 13.019a2.364 2.505 0 011.98-1.136h5.526c.217 0 .426.031.626.089l-8.519 9.026v-4.263l-3.22 3.044V16.53l3.607-3.511zm9.796.737c.05.204.076.419.076.638v9.197h-9.357l9.28-9.835h0zm-9.375-6.89h-2.386l1.578-2.51V.177l9.47 1.672v5.018h-.017a4.34 4.6 0 01-8.645 0z"/><path pid="1" d="M1.672 20.246h7.102V8.538H1.672v11.708zM.093 7.696c0-.458.353-.83.789-.83h8.681c.436 0 .788.372.788.83v13.392a.786.833 0 01-.788.83H.883a.786.833 0 01-.79-.83V7.696z" fill-rule="nonzero"/><path pid="2" d="M3.25 11.047h3.946v1.672H3.25v-1.672zm0 2.509h3.156v1.672H3.25v-1.672z"/></g>'
  }
})
