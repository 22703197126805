<template>
  <div>
    <SplitScreen
      :is-active="isActive"
      :isLoading="isLoading"
      @closeHandler="closeChatHub"
      custom-class="chat-sidebar z-40"
    >
      <template #header>
        <div class="font-extrabold">{{ $t("chat.messageHubTitle") }}</div>
      </template>
      <template #default>
        <div class="pt-4 h-full relative">
          <button @click="markAllMessagesAsRead" class="bg-btYellow block ml-auto p-2 mb-12">
            {{ $t("chat.markAsReadBtnLabel") }}
          </button>
          <ChatsOverview v-show="!activeChat" />
          <ChatRoom />
        </div>
      </template>
      <template #actionBar>
        <div class="w-full flex">
          <div class="ml-auto flex">
            <Button
              @click="closeChatHub"
              variant="secondary"
              class="mr-4 py-2 px-8 h-12 bg-white border-2 border-gray-300  hover:border-yellow-500"
            >
              <span>{{ $t("actions.close") }}</span></Button
            >
          </div>
        </div>
      </template>
    </SplitScreen>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import ChatsOverview from "@/components/Chat/ChatsOverview.vue";
import ChatRoom from "@/components/Chat/ChatRoom.vue";
import store from "@/store";
import { SET_IS_CHAT_ACTIVE, SET_ACTIVE_CHAT, MARK_ALL_MESSAGES_READ, GET_ACTIVE_CHAT } from "@/store/chat";

export default defineComponent({
  name: "ChatHub",
  components: { SplitScreen, ChatsOverview, ChatRoom },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const activeChat = computed(() => store.getters[GET_ACTIVE_CHAT]);

    const isLoading = ref(false);
    const closeChatHub = () => {
      emit("chatHubClosed");
      store.commit(SET_IS_CHAT_ACTIVE, false);
      store.commit(SET_ACTIVE_CHAT, null);
    };

    const markAllMessagesAsRead = () => {
      store.dispatch(MARK_ALL_MESSAGES_READ);
    };

    return { isLoading, closeChatHub, markAllMessagesAsRead, activeChat };
  }
});
</script>

<style>
.chat-sidebar {
  width: 650px;
}
</style>
