



















































































import { defineComponent, toRefs } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import store from "@/store";
import { POST_G11_MESSAGE } from "@/store/board";
import { BookingState, LegG11Action } from "@/types/leg";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";
import { computed } from "@vue/composition-api";
import { useMessages } from "@/use/useMessages";

export default defineComponent({
  name: "LegDetailHeader",
  props: {
    selectedLeg: {
      type: Object
    },
    eventIsRead: {
      type: Boolean,
      default: false
    }
  },
  components: { Icon, ChatNotifications },
  setup(props) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legType, statusClass, hierarchyIndicator, legIssues } = useLegStatus(selectedLegRef);

    const notifyG11 = async (g11Action: LegG11Action) => {
      const { g11Actions } = selectedLegRef?.value || {};
      if (g11Actions) {
        const message = g11Actions[g11Action];
        if (message) {
          await store.dispatch(POST_G11_MESSAGE, message);
        }
      }
    };

    const totalUnreadMessages = computed(() => useMessages(props.selectedLeg?.booking?.id));

    const showChatNotificationIcon = computed(
      () => !!props.selectedLeg?.booking?.id && props.selectedLeg?.booking?.bookingState !== BookingState.PREPLANNED
    );

    const notifyG11Bookings = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_BOOKING_SCREEN);
    };

    const notifyG11Planning = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_PLANNING_SCREEN);
    };

    return {
      statusClass,
      legType,
      notifyG11Bookings,
      notifyG11Planning,
      hierarchyIndicator,
      totalUnreadMessages,
      showChatNotificationIcon,
      legIssues
    };
  }
});
