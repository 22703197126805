



































import { defineComponent, ref, onMounted, onUnmounted, computed } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store";
import { IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";

export default defineComponent({
  name: "BrickActionBar",
  components: { Icon },
  props: {
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const brickActionBar = ref<HTMLElement | null>(null);
    const isBoardEditActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

    const actionBarKeyHandler = (e: KeyboardEvent) => {
      if (e.target instanceof Element) {
        if (e.target.id === "brickActionBar") {
          if (e.key === "Escape") {
            emit("resetBrick");
          }
          if (e.key === "s") {
            emit("updateBrick");
          }
          //makes sure do not fire other key events
          e.stopPropagation();
        }
      }
    };
    onMounted(() => {
      brickActionBar.value?.focus();
      document.addEventListener("keydown", actionBarKeyHandler, true);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", actionBarKeyHandler, true);
    });

    const resetOnTouch = e => {
      e.stopPropagation();
      emit("resetBrick");
    };

    const saveOnTouch = e => {
      e.stopPropagation();
      emit("updateBrick");
    };

    const updateBrick = (e: Event) => {
      if (props.disabled) {
        e.stopPropagation();
        return;
      }
      emit("updateBrick");
    };

    return {
      updateBrick,
      brickActionBar,
      resetOnTouch,
      saveOnTouch,
      isBoardEditActive
    };
  }
});
