var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sideBarCallout" }, [
      _c(
        "div",
        { staticClass: "popover-body" },
        [
          _vm.type === _vm.FleetEntityType.TRUCK
            ? _c("CalloutSideBarTruck", {
                staticClass: "mb-6",
                attrs: { content: _vm.truck }
              })
            : _vm._e(),
          _vm.type === _vm.FleetEntityType.TRAILER || _vm.trailer
            ? _c("CalloutSideBarTrailer", {
                staticClass: "mb-6",
                attrs: { content: _vm.trailer }
              })
            : _vm._e(),
          _vm._l(_vm.drivers, function(driver, index) {
            return _c("CalloutSideBarDriver", {
              key: index,
              staticClass: "mb-6",
              attrs: { content: driver }
            })
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }