<template>
  <div>
    <div class="flex pt-4">
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.truck") }}</div>
        <multiSelect
          v-model="truckPreviewValue"
          :disabled="isDisabledFleet"
          :options="truckPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectTruck')"
          label="displayName"
          track-by="displayName"
          :loading="truckLoading"
          @input="truckChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.trailer") }}</div>
        <multiSelect
          v-model="trailerPreviewValue"
          :disabled="isDisabled"
          :options="trailerPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectTrailer')"
          label="displayName"
          track-by="displayName"
          :loading="trailerLoading"
          @input="trailerChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
      <div class="w-1/3">
        <div class="text-gray-400 mb-2">{{ $t("fleet.driver") }}</div>
        <multiSelect
          v-model="driverPreviewValue"
          :disabled="isDisabledFleet"
          :options="driverPreview"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectDriver')"
          label="displayName"
          track-by="displayName"
          :loading="driverLoading"
          @input="driverChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
    </div>
    <div class="flex py-4">
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.subcontractor") }}</div>
        <multiSelect
          v-model="subcontractorPreviewValue"
          :options="subcontractorPreview"
          :disabled="isDisabled || hasSubiDriver"
          :custom-label="displayName"
          :placeholder="$t('fleet.selectSubcontractor')"
          label="displayName"
          track-by="displayName"
          :loading="subcontractorLoading"
          @input="subcontractorChanged"
          :selectLabel="$t('actions.select')"
          :deselectLabel="$t('actions.remove')"
        />
      </div>
      <div class="w-1/3 mr-6">
        <div class="text-gray-400 mb-2">{{ $t("fleet.subitruck") }}</div>
        <a-input
          size="large"
          :disabled="isDisabledSubcontractor"
          v-model="subcontractorTruckName"
          @input="subconTruckNameChanged(subcontractorTruckName)"
          :placeholder="$t('fleet.subitruck')"
        />
      </div>
      <div class="w-1/3">
        <div class="text-gray-400 mb-2">{{ $t("fleet.subidriver") }}</div>
        <a-input
          size="large"
          :disabled="isDisabledSubcontractor"
          v-model="subcontractorDriverName"
          @input="subconDriverNameChanged(subcontractorDriverName)"
          :placeholder="$t('fleet.subidriver')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import fleetService from "@/services/fleetService";
import { Preview, Subcontractor } from "@/types/fleet";
import { BoardLeg } from "@/types/leg";
import { setAsFirstIfMatching } from "@/use/useArray";
import { defineComponent, toRefs, ref, computed, onMounted } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import useLegStatus from "../../Use/useLegStatus";

export default defineComponent({
  components: {
    multiSelect
  },
  props: {
    selectedLeg: {
      type: Object as () => BoardLeg
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legStatus } = useLegStatus(selectedLegRef);

    //define preview data for multiselects
    const truckPreview = ref<Array<Preview>>([]);
    const truckPreviewValue = ref<Preview | null>(null);
    const truckLoading = ref(false);
    const trailerPreview = ref<Array<Preview>>([]);
    const trailerPreviewValue = ref<Preview | null>(null);
    const trailerLoading = ref(false);
    const driverPreview = ref<Array<Preview>>([]);
    const driverPreviewValue = ref<Preview | null>(null);
    const driverLoading = ref(false);
    const subcontractorPreview = ref<Array<Preview>>([]);
    const subcontractorPreviewValue = ref<Preview | null>(null);
    const subcontractorLoading = ref(false);
    const subcontractorTruckName = ref<string | null>(null);
    const subcontractorDriverName = ref<string | null>(null);
    const subcontractor = ref<Subcontractor | null>(null);

    /**
     * Trucks handling
     */
    const fetchTrucks = async () => {
      if (isDisabledFleet.value) {
        return;
      }
      truckLoading.value = true;
      try {
        const { data } = await fleetService.getTrucksPreview();
        truckPreview.value = setAsFirstIfMatching(data.previews, truckPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      truckLoading.value = false;
    };
    const truckChanged = async (selectedTruck?: Preview | null) => {
      //getData from truck and update other fields
      if (typeof selectedTruck === "undefined" || selectedTruck === null) {
        return;
      }
      try {
        const { data } = await fleetService.getTruckById(selectedTruck!.id!);
        const { driver, trailer } = data;

        driverPreviewValue.value =
          (driver && {
            id: driver.id,
            displayName: driver.id
          }) ||
          null;
        trailerPreviewValue.value =
          (trailer && {
            id: trailer.id,
            displayName: trailer.id
          }) ||
          null;

        truckPreview.value = setAsFirstIfMatching(truckPreview.value, data.id);
        driverPreview.value = setAsFirstIfMatching(driverPreview.value, driverPreviewValue.value?.id);
        trailerPreview.value = setAsFirstIfMatching(trailerPreview.value, trailerPreviewValue.value?.id);

        emit("multiSelectChange", {
          type: "fleet",
          value: { truckId: truckPreviewValue.value?.id || null }
        });
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    /***
     * Trailer handling
     */
    const fetchTrailers = async () => {
      if (isDisabled.value) {
        return;
      }
      trailerLoading.value = false;
      try {
        const { data } = await fleetService.getTrailersPreview();

        trailerPreview.value = setAsFirstIfMatching(data.previews, trailerPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      trailerLoading.value = false;
    };
    const trailerChanged = async () => {
      trailerPreview.value = setAsFirstIfMatching(trailerPreview.value, trailerPreviewValue.value?.id);
      emit("multiSelectChange", {
        type: "fleet",
        value: { trailerId: trailerPreviewValue.value?.id || null }
      });
    };

    /**
     * Driver handling
     */
    const fetchDrivers = async () => {
      if (isDisabledFleet.value) {
        return;
      }
      driverLoading.value = true;
      try {
        const { data } = await fleetService.getDriversPreview();
        driverPreview.value = setAsFirstIfMatching(data.previews, driverPreviewValue.value?.id);
      } catch (e) {
        //show errors which are not handled globally
      }
      driverLoading.value = false;
    };
    const driverChanged = async () => {
      driverPreview.value = setAsFirstIfMatching(driverPreview.value, driverPreviewValue.value?.id);
      emit("multiSelectChange", {
        type: "fleet",
        value: { driverId: driverPreviewValue.value?.id || null }
      });
    };

    /**
     * Subcontractors handling
     */
    const fetchSubcontractors = async () => {
      subcontractorLoading.value = true;
      try {
        const { data } = await fleetService.getSubconctractorsPreview();
        subcontractorPreview.value = setAsFirstIfMatching(data.previews, subcontractorPreviewValue.value?.id);
        setSubConDisplayName();
      } catch (e) {
        //show errors which are not handled globally
      }
      subcontractorLoading.value = false;
    };
    const setSubConDisplayName = (): void => {
      if (subcontractorPreviewValue.value?.displayName) {
        subcontractorPreviewValue.value.displayName =
          subcontractorPreview.value.find(item => item.id === subcontractorPreviewValue.value?.id)?.displayName || "";
      }
    };
    const fetchSubcontractor = async (id?: string) => {
      if (id == null) {
        return;
      }
      try {
        const { data } = await fleetService.getSubcontractorById(id);
        subcontractor.value = data;
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const subcontractorChanged = async () => {
      subcontractorPreview.value = setAsFirstIfMatching(
        subcontractorPreview.value,
        subcontractorPreviewValue.value?.id
      );
      clearDropdowns();
      await fetchSubcontractor(subcontractorPreviewValue.value?.id);
      emit("multiSelectChange", {
        type: "fleet",
        value: { subcontractorId: subcontractorPreviewValue.value?.id || null }
      });
    };

    const subconTruckNameChanged = (subconTruckName: string | null) => {
      if (subconTruckName === "") {
        subconTruckName = null;
      }
      emit("multiSelectChange", {
        type: "details",
        value: { subconTruckName }
      });
    };
    const subconDriverNameChanged = (subconDriverName: string | null) => {
      if (subconDriverName === "") {
        subconDriverName = null;
      }
      emit("multiSelectChange", {
        type: "details",
        value: { subconDriverName }
      });
    };
    const subContractorSelected = computed(() => !!subcontractorPreviewValue.value?.id);

    /***
     * Component functions
     */

    //multiselect how we show the data in the fields
    const displayName = (previewItem: Preview): string => {
      const { displayName } = previewItem;
      return displayName;
    };

    const initInputFields = () => {
      subcontractorDriverName.value = props.selectedLeg?.subcontractor?.subconDriverName || null;
      subcontractorTruckName.value = props.selectedLeg?.subcontractor?.subconTruckName || null;
    };

    const initDropDowns = () => {
      const { selectedLeg } = props;
      truckPreviewValue.value =
        (selectedLeg?.truckId && {
          id: selectedLeg.truckId,
          displayName: selectedLeg.truckId
        }) ||
        null;
      driverPreviewValue.value =
        (selectedLeg?.driverId && {
          id: selectedLeg.driverId,
          displayName: selectedLeg.driverId
        }) ||
        null;
      trailerPreviewValue.value =
        (selectedLeg?.trailerId && {
          id: selectedLeg.trailerId,
          displayName: selectedLeg.trailerId
        }) ||
        null;
      subcontractorPreviewValue.value =
        (selectedLeg?.subcontractor?.id && {
          id: selectedLeg.subcontractor?.id,
          displayName: selectedLeg.subcontractor?.id
        }) ||
        (selectedLeg?.subcontractor?.id && {
          id: selectedLeg.subcontractor.id,
          displayName: selectedLeg.subcontractor.id
        }) ||
        null;
    };

    const clearDropdowns = (): void => {
      truckPreviewValue.value = null;
      driverPreviewValue.value = null;
      trailerPreviewValue.value = null;
      emit("clear");

      if (!subcontractorPreviewValue.value) {
        subcontractorDriverName.value = null;
        subcontractorTruckName.value = null;
        const subconDriverName = null;
        const subconTruckName = null;
        emit("multiSelectChange", {
          type: "details",
          value: { subconDriverName }
        });
        emit("multiSelectChange", {
          type: "details",
          value: { subconTruckName }
        });
      }
    };

    const isDisabled = computed(() => {
      return !legStatus.value.notBooked && !legStatus.value.prePlanned && !props.isLocked;
    });
    const isDisabledFleet = computed(() => {
      return isDisabled.value || (subContractorSelected.value && !hasSubiDriver.value);
    });

    const isDisabledSubcontractor = computed(() => {
      return isDisabled.value || !subContractorSelected.value || hasSubiDriver.value;
    });

    const hasSubiDriver = computed(() => {
      const { id, primaryBoard, secondaryBoard } = props.selectedLeg?.subcontractor || {};
      return !!id && !primaryBoard && !secondaryBoard;
    });

    //make API call
    const getDropdownData = () => {
      fetchTrailers();
      fetchTrucks();
      fetchDrivers();
      fetchSubcontractors();
    };

    onMounted(() => {
      initDropDowns();
      getDropdownData();
      initInputFields();
    });

    return {
      displayName,
      initDropDowns,
      truckChanged,
      trailerChanged,
      driverChanged,
      truckPreview,
      truckPreviewValue,
      truckLoading,
      driverPreview,
      driverPreviewValue,
      driverLoading,
      trailerPreview,
      trailerPreviewValue,
      trailerLoading,
      isDisabled,
      isDisabledFleet,
      isDisabledSubcontractor,
      subcontractorPreview,
      subcontractorPreviewValue,
      subcontractorLoading,
      subcontractorChanged,
      subcontractorDriverName,
      subcontractorTruckName,
      subconTruckNameChanged,
      subconDriverNameChanged,
      subContractorSelected,
      hasSubiDriver
    };
  }
});
</script>

<style scoped></style>
