var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-switch", {
        staticClass: "mr-2",
        attrs: { checked: _vm.showPreviousLegDetail, size: "large" },
        on: { change: _vm.onPreviousLegDetailToggleChange }
      }),
      _c("span", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.$t("showPreviousLegDetail")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }