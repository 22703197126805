var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "border-b-2 font-semibold mb-2 border-yellow-900 text-yellow-900"
      },
      [_vm._v(" " + _vm._s(_vm.spreadTrailerId(_vm.trailer.id).id) + " ")]
    ),
    _c(
      "table",
      {
        staticClass: "trailerCallout__table table-fixed text-left mb-6 w-full"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.type")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.suitableFor")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.tareWeight")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "font-light align-top" }, [
          _c("tr", [
            _c("td", [
              _vm.trailer.metadata.chassisType
                ? _c("span", [_vm._v(_vm._s(_vm.trailer.metadata.chassisType))])
                : _vm._e()
            ]),
            _c("td", [
              _vm.trailer.metadata.suitableFor
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.trailer.metadata.suitableFor) + " ")
                  ])
                : _vm._e()
            ]),
            _c("td", [
              _vm.trailer.metadata.tareWeight
                ? _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.trailer.metadata.tareWeight) + " kg "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]
    ),
    _c(
      "table",
      {
        staticClass: "trailerCallout__table table-fixed text-left w-full mb-6"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.grossWeight")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.nextTest")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("truck.equipment")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "font-light align-top" }, [
          _c("tr", [
            _c("td", [
              _vm.trailer.metadata.grossWeight
                ? _c("span", [
                    _vm._v(_vm._s(_vm.trailer.metadata.grossWeight) + " kg")
                  ])
                : _vm._e()
            ]),
            _c("td", [
              _vm.nextInspectionDate
                ? _c(
                    "span",
                    {
                      class: {
                        "text-btRed": _vm.nextInspectionDate.isDateInPast
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.nextInspectionDate.date) + " ")]
                  )
                : _vm._e(),
              _c(
                "span",
                [
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        trigger: "hover",
                        placement: "right",
                        overlayStyle: { maxWidth: "650px" },
                        getPopupContainer: function(triggerNode) {
                          return triggerNode.parentNode
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c("FleetNextInspectionTable", {
                                attrs: { content: _vm.trailer.metadata }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("Icon", {
                        staticClass: "text-black cursor-pointer",
                        attrs: {
                          name: "info",
                          width: "1.5rem",
                          height: "1.5rem"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("td", { staticClass: "capitalize" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.stringifyTrailerEquipment(_vm.trailer.metadata)) +
                  " "
              )
            ])
          ])
        ])
      ]
    ),
    _c(
      "table",
      { staticClass: "trailerCallout__table table-fixed text-left w-full" },
      [
        _c("thead", { staticClass: "font-light" }, [
          _c("tr", [
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.department")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.tempDepartment")))
            ]),
            _c("th", { staticClass: "w-1/3" }, [
              _vm._v(_vm._s(_vm.$t("trailer.tempGroup")))
            ])
          ])
        ]),
        _c("tbody", { staticClass: "align-top" }, [
          _c("tr", [
            _c("td", [_vm._v(" " + _vm._s(_vm.trailer.baseBoardId) + " ")]),
            _vm.tempDepartment
              ? _c("td", [
                  _vm._v(" " + _vm._s(_vm.tempDepartment.boardId) + " ")
                ])
              : _vm._e(),
            _vm.tempGroup
              ? _c("td", [_vm._v(" " + _vm._s(_vm.tempGroup.boardId) + " ")])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }