/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 1H4a2.006 2.006 0 00-2 2v14h2V3h12zm3 4H8a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h11a2.006 2.006 0 002-2V7a2.006 2.006 0 00-2-2zm0 16H8V7h11z"/>'
  }
})
