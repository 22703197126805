<template>
  <div v-show="isActive">
    <Overlay />
    <transition name="slideRight">
      <div
        v-show="splitScreenContent"
        ref="splitScreen"
        tabindex="0"
        class="splitScreen fixed top-0 right-0 h-screen z-50 bg-white"
        :class="customClass"
      >
        <div class="splitScreen__header border-gray-200 border-b" :class="customClass">
          <div class="splitScreen__header__inner flex justify-between py-2 mx-4 my-2">
            <div class="text-2xl">
              <slot name="header"></slot>
            </div>
            <button @click="closeSplitScreen" ref="closeBtn">
              <Icon class="text-gray-400 cursor-pointer" name="close" />
            </button>
          </div>
        </div>
        <div class="splitScreen__body px-4">
          <slot></slot>
        </div>
        <div class="splitScreen__actionBar" :class="customClass">
          <slot name="actionBar" :close="closeSplitScreen"></slot>
        </div>

        <div
          v-show="isLoading"
          class="h-screen w-1/2 bg-btYellow bg-opacity-25 fixed top-0 flex items-center justify-center"
          :class="customClass"
        >
          <Icon class="text-black" name="spinner" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from "@vue/composition-api";
import Overlay from "@/components/Overlay.vue";
import Icon from "@/components/Icon.vue";
import useScrollPosition from "@/use/useScrollPosition";

export default defineComponent({
  name: "SplitScreen",
  components: { Overlay, Icon },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    const { toggleScrollFixed } = useScrollPosition();

    const closeBtn = ref<HTMLElement | null>(null);

    const splitScreen = ref<HTMLElement | null>(null);
    const splitScreenContent = ref(false);

    const closeSplitScreen = () => {
      splitScreenContent.value = false;
      setTimeout(() => {
        context.emit("closeHandler");
      }, 500);
    };

    const splitScreenEscapeHandler = (e: any) => {
      if (e.key === "Escape") {
        closeSplitScreen();
        e.stopPropagation();
      }
    };

    onMounted(() => {
      document.addEventListener("keydown", splitScreenEscapeHandler, {
        once: true
      });

      setTimeout(() => {
        closeBtn.value?.focus();
      }, 500);
    });

    onUnmounted(() => {
      toggleScrollFixed();
    });

    watch(
      () => props.isActive,
      () => {
        if (props.isActive) {
          toggleScrollFixed();
        }
        splitScreenContent.value = true;
      },
      { immediate: true }
    );

    return {
      splitScreen,
      splitScreenContent,
      closeSplitScreen,
      closeBtn
    };
  }
});
</script>

<style scoped lang="scss">
@use "SplitScreen";
</style>
