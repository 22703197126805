import {
  LegStatuses,
  PreviousLegStatuses,
  LegTypes,
  LegTransportDetail,
  LegTransportStatus,
  ContainerLoadState,
  LegTransportStatuses,
  BookingState
} from "@/types/leg";
import { computed } from "@vue/composition-api";
import { defineLegStatus, defineLegType } from "@/use/useLeg";
import store from "@/store";
import { GET_LANGUAGE, GET_SHOW_PREVIOUS_LEG_DETAIL } from "@/store/board";

export default (legData: any) => {
  const showPreviousLegDetail = computed(() => store.getters[GET_SHOW_PREVIOUS_LEG_DETAIL]);

  const legType = computed(
    (): LegTypes => {
      return defineLegType(legData.value.type);
    }
  );
  const legStatus = computed(
    (): LegStatuses => {
      return defineLegStatus(legData.value, legType.value);
    }
  );
  //shows leg status in color
  const statusClass = computed(() => {
    if (legData.value.status === LegTransportStatus.BLOCKED) {
      return "leg--checklist-blocked";
    }
    return `leg--${legStatus.value.name}`;
  });

  const groupIsPending = (group?: LegTransportDetail[]): boolean => {
    return group?.length != null && group.some(checkbox => !checkbox.checked);
  };

  const isPrevLegViewActive = computed(() => showPreviousLegDetail.value && hasPreviousLeg.value);

  const legIssues = computed(() => {
    const { details } = legData.value || {};
    const currentLocale = store.getters[GET_LANGUAGE];
    return (
      details?.issues?.reduce((acc, issue) => {
        return acc + "- " + issue.messages[currentLocale] + "\n";
      }, "") || ""
    );
  });

  const transportStatuses = computed<LegTransportStatuses>(() => {
    const { customs, specialCustoms, documents, specialEquipments, temperatures } = legData.value.transportInfo || {};
    return {
      hasCustoms: !!customs?.length && (legData.value.details?.isUnloadingRelated || false),
      hasPendingIssues: legIssues.value != "",
      hasPendingCustoms: groupIsPending(customs),
      hasPendingSpecialCustoms: groupIsPending(specialCustoms),
      hasPendingDocuments: groupIsPending(documents),
      hasPendingSpecialEquipments: groupIsPending(specialEquipments),
      hasPendingTemperatures: groupIsPending(temperatures)
    };
  });

  const legBackgroundClass = computed<string>(() => {
    if (transportStatuses.value.hasPendingIssues) {
      return "bg-red-200";
    }
    if (transportStatuses.value.hasPendingSpecialCustoms) {
      return "bg-pink-200";
    }
    if (transportStatuses.value.hasPendingCustoms) {
      return "bg-purple-200";
    }
    if (legData.value.status === LegTransportStatus.READY) {
      return "bg-btLightGreen";
    }
    if (legData.value.status === LegTransportStatus.PREPARATION) {
      return "bg-btLightBlue";
    }
    return "";
  });

  const previousLegIndicatorHeightClass = computed(() => {
    return (showPreviousLegDetail.value && "h-0") || "h-5px";
  });

  const roundedCornersClass = computed(
    () => (hasPreviousLeg.value && !showPreviousLegDetail.value && "leg--rounded") || "rounded"
  );

  const previousLegState = computed<PreviousLegStatuses>(() => {
    const { booking, effectiveDepartureDate, effectiveArrivalDate } = legData.value.previousLeg || {};
    const previousLegState = effectiveArrivalDate || effectiveDepartureDate || booking?.id || null;

    return {
      booked: previousLegState === booking?.id && booking?.bookingState !== BookingState.PREPLANNED,
      departed: previousLegState === effectiveDepartureDate,
      arrived: previousLegState === effectiveArrivalDate,
      unknown:
        previousLegState === null ||
        (previousLegState === booking?.id && booking?.bookingState === BookingState.PREPLANNED)
    };
  });

  const hasPreviousLeg = computed(() => {
    const { departed, booked, prePlanned, notBooked } = legStatus.value;
    return (departed || booked || prePlanned || notBooked) && !previousLegState.value.unknown;
  });

  const isDepartedWithIntermodalIrregularities = computed<boolean>(() => {
    const { effectiveDepartureDate, effectiveArrivalDate, hasIntermodalIrregularities } = legData.value.previousLeg;
    return !!(effectiveDepartureDate && hasIntermodalIrregularities && !effectiveArrivalDate);
  });

  const previousLegClass = computed(() => {
    const { booked, departed, arrived } = previousLegState.value;
    return {
      "leg--previous-booked": booked,
      "leg--previous-departed": departed,
      "leg--previous-arrived": arrived,
      "leg--previous-departed-with-irregularities": isDepartedWithIntermodalIrregularities.value
    };
  });

  const initialLoadState = computed(() => {
    const { initialContainerLoadState } = legData.value.details;
    if (!legStatus.value.notBooked && !legStatus.value.prePlanned) {
      return "";
    }
    switch (initialContainerLoadState) {
      case ContainerLoadState.LOADED:
        return "L";
      case ContainerLoadState.CLEANED:
        return "C";
      case ContainerLoadState.RELOAD:
        return "R";
      case ContainerLoadState.UNCLEANED:
        return "U";
      default:
        return "";
    }
  });

  const unloadingTemperature = computed(() => {
    const { unloadingTempFrom, unloadingTempTo } = legData.value.details;
    const degreeSign = String.fromCharCode(176);
    if (unloadingTempFrom && unloadingTempTo) {
      return unloadingTempFrom + degreeSign + " - " + unloadingTempTo + degreeSign;
    }
    if (unloadingTempFrom) {
      return "Min. " + unloadingTempFrom + degreeSign;
    }
    if (unloadingTempTo) {
      return "Max. " + unloadingTempTo + degreeSign;
    }
    return "";
  });

  const hierarchyIndicator = computed(() => {
    const { parentOrderNumber, childOrderNumbers } = legData.value;
    if (parentOrderNumber) {
      return "[SO]";
    }
    if (childOrderNumbers && childOrderNumbers.length) {
      return "[PO]";
    }
    return "";
  });

  return {
    legType,
    legStatus,
    legIssues,
    statusClass,
    transportStatuses,
    legBackgroundClass,
    previousLegState,
    previousLegClass,
    previousLegIndicatorHeightClass,
    roundedCornersClass,
    hasPreviousLeg,
    showPreviousLegDetail,
    isPrevLegViewActive,
    isDepartedWithIntermodalIrregularities,
    initialLoadState,
    unloadingTemperature,
    hierarchyIndicator
  };
};
