









import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import { LOG_OUT } from "@/store/auth";

export default defineComponent({
  name: "LogoutButton",
  components: { Icon },
  setup(props, { root }) {
    function logOut() {
      root.$store.dispatch(LOG_OUT);
      root.$router.push({ name: "Login" });
    }
    return {
      logOut
    };
  }
});
