



































































































import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import { stringifyTrailerEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Trailer } from "@/types/fleet";
import Icon from "@/components/Icon.vue";
import FleetNextInspectionTable from "@/components/Board/CallOuts/CalloutSideBar/FleetNextInspectionTable/FleetNextInspectionTable.vue";
import { getLatestDateOnBoards, filterBoardTypes } from "@/components/Board/CallOuts/Use/useBoards";
import { spreadTrailerId } from "@/use/useFleet";

export default defineComponent({
  name: "CalloutSideBarDriver.vue",
  components: { FleetNextInspectionTable, Icon },
  props: {
    content: {
      type: Object as PropType<Trailer>,
      required: true
    }
  },
  setup(props) {
    const trailer = computed<Trailer>(() => props.content);

    const GROUP = trailer.value.boards ? filterBoardTypes("GROUP", trailer.value) : null;
    const DEPARTMENT = trailer.value.boards ? filterBoardTypes("DEPARTMENT", trailer.value) : null;

    const tempDepartment = DEPARTMENT && DEPARTMENT.length >= 1 ? getLatestDateOnBoards(DEPARTMENT) : null;
    const tempGroup = GROUP && GROUP.length >= 1 ? getLatestDateOnBoards(GROUP) : null;

    const nextInspectionDate = computed(() => getNextInspectionDate(trailer.value.metadata));

    return {
      trailer,
      stringifyTrailerEquipment,
      getNextInspectionDate,
      tempGroup,
      tempDepartment,
      spreadTrailerId,
      nextInspectionDate
    };
  }
});
