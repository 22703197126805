<template>
  <div>
    <SplitScreen
      :isActive="isActive"
      :isLoading="isLoading"
      custom-class="w-1/3 z-40"
      @closeHandler="closeBoardSettings"
    >
      <template #header>
        <span class="font-extrabold">{{ $t("settings.title") }}</span>
      </template>

      <template #default>
        <div class="pt-4">
          <a-collapse default-active-key="1" :bordered="false" class="bg-gray-100 ant-collapse--single mb-4">
            <a-collapse-panel key="1" :header="$t('showWeekend')" class="text-xl bg-gray-100 ">
              <ShowWeekend @isLoading="isLoadingHandler" />
            </a-collapse-panel>
          </a-collapse>
          <a-collapse default-active-key="1" :bordered="false" class="bg-gray-100 ant-collapse--single mb-4">
            <a-collapse-panel key="1" :header="$t('settings.timeSpan')" class="text-xl bg-gray-100 ">
              <TimeSelectorDialog :showDialog="false" class="mt-2" @isLoading="isLoadingHandler" />
            </a-collapse-panel>
          </a-collapse>
          <a-collapse default-active-key="1" :bordered="false" class="bg-gray-100 ant-collapse--single mb-4">
            <a-collapse-panel
              key="1"
              :header="$t('settings.language')"
              class="navSettings__languageSelector text-xl bg-gray-100 "
            >
              <language-switcher class="mt-2" />
            </a-collapse-panel>
          </a-collapse>
          <a-collapse default-active-key="1" :bordered="false" class="bg-gray-100 ant-collapse--single">
            <a-collapse-panel key="1" :header="$t('settings.defaultDate')" class="text-xl bg-gray-100">
              <default-date @isLoading="isLoadingHandler" />
            </a-collapse-panel>
          </a-collapse>
        </div>
      </template>

      <template #actionBar="{ close }">
        <div class="w-full flex">
          <div class="ml-auto flex">
            <Button @click="close()" variant="secondary" class="mr-4">
              <span>{{ $t("actions.close") }}</span></Button
            >
          </div>
        </div>
      </template>
    </SplitScreen>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import ShowPreviousLegDetail from "@/components/Board/ShowPreviousLegDetail.vue";
import ShowWeekend from "@/components/Board/ShowWeekend.vue";
import DefaultDate from "@/components/DefaultDate.vue";
import TimeSelectorDialog from "@/components/Board/TimeSelector/TimeSelectorDialog/TimeSelectorDialog.vue";

export default defineComponent({
  name: "NavSettings",
  components: {
    SplitScreen,
    Button,
    LanguageSwitcher,
    ShowPreviousLegDetail,
    ShowWeekend,
    TimeSelectorDialog,
    DefaultDate
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isLoadingHandler = (value: boolean): void => {
      isLoading.value = value;
    };
    const closeBoardSettings = () => emit("boardSettingsClosed");
    return { closeBoardSettings, isLoading, isLoadingHandler };
  }
});
</script>

<style lang="scss">
@use "NavSettings";
</style>
