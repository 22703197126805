































































import { defineComponent, onMounted, PropType, computed, reactive } from "@vue/composition-api";
import { LegStatuses } from "@/types/leg";
import moment from "moment";
import { LegPlannedFields } from "@/types/leg";

export default defineComponent({
  name: "LegDetailPlannedDates",
  emits: ["plannedDatesChange"],
  props: {
    status: {
      type: Object as PropType<LegStatuses>,
      required: true
    },
    plannedDates: {
      type: Object as PropType<Record<string, string | undefined>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const planned = reactive<LegPlannedFields>({
      fromDate: null,
      fromTime: null,
      toDate: null,
      toTime: null,
      valid: true
    });

    const hasTime = (date: string): boolean => {
      const [dateStr, timeStr] = date.split("T");
      return timeStr != null;
    };

    onMounted(() => {
      const { plannedDepartureDate, plannedArrivalDate } = props.plannedDates;
      if (plannedDepartureDate != null) {
        planned.fromDate = moment(plannedDepartureDate);
        planned.fromTime = hasTime(plannedDepartureDate) ? moment(plannedDepartureDate) : null;
      }
      if (plannedArrivalDate != null) {
        planned.toDate = moment(plannedArrivalDate);
        planned.toTime = hasTime(plannedArrivalDate) ? moment(plannedArrivalDate) : null;
      }
    });

    const disableAllInputs = computed(() => {
      return props.status.dropped;
    });

    const disablePlannedDepartureFields = computed(() => {
      const { departed, loaded, unloaded } = props.status;
      return departed || loaded || unloaded;
    });

    const datesValid = computed(() => {
      if (planned.fromDate != null && planned.toDate != null) {
        const formattedFrom = moment(planned.fromDate);
        formattedFrom.hour(planned.fromTime ? planned.fromTime.hour() : 0);
        formattedFrom.minutes(planned.fromTime ? planned.fromTime.minutes() : 0);

        const formattedTo = moment(planned.toDate);
        formattedTo.hour(planned.toTime ? planned.toTime.hour() : 23);
        formattedTo.minutes(planned.toTime ? planned.toTime.minutes() : 59);

        return formattedFrom.isSameOrBefore(formattedTo);
      }
      return false;
    });

    const onChange = () => {
      planned.valid = datesValid.value;
      emit("plannedDatesChange", planned);
    };

    const setTriggerAsAnchor = triggerNode => triggerNode.parentNode;

    return {
      datesValid,
      onChange,
      planned,
      setTriggerAsAnchor,
      disableAllInputs,
      disablePlannedDepartureFields
    };
  }
});
