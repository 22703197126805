









import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "BrickSpinner",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: { Icon }
});
