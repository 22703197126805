var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "chat-window w-full fixed right-0 bg-white bottom-0 overflow-hidden border-2 flex flex-col",
      style: _vm.chatWindowStyle
    },
    [
      _c(
        "div",
        {
          staticClass:
            "chat-room-header h-12 flex justify-between items-center px-5 border-2"
        },
        [
          _c("div", [
            _c("span", { staticClass: "text-xl" }, [
              _vm._v(
                _vm._s(
                  (_vm.activeChat ? _vm.activeChat.driverName : "") + " - "
                ) + " "
              )
            ]),
            _c("span", { staticClass: "inline-block mr-auto text-xl" }, [
              _vm._v(
                _vm._s(
                  _vm.activeChat ? _vm.activeChat.alternateOrderNumber : ""
                )
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "inline-block cursor-pointer",
              on: { click: _vm.quitChatRoom }
            },
            [
              _c("Icon", {
                attrs: { name: "close", width: "25", height: "25" }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "chat-room-body p-7 border-2 flex-1" },
        _vm._l(_vm.chatMessages, function(messagesForThisDate, date) {
          return _c(
            "div",
            { key: date },
            [
              _c("div", { staticClass: "flex justify-center sticky top-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-btYellow text-black border border-black text-sm py-2 px-4 rounded-full"
                  },
                  [_vm._v(_vm._s(date))]
                )
              ]),
              _vm._l(messagesForThisDate, function(message, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "chat-bubble my-2 p-4",
                    class: {
                      "ml-auto chat-bubble-right": _vm.alignMessageOnRight(
                        message.origin
                      ),
                      "chat-bubble-left": !_vm.alignMessageOnRight(
                        message.origin
                      )
                    }
                  },
                  [
                    _c("div", { staticClass: "flex mt-1" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "inline-block mr-5 text-lg font-bold text-black"
                        },
                        [_vm._v(_vm._s(message.author))]
                      ),
                      _c(
                        "span",
                        { staticClass: "text-lg font-bold text-black" },
                        [_vm._v(_vm._s(_vm.dateStringToShowTime(message.sent)))]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-lg whitespace-pre-line long-word-wrap"
                      },
                      [_vm._v(" " + _vm._s(message.text) + " ")]
                    )
                  ]
                )
              })
            ],
            2
          )
        }),
        0
      ),
      _c("div", { staticClass: "chat-room-footer h-40 flex p-3 border-2" }, [
        _c(
          "div",
          { staticClass: "chat-field flex-1" },
          [
            _c("a-textarea", {
              staticClass: "input-field",
              attrs: {
                "auto-size": { minRows: 1, maxRows: 3 },
                maxLength: 512,
                placeholder: _vm.$t("chat.message") + "..."
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (!$event.shiftKey) {
                    return null
                  }
                  if ($event.ctrlKey || $event.altKey || $event.metaKey) {
                    return null
                  }
                  return _vm.sendMessage.apply(null, arguments)
                }
              },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            }),
            _c("div", { staticClass: "flex justify-end" }, [
              _c("div", { staticClass: "text-sm text-gray-400" }, [
                _vm._v(_vm._s(_vm.inputValue.length) + "/512")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chat-submit-btn ml-2" },
          [
            _c(
              "Button",
              {
                attrs: {
                  size: "sm",
                  disabled: _vm.messageQueue || _vm.isBookingIdEmpty
                },
                on: { click: _vm.sendMessage }
              },
              [_vm._v(_vm._s(_vm.$t("chat.send")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }