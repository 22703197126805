




















import { defineComponent, ref, watch, computed, onMounted, PropType } from "@vue/composition-api";
import moment from "moment";
import { GET_LANGUAGE } from "@/store/board";
import store from "@/store";
import { getDatePickerLocale } from "@/i18n/i18nUtils";

export default defineComponent({
  name: "fleetFormsDatePicker",
  props: {
    value: Date,
    minDate: {
      type: Date,
      required: false
    },
    maxDate: {
      type: Date,
      required: false
    },
    defaultDate: {
      type: Object as PropType<moment.Moment>
    }
  },
  setup(props, { emit }) {
    const selectedDate = ref<any>(null);

    const lang = computed(() => store.getters[GET_LANGUAGE]);
    const locale = ref(getDatePickerLocale(lang.value));
    onMounted(() => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch(lang, () => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch(
      () => props.value,
      newValue => {
        selectedDate.value = newValue == null ? null : moment(newValue);
      }
    );

    const disabledDates = current => {
      if (!props.minDate && !props.maxDate) {
        return false;
      }
      if (props.minDate && !props.maxDate) {
        return current.isBefore(moment(props.minDate));
      }
      if (!props.minDate && props.maxDate) {
        return current.isAfter(moment(props.maxDate));
      }
      return true;
    };

    const updateDate = (): void => {
      emit("input", selectedDate.value?.toDate());
    };

    return {
      locale,
      disabledDates,
      selectedDate,
      updateDate
    };
  }
});
