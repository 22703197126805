









































import { format, add, addBusinessDays } from "date-fns";
import { computed, defineComponent } from "@vue/composition-api";
import store from "@/store";
import {
  GET_HOUR_BOX_MIN_WIDTH,
  GET_FILTERED_HOURS_AMOUNT,
  GET_FROM_TIME,
  GET_HOURBOX_WIDTH,
  GET_HOURS_DIFFERENCE,
  GET_SHOW_WEEKENDS,
  GET_HOUR_BOX_EXTRA_MIN_WIDTH
} from "@/store/board/";
import { dateLocale } from "@/use/useDate";

export default defineComponent({
  name: "BoardTimeBoxes",
  props: {
    header: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const dateLabelFormat = "E dd.MM.yyyy";

    // Refs which we need
    const hoursDifference = computed(() => store.getters[GET_HOURS_DIFFERENCE]);

    const hours = computed(() => {
      return Math.round(hoursDifference.value);
    });

    const filteredHoursAmount = computed(() => store.getters[GET_FILTERED_HOURS_AMOUNT]);
    const fromTime = computed(() => store.getters[GET_FROM_TIME]);
    const boxWidth = computed(() => store.getters[GET_HOURBOX_WIDTH]);
    const minWidth = computed(() => store.getters[GET_HOUR_BOX_MIN_WIDTH]);
    const extraMinWidth = computed(() => store.getters[GET_HOUR_BOX_EXTRA_MIN_WIDTH]);
    const isZeroZoomLevel = computed(() => minWidth.value === extraMinWidth.value);
    const showWeekends = computed(() => store.getters[GET_SHOW_WEEKENDS]);

    const boxStyle = computed(() => {
      return { width: `${boxWidth.value}%`, minWidth: `${minWidth.value}rem` };
    });

    const startDate = computed(() => store.state.board.fromDate);

    const startDateLabel = computed(() => {
      return format(startDate.value, dateLabelFormat, {
        locale: dateLocale(root.$i18n.locale)
      });
    });

    //dynamic CSS returns class
    const dynamicClass = computed(() => {
      if (props.header) {
        return "boardTimeLine__boxes--header";
      }
      return isZeroZoomLevel.value ? "boardTimeLine__boxes__0zoom" : "boardTimeLine__boxes";
    });

    /**
     * Logic for showing the time on the header timeline
     */
    let timeCounter = 0;
    const getTime = (index: number, isDayEnd?: boolean): string => {
      timeCounter = index % filteredHoursAmount.value === 0 ? 0 : ++timeCounter;

      if ((index + 1) % 3 === 0 && !isDayEnd) {
        return "";
      }

      if (isZeroZoomLevel.value && !shouldShowDayName(index, isDayEnd)) {
        return "";
      }

      const checkedNumber = index % 3 === 0;
      const isLastHourOfDay = (index + 1) % filteredHoursAmount.value === 0;
      if (checkedNumber) {
        const date = new Date();
        date.setHours(fromTime.value + timeCounter, 0, 0, 0);
        return format(date, "H:00");
      } else if (isLastHourOfDay) {
        const date = new Date();
        date.setHours(fromTime.value + timeCounter + 1, 0, 0, 0);
        return format(date, "H:00");
      }
      return "";
    };

    /**
     * Checks if it should show the day start/end date/day name label
     * @param hourBoxes - hour box index counting from the board start
     * @param isDayEnd - last hour box of a day flag
     */
    const shouldShowDayName = (hourBoxes: number, isDayEnd?: boolean): boolean => {
      if (isDayEnd) {
        hourBoxes++;
      }
      if (hourBoxes === 0) {
        return true;
      }
      return hourBoxes % filteredHoursAmount.value === 0;
    };

    const deriveDateLabel = (hourBoxIndex: number): string => {
      const daysToAdd = Math.floor((hourBoxIndex - 1) / filteredHoursAmount.value);
      const dateToDisplay = showWeekends.value
        ? add(startDate.value, { days: daysToAdd })
        : addBusinessDays(startDate.value, daysToAdd);

      return format(dateToDisplay, dateLabelFormat, {
        locale: dateLocale(root.$i18n.locale)
      });
    };

    return {
      boxWidth,
      boxStyle,
      hours,
      dynamicClass,
      startDateLabel,
      deriveDateLabel,
      getTime,
      shouldShowDayName
    };
  }
});
