var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          dataSource: _vm.chatRooms,
          columns: _vm.columns,
          pagination: false,
          bordered: true,
          customRow: _vm.customRow
        },
        scopedSlots: _vm._u([
          {
            key: "unreadCount",
            fn: function(text, record) {
              return _c("div", {}, [
                record.unreadCount > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-red-600 text-white rounded-full text-center"
                      },
                      [_vm._v(" " + _vm._s(record.unreadCount) + " ")]
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "alternateOrderNumber",
            fn: function(text, record) {
              return _c(
                "div",
                {},
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.defineLegTypeName(record.legType)))
                  ]),
                  _vm._v("-"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "cursor-pointer border-b border-gray-400 hover:border-yellow-500",
                      on: { click: record.notifyG11Bookings }
                    },
                    [_vm._v(_vm._s(record.alternateOrderNumber))]
                  ),
                  _c("Icon", {
                    staticClass:
                      "hover:text-btYellow relative -mt-1 ml-1 cursor-pointer",
                    attrs: { name: "hyperlink", height: "17", width: "17" },
                    on: { click: record.notifyG11Planning }
                  })
                ],
                1
              )
            }
          },
          {
            key: "lastSentMessage",
            fn: function(text, record) {
              return _c("div", {}, [
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dateStringToShowDate(record.lastSentMessage)) +
                      " / " +
                      _vm._s(_vm.dateStringToShowTime(record.lastSentMessage)) +
                      " "
                  )
                ])
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }