var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-base" }, [
      _c(
        "div",
        { staticClass: "flex mt-2 mb-6" },
        [
          _c("a-switch", {
            staticClass: "mr-2",
            attrs: { size: "large" },
            on: { change: _vm.onShow24HoursChange },
            model: {
              value: _vm.show24hours,
              callback: function($$v) {
                _vm.show24hours = $$v
              },
              expression: "show24hours"
            }
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.24hours")))])
        ],
        1
      )
    ]),
    !_vm.show24hours
      ? _c(
          "div",
          { staticClass: "flex items-center headerTimeSelector" },
          [
            _c(
              "a-select",
              {
                staticClass: "timeSelector",
                attrs: {
                  "show-search": "",
                  size: "large",
                  "allow-clear": "",
                  "auto-focus": ""
                },
                on: { change: _vm.onFromTimeChange },
                model: {
                  value: _vm.fromTimeSelector,
                  callback: function($$v) {
                    _vm.fromTimeSelector = $$v
                  },
                  expression: "fromTimeSelector"
                }
              },
              _vm._l(_vm.fromOptions, function(hour) {
                return _c(
                  "a-select-option",
                  {
                    key: hour.time,
                    attrs: { value: hour.time, disabled: hour.disabled }
                  },
                  [_vm._v(_vm._s(hour.time))]
                )
              }),
              1
            ),
            _c("span", { staticClass: "mx-2  text-base" }, [
              _vm._v(_vm._s(_vm.$t("settings.to")))
            ]),
            _c(
              "a-select",
              {
                staticClass: "timeSelector",
                attrs: { "show-search": "", size: "large", "allow-clear": "" },
                on: { change: _vm.onToTimeChange },
                model: {
                  value: _vm.toTimeSelector,
                  callback: function($$v) {
                    _vm.toTimeSelector = $$v
                  },
                  expression: "toTimeSelector"
                }
              },
              _vm._l(_vm.toOptions, function(hour) {
                return _c(
                  "a-select-option",
                  {
                    key: hour.time,
                    attrs: { value: hour.time, disabled: hour.disabled }
                  },
                  [_vm._v(_vm._s(hour.time))]
                )
              }),
              1
            ),
            _c(
              "Button",
              {
                staticClass: "ml-6 text-base",
                attrs: { size: "sm", disabled: _vm.noTimeSelected },
                on: { click: _vm.updateTimeline }
              },
              [_vm._v(_vm._s(_vm.$t("actions.save")))]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }