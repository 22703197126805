import { computed, reactive, ref, watch } from "@vue/composition-api";
import { BoardBrick, BoardBrickCoordinates, BoardBrickPosition } from "@/types/board";

import store from "@/store";
import useBrickCalculus from "@/components/Board/Use/useBoardBrickCalculus";
import { GET_BOARD_WIDTH, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { startMouseScroll, stopMouseScroll } from "@/use/useMouseScroll";

const selectedBrickId = ref<string | null>(null);

export default (
  brickData: any,
  resetBrick: any,
  clearBrickEvents: (id: string) => void,
  lockBrick: (id: string) => void,
  unlockBrick: (id: string) => void
) => {
  const {
    getBrickFleetEntity,
    getStartCoordinateTimestamp,
    getEndCoordinateTimestamp,
    getBrickFleetEntityDetails
  } = useBrickCalculus();
  const boardWidth = computed(() => store.getters[GET_BOARD_WIDTH]);
  const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

  //the actual dragComponent as VUE ref component for manipulations
  const brickDragComponent = ref<any>(null);

  const brickCoordinates = ref<BoardBrickCoordinates>({
    id: brickData.value.id,
    x: brickData.value.xCoord,
    y: brickData.value.yCoord,
    width: brickData.value.width
  });

  //dirty state handling
  const brickIsDirty = ref(false);

  const setDirtyState = () => {
    brickIsDirty.value = true;
  };
  const unsetDirtyState = () => {
    brickIsDirty.value = false;
  };

  const brickInitPosition = reactive({
    top: 0,
    left: 0,
    right: 0,
    width: 0
  });

  const updateBrickCoordinates = (x: number, y: number, width: number) => {
    brickCoordinates.value.width = width;
    brickCoordinates.value.x = x;
    brickCoordinates.value.y = y;
  };

  const resetBrickCoordinates = () => {
    const { left, top, width } = brickInitPosition;
    updateBrickCoordinates(left, top, width);
  };

  /***
   * Dragging and resizing logic
   */

  const getBrickInitCoordinates = (brick?: BoardBrick): BoardBrickPosition => {
    const xCoord = (brick && brick.xCoord) || brickData.value.xCoord;
    const yCoord = (brick && brick.yCoord) || brickData.value.yCoord;
    const width = (brick && brick.width) || brickData.value.width;
    return {
      top: yCoord,
      left: xCoord,
      right: boardWidth.value - width - xCoord,
      width
    };
  };

  const setBrickInitPosition = (brick?: BoardBrick) => {
    const { top, left, right, width } = getBrickInitCoordinates(brick);

    brickInitPosition.top = top;
    brickInitPosition.left = left;
    brickInitPosition.right = right;
    brickInitPosition.width = width;
  };

  //check if we have moved the brick
  const brickWasModified = computed(() => {
    if (brickCoordinates.value) {
      return (
        Math.abs(brickCoordinates.value.y - brickInitPosition.top) > 5 ||
        Math.abs(brickCoordinates.value.x - brickInitPosition.left) > 5 ||
        Math.abs(brickCoordinates.value.width - brickInitPosition.width) > 5
      );
    }
    return false;
  });

  const headerHeight = computed(() => {
    const header = document.querySelector<HTMLElement>(".header");
    const boardTimeLineHeader = document.querySelector<HTMLElement>(".boardTimeLineHeader");
    return header !== null && boardTimeLineHeader !== null ? header.offsetHeight + boardTimeLineHeader.offsetHeight : 0;
  });

  const sidebarWidth = computed(() => {
    const sidebar = document.querySelector<HTMLElement>(".sidebar");
    return sidebar != null ? sidebar.offsetWidth : 0;
  });

  const isInViewport = (element: Element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= headerHeight.value &&
      rect.left >= sidebarWidth.value &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const focusActiveBrick = () => {
    if (!isInViewport(brickDragComponent.value.$el)) {
      brickDragComponent.value.$el.scrollIntoView({ block: "center", inline: "center" });
    }
  };

  //logic for calculating the changes and updating the brick dates and time
  const newBrickAttributes = ref<any>({
    effectiveStartTime: undefined,
    effectiveEndTime: undefined,
    truckId: undefined,
    driverId: undefined,
    trailerId: undefined,
    subcontractor: undefined,
    boards: []
  });

  const calculateNewBrickAttributes = async (brickCoords: BoardBrickPosition) => {
    const { left: brickStart, top, width } = brickCoords;
    const brickEnd = brickStart + width;

    updateBrickCoordinates(brickStart, top, width);

    const effectiveStartTime = getStartCoordinateTimestamp(brickStart);
    const effectiveEndTime = getEndCoordinateTimestamp(brickEnd);

    newBrickAttributes.value = {
      effectiveStartTime,
      effectiveEndTime,
      truckId: brickData.value.truckId,
      driverId: brickData.value.driverId,
      trailerId: brickData.value.trailerId,
      subcontractor: brickData.value.subcontractor,
      boards: brickData.value.boards
    };

    if (top !== brickInitPosition.top) {
      const newFleetEntity = getBrickFleetEntity(top);
      if (typeof newFleetEntity === "undefined") {
        await resetBrick();
        return;
      }

      const brickFleetEntityDetails = getBrickFleetEntityDetails(newFleetEntity);

      newBrickAttributes.value = {
        effectiveStartTime,
        effectiveEndTime,
        ...brickFleetEntityDetails
      };
    }

    // console.log(`NEW BRICK ATTRIBUTES ${JSON.stringify(newBrickAttributes.value, null, 2)}`);
  };

  // handle the update of the brick
  const brickIsUpdating = ref(false);

  const isBrickInitialized = (): boolean => {
    const { top, left, right, width } = brickInitPosition;
    return top > 0 || left > 0 || right > 0 || width > 0;
  };

  const onActivated = () => {
    if (!isBrickInitialized()) {
      setBrickInitPosition();
    }
    if (!brickData.value.dropped) {
      clearBrickEvents(brickData.value.id);
    }
    if (isBoardEditModeActive.value) {
      setBrickEditState();
    }
    selectedBrickId.value = brickData.value.id;
    brickDragComponent.value.$el.focus();
  };

  const onDeactivated = () => {
    if (isBoardEditModeActive.value) {
      unsetBrickEditState();
    }
  };

  const setBrickEditState = async () => {
    setDirtyState();
    await lockBrick(brickData.value.id);
  };

  const unsetBrickEditState = async () => {
    unsetDirtyState();
    await unlockBrick(brickData.value.id);
  };

  const onWebsocketUpdate = () => {
    setBrickInitPosition(brickData);
    resetBrickCoordinates();
  };

  const onBrickResizeStop = async (params: BoardBrickPosition) => {
    await calculateNewBrickAttributes(params);
    if (brickWasModified.value) {
      setBrickEditState();
    }
  };

  const onBrickDragging = () => {
    startMouseScroll();
  };

  const onBrickDragStop = async (brickCoords: BoardBrickPosition) => {
    stopMouseScroll();
    await calculateNewBrickAttributes(brickCoords);
  };

  const initializeDropped = async () => {
    await calculateNewBrickAttributes(getBrickInitCoordinates());
    setBrickEditState();
  };

  const initializeSelected = () => {
    selectedBrickId.value = brickData.value.id;
    brickDragComponent.value.active = true;
    brickDragComponent.value.$el.focus();
  };

  //watches brickWasModified value. if it is updated without dirty state we re-init the position
  watch(
    () => brickWasModified.value,
    () => {
      if (brickWasModified.value && !brickIsDirty.value) {
        setBrickInitPosition(brickData);
      }
    }
  );

  watch([selectedBrickId, brickData], ([selectedBrickId, brickData]) => {
    const boardBrick = brickData as BoardBrick;

    if (selectedBrickId != null && selectedBrickId !== boardBrick.id) {
      //resets all active states to false expect the selected one
      brickDragComponent.value.active = false;

      //clear events
      if (boardBrick.selected) {
        clearBrickEvents(boardBrick.id);
      }
    }
    if (boardBrick?.updated) {
      onWebsocketUpdate();
    } else if (!brickIsDirty.value) {
      const { left, top, width } = getBrickInitCoordinates(boardBrick);
      updateBrickCoordinates(left, top, width);
    }
    if (boardBrick?.selected) {
      initializeSelected();
    }
  });

  return {
    onActivated,
    onDeactivated,
    onBrickResizeStop,
    onBrickDragStop,
    onBrickDragging,
    initializeDropped,
    initializeSelected,
    setBrickInitPosition,
    unsetBrickEditState,
    setDirtyState,
    unsetDirtyState,
    resetBrickCoordinates,
    getBrickInitCoordinates,
    focusActiveBrick,
    isBrickInitialized,
    brickWasModified,
    clearBrickEvents,
    brickDragComponent,
    brickIsDirty,
    brickIsUpdating,
    brickCoordinates,
    newBrickAttributes,
    brickInitPosition
  };
};
