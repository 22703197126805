var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isActive
        ? _c("SplitScreen", {
            ref: "fleetFormComponentRef",
            attrs: {
              isActive: _vm.isActive,
              isLoading: _vm.isLoading,
              "custom-class": "w-1/2"
            },
            on: { closeHandler: _vm.closeFleetAction },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "font-extrabold" }, [
                        _vm._v(_vm._s(_vm.$t("fleet.actionReminderTitle")))
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "pt-4" },
                        [
                          _c("FleetSelection", {
                            attrs: {
                              fleetMember: _vm.fleetMembers,
                              optimized: _vm.isUpdate
                            },
                            on: { fleetChange: _vm.updateSelectedFleet }
                          }),
                          _vm.multipleSelectedFleets
                            ? _c("a-alert", {
                                staticClass: "mb-6",
                                attrs: {
                                  message: _vm.$t("fleet.multipleFleets"),
                                  banner: "",
                                  closable: ""
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.alertConfirmed()
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.isUpdate || _vm.isActionUpdate
                            ? _c("FleetActionForm", {
                                key: _vm.fleetActionKey,
                                attrs: { formData: _vm.actionFormData },
                                on: {
                                  "form-change": _vm.updateActionForm,
                                  "form-is-valid": _vm.setActionFormValidState
                                }
                              })
                            : _vm._e(),
                          !_vm.isUpdate || _vm.isReminderUpdate
                            ? _c("FleetReminderForm", {
                                key: _vm.fleetReminderKey,
                                attrs: { formData: _vm.reminderFormData },
                                on: {
                                  "form-change": _vm.updateReminderForm,
                                  "form-is-valid": _vm.setReminderFormValidState
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actionBar",
                  fn: function(ref) {
                    var close = ref.close
                    return [
                      _c(
                        "div",
                        { staticClass: "w-full flex" },
                        [
                          _vm.isUpdate
                            ? _c(
                                "Button",
                                {
                                  attrs: { variant: "tertiary" },
                                  on: { click: _vm.openDeleteDialog }
                                },
                                [_vm._v(_vm._s(_vm.$t("actions.remove")))]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "ml-auto flex" },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "mr-4",
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function($event) {
                                      return close()
                                    }
                                  }
                                },
                                [
                                  !_vm.existingFormUpdated
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("actions.close")))
                                      ])
                                    : _vm._e(),
                                  _vm.existingFormUpdated
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("actions.cancel")))
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              !_vm.isUpdate
                                ? _c(
                                    "Button",
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        disabled:
                                          !_vm.hasValidForm || _vm.isLoading
                                      },
                                      on: { click: _vm.createActionAndClear }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("actions.saveAndNew"))
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isUpdate
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.hasValidForm || _vm.isLoading
                                      },
                                      on: { click: _vm.createActionAndClose }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  )
                                : _vm._e(),
                              _vm.isUpdate
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.hasValidForm ||
                                          !_vm.existingFormUpdated
                                      },
                                      on: { click: _vm.updateActionAndClose }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.update")))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3671817510
            )
          })
        : _vm._e(),
      _vm.deleteDialog.isActive
        ? _c("Dialog", {
            attrs: {
              title: _vm.deleteDialog.title,
              message: _vm.deleteDialog.message,
              "is-active": _vm.deleteDialog.isActive,
              "is-centered": true,
              "show-buttons": true
            },
            on: {
              closeDialog: _vm.closeDeleteDialog,
              confirm: _vm.deleteAction
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }