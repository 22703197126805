/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat': {
    width: 1308,
    height: 1197.333,
    viewBox: '0 0 981 898',
    data: '<path pid="0" d="M347 1l-41.5.5-9.5 3.3c-11.6 4-20.2 8.6-29.7 15.9C250.7 32.5 237.7 52 233 70.5c-3.2 12.3-5.1 53.5-3.4 70.3l.7 6.1 219.1.4c142.9.3 221.9.8 227.1 1.5 15.7 2 35.3 9.9 51.5 20.7 9.5 6.3 25.9 22.7 32.7 32.6 8.5 12.5 15 26.1 19.4 40.9 3.2 10.7 3.8 37.8 4.9 231.4l1.1 181.9 18.6 20.6c27 29.9 37.1 40 40.9 40.7 4.7.9 11.1-1.3 15.5-5.3l3.9-3.5V540.2l10.8-.6c32.3-2.1 49.1-7.7 67.3-22.4 14.9-12.1 26.3-28.6 32.4-46.7 4.4-13 4.7-29.9 4.2-221l-.5-173-2.7-7c-14.7-38.2-44.3-63.2-78.9-66.6C879.9 1.2 448.9-.3 347 1z"/><path pid="1" d="M153.5 180.9c-73.4.7-75.1.7-82 2.9-17.5 5.5-29.4 12.9-43 26.6-11.8 11.9-18.2 22.2-23.4 37.1l-3.4 10-.8 30C-.2 330.6-.2 588.2 1 615.3c.6 12.7 1.7 26.2 2.6 30.5C6.7 661 15 675.1 29.5 689.5c13.2 13.3 27.8 21.9 42.6 25.1 9.7 2 42.2 3.3 87 3.4h35.6l.6 46.7c.4 25.6.7 63.9.7 85v38.4l2.8 2.5c6.4 5.8 14.4 8.4 18.9 6.1 4.5-2.4 21.3-20.2 95.5-101.8l69.7-76.6 132.8-.6c84.8-.4 137.4-1 145.6-1.7 16.6-1.5 25.8-3.9 36.8-9.7 18.8-9.9 33.6-25 42.9-43.8 9.2-18.6 8.4 2 8.7-214.6l.4-191.5-3.2-9c-10.6-30.4-30.5-50.7-62.8-63.8l-6.4-2.6-224.6-.4c-123.5-.2-258.3 0-299.6.3zm231.3 164.2c9.5 11.1 9.6 16.4.3 26.1l-3.5 3.8H288c-125.5-.1-136.1-.7-138.9-7.8-3.2-8.1-2-14.2 4.1-20.8l4.1-4.4h224.8l2.7 3.1zm213.9 100.2c2.8 2.9 5.4 6.3 5.8 7.6 1.3 4.3-.4 8.9-5.6 14.6l-5.1 5.5H157.4l-3.6-3.8c-8-8.1-8.5-15.7-1.7-24.2l3.9-5H593.7l5 5.3zm-2.6 94.8c4 2.8 8.1 10.2 8.3 14.8 0 3.2-.7 4.8-4.7 9.5l-4.9 5.6H156.2l-3.2-3.7c-3.6-4-6-9.1-6-12.5 0-3.4 3.8-10.4 6.8-12.5 4.1-2.7 17.1-2.9 239.5-3.1l199.9-.2 2.9 2.1z"/>'
  }
})
