




















































import { defineComponent, PropType } from "@vue/composition-api";
import { DisplayLegContent, LegSize } from "@/types/leg";
import CalloutLegTrailer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegTrailer.vue";
import CalloutLegDriver from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDriver.vue";
import ClosablePopover from "@/components/ClosablePopover.vue";

export default defineComponent({
  name: "LegAffiliationPopover",
  components: {
    CalloutLegTrailer,
    CalloutLegDriver,
    ClosablePopover
  },
  props: {
    hasDifferentDriver: {
      type: Boolean,
      default: false
    },
    hasDifferentTrailer: {
      type: Boolean
    },
    hasSubiWithPrimaryBoard: {
      type: Boolean,
      default: false
    },
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    onTouchStartHandler: {
      type: Function,
      default: () => ({})
    },
    legSize: {
      type: Object as PropType<LegSize>,
      required: true
    },
    isViewedInPopover: {
      type: Boolean,
      default: false
    }
  }
});
