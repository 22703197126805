import Vue from "vue";
import {
  Select,
  Input,
  Alert,
  DatePicker,
  TimePicker,
  Icon,
  Skeleton,
  Slider,
  Collapse,
  Checkbox,
  Row,
  Col,
  Tooltip,
  Switch,
  InputNumber,
  Divider,
  Popover,
  ConfigProvider,
  Table,
  Spin
} from "ant-design-vue";

Vue.use(Select);
Vue.use(Input);
Vue.use(Alert);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Icon);
Vue.use(Skeleton);
Vue.use(Slider);
Vue.use(Collapse);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(InputNumber);
Vue.use(Divider);
Vue.use(Popover);
Vue.use(ConfigProvider);
Vue.use(Table);
Vue.use(Spin);
