<template>
  <div tabindex="0">
    <a-popover v-model="isPopoverVisible" placement="bottomRight" trigger="click" class="cursor-pointer">
      <template #title>
        {{ $t("settings.title") }}
      </template>
      <template #content>
        <nav class="menu flex justify-center">
          <ul class="flex flex-col justify-center">
            <li @click="closePopover">
              <a
                tabindex="0"
                target="_blank"
                href="https://intranet/?App=ContentManager3&action=getPageContent&pageid=13140"
                class="flex items-center block"
                ><Icon name="help" height="18" width="18" class="mr-1" />{{ $t("helpMenu") }}</a
              >
            </li>
            <li @click="closePopover"><board-settings-button @showBoardSettings="showBoardSettings" /></li>
            <li @click="closePopover"><logout-button /></li>
          </ul>
        </nav>
      </template>
      <Icon name="3dots" class="hover:text-btYellow" />
    </a-popover>
    <nav-settings
      v-if="boardSettingsActive"
      :isActive="boardSettingsActive"
      @boardSettingsClosed="closeBoardSettings"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import LogoutButton from "@/components/Buttons/LogoutButton.vue";
import BoardSettingsButton from "@/components/Buttons/BoardSettingsButton.vue";
import NavSettings from "@/components/Nav/NavSettings/NavSettings.vue";

export default defineComponent({
  components: { Icon, LogoutButton, BoardSettingsButton, NavSettings },
  setup() {
    const isPopoverVisible = ref(false);
    const closePopover = () => {
      isPopoverVisible.value = false;
    };
    const boardSettingsActive = ref(false);
    const showBoardSettings = (): void => {
      boardSettingsActive.value = true;
    };
    const closeBoardSettings = (): void => {
      boardSettingsActive.value = false;
    };
    return { boardSettingsActive, showBoardSettings, closeBoardSettings, isPopoverVisible, closePopover };
  }
});
</script>

<style scoped lang="scss">
.menu {
  @apply text-lg;
  min-width: 100px;
  li:not(:last-child) {
    @apply mb-2;
  }
  a {
    @apply text-gray-700 hover:text-yellow-500;
  }
  button {
    @apply text-gray-700 hover:text-yellow-500;
  }
}
</style>
