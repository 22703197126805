






import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "Button",
  props: {
    variant: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const dynamicClass = computed(() => props.variant);
    const sizeClass = computed(() => (props.size === "sm" ? "button--small" : ""));
    const disabledClass = computed(() => {
      return props.disabled ? "button--disabled" : "";
    });
    return {
      dynamicClass,
      disabledClass,
      sizeClass
    };
  }
});
