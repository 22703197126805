var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "previousLegInfo leg--previous-detail w-full flex flex-row items-center px-2",
      class: [_vm.heightClass, _vm.backgroundGradientClass]
    },
    [
      _c(
        "div",
        { staticClass: "previousLegInfo__icon" },
        [
          _vm.data.size.xl
            ? _c("Icon", {
                attrs: { name: _vm.previousLegIcon, width: "24", height: "24" }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex justify-between items-center flex-1 pl-4 pr-4" },
        [
          _c(
            "div",
            { staticClass: "flex flex-1 flex-row flex-wrap leading-4" },
            [
              _c(
                "div",
                { staticClass: "previousLegInfo__time font-semibold mr-4" },
                [_vm._v(" " + _vm._s(_vm.arrivalDate) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "previousLegInfo__from font-semibold",
                  class: { "mx-auto": _vm.data.size.xl || _vm.data.size.lg }
                },
                [_vm._v(" " + _vm._s(_vm.legTypeAbbreviation) + " ")]
              )
            ]
          ),
          !_vm.data.size.sm && !_vm.data.size.xs
            ? _c(
                "div",
                {
                  staticClass:
                    "previousLegInfo__departure font-semibold flex  flex-wrap leading-4 ml-1"
                },
                [
                  _c("div", [
                    _vm._v(_vm._s("" + _vm.departureInfo.departureDate))
                  ]),
                  _vm.departureInfo.vehicleNumber !== "Not done yet"
                    ? _c("div", [
                        _vm._v(_vm._s("/" + _vm.departureInfo.vehicleNumber))
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }