







































import { defineComponent, ref, onBeforeMount, computed, watch } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import multiSelect from "vue-multiselect";
import store from "@/store";
import { GET_FILTERED_BOARD_PROFILE } from "@/store/board";
import {
  DEACTIVATE_DRIVER_COMMENT_TOGGLE,
  GET_TRAILER_OPTIONS,
  GET_TRAILERS_SELECTED,
  INIT_TRAILERS_DROPDOWN,
  SET_TRAILERS_SELECTED,
  FETCH_BOARD_FLEET_TRAILERS
} from "@/store/fleet/";
import FilterButton from "@/components/Buttons/FilterButton.vue";
import { TrailerMeta } from "@/types/fleet";

export default defineComponent({
  name: "TrailerFilter",
  components: { Icon, multiSelect, FilterButton },
  props: {
    editModeActive: {
      type: Boolean
    }
  },
  setup() {
    const value = ref([]);
    const options = computed(() => store.getters[GET_TRAILER_OPTIONS]);
    const selectedTrailers = computed(() => store.getters[GET_TRAILERS_SELECTED]);

    onBeforeMount(() => {
      store.dispatch(INIT_TRAILERS_DROPDOWN);
    });

    watch(selectedTrailers, last => {
      value.value = last;
    });

    const openDropdown = (): void => {
      store.dispatch(DEACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    const dropdownHandler = (value: TrailerMeta[]): void => {
      if (value !== selectedTrailers.value) {
        store.commit(
          SET_TRAILERS_SELECTED,
          value.map(trailer => ({ name: trailer.id, id: trailer.id }))
        );

        store.dispatch(FETCH_BOARD_FLEET_TRAILERS, { data: value }, { root: true });

        store.dispatch(GET_FILTERED_BOARD_PROFILE);
      }
      //scroll always to top when changing selection
      window.scrollTo(0, 0);
    };

    return { value, options, openDropdown, selectedTrailers, dropdownHandler };
  }
});
