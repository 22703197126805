







































































import { i18n } from "@/i18n/i18n";
import { BoardLeg, LegTransportChecklist, LegTransportDetail, LegTransportStatus } from "@/types/leg";
import { computed, defineComponent, PropType, reactive, ref } from "@vue/composition-api";
import multiSelect from "vue-multiselect";

interface StatusObject {
  identifier: string;
  label: string;
}

export default defineComponent({
  components: {
    multiSelect
  },
  props: {
    leg: {
      type: Object as PropType<BoardLeg>
    }
  },
  setup(props, { emit }) {
    const checklistPanelKey = "checklist-panel";
    const collapsedPanelKey = "collapsed-panel";

    const areAllChecked = (details?: LegTransportDetail[]): boolean => {
      return (details || []).every(detail => detail.checked);
    };

    const allChecked = reactive({
      documents: areAllChecked(props.leg?.transportInfo?.documents),
      customs: areAllChecked(props.leg?.transportInfo?.customs),
      specialCustoms: areAllChecked(props.leg?.transportInfo?.specialCustoms),
      specialEquipments: areAllChecked(props.leg?.transportInfo?.specialEquipments),
      temperatures: areAllChecked(props.leg?.transportInfo?.temperatures)
    });

    const legDetails = reactive<LegTransportChecklist>({
      status: props.leg?.status,
      documents: props.leg?.transportInfo?.documents || [],
      customs: props.leg?.transportInfo?.customs || [],
      specialCustoms: props.leg?.transportInfo?.specialCustoms || [],
      specialEquipments: props.leg?.transportInfo?.specialEquipments || [],
      temperatures: props.leg?.transportInfo?.temperatures || []
    });

    const onCheckAllChanged = (textCategory: string) => {
      allChecked[textCategory] = !allChecked[textCategory];
      legDetails[textCategory] = legDetails[textCategory].map(detailText => ({
        ...detailText,
        checked: allChecked[textCategory]
      }));
      emit("detailChanged", legDetails);
    };

    const onChecklistChanged = (text: string, textCategory: string) => {
      let allCheckedInCategory = true;
      legDetails[textCategory] = legDetails[textCategory].map(detailText => {
        const checked = detailText.checked;
        if (detailText.text === text) {
          allCheckedInCategory = allCheckedInCategory && !checked;
          return {
            text: detailText.text,
            checked: !checked
          };
        } else {
          allCheckedInCategory = allCheckedInCategory && checked;
        }
        return detailText;
      });
      allChecked[textCategory] = allCheckedInCategory;
      emit("detailChanged", legDetails);
    };

    const buildStatusObject = (status: string): StatusObject => {
      return {
        identifier: status,
        label: i18n.t(`transportInfoEditor.values.${status.toLowerCase()}`).toString()
      };
    };

    const statusOptions = ref<Array<StatusObject>>(Object.values(LegTransportStatus).map(buildStatusObject));

    const statusOptionsValue = ref<StatusObject>(buildStatusObject(legDetails.status || LegTransportStatus.NO_STATUS));

    const onStatusChanged = () => {
      legDetails.status =
        statusOptionsValue.value.identifier === LegTransportStatus.NO_STATUS
          ? undefined
          : statusOptionsValue.value.identifier;
      emit("detailChanged", legDetails);
    };

    const checklistShouldExpand = computed(() => {
      if (props.leg == null) {
        return false;
      }
      const { status, transportInfo } = props.leg;

      if (status) {
        return true;
      }

      return (
        transportInfo.documents?.some(item => !item.checked) ||
        transportInfo.customs?.some(item => !item.checked) ||
        transportInfo.specialCustoms?.some(item => !item.checked) ||
        transportInfo.specialEquipments?.some(item => !item.checked) ||
        transportInfo.temperatures?.some(item => !item.checked)
      );
    });

    const activePanelKey = computed(
      (): string => (checklistShouldExpand.value && checklistPanelKey) || collapsedPanelKey
    );

    return {
      legDetails,
      activePanelKey,
      checklistPanelKey,
      collapsedPanelKey,
      allChecked,
      onStatusChanged,
      onChecklistChanged,
      onCheckAllChanged,
      statusOptions,
      statusOptionsValue
    };
  }
});
