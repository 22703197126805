import { CompositeSubcontractorId, CompositeTruckId, Trailer } from "@/types/fleet";
import { addToSearchIndex, buildSearchId, removeFromSearchIndex } from "@/store/board/useSearchIndex";
import { stringifyNestedValues } from "@/use/useObject";
import { BoardEntityType } from "@/types/board";
import { FleetEntityType } from "@/types/fleet";
import { getLastLocationName } from "./useLastLocation";

const buildNoSeqSubcontractorId = (subcontractorId: string, boardId: string): string => {
  return `${subcontractorId}#${boardId}`;
};

const buildTruckId = (truckId: string, driverId?: string | null): string => {
  if (driverId) {
    return `${truckId}#${driverId}`;
  }
  return truckId;
};

const spreadTruckId = (truckId: string): CompositeTruckId => {
  if (!truckId) {
    throw new Error("Truck ID must not be undefined");
  }

  const parts: string[] = truckId.split("#");

  return {
    id: parts[0],
    driverId: parts.length === 2 ? parts[1] : undefined
  };
};

const buildTrailerId = (trailerId: string, lastLocationName?: string | null): string => {
  if (lastLocationName) {
    return `${trailerId}#${lastLocationName}`;
  }
  return trailerId;
};

type CompositeTrailerId = {
  id: string;
  lastLocationName?: string;
};

const spreadTrailerId = (trailerId: string): CompositeTrailerId => {
  if (trailerId == null) {
    throw new Error("Trailer ID must not be undefined");
  }

  const parts: string[] = trailerId.split("#");

  return {
    id: parts[0],
    lastLocationName: parts.length === 2 ? parts[1] : undefined
  };
};

const buildSubcontractorId = (subcontractorId: string, boardId: string, sequence: number): string => {
  return `${subcontractorId}#${boardId}#${sequence}`;
};

const spreadSubcontractorId = (subcontractorId: string): CompositeSubcontractorId => {
  if (!subcontractorId) {
    throw new Error("Subcontractor ID must not be undefined");
  }
  const parts: string[] = subcontractorId.split("#");

  if (parts.length !== 3) {
    throw new Error("Subcontractor ID must have format 'id#boardId#sequence'");
  }

  return {
    id: parts[0],
    boardId: parts[1],
    sequence: +parts[2]
  };
};

const getNoSeqSubcontractorId = (subcontractorId: string): string => {
  const { id, boardId } = spreadSubcontractorId(subcontractorId);
  return buildNoSeqSubcontractorId(id, boardId);
};

const groupBy = (arr, key) => {
  const initialValue = {};
  return arr.reduce((acc, cval) => {
    const myAttribute = cval.metadata[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

const filterTrailers = (fleets: Trailer[], trailer) => {
  const tempTrailers: Trailer[] = [];
  for (let i = 0; i < trailer.length; i++) {
    fleets.forEach((trail: Trailer) => {
      trail.metadata.chassisType = trail.metadata.chassisType ? trail.metadata.chassisType : "undefined";
      if (trail.metadata.chassisType === trailer[i].name) {
        addToSearchIndex(buildSearchId(trail.id, BoardEntityType.FLEET), stringifyNestedValues(trail));
        tempTrailers.push(trail);
      } else {
        removeFromSearchIndex(buildSearchId(trail.id, BoardEntityType.FLEET));
      }
    });
  }
  return tempTrailers.map(trailer => ({
    ...trailer,
    type: FleetEntityType.TRAILER,
    moved: false,
    groupGlueId: trailer.metadata?.lastLocation ? getLastLocationName(trailer.metadata.lastLocation) : undefined
  }));
};

export {
  buildSubcontractorId,
  spreadSubcontractorId,
  buildNoSeqSubcontractorId,
  getNoSeqSubcontractorId,
  buildTruckId,
  spreadTruckId,
  buildTrailerId,
  spreadTrailerId,
  groupBy,
  filterTrailers
};
