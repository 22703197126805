type Chat = {
  driverId: string;
  driverName: string;
  legId: string;
  legType: string;
  alternateOrderNumber: string;
  lastSentMessage: string;
  boardIds: string[];
  bookingId: string;
  unreadCount: number;
  g11Actions: any;
  messages?: ChatMessage[];
};

type ChatMessage = {
  sent: string;
  author: string;
  text: string;
  sequence: number;
  origin: string;
};

type ChatMessagePost = {
  text: string;
};

enum MessageOrigin {
  WEB = "WEB",
  APP = "APP"
}

export { Chat, ChatMessage, ChatMessagePost, MessageOrigin };
