import { computed, ref } from "@vue/composition-api";

import store from "@/store";
import { GET_FROM_TO_DATE_WITH_TIME, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { BoardBrickPosition, BoardBrickType } from "@/types/board";
import { translateAndToastError, translateAndToastSuccess } from "@/use/useToast";
import useBoardBrickDrag from "@/components/Board/Use/useBoardBrickDrag";
import { BoardFleetAction } from "@/types/action";
import {
  CLEAR_FLEET_ACTION_EVENTS,
  CREATE_FLEET_ACTIONS,
  LOCK_FLEET_ACTION,
  REMOVE_FLEET_ACTIONS,
  SET_FLEET_ACTION_TO_SELECTED,
  SET_IGNORABLE_BRICK_ID,
  UNLOCK_FLEET_ACTION,
  UPDATE_FLEET_ACTION
} from "@/store/brick";
import { i18n } from "@/i18n/i18n";
import { FleetEntityType } from "@/types/fleet";
import { brickAssignableToFleetEntity } from "@/use/useBrick";
import { isAfter, isBefore } from "date-fns";

export default (actionData: any) => {
  const {
    brickIsDirty,
    newBrickAttributes,
    brickIsUpdating,
    brickWasModified,
    brickDragComponent,
    brickCoordinates,
    setDirtyState,
    unsetDirtyState,
    resetBrickCoordinates,
    focusActiveBrick,
    setBrickInitPosition,
    onBrickDragStop,
    onActivated,
    onDeactivated,
    onBrickResizeStop,
    onBrickDragging,
    initializeDropped,
    initializeSelected
  } = useBoardBrickDrag(actionData, resetAction, clearActionEvents, lockAction, unlockAction);

  const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

  //lock - unlock logic
  const actionLocked = ref(false);

  const dirtyStateClass = computed(() => {
    if (!brickIsDirty.value) {
      return "action-brick__overlay";
    }
    if (actionData.value.trailerId) {
      return "action-brick--dirty__trailer";
    }
    if (actionData.value.driverId) {
      return "action-brick--dirty__driver";
    }
    return "action-brick--dirty";
  });

  async function lockAction(actionId: string) {
    if (!actionLocked.value) {
      try {
        await store.dispatch(LOCK_FLEET_ACTION, actionId);
        actionLocked.value = true;
      } catch (e) {
        resetBrickCoordinates();
        unsetDirtyState();
      }
    }
  }

  async function unlockAction(actionId: string) {
    if (actionLocked.value) {
      try {
        await store.dispatch(UNLOCK_FLEET_ACTION, actionId);
        actionLocked.value = false;
      } catch (e) {
        //show errors which are not handled globally
      }
    }
  }

  /***
   * Dragging and resizing logic
   */

  async function resetAction() {
    if (isBoardEditModeActive.value) {
      await unsetActionEditState();
      return;
    }
    if (actionData.value.copied) {
      await store.dispatch(REMOVE_FLEET_ACTIONS, [actionData.value.id]);
    } else {
      await store.commit(SET_FLEET_ACTION_TO_SELECTED, actionData.value.id);
    }
    await unsetActionEditState();
    //scroll to the active selected action
    focusActiveBrick();
  }

  const getUpToDateAction = (): BoardFleetAction => {
    const {
      effectiveStartTime: newEffectiveStartTime,
      effectiveEndTime: newEffectiveEndTime,
      truckId: newTruckId,
      trailerId: newTrailerId,
      driverId: newDriverId,
      subcontractor: newSubcontractor,
      boards: newBoards
    } = newBrickAttributes.value;

    const { truckId, trailerId, driverId, subcontractor, fromDate, toDate } = actionData.value;
    const { fromDate: boardFromDate, toDate: boardToDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];

    const updatedAction = {
      truckId: null,
      trailerId: null,
      driverId: null,
      subcontractor: null
    };

    if (actionData.value.entityType) {
      switch (actionData.value.entityType) {
        case FleetEntityType.TRUCK:
          updatedAction.truckId = newTruckId;
          break;
        case FleetEntityType.TRAILER:
          updatedAction.trailerId = newTrailerId;
          break;
        case FleetEntityType.DRIVER:
          updatedAction.driverId = newDriverId;
          break;
        case FleetEntityType.SUBCONTRACTOR:
          updatedAction.subcontractor = newSubcontractor;
          break;
      }
    } else {
      if (truckId) {
        updatedAction.truckId = newTruckId;
      }
      if (trailerId) {
        updatedAction.trailerId = newTrailerId;
      }
      if (driverId) {
        updatedAction.driverId = newDriverId;
      }
      if (subcontractor) {
        updatedAction.subcontractor = newSubcontractor;
      }
    }

    const fromDateTimezone: string | undefined = fromDate?.timezone;
    const toDateTimezone: string | undefined = toDate?.timezone;

    let newEffectiveStart = newEffectiveStartTime;
    if (isBefore(new Date(fromDate.date), boardFromDate)) {
      newEffectiveStart = fromDate.date;
    }
    let newEffectiveEnd = newEffectiveEndTime;
    if (isAfter(new Date(toDate.date), boardToDate)) {
      newEffectiveEnd = toDate.date;
    }

    return {
      ...actionData.value,
      ...updatedAction,
      boards: newBoards,
      fromDate: {
        date: newEffectiveStart,
        timezone: fromDateTimezone
      },
      toDate: {
        date: newEffectiveEnd,
        timezone: toDateTimezone
      }
    };
  };

  const updateAction = async () => {
    brickIsUpdating.value = true;
    const updateRequest = getUpToDateAction();
    try {
      store.commit(SET_IGNORABLE_BRICK_ID, updateRequest.id);
      if (updateRequest.copied) {
        const isFleetValid = brickAssignableToFleetEntity(updateRequest.entityType!, {
          ...updateRequest,
          brickType: BoardBrickType.ACTION
        });
        if (!isFleetValid) {
          brickIsUpdating.value = false;
          return;
        }
        await store.dispatch(CREATE_FLEET_ACTIONS, {
          ...updateRequest,
          fromLocation: (updateRequest.fromLocation?.id && updateRequest.fromLocation) || undefined,
          toLocation: (updateRequest.toLocation?.id && updateRequest.toLocation) || undefined
        });
      } else {
        await store.dispatch(UPDATE_FLEET_ACTION, updateRequest);
      }
      await unsetActionEditState();
      brickIsUpdating.value = false;
      setBrickInitPosition();
      resetBrickCoordinates();
      translateAndToastSuccess(
        actionData.value.isReminder ? "actionReminder.reminderUpdated" : "actionReminder.actionUpdated"
      );
    } catch (e) {
      await resetAction();
      store.commit(SET_IGNORABLE_BRICK_ID, null);
      brickIsUpdating.value = false;
    }
  };

  const resizeSticks = computed(() => {
    const { fromDate, toDate, isReminder } = actionData.value;
    const { fromDate: boardFromDate, toDate: boardToDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];
    let isRightEnabled = true;
    let isLeftEnabled = true;
    if (isReminder) {
      isRightEnabled = false;
      isLeftEnabled = false;
    }
    if (isRightEnabled && isAfter(new Date(toDate.date), boardToDate)) {
      isRightEnabled = false;
    }
    if (isLeftEnabled && isBefore(new Date(fromDate.date), boardFromDate)) {
      isLeftEnabled = false;
    }
    const sticks: string[] = [];
    if (isRightEnabled) {
      sticks.push("mr");
    }
    if (isLeftEnabled) {
      sticks.push("ml");
    }
    return sticks;
  });

  const allowedAxisMovement = computed(() => {
    const { fromDate, toDate } = actionData.value;
    const { fromDate: boardFromDate, toDate: boardToDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];
    if (isAfter(new Date(toDate?.date), boardToDate) || isBefore(new Date(fromDate.date), boardFromDate)) {
      return "y";
    }
    return "both";
  });

  const isDraggable = computed(() => {
    return true;
  });

  function clearActionEvents(actionId: string) {
    store.commit(CLEAR_FLEET_ACTION_EVENTS, actionId);
  }

  const setActionEditState = async () => {
    setDirtyState();
    await lockAction(actionData.value.id);
  };

  const unsetActionEditState = async () => {
    unsetDirtyState();
    await unlockAction(actionData.value.id);
  };

  const toastAndResetInvalidDrop = async (actionType: string, fleetEntityType: string) => {
    translateAndToastError("errors.FLEET_INVALID_BRICK_DROP", {
      brickType: actionType,
      fleetEntityType
    });
    await resetAction();
    initializeSelected();
  };

  const onDragStop = async (params: BoardBrickPosition) => {
    await onBrickDragStop(params);
    const { isReminder, truckId, trailerId, driverId, subcontractor } = actionData.value;
    const actionType = i18n.t(isReminder ? "actionReminder.reminder" : "actionReminder.action").toString();
    const {
      truckId: newTruckId,
      subcontractor: newSubcontractor,
      trailerId: newTrailerId,
      driverId: newDriverId
    } = newBrickAttributes.value;

    if (!actionData.value.copied) {
      if (truckId && newTruckId == null) {
        await toastAndResetInvalidDrop(actionType, i18n.t("fleet.truck").toString());
        return;
      }

      if (trailerId && newTrailerId == null) {
        await toastAndResetInvalidDrop(actionType, i18n.t("fleet.trailer").toString());
        return;
      }

      if (driverId && newDriverId == null) {
        await toastAndResetInvalidDrop(actionType, i18n.t("fleet.driver").toString());
        return;
      }

      if (subcontractor && newSubcontractor == null) {
        await toastAndResetInvalidDrop(actionType, i18n.t("fleet.subcontractor").toString());
        return;
      }
    } else {
      const isFleetValid = brickAssignableToFleetEntity(actionData.value.entityType, {
        ...newBrickAttributes.value,
        brickType: BoardBrickType.ACTION
      });
      if (!isFleetValid) {
        await resetBrickCoordinates();
        focusActiveBrick();
      }
      return;
    }

    if (brickWasModified.value) {
      setActionEditState();
    }
  };

  return {
    onActivated,
    onDeactivated,
    onResizeStop: onBrickResizeStop,
    onDragStop,
    onDragging: onBrickDragging,
    resetAction,
    updateAction,
    initializeDropped,
    initializeSelected,
    setInitPosition: setBrickInitPosition,
    clearActionEvents,
    unsetActionEditState,
    dirtyStateClass,
    brickDragComponent,
    isDraggable,
    allowedAxisMovement,
    resizeSticks,
    actionIsDirty: brickIsDirty,
    actionIsUpdating: brickIsUpdating,
    actionCoordinates: brickCoordinates
  };
};
