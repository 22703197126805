var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "cardComponent",
      staticClass: "bg-white rounded h-full w-full flex flex-col relative"
    },
    [
      _c("SearchMatchOverlay", {
        attrs: { "search-id": _vm.searchId, outerSpanClass: "h-full" }
      }),
      _vm.disabled
        ? _c("span", { staticClass: "h-full w-full absolute occupied" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "card__header border-b border-gray-200 px-3 flex items-center h-9"
        },
        [_vm._t("header")],
        2
      ),
      _c(
        "div",
        { staticClass: "card__body px-3 h-full" },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }