






import { defineComponent } from "@vue/composition-api";
import { CHECK_AUTH } from "@/store/auth";
import store from "@/store";
import { GET_DIRTY_FLEET_ACTION_ID, GET_DIRTY_LEG_ID, UNLOCK_FLEET_ACTION, UNLOCK_LEG } from "@/store/brick";
import {
  GET_DIRTY_DRIVER_ID,
  GET_DIRTY_SUBCONTRACTOR_ID,
  GET_DIRTY_TRUCK_ID,
  UNLOCK_DRIVER,
  UNLOCK_SUBCONTRACTOR,
  UNLOCK_TRUCK
} from "@/store/fleet";

export default defineComponent({
  name: "App",
  created() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },
  setup(props, { root }) {
    root.$store.dispatch(CHECK_AUTH);
  },
  methods: {
    beforeUnload() {
      const dirtyLegId = store.getters[GET_DIRTY_LEG_ID];
      const dirtyActionId = store.getters[GET_DIRTY_FLEET_ACTION_ID];
      const dirtyTruckId = store.getters[GET_DIRTY_TRUCK_ID];
      const dirtyDriverId = store.getters[GET_DIRTY_DRIVER_ID];
      const dirtySubcontractorId = store.getters[GET_DIRTY_SUBCONTRACTOR_ID];
      if (dirtyLegId) {
        store.dispatch(UNLOCK_LEG, dirtyLegId);
      }
      if (dirtyActionId) {
        store.dispatch(UNLOCK_FLEET_ACTION, dirtyActionId);
      }
      if (dirtyTruckId) {
        store.dispatch(UNLOCK_TRUCK, dirtyTruckId);
      }
      if (dirtyDriverId) {
        store.dispatch(UNLOCK_DRIVER, dirtyDriverId);
      }
      if (dirtySubcontractorId) {
        store.dispatch(UNLOCK_SUBCONTRACTOR, dirtySubcontractorId);
      }
    }
  }
});
