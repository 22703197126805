





































import { computed, defineComponent, ref } from "@vue/composition-api";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import ChatsOverview from "@/components/Chat/ChatsOverview.vue";
import ChatRoom from "@/components/Chat/ChatRoom.vue";
import store from "@/store";
import { SET_IS_CHAT_ACTIVE, SET_ACTIVE_CHAT, MARK_ALL_MESSAGES_READ, GET_ACTIVE_CHAT } from "@/store/chat";

export default defineComponent({
  name: "ChatHub",
  components: { SplitScreen, ChatsOverview, ChatRoom },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const activeChat = computed(() => store.getters[GET_ACTIVE_CHAT]);

    const isLoading = ref(false);
    const closeChatHub = () => {
      emit("chatHubClosed");
      store.commit(SET_IS_CHAT_ACTIVE, false);
      store.commit(SET_ACTIVE_CHAT, null);
    };

    const markAllMessagesAsRead = () => {
      store.dispatch(MARK_ALL_MESSAGES_READ);
    };

    return { isLoading, closeChatHub, markAllMessagesAsRead, activeChat };
  }
});
