






























































































































































































































































import VueDragResize from "vue-drag-resize";
import useLegDrag from "@/components/Board/Leg/Use/useLegDrag";
import Icon from "@/components/Icon.vue";
import store from "@/store";

import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch, nextTick } from "@vue/composition-api";
import {
  GET_FROM_TO_DATE_WITH_TIME,
  GET_HOURBOX_WIDTH,
  GET_SELECTED_TRANSPORT_INFO_STATUS,
  GET_SHOW_PREVIOUS_LEG_DETAIL,
  IS_BOARD_EDIT_MODE_ACTIVE,
  POST_G11_MESSAGE
} from "@/store/board/";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import CalloutLegTrailer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegTrailer.vue";
import CalloutLegDrop from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDrop.vue";
import { generateRandomString } from "@/use/useText";
import { useMessages } from "@/use/useMessages";

import BrickSpinner from "@/components/Board/Brick/BrickSpinner/BrickSpinner.vue";
import {
  BoardLeg,
  BookingState,
  DisplayLegContent,
  DisplayLegStatuses,
  LegCallbacks,
  LegG11Action,
  PreviousLegDetails,
  TransportInfoUpdateType
} from "@/types/leg";
import PreviousLegDetail from "@/components/Board/Leg/PreviousLegDetail/PreviousLegDetail.vue";
import { BoardEntityType, TransportInfoStatus } from "@/types/board";
import LegTransportStatusIndicator from "@/components/Board/Leg/LegTransportStatusIndicator/LegTransportStatusIndicator.vue";
import { dateStringToShowTime, dateStringToShowDate } from "@/use/useDate";
import ClickOutside from "vue-click-outside";
import ClosablePopover from "@/components/ClosablePopover.vue";
import CalloutLegDriver from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDriver.vue";
import FullLegViewPopover from "@/components/Board/Leg/FullLegViewPopover/FullLegViewPopover.vue";
import { GET_DIRTY_FLEET_ACTION_ID, GET_DIRTY_LEG_ID } from "@/store/brick";
import TTALink from "@/components/Board/Leg/TTALink/TTALink.vue";
import LegBreakpointXL from "@/components/Board/Leg/LegBreakpoints/LegBreakpointXL.vue";
import LegAffiliationPopover from "@/components/Board/Leg/LegAffiliationPopover/LegAffiliationPopover.vue";
import { TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION, TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS } from "@/store/fleet";
import BrickActionBar from "@/components/Board/Brick/BrickActionBar/BrickActionBar.vue";
import useBoardBrickCalculus from "@/components/Board/Use/useBoardBrickCalculus";
import SearchMatchOverlay from "@/components/SearchBox/SearchMatchOverlay/SearchMatchOverlay.vue";
import LegStepProgressIndicator from "@/components/Board/Leg/LegStepProgressIndicator/LegStepProgressIndicator.vue";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";
import { buildSearchId } from "@/store/board/useSearchIndex";
import { isAfter, isBefore } from "date-fns";
import { translateAndToastError } from "@/use/useToast";

export default defineComponent({
  name: "Leg",
  components: {
    CalloutLegContainer,
    ChatNotifications,
    VueDragResize,
    Icon,
    CalloutLegTrailer,
    CalloutLegDrop,
    BrickActionBar,
    BrickSpinner,
    PreviousLegDetail,
    LegTransportStatusIndicator,
    ClosablePopover,
    CalloutLegDriver,
    FullLegViewPopover,
    TTALink,
    LegBreakpointXL,
    LegAffiliationPopover,
    SearchMatchOverlay,
    LegStepProgressIndicator
  },
  directives: {
    ClickOutside
  },
  props: {
    legData: {
      type: Object as () => BoardLeg
    }
  },
  setup(props, { emit }) {
    const popoverVisibilities = reactive({
      container: false,
      trailer: false
    });
    const closePopover = (popoverName: string): void => {
      setTimeout(() => {
        popoverVisibilities[popoverName] = false;
      }, 50);
    };

    const searchId = computed(() => buildSearchId(props.legData!.id, BoardEntityType.LEG));

    const { legData: legDataRef } = toRefs(props);
    const {
      legSize,
      legIsDirty,
      brickDragComponent,
      allowedAxisMovement,
      dirtyStateClass,
      noPaddingClass,
      legIsUpdating,
      isDraggable,
      resizeSticks,
      legCoordinates,
      initializeDropped,
      resetLeg,
      onResizing,
      onDragStop,
      onDragging,
      onActivated,
      onResizeStop,
      updateLeg,
      setLegInitPosition,
      clearLegEvents,
      onDeactivated,
      currentLegWidth
    } = useLegDrag(legDataRef);
    const {
      legStatus,
      legType,
      statusClass,
      previousLegClass,
      previousLegState,
      legBackgroundClass,
      transportStatuses,
      previousLegIndicatorHeightClass,
      roundedCornersClass,
      hasPreviousLeg,
      isPrevLegViewActive,
      isDepartedWithIntermodalIrregularities,
      initialLoadState,
      unloadingTemperature,
      hierarchyIndicator,
      legIssues
    } = useLegStatus(legDataRef);

    const {
      getBrickFleetEntityDetailsByYCoord,
      getStartCoordinateTimestamp,
      getEndCoordinateTimestamp
    } = useBoardBrickCalculus();
    const legFleetEntity = computed(() => getBrickFleetEntityDetailsByYCoord(legCoordinates.value.y));

    const dragInitData = reactive({
      hourBoxWidth: computed(() => store.getters[GET_HOURBOX_WIDTH]),
      parentWidth: computed(() => store.state.board.board.offsetWidth)
    });

    //shows the websocket update glow
    const overlaySpanKey = ref(generateRandomString());
    const overlaySpanClass = computed(() => {
      if (props.legData?.updated) {
        overlaySpanKey.value = generateRandomString();
        return "fancy";
      } else {
        return "";
      }
    });

    const totalUnreadMessages = computed(() => useMessages(props.legData?.booking?.id));

    const showChatNotificationIcon = computed(
      () => !!props.legData?.booking?.id && props.legData?.booking?.bookingState !== BookingState.PREPLANNED
    );

    const isBoardEditActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

    const updatable = computed(() => {
      return !!props.legData?.updatable && !isBoardEditActive.value;
    });

    const draggable = computed(() => {
      return !!props.legData?.updatable && isDraggable.value && !isBoardEditActive.value;
    });

    const actionBarDisabled = computed(() => {
      return !!props.legData?.oldPlannedDate || legIsUpdating.value;
    });

    const computedLegClass = computed(() => {
      return !!legIsDirty.value && "leg--elevated";
    });

    const hasSymbols = computed(() => {
      const { width } = legCoordinates.value;
      return width <= 100 && Object.values(transportStatuses.value).some(x => x);
    });

    const allowLegDrop = () => {
      clearLegEvents(props.legData!.id);
    };

    const dirtyLegId = computed<string>(() => store.getters[GET_DIRTY_LEG_ID]);
    const dirtyActionId = computed<string>(() => store.getters[GET_DIRTY_FLEET_ACTION_ID]);

    const openDetail = async () => {
      if (!legIsDirty.value && !isBoardEditActive.value && !dirtyLegId.value && !dirtyActionId.value) {
        emit("showSplitScreen", props.legData);
        return;
      }

      if (dirtyLegId.value) {
        if (legIsDirty.value) {
          return;
        }
        if (!legIsUpdating.value) {
          await store.dispatch(TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS);
          return;
        }
      }

      if (dirtyActionId.value) {
        await store.dispatch(TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION);
      }
    };

    onMounted(() => {
      //init legSize
      onResizing({ width: props.legData?.width || 0 });
      if (props.legData?.dropped) {
        initializeDropped();
      }
      setLegInitPosition();
      document.addEventListener("animationend", event => {
        if (event.animationName.startsWith("glowy") && props.legData?.updated) {
          props.legData.updated = false;
        }
      });
      window.addEventListener("mouseup", onMouseUp);
    });

    /**
     * Stop event propagation to overcome component's mobile bug on not being
     * responsive to any events on contained components
     */
    const stopEventPropagation = e => {
      e.stopPropagation();
    };

    /**
     * Quick cross-platform check if app runs on touch device
     */
    const isViewedOnTouchDevice = () => {
      return "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    };

    /**
     * Since the DragResize component has a mobile bug, we need to overcome
     * the double-tap issue on mobile devices by implementing our own
     * double-tap handler and which is bound to the clicked event of the component
     */
    const tapConfig = {
      recentlyTapped: false,
      timeout: 0,
      timeBetweenTaps: 250
    };

    const onDoubleTapOnTouchDevice = () => {
      // if not on touch device, then do nothing
      if (!isViewedOnTouchDevice()) {
        return;
      }
      // if not tapped recently, then register tap for the next <timeBetweenTaps> milliseconds
      if (!tapConfig.recentlyTapped) {
        tapConfig.recentlyTapped = true;
        tapConfig.timeout = setTimeout(() => {
          tapConfig.recentlyTapped = false;
        }, tapConfig.timeBetweenTaps);
        return;
      }
      // double tap occured, so open detail window and reset recorded tap info
      openDetail();
      clearTimeout(tapConfig.timeout);
      tapConfig.recentlyTapped = false;
    };

    const legKey = ref(Math.random());

    const showPreviousLegDetail = computed(() => store.getters[GET_SHOW_PREVIOUS_LEG_DETAIL]);

    const previousLegDetails = computed<PreviousLegDetails>(() => {
      return {
        size: legSize.value,
        state: previousLegState.value,
        ...props.legData?.previousLeg
      };
    });

    //edit Status handling of legs
    const transportInfoStatus = computed((): TransportInfoStatus => store.getters[GET_SELECTED_TRANSPORT_INFO_STATUS]);

    const isLegDisabled = computed((): boolean => {
      if (props.legData == null) {
        return true;
      }

      if (transportInfoStatus.value == null || transportInfoStatus.value.type === TransportInfoUpdateType.STATUS) {
        return false;
      }

      const { transportInfo } = props.legData;
      if (transportInfoStatus.value.type != null && transportInfo[transportInfoStatus.value.type]?.length) {
        return false;
      }

      return true;
    });

    const editStatusClass = computed((): string => {
      return isLegDisabled.value ? "leg--disabled" : "";
    });

    const getTimeString = (dateString?: string): string => {
      if (dateString) {
        return dateStringToShowTime(dateString);
      }
      return "";
    };

    const buildTimeString = (fromString: string, toString: string): string => {
      if (!fromString && !toString) {
        return "";
      }
      if (fromString === toString || !fromString) {
        return toString;
      }
      if (!toString) {
        return fromString;
      }
      return `${fromString} - ${toString}`;
    };

    const slotTime = computed<{ loading?: string; unloading?: string }>(() => {
      const { loadingSlotFrom, loadingSlotTo, unloadingSlotFrom, unloadingSlotTo } = props.legData?.events || {};
      if (legType.value.fullTransport) {
        return {
          loading: buildTimeString(getTimeString(loadingSlotFrom), getTimeString(loadingSlotTo)),
          unloading: buildTimeString(getTimeString(unloadingSlotFrom), getTimeString(unloadingSlotTo))
        };
      }
      if (props.legData?.details?.isLoadingRelated) {
        return {
          loading: buildTimeString(getTimeString(loadingSlotFrom), getTimeString(loadingSlotTo))
        };
      }
      if (props.legData?.details?.isUnloadingRelated) {
        return {
          unloading: buildTimeString(getTimeString(unloadingSlotFrom), getTimeString(unloadingSlotTo))
        };
      }
      return {};
    });

    const showLoadingAndUnloadingTimeMd = computed<boolean>(() => {
      return (
        legSize.value.md &&
        legType.value.fullTransport &&
        !!legContent.value.loadingTime &&
        !!legContent.value.unloadingTime
      );
    });

    const showLoadingName = computed<boolean>(() => {
      const { fullTransport, collection } = legType.value;
      const { from, to, loading } = props.legData?.stops || {};

      if (collection) {
        return loading?.name !== from?.name && loading?.name !== to?.name;
      }
      if (fullTransport) {
        return loading?.name !== from?.name;
      }
      return false;
    });

    const showUnloadingName = computed<boolean>(() => {
      const { fullTransport, delivery } = legType.value;
      const { from, to, unloading } = props.legData?.stops || {};

      if (delivery) {
        return unloading?.name !== from?.name && unloading?.name !== to?.name;
      }
      if (fullTransport) {
        return unloading?.name !== to?.name;
      }
      return false;
    });

    const stops = computed(() => {
      const { from, to, loading, unloading } = props.legData?.stops || {};
      return {
        from: from?.name || "",
        to: to?.name || "",
        loading: (showLoadingName.value && loading?.name) || "",
        unloading: (showUnloadingName.value && unloading?.name) || ""
      };
    });

    const legContent = computed<DisplayLegContent>(() => {
      const { orderNumberAlt, trailerId, product, container, driverId, subcontractor, g11Actions, events, booking } =
        props.legData || {};

      const plannedLoadingDate = events?.plannedLoading?.date
        ? dateStringToShowDate(events.plannedLoading.date, "dd.MM")
        : "";
      const plannedUnloadingDate = events?.plannedUnloading?.date
        ? dateStringToShowDate(events.plannedUnloading.date, "dd.MM")
        : "";

      return {
        type: legType.value?.name,
        orderNumberAlt,
        from: stops.value.from,
        to: stops.value.to,
        loading: stops.value.loading,
        unloading: stops.value.unloading,
        trailerId: trailerId || "",
        product: product?.name || "",
        adrClass: product?.metadata?.adrClass || "",
        loadingTime: slotTime.value.loading || "",
        unloadingTime: slotTime.value.unloading || "",
        unreadMessages: totalUnreadMessages.value,
        plannedLoading: plannedLoadingDate,
        plannedUnloading: plannedUnloadingDate,
        bookingId: booking?.id,
        container,
        driverId,
        subcontractor,
        g11Actions,
        issues: legIssues.value
      };
    });

    const dynamicBreakpointPadding = computed(() => {
      return {
        "pt-2 pl-2": legSize.value.xs,
        "p-2 pt-0 pr-0": legSize.value.sm,
        "p-0 pt-1 pr-0": showLoadingAndUnloadingTimeMd.value,
        "p-2 pr-4": !legSize.value.xs && !legSize.value.sm && !showLoadingAndUnloadingTimeMd.value
      };
    });

    const hasSubiWithPrimaryBoard = computed(() => legContent.value.subcontractor?.primaryBoard != null);

    const hasDifferentTrailer = computed(() => {
      if (hasSubiWithPrimaryBoard.value) {
        return false;
      }
      return !!(legContent.value.trailerId && legFleetEntity.value?.trailerId != legContent.value.trailerId);
    });

    const hasDifferentDriver = computed(() => {
      if (hasSubiWithPrimaryBoard.value) {
        return false;
      }
      return legFleetEntity.value?.driverId !== legContent.value.driverId;
    });

    const notifyG11 = async (g11Action: LegG11Action) => {
      const { g11Actions } = legContent.value;
      if (g11Actions) {
        const message = g11Actions[g11Action];
        if (message) {
          await store.dispatch(POST_G11_MESSAGE, message);
        }
      }
    };

    const notifyG11Bookings = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_BOOKING_SCREEN);
    };

    const notifyG11Planning = async () => {
      return await notifyG11(LegG11Action.NAVIGATE_TO_PLANNING_SCREEN);
    };

    const legStatuses = computed<DisplayLegStatuses>(() => {
      return {
        transportStatuses: transportStatuses.value,
        legType: legType.value,
        statusClass: statusClass.value,
        isPrevLegViewActive: isPrevLegViewActive.value,
        initialLoadState: initialLoadState.value,
        unloadingTemperature: unloadingTemperature.value,
        hierarchyIndicator: hierarchyIndicator.value,
        legSize: legSize.value,
        hasSubiWithPrimaryBoard: hasSubiWithPrimaryBoard.value,
        hasDifferentDriver: hasDifferentDriver.value,
        hasDifferentTrailer: hasDifferentTrailer.value
      };
    });

    const legActionCallbacks = computed<LegCallbacks>(() => {
      return {
        g11BookingCallback: notifyG11Bookings,
        g11PlanningCallback: notifyG11Planning
      };
    });

    const currentLegStartAndEndDate = ref({
      start: props.legData?.effectiveStartTime,
      end: props.legData?.effectiveEndTime
    });

    const currentLegXCoord = ref<number>(props.legData?.xCoord!);

    watch(currentLegWidth, () => {
      currentLegXCoord.value = props.legData?.xCoord!;
    });

    const onLegResize = params => {
      onResizing(params);
      const start = getStartCoordinateTimestamp(params.left);
      const end = getEndCoordinateTimestamp(params.left + params.width);
      currentLegStartAndEndDate.value = { start, end };
      currentLegXCoord.value = params.left;
    };

    const isMouseHoldDown = ref(false);
    const mouseHoldTimeout = ref(0);

    const onMouseDown = () => {
      mouseHoldTimeout.value = setTimeout(() => {
        isMouseHoldDown.value = true;
      }, 300);
    };

    const onMouseUp = () => {
      clearTimeout(mouseHoldTimeout.value);
      const { events } = props.legData || {};
      if (isMouseHoldDown.value && events) {
        const { fromDate, toDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];

        const legTooLongStart = isBefore(new Date(events.plannedDepartureDate?.date!), fromDate);
        const legTooLongEnd = isAfter(new Date(events.plannedArrivalDate?.date!), toDate);
        const legLongerThanBoard = legTooLongStart && legTooLongEnd;
        const legHasNoEffectiveDate = events.effectiveDepartureDate == null || events.effectiveArrivalDate == null;
        const legDepartedNotArrived = events.effectiveDepartureDate != null && events.effectiveArrivalDate == null;
        if ((legLongerThanBoard && legHasNoEffectiveDate) || (legTooLongEnd && legDepartedNotArrived)) {
          translateAndToastError("errors.LEG_LONGER_THAN_BOARD");
        } else if (legTooLongStart && events.effectiveArrivalDate == null) {
          translateAndToastError("errors.LEG_LONGER_THAN_BOARD_END");
        } else if (legTooLongEnd && events.effectiveDepartureDate == null) {
          translateAndToastError("errors.LEG_LONGER_THAN_BOARD_START");
        }
        isMouseHoldDown.value = false;
      }
    };

    const onLegStopDrag = params => {
      onDragStop(params);
      const start = getStartCoordinateTimestamp(params.left);
      const end = getEndCoordinateTimestamp(params.left + params.width);
      currentLegStartAndEndDate.value = { start, end };
      currentLegXCoord.value = params.left;
    };

    const legResetHandler = () => {
      resetLeg();
      currentLegXCoord.value = props.legData?.xCoord!;
    };

    watch(
      [() => Math.floor(legCoordinates.value.x), () => Math.floor(legCoordinates.value.y)],
      async ([newX, newY]) => {
        await nextTick();
        setTimeout(() => {
          const epsilon = 5; // max pixel deviation

          const left = brickDragComponent.value.$el.offsetLeft;
          const top = brickDragComponent.value.$el.offsetTop;

          const diffX = Math.abs(left - newX);
          const diffY = Math.abs(top - newY);

          if (diffX > epsilon || diffY > epsilon) {
            console.info(`[INFO] Leg with ID ${props.legData?.id} was re-built due to incorrect position update`);
            legKey.value = Math.random();
          }
        }, 250);
      },
      { flush: "post" }
    );

    return {
      currentLegStartAndEndDate,
      onLegResize,
      isDepartedWithIntermodalIrregularities,
      closePopover,
      showLoadingAndUnloadingTimeMd,
      dynamicBreakpointPadding,
      legIssues,
      legContent,
      legKey,
      previousLegClass,
      previousLegState,
      roundedCornersClass,
      hasPreviousLeg,
      showPreviousLegDetail,
      isPrevLegViewActive,
      previousLegIndicatorHeightClass,
      previousLegDetails,
      transportStatuses,
      legBackgroundClass,
      onMouseDown,
      onDoubleTapOnTouchDevice,
      stopEventPropagation,
      onResizing,
      onDragStop,
      openDetail,
      onActivated,
      onDeactivated,
      onResizeStop,
      resetLeg,
      updateLeg,
      allowLegDrop,
      searchId,
      isDraggable,
      resizeSticks,
      noPaddingClass,
      legSize,
      legType,
      legStatus,
      statusClass,
      overlaySpanClass,
      overlaySpanKey,
      brickDragComponent,
      dirtyStateClass,
      legIsDirty,
      legIsUpdating,
      updatable,
      draggable,
      actionBarDisabled,
      allowedAxisMovement,
      legCoordinates,
      computedLegClass,
      onDragging,
      editStatusClass,
      isLegDisabled,
      hasDifferentDriver,
      hasDifferentTrailer,
      hasSymbols,
      hasSubiWithPrimaryBoard,
      notifyG11Bookings,
      initialLoadState,
      unloadingTemperature,
      hierarchyIndicator,
      notifyG11Planning,
      legStatuses,
      legActionCallbacks,
      currentLegWidth,
      onLegStopDrag,
      currentLegXCoord,
      legResetHandler,
      totalUnreadMessages,
      showChatNotificationIcon,
      ...toRefs(dragInitData)
    };
  }
});
