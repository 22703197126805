<template>
  <div>
    <div class="border-b-2 font-semibold mb-2 text-gray-900 border-gray-900">
      {{ truck.id }}
    </div>

    <table class="truckCallout__table table-fixed text-left mb-6 w-full">
      <thead>
        <tr>
          <th class="w-1/3">{{ $t("truck.suitableFor") }}</th>
          <th class="w-1/3">{{ $t("truck.tareWeight") }}</th>
          <th class="w-1/3">{{ $t("truck.nextTest") }}</th>
        </tr>
      </thead>
      <tbody class="font-light align-top">
        <tr>
          <td>
            <span v-if="truck.metadata.suitableFor">{{ truck.metadata.suitableFor }}</span>
          </td>
          <td>
            <span v-if="truck.metadata.tareWeight"> {{ truck.metadata.tareWeight }} kg </span>
          </td>
          <td>
            <span
              v-if="nextInspectionDate"
              :class="{
                'text-btRed': nextInspectionDate.isDateInPast
              }"
            >
              {{ nextInspectionDate.date }}
            </span>
            <span>
              <a-popover
                trigger="hover"
                placement="right"
                :overlayStyle="{ maxWidth: '650px' }"
                :getPopupContainer="triggerNode => triggerNode.parentNode"
              >
                <Icon name="info" class="text-black cursor-pointer" width="1.5rem" height="1.5rem" />
                <template #content>
                  <FleetNextInspectionTable :content="truck.metadata" />
                </template>
              </a-popover>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="truckCallout__table table-fixed text-left w-full mb-6">
      <thead class="font-light">
        <tr>
          <th class="w-1/3">{{ $t("truck.equipment") }}</th>
          <th class="w-1/3">Mapon</th>
          <th class="w-1/3">Euro class</th>
        </tr>
      </thead>
      <tbody class="align-top">
        <tr>
          <td class="capitalize">
            {{ stringifyTruckEquipment(truck.metadata) }}
          </td>
          <td>
            <a v-if="truck.metadata.maponLink" class="text-black" :href="truck.metadata.maponLink" target="_blank">
              <Icon name="link" height="18" width="18" />
            </a>
          </td>
          <td>
            <span v-if="truck.metadata.euroClass">{{ truck.metadata.euroClass }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="truckCallout__table table-fixed text-left w-full mb-6">
      <thead class="font-light">
        <tr>
          <th class="w-1/3">{{ $t("truck.department") }}</th>
          <th class="w-1/3">{{ $t("truck.tempDepartment") }}</th>
          <th class="w-1/3">{{ $t("truck.tempGroup") }}</th>
        </tr>
      </thead>
      <tbody class="align-top">
        <tr>
          <td>
            {{ truck.baseBoardId }}
          </td>
          <td v-if="tempDepartment">
            {{ tempDepartment.boardId }}
          </td>
          <td v-if="tempGroup">
            {{ tempGroup.boardId }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="truckCallout__table table-fixed text-left w-full">
      <thead class="font-light">
        <tr>
          <th class="w-1/3">{{ $t("truck.assignedDriver") }}</th>
          <th class="w-1/3">{{ $t("truck.driverPhoneNr") }}</th>
          <th class="w-1/3"></th>
        </tr>
      </thead>
      <tbody class="align-top">
        <tr>
          <td>
            <span v-if="truck.driver"> {{ truck.driver.firstName }} {{ truck.driver.lastName }} </span>
          </td>
          <td>
            <div class="flex space-x-3" v-if="truck.driver">
              <span>{{ truck.driver.phoneNumber }}</span>
              <CopyToClipboard
                v-if="truck.driver.phoneNumber"
                width="18"
                height="18"
                :text="truck.driver.phoneNumber"
              />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import { stringifyTruckEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Truck } from "@/types/fleet";
import { spreadTruckId } from "@/use/useFleet";
import FleetNextInspectionTable from "@/components/Board/CallOuts/CalloutSideBar/FleetNextInspectionTable/FleetNextInspectionTable.vue";
import CopyToClipboard from "@/components/CopyToClipboard.vue";
import { getLatestDateOnBoards, filterBoardTypes } from "@/components/Board/CallOuts/Use/useBoards";
export default defineComponent({
  name: "CalloutSideBarTruck.vue",
  components: { Icon, FleetNextInspectionTable, CopyToClipboard },
  props: {
    content: {
      type: Object as PropType<Truck>,
      required: true
    }
  },
  setup(props) {
    const truck = computed<Truck>(
      () =>
        ({
          ...props.content,
          id: spreadTruckId(props.content.id).id
        } as Truck)
    );

    const GROUP = truck.value.boards ? filterBoardTypes("GROUP", truck.value) : null;
    const DEPARTMENT = truck.value.boards ? filterBoardTypes("DEPARTMENT", truck.value) : null;

    const tempDepartment = DEPARTMENT && DEPARTMENT.length >= 1 ? getLatestDateOnBoards(DEPARTMENT) : null;
    const tempGroup = GROUP && GROUP.length >= 1 ? getLatestDateOnBoards(GROUP) : null;

    const nextInspectionDate = computed(() => getNextInspectionDate(truck.value.metadata));

    return {
      stringifyTruckEquipment,
      getNextInspectionDate,
      truck,
      tempGroup,
      tempDepartment,
      nextInspectionDate
    };
  }
});
</script>

<style scoped lang="scss">
@use "CalloutSideBarTruck";
</style>
