var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "actionReminderCallout" }, [
    _c(
      "div",
      { staticClass: "popover-body actionDetail__inner" },
      _vm._l(_vm.sortedActions, function(action) {
        return _c("div", { key: action.id }, [
          _c("div", { staticClass: "action mb-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "action__header border-b-2 font-semibold mb-2 flex justify-between",
                class: {
                  "border-btOrange": action.truckId || action.subcontractor,
                  "border-blue-400": action.driverId,
                  "border-yellow-900": action.trailerId
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex items-center",
                    class: {
                      "text-btOrange": action.truckId || action.subcontractor,
                      "text-blue-400": action.driverId,
                      "text-yellow-900": action.trailerId
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-1" },
                      [
                        !action.isReminder
                          ? _c("Icon", {
                              attrs: { name: "services", width: "20" }
                            })
                          : _c("Icon", {
                              attrs: { name: "notifications", width: "20" }
                            })
                      ],
                      1
                    ),
                    _vm._v(
                      " " +
                        _vm._s(action.type) +
                        " - " +
                        _vm._s(_vm.equipmentId(action)) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openFleetAction(action)
                      }
                    }
                  },
                  [
                    _c("Icon", {
                      staticClass:
                        "cursor-pointer text-black hover:text-btYellow ml-12",
                      attrs: { tabindex: "0", name: "edit", width: "18" }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", [
              _c("div", [
                action.fromDate && action.fromDate.date
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.dateStringToShowDate(action.fromDate.date)) +
                          " " +
                          _vm._s(
                            _vm.dateStringToShowTime(action.fromDate.date)
                          ) +
                          "h"
                      )
                    ])
                  : _vm._e(),
                action.toDate && action.toDate.date
                  ? _c("span", [
                      _vm._v(
                        " - " +
                          _vm._s(_vm.dateStringToShowDate(action.toDate.date)) +
                          " " +
                          _vm._s(_vm.dateStringToShowTime(action.toDate.date)) +
                          "h"
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", [
                action.fromLocation && action.fromLocation.name
                  ? _c("span", [
                      _vm._v(" " + _vm._s(action.fromLocation.name) + " ")
                    ])
                  : _vm._e(),
                action.toLocation && action.toLocation.name
                  ? _c("span", [_vm._v(" - " + _vm._s(action.toLocation.name))])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "mt-1 font-light" }, [
                _vm._v(" " + _vm._s(action.remarks) + " ")
              ])
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }