/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#707070" d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h8v-2H4z"/>'
  }
})
