























import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    passed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const color = computed(() => {
      return {
        fill: "red"
      };
    });
    return { color };
  }
});
