import { $axios } from "@/config/axios";
import {
  AffiliationRequest,
  FleetPayload,
  FleetPreviews,
  LastBookingsFilter,
  Subcontractor,
  LastBookings,
  Driver,
  DriverUpdate
} from "@/types/fleet";

import { AxiosResponse } from "axios";
import { FleetAction, FleetBoardActionFilter, FleetActionFilter, FleetActionsResponse } from "@/types/action";
import { Location } from "@/types/types";

const baseSlug = "/v1/fleet";

export default {
  async getFleets(payload: FleetPayload) {
    return await $axios.post(`${baseSlug}/filter`, payload);
  },
  async getTrailerById(trailerId: string) {
    return await $axios.get(`${baseSlug}/trailers/${trailerId}`);
  },
  async getDriverById(driverId: string): Promise<AxiosResponse<Driver>> {
    return await $axios.get(`${baseSlug}/drivers/${driverId}`);
  },
  async getTruckById(truckId: string) {
    return await $axios.get(`${baseSlug}/trucks/${truckId}`);
  },
  async lockTruck(truckId: string) {
    return await $axios.post(`${baseSlug}/trucks/${truckId}/lock`);
  },
  async unlockTruck(truckId: string) {
    return await $axios.post(`${baseSlug}/trucks/${truckId}/unlock`);
  },
  async updateTruck(request: AffiliationRequest) {
    return await $axios.post(`${baseSlug}/trucks/${request.id}`, request);
  },
  async getTrucksPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/trucks/preview`);
  },
  async getTrailersPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/trailers/preview`);
  },
  async getDriversPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/drivers/preview`);
  },
  async getSubconctractorsPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/subcontractors/preview`);
  },
  async getSubcontractorById(id: string): Promise<AxiosResponse<Subcontractor>> {
    return await $axios.get(`${baseSlug}/subcontractors/${id}`);
  },
  async updateSubcontractor(id: string, payload: Subcontractor): Promise<AxiosResponse<Subcontractor>> {
    return await $axios.post(`${baseSlug}/subcontractors/${id}`, payload);
  },
  async lockSubcontractor(id: string): Promise<AxiosResponse<void>> {
    return await $axios.post(`${baseSlug}/subcontractors/${id}/lock`);
  },
  async unlockSubcontractor(id: string): Promise<AxiosResponse<void>> {
    return await $axios.post(`${baseSlug}/subcontractors/${id}/unlock`);
  },
  async getFleetBoardActions(params: FleetBoardActionFilter): Promise<AxiosResponse<FleetActionsResponse>> {
    return await $axios.get(`${baseSlug}/actions`, { params });
  },
  async getFleetActions(payload: FleetActionFilter): Promise<AxiosResponse<FleetActionsResponse>> {
    return await $axios.post(`${baseSlug}/actions/filter`, payload);
  },
  async getActionTypesPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/actions/types/preview`);
  },
  async createAction(request: FleetAction): Promise<AxiosResponse<FleetActionsResponse>> {
    return await $axios.post(`${baseSlug}/actions`, request);
  },
  async updateAction(action: FleetAction): Promise<AxiosResponse<FleetAction>> {
    return await $axios.post(`${baseSlug}/actions/${action.id}`, action);
  },
  async deleteAction(actionId: string): Promise<AxiosResponse> {
    return await $axios.delete(`${baseSlug}/actions/${actionId}`);
  },
  async lockAction(actionId: string): Promise<AxiosResponse> {
    return await $axios.post(`${baseSlug}/actions/${actionId}/lock`);
  },
  async unlockAction(actionId: string): Promise<AxiosResponse> {
    return await $axios.post(`${baseSlug}/actions/${actionId}/unlock`);
  },
  async getLocationsPreview(): Promise<AxiosResponse<FleetPreviews>> {
    return await $axios.get(`${baseSlug}/locations/preview`);
  },
  async getLocationById(locationId: string): Promise<AxiosResponse<Location>> {
    return await $axios.get(`${baseSlug}/locations/${locationId}`);
  },
  async getLastBookings(lastBookingFilter: LastBookingsFilter): Promise<AxiosResponse<LastBookings>> {
    return await $axios.post(`${baseSlug}/bookings/fetch-last`, lastBookingFilter);
  },
  async lockDriver(driverId: string): Promise<AxiosResponse> {
    return await $axios.post(`${baseSlug}/drivers/${driverId}/lock`);
  },
  async unlockDriver(driverId: string): Promise<AxiosResponse> {
    return await $axios.post(`${baseSlug}/drivers/${driverId}/unlock`);
  },
  async updateDriver(driverId: string, payload: DriverUpdate): Promise<AxiosResponse<Driver>> {
    return await $axios.post(`${baseSlug}/drivers/${driverId}`, payload);
  }
};
