<template>
  <div ref="cardComponent" class="bg-white rounded h-full w-full flex flex-col relative">
    <SearchMatchOverlay :search-id="searchId" outerSpanClass="h-full" />
    <span v-if="disabled" class="h-full w-full absolute occupied" />
    <div class="card__header border-b border-gray-200 px-3 flex items-center h-9">
      <slot name="header"></slot>
    </div>
    <div class="card__body px-3 h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import SearchMatchOverlay from "@/components/SearchBox/SearchMatchOverlay/SearchMatchOverlay.vue";

export default defineComponent({
  name: "Card",
  components: {
    SearchMatchOverlay
  },
  props: {
    disabled: {
      type: Boolean
    },
    searchId: {
      type: String
    }
  }
});
</script>

<style scoped>
.occupied {
  @apply bg-gray-400 bg-opacity-50;
  pointer-events: none;
}
</style>
