<template>
  <div
    ref="brickActionBar"
    id="brickActionBar"
    tabindex="0"
    class="absolute z-10 left-0 bottom-0 h-10 flex bg-btYellow"
  >
    <button
      v-if="isBoardEditActive"
      @touchstart="resetOnTouch"
      @click="$emit('resetBrick')"
      class="BrickActionBar__button--save p-1 bg-btYellow transition-colors hover:bg-yellow-500"
    >
      <icon name="close" />
    </button>
    <button
      v-if="!isBoardEditActive"
      @touchstart="resetOnTouch"
      @click="$emit('resetBrick')"
      class="BrickActionBar__button--reset p-1 bg-btYellow transition-colors hover:bg-yellow-500"
      :class="{ disabled: disabled }"
    >
      <icon name="undo" />
    </button>
    <button
      @touchstart="saveOnTouch"
      @click="updateBrick"
      class="BrickActionBar__button--save p-1 bg-btYellow transition-colors hover:bg-yellow-500"
      :class="{ disabled: disabled }"
    >
      <icon name="save" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, computed } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store";
import { IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";

export default defineComponent({
  name: "BrickActionBar",
  components: { Icon },
  props: {
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const brickActionBar = ref<HTMLElement | null>(null);
    const isBoardEditActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

    const actionBarKeyHandler = (e: KeyboardEvent) => {
      if (e.target instanceof Element) {
        if (e.target.id === "brickActionBar") {
          if (e.key === "Escape") {
            emit("resetBrick");
          }
          if (e.key === "s") {
            emit("updateBrick");
          }
          //makes sure do not fire other key events
          e.stopPropagation();
        }
      }
    };
    onMounted(() => {
      brickActionBar.value?.focus();
      document.addEventListener("keydown", actionBarKeyHandler, true);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", actionBarKeyHandler, true);
    });

    const resetOnTouch = e => {
      e.stopPropagation();
      emit("resetBrick");
    };

    const saveOnTouch = e => {
      e.stopPropagation();
      emit("updateBrick");
    };

    const updateBrick = (e: Event) => {
      if (props.disabled) {
        e.stopPropagation();
        return;
      }
      emit("updateBrick");
    };

    return {
      updateBrick,
      brickActionBar,
      resetOnTouch,
      saveOnTouch,
      isBoardEditActive
    };
  }
});
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
