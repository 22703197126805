/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>'
  }
})
