import store from "@/store";
import { LOCK_DRIVER, UNLOCK_DRIVER, UPDATE_DRIVER } from "@/store/fleet";
import { Driver, DriverUpdate } from "@/types/fleet";
import { translateAndToastSuccess } from "@/use/useToast";
import { ref } from "@vue/composition-api";

const selectedDriverId = ref<string | null>(null);

export default (driver: Driver) => {
  const driverCommentEditState = ref(false);
  const currentDriverRemarks = ref<string | null>(null);

  const setSelectedDriver = (driverId: string): boolean => {
    if (selectedDriverId.value != null) {
      return false;
    }
    selectedDriverId.value = driverId;
    lockDriver();
    return true;
  };

  const unsetSelectedDriver = () => {
    if (driverCommentEditState.value) {
      unlockDriver();
    }
    selectedDriverId.value = null;
  };

  const initDriver = () => {
    currentDriverRemarks.value = driver.remarks || "";
  };

  const lockDriver = async () => {
    try {
      await store.dispatch(LOCK_DRIVER, selectedDriverId.value);
      driverCommentEditState.value = true;
    } catch (e) {
      unsetSelectedDriver();
    }
  };

  const unlockDriver = async () => {
    try {
      await store.dispatch(UNLOCK_DRIVER, selectedDriverId.value);
      driverCommentEditState.value = false;
    } catch (e) {
      //show errors which are not handled globally
    }
  };

  const updateDriver = async (driver: DriverUpdate) => {
    try {
      await store.dispatch(UPDATE_DRIVER, driver);
      selectedDriverId.value = null;
      driverCommentEditState.value = false;
      currentDriverRemarks.value = driver.remarks!;
      translateAndToastSuccess("driver.updated");
    } catch (e) {
      unsetSelectedDriver();
    }
  };

  return {
    driverCommentEditState,
    currentDriverRemarks,
    setSelectedDriver,
    unsetSelectedDriver,
    initDriver,
    updateDriver
  };
};
