var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SplitScreen", {
        attrs: {
          "is-active": _vm.isActive,
          isLoading: _vm.isLoading,
          "custom-class": "chat-sidebar z-40"
        },
        on: { closeHandler: _vm.closeChatHub },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("div", { staticClass: "font-extrabold" }, [
                  _vm._v(_vm._s(_vm.$t("chat.messageHubTitle")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "pt-4 h-full relative" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "bg-btYellow block ml-auto p-2 mb-12",
                        on: { click: _vm.markAllMessagesAsRead }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("chat.markAsReadBtnLabel")) + " "
                        )
                      ]
                    ),
                    _c("ChatsOverview", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.activeChat,
                          expression: "!activeChat"
                        }
                      ]
                    }),
                    _c("ChatRoom")
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "actionBar",
            fn: function() {
              return [
                _c("div", { staticClass: "w-full flex" }, [
                  _c(
                    "div",
                    { staticClass: "ml-auto flex" },
                    [
                      _c(
                        "Button",
                        {
                          staticClass:
                            "mr-4 py-2 px-8 h-12 bg-white border-2 border-gray-300  hover:border-yellow-500",
                          attrs: { variant: "secondary" },
                          on: { click: _vm.closeChatHub }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("actions.close")))])]
                      )
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }