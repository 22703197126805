





















import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { LegEventTimeSlot, LegOpeningHours } from "@/types/leg";
import { format, parseISO } from "date-fns";

interface Day {
  weekDay: string;
  ranges: LegEventTimeSlot[] | undefined;
  isPlanned: boolean;
}

export default defineComponent({
  name: "LegDetailOpeningHours",
  props: {
    openingHours: {
      type: Object as () => LegOpeningHours
    },
    plannedDate: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const days = ref<Array<Day>>([]);

    const weekDays = {
      // "sunday": 0, // << if sunday is first day of week
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7
    };

    const init = () => {
      const openingHours = props.openingHours;
      if (typeof openingHours === "undefined") {
        return;
      }

      days.value = Object.keys(weekDays)
        .map(weekDay => {
          const matchingDayName = Object.keys(openingHours).find(day => day.toLowerCase() === weekDay);
          if (typeof matchingDayName !== "undefined") {
            return {
              weekDay: matchingDayName.toLowerCase(),
              ranges: formattedOpeningHours.value![matchingDayName],
              isPlanned: deriveDayName(props.plannedDate) === matchingDayName.toLowerCase()
            };
          }
          return {
            weekDay: weekDay.toLowerCase(),
            ranges: [],
            isPlanned: false
          };
        })
        .sort((a, b) => {
          const day1 = a.weekDay.toLowerCase();
          const day2 = b.weekDay.toLowerCase();
          return weekDays[day1] - weekDays[day2];
        });
    };

    const deriveDayName = (date: string): string => {
      if (date == null || date === "") {
        return "";
      }
      const dateObject = parseISO(date);
      const plannedDay = format(dateObject, "iiii");
      return plannedDay.toLowerCase();
    };

    const formattedOpeningHours = computed(() => {
      const openingHours = props.openingHours;
      if (typeof openingHours !== "undefined") {
        Object.keys(openingHours).forEach(day => {
          openingHours[day].map(hours => {
            if (hours?.from && hours.from?.length > 5) {
              hours.from = hours.from?.slice(0, -3);
            }
            if (hours?.to && hours.to.length > 5) {
              hours.to = hours.to?.slice(0, -3);
            }
          });
        });
      }
      return openingHours;
    });

    onMounted(() => {
      init();
    });

    return {
      days
    };
  }
});
