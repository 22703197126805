var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-row justify-end transport-statuses" },
    [
      _vm.status.hasCustoms
        ? _c("Icon", {
            class: { "text-green-600": !_vm.status.hasPendingCustoms },
            attrs: { name: "customs", width: "20", height: "20" }
          })
        : _vm._e(),
      _vm.status.hasPendingTemperatures
        ? _c("Icon", {
            attrs: { name: "thermostat", width: "20", height: "20" }
          })
        : _vm._e(),
      _vm.status.hasPendingSpecialEquipments
        ? _c("Icon", {
            attrs: { name: "construction", width: "20", height: "20" }
          })
        : _vm._e(),
      _vm.status.hasPendingDocuments
        ? _c("Icon", {
            attrs: { name: "description", width: "20", height: "20" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }