import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Chat, ChatMessage } from "@/types/chat";
import chatService from "@/services/chatService";
import { getCookie } from "@/use/useCookie";
import { TP_COOKIE_NAME } from "@/env_config";
import decodeJWT from "jwt-decode";
import { requestAndShowPermission } from "@/use/useBrowserNotification";
import { filterByBoard, filterByChats } from "@/use/useMessages";

//Enums
const CHAT_STORE = "chat";
const APP = "APP";

// Global Getters
const GET_CHATS = `${CHAT_STORE}/GET_CHATS`;
const GET_IS_CHAT_ACTIVE = `${CHAT_STORE}/GET_IS_CHAT_ACTIVE`;
const GET_ACTIVE_CHAT = `${CHAT_STORE}/GET_ACTIVE_CHAT`;
const GET_CHAT_USERNAME = `${CHAT_STORE}/GET_CHAT_USERNAME`;

// Global Mutations
const SET_IS_CHAT_ACTIVE = `${CHAT_STORE}/SET_IS_CHAT_ACTIVE`;
const SET_ACTIVE_CHAT = `${CHAT_STORE}/SET_ACTIVE_CHAT`;
const SET_CHAT_USERNAME = `${CHAT_STORE}/SET_CHAT_USERNAME`;

// Global Actions
const FETCH_CHATS = `${CHAT_STORE}/FETCH_CHATS`;
const IS_CHAT_HUB_ACTIVE = `${CHAT_STORE}/IS_CHAT_HUB_ACTIVE`;
const TOGGLE_CHAT_HUB_MODE = `${CHAT_STORE}/TOGGLE_CHAT_HUB_MODE`;
const FETCH_MESSAGES_FOR_ACTIVE_CHAT = `${CHAT_STORE}/FETCH_MESSAGES_FOR_ACTIVE_CHAT`;
const INIT_CHAT_USERNAME = `${CHAT_STORE}/INIT_CHAT_USERNAME`;
const CHAT_UPDATE = `${CHAT_STORE}/CHAT_UPDATE`;
const CHAT_READ = `${CHAT_STORE}/CHAT_READ`;
const MARK_ALL_MESSAGES_READ = `${CHAT_STORE}/MARK_ALL_MESSAGES_READ`;
const NOTIFY_USER = `${CHAT_STORE}/NOTIFY_USER`;

// Local Getters
// -

// Local Mutations
const SET_CHATS_LOCAL = "SET_CHATS";
const CHAT_HUB_MODE_LOCAL = "CHAT_HUB_MODE";
const SET_ACTIVE_CHAT_LOCAL = "SET_ACTIVE_CHAT";
const SET_CHAT_USERNAME_LOCAL = "SET_CHAT_USERNAME";

// Local Actions
const NOTIFY_USER_LOCAL = "NOTIFY_USER";

@Module({ namespaced: true })
export default class Index extends VuexModule {
  chats: Chat[] = [];
  isChatActive = false;
  activeChat: Chat | null = null;
  chatUsername = "";
  chatHubActive = false;

  get GET_CHATS() {
    return this.chats;
  }

  get IS_CHAT_HUB_ACTIVE() {
    return this.chatHubActive;
  }

  get GET_CHAT_USERNAME() {
    return this.chatUsername;
  }

  get GET_IS_CHAT_ACTIVE() {
    return this.isChatActive;
  }

  get GET_ACTIVE_CHAT() {
    return this.activeChat;
  }

  @Mutation
  SET_CHATS(payload: Chat[]) {
    this.chats = payload;
  }

  @Mutation
  CHAT_HUB_MODE(state) {
    this.chatHubActive = state;
  }

  @Mutation
  SET_CHAT_USERNAME(value: string) {
    this.chatUsername = value;
  }

  @Mutation
  SET_IS_CHAT_ACTIVE(value: boolean) {
    this.isChatActive = value;
  }

  @Mutation
  SET_ACTIVE_CHAT(chat: Chat | null) {
    this.activeChat = chat;
    if (this.activeChat && this.activeChat.bookingId) {
      this.activeChat.unreadCount = 0;
    }
  }

  @Action
  TOGGLE_CHAT_HUB_MODE(state) {
    this.context.commit(CHAT_HUB_MODE_LOCAL, state);
  }

  @Action
  async FETCH_CHATS(boardIds: string[]) {
    const { commit } = this.context;
    try {
      const { data } = await chatService.getChats(boardIds);
      commit(SET_CHATS_LOCAL, data.chats);
    } catch (e) {
      // hide all error messages
    }
  }

  @Action
  async MARK_ALL_MESSAGES_READ() {
    const localChats = [...this.chats];
    const bookingIds: string[] = [];
    localChats.forEach(chat => {
      if (chat.unreadCount > 0) {
        bookingIds.push(chat.bookingId);
      }
    });
    if (bookingIds.length > 0) {
      try {
        await chatService.readAllChatMessages(bookingIds);
        this.chats.forEach(chat => (chat.unreadCount = 0));
      } catch (e) {
        console.error("could not finish the request", e);
      }
    }
  }

  @Action
  async FETCH_MESSAGES_FOR_ACTIVE_CHAT(chat: Chat) {
    try {
      this.context.commit(SET_ACTIVE_CHAT_LOCAL, {});
      const { data } = await chatService.readChatMessages(chat.bookingId);
      this.context.commit(SET_ACTIVE_CHAT_LOCAL, data);
    } catch (e) {
      console.error("could not fetch chat messages", e);
    }
  }

  @Action
  INIT_CHAT_USERNAME() {
    const cookieValue = getCookie(TP_COOKIE_NAME);
    if (cookieValue) {
      const decodedJWT: any = decodeJWT(cookieValue);
      this.context.commit(SET_CHAT_USERNAME_LOCAL, decodedJWT.sub);
    }
  }

  @Action
  CHAT_UPDATE(incomingChat: Chat) {
    const { commit, dispatch } = this.context;

    const localChats = [...this.chats];

    const outdatedChatIndex = localChats.findIndex(chat => chat.bookingId === incomingChat.bookingId);

    const activeConversation = this.activeChat?.messages || [];

    if (filterByBoard(incomingChat)) {
      //CHAT_UPDATE message board included in the working board/s
      const chatFoundOnBoard = filterByChats(localChats, incomingChat);
      if (chatFoundOnBoard) {
        //CHAT_UPDATE message - Chat bookingId Found in Chats on board
        if (this.activeChat && this.activeChat.bookingId === incomingChat.bookingId) {
          this.activeChat.messages =
            incomingChat.messages && incomingChat.messages.length > 0
              ? [...new Set([...incomingChat.messages, ...activeConversation])]
              : this.activeChat.messages;
        }
        this.context.commit(SET_CHATS_LOCAL, localChats);
        if (incomingChat.messages && !this.activeChat) {
          chatFoundOnBoard.unreadCount += incomingChat.messages ? incomingChat.messages.length : 0;
          localChats.splice(outdatedChatIndex, 1, { ...chatFoundOnBoard, ...incomingChat });
          this.context.dispatch(NOTIFY_USER_LOCAL, incomingChat);
        }
      } else {
        //CHAT_UPDATE message - Leg bookignId found/not found on the board Legs
        incomingChat.unreadCount = incomingChat.messages ? incomingChat.messages.length : 0;
        const updatedChats = [...localChats, ...[incomingChat]];
        commit(SET_CHATS_LOCAL, updatedChats);
        dispatch(NOTIFY_USER_LOCAL, incomingChat);
      }
    }
  }

  @Action
  CHAT_READ(chatToClean: Chat) {
    this.chats.forEach(chat => {
      if (chat.bookingId === chatToClean.bookingId) {
        chat.unreadCount = 0;
      }
    });
  }

  @Action
  NOTIFY_USER(updatedChat: Chat) {
    const messages: ChatMessage[] = updatedChat.messages || [];

    const latestMessageSequence = messages.length >= 1 ? Math.max(...messages.map(o => o.sequence)) : 0;

    const latestMessageIndex = messages.findIndex(object => {
      return object.sequence === latestMessageSequence;
    });

    const originIsAPP = messages[latestMessageIndex].origin === APP;

    if (originIsAPP) {
      requestAndShowPermission(updatedChat, messages[latestMessageIndex].author, messages[latestMessageIndex].text);
    }
  }
}

export {
  GET_CHATS,
  FETCH_CHATS,
  GET_IS_CHAT_ACTIVE,
  GET_ACTIVE_CHAT,
  SET_IS_CHAT_ACTIVE,
  SET_ACTIVE_CHAT,
  FETCH_MESSAGES_FOR_ACTIVE_CHAT,
  GET_CHAT_USERNAME,
  SET_CHAT_USERNAME,
  INIT_CHAT_USERNAME,
  IS_CHAT_HUB_ACTIVE,
  TOGGLE_CHAT_HUB_MODE,
  CHAT_UPDATE,
  CHAT_READ,
  MARK_ALL_MESSAGES_READ,
  NOTIFY_USER
};
