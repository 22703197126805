
































































































































import Icon from "@/components/Icon.vue";
import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import { stringifyTruckEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Truck } from "@/types/fleet";
import { spreadTruckId } from "@/use/useFleet";
import FleetNextInspectionTable from "@/components/Board/CallOuts/CalloutSideBar/FleetNextInspectionTable/FleetNextInspectionTable.vue";
import CopyToClipboard from "@/components/CopyToClipboard.vue";
import { getLatestDateOnBoards, filterBoardTypes } from "@/components/Board/CallOuts/Use/useBoards";
export default defineComponent({
  name: "CalloutSideBarTruck.vue",
  components: { Icon, FleetNextInspectionTable, CopyToClipboard },
  props: {
    content: {
      type: Object as PropType<Truck>,
      required: true
    }
  },
  setup(props) {
    const truck = computed<Truck>(
      () =>
        ({
          ...props.content,
          id: spreadTruckId(props.content.id).id
        } as Truck)
    );

    const GROUP = truck.value.boards ? filterBoardTypes("GROUP", truck.value) : null;
    const DEPARTMENT = truck.value.boards ? filterBoardTypes("DEPARTMENT", truck.value) : null;

    const tempDepartment = DEPARTMENT && DEPARTMENT.length >= 1 ? getLatestDateOnBoards(DEPARTMENT) : null;
    const tempGroup = GROUP && GROUP.length >= 1 ? getLatestDateOnBoards(GROUP) : null;

    const nextInspectionDate = computed(() => getNextInspectionDate(truck.value.metadata));

    return {
      stringifyTruckEquipment,
      getNextInspectionDate,
      truck,
      tempGroup,
      tempDepartment,
      nextInspectionDate
    };
  }
});
