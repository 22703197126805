<template>
  <div>
    <SplitScreen
      v-if="isActive"
      :isActive="isActive"
      :isLoading="false"
      custom-class="w-96"
      @closeHandler="closeDetails"
    >
      <template #header>
        <span class="font-extrabold">{{ $t("fleet.affiliationTitle") }}</span>
      </template>

      <template #default>
        <div class="pt-4 h-full">
          <div class="w-auto mb-4 text-2xl">{{ subcontractor.name }} - {{ boardId }}</div>
          <div class="flex flex-col h-full">
            <div class="flex-1 overflow-auto">
              <div class="w-full mb-24">
                <div
                  v-for="(item, index) in subcontractorAffiliations"
                  :key="index"
                  class="bg-gray-100 p-3 mb-4 shadow-md"
                >
                  <div class="mb-1">{{ item.sequence }}</div>
                  <div class="divider w-full h-0.5 bg-gray-300 mb-1"></div>
                  <div class="mb-1">{{ `${$t("fleet.subcontractor")} ${$t("fleet.truck")} Name` }}</div>
                  <div class="mb-2">
                    <a-input v-model="item.truckName"></a-input>
                  </div>
                  <div>{{ `${$t("fleet.subcontractor")} ${$t("fleet.driver")} Name` }}</div>
                  <div>
                    <a-input v-model="item.driverName"></a-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="subContractorDetail__buttons">
              <Button @click="removeDetail" class="mr-4">
                <div class="flex items-center">
                  <Icon name="remove" height="19" width="19" />
                  <span>{{ $t("actions.remove") }}</span>
                </div>
              </Button>
              <Button @click="addDetail">
                <div class="flex items-center">
                  <Icon name="add" height="19" width="19" />
                  <span>{{ $t("actions.add") }}</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </template>

      <template #actionBar="{  }">
        <div class="w-full flex">
          <div class="ml-auto flex">
            <Button @click="closeDetails" variant="secondary" class="mr-4">
              <span>{{ $t("actions.close") }}</span>
            </Button>
            <Button @click="saveDetails">{{ $t("actions.save") }}</Button>
          </div>
        </div>
      </template>
    </SplitScreen>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted } from "@vue/composition-api";
import { Subcontractor } from "@/types/fleet";
import SplitScreen from "@/components/SplitScreen/SplitScreen.vue";
import Button from "@/components/Buttons/Button.vue";
import Icon from "@/components/Icon.vue";
import fleetService from "@/services/fleetService";
import { spreadSubcontractorId } from "@/use/useFleet";
import store from "@/store";
import { FETCH_BOARD_FLEET, LOCK_SUBCONTRACTOR, UNLOCK_SUBCONTRACTOR } from "@/store/fleet/";
import { translateAndToastError, translateAndToastSuccess } from "@/use/useToast";
import { SubcontractorBoardMembership } from "@/types/fleet";

export default defineComponent({
  name: "SubcontractorDetail",
  components: { SplitScreen, Button, Icon },
  props: {
    subcontractor: {
      type: Object as PropType<Subcontractor>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const subcontractorAffiliations = ref<SubcontractorBoardMembership[]>([]);
    const { id, boardId } = spreadSubcontractorId(props.subcontractor.id);

    onMounted(async () => {
      await store.dispatch(LOCK_SUBCONTRACTOR, id);
      subcontractorAffiliations.value = props.subcontractor?.boards
        .filter(board => board.boardId === boardId)
        .map(board => ({ ...board, from: undefined, to: undefined }))
        .sort((b1, b2) => b1.sequence - b2.sequence);
    });

    const addDetail = (): void => {
      subcontractorAffiliations.value.push({
        boardId: boardId,
        sequence: subcontractorAffiliations.value.length + 1,
        truckName: undefined,
        driverName: undefined
      });
    };

    const removeDetail = (): void => {
      subcontractorAffiliations.value.pop();
    };

    const closeDetails = () => {
      try {
        store.dispatch(UNLOCK_SUBCONTRACTOR, id);
        emit("close");
      } catch (e) {
        // pass
      }
    };

    const saveDetails = async () => {
      try {
        const { name, drivers, role } = props.subcontractor;
        const restAffiliations = props.subcontractor.boards
          .filter(board => board.boardId !== boardId)
          .map(board => ({ ...board, from: undefined, to: undefined }));
        const sanitizedSubiAffiliations = subcontractorAffiliations.value.map(affiliation => {
          const { truckName, driverName } = affiliation;
          return {
            ...affiliation,
            truckName: truckName === "" ? undefined : truckName,
            driverName: driverName === "" ? undefined : driverName
          };
        });
        const boards = [...sanitizedSubiAffiliations, ...restAffiliations];
        await fleetService.updateSubcontractor(id, {
          id,
          name,
          drivers,
          role: role,
          boards
        } as Subcontractor);
        await store.dispatch(FETCH_BOARD_FLEET);
        translateAndToastSuccess("subcontractor.updated");
      } catch (e) {
        translateAndToastError("subcontractor.updateFailed");
      }
      await store.dispatch(UNLOCK_SUBCONTRACTOR, id);
      emit("close");
    };

    return { subcontractorAffiliations, addDetail, removeDetail, closeDetails, saveDetails, boardId };
  }
});
</script>

<style scoped lang="scss">
.subContractorDetail__buttons {
  @apply fixed bg-white py-4 flex;
  bottom: 80px;
}
</style>
