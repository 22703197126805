var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-4 gap-x-5 gap-y-2" }, [
    _c(
      "div",
      { staticClass: "planned-departure-title text-gray-400 col-span-2" },
      [_vm._v(_vm._s(_vm.$t("leg.events.plannedDeparture")))]
    ),
    _c(
      "div",
      { staticClass: "planned-arrival-title text-gray-400 col-span-2" },
      [_vm._v(_vm._s(_vm.$t("leg.events.plannedArrival")))]
    ),
    _c(
      "div",
      { staticClass: "col-span-1" },
      [
        _c("span", { staticClass: "block text-gray-400 mb-2" }, [
          _vm._v(_vm._s(_vm.$t("dates.fromDate")))
        ]),
        _c("a-date-picker", {
          staticClass: "w-full",
          attrs: {
            format: "DD.MM.YYYY",
            size: "large",
            disabled: _vm.disableAllInputs || _vm.disablePlannedDepartureFields,
            "show-today": false,
            getCalendarContainer: _vm.setTriggerAsAnchor
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.planned.fromDate,
            callback: function($$v) {
              _vm.$set(_vm.planned, "fromDate", $$v)
            },
            expression: "planned.fromDate"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-1" },
      [
        _c("span", { staticClass: "block text-gray-400 mb-2" }, [
          _vm._v(_vm._s(_vm.$t("times.fromTime")))
        ]),
        _c("a-time-picker", {
          staticClass: "w-full",
          attrs: {
            size: "large",
            format: "HH:mm",
            disabled: _vm.disableAllInputs || _vm.disablePlannedDepartureFields,
            getPopupContainer: _vm.setTriggerAsAnchor
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.planned.fromTime,
            callback: function($$v) {
              _vm.$set(_vm.planned, "fromTime", $$v)
            },
            expression: "planned.fromTime"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-1" },
      [
        _c("span", { staticClass: "block text-gray-400 mb-2" }, [
          _vm._v(_vm._s(_vm.$t("dates.toDate")))
        ]),
        _c("a-date-picker", {
          staticClass: "w-full",
          attrs: {
            size: "large",
            format: "DD.MM.YYYY",
            disabled: _vm.disableAllInputs,
            "show-today": false,
            getCalendarContainer: _vm.setTriggerAsAnchor
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.planned.toDate,
            callback: function($$v) {
              _vm.$set(_vm.planned, "toDate", $$v)
            },
            expression: "planned.toDate"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-1" },
      [
        _c("span", { staticClass: "block text-gray-400 mb-2" }, [
          _vm._v(_vm._s(_vm.$t("times.toTime")))
        ]),
        _c("a-time-picker", {
          staticClass: "w-full",
          attrs: {
            size: "large",
            format: "HH:mm",
            disabled: _vm.disableAllInputs,
            getPopupContainer: _vm.setTriggerAsAnchor
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.planned.toTime,
            callback: function($$v) {
              _vm.$set(_vm.planned, "toTime", $$v)
            },
            expression: "planned.toTime"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "col-span-4 error-hint text-center text-red-600 error-transition overflow-hidden",
        class: { "h-0": _vm.datesValid, "h-6": !_vm.datesValid }
      },
      [_vm._v(" " + _vm._s(_vm.$t("errors.INVALID_PLANNED_DATE_RANGE")) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }