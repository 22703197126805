<template>
  <div class="relative">
    <svg
      :class="{
        'text-yellow-500': isActive,
        'text-green-500': passed
      }"
      class="steps fill-current text-gray-300"
      width="14"
      height="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill-rule="evenodd">
        <circle v-if="!isFirst" cx="7.5" cy="3.5" r="3.5" />
        <circle v-if="!isFirst" cx="7.5" cy="17.5" r="3.5" />
        <circle cx="7" cy="35" r="7" />
        <circle v-if="!isLast" cx="7.5" cy="52.5" r="3.5" />
        <circle v-if="!isLast" cx="7.5" cy="66.5" r="3.5" />
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    passed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const color = computed(() => {
      return {
        fill: "red"
      };
    });
    return { color };
  }
});
</script>

<style scoped>
.steps {
  top: -6px;
}
</style>
