import { Index } from "flexsearch";
import { BoardEntityType } from "@/types/board";

const SEARCH_ID_SEPARATOR = "__";

const index = new Index({
  tokenize: "full"
});

const buildSearchId = (id: string, contentType: BoardEntityType) => {
  return `${id}${SEARCH_ID_SEPARATOR}${contentType}`;
};

const spreadSearchId = (
  searchId: string
): {
  id: string;
  contentType: BoardEntityType;
} => {
  const [id, contentType] = searchId.split(SEARCH_ID_SEPARATOR);

  return {
    id,
    contentType: contentType as BoardEntityType
  };
};

const addToSearchIndex = (id: string, item: string) => {
  index.addAsync(id, item);
};

const searchIndex = (searchTerm: string): Promise<string[]> => {
  return index.searchAsync(searchTerm) as Promise<string[]>;
};

const removeFromSearchIndex = (id: string) => {
  index.remove(id);
};

export { addToSearchIndex, searchIndex, buildSearchId, spreadSearchId, removeFromSearchIndex };
