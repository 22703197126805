import { sortBy } from "@/use/useObject";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { FETCH_BOARD_FLEET, INIT_TRAILER_PROFILE } from "@/store/fleet/";
import {
  add,
  addBusinessDays,
  differenceInBusinessDays,
  differenceInDays,
  eachWeekendOfInterval,
  getHours,
  getMinutes,
  isBefore,
  isWeekend,
  parseISO
} from "date-fns";
import configProfileService from "@/services/configProfileService";
import { BoardProfiles, Boards, ConfigProfile } from "@/types/configProfile";
import { Board, BoardDetails, dayHourSettings, DefaultDateDaysPayload, TransportInfoStatus } from "@/types/board";
import boardService from "@/services/boardService";
import { setDateHoursToZero } from "@/use/useDate";
import { TimeTicker } from "@/types/timeTicker";
import { getBrowserLang } from "@/use/useBrowserLang";
import { i18n } from "@/i18n/i18n";
import { G11_WEB_DOMAIN_ALIAS, G11_WEB_URL, G11_WINDOW_HEIGHT, G11_WINDOW_WIDTH } from "@/env_config";
import { searchIndex } from "@/store/board/useSearchIndex";
import { INIT_CHAT_USERNAME } from "@/store/chat";

//Enums
const SET_BOARD_LOCAL = "SET_BOARD";
const SET_DATES_LOCAL = "SET_DATES";
const SET_FROM_TO_TIME_LOCAL = "SET_FROM_TO_TIME";
const INIT_BOARD_PROFILES_LOCAL = "INIT_BOARD_PROFILES";
const SET_BOARDS_DROPDOWN_LOCAL = "SET_BOARDS_DROPDOWN";
const UPDATE_BOARD_PROFILES_LOCAL = "UPDATE_BOARD_PROFILES";
const UPDATE_CONFIG_PROFILE_LOCAL = "UPDATE_CONFIG_PROFILE";
const UPDATE_FROM_TO_DATES_LOCAL = "UPDATE_FROM_TO_DATES";
const UPDATE_FROM_TO_TIME_LOCAL = "UPDATE_FROM_TO_TIME";
const INIT_BOARD_DATE_TIME_LOCAL = "INIT_BOARD_DATE_TIME";
const SET_TIME_TICKER_LOCAL = "SET_TIME_TICKER";
const SET_LANGUAGE_LOCAL = "SET_LANGUAGE";
const SET_SHOW_WEEKENDS_LOCAL = "SET_SHOW_WEEKENDS";
const SET_SHOW_PREVIOUS_LEG_DETAIL_LOCAL = "SET_SHOW_PREVIOUS_LEG_DETAIL";
const TOGGLE_BOARD_EDIT_MODE_STATE_LOCAL = `TOGGLE_BOARD_EDIT_MODE_STATE`;
const SET_TRANSPORT_INFO_STATUS_LOCAL = `SET_TRANSPORT_INFO_STATUS`;
const SET_DEFAULT_DATE_DAY_AMOUNTS_LOCAL = `SET_DEFAULT_DATE_DAY_AMOUNTS`;
const SET_DEFAULT_DATES_LOCAL = `SET_DEFAULT_DATES`;
const SPOT_BOARD_IDS_LOCAL = `SPOT_BOARD_IDS`;

const BOARD_STORE = "board";
const GET_TIME_TICKER = `${BOARD_STORE}/GET_TIME_TICKER`;
const UPDATE_HOURS = `${BOARD_STORE}/UPDATE_HOURS`;
const SET_TRUCKS = `${BOARD_STORE}/SET_TRUCKS`;
const GET_TRUCKS = `${BOARD_STORE}/GET_TRUCKS`;
const SET_BOARD = `${BOARD_STORE}/SET_BOARD`;
const GET_HOURBOX_WIDTH = `${BOARD_STORE}/GET_HOURBOX_WIDTH`;
const GET_BOARD_WIDTH = `${BOARD_STORE}/GET_BOARD_WIDTH`;
const SET_DATES = `${BOARD_STORE}/SET_DATES`;
const INIT_FROM_TO_DATES = `${BOARD_STORE}/INIT_FROM_TO_DATES`;
const SET_DAYS_DIFFERENCE = `${BOARD_STORE}/SET_DAYS_DIFFERENCE`;
const UPDATE_FROM_TO_DATES = `${BOARD_STORE}/UPDATE_FROM_TO_DATES`;
const SET_TO_TIME = `${BOARD_STORE}/SET_TO_TIME`;
const GET_HOURS_DIFFERENCE = `${BOARD_STORE}/GET_HOURS_DIFFERENCE`;
const GET_DAYS_DIFFERENCE = `${BOARD_STORE}/GET_DAYS_DIFFERENCE`;
const GET_HOUR_FROM_TO_TIME = `${BOARD_STORE}/GET_HOUR_FROM_TO_TIME`;
const GET_FROM_TO_DATE = `${BOARD_STORE}/GET_FROM_TO_DATE`;
const GET_FROM_TO_DATE_WITH_TIME = `${BOARD_STORE}/GET_FROM_TO_DATE_WITH_TIME`;
const GET_FILTERED_HOURS_AMOUNT = `${BOARD_STORE}/GET_FILTERED_HOURS_AMOUNT`;
const GET_FROM_TIME = `${BOARD_STORE}/GET_FROM_TIME`;
const SET_FROM_TO_TIME = `${BOARD_STORE}/SET_FROM_TO_TIME`;
const UPDATE_FROM_TO_TIME = `${BOARD_STORE}/UPDATE_FROM_TO_TIME`;
const INIT_USER_CONFIG_PROFILE = `${BOARD_STORE}/INIT_USER_CONFIG_PROFILE`;
const UPDATE_BOARD_PROFILES = `${BOARD_STORE}/UPDATE_BOARD_PROFILES`;
const INIT_BOARD_PROFILES = `${BOARD_STORE}/INIT_BOARD_PROFILES`;
const INIT_BOARDS_DROPDOWN = `${BOARD_STORE}/INIT_BOARDS_DROPDOWN`;
const SET_BOARDS_DROPDOWN = `${BOARD_STORE}/SET_BOARDS_DROPDOWN`;
const SET_BOARDS_SELECTED = `${BOARD_STORE}/SET_BOARDS_SELECTED`;
const SET_BOARD_READY = `${BOARD_STORE}/SET_BOARD_READY`;
const GET_BOARD_ID = `${BOARD_STORE}/GET_BOARD_ID`;
const UPDATE_CONFIG_PROFILE = `${BOARD_STORE}/UPDATE_CONFIG_PROFILE`;
const GET_FILTERED_BOARD_PROFILE = `${BOARD_STORE}/GET_FILTERED_BOARD_PROFILE`;
const GET_SELECTED_BOARDS = `${BOARD_STORE}/SELECTED_BOARDS`;
const INIT_BOARD_DATE_TIME = `${BOARD_STORE}/INIT_BOARD_DATE_TIME`;
const INIT_TIME_TICKER = `${BOARD_STORE}/INIT_TIME_TICKER`;
const UPDATE_LANGUAGE = `${BOARD_STORE}/UPDATE_LANGUAGE`;
const GET_LANGUAGE = `${BOARD_STORE}/GET_LANGUAGE`;
const GET_SELECTED_BOARD_IDS = `${BOARD_STORE}/GET_SELECTED_BOARD_IDS`;
const GET_BOARD_DETAILS = `${BOARD_STORE}/GET_BOARD_DETAILS`;
const SET_SHOW_WEEKENDS = `${BOARD_STORE}/SET_SHOW_WEEKENDS`;
const GET_SHOW_WEEKENDS = `${BOARD_STORE}/GET_SHOW_WEEKENDS`;
const TOGGLE_SHOW_WEEKENDS = `${BOARD_STORE}/TOGGLE_SHOW_WEEKENDS`;
const GET_SHOW_PREVIOUS_LEG_DETAIL = `${BOARD_STORE}/GET_SHOW_PREVIOUS_LEG_DETAIL`;
const TOGGLE_SHOW_PREVIOUS_LEG_DETAIL = `${BOARD_STORE}/TOGGLE_SHOW_PREVIOUS_LEG_DETAIL`;
const GET_HOUR_BOX_MIN_WIDTH = `${BOARD_STORE}/GET_HOUR_BOX_MIN_WIDTH`;
const GET_HOUR_BOX_EXTRA_MIN_WIDTH = `${BOARD_STORE}/GET_HOUR_BOX_EXTRA_MIN_WIDTH`;
const SET_ZOOM_LEVEL = `${BOARD_STORE}/SET_ZOOM_LEVEL`;
const GET_ZOOM_LEVEL = `${BOARD_STORE}/GET_ZOOM_LEVEL`;
const SET_TRANSPORT_INFO_STATUS = `${BOARD_STORE}/SET_TRANSPORT_INFO_STATUS`;
const GET_SELECTED_TRANSPORT_INFO_STATUS = `${BOARD_STORE}/GET_SELECTED_TRANSPORT_INFO_STATUS`;
const IS_BOARD_EDIT_MODE_ACTIVE = `${BOARD_STORE}/IS_BOARD_EDIT_MODE_ACTIVE`;
const TOGGLE_BOARD_EDIT_MODE = `${BOARD_STORE}/TOGGLE_BOARD_EDIT_MODE`;
const DAYS_FROM_TODAY_START = `${BOARD_STORE}/DAYS_FROM_TODAY_START`;
const DAYS_FROM_TODAY_END = `${BOARD_STORE}/DAYS_FROM_TODAY_END`;
const SET_DEFAULT_DATES = `${BOARD_STORE}/SET_DEFAULT_DATES`;
const IS_DEFAULT_DATE_ACTIVE = `${BOARD_STORE}/IS_DEFAULT_DATE_ACTIVE`;
const POST_G11_MESSAGE = `${BOARD_STORE}/POST_G11_MESSAGE`;
const PERSIST_BOARD_CONFIG = `${BOARD_STORE}/PERSIST_BOARD_CONFIG`;
const SEARCH_BOARD_ENTITIES = `${BOARD_STORE}/SEARCH_BOARD_ENTITIES`;
const GET_SPOTTED_IDS = `${BOARD_STORE}/GET_SPOTTED_IDS`;
const GET_FOCUSED_ID = `${BOARD_STORE}/GET_FOCUSED_ID`;
const SPOT_BOARD_IDS = `${BOARD_STORE}/${SPOT_BOARD_IDS_LOCAL}`;
const FOCUS_BOARD_ID = `${BOARD_STORE}/FOCUS_BOARD_ID`;

const BOARD_ZOOM_LEVEL = "TP_BOARD_ZOOM_LEVEL";
const G11_WINDOW_NAME = `${G11_WEB_DOMAIN_ALIAS}_windowForPostMessages`;

declare global {
  interface Window {
    g11Window: any;
  }
}

const recalculateDateSpan = function(
  from: Date,
  to: Date,
  allowWeekends: boolean
): {
  fromDate: Date;
  toDate: Date;
} {
  const fromDate = !allowWeekends && isWeekend(from) ? addBusinessDays(from, 1) : from;
  const toDate = !allowWeekends && isWeekend(to) ? addBusinessDays(to, -1) : to;

  if (fromDate > toDate) {
    return {
      fromDate: toDate,
      toDate: fromDate
    };
  }

  return {
    fromDate,
    toDate
  };
};

@Module({ namespaced: true })
export default class Index extends VuexModule {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  fromTime = dayHourSettings.DEFAULT_DAY_START;
  toTime = dayHourSettings.DEFAULT_DAY_END;
  days = 1;
  today: Date = new Date();

  showWeekends = false;
  showPreviousLegDetail = false;
  transportInfoStatus: TransportInfoStatus | null = null;
  isEditModeActive = false;

  boardProfiles: ConfigProfile[] = [];
  boards: Board[] = [];
  boardsSelected: Board[] | null = null;
  language: string = getBrowserLang();

  daysFromTodayStart: number | null = null;
  daysFromTodayEnd: number | null = null;

  spottedIds: string[] = [];
  focusedId: string | undefined = undefined;

  board = {
    offsetWidth: 0,
    boardReady: false,
    boxMinWidth: 3,
    zoomLevel: 1,
    boxExtraMinWidth: 1
  };

  get GET_SPOTTED_IDS() {
    return this.spottedIds;
  }

  get GET_FOCUSED_ID() {
    return this.focusedId;
  }

  get DAYS_FROM_TODAY_START() {
    return this.daysFromTodayStart;
  }

  get DAYS_FROM_TODAY_END() {
    return this.daysFromTodayEnd;
  }

  get GET_BOARD_WIDTH() {
    return this.board.offsetWidth;
  }

  get GET_ZOOM_LEVEL(): number {
    const zoomLevel = localStorage.getItem(BOARD_ZOOM_LEVEL);

    if (zoomLevel != undefined) {
      return parseInt(zoomLevel);
    }

    return this.board.zoomLevel;
  }

  get GET_HOUR_BOX_MIN_WIDTH() {
    const { zoomLevel, boxMinWidth, boxExtraMinWidth } = this.board;
    if (zoomLevel === 0) {
      return boxExtraMinWidth;
    }
    return boxMinWidth * zoomLevel;
  }

  get GET_HOUR_BOX_EXTRA_MIN_WIDTH() {
    return this.board.boxExtraMinWidth;
  }

  get GET_TIME_TICKER(): TimeTicker {
    const ticker = {
      show: false,
      offset: 0
    };
    // check it today date is between selected dates
    const hour = getHours(this.today);
    const minutes = getMinutes(this.today);
    const todayStart = new Date(setDateHoursToZero(this.today));
    if (this.fromDate <= todayStart && todayStart <= this.toDate) {
      //check if actual time is between selected time
      const toTime = this.fromTime + this.GET_FILTERED_HOURS_AMOUNT;
      if (this.fromTime <= hour && hour < toTime) {
        let dayDifference = differenceInDays(this.today, this.fromDate);
        if (!this.showWeekends) {
          dayDifference =
            dayDifference -
            eachWeekendOfInterval({
              start: this.fromDate,
              end: this.today
            }).length;
        }
        const daysOffsetInHours = dayDifference * this.GET_FILTERED_HOURS_AMOUNT;
        const hourDifference = hour - this.fromTime + daysOffsetInHours;
        const minutesOffset = (this.GET_HOURBOX_WIDTH / 60) * minutes;
        ticker.offset = hourDifference * this.GET_HOURBOX_WIDTH + minutesOffset;
        ticker.show = true;
      }
    }
    return ticker;
  }

  get GET_HOURBOX_WIDTH() {
    return this.board.offsetWidth / this.GET_HOURS_DIFFERENCE;
  }

  get GET_DAYS_DIFFERENCE() {
    const daysDifference =
      differenceInDays(this.toDate, this.fromDate) !== 0
        ? differenceInDays(add(this.toDate, { days: 1 }), this.fromDate)
        : this.days;

    return this.showWeekends
      ? daysDifference
      : daysDifference -
          eachWeekendOfInterval({
            start: this.fromDate,
            end: this.toDate
          }).length;
  }

  get GET_LANGUAGE() {
    return this.language;
  }

  get GET_SHOW_WEEKENDS() {
    return this.showWeekends;
  }

  get GET_SHOW_PREVIOUS_LEG_DETAIL() {
    return this.showPreviousLegDetail;
  }

  get GET_BOARD_ID() {
    const boardIds: string[] | undefined = this.boardsSelected
      ?.map(board => board.id)
      .concat()
      .sort(sortBy("boardId"));
    return boardIds?.join(",") || "";
  }

  get GET_SELECTED_BOARD_IDS(): string[] {
    return this.boardsSelected?.map(board => board.id) || [];
  }

  get SELECTED_BOARDS() {
    return this.boardsSelected;
  }

  get GET_HOUR_FROM_TO_TIME() {
    return {
      fromTime: this.fromTime,
      toTime: this.toTime
    };
  }

  get GET_FROM_TIME() {
    const { fromTime } = this.GET_HOUR_FROM_TO_TIME;
    return fromTime;
  }

  get GET_FROM_TO_DATE() {
    const { fromDate, toDate } = this;
    return {
      fromDate,
      toDate
    };
  }

  get GET_FROM_TO_DATE_WITH_TIME() {
    const { fromDate, fromTime, toDate, toTime } = this;
    return {
      fromDate: new Date(fromDate).setHours(fromTime),
      toDate: new Date(toDate).setHours(toTime)
    };
  }

  get GET_FILTERED_HOURS_AMOUNT() {
    const { fromTime, toTime } = this.GET_HOUR_FROM_TO_TIME;
    let hours: number = toTime - fromTime;
    //check if hours can be divided by 3 (needed for the 3 hours view or it will break the layout
    let numberToCheck = hours / 3;
    while (!Number.isInteger(numberToCheck)) {
      hours++;
      numberToCheck = hours / 3;
    }
    return hours;
  }

  get GET_HOURS_DIFFERENCE() {
    const hoursAmount = this.GET_FILTERED_HOURS_AMOUNT;
    return hoursAmount * this.GET_DAYS_DIFFERENCE;
  }

  get GET_BOARD_DETAILS(): BoardDetails {
    const boardFromDate = this.fromDate;
    const boardToDate = this.toDate;
    const boardFromTime = this.fromTime;
    const boardToTime = this.toTime;
    const hourBoxWidth = this.GET_HOURBOX_WIDTH;
    const boardWidth = this.GET_BOARD_WIDTH;

    const timeDiff = boardToTime - boardFromTime;
    const hourBoxesPerDay = (timeDiff % 3 > 0 ? Math.floor(timeDiff / 3) + 1 : timeDiff / 3) * 3;

    return {
      boardFromDate,
      boardToDate,
      boardFromTime,
      boardToTime,
      hourBoxWidth,
      boardWidth,
      hourBoxesPerDay
    };
  }

  get GET_SELECTED_TRANSPORT_INFO_STATUS() {
    return this.transportInfoStatus;
  }

  get IS_BOARD_EDIT_MODE_ACTIVE() {
    return this.isEditModeActive;
  }

  get IS_DEFAULT_DATE_ACTIVE() {
    return this.daysFromTodayStart != null && this.daysFromTodayEnd != null;
  }

  @Mutation
  SPOT_BOARD_IDS(ids) {
    this.spottedIds = ids;
  }

  @Mutation
  FOCUS_BOARD_ID(id) {
    this.focusedId = id;
  }

  @Mutation
  SET_DEFAULT_DATE_DAY_AMOUNTS(payload: DefaultDateDaysPayload) {
    const { daysFromTodayStart, daysFromTodayEnd } = payload;
    this.daysFromTodayStart = daysFromTodayStart;
    this.daysFromTodayEnd = daysFromTodayEnd;
  }

  @Mutation
  TOGGLE_BOARD_EDIT_MODE_STATE() {
    this.isEditModeActive = !this.isEditModeActive;
  }

  @Mutation
  SET_BOARD() {
    setTimeout(() => {
      const boardWidth = document.getElementById("board")?.getBoundingClientRect().width;
      this.board.offsetWidth = boardWidth || 0;
      this.board.boardReady = true;
    }, 0);
  }

  @Mutation
  SET_FROM_TO_TIME(payload: { fromTime: number; toTime: number }) {
    this.fromTime = payload.fromTime;
    this.toTime = payload.toTime;
  }

  @Mutation
  SET_DATES(payload: { fromDate: Date; toDate: Date }) {
    const { fromDate, toDate } = payload;
    this.fromDate = new Date(setDateHoursToZero(fromDate));
    this.toDate = new Date(setDateHoursToZero(toDate));
  }

  @Mutation
  INIT_BOARD_PROFILES(payload: BoardProfiles) {
    const array = payload.activeBoardId.split(",");
    const filtered = array.filter(boardId => boardId !== "");
    this.boardsSelected = filtered.map(boardId => {
      return { id: boardId, name: boardId } as Board;
    });
    this.boardProfiles = payload.boardProfiles;
  }

  @Mutation
  UPDATE_BOARD_PROFILES(payload: ConfigProfile) {
    //check for already saved boards with id
    const index = this.boardProfiles.findIndex(board => board.boardId === payload.boardId);
    if (index !== -1) {
      //if existing update entry
      this.boardProfiles[index] = payload;
    } else {
      //if not add new boardselection
      this.boardProfiles.push(payload);
    }
  }

  @Mutation
  SET_BOARDS_DROPDOWN(payload: Array<Board>) {
    this.boards = payload;
  }

  @Mutation
  SET_BOARDS_SELECTED(payload: Array<never>) {
    this.boardsSelected = payload;
  }

  @Mutation
  SET_TIME_TICKER(value: Date) {
    this.today = value;
  }

  @Mutation
  SET_LANGUAGE(value: string) {
    this.language = value;
    i18n.locale = value;
  }

  @Mutation
  SET_SHOW_WEEKENDS(value: boolean) {
    this.showWeekends = value;
  }

  @Mutation
  SET_SHOW_PREVIOUS_LEG_DETAIL(value: boolean) {
    this.showPreviousLegDetail = value;
  }

  @Mutation
  SET_ZOOM_LEVEL(value: number) {
    this.board.zoomLevel = value;
    //set the zoomLevel in localStorage as well
    localStorage.setItem(BOARD_ZOOM_LEVEL, value.toString());
  }

  @Mutation
  SET_TRANSPORT_INFO_STATUS(payload: TransportInfoStatus | null) {
    this.transportInfoStatus = payload;
  }

  @Action
  TOGGLE_SHOW_WEEKENDS() {
    const showWeekends = !this.showWeekends;
    this.context.commit(SET_SHOW_WEEKENDS_LOCAL, showWeekends);
    const boardDateSpan = recalculateDateSpan(this.fromDate, this.toDate, showWeekends);
    this.context.commit(SET_DATES_LOCAL, boardDateSpan);
  }

  @Action
  TOGGLE_SHOW_PREVIOUS_LEG_DETAIL() {
    this.context.commit(SET_SHOW_PREVIOUS_LEG_DETAIL_LOCAL, !this.showPreviousLegDetail);
  }

  @Action
  UPDATE_LANGUAGE(value: string) {
    this.context.commit(SET_LANGUAGE_LOCAL, value);
    this.context.dispatch(UPDATE_CONFIG_PROFILE_LOCAL);
  }

  @Action
  INIT_TIME_TICKER() {
    setInterval(() => {
      this.context.commit(SET_TIME_TICKER_LOCAL, new Date());
    }, 60 * 1000);
  }

  @Action
  async INIT_USER_CONFIG_PROFILE() {
    try {
      const { data } = await configProfileService.getUserConfigProfiles();
      this.context.commit(INIT_BOARD_PROFILES_LOCAL, data);
      this.context.dispatch(INIT_TRAILER_PROFILE, data, { root: true });
      this.context.dispatch(INIT_CHAT_USERNAME, null, { root: true });

      this.context.commit(SET_LANGUAGE_LOCAL, data.language);
      await this.context.dispatch(INIT_BOARD_DATE_TIME_LOCAL, data);
      const array = data.activeBoardId.split(",");
      const boardIds = array.filter(boardId => boardId !== "");
      if (boardIds.length > 0) {
        await this.context.dispatch(FETCH_BOARD_FLEET, boardIds, {
          root: true
        });
      }
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  INIT_BOARD_DATE_TIME(payload: BoardProfiles) {
    const activeProfile = payload.boardProfiles.find(profile => profile.boardId === payload.activeBoardId);
    if (typeof activeProfile !== "undefined") {
      const {
        boardStartsAt,
        boardEndsAt,
        dayStartsAt,
        dayEndsAt,
        showWeekends,
        daysFromTodayStart,
        daysFromTodayEnd
      } = activeProfile;

      this.context.commit(SET_SHOW_WEEKENDS_LOCAL, showWeekends);

      if (daysFromTodayStart != null && daysFromTodayEnd != null) {
        const days = {
          daysFromTodayStart,
          daysFromTodayEnd
        };
        this.context.dispatch(SET_DEFAULT_DATES_LOCAL, days);
      } else {
        const dates = {
          fromDate: boardStartsAt != null ? parseISO(boardStartsAt) : "",
          toDate: boardEndsAt != null ? parseISO(boardEndsAt) : ""
        };
        this.context.dispatch(UPDATE_FROM_TO_DATES_LOCAL, dates);
      }

      const hours = {
        fromTime: dayStartsAt != null ? parseInt(dayStartsAt) : "",
        toTime: dayEndsAt != null ? parseInt(dayEndsAt) : ""
      };

      this.context.dispatch(UPDATE_FROM_TO_TIME_LOCAL, hours);
    }
  }

  @Action
  async UPDATE_CONFIG_PROFILE(trailers?) {
    const boardId = this.GET_BOARD_ID;
    const boardProfile = {
      boardId,
      boardStartsAt: this.fromDate.toISOString(),
      boardEndsAt: this.toDate.toISOString(),
      dayStartsAt: this.fromTime.toString(),
      dayEndsAt: this.toTime.toString(),
      resolutionHours: "string",
      showWeekends: this.showWeekends,
      daysFromTodayStart: this.daysFromTodayStart,
      daysFromTodayEnd: this.daysFromTodayEnd,
      trailerTypes: trailers ? trailers : []
    } as ConfigProfile;
    this.context.commit(UPDATE_BOARD_PROFILES_LOCAL, boardProfile);
    try {
      const payload = {
        activeBoardId: boardId,
        boardProfiles: this.boardProfiles,
        language: this.language
      };
      await configProfileService.updateUserConfigProfiles(payload);
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async GET_FILTERED_BOARD_PROFILE() {
    //check for already saved boards with id
    const index = this.boardProfiles.findIndex(board => board.boardId === this.GET_BOARD_ID);

    if (index === -1) {
      await this.context.dispatch(UPDATE_CONFIG_PROFILE_LOCAL);
    } else {
      const { boardStartsAt, boardEndsAt, dayStartsAt, dayEndsAt } = this.boardProfiles[index];

      const dates = {
        fromDate: boardStartsAt != null ? parseISO(boardStartsAt) : "",
        toDate: boardEndsAt != null ? parseISO(boardEndsAt) : ""
      };
      await this.context.dispatch(UPDATE_FROM_TO_DATES_LOCAL, dates);

      const time = {
        fromTime: dayStartsAt != null ? parseInt(dayStartsAt) : "",
        toTime: dayEndsAt != null ? parseInt(dayEndsAt) : ""
      };
      await this.context.dispatch(UPDATE_FROM_TO_TIME_LOCAL, time);
    }
  }

  @Action
  async INIT_BOARDS_DROPDOWN() {
    try {
      const { data } = await boardService.getBoards();
      this.context.commit(SET_BOARDS_DROPDOWN_LOCAL, data.boards);
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async INIT_FROM_TO_DATES() {
    const dates = {
      fromDate: new Date(),
      toDate: new Date()
    };
    this.context.commit(SET_DATES_LOCAL, dates);
  }

  @Action
  UPDATE_FROM_TO_DATES(payload: { fromDate: Date; toDate: Date }) {
    this.context.commit(SET_DATES_LOCAL, payload);
    this.context.commit(SET_BOARD_LOCAL);
  }

  @Action
  UPDATE_FROM_TO_TIME(payload: { fromTime: number; toTime: number }) {
    this.context.commit(SET_FROM_TO_TIME_LOCAL, payload);
    this.context.commit(SET_BOARD_LOCAL);
  }

  @Action
  TOGGLE_BOARD_EDIT_MODE() {
    this.context.commit(TOGGLE_BOARD_EDIT_MODE_STATE_LOCAL);
    if (this.isEditModeActive) {
      this.context.commit(SET_TRANSPORT_INFO_STATUS_LOCAL, { type: "default", value: "default" });
    } else {
      this.context.commit(SET_TRANSPORT_INFO_STATUS_LOCAL, null);
    }
  }

  @Action
  SET_DEFAULT_DATES(payload: DefaultDateDaysPayload) {
    this.context.commit(SET_DEFAULT_DATE_DAY_AMOUNTS_LOCAL, payload);
    const today = new Date();
    let dates = {
      fromDate: add(today, { days: payload.daysFromTodayStart || 0 }),
      toDate: add(today, { days: payload.daysFromTodayEnd || 0 })
    };
    if (!this.showWeekends) {
      let fromDiff = differenceInBusinessDays(today, dates.fromDate);
      if (isBefore(dates.fromDate, today)) {
        fromDiff *= -1;
      }
      let toDiff = differenceInBusinessDays(today, dates.toDate);
      if (isBefore(today, dates.toDate)) {
        toDiff *= -1;
      }
      dates = {
        fromDate: addBusinessDays(today, fromDiff),
        toDate: addBusinessDays(today, toDiff)
      };
    }

    this.context.dispatch(UPDATE_FROM_TO_DATES_LOCAL, dates);
  }

  @Action
  POST_G11_MESSAGE(message: any) {
    try {
      if (!window.g11Window || window.g11Window.closed) {
        const g11Url = new URL(G11_WEB_URL);
        g11Url.searchParams.append("tp-record", message.data.record);
        g11Url.searchParams.append("tp-screen", message.data.screen);
        g11Url.searchParams.append("tp-leg-id", message.data.parameters.TR_LEG_ID);
        window.g11Window = window.open(
          g11Url.href,
          G11_WINDOW_NAME,
          "menubar = no, resizable = yes, scrollbars = yes, status = no, toolbar = no"
        );
        window.g11Window.resizeTo(G11_WINDOW_WIDTH, G11_WINDOW_HEIGHT);
        return;
      }

      window.g11Window.focus();
      window.g11Window.postMessage(message, G11_WEB_URL);
    } catch (e) {
      console.error("Failed to notify G11 Web", e);
    }
  }

  @Action
  async PERSIST_BOARD_CONFIG(orderedSidebarTiles: string[]): Promise<void> {
    try {
      const boardPayload: Boards = {
        boardId: this.GET_BOARD_ID,
        sidebarTiles: orderedSidebarTiles
      };
      await configProfileService.updateBoardConfig(boardPayload);
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Action
  async SEARCH_BOARD_ENTITIES(searchTerm: string): Promise<string[]> {
    const { commit } = this.context;

    const matchedIds = await searchIndex(searchTerm);

    commit(SPOT_BOARD_IDS_LOCAL, matchedIds);

    return Promise.resolve(matchedIds);
  }
}

export {
  PERSIST_BOARD_CONFIG,
  UPDATE_HOURS,
  SET_TRUCKS,
  GET_TRUCKS,
  SET_BOARD,
  GET_BOARD_WIDTH,
  GET_HOURBOX_WIDTH,
  SET_DATES,
  INIT_FROM_TO_DATES,
  SET_DAYS_DIFFERENCE,
  UPDATE_FROM_TO_DATES,
  SET_TO_TIME,
  GET_HOURS_DIFFERENCE,
  GET_DAYS_DIFFERENCE,
  GET_HOUR_FROM_TO_TIME,
  GET_FROM_TO_DATE,
  GET_FROM_TO_DATE_WITH_TIME,
  GET_FILTERED_HOURS_AMOUNT,
  GET_FROM_TIME,
  SET_FROM_TO_TIME,
  UPDATE_FROM_TO_TIME,
  INIT_USER_CONFIG_PROFILE,
  UPDATE_BOARD_PROFILES,
  INIT_BOARD_PROFILES,
  INIT_BOARDS_DROPDOWN,
  SET_BOARDS_DROPDOWN,
  SET_BOARDS_SELECTED,
  SET_BOARD_READY,
  GET_BOARD_ID,
  UPDATE_CONFIG_PROFILE,
  GET_FILTERED_BOARD_PROFILE,
  GET_SELECTED_BOARDS,
  INIT_BOARD_DATE_TIME,
  GET_TIME_TICKER,
  INIT_TIME_TICKER,
  UPDATE_LANGUAGE,
  GET_LANGUAGE,
  GET_SELECTED_BOARD_IDS,
  GET_BOARD_DETAILS,
  SET_SHOW_WEEKENDS,
  GET_SHOW_WEEKENDS,
  TOGGLE_SHOW_WEEKENDS,
  GET_HOUR_BOX_MIN_WIDTH,
  SET_ZOOM_LEVEL,
  GET_ZOOM_LEVEL,
  GET_SHOW_PREVIOUS_LEG_DETAIL,
  TOGGLE_SHOW_PREVIOUS_LEG_DETAIL,
  SET_TRANSPORT_INFO_STATUS,
  GET_SELECTED_TRANSPORT_INFO_STATUS,
  IS_BOARD_EDIT_MODE_ACTIVE,
  TOGGLE_BOARD_EDIT_MODE,
  DAYS_FROM_TODAY_START,
  DAYS_FROM_TODAY_END,
  SET_DEFAULT_DATES,
  IS_DEFAULT_DATE_ACTIVE,
  POST_G11_MESSAGE,
  SEARCH_BOARD_ENTITIES,
  GET_HOUR_BOX_EXTRA_MIN_WIDTH,
  GET_SPOTTED_IDS,
  GET_FOCUSED_ID,
  SPOT_BOARD_IDS,
  FOCUS_BOARD_ID
};
