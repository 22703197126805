


















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import store from "@/store/index";
import { GET_ZOOM_LEVEL, SET_ZOOM_LEVEL, SET_BOARD } from "@/store/board";
import { ADJUST_BRICK_SIZES } from "@/store/brick";
import { debounce } from "@/use/useTimeout";
import Overlay from "@/components/Overlay.vue";
import { UPDATE_FLEET_OCCUPANCY } from "@/store/fleet";

export default defineComponent({
  components: { Icon, Overlay },
  setup() {
    const loading = ref(false);
    const sliderValue = ref(1);

    const debounceBrickSizeAdjustment = debounce(async function() {
      await store.dispatch(ADJUST_BRICK_SIZES);
      await store.dispatch(UPDATE_FLEET_OCCUPANCY);
      loading.value = false;
    }, 300);

    const handleBoardZoom = (sliderValue): void => {
      loading.value = true;
      store.commit(SET_ZOOM_LEVEL, sliderValue);
      store.commit(SET_BOARD);
      debounceBrickSizeAdjustment();
    };

    const initZoomLevel = () => {
      //check localstorage for zoomLevel and update zoom
      const zoomLevel = store.getters[GET_ZOOM_LEVEL];
      sliderValue.value = zoomLevel;
      handleBoardZoom(zoomLevel);
    };

    onMounted(() => {
      initZoomLevel();
    });
    return { loading, handleBoardZoom, sliderValue };
  }
});
