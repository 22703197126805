<template>
  <div
    class="chat-window w-full fixed right-0 bg-white bottom-0 overflow-hidden border-2 flex flex-col"
    :style="chatWindowStyle"
  >
    <!-- HEADER -->
    <div class="chat-room-header h-12 flex justify-between items-center px-5 border-2">
      <div>
        <span class="text-xl">{{ `${activeChat ? activeChat.driverName : ""} - ` }} </span>
        <span class="inline-block mr-auto text-xl">{{ activeChat ? activeChat.alternateOrderNumber : "" }}</span>
      </div>
      <div @click="quitChatRoom" class="inline-block cursor-pointer"><Icon name="close" width="25" height="25" /></div>
    </div>

    <!-- BODY -->
    <div class="chat-room-body p-7 border-2 flex-1">
      <div v-for="(messagesForThisDate, date) in chatMessages" :key="date">
        <div class="flex justify-center sticky top-0">
          <div class="bg-btYellow text-black border border-black text-sm py-2 px-4 rounded-full">{{ date }}</div>
        </div>
        <!-- DISPLAYED MESSAGE -->
        <div
          v-for="(message, index) in messagesForThisDate"
          :key="index"
          :class="{
            'ml-auto chat-bubble-right': alignMessageOnRight(message.origin),
            'chat-bubble-left': !alignMessageOnRight(message.origin)
          }"
          class="chat-bubble my-2 p-4"
        >
          <div class="flex mt-1">
            <span class="inline-block mr-5 text-lg font-bold text-black">{{ message.author }}</span>
            <span class="text-lg font-bold text-black">{{ dateStringToShowTime(message.sent) }}</span>
          </div>
          <div class="text-lg whitespace-pre-line long-word-wrap">
            {{ message.text }}
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="chat-room-footer h-40 flex p-3 border-2">
      <div class="chat-field flex-1">
        <a-textarea
          class="input-field"
          v-model="inputValue"
          :auto-size="{ minRows: 1, maxRows: 3 }"
          :maxLength="512"
          :placeholder="`${$t('chat.message')}...`"
          @keydown.enter.shift.exact="sendMessage"
        />
        <div class="flex justify-end">
          <div class="text-sm text-gray-400">{{ inputValue.length }}/512</div>
        </div>
      </div>
      <div class="chat-submit-btn ml-2">
        <Button size="sm" @click="sendMessage" :disabled="messageQueue || isBookingIdEmpty">{{
          $t("chat.send")
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import {
  GET_ACTIVE_CHAT,
  GET_CHAT_USERNAME,
  GET_IS_CHAT_ACTIVE,
  SET_ACTIVE_CHAT,
  SET_IS_CHAT_ACTIVE
} from "@/store/chat";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Buttons/Button.vue";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import chatService from "@/services/chatService";
import { MessageOrigin } from "@/types/chat";

export default defineComponent({
  name: "ChatRoom",
  components: { Icon, Button },
  setup() {
    const inputValue = ref("");
    const isActive = computed(() => store.getters[GET_IS_CHAT_ACTIVE]);
    const activeChat = computed(() => store.getters[GET_ACTIVE_CHAT]);
    const chatUsername = computed(() => store.getters[GET_CHAT_USERNAME]);
    const messageQueue = ref(false);
    const isBookingIdEmpty = computed(() => activeChat.value?.bookingId == null);

    const chatWindowStyle = computed(() => {
      return {
        height: isActive.value ? "100vh" : "0px",
        paddingBottom: isActive.value ? "84px" : "0px",
        paddingTop: isActive.value ? "57px" : "0px"
      };
    });

    const quitChatRoom = () => {
      store.commit(SET_IS_CHAT_ACTIVE, false);
      store.commit(SET_ACTIVE_CHAT, null);
    };

    const chatMessages = computed(() => {
      const { messages } = activeChat.value || {};
      if (!messages) return {};
      const sortedMessages = [...messages].sort((a, b) => a.sequence - b.sequence);
      return sortedMessages.reduce((acc, message) => {
        const date = dateStringToShowDate(message.sent);
        if (!acc[date]) {
          acc[date] = [message];
        } else {
          acc[date].push(message);
        }
        return acc;
      }, {});
    });

    watch(chatMessages, async n => {
      await nextTick();
      if (Object.keys(n).length !== 0) {
        scrollToBottomOfChat("auto");
      }
    });

    onMounted(async () => {
      await nextTick();
      scrollToBottomOfChat("auto");
    });

    const sendMessage = async () => {
      if (messageQueue.value === true || inputValue.value === "" || isBookingIdEmpty.value) return;
      messageQueue.value = true;
      try {
        if (inputValue.value.length > 0) {
          await chatService.sendChatMessage(activeChat.value.bookingId, { text: inputValue.value });
          inputValue.value = "";
          messageQueue.value = false;
        } else {
          messageQueue.value = false;
        }
      } catch (e) {
        messageQueue.value = false;
      }
    };

    const alignMessageOnRight = (origin: MessageOrigin): boolean => {
      return origin === MessageOrigin.WEB;
    };

    const scrollToBottomOfChat = (scrollType: ScrollBehavior) => {
      const mostRecentMessage = Array.from(document.querySelectorAll(".chat-bubble")).pop();
      if (mostRecentMessage) {
        mostRecentMessage.scrollIntoView({ behavior: scrollType });
      }
    };

    return {
      isActive,
      chatWindowStyle,
      quitChatRoom,
      activeChat,
      chatMessages,
      dateStringToShowTime,
      chatUsername,
      messageQueue,
      inputValue,
      sendMessage,
      alignMessageOnRight,
      isBookingIdEmpty
    };
  }
});
</script>
<style lang="scss" scoped>
.chat-window {
  height: 0;
  width: 650px;
  transition: all 180ms ease-in-out;
}
.chat-room-body {
  overflow-y: auto;
}
.chat-bubble {
  width: fit-content;
  max-width: 60%;
  border-radius: 15px;
}
.chat-bubble-right {
  @apply bg-btYellow;
}
.chat-bubble-left {
  @apply bg-gray-300;
}
.chat-submit-btn {
  width: fit-content;
}
::v-deep .input-field {
  padding: 8px;
  resize: none;
}
.long-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
