














import { defineComponent, ref, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "LegDetailRemarks",
  emits: ["remarksChanged"],
  props: {
    defaultValue: {
      type: String
    }
  },
  setup(props, { emit }) {
    const remarks = ref<string>();

    const onChange = () => {
      emit("remarksChanged", remarks.value);
    };

    onMounted(() => {
      remarks.value = props.defaultValue;
    });

    return { remarks, onChange };
  }
});
