var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-base" }, [
    _c(
      "div",
      { staticClass: "flex mt-2" },
      [
        _c("a-switch", {
          staticClass: "mr-2",
          attrs: { size: "large" },
          on: { change: _vm.onDefaultDateSwitchChange },
          model: {
            value: _vm.defaultDateActivated,
            callback: function($$v) {
              _vm.defaultDateActivated = $$v
            },
            expression: "defaultDateActivated"
          }
        }),
        _c("span", [_vm._v(_vm._s(_vm.$t("settings.activated")))])
      ],
      1
    ),
    _vm.defaultDateActivated
      ? _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("a-divider"),
            _c("div", { staticClass: "flex items-center" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("a-input-number", {
                    attrs: { size: "large" },
                    model: {
                      value: _vm.daysFromTodayStartInput,
                      callback: function($$v) {
                        _vm.daysFromTodayStartInput = $$v
                      },
                      expression: "daysFromTodayStartInput"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "mx-2 flex items-center h-12" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("dates.today")))])
              ]),
              _c(
                "div",
                { staticClass: "flex flex-col" },
                [
                  _c("a-input-number", {
                    attrs: { min: _vm.daysFromTodayStartInput, size: "large" },
                    model: {
                      value: _vm.daysFromTodayEndInput,
                      callback: function($$v) {
                        _vm.daysFromTodayEndInput = $$v
                      },
                      expression: "daysFromTodayEndInput"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "flex mt-4 items-center" },
              [
                _c("a-date-picker", {
                  attrs: {
                    "default-value": _vm.fromDate,
                    disabled: "",
                    size: "large",
                    format: "DD.MM.YYYY"
                  },
                  model: {
                    value: _vm.fromDate,
                    callback: function($$v) {
                      _vm.fromDate = $$v
                    },
                    expression: "fromDate"
                  }
                }),
                _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                _c("a-date-picker", {
                  attrs: {
                    "default-value": _vm.toDate,
                    disabled: "",
                    size: "large",
                    format: "DD.MM.YYYY"
                  },
                  model: {
                    value: _vm.toDate,
                    callback: function($$v) {
                      _vm.toDate = $$v
                    },
                    expression: "toDate"
                  }
                }),
                _c(
                  "Button",
                  {
                    staticClass: "ml-auto",
                    attrs: { size: "sm" },
                    on: { click: _vm.onSaveButtonClick }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.save")))]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }