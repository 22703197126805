var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-12" }, [
    _vm.legData.details.ttaLink
      ? _c("div", { staticClass: "cursor-pointer" }, [
          _c(
            "a",
            {
              staticClass: "text-black",
              attrs: { href: _vm.legData.details.ttaLink, target: "_blank" }
            },
            [
              _vm.legData.details.flexible
                ? _c("Icon", {
                    staticClass: "leg__truck",
                    attrs: { name: "truck-flexible" }
                  })
                : _vm._e(),
              !_vm.legData.details.flexible
                ? _c("Icon", {
                    staticClass: "leg__truck",
                    attrs: { name: "truck" }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      : _c(
          "div",
          [
            _vm.legData.details.flexible
              ? _c("Icon", {
                  staticClass: "leg__truck",
                  attrs: { name: "truck-flexible" }
                })
              : _vm._e(),
            !_vm.legData.details.flexible
              ? _c("Icon", {
                  staticClass: "leg__truck",
                  attrs: { name: "truck" }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }