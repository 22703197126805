<template>
  <div class="actionReminderCallout">
    <div class="popover-body actionDetail__inner">
      <div v-for="action in sortedActions" :key="action.id">
        <div class="action mb-6">
          <div
            class="action__header border-b-2 font-semibold mb-2 flex justify-between"
            :class="{
              'border-btOrange': action.truckId || action.subcontractor,
              'border-blue-400': action.driverId,
              'border-yellow-900': action.trailerId
            }"
          >
            <div
              class="flex items-center"
              :class="{
                'text-btOrange': action.truckId || action.subcontractor,
                'text-blue-400': action.driverId,
                'text-yellow-900': action.trailerId
              }"
            >
              <div class="mr-1">
                <Icon v-if="!action.isReminder" name="services" width="20" />
                <Icon v-else name="notifications" width="20" />
              </div>
              {{ action.type }} -
              {{ equipmentId(action) }}
            </div>
            <div @click="openFleetAction(action)">
              <Icon tabindex="0" name="edit" width="18" class="cursor-pointer text-black hover:text-btYellow ml-12" />
            </div>
          </div>

          <div>
            <div>
              <span v-if="action.fromDate && action.fromDate.date"
                >{{ dateStringToShowDate(action.fromDate.date) }}
                {{ dateStringToShowTime(action.fromDate.date) }}h</span
              >
              <span v-if="action.toDate && action.toDate.date">
                - {{ dateStringToShowDate(action.toDate.date) }} {{ dateStringToShowTime(action.toDate.date) }}h</span
              >
            </div>
            <div>
              <span v-if="action.fromLocation && action.fromLocation.name">
                {{ action.fromLocation.name }}
              </span>
              <span v-if="action.toLocation && action.toLocation.name"> - {{ action.toLocation.name }}</span>
            </div>
            <div class="mt-1 font-light">
              {{ action.remarks }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import { FleetAction } from "@/types/action";

export default defineComponent({
  name: "CalloutSidebarAction.vue",
  components: {
    Icon
  },
  props: {
    actions: {
      type: Array as () => Array<FleetAction>,
      default: []
    }
  },
  setup(props, { emit }) {
    const { actions } = toRefs(props);

    const sortedActions = computed(() => actions.value.sort(getActionSortingOrder));

    const getEntityOrdinal = (action: FleetAction): number => {
      const { truckId, trailerId, driverId } = action;
      if (truckId) {
        return 1;
      }
      if (trailerId) {
        return 2;
      }
      if (driverId) {
        return 3;
      }
      return 100;
    };

    const getActionSortingOrder = (action1: FleetAction, action2: FleetAction): number => {
      return getEntityOrdinal(action1) - getEntityOrdinal(action2);
    };

    const equipmentId = (action: FleetAction): string => {
      if (action.truckId) {
        return action.truckId;
      }
      if (action.driverId) {
        return `${action.firstName} ${action.lastName}`;
      }
      if (action.trailerId) {
        return action.trailerId;
      }
      return "";
    };

    const openFleetAction = (actionItem: FleetAction) => {
      emit("onFleetAction", {
        ...actionItem,
        payloadType: "action"
      });
    };

    return {
      equipmentId,
      openFleetAction,
      dateStringToShowDate,
      dateStringToShowTime,
      sortedActions
    };
  }
});
</script>

<style scoped lang="scss">
.actionDetail__inner {
  max-height: 500px;
  overflow-x: auto;
}
.actionReminderCallout {
  width: 500px;
}
</style>
