






































import { computed, defineComponent } from "@vue/composition-api";
import { version } from "../../package.json";
import Logo from "@/components/Logo.vue";
import BoardDateSelector from "@/components/Board/DateSelector/DateSelector.vue";
import TimeLineZoom from "@/components/Board/TimeLine/TimeLineZoom/TimeLineZoom.vue";
import store from "@/store";
import Icon from "@/components/Icon.vue";
import Nav from "@/components/Nav/Nav.vue";
import { GET_BOARD_ID, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import ShowBoardEditMode from "@/components/Board/TransportInfoEditor/TransportInfoEditor.vue";
import ShowPreviousLegDetail from "@/components/Board/ShowPreviousLegDetail.vue";
import { FLEET_LOADING } from "@/store/fleet";
import SearchBox from "@/components/SearchBox/SearchBox.vue";
import ChatHub from "@/components/Chat/ChatHub.vue";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";
import { GET_CHATS, IS_CHAT_HUB_ACTIVE, TOGGLE_CHAT_HUB_MODE } from "@/store/chat";

export default defineComponent({
  name: "Header",
  components: {
    Logo,
    BoardDateSelector,
    TimeLineZoom,
    Icon,
    ChatNotifications,
    Nav,
    ShowPreviousLegDetail,
    ShowBoardEditMode,
    SearchBox,
    ChatHub
  },
  setup() {
    const title = computed(() => {
      const boardId: string = store.getters[GET_BOARD_ID];
      return boardId.replaceAll(",", " / ");
    });
    const boardEditMode = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const fleetIsLoading = computed(() => store.getters[FLEET_LOADING]);

    const chatHubActive = computed(() => store.getters[IS_CHAT_HUB_ACTIVE]);

    const chats = computed(() => store.getters[GET_CHATS]);

    const legs = computed(() => store.state.brick.legs);

    const totalLegsWithIssues = computed(() => {
      return legs.value.reduce((acc, leg) => {
        if (leg.details?.issues?.length > 0) {
          return acc + 1;
        }
        return acc;
      }, 0);
    });

    const totalUnreadMessages = computed(() =>
      chats.value.reduce((acc, chat) => {
        return acc + chat.unreadCount;
      }, 0)
    );

    const closeChatHub = () => {
      store.dispatch(TOGGLE_CHAT_HUB_MODE, false);
    };

    return {
      closeChatHub,
      chatHubActive,
      version,
      title,
      boardEditMode,
      fleetIsLoading,
      totalUnreadMessages,
      totalLegsWithIssues
    };
  }
});
