var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.legType.collection &&
    _vm.selectedLeg.events.loadingSlotFrom &&
    _vm.selectedLeg.events.loadingSlotTo
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.dateStringToShowTime(_vm.selectedLeg.events.loadingSlotFrom)
              ) +
              " - " +
              _vm._s(
                _vm.dateStringToShowTime(_vm.selectedLeg.events.loadingSlotTo)
              ) +
              " "
          )
        ])
      : _vm.legType.collection && _vm.selectedLeg.events.loadingSlotFrom
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.dateStringToShowTime(_vm.selectedLeg.events.loadingSlotFrom)
              ) +
              " "
          )
        ])
      : _vm.legType.delivery &&
        _vm.selectedLeg.events.unloadingSlotFrom &&
        _vm.selectedLeg.events.unloadingSlotTo
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.dateStringToShowTime(
                  _vm.selectedLeg.events.unloadingSlotFrom
                )
              ) +
              " - " +
              _vm._s(
                _vm.dateStringToShowTime(_vm.selectedLeg.events.unloadingSlotTo)
              ) +
              " "
          )
        ])
      : _vm.legType.delivery && _vm.selectedLeg.events.unloadingSlotFrom
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.dateStringToShowTime(
                  _vm.selectedLeg.events.unloadingSlotFrom
                )
              ) +
              " "
          )
        ])
      : _vm._e(),
    (_vm.legType.dropSwap || _vm.legType.fullTransport) &&
    _vm.selectedLeg.events.loadingSlotFrom
      ? _c("div", [
          _vm._v(
            " L: " +
              _vm._s(
                _vm.dateStringToShowTime(_vm.selectedLeg.events.loadingSlotFrom)
              ) +
              " "
          ),
          _vm.selectedLeg.events.loadingSlotTo
            ? _c("span", [
                _vm._v(
                  "- " +
                    _vm._s(
                      _vm.dateStringToShowTime(
                        _vm.selectedLeg.events.loadingSlotTo
                      )
                    )
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    (_vm.legType.dropSwap || _vm.legType.fullTransport) &&
    _vm.selectedLeg.events.unloadingSlotFrom
      ? _c("div", [
          _vm._v(
            " U: " +
              _vm._s(
                _vm.dateStringToShowTime(
                  _vm.selectedLeg.events.unloadingSlotFrom
                )
              ) +
              " "
          ),
          _vm.selectedLeg.events.unloadingSlotTo
            ? _c("span", [
                _vm._v(
                  " - " +
                    _vm._s(
                      _vm.dateStringToShowTime(
                        _vm.selectedLeg.events.unloadingSlotTo
                      )
                    )
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }