












import store from "@/store";
import Logo from "@/components/Logo.vue";
import { FLEET_LOADING } from "@/store/fleet";
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: { Logo },
  setup() {
    const isLoading = computed(() => store.getters[FLEET_LOADING]);

    return { isLoading };
  }
});
