






import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  components: { Icon },
  setup(props, { emit }) {
    const showBoardSettings = () => {
      emit("showBoardSettings");
    };
    return { showBoardSettings };
  }
});
