<template>
  <div>
    <a-config-provider :locale="locale">
      <a-date-picker
        v-model="selectedDate"
        size="large"
        format="DD.MM.YYYY"
        :disabled-date="disabledDates"
        :showToday="false"
        :getCalendarContainer="trigger => trigger.parentNode"
        @change="updateDate"
      >
        <template #suffixIcon>
          <a-icon type="calendar" />
        </template>
      </a-date-picker>
    </a-config-provider>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted, PropType } from "@vue/composition-api";
import moment from "moment";
import { GET_LANGUAGE } from "@/store/board";
import store from "@/store";
import { getDatePickerLocale } from "@/i18n/i18nUtils";

export default defineComponent({
  name: "fleetFormsDatePicker",
  props: {
    value: Date,
    minDate: {
      type: Date,
      required: false
    },
    maxDate: {
      type: Date,
      required: false
    },
    defaultDate: {
      type: Object as PropType<moment.Moment>
    }
  },
  setup(props, { emit }) {
    const selectedDate = ref<any>(null);

    const lang = computed(() => store.getters[GET_LANGUAGE]);
    const locale = ref(getDatePickerLocale(lang.value));
    onMounted(() => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch(lang, () => {
      locale.value = getDatePickerLocale(lang.value);
    });

    watch(
      () => props.value,
      newValue => {
        selectedDate.value = newValue == null ? null : moment(newValue);
      }
    );

    const disabledDates = current => {
      if (!props.minDate && !props.maxDate) {
        return false;
      }
      if (props.minDate && !props.maxDate) {
        return current.isBefore(moment(props.minDate));
      }
      if (!props.minDate && props.maxDate) {
        return current.isAfter(moment(props.maxDate));
      }
      return true;
    };

    const updateDate = (): void => {
      emit("input", selectedDate.value?.toDate());
    };

    return {
      locale,
      disabledDates,
      selectedDate,
      updateDate
    };
  }
});
</script>

<style lang="scss">
.fleet-forms-date-picker__date {
  &-input {
    height: 40px;
    border-color: #d9d9d9;
    &:hover {
      border-color: #ffc537;
    }
    &::placeholder {
      color: #bfbfbf !important;
    }
  }
  &-icon {
    padding-right: 11px;
    & > svg {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
