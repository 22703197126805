<template>
  <div class="flex flex-row justify-end transport-statuses">
    <Icon
      v-if="status.hasCustoms"
      name="customs"
      :class="{ 'text-green-600': !status.hasPendingCustoms }"
      width="20"
      height="20"
    />
    <Icon v-if="status.hasPendingTemperatures" name="thermostat" width="20" height="20" />
    <Icon v-if="status.hasPendingSpecialEquipments" name="construction" width="20" height="20" />
    <Icon v-if="status.hasPendingDocuments" name="description" width="20" height="20" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import { LegTransportStatuses } from "@/types/leg";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "LegTransportStatusIndicator",
  components: { Icon },
  props: {
    status: {
      type: Object as () => LegTransportStatuses,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.transport-statuses {
  min-height: 20px;
}
</style>
