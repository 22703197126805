/**
 * Collection of helper functions
 * Some native functions inspired from lodash or underscore
 * Check https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
 */

//Sorts an array of object based on an object key provided by a parameter
// The native sort modifies the array in place. `_.orderBy` and `_.sortBy` do not, so we use `.concat()` to
// copy the array, then sort.
// fruits.concat().sort(sortBy("name"));
const sortBy = (key: string) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

const sortByNested = (key: string, nestedKey: string) => {
  return (a, b) => (a[key][nestedKey] > b[key][nestedKey] ? 1 : b[key][nestedKey] > a[key][nestedKey] ? -1 : 0);
};

//Checks if value is an empty object or collection.
const isEmpty = (obj): boolean =>
  [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

const hasValues = (obj, ignoredKeys: string[]): boolean => {
  return Object.keys(obj)
    .filter(key => !ignoredKeys.includes(key))
    .some(key => obj[key] != null);
};

const stringifyNestedValues = (obj: any): string => {
  let result = "";
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value == null) return;
    if (typeof value !== "object") {
      result = `${result} ${value}`;
    } else {
      result = `${result} ${stringifyNestedValues(value)}`;
    }
  });

  return result;
};

export { sortBy, sortByNested, isEmpty, hasValues, stringifyNestedValues };
