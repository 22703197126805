import { $axios } from "@/config/axios";
import { AxiosResponse } from "axios";
import { BoardProfiles, Boards } from "@/types/configProfile";

const baseSlug = "/v1/config-profile";

export default {
  async getUserConfigProfiles(): Promise<AxiosResponse<BoardProfiles>> {
    return await $axios.get(`${baseSlug}/users`);
  },
  updateUserConfigProfiles(payload: BoardProfiles) {
    return $axios.post(`${baseSlug}/users`, payload);
  },
  updateBoardConfig(payload: Boards): Promise<AxiosResponse<Boards>> {
    return $axios.post(`${baseSlug}/boards`, payload);
  },
  getBoardConfig(payload: { boardId: string }): Promise<AxiosResponse<Boards>> {
    return $axios.post(`${baseSlug}/boards/fetch`, payload);
  }
};
