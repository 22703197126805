var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActive
    ? _c(
        "div",
        {
          staticClass:
            "w-full h-full flex left-0 top-0  bg-black bg-opacity-40 justify-center items-center absolute"
        },
        [
          _c("icon", {
            staticClass: "text-btYellow",
            attrs: { name: "spinner" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }