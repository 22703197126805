var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiSelect", {
    attrs: {
      value: _vm.actualLanguage,
      options: _vm.languages,
      placeholder: "Select language",
      label: "value",
      "track-by": "key",
      allowEmpty: false,
      showLabels: false
    },
    on: { input: _vm.languageChanged }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }