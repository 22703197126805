<template>
  <div
    v-if="hasDifferentDriver || hasDifferentTrailer || hasSubiWithPrimaryBoard"
    class="absolute bottom-0 right-0 leg"
  >
    <ClosablePopover placement="top" :overlayStyle="{ maxWidth: '500px', width: '500px', height: '260px' }">
      <template #popoverContent>
        <CalloutLegTrailer
          :title="legContent.trailerId"
          :trailer-id="legContent.trailerId"
          v-if="hasDifferentTrailer"
        />
        <CalloutLegDriver :driver-id="legContent.driverId" v-if="hasDifferentDriver" />
      </template>
      <template #popoverTrigger>
        <div
          :class="{
            'indicator--both leg_callout': hasDifferentDriver && hasDifferentTrailer,
            'indicator--blue leg_callout': hasDifferentDriver,
            'indicator--brown leg_callout': hasDifferentTrailer,
            indicator: !isViewedInPopover,
            'indicator-popover leg_callout': isViewedInPopover
          }"
        ></div>
        <span
          class="cursor-pointer border-b border-gray-400 hover:border-yellow-500 mb-2 mr-3.5 inline-block"
          @touchstart="onTouchStartHandler"
          v-if="legSize.xl && hasDifferentDriver"
        >
          {{ legContent.driverId }}
        </span>
        <span
          class="cursor-pointer border-b border-gray-400 hover:border-yellow-500 mb-2 mr-3.5 inline-block"
          @touchstart="onTouchStartHandler"
          v-if="legSize.xl && hasDifferentTrailer"
        >
          {{ legContent.trailerId }}
        </span>
      </template>
    </ClosablePopover>
    <span class="cursor-pointer mb-2 mr-3.5 inline-block" v-if="legSize.xl && hasSubiWithPrimaryBoard">
      {{ legContent.subcontractor.subconDriverName }}
    </span>
    <span
      class="cursor-pointer mb-2 mr-3.5 inline-block"
      v-if="legSize.xl && hasSubiWithPrimaryBoard && legContent.trailerId"
    >
      {{ legContent.trailerId }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api";
import { DisplayLegContent, LegSize } from "@/types/leg";
import CalloutLegTrailer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegTrailer.vue";
import CalloutLegDriver from "@/components/Board/CallOuts/CalloutLeg/CalloutLegDriver.vue";
import ClosablePopover from "@/components/ClosablePopover.vue";

export default defineComponent({
  name: "LegAffiliationPopover",
  components: {
    CalloutLegTrailer,
    CalloutLegDriver,
    ClosablePopover
  },
  props: {
    hasDifferentDriver: {
      type: Boolean,
      default: false
    },
    hasDifferentTrailer: {
      type: Boolean
    },
    hasSubiWithPrimaryBoard: {
      type: Boolean,
      default: false
    },
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    onTouchStartHandler: {
      type: Function,
      default: () => ({})
    },
    legSize: {
      type: Object as PropType<LegSize>,
      required: true
    },
    isViewedInPopover: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
@use "../Leg.scss";
.indicator-popover {
  @apply absolute -bottom-1 -right-1 w-6 h-6;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
</style>
