import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AffiliationRequest,
  Driver,
  DriverUpdate,
  Fleet,
  FleetEntity,
  FleetEntityDescription,
  FleetEntityType,
  FleetIdentifier,
  FleetPayload,
  FleetSchedule,
  OrderedFleetIds,
  Subcontractor,
  Trailer,
  Truck,
  TruckDriverSchedule,
  TrailerMeta
} from "@/types/fleet";
import fleetService from "@/services/fleetService";
import configProfileService from "@/services/configProfileService";
import {
  GET_BOARD_DETAILS,
  GET_BOARD_ID,
  GET_LANGUAGE,
  GET_SELECTED_BOARD_IDS,
  GET_SHOW_WEEKENDS,
  PERSIST_BOARD_CONFIG
} from "@/store/board/";
import {
  FETCH_FLEET_ACTIONS,
  FETCH_LEGS,
  GET_DIRTY_FLEET_ACTION_ID,
  GET_DIRTY_LEG_ID,
  GET_FLEET_ACTION_BY_ID,
  GET_LEG_BY_ID,
  SORT_LEGS,
  FETCH_ACTIONS_ON_TRAILERS,
  GET_LEGS,
  GET_BOARD_ACTIONS,
  SET_LEGS,
  INDEX_LEGS,
  SET_BOARD_ACTIONS,
  INDEX_BOARD_ACTIONS,
  GET_INITIAL_LEGS,
  GET_INITIAL_ACTIONS
} from "@/store/brick";
import { endOfDay, startOfDay } from "date-fns";
import { BoardDetails, BoardEntityType, StringNumberMap } from "@/types/board";
import { toIsoWithNoOffset } from "@/use/useDate";
import { FleetAction, FleetActionIdFilter } from "@/types/action";
import { toastAxiosError, translateAndToastSuccess, translateAndToastWarn } from "@/use/useToast";
import { findLastIndex } from "@/use/useArray";
import {
  buildSubcontractorId,
  buildTrailerId,
  buildTruckId,
  getNoSeqSubcontractorId,
  spreadSubcontractorId,
  spreadTruckId,
  spreadTrailerId,
  groupBy,
  filterTrailers
} from "@/use/useFleet";
import { deriveBoardFlexibles, getBrickSubcontractorId } from "@/store/brick/useBrick";
import { AxiosError } from "axios";
import { i18n } from "@/i18n/i18n";
import { getBrickFleetIdentifier } from "@/use/useBrick";
import { sortByNested, stringifyNestedValues } from "@/use/useObject";
import { deriveBoardOccupancy } from "@/store/board/useOccupancyBlock";
import { addToSearchIndex, buildSearchId } from "@/store/board/useSearchIndex";
import { ConfigProfile } from "@/types/configProfile";
import { BoardLeg, Leg } from "@/types/leg";
import { FETCH_CHATS } from "@/store/chat";
import { getLastLocationName } from "@/use/useLastLocation";
import { ErrorResponse } from "@/types/types";

//enums
const SET_FLEET_LOCAL = "SET_FLEET";
const SET_FLEET_LOADING_LOCAL = "SET_FLEET_LOADING";
const SET_FLEET_ACTIONS_LOCAL = "SET_FLEET_ACTIONS";
const SORT_FLEET_LOCAL = "SORT_FLEET";
const FETCH_BOARD_FLEET_LOCAL = "FETCH_BOARD_FLEET";
const GET_EXTENDED_FLEET_ACTIONS_LOCAL = "GET_EXTENDED_FLEET_ACTIONS";
const UPDATE_FLEET_ORDER_LOCAL = "UPDATE_FLEET_ORDER";
const SET_FLEET_PURE_LOCAL = "SET_FLEET_PURE";
const SET_DRIVERS_LOCAL = "SET_DRIVERS";
const SET_DRIVER_COMMENT_TOGGLE_LOCAL = "SET_DRIVER_COMMENT_TOGGLE";
const SET_ACTIVE_REMARKS_DESCRIPTION_LOCAL = "SET_ACTIVE_REMARKS_DESCRIPTION";
const GET_SUBCONTRACTOR_BY_ID_LOCAL = "GET_SUBCONTRACTOR_BY_ID";
const GET_FLEET_ENTITY_DESCRIPTION_LOCAL = "GET_FLEET_ENTITY_DESCRIPTION";
const COMPILE_AND_TOAST_UNSAVED_ACTION_LOCAL = "COMPILE_AND_TOAST_UNSAVED_ACTION";
const UPDATE_FLEET_OCCUPANCY_LOCAL = "UPDATE_FLEET_OCCUPANCY";
const SET_FLEET_FILTERED_LOCAL = "SET_FLEET_FILTERED";
const SET_TRAILERS_DROPDOWN_LOCAL = "SET_TRAILERS_DROPDOWN";
const INIT_TRAILERS_DROPDOWN_LOCAL = "INIT_TRAILERS_DROPDOWN";
const UPDATE_TRAILER_LOCAL = "UPDATE_TRAILER";
const SET_FLEET_SPANS_LOCAL = "SET_FLEET_SPANS";

const SET_ACTIVE_BOARD_TRAILERS_LOCAL = "SET_ACTIVE_BOARD_TRAILERS";
const SET_TRAILERS_SELECTED_LOCAL = "SET_TRAILERS_SELECTED";
const FETCH_BOARD_FLEET_TRAILERS_LOCAL = "FETCH_BOARD_FLEET_TRAILERS";
const SET_FLEET_TRAILERS_LOCAL = "SET_FLEET_TRAILERS";

const UPDATE_FLEET_LOCAL = "UPDATE_FLEET";
const SET_DIRTY_TRUCK_LOCAL = "SET_DIRTY_TRUCK";
const UNSET_DIRTY_TRUCK_LOCAL = "UNSET_DIRTY_TRUCK";
const SET_DIRTY_DRIVER_LOCAL = "SET_DIRTY_DRIVER";
const UNSET_DIRTY_DRIVER_LOCAL = "UNSET_DIRTY_DRIVER";
const SET_DIRTY_SUBCONTRACTOR_LOCAL = "SET_DIRTY_SUBCONTRACTOR";
const UNSET_DIRTY_SUBCONTRACTOR_LOCAL = "UNSET_DIRTY_SUBCONTRACTOR";

const FLEET_STORE = "fleet";
const FETCH_BOARD_FLEET = `${FLEET_STORE}/${FETCH_BOARD_FLEET_LOCAL}`;
const GET_FLEET = `${FLEET_STORE}/GET_FLEET`;
const CLEAR_FLEET = `${FLEET_STORE}/CLEAR_FLEET`;
const FLEET_IS_EMPTY = `${FLEET_STORE}/FLEET_IS_EMPTY`;
const SET_FLEET_LOADING = `${FLEET_STORE}/SET_FLEET_LOADING`;
const FLEET_LOADING = `${FLEET_STORE}/FLEET_LOADING`;
const UPDATE_FLEET_ORDER = `${FLEET_STORE}/UPDATE_FLEET_ORDER`;
const UPDATE_FLEET = `${FLEET_STORE}/UPDATE_FLEET`;
const SORT_FLEET = `${FLEET_STORE}/SORT_FLEET`;
const GET_FLEET_TRUCK_IDS = `${FLEET_STORE}/GET_FLEET_TRUCK_IDS`;
const GET_FLEET_IDS = `${FLEET_STORE}/GET_FLEET_IDS`;
const SET_MOVED_FLEET_ENTITIES = `${FLEET_STORE}/SET_MOVED_FLEET_ENTITIES`;
const CLEAR_MOVED_FLEET_ENTITIES = `${FLEET_STORE}/CLEAR_MOVED_FLEET_ENTITIES`;
const GET_TRAILER_BY_ID = `${FLEET_STORE}/GET_TRAILER_BY_ID`;
const GET_SUBCONTRACTOR_BY_ID = `${FLEET_STORE}/GET_SUBCONTRACTOR_BY_ID`;
const GET_DRIVER_BY_ID = `${FLEET_STORE}/GET_DRIVER_BY_ID`;
const FETCH_DRIVER = `${FLEET_STORE}/FETCH_DRIVER`;
const SET_FLEET_SPANS = `${FLEET_STORE}/SET_FLEET_SPANS`;
const GET_FLEET_SPANS = `${FLEET_STORE}/GET_FLEET_SPANS`;
const UPDATE_FLEET_AFFILIATION = `${FLEET_STORE}/UPDATE_FLEET_AFFILIATION`;
const REPOPULATE_EXTENDED_FLEET_ACTIONS = `${FLEET_STORE}/REPOPULATE_EXTENDED_FLEET_ACTIONS`;
const IS_DRIVER_COMMENT_TOGGLE_ACTIVE = `${FLEET_STORE}/IS_DRIVER_COMMENT_TOGGLE_ACTIVE`;
const ACTIVATE_DRIVER_COMMENT_TOGGLE = `${FLEET_STORE}/ACTIVATE_DRIVER_COMMENT_TOGGLE`;
const DEACTIVATE_DRIVER_COMMENT_TOGGLE = `${FLEET_STORE}/DEACTIVATE_DRIVER_COMMENT_TOGGLE`;
const LOCK_DRIVER = `${FLEET_STORE}/LOCK_DRIVER`;
const UNLOCK_DRIVER = `${FLEET_STORE}/UNLOCK_DRIVER`;
const UPDATE_DRIVER = `${FLEET_STORE}/UPDATE_DRIVER`;
const GET_ACTIVE_REMARKS_DESCRIPTION = `${FLEET_STORE}/GET_ACTIVE_REMARKS_DESCRIPTION`;
const GET_NOTRATED_SUBCONTRACTORS = `${FLEET_STORE}/GET_NOTRATED_SUBCONTRACTORS`;
const UPDATE_ACTIVE_REMARKS_DESCRIPTION = `${FLEET_STORE}/UPDATE_ACTIVE_REMARKS_DESCRIPTION`;
const GET_FLEET_ENTITY_DESCRIPTION = `${FLEET_STORE}/GET_FLEET_ENTITY_DESCRIPTION`;
const TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS = `${FLEET_STORE}/TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS`;
const TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION = `${FLEET_STORE}/TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION`;
const UPDATE_FLEET_OCCUPANCY = `${FLEET_STORE}/${UPDATE_FLEET_OCCUPANCY_LOCAL}`;
const GET_TRAILER_OPTIONS = `${FLEET_STORE}/GET_TRAILER_OPTIONS`;
const GET_TRAILERS_SELECTED = `${FLEET_STORE}/GET_TRAILERS_SELECTED`;
const SET_TRAILERS_SEARCH_KEYS_LOCAL = "SET_TRAILERS_SEARCH_KEYS";
const INIT_TRAILERS_DROPDOWN = `${FLEET_STORE}/INIT_TRAILERS_DROPDOWN`;
const INIT_TRAILER_PROFILE = `${FLEET_STORE}/INIT_TRAILER_PROFILE`;
const SET_TRAILERS_SELECTED = `${FLEET_STORE}/SET_TRAILERS_SELECTED`;
const FETCH_BOARD_FLEET_TRAILERS = `${FLEET_STORE}/FETCH_BOARD_FLEET_TRAILERS`;
const REMOVE_SELECTED_TRAILERS = `${FLEET_STORE}/REMOVE_SELECTED_TRAILERS`;
const UPDATE_TRAILERS_VIA_LEG = `${FLEET_STORE}/UPDATE_TRAILERS_VIA_LEG`;
// const UPDATE_TRAILERS_VIA_FLEET = `${FLEET_STORE}/UPDATE_TRAILERS_VIA_FLEET`;
const UPDATE_TRAILER = `${FLEET_STORE}/UPDATE_TRAILER`;
const REMOVE_TRAILER = `${FLEET_STORE}/REMOVE_TRAILER`;
const LOCK_TRUCK = `${FLEET_STORE}/LOCK_TRUCK`;
const UNLOCK_TRUCK = `${FLEET_STORE}/UNLOCK_TRUCK`;
const LOCK_SUBCONTRACTOR = `${FLEET_STORE}/LOCK_SUBCONTRACTOR`;
const UNLOCK_SUBCONTRACTOR = `${FLEET_STORE}/UNLOCK_SUBCONTRACTOR`;
const GET_DIRTY_TRUCK_ID = `${FLEET_STORE}/GET_DIRTY_TRUCK_ID`;
const GET_DIRTY_DRIVER_ID = `${FLEET_STORE}/GET_DIRTY_DRIVER_ID`;
const GET_DIRTY_SUBCONTRACTOR_ID = `${FLEET_STORE}/GET_DIRTY_SUBCONTRACTOR_ID`;

const getLastIndexPerEntityType = (fleet: FleetEntity[]): Map<FleetEntityType, number> => {
  return fleet.reduce((indexMap, entity, index) => {
    indexMap.set(entity.type, index);
    return indexMap;
  }, new Map<FleetEntityType, number>());
};

const getLastIndexPerSubiId = (fleet: FleetEntity[]): Map<string, number> => {
  return fleet.reduce((indexMap, entity, index) => {
    if (entity.type === FleetEntityType.SUBCONTRACTOR) {
      indexMap.set(getNoSeqSubcontractorId(entity.id), index);
    }
    return indexMap;
  }, new Map<string, number>());
};

const getLastIndexPerTruckId = (fleet: FleetEntity[]): Map<string, number> => {
  return fleet.reduce((indexMap, entity, index) => {
    if (entity.type === FleetEntityType.TRUCK && entity.groupGlueId) {
      indexMap.set(entity.groupGlueId, index);
    }
    return indexMap;
  }, new Map<string, number>());
};

const getLastIndexForType = (fleetIndexes: Map<FleetEntityType, number>, type: FleetEntityType): number | undefined => {
  switch (type) {
    case FleetEntityType.TRUCK.toLowerCase():
      return fleetIndexes.get(FleetEntityType.TRUCK);
    case FleetEntityType.SUBCONTRACTOR.toLowerCase():
      return fleetIndexes.get(FleetEntityType.SUBCONTRACTOR) || fleetIndexes.get(FleetEntityType.TRUCK);
    case FleetEntityType.TRAILER.toLowerCase():
      return (
        fleetIndexes.get(FleetEntityType.TRAILER) ||
        fleetIndexes.get(FleetEntityType.SUBCONTRACTOR) ||
        fleetIndexes.get(FleetEntityType.TRUCK)
      );
    case FleetEntityType.DRIVER.toLowerCase():
      return (
        fleetIndexes.get(FleetEntityType.DRIVER) ||
        fleetIndexes.get(FleetEntityType.TRAILER) ||
        fleetIndexes.get(FleetEntityType.SUBCONTRACTOR) ||
        fleetIndexes.get(FleetEntityType.TRUCK)
      );
  }
};

const addOrphansToFleet = (
  type: FleetEntityType,
  orphans: FleetEntity[],
  skimmedFleet: FleetEntity[],
  configTiles: string[] = []
): FleetEntity[] => {
  const entityTypeToLastIndex = getLastIndexPerEntityType(skimmedFleet);

  const lastIndex = (getLastIndexForType(entityTypeToLastIndex, type) || 0) + 1 || skimmedFleet.length;

  if (type === FleetEntityType.TRUCK) {
    return addTruckOrphansToFleet(skimmedFleet, orphans, lastIndex, configTiles);
  } else if (type === FleetEntityType.SUBCONTRACTOR) {
    return addSubiOrphansToFleet(skimmedFleet, orphans, lastIndex);
  }

  return [...skimmedFleet.slice(0, lastIndex), ...orphans, ...skimmedFleet.slice(lastIndex, skimmedFleet.length)];
};

const applyShiftToLastOrphanIndices = (orphanIdIndices: Map<string, number>, fromIndex: number, shift = 1): void => {
  orphanIdIndices.forEach((lastIndex, orphanId) => {
    if (orphanIdIndices.get(orphanId)! >= fromIndex) {
      orphanIdIndices.set(orphanId, lastIndex + shift);
    }
  });
};

const addTruckOrphansToFleet = (
  fleet: FleetEntity[],
  truckOrphans: FleetEntity[],
  initialLastTruckIndex: number,
  configTiles: string[]
) => {
  let lastTruckIndex = initialLastTruckIndex;
  let truckGroupGlueIdIndices = getLastIndexPerTruckId(fleet);
  const extendedFleet = [...fleet];

  truckOrphans.forEach(orphan => {
    const lastIdIndex = truckGroupGlueIdIndices.get(orphan.groupGlueId!);
    if (lastIdIndex != null) {
      const orphanIndex = lastIdIndex + 1;
      applyShiftToLastOrphanIndices(truckGroupGlueIdIndices, lastIdIndex);
      extendedFleet.splice(orphanIndex, 0, orphan);
    } else {
      const orphanInfo = spreadTruckId(orphan.id);
      let truck: string | undefined = undefined;
      if (!orphanInfo.driverId) {
        truck = configTiles.find(tile => tile.startsWith(orphan.id));
      } else {
        if (orphan.groupGlueId) {
          truck = configTiles.find(tile => tile.startsWith(orphan.groupGlueId!));
        }
      }
      if (truck != null) {
        const truckIndex = configTiles.indexOf(truck);
        extendedFleet.splice(truckIndex, 0, orphan);
        truckGroupGlueIdIndices = getLastIndexPerTruckId(extendedFleet);
      } else {
        extendedFleet.splice(lastTruckIndex, 0, orphan);
      }
    }
    lastTruckIndex++;
  });
  return extendedFleet;
};

const addSubiOrphansToFleet = (
  fleet: FleetEntity[],
  subiOrphans: FleetEntity[],
  initialLastSubiIndex: number
): FleetEntity[] => {
  let lastSubiIndex = initialLastSubiIndex;
  const subiIdIndices = getLastIndexPerSubiId(fleet);
  const extendedFleet = [...fleet];

  subiOrphans.forEach(orphan => {
    const lastIdIndex = subiIdIndices.get(getNoSeqSubcontractorId(orphan.id));
    if (lastIdIndex != null) {
      const orphanIndex = lastIdIndex + 1;
      applyShiftToLastOrphanIndices(subiIdIndices, lastIdIndex);
      extendedFleet.splice(orphanIndex, 0, orphan);
    } else {
      extendedFleet.splice(lastSubiIndex, 0, orphan);
    }
    lastSubiIndex++;
  });

  return extendedFleet;
};

interface FleetEntityDraggable {
  items: FleetEntity[];
  ordinal: number;
}

@Module({ namespaced: true })
export default class Index extends VuexModule {
  fleet: FleetEntity[] = [];
  fleetLoading = false;
  fleetSpans: StringNumberMap = {};
  drivers: Driver[] = [];
  isDriverCommentToggleActive = true;
  activeRemarksDescription: FleetEntityDescription | null = null;
  trailersOptions: TrailerMeta[] = [];
  trailersSelected: TrailerMeta[] | null = null;
  fleetFiltered: FleetEntity[] = [];
  activeBoard: ConfigProfile = {};
  dirtyTruckId: string | undefined = undefined;
  dirtyDriverId: string | undefined = undefined;
  dirtySubcontractorId: string | undefined = undefined;

  get GET_TRAILER_OPTIONS() {
    return this.trailersOptions;
  }

  get GET_TRAILERS_SELECTED() {
    return this.trailersSelected;
  }

  get GET_FLEET() {
    return this.fleet;
  }

  get GET_FLEET_SPANS() {
    return this.fleetSpans;
  }

  get FLEET_LOADING() {
    return this.fleetLoading;
  }

  get FLEET_IS_EMPTY() {
    return !this.fleet.length;
  }

  get GET_FLEET_TRUCK_IDS() {
    return this.fleet.filter(fleetMember => fleetMember.type === FleetEntityType.TRUCK).map(truck => truck.id);
  }

  get GET_ACTIVE_REMARKS_DESCRIPTION() {
    return this.activeRemarksDescription;
  }

  get GET_FLEET_DRIVER_IDS(): string[] {
    return this.fleet.map(fleetMember => {
      if (fleetMember.type === FleetEntityType.DRIVER) {
        return fleetMember.id;
      }
      if ((fleetMember as Truck).crewDriver != null) {
        return (fleetMember as Truck).crewDriver!.id;
      }
      return "";
    });
  }

  get GET_FLEET_TRAILER_IDS(): string[] {
    return this.fleet.map(fleetMember => {
      if (fleetMember.type === FleetEntityType.TRAILER) {
        return fleetMember.id;
      }
      if ((fleetMember as Truck).trailer != null) {
        return (fleetMember as Truck).trailer.id;
      }
      return "";
    });
  }

  get GET_FLEET_SUBCONTRACTOR_IDS(): string[] {
    return this.fleet
      .filter(fleetMember => fleetMember.type === FleetEntityType.SUBCONTRACTOR)
      .map(subcon => subcon.id);
  }

  get GET_NOTRATED_SUBCONTRACTORS(): any[] {
    return this.fleet.filter(fleetMember => fleetMember.type === FleetEntityType.SUBCONTRACTOR);
  }

  get GET_DRIVER_BY_ID(): Function {
    return (driverId: string): Driver | undefined => {
      const fleetDriver = this.fleet
        .filter(
          entity =>
            (entity.type === FleetEntityType.TRUCK && (entity as Truck).driver) ||
            entity.type === FleetEntityType.DRIVER
        )
        .map(entity => (entity.type === FleetEntityType.TRUCK ? (entity as Truck).driver : (entity as Driver)))
        .find(driver => driver.id === driverId);
      if (!fleetDriver) {
        return this.drivers.find((driver: Driver) => driver.id === driverId);
      }
      return fleetDriver;
    };
  }
  get GET_FLEET_IDS(): OrderedFleetIds {
    return this.fleet.reduce(
      (acc, entity, index) => {
        const nestedFleetIndices = acc.nestedFleetIndices;
        if (entity.type === FleetEntityType.TRUCK) {
          const { crewDriver, trailer, groupGlueId } = entity as Truck;
          if (crewDriver != null) {
            const currentIndices = nestedFleetIndices.get(crewDriver.id) || [];
            nestedFleetIndices.set(crewDriver.id, [...currentIndices, index]);
          }
          if (trailer != null) {
            const currentIndices = nestedFleetIndices.get(trailer.id) || [];
            const trailerId = groupGlueId != null ? `${trailer.id}#${index}` : trailer.id;
            nestedFleetIndices.set(trailerId, [...currentIndices, index]);
          }
        }

        const directFleetIndices = acc.directFleetIndices;
        const currentIndices = directFleetIndices.get(entity.id) || [];
        directFleetIndices.set(entity.id, [...currentIndices, index]);

        acc = {
          directFleetIndices,
          nestedFleetIndices
        };
        return acc;
      },
      {
        directFleetIndices: new Map(),
        nestedFleetIndices: new Map()
      }
    );
  }

  get IS_DRIVER_COMMENT_TOGGLE_ACTIVE(): boolean {
    return this.isDriverCommentToggleActive;
  }

  get GET_DIRTY_TRUCK_ID() {
    return this.dirtyTruckId;
  }

  get GET_DIRTY_DRIVER_ID() {
    return this.dirtyDriverId;
  }

  get GET_DIRTY_SUBCONTRACTOR_ID() {
    return this.dirtySubcontractorId;
  }

  @Mutation
  SET_FLEET_LOADING(payload: boolean) {
    this.fleetLoading = payload;
  }
  @Mutation
  REMOVE_SELECTED_TRAILERS() {
    this.trailersSelected = [];
  }

  @Mutation
  SET_TRAILERS_SELECTED(payload) {
    this.trailersSelected = payload;
  }

  @Mutation
  SET_FLEET_TRAILERS(data: { fleet: Fleet }) {
    const { fleet } = data;

    const trailersSelected = this.trailersSelected;
    const trailers: Trailer[] =
      trailersSelected && trailersSelected.length && trailersSelected.length > 0
        ? filterTrailers(fleet.trailers, trailersSelected)
        : fleet.trailers.map(trailer => ({
            ...trailer,
            type: FleetEntityType.TRAILER,
            groupGlueId: getLastLocationName(trailer.metadata.lastLocation),
            moved: false
          }));
    if (trailersSelected && trailersSelected.length === 0 && trailers) {
      trailers.forEach(trailer =>
        addToSearchIndex(buildSearchId(trailer.id, BoardEntityType.FLEET), stringifyNestedValues(trailer))
      );
    }
    this.fleet = [
      ...this.fleet.filter(fleet => fleet.type === FleetEntityType.TRUCK),
      ...this.fleet.filter(fleet => fleet.type === FleetEntityType.SUBCONTRACTOR),
      ...trailers,
      ...this.fleet.filter(fleet => fleet.type === FleetEntityType.DRIVER)
    ];
  }

  @Mutation
  SET_TRAILERS_DROPDOWN(payload: TrailerMeta[]) {
    this.trailersOptions = payload;
  }

  @Mutation
  SET_ACTIVE_BOARD_TRAILERS(payload) {
    this.activeBoard = payload;
  }

  @Mutation
  SET_FLEET_FILTERED(payload) {
    this.fleetFiltered = payload;
  }
  @Mutation
  SET_MOVED_FLEET_ENTITIES(indices: number[]) {
    this.fleet = this.fleet.map((entity, index) => {
      if (indices.includes(index)) {
        return {
          ...entity,
          moved: true
        };
      }
      return entity;
    });
  }

  @Mutation
  CLEAR_MOVED_FLEET_ENTITIES() {
    this.fleet = this.fleet.map(entity => ({
      ...entity,
      moved: false
    }));
  }

  @Mutation
  SET_FLEET(data: { fleet: Fleet; boardIds: string[] }) {
    const { fleet, boardIds } = data;
    this.fleet = [];

    const trucks: Truck[] = fleet.trucks.flatMap(truck => {
      const { id, crewSchedule } = truck;
      const fittingCrew = crewSchedule
        .filter(schedule => schedule.driver != null)
        .sort(sortByNested("driver", "firstName"));

      if (fittingCrew.length === 0) {
        return [
          {
            ...truck,
            id: buildTruckId(id),
            type: FleetEntityType.TRUCK,
            moved: false,
            crewSchedule: []
          }
        ];
      }

      const flattenedCrew = fittingCrew.reduce((acc, schedule) => {
        acc.set(schedule.driver.id, schedule);
        return acc;
      }, new Map<string, TruckDriverSchedule>());

      return Array.from(flattenedCrew.values()).map(
        schedule =>
          ({
            ...truck,
            id: buildTruckId(id, schedule.driver.id),
            type: FleetEntityType.TRUCK,
            moved: false,
            groupGlueId: id,
            crewDriver: schedule.driver
          } as Truck)
      );
    });

    const drivers: Driver[] = fleet.drivers.map(driver => ({
      ...driver,
      type: FleetEntityType.DRIVER,
      moved: false
    }));

    const subcontractors: Subcontractor[] = fleet.subcontractors.flatMap(subi => {
      if (subi.boards.length === 0) {
        return [];
      }

      if (subi.boards.length === 1 && boardIds.includes(subi.boards[0].boardId)) {
        const { boardId, sequence, truckName, driverName } = subi.boards[0];
        return [
          {
            ...subi,
            id: buildSubcontractorId(subi.id, boardId, sequence),
            type: FleetEntityType.SUBCONTRACTOR,
            moved: false,
            flatTruckName: truckName,
            flatDriverName: driverName
          }
        ];
      }

      const boardToSequenceDetails = subi.boards.reduce((acc, board) => {
        const { boardId, sequence, truckName, driverName } = board;
        if (!boardIds.includes(boardId)) {
          return acc;
        }
        if (acc.get(boardId) == null) {
          acc.set(boardId, []);
        }
        acc.set(boardId, [
          ...acc.get(boardId)!,
          {
            sequence,
            driverName,
            truckName
          }
        ]);
        return acc;
      }, new Map<string, { sequence: number; truckName?: string | null; driverName?: string | null }[]>());

      return Array.from(boardToSequenceDetails.keys()).flatMap(boardId => {
        return boardToSequenceDetails
          .get(boardId)!
          .sort((seqDetail1, seqDetail2) => seqDetail1.sequence - seqDetail2.sequence)
          .map(sequenceDetails => {
            const { sequence, truckName, driverName } = sequenceDetails;
            const subiName = boardToSequenceDetails.get(boardId)!.length > 1 ? `${subi.name} ${sequence}` : subi.name;
            const subcontractor: Subcontractor = {
              ...subi,
              id: buildSubcontractorId(subi.id, boardId, sequence),
              name: subiName,
              type: FleetEntityType.SUBCONTRACTOR,
              moved: false,
              groupGlueId: `${subi.id}#${boardId}`,
              flatTruckName: truckName,
              flatDriverName: driverName
            };

            return boardToSequenceDetails.size > 1
              ? {
                  ...subcontractor,
                  primaryBoardId: boardId
                }
              : subcontractor;
          });
      });
    });

    const trailers: Trailer[] = fleet.trailers.map(trailer => ({
      ...trailer,
      id: buildTrailerId(trailer.id, getLastLocationName(trailer.metadata?.lastLocation)),
      type: FleetEntityType.TRAILER,
      groupGlueId: getLastLocationName(trailer.metadata.lastLocation),
      moved: false
    }));

    this.fleet = [...trucks, ...subcontractors, ...trailers, ...drivers];
    this.fleet.forEach(entity => {
      addToSearchIndex(buildSearchId(entity.id, BoardEntityType.FLEET), stringifyNestedValues(entity));
    });
  }

  @Mutation
  SET_FLEET_SPANS(fleetSpans: any) {
    this.fleetSpans = this.fleet.reduce((acc, entity, id) => {
      acc[entity.id] = fleetSpans[id] || 100;
      return acc;
    }, {} as any);
  }

  @Mutation
  UPDATE_FLEET(payload: FleetEntity[]) {
    this.fleet = payload;
  }

  @Mutation
  CLEAR_FLEET() {
    this.fleet = [];
  }

  @Mutation
  SET_ACTIVE_REMARKS_DESCRIPTION(description: FleetEntityDescription | null) {
    this.activeRemarksDescription = description;
  }

  @Mutation
  SET_FLEET_ACTIONS(payload: { actions: FleetAction[]; orderedFleetIds: OrderedFleetIds }) {
    const { actions, orderedFleetIds } = payload;
    const { directFleetIndices } = orderedFleetIds;

    const fleetEntitiesActions = actions.reduce((acc, action) => {
      const { truckId, trailerId, driverId, subcontractor } = action;
      const subcontractorId = subcontractor && getBrickSubcontractorId(subcontractor, directFleetIndices);
      const actionEntityId = truckId || trailerId || driverId || subcontractorId;
      if (actionEntityId == null) {
        return acc;
      }
      if (acc[actionEntityId] == null) {
        acc[actionEntityId] = [];
      }
      acc[actionEntityId] = [...acc[actionEntityId], action];

      return acc;
    }, {} as any);

    this.fleet = this.fleet.map(item => {
      if (item.type === FleetEntityType.TRUCK) {
        const { id: truckId, crewDriver, trailer: truckTrailer } = item as Truck;
        const truckActions = fleetEntitiesActions[spreadTruckId(truckId).id] || [];
        const driverActions = ((crewDriver && fleetEntitiesActions[crewDriver.id]) || []).map(action => ({
          ...action,
          firstName: crewDriver?.firstName,
          lastName: crewDriver?.lastName
        }));
        const trailerActions = (truckTrailer && fleetEntitiesActions[truckTrailer.id]) || [];

        return {
          ...item,
          actions: [...truckActions, ...driverActions, ...trailerActions]
        };
      }
      if (item.type === FleetEntityType.DRIVER) {
        const driver = item as Driver;
        const driverActions = (fleetEntitiesActions[driver.id] || []).map(action => ({
          ...action,
          firstName: driver.firstName,
          lastName: driver.lastName
        }));

        return {
          ...item,
          actions: driverActions
        };
      }
      if (item.type === FleetEntityType.SUBCONTRACTOR) {
        return {
          ...item,
          actions: fleetEntitiesActions[item.id] || []
        };
      }
      if (item.type === FleetEntityType.TRAILER) {
        return {
          ...item,
          actions: fleetEntitiesActions[spreadTrailerId(item.id).id] || []
        };
      }
      return item;
    });
  }

  @Mutation
  SET_FLEET_PURE(fleet: FleetEntity[]) {
    this.fleet = fleet;
  }

  @Mutation
  SET_DRIVER_COMMENT_TOGGLE(payload: boolean) {
    this.isDriverCommentToggleActive = payload;
  }

  @Mutation
  SET_DIRTY_TRUCK(truckId: string) {
    this.dirtyTruckId = truckId;
  }

  @Mutation
  UNSET_DIRTY_TRUCK() {
    this.dirtyTruckId = undefined;
  }

  @Mutation
  SET_DIRTY_DRIVER(driverId: string) {
    this.dirtyDriverId = driverId;
  }

  @Mutation
  UNSET_DIRTY_DRIVER() {
    this.dirtyDriverId = undefined;
  }

  @Mutation
  SET_DIRTY_SUBCONTRACTOR(subcontractorId: string) {
    this.dirtySubcontractorId = subcontractorId;
  }

  @Mutation
  UNSET_DIRTY_SUBCONTRACTOR() {
    this.dirtySubcontractorId = undefined;
  }

  @Action
  UPDATE_ACTIVE_REMARKS_DESCRIPTION(description: FleetEntityDescription | null) {
    const { commit } = this.context;
    commit(SET_ACTIVE_REMARKS_DESCRIPTION_LOCAL, description);
  }

  @Action
  UPDATE_TRAILERS_VIA_LEG(legs: Leg[]) {
    const { dispatch, rootGetters } = this.context;
    const boardLegs = rootGetters[GET_LEGS] as BoardLeg[];

    legs.forEach(leg => {
      const outdatedLeg = boardLegs.find(boardLeg => boardLeg.id === leg.id);
      if (outdatedLeg?.details?.dropWithTrailer !== leg.details?.dropWithTrailer) {
        this.GET_FLEET.filter(fleet => fleet.type === FleetEntityType.TRAILER).forEach(fleetTrailer => {
          const trailer = fleetTrailer as Trailer;
          const legId = trailer.metadata.lastLocation?.leg?.id;
          if (legId === leg.id) {
            trailer.id = spreadTrailerId(trailer.id).id;
            trailer.metadata.lastLocation = { leg };

            dispatch(UPDATE_TRAILER_LOCAL, trailer);
          }
        });
      }
    });
  }

  @Action
  SET_TRAILERS_SEARCH_KEYS(trailerCategories) {
    const { commit, dispatch } = this.context;

    const trailerList = Object.keys(trailerCategories).map(key => {
      const k = { id: key, name: key };
      return k;
    });

    commit(SET_TRAILERS_DROPDOWN_LOCAL, trailerList);
    dispatch(INIT_TRAILERS_DROPDOWN_LOCAL);
  }

  @Action
  async FETCH_BOARD_FLEET_TRAILERS() {
    const { commit, dispatch } = this.context;

    const fleet = this.fleetFiltered;

    commit(SET_FLEET_TRAILERS_LOCAL, { fleet });

    await dispatch(FETCH_ACTIONS_ON_TRAILERS, {}, { root: true });

    await dispatch(UPDATE_FLEET_OCCUPANCY_LOCAL);
  }

  @Action
  INIT_TRAILERS_DROPDOWN() {
    const { commit } = this.context;

    const board = this.activeBoard;
    const tempTrailer: TrailerMeta[] = [];
    if (this.trailersOptions) {
      this.trailersOptions.forEach(trailerCategory => {
        if (board && board.trailerTypes?.includes(trailerCategory.id)) {
          tempTrailer.push(trailerCategory);
        }
      });

      commit(SET_TRAILERS_SELECTED_LOCAL, tempTrailer);
    }
  }

  @Action
  INIT_TRAILER_PROFILE(payload) {
    const { dispatch, commit } = this.context;
    const activeBoard = payload.boardProfiles.find(profile => profile.boardId === payload.activeBoardId);
    commit(SET_ACTIVE_BOARD_TRAILERS_LOCAL, activeBoard);
    dispatch(INIT_TRAILERS_DROPDOWN_LOCAL, activeBoard);
  }

  @Action
  async UPDATE_FLEET_AFFILIATION(request: AffiliationRequest) {
    const { id: truckId, trailerId, driverId } = request;
    const truck = this.fleet.find(item => item.id === truckId) as Truck;
    const oldTrailerId = truck?.trailer?.id;
    const oldDriverId = truck?.driver?.id;
    const fleetIds: string[] = this.fleet.map(item => item.id);

    //resort fleet only when we detach trailer or driver from truck
    if (oldDriverId != null && driverId == null) {
      const lastIndex: number =
        findLastIndex(this.fleet, item => item.type === FleetEntityType.DRIVER) + 1 || this.fleet.length;
      fleetIds.splice(lastIndex, 0, oldDriverId);
    }
    if (oldTrailerId != null && trailerId == null) {
      const lastIndex: number =
        findLastIndex(this.fleet, item => item.type === FleetEntityType.TRAILER) + 1 || this.fleet.length;
      fleetIds.splice(lastIndex, 0, oldTrailerId);
    }
    if (fleetIds.length !== this.fleet.length) {
      await this.context.dispatch(UPDATE_FLEET_ORDER_LOCAL, fleetIds);
    }

    try {
      await fleetService.updateTruck(request);
      await this.context.dispatch(FETCH_BOARD_FLEET_LOCAL);
    } catch (e) {
      //throws error here
      throw e;
    }
  }

  @Action
  async SORT_FLEET(boardId: string) {
    const { commit } = this.context;
    const boardPayload = {
      boardId: boardId
    };
    try {
      const { data: boardConfig } = await configProfileService.getBoardConfig(boardPayload);
      const sidebarTiles = boardConfig.sidebarTiles || [];
      const orphans = this.fleet.reduce(
        (acc, fleetEntity) => {
          if (!sidebarTiles.includes(fleetEntity.id)) {
            acc[fleetEntity.type.toLowerCase()] = [...acc[fleetEntity.type.toLowerCase()], fleetEntity];
          }
          return acc;
        },
        {
          truck: [],
          trailer: [],
          subcontractor: [],
          driver: []
        }
      );
      this.fleet.sort((entity1, entity2) => sidebarTiles.indexOf(entity1.id) - sidebarTiles.indexOf(entity2.id));
      const { truck, trailer, subcontractor, driver } = orphans;
      const truckIds = (truck as FleetEntity[]).map(orphan => orphan.id);
      const trailerIds = (trailer as FleetEntity[]).map(orphan => orphan.id);
      const subcontractorIds = (subcontractor as FleetEntity[]).map(orphan => orphan.id);
      const driverIds = (driver as FleetEntity[]).map(orphan => orphan.id);
      let skimmedFleet = this.fleet.filter(fleetEntity => !truckIds.includes(fleetEntity.id));
      skimmedFleet = skimmedFleet.filter(fleetEntity => !trailerIds.includes(fleetEntity.id));
      skimmedFleet = skimmedFleet.filter(fleetEntity => !subcontractorIds.includes(fleetEntity.id));
      skimmedFleet = skimmedFleet.filter(fleetEntity => !driverIds.includes(fleetEntity.id));

      if (truck.length) {
        commit(SET_FLEET_PURE_LOCAL, addOrphansToFleet(FleetEntityType.TRUCK, truck, skimmedFleet, sidebarTiles));
        skimmedFleet = this.fleet;
      }
      if (trailer.length) {
        commit(SET_FLEET_PURE_LOCAL, addOrphansToFleet(FleetEntityType.TRAILER, trailer, skimmedFleet));
        skimmedFleet = this.fleet;
      }
      if (subcontractor.length) {
        commit(SET_FLEET_PURE_LOCAL, addOrphansToFleet(FleetEntityType.SUBCONTRACTOR, subcontractor, skimmedFleet));
        skimmedFleet = this.fleet;
      }
      if (driver.length) {
        commit(SET_FLEET_PURE_LOCAL, addOrphansToFleet(FleetEntityType.DRIVER, driver, skimmedFleet));
      }
    } catch (e) {
      //we hide all 404 message
    }
  }

  @Action
  async GET_EXTENDED_FLEET_ACTIONS() {
    const { commit, dispatch, rootState } = this.context;
    const boardIds: string[] = rootState.board.boardsSelected.map(board => board.id);
    const truckIds = this.GET_FLEET_TRUCK_IDS.map(truckId => spreadTruckId(truckId).id);
    const trailerIds = this.GET_FLEET_TRAILER_IDS.map(trailerId => spreadTrailerId(trailerId).id);
    const driverIds = this.GET_FLEET_DRIVER_IDS;
    const subcontractorIds = this.GET_FLEET_SUBCONTRACTOR_IDS.map(compositeId => spreadSubcontractorId(compositeId).id);
    const filter: FleetActionIdFilter = {
      boardIds,
      truckIds,
      trailerIds,
      driverIds,
      subcontractorIds
    };
    try {
      const actions = await dispatch(FETCH_FLEET_ACTIONS, filter, { root: true });
      commit(SET_FLEET_ACTIONS_LOCAL, {
        actions,
        orderedFleetIds: this.GET_FLEET_IDS
      });
    } catch (e) {
      //handle not global errors
    }
  }

  @Action
  REPOPULATE_EXTENDED_FLEET_ACTIONS(actions: FleetAction[]) {
    this.context.commit(SET_FLEET_ACTIONS_LOCAL, {
      actions,
      orderedFleetIds: this.GET_FLEET_IDS
    });
  }

  @Action
  async FETCH_BOARD_FLEET(boardIds: string[]) {
    const { rootState, commit, dispatch } = this.context;
    const boards: string[] = boardIds || rootState.board.boardsSelected.map(board => board.id);
    const from = toIsoWithNoOffset(startOfDay(rootState.board.fromDate));
    const to = toIsoWithNoOffset(endOfDay(rootState.board.toDate));

    commit(SET_FLEET_LOADING_LOCAL, true);

    const payload: FleetPayload = {
      boardIds: boards,
      from,
      to
    };
    try {
      const { data: fleet } = await fleetService.getFleets(payload);

      commit(SET_FLEET_FILTERED_LOCAL, fleet);
      if (fleet.trailers) {
        dispatch(SET_TRAILERS_SEARCH_KEYS_LOCAL, groupBy(fleet.trailers, "chassisType"));
      }

      commit(SET_FLEET_LOCAL, { fleet, boardIds: boards });

      await Promise.all([
        dispatch(SORT_FLEET_LOCAL, this.context.rootGetters[GET_BOARD_ID]),
        dispatch(FETCH_LEGS, boardIds, { root: true }),
        dispatch(GET_EXTENDED_FLEET_ACTIONS_LOCAL),
        dispatch(FETCH_CHATS, boardIds, { root: true })
      ]);

      const boardLegs = this.context.rootGetters[GET_INITIAL_LEGS];
      const boardActions = this.context.rootGetters[GET_INITIAL_ACTIONS];
      const orderedFleetIds = this.GET_FLEET_IDS;

      const { legs, actions, fleetSpans } = deriveBoardFlexibles([...boardLegs, ...boardActions], orderedFleetIds);

      commit(SET_FLEET_SPANS, fleetSpans, {
        root: true
      });
      commit(SET_LEGS, legs, { root: true });
      dispatch(INDEX_LEGS, legs, { root: true });
      commit(SET_BOARD_ACTIONS, actions, { root: true });
      dispatch(INDEX_BOARD_ACTIONS, actions, { root: true });

      await dispatch(UPDATE_FLEET_OCCUPANCY_LOCAL);
      if (this.trailersSelected && this.trailersSelected.length > 0) {
        await dispatch(FETCH_BOARD_FLEET_TRAILERS_LOCAL);
      }
      this.context.commit(SET_FLEET_LOADING_LOCAL, false);
    } catch (e) {
      this.context.commit(SET_FLEET_LOADING_LOCAL, false);
    }
  }

  @Action
  UPDATE_FLEET_OCCUPANCY() {
    const { commit, rootGetters } = this.context;
    const boardDetails: BoardDetails = rootGetters[GET_BOARD_DETAILS];
    const includeWeekends: boolean = rootGetters[GET_SHOW_WEEKENDS];
    const boardIds = rootGetters[GET_SELECTED_BOARD_IDS];

    const fleet = this.fleet.map(entity => {
      if (entity.type === FleetEntityType.SUBCONTRACTOR) {
        return entity;
      }

      let driverShifts: FleetSchedule[] = [];

      if (entity.type === FleetEntityType.TRUCK) {
        const { crewSchedule: truckCrewSchedule, crewDriver } = entity as Truck;
        driverShifts = truckCrewSchedule.filter(
          schedule => schedule.driver != null && schedule.driver.id === crewDriver?.id
        );
      }

      const { blocks: occupancyBlocks, byOtherBoard: isOccupied } = deriveBoardOccupancy(
        boardIds,
        boardDetails,
        includeWeekends,
        entity.boards,
        driverShifts
      );

      return {
        ...entity,
        isOccupied,
        occupancyBlocks
      };
    });

    commit(SET_FLEET_PURE_LOCAL, fleet);
  }

  @Action
  async UPDATE_FLEET_ORDER(orderedSidebarTiles?: string[]): Promise<void> {
    try {
      orderedSidebarTiles = orderedSidebarTiles || this.fleet.map(item => item.id);
      await this.context.dispatch(PERSIST_BOARD_CONFIG, orderedSidebarTiles, { root: true });
      await this.context.dispatch(SORT_LEGS, {}, { root: true });
      translateAndToastSuccess("Board saved");
    } catch (e) {
      //we hide errors which are not handled globally
    }
  }

  @Action
  GET_SUBCONTRACTOR_BY_ID(subcontractorId: string): Subcontractor | undefined {
    const subcontractor = this.fleet
      .filter(entity => entity.type === FleetEntityType.SUBCONTRACTOR)
      .find(subi => {
        const { id } = spreadSubcontractorId(subi.id);
        return id === subcontractorId.toString();
      });
    return subcontractor as Subcontractor;
  }

  @Action
  GET_TRAILER_BY_ID(trailerId: string) {
    return this.fleet
      .filter(entity => entity.type === FleetEntityType.TRUCK)
      .map(truck => (truck as Truck).trailer)
      .find(trailer => {
        if (typeof trailer !== "undefined") {
          return trailer.id === spreadTrailerId(trailerId).id;
        }
      });
  }

  @Action
  async FETCH_DRIVER(driverId: string) {
    const { commit } = this.context;
    try {
      const { data } = await fleetService.getDriverById(driverId);
      commit(SET_DRIVERS_LOCAL, data);
    } catch (e) {
      //show errors which are not handled globally
    }
  }

  @Mutation
  SET_DRIVERS(payload: Driver) {
    this.drivers = [...this.drivers, payload];
  }

  @Action
  async LOCK_DRIVER(driverId: string) {
    try {
      await fleetService.lockDriver(driverId);
      this.context.commit(SET_DIRTY_DRIVER_LOCAL, driverId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UNLOCK_DRIVER(driverId: string) {
    try {
      await fleetService.unlockDriver(driverId);
      this.context.commit(UNSET_DIRTY_DRIVER_LOCAL, driverId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UPDATE_DRIVER(driver: DriverUpdate) {
    const { rootGetters } = this.context;
    const currentLocale = rootGetters[GET_LANGUAGE];
    try {
      fleetService.updateDriver(driver.id, driver);
    } catch (e) {
      toastAxiosError(e as AxiosError<ErrorResponse>, currentLocale);
      throw e;
    }
  }

  @Action
  ACTIVATE_DRIVER_COMMENT_TOGGLE() {
    this.context.commit(SET_DRIVER_COMMENT_TOGGLE_LOCAL, true);
  }

  @Action
  DEACTIVATE_DRIVER_COMMENT_TOGGLE() {
    this.context.commit(SET_DRIVER_COMMENT_TOGGLE_LOCAL, false);
  }

  @Action
  async GET_FLEET_ENTITY_DESCRIPTION(fleetIdentifier: FleetIdentifier): Promise<FleetEntityDescription> {
    const { dispatch } = this.context;
    const { id, type } = fleetIdentifier;
    if (type === FleetEntityType.SUBCONTRACTOR) {
      const subi = await dispatch(GET_SUBCONTRACTOR_BY_ID_LOCAL, id);
      return { entity: type, name: subi.name };
    }
    if (type === FleetEntityType.DRIVER) {
      const driver = this.GET_DRIVER_BY_ID(id);
      return { entity: FleetEntityType.DRIVER, name: `${driver.firstName} ${driver.lastName}` };
    }
    return { entity: type, name: id };
  }

  @Action
  async TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS() {
    const { dispatch, rootGetters } = this.context;
    const dirtyLegId = rootGetters[GET_DIRTY_LEG_ID];
    const dirtyLeg = await dispatch(GET_LEG_BY_ID, dirtyLegId, { root: true });
    const fleetIdentifier = getBrickFleetIdentifier(dirtyLeg);
    await dispatch(COMPILE_AND_TOAST_UNSAVED_ACTION_LOCAL, { fleetIdentifier, unsavedActionItem: "Legs" });
  }

  @Action
  async TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION() {
    const { dispatch, rootGetters } = this.context;
    const dirtyActionId = rootGetters[GET_DIRTY_FLEET_ACTION_ID];
    const dirtyAction = await dispatch(GET_FLEET_ACTION_BY_ID, dirtyActionId, { root: true });
    const fleetIdentifier = getBrickFleetIdentifier(dirtyAction);
    await dispatch(COMPILE_AND_TOAST_UNSAVED_ACTION_LOCAL, {
      fleetIdentifier,
      unsavedActionItem: "Action or Reminder"
    });
  }

  @Action
  async COMPILE_AND_TOAST_UNSAVED_ACTION(content: { fleetIdentifier: FleetIdentifier; unsavedActionItem: string }) {
    const { dispatch } = this.context;
    const { fleetIdentifier, unsavedActionItem } = content;
    const description = await dispatch(GET_FLEET_ENTITY_DESCRIPTION_LOCAL, fleetIdentifier);
    const translationKey = `fleet.${description?.entity}`;
    translateAndToastWarn("errors.UNSAVED_CHANGES", {
      dirtyItem: unsavedActionItem,
      entity: `${i18n.t(translationKey)} ${description?.name}`
    });
  }

  @Action
  REMOVE_TRAILER(trailerId: string) {
    const { commit } = this.context;
    const localFleet = [...this.fleet];
    const index = this.fleet.findIndex(entity => {
      if (entity.type === FleetEntityType.TRAILER) {
        const id = spreadTrailerId(entity.id).id;
        return id === trailerId;
      }
    });
    if (index > -1) {
      localFleet.splice(index, 1);
      commit(UPDATE_FLEET_LOCAL, localFleet);
    }
  }

  @Action
  UPDATE_TRAILER(trailer: Trailer) {
    const { commit, rootGetters } = this.context;
    const localFleet = [...this.fleet];
    const index = this.fleet.findIndex(entity => {
      if (entity.type === FleetEntityType.TRAILER) {
        const id = spreadTrailerId(entity.id).id;
        return id === trailer.id;
      }
    });

    if (index > -1) {
      const targetTrailer = this.fleet[index];
      localFleet.splice(index, 1, {
        ...targetTrailer,
        ...trailer,
        id: buildTrailerId(trailer.id, getLastLocationName(trailer?.metadata.lastLocation)),
        groupGlueId: getLastLocationName(trailer.metadata.lastLocation)
      });
      commit(UPDATE_FLEET_LOCAL, localFleet);
      const boardLegs = rootGetters[GET_LEGS];
      const boardActions = rootGetters[GET_BOARD_ACTIONS];
      const fleetIds = this.GET_FLEET_IDS;
      const { fleetSpans } = deriveBoardFlexibles([...boardLegs, ...boardActions], fleetIds);
      commit(SET_FLEET_SPANS_LOCAL, fleetSpans);
    }
  }

  @Action
  async LOCK_TRUCK(truckId: string) {
    try {
      await fleetService.lockTruck(truckId);
      this.context.commit(SET_DIRTY_TRUCK_LOCAL, truckId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UNLOCK_TRUCK(truckId: string) {
    try {
      await fleetService.unlockTruck(truckId);
      this.context.commit(UNSET_DIRTY_TRUCK_LOCAL, truckId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async LOCK_SUBCONTRACTOR(subcontractorId: string) {
    try {
      await fleetService.lockSubcontractor(subcontractorId);
      this.context.commit(SET_DIRTY_SUBCONTRACTOR_LOCAL, subcontractorId);
    } catch (e) {
      throw e;
    }
  }

  @Action
  async UNLOCK_SUBCONTRACTOR(subcontractorId: string) {
    try {
      await fleetService.unlockSubcontractor(subcontractorId);
      this.context.commit(UNSET_DIRTY_SUBCONTRACTOR_LOCAL, subcontractorId);
    } catch (e) {
      throw e;
    }
  }
}

export {
  FLEET_STORE,
  FETCH_BOARD_FLEET,
  GET_FLEET,
  CLEAR_FLEET,
  FLEET_IS_EMPTY,
  SET_FLEET_LOADING,
  FLEET_LOADING,
  UPDATE_FLEET_ORDER,
  UPDATE_FLEET,
  SORT_FLEET,
  GET_FLEET_TRUCK_IDS,
  GET_FLEET_IDS,
  IS_DRIVER_COMMENT_TOGGLE_ACTIVE,
  SET_MOVED_FLEET_ENTITIES,
  CLEAR_MOVED_FLEET_ENTITIES,
  GET_TRAILER_BY_ID,
  SET_FLEET_SPANS,
  GET_FLEET_SPANS,
  GET_NOTRATED_SUBCONTRACTORS,
  UPDATE_FLEET_AFFILIATION,
  REPOPULATE_EXTENDED_FLEET_ACTIONS,
  GET_DRIVER_BY_ID,
  FETCH_DRIVER,
  LOCK_DRIVER,
  UNLOCK_DRIVER,
  UPDATE_DRIVER,
  ACTIVATE_DRIVER_COMMENT_TOGGLE,
  DEACTIVATE_DRIVER_COMMENT_TOGGLE,
  GET_SUBCONTRACTOR_BY_ID,
  GET_ACTIVE_REMARKS_DESCRIPTION,
  UPDATE_ACTIVE_REMARKS_DESCRIPTION,
  GET_FLEET_ENTITY_DESCRIPTION,
  TOAST_FLEET_ENTITY_HAS_UNSAVED_LEGS,
  TOAST_FLEET_ENTITY_HAS_UNSAVED_ACTION,
  UPDATE_FLEET_OCCUPANCY,
  GET_TRAILER_OPTIONS,
  GET_TRAILERS_SELECTED,
  INIT_TRAILERS_DROPDOWN,
  SET_TRAILERS_SELECTED,
  FETCH_BOARD_FLEET_TRAILERS,
  INIT_TRAILER_PROFILE,
  REMOVE_SELECTED_TRAILERS,
  UPDATE_TRAILERS_VIA_LEG,
  // UPDATE_TRAILERS_VIA_FLEET,
  UPDATE_TRAILER,
  REMOVE_TRAILER,
  LOCK_TRUCK,
  UNLOCK_TRUCK,
  LOCK_SUBCONTRACTOR,
  UNLOCK_SUBCONTRACTOR,
  GET_DIRTY_TRUCK_ID,
  GET_DIRTY_DRIVER_ID,
  GET_DIRTY_SUBCONTRACTOR_ID
};
