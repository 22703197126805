<template>
  <div class="flex-1 ml-84">
    <div
      id="board"
      class="boardTimeLine w-full"
      ref="timelineBoard"
      :style="{
        'background-image': backgroundImageUrl
      }"
    >
      <transition name="fade">
        <div v-show="boardReady" class="relative" :class="bgClass">
          <BoardTimeLineHeader />
          <div
            class="h-auto boardTimeLine__body"
            @drop="handleDrop"
            @dragover="allowDrop"
            @dragenter="allowDrop"
            @contextmenu.prevent="openContextMenu"
          >
            <div class="mt-28">
              <Leg v-for="leg in legs" :key="leg.id" :leg-data="leg" @showSplitScreen="showSplitScreen" />
              <ActionBrick
                v-for="(action, actionIndex) in actions"
                :key="action.isDuplicate ? `${actionIndex}-${action.id}` : action.id"
                :action-data="action"
                @showSplitScreen="showSplitScreen"
              />
              <div v-if="!fleet.length">
                <div v-for="index in 20" :key="index" class="h-28">
                  <BoardTimeBoxes />
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(entry, index) in fleet"
                  class="relative"
                  :key="`${entry.id}-${index}`"
                  :style="{
                    height: `${fleetSpans[entry.id] || 100}px`
                  }"
                >
                  <span v-if="entry.occupancyBlocks && entry.occupancyBlocks.length > 0" class="h-full w-full absolute">
                    <span
                      v-for="(block, index) in entry.occupancyBlocks"
                      class="occupied absolute h-full"
                      :style="{ left: `${block.startXCoord}px`, width: `${block.width}px` }"
                      :key="index"
                    />
                  </span>
                  <BoardTimeBoxes />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <ContextMenu :mouse-event="ctxEvent">
      <template #content="{ closeContextMenu, mouseEvent }">
        <TimelineContextOptions
          :closeContextMenu="closeContextMenu"
          :boardReference="timelineBoard"
          :mouseEvent="mouseEvent"
        />
      </template>
    </ContextMenu>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, provide, ref, watch } from "@vue/composition-api";
import store from "@/store";
import Leg from "@/components/Board/Leg/Leg.vue";
import ActionBrick from "@/components/Board/Action/ActionBrick.vue";
import BoardTimeBoxes from "@/components/Board/TimeLine/TimeLineBoxes/TimeLineBoxes.vue";
import BoardTimeLineHeader from "@/components/Board/TimeLine/TimeLineHeader/TimeLineHeader.vue";
import { GET_HOUR_FROM_TO_TIME, INIT_USER_CONFIG_PROFILE, IS_BOARD_EDIT_MODE_ACTIVE, SET_BOARD } from "@/store/board/";
import { GET_FLEET, GET_FLEET_SPANS, UPDATE_FLEET_OCCUPANCY } from "@/store/fleet";
import { toLocalDateTimeHours } from "@/use/useDate";
import useWebSocket from "@/components/Board/TimeLine/useWebSocket";
import LegDetail from "@/components/Board/Leg/LegDetail/LegDetail.vue";
import FleetDetail from "@/components/Board/Fleet/FleetDetail/FleetDetail.vue";

import useScrollPosition from "@/use/useScrollPosition";
import { ADJUST_BRICK_SIZES, FETCH_DROPPED_LEG, SET_LEG_TO_SELECTED } from "@/store/brick";
import { LegDropInfo } from "@/types/leg";
import { debounce } from "@/use/useTimeout";
import { translateAndToastError, translateAndToastInfo } from "@/use/useToast";
import { FleetEntityType } from "@/types/fleet";
import { dayHourSettings } from "@/types/board";
import useBoardBrickCalculus from "@/components/Board/Use/useBoardBrickCalculus";

import ContextMenu from "@/components/Board/ContextMenu/ContextMenu.vue";
import TimelineContextOptions from "@/components/Board/ContextMenu/TimelineContextOptions/TimelineContextOptions.vue";

export default defineComponent({
  name: "TimeLine",
  components: {
    ContextMenu,
    Leg,
    ActionBrick,
    BoardTimeBoxes,
    BoardTimeLineHeader,
    LegDetail,
    FleetDetail,
    TimelineContextOptions
  },
  setup() {
    const showSplitScreen = inject("showSplitScreen");

    //get all the data we need
    const fleet = computed(() => store.getters[GET_FLEET]);
    const fleetSpans = computed(() => store.getters[GET_FLEET_SPANS]);
    const legs = computed(() => store.state.brick.legs);
    const actions = computed(() => store.state.brick.boardActions);
    const fromDate = computed(() => store.state.board.fromDate);
    const toDate = computed(() => store.state.board.toDate);
    const time = computed(() => store.getters[GET_HOUR_FROM_TO_TIME]);

    const { getBrickFleetEntity, getStartCoordinateTimestamp, getBrickFleetEntityDetails } = useBoardBrickCalculus();

    const { scrolledTop, scrolledLeft } = useScrollPosition();

    const timelineBoard = ref();
    const { initWebSocket } = useWebSocket();

    const initBoard = () => {
      store.commit(SET_BOARD);
      store.dispatch(INIT_USER_CONFIG_PROFILE);
    };

    const allowDrop = (event: DragEvent): void => {
      const { dataTransfer } = event;
      if (dataTransfer?.types.includes("text") || dataTransfer?.types.includes("text/plain")) {
        event.preventDefault();
      }
    };

    const handleDrop = async (event: DragEvent): Promise<void> => {
      event.preventDefault();
      const { dataTransfer } = event;

      const legId = dataTransfer?.getData("text") || dataTransfer?.getData("text/plain");

      //checks if dropped leg is already on board and sets it to dirty state
      if (legs.value.find(leg => leg.id === legId)) {
        await store.commit(SET_LEG_TO_SELECTED, legId);
        translateAndToastInfo("errors.LEG_EXISTS_ON_BOARD", { id: legId });
        return;
      }

      const { value: boardReference } = timelineBoard;
      const boardOffsetX = boardReference.offsetLeft - scrolledLeft.value;
      const boardOffsetY = boardReference.offsetTop - scrolledTop.value;
      const dropFleetEntity = getBrickFleetEntity(event.clientY - boardOffsetY - 100);

      if (dropFleetEntity?.type === FleetEntityType.TRAILER || dropFleetEntity?.type === FleetEntityType.DRIVER) {
        translateAndToastError("errors.LEG_DROP_NOT_ALLOWED");
        return;
      }
      if (typeof dropFleetEntity === "undefined") {
        return;
      }

      const { truckId, trailerId, driverId, subcontractor } = getBrickFleetEntityDetails(dropFleetEntity);

      const dropTimestamp = getStartCoordinateTimestamp(event.clientX - boardOffsetX);

      await store.dispatch(FETCH_DROPPED_LEG, {
        legId,
        dropTimestamp,
        dropTruckId: truckId,
        dropTrailerId: trailerId,
        dropDriverId: driverId,
        dropSubcontractor: subcontractor
      } as LegDropInfo);
    };

    //check if board is ready for init
    const boardReady = computed(() => store.state.board.board.boardReady);

    watch([fromDate, toDate, time], () => {
      const { fromTime, toTime } = time.value;

      if (fromTime != null && toTime != null) {
        const rangeFrom = toLocalDateTimeHours(fromDate.value, fromTime);
        const rangeTo =
          toTime === dayHourSettings.DAY_END
            ? toLocalDateTimeHours(toDate.value, 23, 59)
            : toLocalDateTimeHours(toDate.value, toTime);
        initWebSocket(rangeFrom, rangeTo);
      }
    });

    const backgroundImageUrl = computed(() => {
      return `url(${require("@/assets/images/background.webp")})`;
    });

    const debounceBrickSizeAdjustment = debounce(async function() {
      await store.dispatch(ADJUST_BRICK_SIZES);
      await store.dispatch(UPDATE_FLEET_OCCUPANCY);
    }, 300);

    onMounted(() => {
      initBoard();
      window.addEventListener("resize", function() {
        store.commit(SET_BOARD);
        debounceBrickSizeAdjustment();
      });
    });

    const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

    const bgClass = computed<string>(() => (isBoardEditModeActive.value ? "bg-yellow-50 bg-opacity-80" : ""));

    const ctxEvent = ref(null);

    const openContextMenu = e => {
      ctxEvent.value = e;
    };

    const selectedAction = ref(null);

    const getSelectedAction = () => {
      return selectedAction.value;
    };

    const setSelectedAction = action => {
      selectedAction.value = action;
    };

    provide("getSelectedAction", getSelectedAction);
    provide("setSelectedAction", setSelectedAction);

    return {
      ctxEvent,
      openContextMenu,
      handleDrop,
      allowDrop,
      fleetSpans,
      timelineBoard,
      boardReady,
      fleet,
      legs,
      actions,
      backgroundImageUrl,
      showSplitScreen,
      isBoardEditModeActive,
      bgClass,
      selectedAction
    };
  }
});
</script>

<style scoped lang="scss">
.occupied {
  @apply bg-gray-400 bg-opacity-50;
  z-index: 2;
  pointer-events: none;
}
</style>
