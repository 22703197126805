import { $axios } from "@/config/axios";
import { AxiosResponse } from "axios";
import { Container } from "@/types/fleet";
const baseSlug = "/v1/";

export default {
  async getSingleContainer(containerId: string): Promise<AxiosResponse<Container>> {
    return await $axios.get(`${baseSlug}containers/${containerId}`);
  }
};
