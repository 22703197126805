
















import { BoardLeg } from "@/types/leg";
import { defineComponent, PropType } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "TTALink",
  components: { Icon },
  props: {
    legData: {
      type: Object as PropType<BoardLeg>,
      required: true
    }
  }
});
