import { $axios } from "@/config/axios";
import { AxiosResponse } from "axios";
import { Board } from "@/types/board";

const baseSlug = "/v1/boards";

interface Boards {
  boards: Array<Board>;
}

export default {
  async getBoards(): Promise<AxiosResponse<Boards>> {
    return await $axios.get(`${baseSlug}`);
  }
};
