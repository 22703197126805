interface IdEntity<T> {
  id: T;
}

interface DateTimezone {
  date: string;
  timezone?: string;
}

interface Location extends IdEntity<string> {
  code: string;
  name: string;
  timezone: string;
}

interface ErrorResponseMessage {
  en?: string;
  de?: string;
}

interface ErrorResponseDetails {
  severity: string;
  message: ErrorResponseMessage;
}

interface ErrorResponse {
  errorCode: string;
  message: string;
  details?: ErrorResponseDetails[];
}

export { IdEntity, DateTimezone, Location, ErrorResponse, ErrorResponseDetails, ErrorResponseMessage };
