import axios from "axios";
import { API_BASE_URL, APP_AUTH, TP_COOKIE_NAME } from "@/env_config";
import { getCookie } from "@/use/useCookie";
import store from "@/store";
import router from "@/router";
import { LOG_OUT } from "@/store/auth";
import { i18n } from "@/i18n/i18n";

import { toastError, translateAndToastError } from "@/use/useToast";
const envAuth: string | undefined = APP_AUTH;

const $axiosMocks = axios.create({
  baseURL: window.location.origin + "/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

$axiosMocks.interceptors.response.use(
  function(successRes) {
    return successRes;
  },
  function(error) {
    if (error.response.status === 401 && (envAuth === "true" || typeof envAuth === "undefined")) {
      //if unauthorized clear cookies and redirect to login
      store.dispatch(LOG_OUT);
      router.push({ name: "Login" });
    }
    if (error.response.status > 399) {
      if (error.response.data.errors.length > 0) {
        translateAndToastError(`apiErrors.${error.response.data.errors[0]}`);
      } else {
        toastError(error);
      }
    }

    return Promise.reject(error);
  }
);

const $axios = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie(TP_COOKIE_NAME)}`
  }
});

$axios.interceptors.response.use(
  function(successRes) {
    return successRes;
  },
  function(error) {
    if (error.response.status === 401 && (envAuth === "true" || typeof envAuth === "undefined")) {
      //if unauthorized clear cookies and redirect to login
      store.dispatch(LOG_OUT);
      router.push({ name: "Login" });
    } else if (error.response.status === 409) {
      const lockedUserDetails = error.response.data.message.replace(/(^.*\[|\].*$)/g, "");
      translateAndToastError(`apiErrors.${error.response.data.errorCode}`, {
        lockedUserDetails
      });
    } else if (error.response.status > 499) {
      if (typeof error.response.data.errors !== "undefined" && error.response.data.errors.length > 0) {
        toastError(
          `${i18n.t(`apiErrors.${error.response.data.errors[0]}`).toString()}: ${error.response.data.message}`
        );
      } else {
        toastError(
          `${i18n.t(`apiErrors.${error.response.data.errorCode}`).toString()}: ${error.response.data.message}`
        );
      }
    }
    return Promise.reject(error);
  }
);

export { $axiosMocks, $axios };
