var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "min-h-screen" },
    [
      _c("Overlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.fleetEditActive || _vm.fleetIsLoading,
            expression: "fleetEditActive || fleetIsLoading"
          }
        ],
        staticClass: "left-84"
      }),
      _c(
        "div",
        { staticClass: "z-20 fixed" },
        [
          _c("BoardFilter", {
            staticClass: " z-20 bg-gray-100 left-0",
            attrs: { editModeActive: _vm.fleetEditActive },
            on: {
              edit: function($event) {
                return _vm.onFleetEdit()
              },
              save: function($event) {
                return _vm.onFleetSave()
              },
              cancel: function($event) {
                return _vm.onFleetCancel()
              }
            }
          }),
          _c("TrailerFilter", {
            staticClass: " z-20 bg-gray-100 left-0",
            attrs: { editModeActive: _vm.fleetEditActive },
            on: {
              edit: function($event) {
                return _vm.onFleetEdit()
              },
              save: function($event) {
                return _vm.onFleetSave()
              },
              cancel: function($event) {
                return _vm.onFleetCancel()
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "mt-28 absolute z-10 left-0 top-0 bg-gray-100 sidebar w-full",
          style: _vm.styledObject
        },
        [
          _vm.fleetIsEmpty || _vm.fleetIsLoading
            ? _c("div", [
                _vm.fleetIsEmpty && !_vm.fleetIsLoading && _vm.boardsSelected
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center items-center h-24" },
                      [_c("div", [_vm._v("No data found")])]
                    )
                  : _vm._e(),
                _vm.fleetIsLoading
                  ? _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c("a-skeleton", {
                          attrs: { active: "", paragraph: { rows: 60 } }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          !_vm.fleetIsLoading
            ? _c(
                "div",
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        id: "fleetList",
                        disabled: !_vm.fleetEditActive,
                        ghostClass: "sortable-ghost",
                        chosenClass: "sortable-chosen",
                        dragClass: "dragClass"
                      },
                      on: {
                        start: _vm.startDrag,
                        end: _vm.stopDrag,
                        change: _vm.highlightMovedElement
                      },
                      model: {
                        value: _vm.fleetDraggables,
                        callback: function($$v) {
                          _vm.fleetDraggables = $$v
                        },
                        expression: "fleetDraggables"
                      }
                    },
                    _vm._l(_vm.fleetDraggables, function(draggable) {
                      return _c(
                        "div",
                        { key: draggable.ordinal },
                        [
                          draggable.items.length === 1 &&
                          !(
                            draggable.items[0].type ===
                              _vm.FleetEntityType.TRAILER &&
                            draggable.items[0].groupGlueId
                          )
                            ? _c("BoardSideBarCard", {
                                key: draggable.items[0].id,
                                attrs: {
                                  content: draggable.items[0],
                                  type: draggable.items[0].type,
                                  isDraggable: _vm.fleetEditActive,
                                  onlyRenderLeadingTitle: false,
                                  isGroupCard: false
                                }
                              })
                            : _c(
                                "div",
                                {
                                  class: {
                                    "sideBarCard--grouped__subcontractor":
                                      draggable.items[0].type ===
                                      _vm.FleetEntityType.SUBCONTRACTOR,
                                    "sideBarCard--grouped__truck":
                                      draggable.items[0].type ===
                                      _vm.FleetEntityType.TRUCK,
                                    "sideBarCard--grouped__trailer":
                                      draggable.items[0].type ===
                                      _vm.FleetEntityType.TRAILER
                                  }
                                },
                                _vm._l(draggable.items, function(
                                  groupDraggable,
                                  index
                                ) {
                                  return _c("BoardSideBarCard", {
                                    key: groupDraggable.id,
                                    attrs: {
                                      content: groupDraggable,
                                      type: groupDraggable.type,
                                      isDraggable: _vm.fleetEditActive,
                                      isGroupCard: true,
                                      onlyRenderLeadingTitle:
                                        groupDraggable.type ===
                                          _vm.FleetEntityType.TRAILER &&
                                        index === 0
                                    }
                                  })
                                }),
                                1
                              )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.isBoardEditModeActive
        ? _c("div", {
            staticClass:
              "h-full w-84 bg-black fixed top-0 left-0 z-30 opacity-20"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }