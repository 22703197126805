var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-22 pt-2" }, [
    _c(
      "div",
      { staticClass: "w-84 px-2 z-20 flex justify-end" },
      [
        !_vm.editModeActive
          ? _c("multiSelect", {
              attrs: {
                options: _vm.options,
                multiple: true,
                "close-on-select": false,
                "clear-on-select": true,
                placeholder: _vm.$t("filter.chooseBoards"),
                label: "name",
                "track-by": "name",
                selectLabel: _vm.$t("actions.select"),
                deselectLabel: _vm.$t("actions.remove")
              },
              on: { open: _vm.openDropdown, close: _vm.updateDropdownHandler },
              scopedSlots: _vm._u(
                [
                  {
                    key: "selection",
                    fn: function(ref) {
                      var values = ref.values
                      var isOpen = ref.isOpen
                      return [
                        values.length && !isOpen
                          ? _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(
                                _vm._s(values.length) +
                                  " " +
                                  _vm._s(_vm.$t("filter.boardsSelected"))
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                3070493284
              ),
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            })
          : _vm._e(),
        _vm.editModeActive
          ? _c("FilterButton", {
              attrs: { icon: "close", tooltip: _vm.$t("actions.cancel") },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            })
          : _vm._e(),
        _vm.selectedBoards && !_vm.editModeActive
          ? _c("FilterButton", {
              attrs: {
                icon: "sort",
                disabled: !_vm.selectedBoards.length,
                tooltip: _vm.$t("actions.sort")
              },
              on: {
                click: function($event) {
                  return _vm.$emit("edit")
                }
              }
            })
          : _vm._e(),
        _vm.selectedBoards && _vm.editModeActive
          ? _c("FilterButton", {
              attrs: { icon: "save", tooltip: _vm.$t("actions.save") },
              on: {
                click: function($event) {
                  return _vm.$emit("save")
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }