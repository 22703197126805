var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.notStartedLegs.length > 0 && _vm.ticker.show
        ? _c("Notification", {
            attrs: {
              notStartedLegsOn: _vm.notStartedLegs,
              tickerPosition: _vm.tickerPosition
            }
          })
        : _vm._e(),
      _vm.ticker.show
        ? _c("div", {
            staticClass:
              "absolute top-12 left-0 w-1 h-screen opacity-50 bg-red-600 z-0",
            style: _vm.tickerPosition
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }