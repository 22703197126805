/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'truck': {
    width: 29.137,
    height: 16,
    viewBox: '0 0 29.137 16',
    data: '<g _fill="#707070"><path pid="0" d="M5.996 10.789a2.514 2.514 0 102.515 2.518 2.513 2.513 0 00-2.515-2.518zm0 3.618a1.1 1.1 0 111.1-1.1 1.1 1.1 0 01-1.1 1.1zM29.137 12.386v1.01h-1.362a2.616 2.616 0 00.048-.461 2.587 2.587 0 00-.064-.549zM20.955 12.386a3.343 3.343 0 00-.048.549 3.408 3.408 0 00.035.461h-1.038v-1.01zM9.072 12.935h10.546v.735H9.094c.009-.09.015-.183.015-.277a3.191 3.191 0 00-.037-.458z"/><path pid="1" d="M19.412-.005H.273l.008 12.466h2.582l.125-.081a3.144 3.144 0 016.048.344h10.587V2.474h-.211zm-18.029 11.2H.99V9.632h.393zM2.831 12.672a2.836 2.836 0 00-.04.459c0 .094.009.185.015.277H0v-.735zM29.046 12.109v-5.1l-2.077-4.55h-7.083v9.652h1.128a3.351 3.351 0 016.5 0h1.532zm-3.631-4.825l-2.022-1.7h-2.734V3.191h5.952l1.509 4.093z"/><path pid="2" d="M24.253 10.607a2.7 2.7 0 102.7 2.7 2.7 2.7 0 00-2.7-2.7zm0 3.878a1.182 1.182 0 111.184-1.182 1.183 1.183 0 01-1.184 1.182z"/></g>'
  }
})
