











































import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Dialog from "@/components/Dialog.vue";
import Button from "@/components/Buttons/Button.vue";
import store from "@/store";
import { UPDATE_CONFIG_PROFILE, UPDATE_FROM_TO_TIME } from "@/store/board";
import { CLEAR_FLEET, FETCH_BOARD_FLEET } from "@/store/fleet";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { format } from "date-fns";
import { prependZeroNumber } from "@/use/useNumbers";
import { dayHourSettings } from "@/types/board";

export default defineComponent({
  name: "TimeSelectorDialog",
  components: { Dialog, Button },
  setup(props, { emit }) {
    const isActive = ref<boolean>(false);
    const fromTime = computed(() => store.state.board.fromTime);
    const toTime = computed(() => store.state.board.toTime);

    const fromTimeSelector = ref<string>(prependZeroNumber(fromTime.value));
    const toTimeSelector = ref<string>(prependZeroNumber(toTime.value));

    const toTimeChanged = ref<boolean>(false);
    const fromTimeChanged = ref<boolean>(false);

    const noTimeSelected = computed(() => {
      return fromTimeSelector.value == null || toTimeSelector.value == null;
    });

    const resetFromTime = () => {
      toTimeChanged.value = false;
    };

    const resetToTime = () => {
      fromTimeChanged.value = false;
    };

    const fromTimeMaxValue = 21;
    const toTimeMinValue = 3;
    const timeInterval = 3;

    const onFromTimeChange = () => {
      if (fromTimeSelector.value == null) {
        resetFromTime();
      } else if (!toTimeChanged.value) {
        let fromTime = parseInt(fromTimeSelector.value);
        if (fromTime > fromTimeMaxValue) {
          fromTime = fromTimeMaxValue;
          fromTimeSelector.value = prependZeroNumber(fromTime);
        }
        toTimeSelector.value = prependZeroNumber(fromTime + timeInterval);
        fromTimeChanged.value = true;
      }
    };

    const onToTimeChange = () => {
      if (toTimeSelector.value == null) {
        resetToTime();
      } else if (!fromTimeChanged.value) {
        let toTime = parseInt(toTimeSelector.value);
        if (toTime < toTimeMinValue) {
          toTime = toTimeMinValue;
          toTimeSelector.value = prependZeroNumber(toTime);
        }
        fromTimeSelector.value = prependZeroNumber(toTime - timeInterval);
        toTimeChanged.value = true;
      }
    };

    const defaultHourOptions = [...Array(24).keys()].map(time => ({
      disabled: false,
      time: prependZeroNumber(time)
    }));

    const fromOptions = computed(() => {
      if (toTimeSelector.value && toTimeChanged.value) {
        return defaultHourOptions.map(item => {
          if (item.time >= toTimeSelector.value) {
            return { ...item, disabled: true };
          }
          if (item.time < toTimeSelector.value) {
            return {
              ...item,
              disabled: (parseInt(toTimeSelector.value) - parseInt(item.time)) % timeInterval !== 0
            };
          }
          return item;
        });
      }
      return defaultHourOptions;
    });

    const toOptions = computed(() => {
      if (fromTimeSelector.value && fromTimeChanged.value) {
        return defaultHourOptions.map(item => {
          if (item.time <= fromTimeSelector.value) {
            return { ...item, disabled: true };
          }
          if (item.time > fromTimeSelector.value) {
            return {
              ...item,
              disabled: (parseInt(item.time) - parseInt(fromTimeSelector.value)) % timeInterval !== 0
            };
          }
          return item;
        });
      }
      return defaultHourOptions;
    });

    watch([fromTime, toTime], () => {
      fromTimeSelector.value = fromTime.value;
      toTimeSelector.value = toTime.value;
    });

    const updateTimeline = async () => {
      isActive.value = false;

      const hours = {
        fromTime: parseInt(fromTimeSelector.value),
        toTime: parseInt(toTimeSelector.value)
      };

      if (hours.fromTime !== fromTime.value || hours.toTime !== toTime.value) {
        saveHourSelection(hours);
      }
      closeTimeSelector();
    };

    const closeTimeSelector = () => {
      emit("closeHandler");
    };

    const show24hours = ref(false);
    const dayStartHour = dayHourSettings.DAY_START;
    const dayEndHour = dayHourSettings.DAY_END;
    const onShow24HoursChange = async () => {
      const payload = {
        fromTime: show24hours.value ? dayStartHour : dayHourSettings.DEFAULT_DAY_START,
        toTime: show24hours.value ? dayEndHour : dayHourSettings.DEFAULT_DAY_END
      };
      await saveHourSelection(payload);
    };

    const saveHourSelection = async (payload: { fromTime: number; toTime: number }) => {
      emit("isLoading", true);
      await store.dispatch(UPDATE_FROM_TO_TIME, payload);
      await store.dispatch(UPDATE_CONFIG_PROFILE);
      await store.commit(CLEAR_FLEET);
      await store.commit(RESET_LEGS);
      await store.commit(RESET_FLEET_ACTIONS);
      await store.dispatch(FETCH_BOARD_FLEET);
      emit("isLoading", false);
    };

    onMounted(() => {
      isActive.value = true;
      show24hours.value = fromTime.value === dayStartHour && toTime.value === dayEndHour;
    });

    return {
      isActive,
      fromOptions,
      toOptions,
      fromTimeSelector,
      toTimeSelector,
      noTimeSelected,
      closeTimeSelector,
      updateTimeline,
      onFromTimeChange,
      onToTimeChange,
      format,
      show24hours,
      onShow24HoursChange
    };
  }
});
