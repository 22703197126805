var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isActive,
          expression: "isActive"
        }
      ]
    },
    [
      _c("Overlay"),
      _c("transition", { attrs: { name: "slideRight" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.splitScreenContent,
                expression: "splitScreenContent"
              }
            ],
            ref: "splitScreen",
            staticClass:
              "splitScreen fixed top-0 right-0 h-screen z-50 bg-white",
            class: _vm.customClass,
            attrs: { tabindex: "0" }
          },
          [
            _c(
              "div",
              {
                staticClass: "splitScreen__header border-gray-200 border-b",
                class: _vm.customClass
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "splitScreen__header__inner flex justify-between py-2 mx-4 my-2"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-2xl" },
                      [_vm._t("header")],
                      2
                    ),
                    _c(
                      "button",
                      { ref: "closeBtn", on: { click: _vm.closeSplitScreen } },
                      [
                        _c("Icon", {
                          staticClass: "text-gray-400 cursor-pointer",
                          attrs: { name: "close" }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "splitScreen__body px-4" },
              [_vm._t("default")],
              2
            ),
            _c(
              "div",
              { staticClass: "splitScreen__actionBar", class: _vm.customClass },
              [_vm._t("actionBar", null, { close: _vm.closeSplitScreen })],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ],
                staticClass:
                  "h-screen w-1/2 bg-btYellow bg-opacity-25 fixed top-0 flex items-center justify-center",
                class: _vm.customClass
              },
              [
                _c("Icon", {
                  staticClass: "text-black",
                  attrs: { name: "spinner" }
                })
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }