<template>
  <div>
    <div class="border-b-2 font-semibold mb-2 border-yellow-900 text-yellow-900">
      {{ spreadTrailerId(trailer.id).id }}
    </div>

    <table class="trailerCallout__table table-fixed text-left mb-6 w-full">
      <thead>
        <tr>
          <th class="w-1/3">{{ $t("trailer.type") }}</th>
          <th class="w-1/3">{{ $t("trailer.suitableFor") }}</th>
          <th class="w-1/3">{{ $t("trailer.tareWeight") }}</th>
        </tr>
      </thead>
      <tbody class="font-light align-top">
        <tr>
          <td>
            <span v-if="trailer.metadata.chassisType">{{ trailer.metadata.chassisType }}</span>
          </td>
          <td>
            <span v-if="trailer.metadata.suitableFor">
              {{ trailer.metadata.suitableFor }}
            </span>
          </td>
          <td>
            <span v-if="trailer.metadata.tareWeight"> {{ trailer.metadata.tareWeight }} kg </span>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="trailerCallout__table table-fixed text-left w-full mb-6">
      <thead>
        <tr>
          <th class="w-1/3">{{ $t("trailer.grossWeight") }}</th>
          <th class="w-1/3">{{ $t("trailer.nextTest") }}</th>
          <th class="w-1/3">{{ $t("truck.equipment") }}</th>
        </tr>
      </thead>
      <tbody class="font-light align-top">
        <tr>
          <td>
            <span v-if="trailer.metadata.grossWeight">{{ trailer.metadata.grossWeight }} kg</span>
          </td>
          <td>
            <span
              v-if="nextInspectionDate"
              :class="{
                'text-btRed': nextInspectionDate.isDateInPast
              }"
            >
              {{ nextInspectionDate.date }}
            </span>
            <span>
              <a-popover
                trigger="hover"
                placement="right"
                :overlayStyle="{ maxWidth: '650px' }"
                :getPopupContainer="triggerNode => triggerNode.parentNode"
              >
                <Icon name="info" class="text-black cursor-pointer" width="1.5rem" height="1.5rem" />
                <template #content>
                  <FleetNextInspectionTable :content="trailer.metadata" />
                </template>
              </a-popover>
            </span>
          </td>
          <td class="capitalize">
            {{ stringifyTrailerEquipment(trailer.metadata) }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="trailerCallout__table table-fixed text-left w-full">
      <thead class="font-light">
        <tr>
          <th class="w-1/3">{{ $t("trailer.department") }}</th>
          <th class="w-1/3">{{ $t("trailer.tempDepartment") }}</th>
          <th class="w-1/3">{{ $t("trailer.tempGroup") }}</th>
        </tr>
      </thead>
      <tbody class="align-top">
        <tr>
          <td>
            {{ trailer.baseBoardId }}
          </td>
          <td v-if="tempDepartment">
            {{ tempDepartment.boardId }}
          </td>
          <td v-if="tempGroup">
            {{ tempGroup.boardId }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { getNextInspectionDate } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";
import { stringifyTrailerEquipment } from "@/components/Board/CallOuts/Use/useFleetEquipment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Trailer } from "@/types/fleet";
import Icon from "@/components/Icon.vue";
import FleetNextInspectionTable from "@/components/Board/CallOuts/CalloutSideBar/FleetNextInspectionTable/FleetNextInspectionTable.vue";
import { getLatestDateOnBoards, filterBoardTypes } from "@/components/Board/CallOuts/Use/useBoards";
import { spreadTrailerId } from "@/use/useFleet";

export default defineComponent({
  name: "CalloutSideBarDriver.vue",
  components: { FleetNextInspectionTable, Icon },
  props: {
    content: {
      type: Object as PropType<Trailer>,
      required: true
    }
  },
  setup(props) {
    const trailer = computed<Trailer>(() => props.content);

    const GROUP = trailer.value.boards ? filterBoardTypes("GROUP", trailer.value) : null;
    const DEPARTMENT = trailer.value.boards ? filterBoardTypes("DEPARTMENT", trailer.value) : null;

    const tempDepartment = DEPARTMENT && DEPARTMENT.length >= 1 ? getLatestDateOnBoards(DEPARTMENT) : null;
    const tempGroup = GROUP && GROUP.length >= 1 ? getLatestDateOnBoards(GROUP) : null;

    const nextInspectionDate = computed(() => getNextInspectionDate(trailer.value.metadata));

    return {
      trailer,
      stringifyTrailerEquipment,
      getNextInspectionDate,
      tempGroup,
      tempDepartment,
      spreadTrailerId,
      nextInspectionDate
    };
  }
});
</script>

<style scoped lang="scss">
@use "CalloutSideBarTrailer";
</style>
