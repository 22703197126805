















import { defineComponent } from "@vue/composition-api";
import { LegTransportStatuses } from "@/types/leg";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "LegTransportStatusIndicator",
  components: { Icon },
  props: {
    status: {
      type: Object as () => LegTransportStatuses,
      required: true
    }
  }
});
