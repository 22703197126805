var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      on: {
        click: _vm.logOut,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.logOut($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "flex items-center cursor-pointer " },
        [
          _c("Icon", {
            staticClass: "mr-1",
            attrs: { name: "logout", height: "18", width: "18" }
          }),
          _c("span", [_vm._v("Logout")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }