<template>
  <svg-icon :name="name" :height="height" :width="width" :original="original" @click="onClick"></svg-icon>
</template>

<script lang="ts">
import "@/assets/icons";
import { defineComponent } from "@vue/composition-api";
import svgIcon from "vue-svgicon";

export default defineComponent({
  components: { svgIcon },
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit("click");
    };

    return { onClick };
  }
});
</script>

<style scoped></style>
