

















































import { defineComponent, PropType, computed } from "@vue/composition-api";
import { PreviousLeg, LegStatuses } from "@/types/leg";
import { dateStringToShowDate } from "@/use/useDate";

export default defineComponent({
  name: "LegDetailPreviousLegState",
  props: {
    data: {
      type: Object as PropType<PreviousLeg>,
      required: true
    },
    status: {
      type: Object as PropType<LegStatuses>,
      required: true
    }
  },
  setup(props) {
    const collapsePanelKey = "previousLegStateKey";
    const defaultActiveKey = computed<string>(() =>
      props.status.notBooked || props.status.prePlanned ? collapsePanelKey : ""
    );

    const DEFAULT_TEXT = "-";

    const from = computed<string>(() => props.data?.from?.name || DEFAULT_TEXT);

    const to = computed<string>(() => props.data?.to?.name || DEFAULT_TEXT);

    const bookingId = computed<string>(() => props.data?.booking?.id || DEFAULT_TEXT);

    const departureDate = computed<string>(() => {
      const { date } = props.data?.effectiveDepartureDate || {};
      return date ? `E: ${dateStringToShowDate(date)}` : DEFAULT_TEXT;
    });

    const arrivalDate = computed<string>(() => {
      const { date: effectiveArivalDate } = props.data?.effectiveArrivalDate || {};
      if (effectiveArivalDate) {
        return `E: ${dateStringToShowDate(effectiveArivalDate)}`;
      }
      const { date: plannedArrivalDate } = props.data?.plannedArrivalDate || {};
      return plannedArrivalDate ? `P: ${dateStringToShowDate(plannedArrivalDate)}` : DEFAULT_TEXT;
    });

    return {
      collapsePanelKey,
      defaultActiveKey,
      from,
      to,
      bookingId,
      departureDate,
      arrivalDate,
      DEFAULT_TEXT
    };
  }
});
