var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "z-0" },
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("div", { staticClass: "w-84 bg-gray-100 z-20 fixed h-full" }),
          _c("BoardSideBar", {
            staticClass: "w-84 z-20 bg-gray-100 absolute left-0"
          }),
          _c("BoardTimeLine", { staticClass: "flex-1 flex" }),
          _vm.legDetailActive
            ? _c("LegDetail", {
                attrs: {
                  "selected-leg": _vm.selectedLeg,
                  isActive: _vm.legDetailActive
                },
                on: { legDetailClosed: _vm.closeSplitScreen }
              })
            : _vm._e(),
          _vm.fleetDetailActive
            ? _c("FleetDetail", {
                attrs: {
                  fleetMember: _vm.selectedAffiliation,
                  isActive: _vm.fleetDetailActive
                },
                on: { fleetDetailClosed: _vm.closeSplitScreen }
              })
            : _vm._e(),
          _vm.fleetActionActive
            ? _c("FleetActionReminder", {
                attrs: {
                  fleetAction: _vm.selectedFleetAction,
                  isActive: _vm.fleetActionActive
                },
                on: { fleetDetailClosed: _vm.closeSplitScreen }
              })
            : _vm._e(),
          _vm.subcontractorDetailActive
            ? _c("SubcontractorDetail", {
                attrs: {
                  subcontractor: _vm.selectedAffiliation,
                  isActive: _vm.subcontractorDetailActive
                },
                on: { close: _vm.closeSplitScreen }
              })
            : _vm._e()
        ],
        1
      ),
      _c("LoadingScreen")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }