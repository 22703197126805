var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-1 ml-84" },
    [
      _c(
        "div",
        {
          ref: "timelineBoard",
          staticClass: "boardTimeLine w-full",
          style: {
            "background-image": _vm.backgroundImageUrl
          },
          attrs: { id: "board" }
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.boardReady,
                    expression: "boardReady"
                  }
                ],
                staticClass: "relative",
                class: _vm.bgClass
              },
              [
                _c("BoardTimeLineHeader"),
                _c(
                  "div",
                  {
                    staticClass: "h-auto boardTimeLine__body",
                    on: {
                      drop: _vm.handleDrop,
                      dragover: _vm.allowDrop,
                      dragenter: _vm.allowDrop,
                      contextmenu: function($event) {
                        $event.preventDefault()
                        return _vm.openContextMenu.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-28" },
                      [
                        _vm._l(_vm.legs, function(leg) {
                          return _c("Leg", {
                            key: leg.id,
                            attrs: { "leg-data": leg },
                            on: { showSplitScreen: _vm.showSplitScreen }
                          })
                        }),
                        _vm._l(_vm.actions, function(action, actionIndex) {
                          return _c("ActionBrick", {
                            key: action.isDuplicate
                              ? actionIndex + "-" + action.id
                              : action.id,
                            attrs: { "action-data": action },
                            on: { showSplitScreen: _vm.showSplitScreen }
                          })
                        }),
                        !_vm.fleet.length
                          ? _c(
                              "div",
                              _vm._l(20, function(index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "h-28" },
                                  [_c("BoardTimeBoxes")],
                                  1
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(_vm.fleet, function(entry, index) {
                                return _c(
                                  "div",
                                  {
                                    key: entry.id + "-" + index,
                                    staticClass: "relative",
                                    style: {
                                      height:
                                        (_vm.fleetSpans[entry.id] || 100) + "px"
                                    }
                                  },
                                  [
                                    entry.occupancyBlocks &&
                                    entry.occupancyBlocks.length > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "h-full w-full absolute"
                                          },
                                          _vm._l(
                                            entry.occupancyBlocks,
                                            function(block, index) {
                                              return _c("span", {
                                                key: index,
                                                staticClass:
                                                  "occupied absolute h-full",
                                                style: {
                                                  left:
                                                    block.startXCoord + "px",
                                                  width: block.width + "px"
                                                }
                                              })
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _c("BoardTimeBoxes")
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ],
                      2
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("ContextMenu", {
        attrs: { "mouse-event": _vm.ctxEvent },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var closeContextMenu = ref.closeContextMenu
              var mouseEvent = ref.mouseEvent
              return [
                _c("TimelineContextOptions", {
                  attrs: {
                    closeContextMenu: closeContextMenu,
                    boardReference: _vm.timelineBoard,
                    mouseEvent: mouseEvent
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }