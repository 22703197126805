<template>
  <div>
    <a-table :dataSource="chatRooms" :columns="columns" :pagination="false" :bordered="true" :customRow="customRow">
      <div slot="unreadCount" slot-scope="text, record">
        <div v-if="record.unreadCount > 0" class="bg-red-600 text-white rounded-full text-center">
          {{ record.unreadCount }}
        </div>
      </div>
      <div slot="alternateOrderNumber" slot-scope="text, record">
        <span>{{ defineLegTypeName(record.legType) }}</span
        >-<span
          class="cursor-pointer border-b border-gray-400 hover:border-yellow-500"
          @click="record.notifyG11Bookings"
          >{{ record.alternateOrderNumber }}</span
        >
        <Icon
          name="hyperlink"
          class="hover:text-btYellow relative -mt-1 ml-1 cursor-pointer"
          height="17"
          width="17"
          @click="record.notifyG11Planning"
        />
      </div>
      <div slot="lastSentMessage" slot-scope="text, record">
        <div>
          {{ dateStringToShowDate(record.lastSentMessage) }} / {{ dateStringToShowTime(record.lastSentMessage) }}
        </div>
      </div>
    </a-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import { GET_LEGS } from "@/store/brick";
import { POST_G11_MESSAGE } from "@/store/board";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import { i18n } from "@/i18n/i18n";
import { LegG11Action } from "@/types/leg";
import Icon from "@/components/Icon.vue";
import { defineLegTypeName } from "@/use/useLeg";
import { GET_CHATS, SET_IS_CHAT_ACTIVE, FETCH_MESSAGES_FOR_ACTIVE_CHAT } from "@/store/chat";

export default defineComponent({
  components: { Icon },
  setup() {
    const chats = computed(() => store.getters[GET_CHATS]);

    const notifyG11 = async (legG11Action: LegG11Action, g11Actions) => {
      if (g11Actions) {
        const message = g11Actions[legG11Action];
        if (message) {
          await store.dispatch(POST_G11_MESSAGE, message);
        }
      }
    };

    const legs = computed(() => store.getters[GET_LEGS]);

    const chatRooms = computed(() => {
      return chats.value.map((chat, index) => {
        return {
          ...chat,
          key: (index + 1).toString(),
          notifyG11Planning: async () => {
            return await notifyG11(LegG11Action.NAVIGATE_TO_PLANNING_SCREEN, chat.g11Actions);
          },
          notifyG11Bookings: async () => {
            return await notifyG11(LegG11Action.NAVIGATE_TO_BOOKING_SCREEN, chat.g11Actions);
          }
        };
      });
    });

    const columns = computed(() => {
      return [
        {
          title: i18n.t("fleet.driver"),
          dataIndex: "driverName",
          key: "driverName",
          sorter: (a, b) => a.driverName.localeCompare(b.driverName)
        },
        {
          title: i18n.t("chat.order"),
          dataIndex: "alternateOrderNumber",
          key: "alternateOrderNumber",
          sorter: (a, b) => parseInt(a.alternateOrderNumber) - parseInt(b.alternateOrderNumber),
          scopedSlots: { customRender: "alternateOrderNumber" }
        },
        {
          title: i18n.t("chat.dateAndTime"),
          dataIndex: "lastSentMessage",
          key: "lastSentMessage",
          sorter: (a, b) => new Date(a.lastSentMessage).getTime() - new Date(b.lastSentMessage).getTime(),
          defaultSortOrder: "descend",
          scopedSlots: { customRender: "lastSentMessage" }
        },
        {
          title: "",
          dataIndex: "unreadCount",
          key: "unreadCount",
          scopedSlots: { customRender: "unreadCount" }
        }
      ];
    });

    const customRow = record => ({
      on: {
        click: async () => {
          await store.dispatch(FETCH_MESSAGES_FOR_ACTIVE_CHAT, record);
          store.commit(SET_IS_CHAT_ACTIVE, true);
        }
      }
    });

    return {
      columns,
      chatRooms,
      dateStringToShowDate,
      dateStringToShowTime,
      defineLegTypeName,
      legs,
      chats,
      customRow
    };
  }
});
</script>

<style scoped></style>
