/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compressor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<text font-family="sans-serif" font-size="24" y="20.557" x="3.387" stroke-width="0" fill="#5a5d60">C</text>'
  }
})
