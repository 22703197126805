<template>
  <div class="h-22 pt-2">
    <div class="w-84 px-2 z-20 flex justify-end">
      <multiSelect
        v-if="!editModeActive"
        v-model="value"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="true"
        :placeholder="$t('filter.chooseTrailers')"
        label="name"
        track-by="name"
        :selectLabel="$t('actions.select')"
        :selectedLabel="''"
        :deselectLabel="$t('actions.remove')"
        @open="openDropdown"
        @close="dropdownHandler"
      >
        <template slot="selection" slot-scope="{ values, isOpen }"
          ><span class="multiselect__single" v-if="values.length && !isOpen"
            >{{ values.length }} {{ $t("filter.trailersSelected") }}</span
          ></template
        >
        <template slot="option" slot-scope="props">
          <a-tooltip placement="right" :align="{ offset: [10, 0] }">
            <template slot="title">
              <span>{{ props.option.id }}</span>
            </template>
            <div class="w-84 max-w-full overflow-ellipsis overflow-hidden">
              {{ props.option.id }}
            </div>
          </a-tooltip>
        </template>
      </multiSelect>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, computed, watch } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import multiSelect from "vue-multiselect";
import store from "@/store";
import { GET_FILTERED_BOARD_PROFILE } from "@/store/board";
import {
  DEACTIVATE_DRIVER_COMMENT_TOGGLE,
  GET_TRAILER_OPTIONS,
  GET_TRAILERS_SELECTED,
  INIT_TRAILERS_DROPDOWN,
  SET_TRAILERS_SELECTED,
  FETCH_BOARD_FLEET_TRAILERS
} from "@/store/fleet/";
import FilterButton from "@/components/Buttons/FilterButton.vue";
import { TrailerMeta } from "@/types/fleet";

export default defineComponent({
  name: "TrailerFilter",
  components: { Icon, multiSelect, FilterButton },
  props: {
    editModeActive: {
      type: Boolean
    }
  },
  setup() {
    const value = ref([]);
    const options = computed(() => store.getters[GET_TRAILER_OPTIONS]);
    const selectedTrailers = computed(() => store.getters[GET_TRAILERS_SELECTED]);

    onBeforeMount(() => {
      store.dispatch(INIT_TRAILERS_DROPDOWN);
    });

    watch(selectedTrailers, last => {
      value.value = last;
    });

    const openDropdown = (): void => {
      store.dispatch(DEACTIVATE_DRIVER_COMMENT_TOGGLE);
    };

    const dropdownHandler = (value: TrailerMeta[]): void => {
      if (value !== selectedTrailers.value) {
        store.commit(
          SET_TRAILERS_SELECTED,
          value.map(trailer => ({ name: trailer.id, id: trailer.id }))
        );

        store.dispatch(FETCH_BOARD_FLEET_TRAILERS, { data: value }, { root: true });

        store.dispatch(GET_FILTERED_BOARD_PROFILE);
      }
      //scroll always to top when changing selection
      window.scrollTo(0, 0);
    };

    return { value, options, openDropdown, selectedTrailers, dropdownHandler };
  }
});
</script>

<style scoped lang="scss">
@use "TrailerFilter";
::v-deep .multiselect__content {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
