import { translateAndToastError, translateAndToastSuccess } from "@/use/useToast";

const copyTextToClipBoard = async (text: string) => {
  const permissionName = "clipboard-write" as PermissionName;
  const permissions = await navigator.permissions.query({
    name: permissionName
  });
  if (permissions.state == "granted" || permissions.state == "prompt") {
    /* write to the clipboard now */
    try {
      await navigator.clipboard.writeText(text);
      translateAndToastSuccess("Copied to clipboard");
    } catch (error) {
      translateAndToastError("Couldn't copy it to clipboard");
    }
  }
};

const generateRandomString = () => {
  return Math.random()
    .toString(36)
    .substring(2, 15);
};

const capitalizeFirst = (string?: string) => {
  if (string == null) {
    return "";
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
};

export { copyTextToClipBoard, generateRandomString, capitalizeFirst };
