<template>
  <div>
    <Header />
    <div class="pt-28">
      <Board />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Header from "@/components/Header.vue";
import Board from "@/components/Board/Board.vue";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  name: "Dashboard",
  components: { Header, Board, Icon }
});
</script>
