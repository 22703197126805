import { BoardLeg, BookingState, LegStatuses, LegTypes } from "@/types/leg";

const defineLegTypeName = (legType?: string): string => {
  switch (legType) {
    case "COLLECTION":
      return "C";
    case "DELIVERY":
      return "D";
    case "TRUCKING":
      return "T";
    case "FULL_TRANSPORT":
      return "F";
    case "DROP_SWAP":
      return "DS";
    case "ABORTED_COLLECTION":
      return "ABRTC";
    case "ABORTED_DELVIERY":
      return "ABRTD";
    case "CLEANING":
      return "CL";
    case "INTERMODAL":
    case "IM_ROUTE":
      return "IM";
    case "HEATING":
      return "HT";
    case "STORE":
      return "S";
    case "TP_DELIVERY":
      return "TPD";
    case "TP_COLLECTION":
      return "TPC";
    case "AGL_COLLECTION":
      return "AC";
    case "AGL_DELIVERY":
      return "AD";
    default:
      return "";
  }
};

const defineLegTypeIcon = (legType?: string): string => {
  switch (legType) {
    case "CLEANING":
      return "shower";
    case "IM_ROUTE":
    case "INTERMODAL":
      return "train";
    case "HEATING":
      return "heating";
    case "STORE":
      return "warehouse";
    case "TRUCKING":
    case "COLLECTION":
    case "DELIVERY":
    case "DROP_SWAP":
    case "FULL_TRANSPORT":
      return "delivery-truck";
    default:
      return "";
  }
};

const defineLegType = (legType: string): LegTypes => {
  const legTypes = {
    collection: false,
    delivery: false,
    trucking: false,
    fullTransport: false,
    dropSwap: false,
    abortedCollection: false,
    abortedDelivery: false,
    name: ""
  };
  legTypes.collection = legType === "COLLECTION";
  legTypes.delivery = legType === "DELIVERY";
  legTypes.trucking = legType === "TRUCKING";
  legTypes.fullTransport = legType === "FULL_TRANSPORT";
  legTypes.dropSwap = legType === "DROP_SWAP";
  legTypes.abortedCollection = legType === "ABORTED_COLLECTION";
  legTypes.abortedDelivery = legType === "ABORTED_DELIVERY";
  legTypes.name = defineLegTypeName(legType);

  return legTypes;
};

const isSpecialType1 = (legType: LegTypes) => legType.collection || legType.fullTransport;

const isSpecialType2 = (legType: LegTypes) => legType.delivery || legType.fullTransport;

const defineLegStatus = (legData: BoardLeg, legType?: LegTypes): LegStatuses => {
  const actualType = legType == null ? defineLegType(legData.type) : legType;

  const legStatuses: LegStatuses = {
    notBooked: false,
    prePlanned: false,
    booked: false,
    departed: false,
    loaded: false,
    unloaded: false,
    dropped: false,
    name: ""
  };

  if (typeof legData?.events?.effectiveArrivalDate !== "undefined") {
    legStatuses.dropped = true;
    legStatuses.name = "dropped";
  } else if (typeof legData?.events?.unloadingCheckOut !== "undefined" && isSpecialType2(actualType)) {
    legStatuses.unloaded = true;
    legStatuses.name = "unloaded";
  } else if (typeof legData?.events?.loadingCheckOut !== "undefined" && isSpecialType1(actualType)) {
    legStatuses.loaded = true;
    legStatuses.name = "loaded";
  } else if (typeof legData?.events?.effectiveDepartureDate !== "undefined") {
    legStatuses.departed = true;
    legStatuses.name = "departed";
  } else if (legData?.booking?.bookingState === BookingState.PREPLANNED) {
    legStatuses.prePlanned = true;
    legStatuses.name = "prePlanned";
  } else if (typeof legData?.booking?.id !== "undefined") {
    legStatuses.booked = true;
    legStatuses.name = "booked";
  } else if (typeof legData?.booking?.id === "undefined") {
    legStatuses.notBooked = true;
    legStatuses.name = "notBooked";
  }
  return legStatuses;
};

export { defineLegTypeName, defineLegType, defineLegTypeIcon, defineLegStatus };
