import { SET_IS_CHAT_ACTIVE, TOGGLE_CHAT_HUB_MODE, FETCH_MESSAGES_FOR_ACTIVE_CHAT } from "@/store/chat";
import store from "@/store";

const showNotification = (messageInfo, from, message) => {
  const icon = `${require("@/assets/images/logo.webp")}`;
  const title = `New message : ${messageInfo.alternateOrderNumber}`;
  const messageOn = message.length > 100 ? message.substring(0, 100) : message;
  const body = from + " : " + messageOn;
  const notification = new Notification(title, { body, icon });
  notification.onclick = () => {
    openChatHub(messageInfo);
    notification.close();
    window.parent.focus();
  };
};

const openChatHub = async messageInfo => {
  await store.dispatch(FETCH_MESSAGES_FOR_ACTIVE_CHAT, messageInfo);
  store.commit(SET_IS_CHAT_ACTIVE, true);
  store.dispatch(TOGGLE_CHAT_HUB_MODE, true);
};

const requestAndShowPermission = (messageInfo, from, message) => {
  Notification.requestPermission(function(permission) {
    if (permission === "granted") {
      showNotification(messageInfo, from, message);
    }
  });
};

export { showNotification, requestAndShowPermission };
