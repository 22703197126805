



















import { defineComponent, ref, watch, computed } from "@vue/composition-api";
import { formatTimeString } from "@/use/useTime";
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default defineComponent({
  name: "fleetFormsTimePicker",
  props: {
    value: Object,
    defaultValue: {
      type: String,
      required: true
    }
  },
  directives: {
    ClickOutside
  },
  setup(props, { emit }) {
    // Template ref to timepicker component (<span> element)
    const timepicker = ref<any>(null);
    // State of the timepicker popup
    const isOpen = ref<boolean>(false);
    // Timepicker input (initially set to default value)
    const time = ref<moment.Moment>(moment(props.defaultValue, "HH:mm"));
    emit("input", time.value);

    /**
     * Reset time picker's value when action type is changing in parent
     */
    watch(
      () => props.value,
      newValue => {
        if (newValue == null) {
          time.value = moment(props.defaultValue, "HH:mm");
          emit("input", time.value);
        } else {
          time.value = moment(props.value, "HH:mm");
        }
      }
    );

    /**
     * Since the timepicker input & timepicker popup are DOM siblings,
     * pressing SHIFT+TAB (to navigate to the previous input element)
     * is not possible, since tabbing back from the active popup re-focuses
     * the timepicker input which invokes the focus callback to actually
     * open the popup again (stuck in a re-open loop)
     * To overcome this we make the timepicker (not popup!) input non-tabbable
     * on popup open and only tabbable again after a short period of time
     * after closing the popup (Once navigated to prev element it will be up again).
     */
    watch(
      () => isOpen.value,
      () => {
        const input = timepicker.value.$el.querySelector("input");
        if (isOpen.value) {
          input.tabIndex = -1;
        } else {
          setTimeout(() => {
            input.tabIndex = 0;
          }, 200);
        }
      }
    );

    /**
     * Callback function to evaluate, assemble and format various user input formats
     */
    const evaluateAndClose = (): void => {
      // Select sibling (which contains the corresponding popup input field)
      const timePickerInput = timepicker.value.$el.nextSibling.querySelector("input.ant-time-picker-panel-input");
      const inputValue = timePickerInput?.value;

      const timeStr = formatTimeString(inputValue, props.defaultValue);

      // Set selected Time
      time.value = moment(timeStr, "HH:mm");
      emit("input", time.value);

      isOpen.value = false;
    };

    /**
     * Adds the popup to the trigger's parent in the DOM
     * Therefore scrolling the action form will also make the popup
     * scroll with the rest, instead of floating at the same position
     */
    const addPopupToParent = (trigger): Node => trigger.parentNode;

    // focus event callback
    const onFocus = (): void => {
      isOpen.value = true;
    };

    /**
     * Since we manually handle the popup's "open" state we need
     * make sure it still closes on outside-clicks
     */
    const onClickOutside = (): void => {
      if (isOpen.value) {
        evaluateAndClose();
      }
    };

    /**
     * evaluate input and close popup
     */
    const onTab = (): void => {
      evaluateAndClose();
    };

    const defaultTime = computed(() => {
      return moment(props.defaultValue, "HH:mm");
    });

    return {
      defaultTime,
      time,
      isOpen,
      moment,
      timepicker,
      onTab,
      onFocus,
      onClickOutside,
      addPopupToParent
    };
  }
});
