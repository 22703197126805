var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isActive
        ? _c("SplitScreen", {
            attrs: {
              isActive: _vm.isActive,
              isLoading: _vm.isLoading,
              "custom-class": "w-1/6"
            },
            on: { closeHandler: _vm.closeFleetDetail },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "font-extrabold" }, [
                        _vm._v(_vm._s(_vm.$t("fleet.affiliationTitle")))
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "pt-4" }, [
                        _c("div", { staticClass: "w-auto mt-4 text-2xl" }, [
                          _vm._v(" " + _vm._s(_vm.affiliation.title) + " ")
                        ]),
                        _c("div", { staticClass: "w-auto mt-8" }, [
                          _c("div", { staticClass: "text-gray-400 mb-2" }, [
                            _vm._v(_vm._s(_vm.$t("fleet.truck")))
                          ]),
                          _c(
                            "div",
                            {
                              class: {
                                "multiselect--error":
                                  _vm.errors.truck.length > 0
                              }
                            },
                            [
                              _c("multiSelect", {
                                attrs: {
                                  options: _vm.truckPreview,
                                  "custom-label": _vm.displayName,
                                  placeholder: "Select truck",
                                  label: "displayName",
                                  "track-by": "displayName",
                                  loading: _vm.truckLoading,
                                  disabled: _vm.affiliation.type === "truck"
                                },
                                on: { input: _vm.truckChanged },
                                model: {
                                  value: _vm.truckPreviewValue,
                                  callback: function($$v) {
                                    _vm.truckPreviewValue = $$v
                                  },
                                  expression: "truckPreviewValue"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.errors.truck.length > 0
                            ? _c(
                                "label",
                                { staticClass: "multiselect__error-message" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(_vm.errors.truck[0])) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          { staticClass: "w-auto mt-8" },
                          [
                            _c("div", { staticClass: "text-gray-400 mb-2" }, [
                              _vm._v(_vm._s(_vm.$t("fleet.trailer")))
                            ]),
                            _c("multiSelect", {
                              attrs: {
                                options: _vm.trailerPreview,
                                "custom-label": _vm.displayName,
                                placeholder: "Select Trailer",
                                label: "displayName",
                                "track-by": "displayName",
                                loading: _vm.trailerLoading,
                                disabled: _vm.affiliation.type === "trailer"
                              },
                              on: { input: _vm.trailerChanged },
                              model: {
                                value: _vm.trailerPreviewValue,
                                callback: function($$v) {
                                  _vm.trailerPreviewValue = $$v
                                },
                                expression: "trailerPreviewValue"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actionBar",
                  fn: function(ref) {
                    var close = ref.close
                    return [
                      _c("div", { staticClass: "w-full flex" }, [
                        _c(
                          "div",
                          { staticClass: "ml-auto flex" },
                          [
                            _c(
                              "Button",
                              {
                                staticClass: "mr-4",
                                attrs: { variant: "secondary" },
                                on: {
                                  click: function($event) {
                                    return close()
                                  }
                                }
                              },
                              [
                                _vm.affiliationWasModified
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("actions.cancel")))
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("actions.close")))
                                    ])
                              ]
                            ),
                            _c(
                              "Button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.affiliationWasModified,
                                    expression: "affiliationWasModified"
                                  }
                                ],
                                on: { click: _vm.saveChanges }
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.save")))]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              3488151920
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }