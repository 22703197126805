<template>
  <div>
    <div class="border-b-2 font-semibold mb-2 border-blue-400 text-blue-400 w-full">
      {{ driver.firstName }} {{ driver.lastName }}
    </div>
    <table class="driverCallout__table table-fixed text-left w-full mb-6">
      <thead>
        <tr>
          <th class="w-1/3">{{ $t("driver.department") }}</th>
          <th class="w-1/3">{{ $t("driver.timespan") }}</th>
          <th class="w-1/3">{{ $t("driver.dayShifts") }}</th>
        </tr>
      </thead>
      <tbody class="font-light align-top">
        <tr v-if="driver.shifts.length === 0">
          <td>{{ driver.baseBoardId }}</td>
        </tr>
        <tr v-for="(shift, index) in driver.shifts" :key="index">
          <td>{{ (index === 0 && driver.baseBoardId) || "" }}</td>
          <td>
            <span v-if="!hideTimeframe(shift.fromDate, shift.toDate)"> {{ shift.fromDate }} - {{ shift.toDate }}</span>
          </td>
          <td>
            <span v-if="!hideTimeframe(shift.fromDate, shift.toDate)">
              {{ shift.startTime }} - {{ shift.endTime }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>

    <table class="driverCallout__table table-fixed text-left w-full">
      <thead class="font-light">
        <tr>
          <th class="w-1/3">{{ $t("driver.phoneNumber") }}</th>
        </tr>
      </thead>
      <tbody class="align-top">
        <tr>
          <td>
            <div class="flex space-x-3">
              <span>{{ driver.phoneNumber }}</span>
              <CopyToClipboard v-if="driver.phoneNumber" width="18" height="18" :text="driver.phoneNumber" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { ScheduledDriver } from "@/types/fleet";
import { dateStringToShowDate, HOUR_SEPARATOR, toLocalOrDefault } from "@/use/useDate";
import { sortBy } from "@/use/useObject";
import CopyToClipboard from "@/components/CopyToClipboard.vue";

export default defineComponent({
  name: "CalloutSideBarDriver.vue",
  components: {
    CopyToClipboard
  },
  props: {
    content: {
      type: Object as PropType<ScheduledDriver>,
      required: true
    }
  },
  setup(props) {
    const eternityMarker = "";
    const dayShiftMarker = "00:00";

    const stripSeconds = (hourStamp?: string): string | undefined => {
      if (hourStamp == null) {
        return undefined;
      }

      if (hourStamp.split(HOUR_SEPARATOR).length < 3) {
        return hourStamp;
      }

      return hourStamp.substr(0, hourStamp.lastIndexOf(HOUR_SEPARATOR));
    };

    const driver = computed<ScheduledDriver>(() => {
      const { shifts } = props.content as ScheduledDriver;
      const displayShifts = shifts
        .map(shift => {
          const { fromDate, startTime, toDate, endTime } = shift;
          const displayFromDate = toLocalOrDefault(fromDate, eternityMarker);
          const displayToDate = toLocalOrDefault(toDate, eternityMarker);
          const displayStartTime = stripSeconds(startTime) || dayShiftMarker;
          const displayEndTime = stripSeconds(endTime) || dayShiftMarker;
          return {
            fromDate: displayFromDate !== eternityMarker ? dateStringToShowDate(displayFromDate) : eternityMarker,
            toDate: displayToDate !== eternityMarker ? dateStringToShowDate(displayToDate) : eternityMarker,
            startTime: displayStartTime,
            endTime: displayEndTime
          };
        })
        .sort(sortBy("fromDate"));

      return {
        ...props.content,
        shifts: displayShifts
      };
    });

    const hideTimeframe = (fromDate: string | undefined, toDate: string | undefined) => {
      return fromDate == null && toDate == null;
    };

    return {
      driver,
      hideTimeframe
    };
  }
});
</script>

<style scoped lang="scss">
@use "CalloutSideBarDriver";
</style>
