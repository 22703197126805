



















































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { ScheduledDriver } from "@/types/fleet";
import { dateStringToShowDate, HOUR_SEPARATOR, toLocalOrDefault } from "@/use/useDate";
import { sortBy } from "@/use/useObject";
import CopyToClipboard from "@/components/CopyToClipboard.vue";

export default defineComponent({
  name: "CalloutSideBarDriver.vue",
  components: {
    CopyToClipboard
  },
  props: {
    content: {
      type: Object as PropType<ScheduledDriver>,
      required: true
    }
  },
  setup(props) {
    const eternityMarker = "";
    const dayShiftMarker = "00:00";

    const stripSeconds = (hourStamp?: string): string | undefined => {
      if (hourStamp == null) {
        return undefined;
      }

      if (hourStamp.split(HOUR_SEPARATOR).length < 3) {
        return hourStamp;
      }

      return hourStamp.substr(0, hourStamp.lastIndexOf(HOUR_SEPARATOR));
    };

    const driver = computed<ScheduledDriver>(() => {
      const { shifts } = props.content as ScheduledDriver;
      const displayShifts = shifts
        .map(shift => {
          const { fromDate, startTime, toDate, endTime } = shift;
          const displayFromDate = toLocalOrDefault(fromDate, eternityMarker);
          const displayToDate = toLocalOrDefault(toDate, eternityMarker);
          const displayStartTime = stripSeconds(startTime) || dayShiftMarker;
          const displayEndTime = stripSeconds(endTime) || dayShiftMarker;
          return {
            fromDate: displayFromDate !== eternityMarker ? dateStringToShowDate(displayFromDate) : eternityMarker,
            toDate: displayToDate !== eternityMarker ? dateStringToShowDate(displayToDate) : eternityMarker,
            startTime: displayStartTime,
            endTime: displayEndTime
          };
        })
        .sort(sortBy("fromDate"));

      return {
        ...props.content,
        shifts: displayShifts
      };
    });

    const hideTimeframe = (fromDate: string | undefined, toDate: string | undefined) => {
      return fromDate == null && toDate == null;
    };

    return {
      driver,
      hideTimeframe
    };
  }
});
