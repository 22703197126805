<template xmlns:div="http://www.w3.org/1999/html">
  <div
    :class="{ 'bg-yellow-50': isBoardEditModeActive }"
    class="boardTimeLineHeader w-full bg-white h-28 flex flex-col fixed top-0 mt-28"
    :style="styledObject"
  >
    <div class="flex-grow"><Ticker /></div>
    <div class="h-8">
      <BoardTimeBoxes :width-in-hours="1" :header="true" />
    </div>
    <div class="h-8">
      <BoardTimeBoxes :width-in-hours="1" />
    </div>
    <div
      v-if="isBoardEditModeActive || fleetIsLoading"
      class="h-28 w-full bg-black fixed top-100 left-200 z-40 opacity-20"
    ></div>
  </div>
</template>

<script lang="ts">
import useScrollPosition from "@/use/useScrollPosition";
import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import BoardTimeBoxes from "@/components/Board/TimeLine/TimeLineBoxes/TimeLineBoxes.vue";
import Ticker from "@/components/Board/Ticker/Ticker.vue";
import { IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { FLEET_LOADING } from "@/store/fleet";

export default defineComponent({
  name: "BoardTimeLineHeader",
  components: { BoardTimeBoxes, Ticker },
  setup() {
    const useScroll = useScrollPosition();
    const scrolledLeft = useScroll.scrolledLeft;
    const boardWidth = computed(() => store.state.board.board.offsetWidth);
    const styledObject = computed(() => {
      const style = {
        width: `${boardWidth.value}px`,
        "z-index": 19
      };
      if (scrolledLeft.value > 0) {
        const offsetLeft = 266 - scrolledLeft.value;
        return {
          ...style,
          top: 0,
          left: `${offsetLeft}px`
        };
      }
      return style;
    });

    const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const fleetIsLoading = computed(() => store.getters[FLEET_LOADING]);

    return {
      boardWidth,
      scrolledLeft,
      styledObject,
      isBoardEditModeActive,
      fleetIsLoading
    };
  }
});
</script>

<style scoped></style>
