import { IdEntity } from "@/types/types";
import { FleetAction } from "@/types/action";
import { BrickSubcontractor, Leg } from "@/types/leg";
import { BoardTimespan, OccupancyBlock } from "@/types/board";

interface BoardMembership extends BoardTimespan {
  boardId: string;
  boardType?: string;
}

interface SubcontractorBoardMembership extends BoardMembership {
  sequence: number;
  truckName?: string | null;
  driverName?: string | null;
}

interface Fleet {
  trucks: Truck[];
  subcontractors: Subcontractor[];
  drivers: Driver[];
  trailers: Trailer[];
}

enum FleetEntityType {
  TRUCK = "truck",
  DRIVER = "driver",
  TRAILER = "trailer",
  SUBCONTRACTOR = "subcontractor"
}

interface FleetEntityDescription {
  entity: string;
  name: string;
}

interface FleetEntity {
  id: string;
  moved?: boolean;
  actions?: FleetAction[];
  type: FleetEntityType;
  boards: BoardMembership[];
  baseBoardId?: string;
  isOccupied?: boolean;
  occupancyBlocks: OccupancyBlock[];
  groupGlueId?: string;
}

interface FleetEntityDetails {
  truckId?: string;
  driverId?: string;
  trailerId?: string;
  subcontractor?: BrickSubcontractor;
}

interface FleetSchedule {
  fromDate: string;
  toDate: string;
  startTime: string;
  endTime: string;
}

interface TruckDriverSchedule extends FleetSchedule {
  driver: Driver;
}

interface DriverTruckSchedule extends FleetSchedule {
  truckId: string;
}

interface Truck extends FleetEntity {
  driver: Driver;
  trailer: Trailer;
  metadata: TruckMetaData;
  crewSchedule: TruckDriverSchedule[];
  crewDriver?: Driver;
  tempDepartment?: string;
  tempGroup?: string;
}

interface Driver extends FleetEntity {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  subcontractorId?: string;
  subcontractorName?: string;
  remarks?: string;
  trucksSchedule: DriverTruckSchedule[];
}

interface DriverUpdate {
  id: string;
  remarks: string;
}

interface Subcontractor extends Omit<FleetEntity, "boards"> {
  name: string;
  role?: string;
  drivers: string[];
  boards: SubcontractorBoardMembership[];
  primaryBoardId?: string;
  flatTruckName?: string | null;
  flatDriverName?: string | null;
}

interface Trailer extends FleetEntity {
  metadata: TrailerMetadata;
}

interface TrailerLastLocation {
  leg?: Leg;
  action?: FleetAction;
  generic?: LastLocationInfo;
}

interface TrailerMetadata {
  chassisType: string;
  suitableFor?: string;
  grossWeight?: number;
  tareWeight?: number;
  nextMajorTest?: string;
  nextSafetyTest?: string;
  lastLocation?: TrailerLastLocation;
  chamberVolumes?: number[];
  nextBrakeTest?: string;
  nextADRTest?: string;
  nextAdrTest?: string;
  nextAdrMinorTest?: string;
  nextHoseTest?: string;
  nextRotaryTest?: string;
  rotaryValveBrand?: string;
  additionalCooler?: boolean;
  foodApproved?: boolean;
}

interface Container {
  id?: string;
  metadata?: ContainerMetadata;
  name?: string;
}
interface ContainerMetadata {
  acceptance?: string;
  anchorChain?: string;
  dipTube?: string;
  chamberVolumes?: number[];
  heatingMethod?: string;
  groundOperatedContainer?: string;
  baffles?: string;
  dedicatedProductGroup: string;
  isoDimension?: string;
  markedForRepair?: boolean;
  nextCscTest: string;
  tankCode: string;
  tareWeight: number;
  length?: number;
  nexxiotLink?: string;
  nextAdrTest: string;
  nextAdrMinorTest: string;
}

interface TrailerMeta {
  id: string;
  name?: string;
}

type TrailersSelectedProfile = {
  activeBoardId: string[];
  trailersSelected?: TrailerMeta[];
};

interface TruckMetaData {
  suitableFor: string;
  grossWeight: 0;
  tareWeight: 0;
  nextMajorTest?: string;
  nextSafetyTest?: string;
  nextBrakeTest?: string;
  nextTachoTest?: string;
  nextADRTest?: string;
  nextHydraulicHosesTest?: string;
  blower?: string;
  compressor?: string;
  pump?: string;
  hydraulic?: string;
  maponLink?: string;
  euroClass?: string;
  foodApproved?: true;
}

interface ScheduledDriver extends Driver {
  shifts: FleetSchedule[];
}

interface FleetEntityTest {
  name: string;
  date: string;
  isDateInPast: boolean;
}

interface AffiliationRequest extends Truck {
  driverId?: string | null;
  trailerId?: string | null;
}

interface FleetPayload {
  boardIds: string[];
  from: string;
  to: string;
}

interface FleetPreviews {
  previews: Preview[];
}

interface Preview extends IdEntity<string> {
  displayName: string;
}

type OrderedFleetIds = {
  directFleetIndices: Map<string, number[]>;
  nestedFleetIndices: Map<string, number[]>;
};

interface LastBookingsBricks {
  leg?: Leg;
  actions?: FleetAction;
}

interface LastBookings {
  truck?: LastBookingsBricks;
  driver?: LastBookingsBricks;
  trailer?: LastBookingsBricks;
}

interface LastBookingsFilter {
  truckId?: string;
  driverId?: string;
  trailerId?: string;
  before: string;
}

interface LastLocationData {
  brickData: Leg | FleetAction | null;
  brickId: string | null;
  brickLocation: string | null;
  brickTimestamp: string | null;
  fleetEntityName: string;
  fleetEntityType: string;
  fleetEntityOrdinal: number;
  isLegBrick: boolean;
  lastMovement?: string;
}

type CompositeSubcontractorId = {
  id: string;
  boardId: string;
  sequence: number;
};

type CompositeTruckId = {
  id: string;
  driverId?: string;
};

interface FleetIdentifier {
  id: string;
  type: string;
}

interface LastLocationInfo {
  name: string;
  date: string;
  containerId?: string;
  lastMovement?: string;
  dropWithTrailer: boolean;
}

export {
  Fleet,
  FleetPayload,
  Truck,
  Subcontractor,
  Driver,
  DriverUpdate,
  Trailer,
  TruckMetaData,
  FleetPreviews,
  Preview,
  AffiliationRequest,
  TrailerMetadata,
  OrderedFleetIds,
  LastBookingsFilter,
  LastBookings,
  LastLocationData,
  FleetEntityType,
  CompositeSubcontractorId,
  FleetEntity,
  FleetEntityDetails,
  SubcontractorBoardMembership,
  FleetEntityDescription,
  FleetIdentifier,
  Container,
  BoardMembership,
  CompositeTruckId,
  TruckDriverSchedule,
  DriverTruckSchedule,
  FleetSchedule,
  FleetEntityTest,
  ScheduledDriver,
  TrailerLastLocation,
  TrailerMeta,
  TrailersSelectedProfile,
  LastLocationInfo
};
