<template>
  <div class="relative w-full h-full bg-gray-100 form mb-8">
    <form @submit.prevent>
      <div class="form__head flex items-center">
        <Icon name="services" width="20" height="20" class="mr-1" />
        {{ $t("form.actionForm.title") }}
      </div>
      <div class="form__body">
        <div class="flex mb-8">
          <div class="w-1/2 mr-6">
            <label>{{ $t("form.reason") }}</label>
            <multiSelect
              class="mt-1"
              v-model="actionType"
              :options="actionTypes"
              track-by="id"
              label="displayName"
              :placeholder="$t('form.selectReason')"
              :selectLabel="$t('actions.select')"
              :deselectLabel="$t('actions.remove')"
              @input="onActionTypeChange"
            />
            <label v-if="!form.type && form.hasData" class="label__error">
              {{ $t("form.required") }}
            </label>
          </div>
          <div class="w-1/2">
            <div class="flex flex-col">
              <label class="mb-1">{{ $t("form.remarks") }}</label>
              <a-input v-model="form.remarks" :placeholder="$t('form.descriptionPlaceholder')" size="large" />
            </div>
            <label v-if="form.type === 'VARIOUS' && form.hasData && !form.remarks" class="label__error">
              {{ $t("form.required") }}
            </label>
          </div>
        </div>
        <div class="form__section">
          <div class="form__section__title">{{ $t("form.dateAndTime") }}</div>
          <div class="flex">
            <div class="w-1/2 mr-6 flex">
              <div class="mr-4 w-1/2">
                <div class="flex flex-col">
                  <label class="mb-1">{{ $t("dates.fromDate") }}</label>
                  <fleet-forms-date-picker
                    v-model="form.fromDate"
                    :default-date="defaultDates.from"
                    @input="fromDateInput"
                  />
                </div>
                <label v-if="!form.fromDate && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
              </div>
              <div class="w-1/2">
                <div class="flex flex-col">
                  <label class="mb-1">{{ $t("form.timeFrom") }}</label>
                  <fleet-forms-time-picker v-model="form.fromTime" :default-value="defaultTimes.from" />
                </div>
                <label v-if="!form.fromTime && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
              </div>
            </div>
            <div class="w-1/2 flex">
              <div class="mr-4 w-1/2">
                <div class="flex flex-col">
                  <label class="mb-1">{{ $t("dates.toDate") }}</label>
                  <fleet-forms-date-picker v-model="form.toDate" :default-date="defaultDates.to" @input="toDateInput" />
                </div>
                <label v-if="!form.toDate && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
              </div>
              <div class="w-1/2">
                <div class="flex flex-col">
                  <label class="mb-1">{{ $t("form.timeTo") }}</label>
                  <fleet-forms-time-picker v-model="form.toTime" :default-value="defaultTimes.to" />
                </div>
                <label v-if="!form.toTime && form.hasData" class="label__error">
                  {{ $t("form.required") }}
                </label>
                <label v-if="hasTimeError" class="label__error block">
                  {{ $t("errors.IMPOSSIBLE_TIME_RANGE") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form__section">
          <div class="form__section__title">{{ $t("form.location") }}</div>
          <div class="flex">
            <div class="w-1/2 mr-6">
              <div class="flex flex-col">
                <label>{{ $t("ranges.from") }}</label>
                <multiSelect
                  v-model="locationFrom"
                  :options="fromLocations"
                  track-by="id"
                  label="displayName"
                  :placeholder="$t('form.selectFromLocation')"
                  :selectLabel="$t('actions.select')"
                  :deselectLabel="$t('actions.remove')"
                  @input="onLocationFromChanged"
                />
              </div>
            </div>

            <div class="w-1/2">
              <div class="flex flex-col">
                <label>{{ $t("ranges.toLocation") }}</label>
                <multiSelect
                  v-model="locationTo"
                  :options="toLocations"
                  track-by="id"
                  label="displayName"
                  :placeholder="$t('form.selectToLocation')"
                  :selectLabel="$t('actions.select')"
                  :deselectLabel="$t('actions.remove')"
                  @input="onLocationToChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import multiSelect from "vue-multiselect";
import Button from "@/components/Buttons/Button.vue";
import Icon from "@/components/Icon.vue";
import fleetService from "@/services/fleetService";
import fleetFormsDatePicker from "@/components/Board/Fleet/FleetAction/FleetForms/FleetFormsDatePicker.vue";
import fleetFormsTimePicker from "@/components/Board/Fleet/FleetAction/FleetForms/FleetFormsTimePicker.vue";
import { Preview } from "@/types/fleet";
import { FleetActionFormData, FleetActionFormDataInternal } from "@/types/action";
import { setAsFirstIfMatching } from "@/use/useArray";
import { isAfter, isBefore, isSameDay, parseISO } from "date-fns";
import { hasValues } from "@/use/useObject";
import { dateStringToShowTime } from "@/use/useDate";
import moment from "moment";

export default defineComponent({
  name: "FleetActionForm",
  components: {
    fleetFormsDatePicker,
    fleetFormsTimePicker,
    multiSelect,
    Button,
    Icon
  },
  props: {
    formData: {
      type: Object as () => FleetActionFormData
    }
  },
  setup(props, { emit }) {
    const form = reactive<FleetActionFormDataInternal>({
      type: undefined,
      remarks: undefined,
      fromTime: undefined,
      toTime: undefined,
      fromLocation: undefined,
      toLocation: undefined,
      fromDate: undefined,
      toDate: undefined,
      hasData: computed(() => hasValues(form, ["hasData", "fromTime", "toTime"]))
    });

    const hasTimeError = ref<boolean>(false);

    const formIsValid = computed(() => {
      if (form.hasData) {
        if (!form?.type || !form?.fromDate || !form?.fromTime || !form?.toDate || !form?.toTime) {
          return false;
        }
        if (isSameDay(form.fromDate, form.toDate) && form.fromTime.isSameOrAfter(form.toTime)) {
          hasTimeError.value = true;
          return false;
        }
        hasTimeError.value = false;
        return !(form.type === "VARIOUS" && !form.remarks);
      }
      hasTimeError.value = false;
    });

    const fromLocations = ref<Preview[]>([]);
    const toLocations = ref<Preview[]>([]);
    const locationFrom = ref<Preview | null>(null);
    const locationTo = ref<Preview | null>(null);
    const actionType = ref<Preview | null>(null);
    const actionTypes = ref<Preview[]>([]);

    const getActionTypes = async () => {
      try {
        const { data } = await fleetService.getActionTypesPreview();
        actionTypes.value = data.previews;

        if (props.formData?.type) {
          actionType.value = actionTypes.value.find(actionType => actionType.id === props.formData?.type) || null;
        }
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const onActionTypeChange = (actionType?: Preview | null) => {
      form.type = actionType?.id;
      if (actionType?.id != null) {
        actionTypes.value = setAsFirstIfMatching(actionTypes.value, actionType.id);
      }
    };

    const getLocations = async () => {
      try {
        const { data } = await fleetService.getLocationsPreview();
        fromLocations.value = data.previews;
        toLocations.value = data.previews;

        if (props.formData != null) {
          const { fromLocation, toLocation } = props.formData;
          locationFrom.value = fromLocations.value.find(location => location.id === fromLocation?.id) || null;
          locationTo.value = toLocations.value.find(location => location.id === toLocation?.id) || null;
        }
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const onLocationFromChanged = async (location?: Preview | null) => {
      try {
        const { data } = await fleetService.getLocationById(location!.id!);
        form.fromLocation = data;
        fromLocations.value = setAsFirstIfMatching(fromLocations.value, data.id);
        emit("form-change", form);
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const onLocationToChanged = async (location?: Preview | null) => {
      try {
        const { data } = await fleetService.getLocationById(location!.id!);
        form.toLocation = data;
        toLocations.value = setAsFirstIfMatching(toLocations.value, data.id);
        emit("form-change", form);
      } catch (e) {
        //show errors which are not handled globally
      }
    };

    const fromDateInput = () => {
      if (form.fromDate == null || form.toDate == null) {
        return;
      }
      if (isAfter(form.fromDate, form.toDate)) {
        form.toDate = form.fromDate;
      }
    };

    const toDateInput = () => {
      if (form.fromDate == null || form.toDate == null) {
        return;
      }
      if (isBefore(form.toDate, form.fromDate)) {
        form.fromDate = form.toDate;
      }
    };

    const initializeForm = () => {
      const { type, remarks, fromDate, fromTime, toDate, toTime, fromLocation, toLocation } = props.formData || {};

      form.fromTime = fromTime ? moment(fromTime) : null;
      form.toTime = toTime ? moment(toTime) : null;
      form.fromDate = fromDate ? parseISO(fromDate) : undefined;
      form.toDate = toDate ? parseISO(toDate) : undefined;

      if (props.formData == null || props.formData.type == null) {
        return;
      }

      form.type = type;
      form.remarks = remarks;
      form.fromDate = parseISO(fromDate || "");
      form.fromTime = (fromTime && moment(fromTime)) || null;
      form.toDate = parseISO(toDate || "");
      form.toTime = (toTime && moment(toTime)) || null;
      form.fromLocation = fromLocation;
      form.toLocation = toLocation;
    };

    watch(
      () => ({ ...form }),
      () => {
        emit("form-change", form);
        emit("form-is-valid", formIsValid.value);
      }
    );

    watch(
      () => props.formData,
      () => {
        initializeForm();
      }
    );

    onMounted(() => {
      getLocations();
      getActionTypes();
      initializeForm();
    });

    const defaultDates = computed(() => {
      const { fromDate, toDate } = props.formData || {};
      return {
        from: fromDate ? moment(fromDate) : null,
        to: toDate ? moment(toDate) : null
      };
    });

    const defaultTimes = computed(() => {
      const { fromTime, toTime } = props.formData || {};
      return {
        // from: fromTime ? moment(fromTime).format("HH:mm") : "00:00",
        // to: fromTime ? moment(toTime).format("HH:mm") : "23:59"
        from: fromTime ? dateStringToShowTime(fromTime) : "00:00",
        to: toTime ? dateStringToShowTime(toTime) : "23:59"
      };
    });

    return {
      defaultTimes,
      defaultDates,
      actionTypes,
      actionType,
      locationFrom,
      locationTo,
      form,
      formIsValid,
      toLocations,
      fromLocations,
      hasTimeError,
      fromDateInput,
      toDateInput,
      onLocationFromChanged,
      onLocationToChanged,
      onActionTypeChange
    };
  }
});
</script>

<style scoped></style>
