const INPUT_FIELD = "INPUT";
const TEXTAREA_FIELD = "TEXTAREA";

const isTextArea = (e: KeyboardEvent): boolean => {
  return e.target!["tagName"].toUpperCase() === TEXTAREA_FIELD;
};

const isInputField = (e: KeyboardEvent): boolean => {
  return e.target!["tagName"].toUpperCase() === INPUT_FIELD;
};
export { isTextArea, isInputField };
