import { render, staticRenderFns } from "./TimeLineBoxes.vue?vue&type=template&id=385c56cc&scoped=true"
import script from "./TimeLineBoxes.vue?vue&type=script&lang=ts"
export * from "./TimeLineBoxes.vue?vue&type=script&lang=ts"
import style0 from "./TimeLineBoxes.vue?vue&type=style&index=0&id=385c56cc&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385c56cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('385c56cc')) {
      api.createRecord('385c56cc', component.options)
    } else {
      api.reload('385c56cc', component.options)
    }
    module.hot.accept("./TimeLineBoxes.vue?vue&type=template&id=385c56cc&scoped=true", function () {
      api.rerender('385c56cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Board/TimeLine/TimeLineBoxes/TimeLineBoxes.vue"
export default component.exports