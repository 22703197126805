var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "buttonIcon bg-btYellow flex-shrink-0 cursor-pointer transition-colors duration-300 hover:bg-yellow-500 ml-2",
      class: { "filterButton--disabled": _vm.disabled },
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.onClick }
    },
    [
      _c(
        "a-tooltip",
        {
          attrs: {
            placement: "bottom",
            align: { offset: [0, 3] },
            title: _vm.tooltip
          }
        },
        [_c("Icon", { key: "1", attrs: { name: _vm.icon } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }