<template>
  <div>
    <a-switch size="large" @change="onWeekendToggleChange" :checked="showWeekend" class="mr-2" /><span
      class="text-base"
      >{{ $t("showWeekend") }}</span
    >
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import { GET_SHOW_WEEKENDS, SET_BOARD, TOGGLE_SHOW_WEEKENDS, UPDATE_CONFIG_PROFILE } from "@/store/board";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { CLEAR_FLEET, FETCH_BOARD_FLEET } from "@/store/fleet";

export default defineComponent({
  name: "ShowWeekend",
  setup(props, { emit }) {
    const showWeekend = computed(() => store.getters[GET_SHOW_WEEKENDS]);

    const onWeekendToggleChange = async () => {
      emit("isLoading", true);
      await store.dispatch(TOGGLE_SHOW_WEEKENDS);
      store.commit(SET_BOARD);
      await store.dispatch(UPDATE_CONFIG_PROFILE);
      await store.commit(CLEAR_FLEET);
      await store.commit(RESET_LEGS);
      await store.commit(RESET_FLEET_ACTIONS);
      await store.dispatch(FETCH_BOARD_FLEET);
      emit("isLoading", false);
    };
    return {
      onWeekendToggleChange,
      showWeekend
    };
  }
});
</script>

<style scoped></style>
