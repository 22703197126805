import { IdEntity } from "@/types/types";

const setAsFirstIfMatching = <T, R extends IdEntity<T>>(entities: R[], idToMatch?: T): R[] => {
  if (typeof idToMatch === "undefined") {
    return entities;
  }
  entities.unshift(
    entities.splice(
      entities.findIndex(preview => preview.id === idToMatch),
      1
    )[0]
  );
  return entities;
};

const addIfNotExist = <T>(array: T[], element: any, fieldsToMatch: string[]): T[] => {
  if (array == null || array.length === 0) {
    return [element];
  }

  const matchingElement = array.find(elem => {
    return fieldsToMatch.reduce<boolean>((acc, field) => {
      return acc && elem[field] === element[field];
    }, true);
  });

  return matchingElement != null ? array : [...array, element];
};

const findLastIndex = <T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number => {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) {
      return l;
    }
  }
  return -1;
};

export { setAsFirstIfMatching, addIfNotExist, findLastIndex };
