






















































































































import { defineComponent } from "@vue/composition-api";
import { DisplayLegContent, DisplayLegStatuses, LegCallbacks } from "@/types/leg";
import ClosablePopover from "@/components/ClosablePopover.vue";
import LegTransportStatusIndicator from "@/components/Board/Leg/LegTransportStatusIndicator/LegTransportStatusIndicator.vue";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import Icon from "@/components/Icon.vue";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";

export default defineComponent({
  name: "LegBreakpointXL",
  components: {
    ClosablePopover,
    ChatNotifications,
    LegTransportStatusIndicator,
    CalloutLegContainer,
    Icon
  },
  props: {
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    legStatuses: {
      type: Object as () => DisplayLegStatuses,
      required: true
    },
    legActionCallbacks: {
      type: Object as () => LegCallbacks,
      required: true
    },
    onTouchStart: {
      type: Function,
      default: () => ({})
    },
    showChatNotificationIcon: {
      type: Boolean,
      required: true
    }
  }
});
