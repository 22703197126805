


























































































import { defineComponent, inject, computed } from "@vue/composition-api";
import { FleetEntityType, Trailer, Truck, Driver, Subcontractor } from "@/types/fleet";
import useBoardBrickCalculus from "@/components/Board/Use/useBoardBrickCalculus";
import useScrollPosition from "@/use/useScrollPosition";
import { BoardBrickType, SubcontractorBoard } from "@/types/board";
import moment from "moment";
import { spreadSubcontractorId, spreadTruckId, spreadTrailerId } from "@/use/useFleet";
import store from "@/store";
import {
  ADD_ACTION_TO_STORE,
  GET_COPIED_ACTION,
  GET_DIRTY_FLEET_ACTION_ID,
  GET_DIRTY_LEG_ID,
  SET_COPIED_ACTION
} from "@/store/brick";
import { translateAndToastSuccess, translateAndToastWarn } from "@/use/useToast";
import { toLocalDateTime } from "@/use/useDate";
import { brickAssignableToFleetEntity } from "@/use/useBrick";
import { BoardFleetAction } from "@/types/action";

export default defineComponent({
  name: "TimelineContextOptions",
  props: {
    closeContextMenu: {
      type: Function,
      required: true
    },
    boardReference: {
      type: HTMLElement,
      required: true
    },
    mouseEvent: {
      type: MouseEvent,
      required: true
    }
  },
  setup(props) {
    const showSplitScreen: any = inject("showSplitScreen");

    const { getBrickFleetEntity, getStartCoordinateTimestamp } = useBoardBrickCalculus();
    const { scrolledTop, scrolledLeft } = useScrollPosition();

    const getTargetFleetEntity = (e: MouseEvent) => {
      const boardOffsetY = props.boardReference.offsetTop - scrolledTop.value;
      return getBrickFleetEntity(e.clientY - boardOffsetY - 100);
    };

    const actionReminderTimestamps = computed(() => {
      const boardOffsetX = props.boardReference.offsetLeft - scrolledLeft.value;
      const startTimestampString = getStartCoordinateTimestamp(props.mouseEvent.clientX - boardOffsetX);
      return {
        from: moment(startTimestampString),
        to: moment(startTimestampString).add(3, "hours")
      };
    });

    const targetFleetEntity = computed(() => {
      return getTargetFleetEntity(props.mouseEvent);
    });

    const isEditMode = computed(() => store.getters[GET_DIRTY_LEG_ID] || store.getters[GET_DIRTY_FLEET_ACTION_ID]);

    const isTruck = computed(() => targetFleetEntity.value?.type === FleetEntityType.TRUCK);
    const isTrailer = computed(() => targetFleetEntity.value?.type === FleetEntityType.TRAILER);
    const isDriver = computed(() => targetFleetEntity.value?.type === FleetEntityType.DRIVER);
    const isSubcontractor = computed(() => targetFleetEntity.value?.type === FleetEntityType.SUBCONTRACTOR);

    const hasTruck = computed(() => isTruck.value);

    const hasDriver = computed(() => {
      if (isTruck.value) {
        const { crewDriver } = (targetFleetEntity.value || {}) as Truck;
        return !!crewDriver;
      }
      return isDriver.value;
    });

    const hasTrailer = computed(() => {
      if (isTruck.value) {
        const { trailer } = (targetFleetEntity.value || {}) as Truck;
        return !!trailer;
      }
      return isTrailer.value;
    });

    const hasSubcontractor = computed(() => isSubcontractor.value);

    const basePayloadConfig = (isReminder: boolean) => {
      return {
        payloadType: BoardBrickType.ACTION,
        fromDate: { date: actionReminderTimestamps.value.from },
        toDate: { date: actionReminderTimestamps.value.to },
        isReminder: isReminder
      };
    };

    const onOptionClick = (entity, isReminder) => {
      if (isTruck.value) {
        const truck = targetFleetEntity.value as Truck;
        const payload = {
          truckId: FleetEntityType.TRUCK === entity ? spreadTruckId(truck.id).id : undefined,
          trailerId: FleetEntityType.TRAILER === entity ? truck.trailer.id : undefined,
          driverId: FleetEntityType.DRIVER === entity ? truck.crewDriver?.id : undefined,
          ...basePayloadConfig(isReminder)
        };
        showSplitScreen(payload);
      } else if (isTrailer.value) {
        const trailer = targetFleetEntity.value as Trailer;
        const payload = {
          trailerId: spreadTrailerId(trailer.id).id,
          ...basePayloadConfig(isReminder)
        };
        showSplitScreen(payload);
      } else if (isDriver.value) {
        const driver = targetFleetEntity.value as Driver;
        const payload = {
          driverId: driver.id,
          ...basePayloadConfig(isReminder)
        };
        showSplitScreen(payload);
      } else if (isSubcontractor.value) {
        const subcontractor = targetFleetEntity.value as Subcontractor;
        const payload = {
          subcontractorId: subcontractor.id,
          ...basePayloadConfig(isReminder)
        };
        showSplitScreen(payload);
      }
      (props.closeContextMenu as Function)();
    };

    const getSelectedAction: any = inject("getSelectedAction");

    const copyAction = () => {
      const action = getSelectedAction();

      if (action) {
        try {
          store.commit(SET_COPIED_ACTION, {
            ...action,
            entityType: actionEntityType(action)
          });
          const toastMessage = action.isReminder ? "actionReminder.reminderCopied" : "actionReminder.actionCopied";
          translateAndToastSuccess(toastMessage);
        } catch (e) {
          translateAndToastWarn("errors.ACTION_NOT_COPIED");
        }
      }
      (props.closeContextMenu as Function)();
    };

    const isActionSelected = computed(() => !!getSelectedAction());

    const hasCopiedAction = computed(() => !!store.getters[GET_COPIED_ACTION]);

    const pasteAction = () => {
      const action = store.getters[GET_COPIED_ACTION];

      if (!action) {
        return;
      }

      const actionEntityId = retrieveActionEntityId(action.entityType);
      const isFleetValid = brickAssignableToFleetEntity(action.entityType, {
        ...actionEntityId,
        id: "",
        isReminder: action.isReminder,
        brickType: BoardBrickType.ACTION
      } as BoardFleetAction);

      if (isFleetValid) {
        const originalStart = moment(action.fromDate.date);
        const originalEnd = moment(action.toDate.date);
        const copiedActionDuration = moment.duration(originalEnd.diff(originalStart));

        const copiedActionReminderNewEndDate = moment(actionReminderTimestamps.value.from)
          .add(copiedActionDuration.years(), "years")
          .add(copiedActionDuration.months(), "months")
          .add(copiedActionDuration.days(), "days")
          .add(copiedActionDuration.hours(), "hours")
          .add(copiedActionDuration.minutes(), "minutes")
          .add(copiedActionDuration.seconds(), "seconds")
          .toDate();

        const payload = {
          ...action,
          fromDate: {
            date: toLocalDateTime(moment(actionReminderTimestamps.value.from).toDate()),
            timezone: "UTC"
          },
          toDate: {
            date: toLocalDateTime(copiedActionReminderNewEndDate),
            timezone: "UTC"
          },
          ...actionEntityId
        };

        if (action.entityType === FleetEntityType.SUBCONTRACTOR) {
          const spreadSubiId = spreadSubcontractorId(actionEntityId?.subcontractor?.id!);
          payload.subcontractor = {
            id: spreadSubiId.id,
            primaryBoard: {
              id: spreadSubiId.boardId,
              sequence: spreadSubiId.sequence
            } as SubcontractorBoard
          };
        }

        try {
          store.dispatch(ADD_ACTION_TO_STORE, payload);
        } catch (e) {
          translateAndToastWarn("errors.ACTION_NOT_PASTED");
        }
      }
      (props.closeContextMenu as Function)();
    };

    const actionEntityType = (action: any) => {
      if (action.truckId) {
        return FleetEntityType.TRUCK;
      } else if (action.trailerId) {
        return FleetEntityType.TRAILER;
      } else if (action.driverId) {
        return FleetEntityType.DRIVER;
      } else if (action.subcontractor) {
        return FleetEntityType.SUBCONTRACTOR;
      }
    };

    const retrieveActionEntityId = (entityType: FleetEntityType) => {
      if (isTruck.value) {
        const truck = targetFleetEntity.value as Truck;
        switch (entityType) {
          case FleetEntityType.TRUCK:
            return {
              truckId: spreadTruckId(truck.id).id ?? undefined
            };
          case FleetEntityType.TRAILER:
            return {
              trailerId: truck.trailer?.id ?? undefined
            };
          case FleetEntityType.DRIVER:
            return {
              driverId: truck.crewDriver?.id ?? undefined
            };
        }
      } else if (isTrailer.value) {
        return {
          trailerId: (targetFleetEntity.value as Trailer).id ?? undefined
        };
      } else if (isDriver.value) {
        return {
          driverId: (targetFleetEntity.value as Driver).id
        };
      } else if (isSubcontractor.value) {
        return {
          subcontractor: targetFleetEntity.value as Subcontractor
        };
      }
    };

    return {
      onOptionClick,
      hasTruck,
      hasTrailer,
      hasDriver,
      hasSubcontractor,
      FleetEntityType,
      copyAction,
      isActionSelected,
      hasCopiedAction,
      pasteAction,
      isEditMode
    };
  }
});
