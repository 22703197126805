enum GroundOperatedContainerTypes {
  NO = "no",
  YES = "yes",
  GO1 = "GO1",
  GO2 = "GO2",
  GOC = "GOC",
  GOC1 = "GOC1",
  GOC2 = "GOC2"
}

enum DipTubeTypes {
  NO = "NO",
  YES = "YES",
  POSSIBLE = "POSSIBLE"
}

enum IsoDimensionTypes {
  NO = "false",
  YES = "true",
  GLISO = "GL ISO"
}

const BaffleTypes = {
  NO: "NO",
  YES: "YES",
  BAFFLE_GROUPS: ["1", "2", "3", "4", "5", "K1/K3", "K3", "YES"]
};

export { GroundOperatedContainerTypes, DipTubeTypes, IsoDimensionTypes, BaffleTypes };
