import { DateTimezone } from "@/types/types";
import { BoardBrick, SubcontractorBoard } from "@/types/board";
import moment from "moment";

interface LegsResponse {
  legs: Leg[];
}

interface LegProduct {
  id: string;
  name?: string;
  number?: number;
  metadata?: LegProductMetadata;
}

interface LegProductMetadata {
  productGroup?: string;
  unnr?: string;
  kemlerNumber?: string;
  packagingGroup?: string;
  adrClass?: string;
  tunnelRestriction?: string;
}

interface LegContainerMetadata {
  tareWeight?: number;
  length?: number;
  chamberVolumes: number[];
  nexxiotLink?: string;
  nextAdrTest: string;
  nextAdrMinorTest: string;
  baffles: string;
  groundOperatedContainer: string;
  heatingMethod: string;
  dipTube: string;
  isoDimension: string;
  anchorChain: string;
  dedicatedProductGroup?: string;
  acceptance?: string;
  markedForRepair?: boolean;
  nextCscTest?: string;
  tankCode?: string;
}

interface LegContainer {
  id: string;
  name: string;
  metadata?: LegContainerMetadata;
  containerId?: string;
}

interface LegDetails {
  remarks?: string;
  orderedWeight?: number;
  loadedWeight?: number;
  senderReference?: string;
  consigneeReference?: string;
  ttaLink?: string;
  invoicee?: string;
  initialContainerLoadState?: string;
  unloadingTempFrom?: number;
  unloadingTempTo?: number;
  flexible?: boolean;
  aborted?: boolean;
  neutral?: string;
  dropWithTrailer?: boolean;
  fillingRate?: number;
  orderedVolume?: number;
  loadedVolume?: number;
  loadingInfo?: string[];
  unloadingInfo?: string[];
  containerSize?: string;
  tankBoxDesign?: string;
  highCube?: string;
  subconTruckName?: string;
  subconDriverName?: string;
  isLoadingRelated?: boolean;
  isUnloadingRelated?: boolean;
  issues?: LegIssue[];
}

interface LegIssue {
  messages: string[];
}

interface LegTransport {
  customs?: LegTransportDetail[];
  documents?: LegTransportDetail[];
  specialCustoms?: LegTransportDetail[];
  specialEquipments?: LegTransportDetail[];
  temperatures?: LegTransportDetail[];
}

interface LegTransportDetail {
  checked: boolean;
  text: string;
}

interface LegTransportChecklist extends LegTransport {
  status?: string;
}

interface LegTransportStatuses {
  hasCustoms: boolean;
  hasPendingIssues: boolean;
  hasPendingCustoms: boolean;
  hasPendingSpecialCustoms: boolean;
  hasPendingDocuments: boolean;
  hasPendingSpecialEquipments: boolean;
  hasPendingTemperatures: boolean;
}

interface LegStop {
  id: string;
  code: string;
  name: string;
  point?: string;
  timezone: string;
  longName?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
}

interface LegStops {
  from: LegStop;
  to: LegStop;
  loading?: LegStop;
  unloading?: LegStop;
}

interface LegEventTimeSlot {
  from?: string;
  to?: string;
}

enum WeekDays {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY"
}

interface LegOpeningHours {
  [key: string]: LegEventTimeSlot[];
}

interface LegEvents {
  plannedDepartureDate?: DateTimezone;
  plannedArrivalDate?: DateTimezone;
  effectiveDepartureDate?: DateTimezone;
  effectiveArrivalDate?: DateTimezone;
  plannedLoading?: DateTimezone;
  plannedUnloading?: DateTimezone;
  loadingArrivedAtGate?: DateTimezone;
  unloadingArrivedAtGate?: DateTimezone;
  loadingCheckOut?: DateTimezone;
  unloadingCheckOut?: DateTimezone;
  loadingSlotFrom?: string;
  loadingSlotTo?: string;
  unloadingSlotFrom?: string;
  unloadingSlotTo?: string;
  loadingOpeningHours?: LegOpeningHours;
  unloadingOpeningHours?: LegOpeningHours;
}

interface PreviousLeg {
  legType?: string;
  effectiveArrivalDate?: DateTimezone;
  plannedArrivalDate?: DateTimezone;
  effectiveDepartureDate?: DateTimezone;
  intermodalVehicleNumber?: string;
  hasIntermodalIrregularities?: boolean;
  irregularityDescription?: string;
  booking?: Booking;
  product?: LegProduct;
  from?: LegStop;
  to?: LegStop;
}

interface BrickSubcontractor {
  id: string;
  subconDriverName?: string | null;
  subconTruckName?: string | null;
  primaryBoard?: SubcontractorBoard;
  secondaryBoard?: SubcontractorBoard;
}

interface Leg {
  id: string;
  type: string;
  orderNumber: string;
  orderNumberAlt?: string;
  booking?: Booking;
  boards?: string[];
  truckId?: string | null;
  driverId?: string | null;
  trailerId?: string | null;
  subcontractor?: BrickSubcontractor | null;
  product?: LegProduct;
  container?: LegContainer;
  details?: LegDetails;
  transportInfo: LegTransport;
  stops: LegStops;
  steps: LegStep[];
  events?: LegEvents;
  previousLeg?: PreviousLeg;
  status?: string;
  g11Actions?: any;
  parentOrderNumber?: string;
  childOrderNumbers?: string[];
  unreadMessages?: number;
}

interface Booking {
  id: string;
  bookingState?: string;
}

enum BookingState {
  PREPLANNED = "PREPLANNED",
  BOOKED = "BOOKED",
  STARTED = "STARTED",
  FINISHED = "FINISHED"
}

interface LegStep {
  sequenceNumber: number;
  status: string;
  time: DateTimezone;
}

interface BoardLeg extends Leg, BoardBrick {
  oldPlannedDate?: string;
  subcontractorIdToCompare?: string;
  comparableDate?: string;
}

interface LegFilter {
  boardIds: string[];
  from: string;
  to: string;
}

interface LegEffectiveTimestamps {
  departureDate?: string;
  arrivalDate?: string;
}

interface LegSize {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

interface LegTypes {
  collection: boolean;
  delivery: boolean;
  trucking: boolean;
  fullTransport: boolean;
  dropSwap: boolean;
  abortedCollection: boolean;
  abortedDelivery: boolean;
  name: string;
}

interface LegStatuses {
  notBooked: boolean;
  prePlanned: boolean;
  booked: boolean;
  departed: boolean;
  loaded: boolean;
  unloaded: boolean;
  dropped: boolean;
  name: string;
}

interface PreviousLegStatuses {
  booked: boolean;
  departed: boolean;
  arrived: boolean;
  unknown: boolean;
}

interface PreviousLegDetails extends PreviousLeg {
  state?: PreviousLegStatuses;
  size?: LegSize;
}

interface LegsUpdateRequest {
  legs: Leg[];
  viaSocket?: boolean;
  viaDrop?: boolean;
}

interface LegUpdateMessage {
  en?: string;
  de?: string;
}

interface LegUpdateDetails {
  info: LegUpdateMessage[];
  warnings: LegUpdateMessage[];
  interactiveWarnings: LegUpdateMessage[];
  errors: LegUpdateMessage[];
}
interface LegUpdateResponse {
  leg: Leg;
  details?: LegUpdateDetails;
}

interface LegDropInfo {
  legId: string;
  dropTimestamp: string;
  dropTruckId?: string;
  dropTrailerId?: string;
  dropDriverId?: string;
  dropSubcontractor?: BrickSubcontractor;
}

interface LegBooking {
  id: string;
  steps: LegBookingStep[];
}

interface LegBookingStep {
  sequenceNumber: number;
  type: string;
  location: LegStop;
  plannedTime: DateTimezone;
  effectiveDepartureDate: DateTimezone;
  effectiveArrivalDate: DateTimezone;
  references: string;
  remarks: string;
  events: BookingStepEvent[];
}

interface BookingStepEvent {
  type: string;
  occurred: DateTimezone;
  received: DateTimezone;
  values: string[];
}

interface LegBookingStepStatus extends LegBookingStep {
  planned?: false;
  active?: false;
  arrived?: false;
}

interface LegFormPayload {
  type: string;
  value: {
    truckId?: string;
    trailerId?: string;
    driverId?: string;
    subconTruckName?: string;
    subconDriverName?: string;
  };
}

enum TransportInfoUpdateType {
  STATUS = "status",
  GROUP = "group"
}

enum TransportInfoGroupStatus {
  PENDING = "PENDING",
  DONE = "DONE"
}

enum LegTransportStatus {
  BLOCKED = "BLOCKED",
  NO_STATUS = "NO_STATUS",
  READY = "READY",
  PREPARATION = "PREPARATION"
}

interface LegPlannedFields {
  fromDate: moment.Moment | null;
  fromTime: moment.Moment | null;
  toDate: moment.Moment | null;
  toTime: moment.Moment | null;
  valid: boolean;
}

enum LegG11Action {
  NAVIGATE_TO_BOOKING_SCREEN = "NAVIGATE_TO_BOOKING_SCREEN",
  NAVIGATE_TO_PLANNING_SCREEN = "NAVIGATE_TO_PLANNING_SCREEN"
}

interface LoadingUnloadingInfo {
  title: string;
  loadingPoint?: string;
  unloadingPoint?: string;
}

enum ContainerLoadState {
  LOADED = "LOADED",
  CLEANED = "CLEANED",
  RELOAD = "RELOAD",
  UNCLEANED = "UNCLEANED"
}

interface DisplayLegContent {
  type: string;
  orderNumberAlt?: string;
  from: string;
  to: string;
  loading: string;
  unloading: string;
  trailerId: string;
  product: string;
  adrClass: string;
  loadingTime: string;
  unloadingTime: string;
  container?: LegContainer;
  driverId?: string | null;
  subcontractor?: BrickSubcontractor | null;
  g11Actions?: any;
  issues?: string;
}

interface DisplayLegStatuses {
  transportStatuses?: LegTransportStatuses;
  legType?: LegTypes;
  statusClass?: string;
  isPrevLegViewActive?: boolean;
  initialLoadState?: string;
  unloadingTemperature?: string;
  hierarchyIndicator?: string;
  legSize?: LegSize;
  hasSubiWithPrimaryBoard?: boolean;
  hasDifferentDriver?: boolean;
  hasDifferentTrailer?: boolean;
}

interface LegCallbacks {
  g11BookingCallback: () => Promise<void>;
  g11PlanningCallback: () => Promise<void>;
}

export {
  LegStop,
  LegStatuses,
  LegsResponse,
  LegEvents,
  DateTimezone,
  LegFilter,
  Leg,
  BoardLeg,
  BookingState,
  LegEffectiveTimestamps,
  LegSize,
  LegTypes,
  LegsUpdateRequest,
  LegDropInfo,
  LegOpeningHours,
  LegEventTimeSlot,
  LegBooking,
  LegBookingStep,
  LegBookingStepStatus,
  WeekDays,
  LegDetails,
  LegFormPayload,
  LegUpdateResponse,
  LegUpdateDetails,
  PreviousLegStatuses,
  PreviousLeg,
  PreviousLegDetails,
  LegTransportDetail,
  LegTransportChecklist,
  LegTransport,
  LegTransportStatuses,
  TransportInfoUpdateType,
  TransportInfoGroupStatus,
  LegTransportStatus,
  BrickSubcontractor,
  LegPlannedFields,
  LegG11Action,
  LoadingUnloadingInfo,
  ContainerLoadState,
  DisplayLegContent,
  DisplayLegStatuses,
  LegCallbacks,
  LegContainer,
  LegStep
};
