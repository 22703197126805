<template>
  <div class="ml-3 w-full flex flex-no-wrap justify-between whitespace-nowrap">
    <div class="flex flex-col mr-2 overflow-hidden" :class="{ 'justify-between': !legStatuses.isPrevLegViewActive }">
      <div>
        <span>{{ legStatuses.hierarchyIndicator }} </span>
        <span>{{ legContent.type }}-</span>
        <span
          class="cursor-pointer border-b border-gray-400 hover:border-yellow-500 inline-block"
          @click="legActionCallbacks.g11BookingCallback"
        >
          {{ legContent.orderNumberAlt }}
        </span>
        <span v-if="legStatuses.transportStatuses.hasPendingIssues" class="cursor-pointer ml-1">
          <a-tooltip placement="top" :title="legContent.issues">
            <Icon name="exclamation-triangle" class="text-red-500" height="17" width="17" />
          </a-tooltip>
        </span>
        <span class="cursor-pointer ml-1" @click="legActionCallbacks.g11PlanningCallback">
          <Icon name="hyperlink" class="hover:text-btYellow" height="17" width="17" />
        </span>
      </div>
      <div v-if="legContent.from" class="font-semibold">{{ legContent.from }}</div>
      <div class="overflow-hidden overflow-ellipsis">
        <span v-if="legContent.product" class="capitalize" :class="{ 'text-btRed': legContent.adrClass }">{{
          legContent.product.toLowerCase()
        }}</span>
        <span v-if="legStatuses.unloadingTemperature" class="font-bold"> {{ legStatuses.unloadingTemperature }}</span>
      </div>
    </div>
    <div class="flex flex-col mr-2" :class="{ 'justify-between': !legStatuses.isPrevLegViewActive }">
      <div v-if="legContent.container">
        <ClosablePopover :overlayStyle="{ maxWidth: '600px', width: '500px' }">
          <template #popoverContent>
            <CalloutLegContainer
              :title="legContent.container.id"
              :content="legContent.container"
              container-type="LEG"
              class="px-2"
            />
          </template>
          <template #popoverTrigger>
            <span
              class="leg_callout cursor-pointer border-b border-gray-400 hover:border-yellow-500"
              @touchstart="onTouchStart"
              >{{ legContent.container.id }}</span
            >
            <span class="font-bold"> {{ legStatuses.initialLoadState }}</span>
          </template>
        </ClosablePopover>
      </div>
      <div v-else class="font-semibold whitespace-nowrap invisible">Placeholder</div>
      <template v-if="legStatuses.legType.fullTransport">
        <div>
          <span class="font-semibold">{{ legContent.loading }}</span>
          <span v-if="legContent.loadingTime || legContent.plannedLoading">
            <span>{{ ` L:` }}</span>
            <span class="font-semibold">{{ ` ${legContent.plannedLoading}` }}</span>
            <span>{{ ` ${legContent.loadingTime}` }}</span>
          </span>
        </div>
        <div>
          <span class="font-semibold">{{ legContent.unloading }}</span>
          <span v-if="legContent.unloadingTime || legContent.plannedUnloading">
            <span>{{ ` U:` }}</span>
            <span class="font-semibold">{{ ` ${legContent.plannedUnloading}` }}</span>
            <span>{{ ` ${legContent.unloadingTime}` }}</span>
          </span>
        </div>
      </template>
      <template v-else-if="legStatuses.legType.collection">
        <div v-if="legContent.loading" class="font-semibold">{{ legContent.loading }}</div>
        <div v-else class="invisible">placeholder</div>
        <div v-if="legContent.loadingTime || legContent.plannedLoading">
          <span>{{ ` L:` }}</span>
          <span class="font-semibold">{{ ` ${legContent.plannedLoading}` }}</span>
          <span>{{ ` ${legContent.loadingTime}` }}</span>
        </div>
        <div v-else class="invisible">placeholder</div>
      </template>
      <template v-else-if="legStatuses.legType.delivery">
        <div v-if="legContent.unloading" class="font-semibold">{{ legContent.unloading }}</div>
        <div v-else class="invisible">placeholder</div>
        <div v-if="legContent.unloadingTime || legContent.plannedUnloading">
          <span>{{ ` U:` }}</span>
          <span class="font-semibold">{{ ` ${legContent.plannedUnloading}` }}</span>
          <span>{{ ` ${legContent.unloadingTime}` }}</span>
        </div>
        <div v-else class="invisible">placeholder</div>
      </template>
      <template v-else-if="legStatuses.legType.dropSwap">
        <div v-if="legContent.plannedLoading">
          <span>{{ `L: ` }}</span>
          <span class="font-semibold">{{ legContent.plannedLoading }}</span>
        </div>
        <div v-else class="invisible">placeholder</div>
        <div v-if="legContent.plannedUnloading">
          <span>{{ `U: ` }}</span>
          <span class="font-semibold">{{ legContent.plannedUnloading }}</span>
        </div>
        <div v-else class="invisible">placeholder</div>
      </template>
    </div>
    <div class="flex flex-col items-end " :class="{ 'justify-between': !legStatuses.isPrevLegViewActive }">
      <div class="flex flex-row ">
        <LegTransportStatusIndicator :status="legStatuses.transportStatuses" />
        <ChatNotifications
          v-if="showChatNotificationIcon"
          :content="legContent"
          iconWidth="20"
          :totalUnreadMessages="legContent.unreadMessages"
        />
      </div>
      <div v-if="legContent.to" class="font-semibold">{{ legContent.to }}</div>
      <div class="invisible">placeholder</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import { DisplayLegContent, DisplayLegStatuses, LegCallbacks } from "@/types/leg";
import ClosablePopover from "@/components/ClosablePopover.vue";
import LegTransportStatusIndicator from "@/components/Board/Leg/LegTransportStatusIndicator/LegTransportStatusIndicator.vue";
import CalloutLegContainer from "@/components/Board/CallOuts/CalloutLeg/CalloutLegContainer.vue";
import Icon from "@/components/Icon.vue";
import ChatNotifications from "@/components/Chat/ChatNotifications.vue";

export default defineComponent({
  name: "LegBreakpointXL",
  components: {
    ClosablePopover,
    ChatNotifications,
    LegTransportStatusIndicator,
    CalloutLegContainer,
    Icon
  },
  props: {
    legContent: {
      type: Object as () => DisplayLegContent,
      required: true
    },
    legStatuses: {
      type: Object as () => DisplayLegStatuses,
      required: true
    },
    legActionCallbacks: {
      type: Object as () => LegCallbacks,
      required: true
    },
    onTouchStart: {
      type: Function,
      default: () => ({})
    },
    showChatNotificationIcon: {
      type: Boolean,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
@use "../Leg.scss";
</style>
