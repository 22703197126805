<template>
  <div>
    <table class="table-fixed inspections__table">
      <thead>
        <tr>
          <th>{{ `${$t("fleetTests.nextTest")}` }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(inspection, index) in truckInspections" :key="index">
          <td>{{ inspection.name }}:</td>
          <td :class="{ 'text-btRed': inspection.isDateInPast }">{{ inspection.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { TrailerMetadata, TruckMetaData } from "@/types/fleet";
import { getNextInspectionDetails } from "@/components/Board/CallOuts/Use/useFleetTechInspectionDates";

export default defineComponent({
  name: "FleetNextInspectionTable",
  props: {
    content: {
      type: Object as PropType<TruckMetaData | TrailerMetadata>,
      required: true
    }
  },
  setup(props) {
    const truckInspections = computed(() => getNextInspectionDetails(props.content));
    return {
      truckInspections
    };
  }
});
</script>

<style scoped lang="scss">
.inspections__table {
  th {
    @apply pb-4 whitespace-nowrap;
  }
  td {
    @apply pb-4 pr-4 whitespace-nowrap;
  }
}
</style>
