/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" _fill="#3e3e3e" d="M6 9H4v2h2zm4 0H8v2h2zm4 0h-2v2h2zm2-7h-1V0h-2v2H5V0H3v2H2A1.991 1.991 0 00.01 4L0 18a2 2 0 002 2h14a2.006 2.006 0 002-2V4a2.006 2.006 0 00-2-2zm0 16H2V7h14z"/>'
  }
})
