import { computed } from "@vue/composition-api";
import store from "@/store";
import { GET_FROM_TO_DATE, GET_HOUR_FROM_TO_TIME, GET_HOURBOX_WIDTH, GET_SHOW_WEEKENDS } from "@/store/board";
import { addBusinessDays, addDays } from "date-fns";
import { GET_FLEET, GET_FLEET_SPANS } from "@/store/fleet";
import { Driver, FleetEntity, FleetEntityDetails, FleetEntityType, Subcontractor, Trailer, Truck } from "@/types/fleet";
import { toLocalDateTimeFull } from "@/use/useDate";
import { StringNumberMap } from "@/types/board";
import { spreadSubcontractorId, spreadTruckId } from "@/use/useFleet";
import { BrickSubcontractor } from "@/types/leg";
import { isMonday, startOfDay, isEqual } from "date-fns";

export default () => {
  const orderedFleet = computed<FleetEntity[]>(() => store.getters[GET_FLEET]);
  const fleetSpans = computed<StringNumberMap>(() => store.getters[GET_FLEET_SPANS]);
  const includeWeekends = computed(() => store.getters[GET_SHOW_WEEKENDS]);

  const timeFromTo = computed(() => store.getters[GET_HOUR_FROM_TO_TIME]);
  const dateFromTo = computed(() => store.getters[GET_FROM_TO_DATE]);
  const hourBoxWidth = computed(() => store.getters[GET_HOURBOX_WIDTH]);
  const hourBoxesPerDay = computed(() => {
    const { fromTime, toTime } = timeFromTo.value;
    const hourDiff = toTime - fromTime;
    if (hourDiff === 0) {
      return 3;
    }
    if (hourDiff % 3 === 0) {
      return hourDiff;
    }
    return hourDiff + 3 - (hourDiff % 3);
  });

  const getTimestampForCoordinate = (xCoordinate: number, isStartCoordinate: boolean): string => {
    const { fromDate: boardFromDate } = dateFromTo.value;
    const { fromTime: boardFromTime, toTime: boardToTime } = timeFromTo.value;

    const dayWidth = Math.round(hourBoxesPerDay.value * hourBoxWidth.value);
    const sanitizedX = xCoordinate % dayWidth === dayWidth - 1 ? xCoordinate + 1 : xCoordinate;
    const daysToAdd = Math.floor(sanitizedX / dayWidth);
    const minutesLeft = Math.round((sanitizedX % dayWidth) / (hourBoxWidth.value / 60));

    const hoursToAdd = Math.floor(minutesLeft / 60);

    let newDate = includeWeekends.value ? addDays(boardFromDate, daysToAdd) : addBusinessDays(boardFromDate, daysToAdd);
    let newHours = boardFromTime + hoursToAdd;
    let newMinutes = Math.round((minutesLeft % 60) / 15) * 15;

    if (newMinutes === 60) {
      newHours += 1;
      newMinutes = 0;
    }

    if (newMinutes == 0 && (newHours === boardFromTime || newHours === boardToTime)) {
      const daysToSubtract = !includeWeekends.value && isMonday(newDate) ? 3 : 1;
      newDate = addDays(newDate, isStartCoordinate ? 0 : -daysToSubtract);
      newHours = isStartCoordinate ? boardFromTime : boardToTime;
    }

    if (newHours === 24) {
      newHours = 0;
      newDate = addDays(newDate, 1);
      if (!includeWeekends.value && !isStartCoordinate && isMonday(newDate) && isEqual(newDate, startOfDay(newDate))) {
        newDate = addDays(newDate, -2);
      }
    }

    return toLocalDateTimeFull(newDate, newHours, newMinutes);
  };

  const getStartCoordinateTimestamp = (xCoordinate: number): string => {
    return getTimestampForCoordinate(xCoordinate, true);
  };

  const getEndCoordinateTimestamp = (xCoordinate: number): string => {
    return getTimestampForCoordinate(xCoordinate, false);
  };

  const getBrickFleetEntity = (yCoordinate: number): FleetEntity | undefined => {
    let ySum = 0;
    for (let i = 0; i < orderedFleet.value.length; i++) {
      ySum += fleetSpans.value[orderedFleet.value[i].id];
      if (ySum > yCoordinate) {
        return orderedFleet.value[i];
      }
    }
  };

  const getBrickFleetEntityDetails = (brickFleetEntity: FleetEntity): FleetEntityDetails => {
    let truckId: string | undefined;
    let driverId: string | undefined;
    let trailerId: string | undefined;
    let subcontractor: BrickSubcontractor | undefined;

    if (brickFleetEntity.type === FleetEntityType.TRUCK) {
      const truck = brickFleetEntity as Truck;
      const { id: truckDriverId, subcontractorId } = truck.crewDriver || {};
      const { id } = spreadTruckId(truck.id);
      truckId = id;
      trailerId = truck.trailer?.id;
      driverId = truckDriverId;
      subcontractor = subcontractorId ? { id: subcontractorId } : undefined;
    } else if (brickFleetEntity.type === FleetEntityType.SUBCONTRACTOR) {
      const { id, boardId, sequence } = spreadSubcontractorId(brickFleetEntity.id);
      const match = (brickFleetEntity as Subcontractor).boards.find(b => {
        return b.sequence == sequence && b.boardId == boardId;
      });
      const { truckName, driverName } = match || {};
      subcontractor = {
        id,
        subconTruckName: truckName,
        subconDriverName: driverName,
        primaryBoard: {
          id: boardId,
          sequence
        }
      };
      driverId = undefined;
      truckId = undefined;
      trailerId = undefined;
    } else if (brickFleetEntity.type === FleetEntityType.TRAILER) {
      driverId = undefined;
      truckId = undefined;
      trailerId = (brickFleetEntity as Trailer).id;
      subcontractor = undefined;
    } else {
      driverId = (brickFleetEntity as Driver).id;
      truckId = undefined;
      trailerId = undefined;
      subcontractor = undefined;
    }

    return {
      truckId,
      trailerId,
      driverId,
      subcontractor
    };
  };

  const getBrickFleetEntityDetailsByYCoord = (yCoord: number): FleetEntityDetails | undefined => {
    const fleetEntity = getBrickFleetEntity(yCoord);
    if (fleetEntity) {
      return getBrickFleetEntityDetails(fleetEntity);
    }
  };

  return {
    getStartCoordinateTimestamp,
    getEndCoordinateTimestamp,
    getBrickFleetEntity,
    getBrickFleetEntityDetails,
    getBrickFleetEntityDetailsByYCoord
  };
};
