



































import { computed, defineComponent, watch } from "@vue/composition-api";
import Icon from "../../Icon.vue";
import multiSelect from "vue-multiselect";
import store from "@/store";
import { TOGGLE_BOARD_EDIT_MODE, SET_TRANSPORT_INFO_STATUS, IS_BOARD_EDIT_MODE_ACTIVE } from "@/store/board";
import { GET_LEG_TRANSPORT_INFO_KEYS } from "@/store/brick";
import { LegTransportStatus, TransportInfoGroupStatus, TransportInfoUpdateType } from "@/types/leg";

export default defineComponent({
  components: { Icon, multiSelect },
  setup() {
    const isEditMode = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);
    const toggleEditMode = () => {
      store.dispatch(TOGGLE_BOARD_EDIT_MODE);
    };
    const bulkOptionsPresets = {
      documents: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      specialEquipments: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      temperatures: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      customs: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE],
      specialCustoms: [TransportInfoGroupStatus.PENDING, TransportInfoGroupStatus.DONE]
    };
    const statusPreset = [
      {
        type: TransportInfoUpdateType.STATUS,
        values: [
          LegTransportStatus.NO_STATUS,
          LegTransportStatus.PREPARATION,
          LegTransportStatus.READY,
          LegTransportStatus.BLOCKED
        ]
      }
    ];

    const transportInfoKeys = computed(() => store.getters[GET_LEG_TRANSPORT_INFO_KEYS]);
    const bulkOptions = computed(() => {
      const options = transportInfoKeys.value.map(key => ({
        type: key,
        values: bulkOptionsPresets[key]
      }));
      return [...statusPreset, ...options];
    });

    const bulkOptionChanged = (payload: string) => {
      const values = payload.split(",");
      const type = values[0];
      const value = LegTransportStatus.NO_STATUS === values[1] ? null : values[1];

      store.commit(SET_TRANSPORT_INFO_STATUS, { type: type, value: value });
    };

    const handleEscKey = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        toggleEditMode();
      }
    };

    watch(
      () => isEditMode.value,
      () => {
        if (isEditMode.value) {
          document.addEventListener("keydown", handleEscKey);
        } else {
          document.removeEventListener("keydown", handleEscKey);
        }
      }
    );

    return { isEditMode, toggleEditMode, bulkOptionChanged, bulkOptions };
  }
});
