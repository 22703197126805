import { computed, ref } from "@vue/composition-api";
import { BoardLeg, BookingState, LegSize, TransportInfoUpdateType } from "@/types/leg";

import store from "@/store";
import {
  CLEAR_LEG_EVENTS,
  DELETE_LEGS,
  LOCK_LEG,
  SET_IGNORABLE_BRICK_ID,
  SET_LEG_TO_SELECTED,
  UNLOCK_LEG,
  UPDATE_LEG
} from "@/store/brick";
import {
  GET_FROM_TO_DATE_WITH_TIME,
  GET_SELECTED_TRANSPORT_INFO_STATUS,
  IS_BOARD_EDIT_MODE_ACTIVE
} from "@/store/board";
import { BoardBrickPosition } from "@/types/board";
import { translateAndToastError, translateAndToastSuccess } from "@/use/useToast";
import { TRANSPORT_INFO_TYPES } from "@/components/Board/TransportInfoEditor/TransportInfoEnums";
import useBoardBrickDrag from "@/components/Board/Use/useBoardBrickDrag";
import { isAfter, isBefore } from "date-fns";

export default (legData: any) => {
  const {
    brickIsDirty,
    newBrickAttributes,
    brickIsUpdating,
    brickWasModified,
    brickDragComponent,
    brickCoordinates,
    setDirtyState,
    unsetDirtyState,
    resetBrickCoordinates,
    focusActiveBrick,
    setBrickInitPosition,
    onBrickDragStop,
    onActivated,
    onDeactivated,
    onBrickResizeStop,
    onBrickDragging,
    initializeDropped,
    initializeSelected
  } = useBoardBrickDrag(legData, resetLeg, clearLegEvents, lockLeg, unlockLeg);

  const selectedTransportInfoStatus = computed(() => store.getters[GET_SELECTED_TRANSPORT_INFO_STATUS]);
  const isBoardEditModeActive = computed(() => store.getters[IS_BOARD_EDIT_MODE_ACTIVE]);

  //Handling resizing - showing & hiding elements
  const legSize = ref<LegSize>({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
  });

  const currentLegWidth = ref<number>(legData.width);

  const noPaddingClass = computed(() => {
    return legSize.value.sm ? "leg--noPadding" : "";
  });

  const dirtyStateClass = computed(() => {
    return !!brickIsDirty.value && "leg--dirty";
  });

  //lock - unlock logic
  const legLocked = ref(false);

  async function lockLeg(legId: string) {
    if (!legLocked.value) {
      try {
        await store.dispatch(LOCK_LEG, legId);
        legLocked.value = true;
      } catch (e) {
        resetBrickCoordinates();
        unsetDirtyState();
      }
    }
  }

  async function unlockLeg(legId: string) {
    if (legLocked.value) {
      try {
        await store.dispatch(UNLOCK_LEG, legId);
        legLocked.value = false;
      } catch (e) {
        //show errors which are not handled globally
      }
    }
  }

  /***
   * Dragging and resizing logic
   */

  async function resetLeg() {
    if (isBoardEditModeActive.value) {
      await unsetLegEditState();
      return;
    }
    if (legData.value.dropped) {
      await store.dispatch(DELETE_LEGS, [legData.value.id]);
    } else {
      await store.commit(SET_LEG_TO_SELECTED, legData.value.id);
    }
    await unsetLegEditState();
    //scroll to the active selected leg
    focusActiveBrick();
  }

  const getUpToDateLeg = (): BoardLeg => {
    if (isBoardEditModeActive.value) {
      return getEditedTransportInfoLeg();
    }
    const {
      effectiveStartTime: plannedDepartureTime,
      effectiveEndTime: plannedArrivalTime,
      truckId,
      trailerId,
      driverId,
      subcontractor,
      boards
    } = newBrickAttributes.value;

    const { events } = legData.value;
    const { fromDate, toDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];
    const plannedDepartureTimezone: string | undefined = events && events.plannedDepartureDate?.timezone;
    const plannedArrivalTimezone: string | undefined = events && events.plannedArrivalDate?.timezone;

    let plannedArrival = plannedArrivalTime;
    if (isAfter(new Date(events.plannedArrivalDate?.date!), toDate)) {
      plannedArrival = events.plannedArrivalDate.date;
    }
    let plannedDeparture = plannedDepartureTime;
    if (isBefore(new Date(events.plannedDepartureDate?.date!), fromDate)) {
      plannedDeparture = events.plannedDepartureDate.date;
    }

    return {
      ...legData.value,
      truckId,
      trailerId,
      driverId,
      boards,
      subcontractor,
      events: {
        ...legData.value.events,
        plannedDepartureDate: {
          date: plannedDeparture,
          timezone: plannedDepartureTimezone
        },
        plannedArrivalDate: {
          date: plannedArrival,
          timezone: plannedArrivalTimezone
        }
      }
    };
  };

  const getEditedTransportInfoLeg = (): BoardLeg => {
    if (selectedTransportInfoStatus.value.type == TransportInfoUpdateType.STATUS) {
      const status = selectedTransportInfoStatus.value.value;
      return {
        ...legData.value,
        status
      };
    }
    const transportInfo = {
      ...legData.value.transportInfo
    };
    transportInfo[selectedTransportInfoStatus.value.type] = legData.value.transportInfo[
      selectedTransportInfoStatus.value.type
    ].map(item => ({
      ...item,
      checked: selectedTransportInfoStatus.value.value === TRANSPORT_INFO_TYPES.DONE
    }));

    return {
      ...legData.value,
      transportInfo
    };
  };

  const updateLeg = async () => {
    brickIsUpdating.value = true;
    const updateRequest = getUpToDateLeg();
    try {
      store.commit(SET_IGNORABLE_BRICK_ID, updateRequest.id);
      const updatedLeg = await store.dispatch(UPDATE_LEG, updateRequest);
      await unsetLegEditState();
      brickIsUpdating.value = false;
      setBrickInitPosition(updatedLeg);
      resetBrickCoordinates();
      translateAndToastSuccess("leg.updated");
    } catch (e) {
      await resetLeg();
      store.commit(SET_IGNORABLE_BRICK_ID, null);
      brickIsUpdating.value = false;
    }
  };

  const resizeSticks = computed(() => {
    const { events } = legData.value;
    const { fromDate, toDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];
    let isRightEnabled = true;
    let isLeftEnabled = true;
    if (events && events.effectiveArrivalDate?.date) {
      isRightEnabled = false;
      isLeftEnabled = false;
    }
    if (events && events.effectiveDepartureDate?.date) {
      isLeftEnabled = false;
    }
    if (isRightEnabled && isAfter(new Date(events.plannedArrivalDate?.date!), toDate)) {
      isRightEnabled = false;
    }
    if (isLeftEnabled && isBefore(new Date(events.plannedDepartureDate?.date!), fromDate)) {
      isLeftEnabled = false;
    }

    const sticks: string[] = [];
    if (isRightEnabled) {
      sticks.push("mr");
    }
    if (isLeftEnabled) {
      sticks.push("ml");
    }
    return sticks;
  });

  const allowedAxisMovement = computed(() => {
    const { booking, events } = legData.value;
    const { fromDate, toDate } = store.getters[GET_FROM_TO_DATE_WITH_TIME];
    let isAllowedX = true;
    let isAllowedY = true;
    if (booking?.bookingState == BookingState.STARTED || booking?.bookingState == BookingState.FINISHED) {
      isAllowedY = false;
    }
    if (
      isAfter(new Date(events.plannedArrivalDate?.date!), toDate) ||
      isBefore(new Date(events.plannedDepartureDate?.date!), fromDate)
    ) {
      isAllowedX = false;
    }
    if (events.effectiveDepartureDate?.date || events.effectiveArrivalDate?.date) {
      isAllowedX = false;
      isAllowedY = false;
    }
    if (isAllowedX && isAllowedY) {
      return "both";
    } else if (isAllowedX) {
      return "x";
    } else if (isAllowedY) {
      return "y";
    }
    return "none";
  });

  const isDraggable = computed(() => {
    return true;
  });

  function clearLegEvents(legId: string) {
    store.commit(CLEAR_LEG_EVENTS, legId);
  }

  const setLegEditState = async () => {
    setDirtyState();
    await lockLeg(legData.value.id);
  };

  const unsetLegEditState = async () => {
    unsetDirtyState();
    await unlockLeg(legData.value.id);
  };

  const onResizing = (params: { width: number }) => {
    currentLegWidth.value = params.width;
    //set all to false
    Object.keys(legSize.value).forEach(size => (legSize.value[size] = false));
    legSize.value.xs = params.width <= 50;
    legSize.value.sm = params.width > 50 && params.width <= 250;
    legSize.value.md = params.width > 250 && params.width <= 400;
    legSize.value.lg = params.width > 400 && params.width <= 600;
    legSize.value.xl = params.width > 600;
  };

  const onDragStop = async (params: BoardBrickPosition) => {
    await onBrickDragStop(params);
    const { truckId, subcontractor } = newBrickAttributes.value;
    if (truckId == null && subcontractor?.id == null) {
      translateAndToastError("errors.LEG_DROP_NOT_ALLOWED");
      await resetLeg();
      initializeSelected();
      return;
    }
    if (brickWasModified.value) {
      setLegEditState();
    }
  };

  return {
    onActivated,
    onDeactivated,
    onResizing,
    onResizeStop: onBrickResizeStop,
    onDragging: onBrickDragging,
    onDragStop,
    resetLeg,
    updateLeg,
    initializeDropped,
    initializeSelected,
    setLegInitPosition: setBrickInitPosition,
    clearLegEvents,
    unsetLegEditState,
    brickDragComponent,
    isDraggable,
    allowedAxisMovement,
    resizeSticks,
    legSize,
    legIsDirty: brickIsDirty,
    dirtyStateClass,
    noPaddingClass,
    legIsUpdating: brickIsUpdating,
    legCoordinates: brickCoordinates,
    currentLegWidth
  };
};
