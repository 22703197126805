import { createServer, Response } from "miragejs";
import trucks from "@/mocks/data/trucks.json";
import trailers from "@/mocks/data/trailers.json";
import drivers from "@/mocks/data/drivers.json";
import legs from "@/mocks/data/legs.json";
import { API_BASE_URL } from "@/env_config";

export function makeServer({ environment = "development" } = {}) {
  if (process.env.NODE_ENV === "development") {
    return createServer({
      environment,
      routes() {
        this.namespace = "api";
        this.passthrough(`${API_BASE_URL}/**`);

        //fleets
        this.get("/fleet/trucks/preview", () => {
          return trucks;
        });
        this.get("/fleet/trailers/preview", () => {
          return trailers;
        });
        this.get("/fleet/drivers/preview", () => {
          return drivers;
        });

        //legs
        /*boardIds: ["FSFRoad"]
        from: "2021-03-05T00:00:00.000Z"
        to: "2021-03-08T23:59:59.000Z"       */
        this.post("/legs/filter", () => {
          return legs;
        });

        //mocking errors
        this.post("/legs/:id/lock", () => {
          return new Response(409, { some: "header" }, { errors: ["LEG_ALREADY_LOCKED"] });
        });
      }
    });
  }
}
