




































import { defineComponent, toRefs } from "@vue/composition-api";
import { BoardLeg } from "@/types/leg";
import useLegStatus from "@/components/Board/Leg/Use/useLegStatus";
import { dateStringToShowTime } from "@/use/useDate";

export default defineComponent({
  name: "LegDetailTimeSlot",
  props: {
    selectedLeg: {
      type: Object as () => BoardLeg
    }
  },
  setup(props) {
    const { selectedLeg: selectedLegRef } = toRefs(props);
    const { legType } = useLegStatus(selectedLegRef);

    return {
      legType,
      dateStringToShowTime
    };
  }
});
