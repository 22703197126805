




























import { defineComponent, ref } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import ClickOutside from "vue-click-outside";

export default defineComponent({
  name: "Popover",
  components: { Icon },
  directives: {
    ClickOutside
  },
  props: {
    align: {
      type: Object,
      default: () => ({})
    },
    defaultVisible: {
      type: Boolean,
      default: false
    },
    placement: String,
    overlayStyle: Object
  },
  setup() {
    const isPopoverVisible = ref(false);

    const closePopover = (): void => {
      isPopoverVisible.value = false;
    };

    const onClickOutside = (event): void => {
      const targetPopOverElement = event.target.closest(".ant-popover");
      const targetLegElement = event.target.closest(".brick");
      const hasLegCalloutClass = event.target.classList.contains("leg_callout");
      if (
        !targetPopOverElement &&
        targetLegElement &&
        isPopoverVisible.value === true &&
        hasLegCalloutClass === false
      ) {
        closePopover();
      }
    };

    return {
      isPopoverVisible,
      closePopover,
      onClickOutside
    };
  }
});
