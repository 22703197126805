var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c("p", { staticClass: "mb-2 font-semibold" }, [
      _vm._v(_vm._s(_vm.$t("openingHours.title")))
    ]),
    _c(
      "div",
      _vm._l(_vm.days, function(day) {
        return _c("div", { key: day.weekDay }, [
          _c(
            "div",
            {
              staticClass: "flex justify-between mb-4",
              class: { plannedDay: day.isPlanned }
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-gray-400",
                  class: { plannedDay: day.isPlanned }
                },
                [_vm._v(_vm._s(_vm.$t("weekdays." + day.weekDay)) + ":")]
              ),
              day.ranges.length
                ? _c(
                    "div",
                    { staticClass: "ml-4" },
                    _vm._l(day.ranges, function(timeRange, index) {
                      return _c("div", { key: index }, [
                        _vm._v(
                          " " +
                            _vm._s(timeRange.from) +
                            " - " +
                            _vm._s(timeRange.to) +
                            " "
                        )
                      ])
                    }),
                    0
                  )
                : _c("div", { staticClass: "ml-4" }, [
                    _vm._v(_vm._s(_vm.$t("openingHours.closed")))
                  ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }