













import { defineComponent } from "@vue/composition-api";
import Icon from "@/components/Icon.vue";
import Button from "./Button.vue";

export default defineComponent({
  name: "FilterButton",
  components: { Icon, Button },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit("click");
    };

    return { onClick };
  }
});
