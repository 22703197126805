/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>'
  }
})
