































import { defineComponent, ref, computed } from "@vue/composition-api";
import moment from "moment";
import Button from "@/components/Buttons/Button.vue";
import store from "@/store";
import { DAYS_FROM_TODAY_START, DAYS_FROM_TODAY_END, SET_DEFAULT_DATES, UPDATE_CONFIG_PROFILE } from "@/store/board";
import { CLEAR_FLEET, FETCH_BOARD_FLEET } from "@/store/fleet";
import { RESET_FLEET_ACTIONS, RESET_LEGS } from "@/store/brick";
import { DefaultDateDaysPayload } from "@/types/board";

export default defineComponent({
  components: { Button },
  setup(props, { emit }) {
    const daysFromTodayStart = computed(() => store.getters[DAYS_FROM_TODAY_START]);
    const daysFromTodayEnd = computed(() => store.getters[DAYS_FROM_TODAY_END]);
    const daysFromTodayStartInput = ref(daysFromTodayStart.value || 0);
    const daysFromTodayEndInput = ref(daysFromTodayEnd.value || 0);
    const fromDate = computed(() => moment().add(daysFromTodayStartInput.value, "days"));
    const toDate = computed(() => moment().add(daysFromTodayEndInput.value, "days"));

    const defaultDateActivated = daysFromTodayStart.value != null && daysFromTodayEnd.value != null;

    const onDefaultDateSwitchChange = async () => {
      const payload = {
        daysFromTodayStart: defaultDateActivated ? null : 0,
        daysFromTodayEnd: defaultDateActivated ? null : 0
      };
      saveDefaultDateSettings(payload);
    };

    const onSaveButtonClick = () => {
      const payload = {
        daysFromTodayStart: daysFromTodayStartInput.value,
        daysFromTodayEnd: daysFromTodayEndInput.value
      };
      saveDefaultDateSettings(payload);
    };

    const saveDefaultDateSettings = async (payload: DefaultDateDaysPayload) => {
      emit("isLoading", true);
      await store.dispatch(SET_DEFAULT_DATES, payload);
      await store.dispatch(UPDATE_CONFIG_PROFILE);
      await store.commit(CLEAR_FLEET);
      await store.commit(RESET_LEGS);
      await store.commit(RESET_FLEET_ACTIONS);
      await store.dispatch(FETCH_BOARD_FLEET);
      emit("isLoading", false);
    };

    return {
      defaultDateActivated,
      daysFromTodayStart,
      daysFromTodayEnd,
      daysFromTodayStartInput,
      daysFromTodayEndInput,
      fromDate,
      toDate,
      onSaveButtonClick,
      onDefaultDateSwitchChange
    };
  }
});
