var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      attrs: {
        overlayClassName: "fullLegViewPopover",
        placement: "topLeft",
        overlayStyle: { width: "650px" },
        arrowPointAtCenter: true,
        mouseEnterDelay: _vm.POPOVER_OPEN_DELAY,
        mouseLeaveDelay: _vm.POPOVER_CLOSE_DELAY
      },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function() {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.onClickOutside,
                      expression: "onClickOutside"
                    }
                  ],
                  staticClass: "flex",
                  class: _vm.legStatuses.statusClass
                },
                [
                  _c("TTALink", { attrs: { "leg-data": _vm.legData } }),
                  _c("LegAffiliationPopover", {
                    staticClass: "bottom-4 right-1",
                    attrs: {
                      legContent: _vm.legContent,
                      legSize: _vm.largeLegSize,
                      isViewedInPopover: true,
                      hasDifferentDriver: _vm.legStatuses.hasDifferentDriver,
                      hasDifferentTrailer: _vm.legStatuses.hasDifferentTrailer,
                      hasSubiWithPrimaryBoard:
                        _vm.legStatuses.hasSubiWithPrimaryBoard,
                      onTouchStartHandler: _vm.onTouchStart
                    }
                  }),
                  _c("LegBreakpointXL", {
                    attrs: {
                      legContent: _vm.legContent,
                      legStatuses: _vm.legStatuses,
                      legActionCallbacks: _vm.legActionCallbacks,
                      onTouchStart: _vm.onTouchStart,
                      showChatNotificationIcon: false
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c("div", {
        staticClass:
          "full-leg-view-trigger absolute left-0 top-0 w-2 h-full bg-transparent"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }