import store from "@/store";
import webStomp, { Message } from "webstomp-client";
import { WebSocketMessage, WebSocketMessageAction } from "@/types/websocket";
import { Leg as LegResponse } from "@/types/leg";
import {
  DELETE_LEGS,
  GET_IGNORABLE_BRICK_ID,
  REMOVE_FLEET_ACTIONS,
  SET_IGNORABLE_BRICK_ID,
  UPDATE_FLEET_ACTIONS,
  UPDATE_LEGS
} from "@/store/brick";
import { getCookie } from "@/use/useCookie";
import { TP_COOKIE_NAME, DISPATCHER_BASE_URL } from "@/env_config";
import Sock from "sockjs-client";
import { ref } from "@vue/composition-api";
import { FleetAction } from "@/types/action";
import { Trailer } from "@/types/fleet";
import { Chat } from "@/types/chat";
import { UPDATE_TRAILER, REMOVE_TRAILER } from "@/store/fleet";
import { CHAT_UPDATE, CHAT_READ } from "@/store/chat";

function shouldIgnoreDuplicateUpdate(id: string): boolean {
  const ignorableBrickId = store.getters[GET_IGNORABLE_BRICK_ID];
  if (id === ignorableBrickId) {
    store.commit(SET_IGNORABLE_BRICK_ID, null);
    return true;
  }
  return false;
}

export default function() {
  let retryCount = 0;
  let keepaliveInterval: number;
  const stompClient: any = ref(undefined);

  const onSocketMessage = (socketMessage: Message): void => {
    const message: WebSocketMessage = JSON.parse(socketMessage.body);

    if (message.messageType === "FLEET_ACTION") {
      const action = message.messageContent as FleetAction;

      const shouldIgnoreActionUpdate = shouldIgnoreDuplicateUpdate(action.id);
      if (shouldIgnoreActionUpdate) return;

      if (message.messageAction === WebSocketMessageAction.UPDATE) {
        store.dispatch(UPDATE_FLEET_ACTIONS, { actions: [action], viaSocket: true });
      } else if (message.messageAction === WebSocketMessageAction.DELETE) {
        store.dispatch(REMOVE_FLEET_ACTIONS, [action.id]);
      }
      return;
    }

    if (message.messageType === "LEG") {
      const leg = message.messageContent as LegResponse;

      const shouldIgnoreActionUpdate = shouldIgnoreDuplicateUpdate(leg.id);
      if (shouldIgnoreActionUpdate) return;

      if (message.messageAction === WebSocketMessageAction.UPDATE) {
        store.dispatch(UPDATE_LEGS, { legs: [leg], viaSocket: true });
      } else if (message.messageAction === WebSocketMessageAction.DELETE) {
        store.dispatch(DELETE_LEGS, [leg.id]);
      }
    }

    if (message.messageType === "TRAILER") {
      const trailer = message.messageContent as Trailer;
      if (message.messageAction === WebSocketMessageAction.UPDATE) {
        store.dispatch(UPDATE_TRAILER, trailer);
      } else if (message.messageAction === WebSocketMessageAction.DELETE) {
        store.dispatch(REMOVE_TRAILER, trailer.id);
      }
    }

    if (message.messageType === "CHAT_UPDATE") {
      const chat = message.messageContent as Chat;
      if (message.messageAction === WebSocketMessageAction.UPDATE) {
        store.dispatch(CHAT_UPDATE, chat);
      }
    }

    if (message.messageType === "CHAT_READ") {
      const chat = message.messageContent as Chat;
      if (message.messageAction === WebSocketMessageAction.UPDATE) {
        store.dispatch(CHAT_READ, chat);
      }
    }
  };

  const getSocketRetryInterval = retryCount => {
    return retryCount * 1000;
  };

  const isWebSocketConnected = (): boolean => {
    return typeof stompClient.value !== "undefined" && stompClient.value.connected;
  };

  const keepAlive = (): void => {
    if (isWebSocketConnected()) {
      stompClient.value!.send("/app/ping", "PING");
    }
  };

  const resetKeepAliveInterval = (): void => {
    if (typeof keepaliveInterval !== "undefined") {
      clearInterval(keepaliveInterval);
    }
  };

  const disconnectWebSocket = (): void => {
    stompClient.value.disconnect();
    resetKeepAliveInterval();
  };

  const initWebSocket = (rangeFrom: string, rangeTo: string) => {
    if (isWebSocketConnected()) {
      disconnectWebSocket();
    }

    const token = getCookie(TP_COOKIE_NAME);
    stompClient.value = webStomp.over(new Sock(`${DISPATCHER_BASE_URL}/updates-ws?tpToken=${token}`), {
      debug: false
    });

    stompClient.value.connect(
      {
        "x-authorization": token,
        "x-time-range": `${rangeFrom}/${rangeTo}`
      },
      () => {
        stompClient.value!.subscribe("/user/queue/updates", onSocketMessage);
        retryCount = 0;
      },
      () => {
        console.log("Failed to connect to WS, retrying...");

        if (isWebSocketConnected()) {
          disconnectWebSocket();
        }

        setTimeout(() => initWebSocket(rangeFrom, rangeTo), getSocketRetryInterval(retryCount++));
      }
    );

    keepaliveInterval = setInterval(() => keepAlive(), 30000);
  };

  return {
    initWebSocket
  };
}
