<template>
  <div v-if="bookingDetail" class="mt-8 legDetailStepSection relative">
    <table class="table-fixed">
      <thead>
        <tr>
          <th></th>
          <th class="">{{ $t("legSteps.stepType") }}</th>
          <th class="w-1/4">{{ $t("legSteps.timing") }}</th>
          <th class="">{{ $t("legSteps.address") }}</th>
          <th class="w-1/4">{{ $t("legSteps.reference") }}</th>
          <th class="w-1/2">{{ $t("legSteps.remarks") }}</th>
        </tr>
      </thead>
      <tbody v-if="steps && !isLoading">
        <tr v-for="(step, index) in steps" :key="index">
          <td>
            <leg-detail-step-section-bullets
              :isFirst="index === 0"
              :passed="step.arrived"
              :isActive="step.active"
              :isLast="index === steps.length - 1"
            />
          </td>
          <td>
            <tag :variant="variant(step)">{{ step.type }}</tag>
          </td>
          <td>
            <div class="flex items-center">
              <div v-if="step.active">
                {{ dateStringToShowDate(step.effectiveArrivalDate.date) }}
                {{ dateStringToShowTime(step.effectiveArrivalDate.date) }}<br />{{ step.effectiveArrivalDate.timezone }}
              </div>
              <div v-else-if="step.arrived">
                {{ dateStringToShowDate(step.effectiveDepartureDate.date) }}
                {{ dateStringToShowTime(step.effectiveDepartureDate.date) }}<br />{{
                  step.effectiveDepartureDate.timezone
                }}
              </div>
              <div v-else>
                {{ dateStringToShowDate(step.plannedTime.date) }} {{ dateStringToShowTime(step.plannedTime.date)
                }}<br />{{ step.plannedTime.timezone }}
              </div>
              <div v-if="step.events">
                <a-popover
                  trigger="hover"
                  placement="top"
                  :overlayStyle="{ maxWidth: '650px' }"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <Icon name="info" class="text-black cursor-pointer" width="1.5rem" height="1.5rem" />
                  <template #content>
                    <LegDetailStepSectionEvents :events="step.events" />
                  </template>
                </a-popover>
              </div>
            </div>
          </td>
          <td>
            <span v-if="step.location">{{ step.location.name }}</span>
          </td>
          <td>
            <span v-if="step.references">{{ step.references }}</span>
          </td>
          <td>
            <span v-if="step.remarks">{{ step.remarks }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="isLoading"
      class=" h-48 w-full bg-btYellow bg-opacity-25 absolute top-0 flex items-center justify-center"
    >
      <Icon class="text-black" name="spinner" />
    </div>
  </div>
</template>

<script lang="ts">
import Tag from "@/components/Tag.vue";
import legService from "@/services/legService";
import Icon from "@/components/Icon.vue";
import { BoardLeg, LegBooking, LegBookingStepStatus } from "@/types/leg";
import { dateStringToShowDate, dateStringToShowTime } from "@/use/useDate";
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import LegDetailStepSectionBullets from "./LegDetailStepSectionBullets/LegDetailStepSectionBullets.vue";
import LegDetailStepSectionEvents from "@/components/Board/Leg/LegDetail/LegDetailStepSection/LegDetailStepSectionEvents/LegDetailStepSectionEvents.vue";

export default defineComponent({
  components: { LegDetailStepSectionBullets, Tag, Icon, LegDetailStepSectionEvents },
  props: {
    selectedLeg: {
      type: Object as () => BoardLeg
    }
  },
  setup(props, { emit }) {
    const { selectedLeg } = toRefs(props);
    const bookingDetail = ref<LegBooking | null>(null);
    const isLoading = ref(false);

    const steps = computed(() => {
      return bookingDetail.value?.steps
        .map(item => {
          const timeStatus = {
            planned: false,
            active: false,
            arrived: false
          };
          if (item.effectiveDepartureDate) {
            timeStatus.arrived = true;
          } else if (item.effectiveArrivalDate) {
            timeStatus.active = true;
          } else if (item.plannedTime) {
            timeStatus.planned = true;
          }
          item = {
            ...item,
            ...timeStatus
          };
          return item;
        })
        .sort((s1, s2) => s1.sequenceNumber - s2.sequenceNumber);
    });

    const getLegBookingDetails = async () => {
      isLoading.value = true;
      try {
        const { data } = await legService.getLegBooking(selectedLeg?.value?.booking?.id || "");
        bookingDetail.value = data;
      } catch (e) {
        //handle not global errors
      }
      isLoading.value = false;
    };

    const variant = (step: LegBookingStepStatus): string => {
      if (step.active) {
        return "warning";
      }
      if (step.arrived) {
        return "success";
      }
      return "primary";
    };

    const eventWasRead = computed(() => {
      const readEvents = steps.value
        ?.map(item => {
          return item.events.find(event => event.type === "READ");
        })
        .filter(item => item);
      if (readEvents?.length) {
        return true;
      }
      return false;
    });

    watch(
      () => eventWasRead.value,
      () => {
        emit("setEventIsRead", eventWasRead.value);
      }
    );

    onMounted(() => {
      getLegBookingDetails();
    });

    return {
      steps,
      variant,
      dateStringToShowTime,
      dateStringToShowDate,
      isLoading,
      bookingDetail
    };
  }
});
</script>

<style scoped lang="scss">
@use "./LegDetailStepSection";
</style>
