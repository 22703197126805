import { ref, computed, watch, onMounted, onUnmounted } from "@vue/composition-api";

//global variables
const scrollFixed = ref(false);

export default function useScrollPosition() {
  const state = ref({
    top: 0,
    left: 0,
    isScrollingX: false,
    isScrollingY: false
  });

  function init() {
    state.value.top = window.scrollY;
    state.value.left = window.scrollX;
  }

  onMounted(() => {
    window.addEventListener("scroll", init);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", init);
  });

  let resetScrollingX;
  let resetScrollingY;
  watch(
    () => state.value.left,
    () => {
      state.value.isScrollingX = true;
      window.clearTimeout(resetScrollingX);
      resetScrollingX = setTimeout(function() {
        state.value.isScrollingX = false;
        // Run the callback
      }, 1000);
    }
  );

  watch(
    () => state.value.top,
    () => {
      state.value.isScrollingY = true;
      window.clearTimeout(resetScrollingY);
      resetScrollingY = setTimeout(function() {
        state.value.isScrollingY = false;
      }, 1000);
    }
  );

  const scrolledTop = computed(() => state.value.top);
  const scrolledLeft = computed(() => state.value.left);
  const isScrollingX = computed(() => state.value.isScrollingX);
  const isScrollingY = computed(() => state.value.isScrollingY);

  //enables/disables the scroll on page - use it for modals etc.
  const toggleScrollFixed = () => {
    scrollFixed.value = !scrollFixed.value;

    if (scrollFixed.value) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  };

  init();

  return {
    scrolledTop,
    scrolledLeft,
    isScrollingX,
    isScrollingY,
    toggleScrollFixed
  };
}
